import { Fragment, useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Table, Modal, Input, Tooltip, Button, Space, Select, Popover, Tag, Drawer } from 'antd';
import { privilegeslist, updatestatususerpreviliges, saveuserpreviliges, updateUserPrivileges } from './../../Store/Action/Master/Userprivileges';
import SimpleReactValidator from 'simple-react-validator';
import _, { merge } from 'lodash'
import { Toaster } from '../../Libs/Toaster';
import { LikeOutlined, DislikeOutlined, UserAddOutlined, SearchOutlined, UpCircleOutlined, PlusCircleOutlined, PlayCircleOutlined, ExclamationCircleTwoTone, FullscreenOutlined, UserDeleteOutlined, UpOutlined, EditOutlined } from '@ant-design/icons';
import ScrollToTop from 'react-scroll-to-top';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";


const mapStateToProps = (state) => ({
    list: state.Userprivileges.privileges
})

const Userprivilegeslist = ({
    privilegeslist,
    list,
    updatestatususerpreviliges,
    saveuserpreviliges,
    updateUserPrivileges
}) => {
    console.log(list, 'listlist');
    const [temporary, setTemporary] = useState([])
    const { Option } = Select;
    const [typedata, setTypeData] = useState("")
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [passvisible, Setpassvisible] = useState(false);
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [PopupFormSubmit, setPopupFormSubmit] = useState(false);
    const [UserPriviligesFrom, setUserPriviligesForm] = useState(false);
    const [modalVisible, SetmodalVisible] = useState(false);
    const [editmodalVisible, SeteditmodalVisible] = useState(false);
    const [formsResponse, setFormsResponse] = useState([]);
    const [menuList, setmenuList] = useState([]);
    const [selectShow, setSelectShow] = useState(false);
    const [temparr, setTemparr] = useState([]);
    const [checkedRow, setCheckedRow] = useState(false);
    const [checkbox, setCheckbox] = useState(false);
    const [tempSubMenu, settempSubMenu] = useState([]);
    const [finalMenu, setFinalmenu] = useState([])
    const [scroll, setScroll] = useState(false)
    const [passwordata, Setpassword] = useState({

        passvalue: '',
        u_g_id: '',
        is_active: ''
    });
    const content = (
        <div>
            <p style={{ color: 'green' }}>Active - 10</p>
            <p style={{ color: 'red' }}>InActive - 2</p>
        </div>
    );
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    //form initialize
    const [userPriviligesDetails, setuserPriviligesDetails] = useState({
        userGroupName: '',
        categoryName: '',
        form_id: [],
    });
    console.log(userPriviligesDetails, "userPriviligesDetails");

    const [formId, setFormId] = useState()
    const [userPriviligeValue, setUserPriviligeValue] = useState({
        user_group_name: '',
        category_name: ''
    })
    const [edit, setEdit] = useState(false);
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(
        false
    );

    const formValidator = useRef(new SimpleReactValidator());
    const formValidator1 = useRef(new SimpleReactValidator());
    const [userCategoryNameList, setUserCategoryNameList] = useState([])
    //Status change
    const statuschange = (row) => {
        setTypeData(row.is_active)
        let is_active_current = '';
        if (row.is_active == 1) {
            is_active_current = false
        } else if (row.is_active == 0) {
            is_active_current = true
        }
        Activate(row.is_active)
        Setpassword({
            ...passwordata,
            u_g_id: row.u_g_id,
            is_active: is_active_current
        })


    }
    const passwordcancel = () => {
        Setpassvisible(false);
        Setpassword({
            ...passwordata,
            passvalue: ''
        })
        setAddFormSubmit(false);
    }
    const Activate = (type) => {
        type == 1 ?
            Swal.fire({
                title: 'Are You Sure?',
                text: "You want to Deactivate",
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                // customClass: localStorage.getItem("currentTheme") == 'theme_four' ? "customSWal" : 'customSWal2',
                // confirmButtonColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 '
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    Setpassvisible(true);



                    // Swal.fire('Closed.!', 'Closed...', 'success');
                }
            }) :
            Swal.fire({
                title: 'Are You Sure?',
                text: "You want to Activate",
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                // customClass: localStorage.getItem("currentTheme") == 'theme_four' ? "customSWal" : 'customSWal2',
                // confirmButtonColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 '
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    Setpassvisible(true);



                    // Swal.fire('Closed.!', 'Closed...', 'success');
                }
            });
    };
    console.log(userCategoryNameList, 'serCategoryNameList')
    console.log(userPriviligesDetails, 'serCategoryNameList')
    const modalcancel = () => {
        setuserPriviligesDetails({
            ...userPriviligesDetails,
            userGroupName: '',
            categoryName: '',
            form_id: [],
        })
        SetmodalVisible(false);
        //setmenuList([])
        setFinalmenu([])
        setPopupFormSubmit(false);
        // setUserCategoryNameList({})
        setSelectShow(false);
        setOpendrawer(false);

    }
    const editmodalcancel = () => {
        SeteditmodalVisible(false);
        Seteditdrawer(false)
        setmenuList([])
        setFinalmenu([])
        setuserPriviligesDetails({
            userGroupName: '',
            categoryName: '',
            form_id: [],
        })
        setSelectShow(false);
        setPopupFormSubmit(false);
    }
    const [opendrawer, setOpendrawer] = useState(false);
    const [editdrawer, Seteditdrawer] = useState(false);


    const addopen = () => {
        setFormsResponse(list.forms)
        SetmodalVisible(true);
        setOpendrawer(true);

    }
    const fetchdetails = (row) => {
        setFormsResponse(list.forms)
        let temp = []
        for (let i in row.f_ids) {
            temp.push(row.f_ids[i])
        }
        setTemparr(temp)
        SeteditmodalVisible(true);
        Seteditdrawer(true)
        setSelectShow(true)
        setuserPriviligesDetails({
            ...userPriviligesDetails,
            userGroupName: row.u_g_name,
            categoryName: row.u_c_id,
            c_name: row.u_c_name,
            form_id: temp,
            u_g_id: row.u_g_id
        })
    }
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        setSelectedKeys(e.target.value ? [e.target.value] : [])
        confirm({ closeDropdown: false });
        console.log(dataIndex, 'SEARCH VALUE')
        setSearchText(e.target.value);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const searchInput = useRef(searchText ? searchText : null);
    const [iconEnable, setIconEnable] = useState(false);
    const [clearText, setClearText] = useState('');
    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div>
                <Input
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    value={selectedKeys[0]}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}
                />
                {/* <Space>
                    <Button
                        type="primary"
                        // onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                </Space> */}
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });
    const [paginationSize, setPaginationSize] = useState(25);
    const [page, setPage] = useState(1);
    const columns = [
        {
            title: '#',
            key: 'index',
            align: 'center',
            width: '30px',
            render: (text, record, index) => {
                return (
                    (page - 1) * paginationSize + index + 1
                )
            },
        },
        {

            title: 'User GroupName',
            dataIndex: 'u_g_name',
            key: 'u_g_name',
            width: '205px',
            ellipsis: 'true',
            // sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('u_g_name', 'User Group Name'),
            // sorter: (a, b) => a.u_g_name.length - b.u_g_name.length
            sorter: (a, b) => a.u_g_name.localeCompare(b.u_g_name),

        },
        {
            title: 'Category Name',
            dataIndex: 'u_c_name',
            key: 'u_c_name',
            width: '160px',
            // sortDirections: ['descend', 'ascend'],
            filters: [
                {
                    text: 'Client Executive',
                    value: 'Client Executive',
                },
                {
                    text: 'Domain Admin',
                    value: 'Domain Admin',
                },
                {
                    text: 'Legal Entity Admin',
                    value: 'Legal Entity Admin',
                },
                {
                    text: 'Service Provider User',
                    value: 'Service Provider User',
                },
                {
                    text: 'View Only',
                    value: 'View Only',
                },
                {
                    text: 'View Only Restricted',
                    value: 'View Only Restricted',
                }
            ],
            onFilter: (value, record) => record.u_c_name.indexOf(value) === 0,
            // sorter: (a, b) => a.u_c_name.length - b.u_c_name.length
            sorter: (a, b) => a.u_c_name.localeCompare(b.u_c_name)

        },
        {
            // title: <><label>Actions</label><Tooltip className='serviveproidertooltipclass' style={{ marginBotton: '5px', marginleft: '5px' }} placement="topRight" title={"This Action Includes Edit, Active/Inactive Status Change"}><InfoCircleOutlined /></Tooltip></>,
            title: <><label>Actions</label></>,
            dataIndex: 'u_c_name',
            align: 'center',
            width: '70px',
            filters: [
                {
                    text: 'Active',
                    value: 1,
                },
                {
                    text: 'Inactive',
                    value: 0,
                },
            ],
            onFilter: (value, record) => record.is_active === value,
            filterSearch: false,
            render: (text, record) => {
                return (
                    <>
                        <div className='d-flex justify-content-center hover'>
                            <div>

                                <Fragment>
                                    <Link className="social-link btn btn-sm btn-light-info text-info d-flex btn-circle
                                align-items-center justify-content-center" onClick={(e) => {
                                            fetchdetails(record)
                                            setEdit(true)
                                        }}>
                                        <Tooltip title="Click here to Edit" color='blue'>
                                            <EditOutlined /></Tooltip>
                                    </Link>
                                </Fragment>
                            </div>
                            <div style={{ marginLeft: '10%' }}>
                                <Fragment>
                                    {record.is_active === 1 ?
                                        <Link className="social-link btn btn-sm btn-light-success text-success btn-circle d-flex align-items-center justify-content-center" onClick={(e) => { statuschange(record) }}>
                                            <Tooltip title="Click here to Deactivate" color='#39cb7f'>
                                                <LikeOutlined /></Tooltip></Link> :
                                        <Link className="social-link btn btn-sm btn-light-danger text-danger btn-circle d-flex align-items-center justify-content-center" onClick={(e) => { statuschange(record) }}>
                                            <Tooltip title="Click here to Activate" color='#fc4b6c'>
                                                <DislikeOutlined /></Tooltip></Link>
                                    }
                                </Fragment>
                            </div>

                        </div>
                    </>
                )
            },
            sortable: false,
        },
    ];
    const columns1 = [
        {
            title: '#',
            key: 'index',
            align: 'center',
            width: '30px',
            render: (text, record, index) => {
                return (
                    (page - 1) * paginationSize + index + 1
                )
            },
        },
        {
            title: <><span className="btn btn-sm btn-danger text-white btn-circle d-flex align-items-center justify-content-center">
                <i className="ri-user-unfollow-fill"></i></span><span style={{ marginTop: '3px', marginLeft: '5px' }}>InActive User GroupName</span></>,
            dataIndex: 'u_g_name',
            key: 'u_g_name',
            width: '200px',
            ellipsis: 'true',
            // sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('u_g_name', 'User Group Name'),
            // sorter: (a, b) => a.u_g_name.length - b.u_g_name.length
            sorter: (a, b) => a.u_g_name.localeCompare(b.u_g_name)

        },
        {
            title: 'Category Name',
            dataIndex: 'u_c_name',
            key: 'u_c_name',
            width: '155px',
            // sortDirections: ['descend', 'ascend'],
            filters: [
                {
                    text: 'Client Executive',
                    value: 'Client Executive',
                },
                {
                    text: 'Domain Admin',
                    value: 'Domain Admin',
                },
                {
                    text: 'Legal Entity Admin',
                    value: 'Legal Entity Admin',
                },
                {
                    text: 'Service Provider User',
                    value: 'Service Provider User',
                },
                {
                    text: 'View Only',
                    value: 'View Only',
                },
                {
                    text: 'View Only Restricted',
                    value: 'View Only Restricted',
                }
            ],
            onFilter: (value, record) => record.u_c_name.indexOf(value) === 0,
            // sorter: (a, b) => a.u_c_name.length - b.u_c_name.length
            sorter: (a, b) => a.u_c_name.localeCompare(b.u_c_name)

        },
        {
            // title: <><label>Actions</label><Tooltip className='serviveproidertooltipclass' style={{ marginBotton: '5px', marginleft: '5px' }} placement="topRight" title={"This Action Includes Edit, Active/Inactive Status Change"}><InfoCircleOutlined /></Tooltip></>,
            title: <><label>Actions</label></>,
            dataIndex: 'u_c_name',
            align: 'center',
            width: '73px',
            // filters: [
            //     {
            //         text: 'Active',
            //         value: 1,
            //     },
            //     {
            //         text: 'Inactive',
            //         value: 0,
            //     },
            // ],
            // onFilter: (value, record) => record.is_active === value,
            filterSearch: false,
            render: (text, record) => {
                return (
                    <>
                        <div className='d-flex justify-content-center hover'>
                            <div>

                                <Fragment>
                                    <Link className="social-link btn btn-sm d-flex btn-light-info text-info btn-circle
                                align-items-center justify-content-center" onClick={(e) => {
                                            fetchdetails(record)
                                            setEdit(true)
                                        }}>
                                        <Tooltip title="Click here to Edit" color='blue'>
                                            <EditOutlined /></Tooltip>
                                    </Link>
                                </Fragment>
                            </div>
                            <div style={{ marginLeft: '10%' }}>
                                <Fragment>
                                    {record.is_active === 1 ?
                                        <Link className="social-link btn btn-sm btn-light-success text-success btn-circle d-flex align-items-center justify-content-center" onClick={(e) => { statuschange(record) }}>
                                            <Tooltip title="Click here to Deactivate" color='#39cb7f'>
                                                <LikeOutlined /></Tooltip></Link> :
                                        <Link className="social-link btn btn-sm btn-light-danger text-danger btn-circle d-flex align-items-center justify-content-center" onClick={(e) => { statuschange(record) }}>
                                            <Tooltip title="Click here to Activate" color='#fc4b6c'>
                                                <DislikeOutlined /></Tooltip></Link>
                                    }
                                </Fragment>
                            </div>

                        </div>
                    </>
                )
            },
            sortable: false,
        },
    ];

    useEffect(() => {
        formValidator.current.showMessages()
        formValidator1.current.showMessages();
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetUserPrivileges",
                        {

                        }
                    ]
                }
            ]

            privilegeslist({
                payload: payload,
                paramid: paramid
            })
        }
    }, [isAuth])

    const submitpassword = () => {

        if (formValidator.current.allValid()) {
            const pay = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "ChangeUserPrivilegeStatus",
                        {
                            "u_g_id": passwordata.u_g_id,
                            "is_active": passwordata.is_active,
                            "password": passwordata.passvalue
                        }
                    ]
                }
            ]
            updatestatususerpreviliges({
                payload: pay,
                paramid: paramid,
                type: typedata,
                Setpassvisible: Setpassvisible,
            })
            Setpassvisible(false)
            Setpassword({
                ...passwordata,
                passvalue: '',
                u_g_id: '',
                is_active: ''
            })
        } else {
            setAddFormSubmit(true)
            Setpassvisible(false)
        }

    }

    const categoryChange = (e) => {
        setuserPriviligesDetails({
            ...userPriviligesDetails,
            categoryName: e,
        })
        console.log(e, 'e.target.value')
        if (e == '') {
            setSelectShow(false)
        } else {
            setSelectShow(true)
            setTemparr([])
            setCheckedRow(false)
            var eleall = document.getElementsByName('allcheck');
            eleall[0].checked = false;
            var ele = document.getElementsByName('checked');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = false;
            }

        }


    }

    useEffect(() => {
        let tempPush = [];
        if (list.user_category && list.user_category.length > 0) {
            list.user_category.map((categList) => {
                if (categList.u_c_id != 1) {
                    tempPush.push({
                        'u_c_id': categList.u_c_id,
                        'u_c_name': categList.u_c_name
                    })
                }

            })
            setUserCategoryNameList([...tempPush])
        }
    }, [list])

    useEffect(() => {
        if (formsResponse && formsResponse[userPriviligesDetails.categoryName] != null) {
            let menu = Object.values(formsResponse[userPriviligesDetails.categoryName].menus);
            setmenuList(menu);
            //convert array of array into single array
            var merged = [].concat.apply([], menu);
            settempSubMenu(merged);
        }
    }, [formsResponse && formsResponse[userPriviligesDetails.categoryName]])

    const addAllSelectedDataValues = (checked) => {
        let tempp = []
        if (checked == true) {
            setCheckedRow(true)
            setTemparr([...tempSubMenu])
            var ele = document.getElementsByName('checked');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = true;
            }
        } else {
            setCheckedRow(false)
            setTemparr([])
            var ele = document.getElementsByName('checked');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = false;
            }
        }
    }
    const addSelectedDataValues = (checked, form_id, subMenuList) => {
        let array
        let tempArray = temparr
        var ele = document.getElementsByName('allcheck');
        if (checkedRow == true) {
            if (checked == true) {
                array = _.find(subMenuList, { form_id: form_id });
                tempArray.push(array)
            } else {
                setCheckedRow(false)
                for (let i in tempArray) {
                    if (tempArray[i].form_id == form_id) {
                        tempArray.splice(i, 1)
                    }
                }
                ele[0].checked = false
            }
            setTemparr([...tempArray])
        } else {
            setCheckedRow(false)
            if (checked == true) {
                array = _.find(subMenuList, { form_id: form_id });
                tempArray.push(array)
                if (tempSubMenu.length == tempArray.length) {
                    ele[0].checked = true
                }
            } else {
                for (let i in tempArray) {
                    if (tempArray[i].form_id == form_id) {
                        tempArray.splice(i, 1)
                    }
                }
                ele[0].checked = false
            }
            setTemparr([...tempArray])
        }
    }

    const editAllSelectedDataValues = (checked) => {
        let tempp = []
        if (checked == true) {
            setCheckedRow(true)
            let tempSubMenuState = tempSubMenu
            for (let i in tempSubMenuState) {
                tempp.push(tempSubMenuState[i].form_id)
            }
            setTemparr(tempp)
            var ele = document.getElementsByName('checked');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = true;
            }
        } else {
            setCheckedRow(false)
            setTemparr([])
            var ele = document.getElementsByName('checked');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = false;
            }
        }
    }

    const editSelectedDataValues = (checked, form_id, subMenuList) => {

        let array
        let tempArray = temparr
        if (checkedRow == true) {
            if (checked == true) {
                array = _.find(subMenuList, { form_id: form_id });
                tempArray.push(array)
            } else {
                setCheckedRow(false)
                for (let i in tempArray) {
                    if (tempArray[i].form_id == form_id) {
                        tempArray.splice(i, 1)
                    }
                }
            }
            setTemparr([...tempArray])
        } else {
            setCheckedRow(false)
            if (checked == true) {
                array = _.find(subMenuList, { form_id: form_id });
                let temporaryArray = array.form_id
                // setTemporary([...temporary,...temporaryArray])
                tempArray.push(temporaryArray)
            } else {
                console.log(checked, 'inserting into')
                for (let i in tempArray) {
                    if (tempArray[i] == form_id) {
                        tempArray.splice(i, 1)
                    }
                }
            }
            setTemparr([...tempArray])
        }
    }

    const submitrec = (e) => {
        e.preventDefault();
        setPopupFormSubmit(true)
        let temp = []
        for (let i in temparr) {
            temp.push(temparr[i].form_id)
        }


        if (formValidator1.current.allValid()) {
            if (temp != null && temp != '') {

                const savepayload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "SaveUserPrivileges",
                            {
                                "u_g_name": userPriviligesDetails.userGroupName,
                                "u_c_id": parseInt(userPriviligesDetails.categoryName),
                                "f_ids": temp
                            }
                        ]
                    }
                ]
                saveuserpreviliges({
                    payload: savepayload,
                    paramid: paramid
                })
            } else {
                Toaster.error('Select atleast one form from list')
            }

        }
    }


    const updatePrivilege = (e) => {
        e.preventDefault();
        setPopupFormSubmit(true)

        if (formValidator1.current.allValid()) {
            if (temparr != null && temparr != '') {
                const savepayload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "UpdateUserPrivileges",
                            {
                                "u_g_id": userPriviligesDetails.u_g_id,
                                "u_g_name": userPriviligesDetails.userGroupName,
                                "u_c_id": parseInt(userPriviligesDetails.categoryName),
                                "f_ids": temparr
                            }
                        ]
                    }
                ]
                updateUserPrivileges({
                    payload: savepayload,
                    paramid: paramid
                })
            } else {
                Toaster.error('Select atleast one form from list')
            }

        }
    }

    const [baseData, setBaseData] = useState([]);
    const [baseData1, setBaseData1] = useState([]);

    useEffect(() => {
        if (list && list.user_groups.length > 0) {
            let array = _.filter(list.user_groups);
            setBaseData(array);
        }
    }, [list])

    useEffect(() => {
        if (tempSubMenu) {
            let temp = []
            let array = _.filter(tempSubMenu, { form_type__form_type: "Dashboard" });
            if (array.length > 0) {
                temp.push([...array])
            }
            let array1 = _.filter(tempSubMenu, { form_type__form_type: "Master" });
            if (array1.length > 0) {
                temp.push([...array1])
            }
            let array2 = _.filter(tempSubMenu, { form_type__form_type: "Transaction" });
            if (array2.length > 0) {
                temp.push([...array2])
            }
            let array3 = _.filter(tempSubMenu, { form_type__form_type: "Report" });
            if (array3.length > 0) {
                temp.push([...array3])
            }
            setFinalmenu([...temp])
        }

    }, [tempSubMenu])

    const searchRecords = (e) => {
        if (e == 'clear') {
            setClearText('')
            setBaseData(list.user_groups)
            setIconEnable(false)
        } else {
            setClearText(e.target.value)
            if (e.target.value.length > 0) {
                setIconEnable(true)
            } else {
                setIconEnable(false)
            }
            const filterTable = list.user_groups.filter(o =>
                Object.keys(o).some(k =>
                    String(o[k])
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            );
            setBaseData(filterTable)
        }

    };

    const homescreen = () => {
        window.location.href = '/dashboard'
    }
    //  Full screen mode //
    const [fullscreen, setfullscreen] = useState(false);
    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', handleKeyDown, false);
            document.addEventListener('webkitfullscreenchange', handleKeyDown, false);
            return () => {
                document.addEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('webkitfullscreenchange', handleKeyDown);
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode();
            setfullscreen(true);
        } else {
            fullscreenexit();
            setfullscreen(false);
        }
    }



    return (
        <Fragment>
            <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} />
            <div id='page-wrapper' className="page-wrapper">
                <div className="page-titles pb-0 pt-0">
                    <div className="row">
                        <div className="col-lg-4 col-md-2 col-12 align-self-center" style={{ padding: 0 }}>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                    </li>
                                    <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                        <span style={{ fontSize: '16px' }}>Master</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        <span>User Privileges </span>
                                    </li>
                                </ol>
                            </nav>

                        </div>
                        <div className="col-lg-8 col-md-6 py-2" style={{ padding: 0 }}>

                            <div className="search-box" style={{ position: 'absolute', right: '13%', marginRight: '0 !important', width: '240px', boxShadow: ' 0 5px 5px rgb(0 0 0 / 10%)' }} >
                                <input className="search-txt" value={clearText} type="text" onChange={searchRecords} placeholder="Type to Search" />
                                <a className="search-btn" style={{ position: 'absolute', right: 1 }} >
                                    {iconEnable == true ?
                                        <i className="fas fa-close" onClick={(e) => { searchRecords('clear') }}></i>
                                        : <i className="fas fa-search"></i>}
                                </a>
                            </div>

                            <button style={{ float: 'right', marginTop: '5px' }} onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                                <Tooltip placement="left" title="Full Screen Mode"><FullscreenOutlined /></Tooltip>
                            </button>

                            <Button style={{ float: 'right' }} type="primary" shape="round"
                                icon={<PlusCircleOutlined />} size='default' onClick={addopen} >
                                Add
                            </Button>


                        </div>
                    </div>
                </div>
                <div className="container-fluid pt-0">
                    <center>
                        <Table
                            sortDirections={["ascend", "descend", "ascend"]}
                            className={localStorage.getItem("currentTheme")}
                            id='user-priv-table'
                            columns={columns}
                            bordered
                            pagination={false}
                            showSorterTooltip={false}
                            dataSource={baseData} />
                    </center>
                </div>
            </div>
            <Modal visible={passvisible} footer={null} onCancel={passwordcancel} className="newStyleModalPassword modelradius" maskClosable={false}>
                <div className="row">
                    <div className="col-md-12">
                        <div>
                            <label className="control-label"><b>Password </b></label>
                            <span style={{ "color": "red" }}>*</span>
                            <input
                                type="password"
                                id="password"
                                name='passwordvalue'
                                className="form-control"
                                placeholder="Enter password"
                                value={passwordata.passvalue}
                                onChange={(e) => {
                                    Setpassword({
                                        ...passwordata,
                                        passvalue: e.target.value

                                    })
                                }}
                            />
                            {formValidator.current.message(
                                'password',
                                passwordata.passvalue,
                                'required',
                                {
                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Password Required',
                                    }
                                })}
                        </div>
                    </div>

                </div>
                <div className="form-actions popupbtncolour" style={{ textAlign: 'center' }}>
                    <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                        style={{ background: "#198754", borderColor: "#198754", marginTop: '5px' }}
                        icon={<PlayCircleOutlined />} size='default'
                        onClick={submitpassword}>
                        Submit
                    </Button>
                </div>
            </Modal>
            {/* <div className={scroll == true ? "d-block" : "d-none"}>
                <Button title="Scroll Back to Top" style={{ position: 'fixed', right: '30px', bottom: '50px', zIndex: 1, width: '34px', display: 'flex', justifyContent: 'center', borderRadius: '15px' }} onClick={() => { scrollup() }}><UpCircleOutlined style={{ fontSize: '1.5rem' }} /></Button>
            </div> */}
            {/* <Modal title="User Privileges Master" visible={modalVisible} footer={null} className={"userprivilegemodel userprivilegemodelview user-priv-modal add-service-prv modelradius " + localStorage.getItem('currentTheme')} onCancel={modalcancel} maskClosable={false}>
                <div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label><b>User Group Name</b><span style={{ color: "red" }}> * </span> </label>
                                    <Input type="text" autoComplete='off' maxLength="50" placeholder=" User Group Name" className='form-control' value={userPriviligesDetails.userGroupName} name="groupName" id="" style={{ marginTop: '5px' }} onChange={(e) => {
                                        setuserPriviligesDetails({
                                            ...userPriviligesDetails,
                                            userGroupName: e.target.value
                                        })
                                    }} />
                                    {formValidator1.current.message(
                                        'category',
                                        userPriviligesDetails.userGroupName,
                                        [
                                            'required',
                                            {
                                                regex: /^[a-zA-Z ]*$/,
                                            },
                                            `max:50`
                                        ],
                                        {
                                            className: `invalid-feedback ${PopupFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'User Group name Required',
                                                regex: 'Enter Valid Name',
                                                max: 'Maximum character is 50 only'
                                            }
                                        })}
                                </div>
                            </div>
                            <div className='col-md-6 '>
                                <div className="form-group">
                                    <label><b>Category Name</b><span style={{ color: "red" }}> * </span> </label>
                                    <Select name="categoryName" className='form-control' value={userPriviligesDetails.categoryName ? userPriviligesDetails.categoryName : ''} style={{ marginTop: '5px' }}
                                        onChange={categoryChange}>
                                        <Option value=''>Select Category</Option>
                                        {userCategoryNameList && userCategoryNameList.length > 0 &&
                                            userCategoryNameList.map((categoryList) => (
                                                <Option value={categoryList.u_c_id} >{categoryList.u_c_name}</Option>
                                            ))}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <br />
                        {finalMenu && finalMenu.length > 0 ?
                            <div>
                                <div className='ant-table edit-user-priv ant-table-bordered' style={{ display: selectShow ? 'block' : 'none' }} >
                                    <div className='ant-table-container'>
                                        <div className='ant-table-content tableFixHead'>
                                            <table style={{ tableLayout: 'auto' }}>
                                                <thead className='ant-table-thead'>
                                                    <tr>
                                                        <th className='ant-table-cell custom-width'>
                                                            <center><input type="checkbox" name="allcheck" onClick={(e) => {
                                                                let checked = e.target.checked;
                                                                addAllSelectedDataValues(
                                                                    checked
                                                                );
                                                            }} /></center></th>
                                                        <th className='ant-table-cell' id="textalignleft">Form Name</th>
                                                        <th className='ant-table-cell'><center>Compfie</center></th>
                                                        <th className='ant-table-cell'><center>Compfie Vendor</center></th>
                                                    </tr>
                                                </thead>
                                                <tbody className='ant-table-tbody'>
                                                    {finalMenu && finalMenu.length > 0 && finalMenu.map((subMenuList) => {
                                                        console.log(subMenuList, "subMenuList555");
                                                        const arr = [];
                                                        const pmenus = subMenuList.map(function (user) {
                                                            if ((arr.indexOf(user.form_type__form_type) < 0))
                                                                arr.push(user.form_type__form_type)
                                                        });
                                                        {
                                                            return arr.map((pmenu) => {
                                                                console.log(pmenu, "pmenupmenu777");
                                                                if (pmenu) {
                                                                    return <Fragment><tr className='ant-table-row ant-table-row-level-0'>
                                                                        <td colSpan={5} className='ant-table-cell'><b>{pmenu}</b></td>
                                                                    </tr>
                                                                        {subMenuList && subMenuList.map((subMenuChildList, sl) => {
                                                                            return <tr className='ant-table-row ant-table-row-level-0' key={sl}>
                                                                                <td className='ant-table-cell custom-width'><center><input type="checkbox" name="checked"
                                                                                    onClick={(e) => {
                                                                                        let checked = e.target.checked;
                                                                                        addSelectedDataValues(
                                                                                            checked,
                                                                                            subMenuChildList.form_id,
                                                                                            subMenuList,
                                                                                        );
                                                                                    }} /></center></td>
                                                                                <td className='ant-table-cell fs'>{subMenuChildList.form_name}</td>
                                                                                <td className='ant-table-cell fs'>{(subMenuChildList.category_id) ? <center><span
                                                                                    className="btn btn-sm btn-light-success text-success btn-circle d-flex align-items-center justify-content-center">
                                                                                    <i className="ri-check-line"></i>
                                                                                </span></center> : <center><span className="btn btn-sm btn-light-danger text-danger btn-circle d-flex align-items-center justify-content-center">
                                                                                    <i className="ri-close-line"></i>
                                                                                </span></center>}</td>
                                                                                <td className='ant-table-cell fs'>{(subMenuChildList.vndr_category_id) ? <center><span
                                                                                    className="btn btn-sm btn-light-success text-success btn-circle d-flex align-items-center justify-content-center">
                                                                                    <i className="ri-check-line"></i>
                                                                                </span></center> : <center><span
                                                                                    className="btn btn-sm btn-light-danger text-danger btn-circle d-flex align-items-center justify-content-center"
                                                                                >
                                                                                    <i className="ri-close-line"></i>
                                                                                </span></center>}</td>
                                                                            </tr>
                                                                        })}
                                                                    </Fragment>
                                                                }
                                                            })
                                                        }
                                                    })}
                                                </tbody>
                                            </table>

                                        </div>

                                    </div><div className='text-center mt-1 popupbtncolour'><Button type="primary" shape="round"
                                        className={'addbutton ' + localStorage.getItem('currentTheme')}
                                        style={{ background: "#198754", borderColor: "#198754" }}
                                        icon={<PlayCircleOutlined />} size='default'
                                        onClick={submitrec}
                                    >
                                        Submit
                                    </Button>
                                    </div>
                                </div>

                            </div>
                            : ''}

                    </div>
                </div>
            </Modal> */}
            {/* <Modal title="User Privileges Master" visible={editmodalVisible} footer={null} className={"userprivilegemodel user-priv-modal add-service-prv modelradius " + localStorage.getItem('currentTheme')} onCancel={editmodalcancel} maskClosable={false}>
                <div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">

                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className="form-group">
                                            <label><b>User Goup Name</b><span style={{ color: "red" }}> * </span> </label>
                                            <Input placeholder=" User Group Name" maxlength="50" className='form-control' name="groupName"
                                                style={{ marginTop: '5px' }}
                                                value={userPriviligesDetails.userGroupName}
                                                onChange={(e) => {
                                                    setuserPriviligesDetails({
                                                        ...userPriviligesDetails,
                                                        userGroupName: e.target.value
                                                    })
                                                }}
                                            />
                                            {formValidator1.current.message(
                                                'userGroupName',
                                                userPriviligesDetails.userGroupName,
                                                [
                                                    'required',
                                                    { regex: /^[a-zA-Z ]*$/ },
                                                    `max:50`
                                                ],
                                                {
                                                    className: `invalid-feedback ${PopupFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'User Group name Required',
                                                        regex: 'Enter Valid Name',
                                                        max: 'Maximum character is 50 only'
                                                    }
                                                })}

                                        </div>
                                    </div>
                                    <div className='col-md-6 '>
                                        <label><b>Category Name</b></label>
                                        <Input className='form-control'
                                            style={{ marginTop: '5px' }}

                                            value={userPriviligesDetails.c_name}
                                            disabled={true}
                                        />
                                    </div>


                                </div>
                            </div>
                        </div>
                        <br />
                        <div>
                            <div className='ant-table edit-user-priv ant-table-bordered' style={{ display: selectShow ? 'block' : 'none' }} >
                                <div className='ant-table-container'>
                                    <div className='ant-table-content tableFixHead'>
                                        <table style={{ tableLayout: 'auto' }}>
                                            <thead className='ant-table-thead'>
                                                <tr>
                                                    <th className='ant-table-cell custom-width'><center><input type="checkbox"
                                                        checked={(temparr.length == tempSubMenu.length) == true ? true : false}
                                                        onClick={(e) => {
                                                            let checked = e.target.checked;
                                                            editAllSelectedDataValues(
                                                                checked
                                                            );
                                                        }} /></center></th>
                                                    <th className='ant-table-cell' id="textalignleft">Form Name</th>
                                                    <th className='ant-table-cell'><center>Compfie</center></th>
                                                    <th className='ant-table-cell'><center>Compfie Vendor</center></th>
                                                </tr>
                                            </thead>
                                            <tbody className='ant-table-tbody'>
                                                {finalMenu && finalMenu.length > 0 && finalMenu.map((subMenuList) => {
                                                    console.log("trueeeeeee");
                                                    const arr = [];
                                                    const pmenus = subMenuList.map(function (user) {
                                                        if ((arr.indexOf(user.form_type__form_type) < 0))
                                                            arr.push(user.form_type__form_type)
                                                    });
                                                    {
                                                        return arr.map((pmenu) => {
                                                            console.log(pmenu, "pmenupmenu666");
                                                            if (pmenu) {
                                                                return <Fragment><tr className='ant-table-row ant-table-row-level-0'>
                                                                    <td colSpan={5} className='ant-table-cell'><b>{pmenu}</b></td>
                                                                </tr>

                                                                    {subMenuList && subMenuList.length > 0 && subMenuList.map((subMenuChildList, ls) => {
                                                                        return <tr className='ant-table-row ant-table-row-level-0' key={ls}>
                                                                            <td className='ant-table-cell custom-width'><center><input type="checkbox" name="checked" value={checkbox}
                                                                                checked={temparr && temparr.includes(subMenuChildList.form_id) == true ? true : false}
                                                                                onClick={(e) => {
                                                                                    let checked = e.target.checked;

                                                                                    editSelectedDataValues(
                                                                                        checked,
                                                                                        subMenuChildList.form_id,
                                                                                        subMenuList
                                                                                    );
                                                                                }}
                                                                            /></center></td>
                                                                            <td className='ant-table-cell fs'>{subMenuChildList.form_name}</td>
                                                                            <td className='ant-table-cell fs'>{(subMenuChildList.category_id) ?
                                                                                <center><span
                                                                                    className="btn btn-sm btn-light-success text-success btn-circle d-flex align-items-center justify-content-center">
                                                                                    <i className="ri-check-line"></i>
                                                                                </span></center> :
                                                                                <center><span
                                                                                    className="btn btn-sm btn-light-danger text-danger btn-circle d-flex align-items-center justify-content-center"
                                                                                >
                                                                                    <i className="ri-close-line"></i>
                                                                                </span>
                                                                                </center>}</td>
                                                                            <td className='ant-table-cell fs'>{(subMenuChildList.vndr_category_id) ?
                                                                                <center><span
                                                                                    className="btn btn-sm btn-light-success text-success btn-circle d-flex align-items-center justify-content-center">
                                                                                    <i className="ri-check-line"></i>
                                                                                </span></center> : <center><span
                                                                                    className="btn btn-sm btn-light-danger text-danger btn-circle d-flex align-items-center justify-content-center"
                                                                                >
                                                                                    <i className="ri-close-line"></i>
                                                                                </span></center>}</td>
                                                                        </tr>
                                                                    })}
                                                                </Fragment>
                                                            }
                                                        })
                                                    }
                                                })}
                                            </tbody>
                                        </table>
                                        <div className='text-center mt-1 popupbtncolour'>
                                            <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                                                style={{ background: "#198754", borderColor: "#198754" }}
                                                icon={<PlayCircleOutlined />} size='default'
                                                onClick={updatePrivilege}
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </Modal> */}
            <div>
                <Drawer
                    title="User Privileges Master"
                    placement='right'
                    closable={false}
                    height={'100%'}
                    width={600}
                    className={localStorage.getItem("currentTheme") + ' resize-drawer'}

                    onClose={modalcancel}
                    open={opendrawer}
                    // getContainer={false}
                    visible={opendrawer}
                    onCancel={modalcancel} >
                    <div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className='col-md-6'>
                                    <div className="form-group">
                                        <label><b>User Group Name</b><span style={{ color: "red" }}> * </span> </label>
                                        <Input type="text" autoComplete='off' maxLength="50" placeholder=" User Group Name" className='form-control' value={userPriviligesDetails.userGroupName} name="groupName" id="" style={{ marginTop: '5px' }} onChange={(e) => {
                                            setuserPriviligesDetails({
                                                ...userPriviligesDetails,
                                                userGroupName: e.target.value
                                            })
                                        }} />
                                        {formValidator1.current.message(
                                            'category',
                                            userPriviligesDetails.userGroupName,
                                            [
                                                'required',
                                                {
                                                    regex: /^[a-zA-Z ]*$/,
                                                },
                                                `max:50`
                                            ],
                                            {
                                                className: `invalid-feedback ${PopupFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'User Group name Required',
                                                    regex: 'Enter Valid Name',
                                                    max: 'Maximum character is 50 only'
                                                }
                                            })}
                                    </div>
                                </div>
                                <div className='col-md-6 '>
                                    <div className="form-group">
                                        <label><b>Category Name</b><span style={{ color: "red" }}> * </span> </label>
                                        <Select name="categoryName" className='form-control' value={userPriviligesDetails.categoryName ? userPriviligesDetails.categoryName : ''} style={{ marginTop: '5px' }}
                                            onChange={categoryChange}>
                                            <Option value=''>Select Category</Option>
                                            {userCategoryNameList && userCategoryNameList.length > 0 &&
                                                userCategoryNameList.map((categoryList, i) => (
                                                    <Option value={categoryList.u_c_id} key={i}>{categoryList.u_c_name}</Option>
                                                ))}
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <br />
                            {finalMenu && finalMenu.length > 0 ?
                                <div>
                                    <div className='ant-table edit-user-priv ant-table-bordered' style={{ display: selectShow ? 'block' : 'none' }} >
                                        <div className='ant-table-container'>
                                            <div className='ant-table-content tableFixHead1'>
                                                <table style={{ tableLayout: 'auto' }}>
                                                    <thead className='ant-table-thead'>
                                                        <tr>
                                                            <th className='ant-table-cell custom-width'>
                                                                <center><input type="checkbox" name="allcheck" onClick={(e) => {
                                                                    let checked = e.target.checked;
                                                                    addAllSelectedDataValues(
                                                                        checked
                                                                    );
                                                                }} /></center></th>
                                                            <th className='ant-table-cell' id="textalignleft">Form Name</th>
                                                            <th className='ant-table-cell'><center>Compfie</center></th>
                                                            <th className='ant-table-cell'><center>Compfie Vendor</center></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='ant-table-tbody'>
                                                        {finalMenu && finalMenu.length > 0 && finalMenu.map((subMenuList) => {
                                                            console.log(subMenuList, "subMenuList555");
                                                            const arr = [];
                                                            const pmenus = subMenuList.map(function (user) {
                                                                if ((arr.indexOf(user.form_type__form_type) < 0))
                                                                    arr.push(user.form_type__form_type)
                                                            });
                                                            {
                                                                return arr.map((pmenu) => {
                                                                    console.log(pmenu, "pmenupmenu777");
                                                                    if (pmenu) {
                                                                        return <Fragment><tr className='ant-table-row ant-table-row-level-0'>
                                                                            <td colSpan={5} className='ant-table-cell'><b>{pmenu}</b></td>
                                                                        </tr>
                                                                            {subMenuList && subMenuList.map((subMenuChildList, sl) => {
                                                                                return <tr className='ant-table-row ant-table-row-level-0' key={sl}>
                                                                                    <td className='ant-table-cell custom-width'><center><input type="checkbox" name="checked"
                                                                                        onClick={(e) => {
                                                                                            let checked = e.target.checked;
                                                                                            addSelectedDataValues(
                                                                                                checked,
                                                                                                subMenuChildList.form_id,
                                                                                                subMenuList,
                                                                                            );
                                                                                        }} /></center></td>
                                                                                    <td className='ant-table-cell fs'>{subMenuChildList.form_name}</td>
                                                                                    <td className='ant-table-cell fs'>{(subMenuChildList.category_id) ? <center><span
                                                                                        className="btn btn-sm btn-light-success text-success btn-circle d-flex align-items-center justify-content-center">
                                                                                        <i className="ri-check-line"></i>
                                                                                    </span></center> : <center><span className="btn btn-sm btn-light-danger text-danger btn-circle d-flex align-items-center justify-content-center">
                                                                                        <i className="ri-close-line"></i>
                                                                                    </span></center>}</td>
                                                                                    <td className='ant-table-cell fs'>{(subMenuChildList.vndr_category_id) ? <center><span
                                                                                        className="btn btn-sm btn-light-success text-success btn-circle d-flex align-items-center justify-content-center">
                                                                                        <i className="ri-check-line"></i>
                                                                                    </span></center> : <center><span
                                                                                        className="btn btn-sm btn-light-danger text-danger btn-circle d-flex align-items-center justify-content-center"
                                                                                    >
                                                                                        <i className="ri-close-line"></i>
                                                                                    </span></center>}</td>
                                                                                </tr>
                                                                            })}
                                                                        </Fragment>
                                                                    }
                                                                })
                                                            }
                                                        })}
                                                    </tbody>
                                                </table>

                                            </div>

                                        </div><div className='text-center mt-1 popupbtncolour'><Button type="primary" shape="round"
                                            className={'addbutton ' + localStorage.getItem('currentTheme')}
                                            style={{ position: 'fixed', right: '24px', bottom: 0, margin: '5px', marginRight: '100px' }}
                                            icon={<PlayCircleOutlined />} size='default'
                                            onClick={submitrec}
                                        >
                                            Submit
                                        </Button>
                                        </div>
                                    </div>

                                </div>
                                : ''}

                        </div>
                        <div className={localStorage.getItem('currentTheme')}>
                            <Button style={{ position: 'absolute', bottom: 0, margin: '5px', right: 0.5 }} onClick={modalcancel}>
                                <><PlusCircleOutlined style={{ transform: 'rotate(45deg)', marginRight: '4px' }} /> </> Cancel
                            </Button>
                        </div>
                    </div>
                </Drawer>
            </div>
            <div>
                <Drawer
                    title="User Privileges Master"
                    placement='right'
                    closable={false}
                    height={'100%'}
                    width={600}
                    onClose={editmodalcancel}
                    open={editdrawer}
                    // getContainer={false} 
                    className={localStorage.getItem("currentTheme")}
                    visible={editdrawer}
                    onCancel={editmodalcancel} >
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className="form-group">
                                            <label><b>User Goup Name</b><span style={{ color: "red" }}> * </span> </label>
                                            <Input placeholder=" User Group Name" maxlength="50" className='form-control' name="groupName"
                                                style={{ marginTop: '5px' }}
                                                value={userPriviligesDetails.userGroupName}
                                                onChange={(e) => {
                                                    setuserPriviligesDetails({
                                                        ...userPriviligesDetails,
                                                        userGroupName: e.target.value
                                                    })
                                                }}
                                            />
                                            {formValidator1.current.message(
                                                'userGroupName',
                                                userPriviligesDetails.userGroupName,
                                                [
                                                    'required',
                                                    { regex: /^[a-zA-Z ]*$/ },
                                                    `max:50`
                                                ],
                                                {
                                                    className: `invalid-feedback ${PopupFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'User Group name Required',
                                                        regex: 'Enter Valid Name',
                                                        max: 'Maximum character is 50 only'
                                                    }
                                                })}

                                        </div>
                                    </div>
                                    <div className='col-md-6 '>
                                        <label><b>Category Name</b></label>
                                        <Input className='form-control'
                                            style={{ marginTop: '5px' }}

                                            value={userPriviligesDetails.c_name}
                                            disabled={true}
                                        />
                                    </div>


                                </div>
                            </div>
                        </div>
                        <br />
                        <div>
                            <div className='ant-table edit-user-priv ant-table-bordered' style={{ display: selectShow ? 'block' : 'none' }} >
                                <div className='ant-table-container'>
                                    <div className='ant-table-content tableFixHead1'>
                                        <table style={{ tableLayout: 'auto' }}>
                                            <thead className='ant-table-thead'>
                                                <tr>
                                                    <th className='ant-table-cell custom-width'><center><input type="checkbox"
                                                        checked={(temparr.length == tempSubMenu.length) == true ? true : false}
                                                        onClick={(e) => {
                                                            let checked = e.target.checked;
                                                            editAllSelectedDataValues(
                                                                checked
                                                            );
                                                        }} /></center></th>
                                                    <th className='ant-table-cell' id="textalignleft">Form Name</th>
                                                    <th className='ant-table-cell'><center>Compfie</center></th>
                                                    <th className='ant-table-cell'><center>Compfie Vendor</center></th>
                                                </tr>
                                            </thead>
                                            <tbody className='ant-table-tbody'>
                                                {finalMenu && finalMenu.length > 0 && finalMenu.map((subMenuList) => {
                                                    console.log("trueeeeeee");
                                                    const arr = [];
                                                    const pmenus = subMenuList.map(function (user) {
                                                        if ((arr.indexOf(user.form_type__form_type) < 0))
                                                            arr.push(user.form_type__form_type)
                                                    });
                                                    {
                                                        return arr.map((pmenu) => {
                                                            console.log(pmenu, "pmenupmenu666");
                                                            if (pmenu) {
                                                                return <Fragment><tr className='ant-table-row ant-table-row-level-0'>
                                                                    <td colSpan={5} className='ant-table-cell'><b>{pmenu}</b></td>
                                                                </tr>

                                                                    {subMenuList && subMenuList.length > 0 && subMenuList.map((subMenuChildList, ls) => {
                                                                        return <tr className='ant-table-row ant-table-row-level-0' key={ls}>
                                                                            <td className='ant-table-cell custom-width'><center><input type="checkbox" name="checked" value={checkbox}
                                                                                checked={temparr && temparr.includes(subMenuChildList.form_id) == true ? true : false}
                                                                                onClick={(e) => {
                                                                                    let checked = e.target.checked;

                                                                                    editSelectedDataValues(
                                                                                        checked,
                                                                                        subMenuChildList.form_id,
                                                                                        subMenuList
                                                                                    );
                                                                                }}
                                                                            /></center></td>
                                                                            <td className='ant-table-cell fs'>{subMenuChildList.form_name}</td>
                                                                            <td className='ant-table-cell fs'>{(subMenuChildList.category_id) ?
                                                                                <center><span
                                                                                    className="btn btn-sm btn-light-success text-success btn-circle d-flex align-items-center justify-content-center">
                                                                                    <i className="ri-check-line"></i>
                                                                                </span></center> :
                                                                                <center><span
                                                                                    className="btn btn-sm btn-light-danger text-danger btn-circle d-flex align-items-center justify-content-center"
                                                                                >
                                                                                    <i className="ri-close-line"></i>
                                                                                </span>
                                                                                </center>}</td>
                                                                            <td className='ant-table-cell fs'>{(subMenuChildList.vndr_category_id) ?
                                                                                <center><span
                                                                                    className="btn btn-sm btn-light-success text-success btn-circle d-flex align-items-center justify-content-center">
                                                                                    <i className="ri-check-line"></i>
                                                                                </span></center> : <center><span
                                                                                    className="btn btn-sm btn-light-danger text-danger btn-circle d-flex align-items-center justify-content-center"
                                                                                >
                                                                                    <i className="ri-close-line"></i>
                                                                                </span></center>}</td>
                                                                        </tr>
                                                                    })}
                                                                </Fragment>
                                                            }
                                                        })
                                                    }
                                                })}
                                            </tbody>
                                        </table>
                                        <div className='text-center mt-1 popupbtncolour'>
                                            <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                                                style={{ position: 'fixed', right: '24px', bottom: 0, margin: '5px', marginRight: '100px' }}

                                                icon={<PlayCircleOutlined />} size='default'
                                                onClick={updatePrivilege}
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className={localStorage.getItem('currentTheme')}>
                            <Button style={{ position: 'absolute', bottom: 0, margin: '5px', right: 0.5 }} onClick={editmodalcancel}>
                                <><PlusCircleOutlined style={{ transform: 'rotate(45deg)', marginRight: '4px' }} /> </> Cancel
                            </Button>
                        </div>
                    </div>

                </Drawer>
            </div>


        </Fragment>
    )

}

export default connect(mapStateToProps, {
    privilegeslist,
    updatestatususerpreviliges,
    saveuserpreviliges,
    updateUserPrivileges
})(Userprivilegeslist)

