import { Fragment, useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory, Link } from 'react-router-dom'
import { PlusOutlined, ExclamationCircleTwoTone, BookTwoTone, ArrowRightOutlined, ArrowLeftOutlined, PlayCircleOutlined, SearchOutlined, FilterFilled, PlusCircleOutlined, DownOutlined, EyeOutlined, FullscreenOutlined, UpOutlined, FilterOutlined } from '@ant-design/icons';
import { Modal, Input, Card, Collapse, Tabs, Select, Tooltip, Table, Button, Space } from 'antd';
import DataTable from "react-data-table-component";
import SimpleReactValidator from "simple-react-validator";
import { reassignlist, reassignunitlist, reassigndatatable, getusercomplaince, savereassigncomplaince, showmoredata } from './../../Store/Action/Transcations/ReassignComplaince'
import { GetRemarksData } from './../../Store/Action/Transcations/Taskrecall'
import { toast } from 'react-toastify';
import moment from 'moment';
import ScrollToTop from "react-scroll-to-top";
import FeatherIcon from 'feather-icons-react';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";



const _ = require("lodash");
const mapStateToProps = (state) => ({
    lists: state.ReassignComplaince.reassigndata,
    remarks: state.recallfilters.recallfilters
})
const ReassignCompliance = ({
    reassignlist, reassignunitlist, reassigndatatable, getusercomplaince, savereassigncomplaince, GetRemarksData, showmoredata,
    lists: { reassigndatalist, reassignunitdata, reassigndatatablelist, reassignusercompliance, savereassigndatalist, showmorelist },
    remarks: { recallremarks }
}) => {

    const history = useHistory();
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const validator = useRef(new SimpleReactValidator());
    const validator1 = useRef(new SimpleReactValidator());
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [AddSubmitForm, setAddSubmitForm] = useState(false);
    const [temparr, setTemparr] = useState([])
    const [legalstate, Setlegalstate] = useState(false)
    const [unitcategory, Setunitcategory] = useState([])
    const [selectedRows, setSelectedRows] = useState([]);
    const [unitid, Setunitid] = useState([])
    const [childlist, Setchildlist] = useState([])
    const [checkrow, Setcheckrow] = useState([])
    const [remarksvalue, Setremarksvalue] = useState();
    const [page, setPage] = useState(1);
    const [samplepage, setPagesample] = useState("1");
    const [key, setKey] = useState("1")
    const { TabPane } = Tabs;
    const { Search } = Input;
    const { Option } = Select
    const [tab, setTab] = useState(false)
    const { Panel } = Collapse;
    const [titledata, Settitledata] = useState([]);
    const [checkboxtitle, Setcheckboxtitle] = useState([])
    console.log(checkboxtitle, "checkboxtitlecheckboxtitle");
    const [remarksModal, setRemarksModal] = useState(false);
    const [checkedRow, setCheckedRow] = useState(false)
    const [checkedRow1, setCheckedRow1] = useState([])
    const [Assignpayload, setAssignPayload] = useState([])
    const [entires, setEntires] = useState(null)
    const [Entriescount, SetEntriescount] = useState(0)
    const [datacount, Setdatacount] = useState([])
    const [countvalue, setCountvalue] = useState(false)
    const [showmore1, setShowMore1] = useState(false)
    const [showmoredatalist, setShowMoreDataList] = useState([])
    const [trigger, setTrigger] = useState([{
        comp_id: '',
    }])
    const [updateduedate, setUpdateduedate] = useState([])
    const [rowcompid, setRowCompId] = useState([])
    const [diffid, setDiffId] = useState([])
    const [recalldata, Setrecalldata] = useState({
        reasons: ''
    })
    const [categorylist, Setcategorylist] = useState({
        employee_name: '',
        employee_code: ''
    })
    const [assigneelist, Setassigneelist] = useState([])
    const [allassigneelist, Setallassigneelist] = useState([])
    const [allassigneecode, Setallassigneecode] = useState([])

    const [finalassignee, Setfinalassignee] = useState([])
    const [finalallassignee, Setfinalallassignee] = useState([])
    const [finalallassigneecode, Setfinalallassigneecode] = useState([])
    const [newassigneelist, Setnewasigneelist] = useState([])
    const [newassigneelistshow, Setnewasigneelistshow] = useState([])
    const [newassigneelistshowData, SetnewasigneelistshowData] = useState([])
    const [dataa, setDataa] = useState({
        d_id: '',
        usr_id: '',
        u_ids: '',
        emp_code: '',
        s_u_name: '',
        employee_name: '',
        usr_name: '',
        legalentity: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
        d_name: '',
        user_type: '',
        unit_name: '',
        su_name: '',
        su_num: '',
        su_id: ''
    })

    const [filteredArray, setFilteredArray] = useState([])
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [exitCollapse, setExitCollapse] = useState(false);


    const [userType, SetuserType] = useState("ALL")
    const [showTable, SetshowTable] = useState(false)
    const [entityid, setCurrentEntity] = useState("")
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 50,
        sizechanger: true,
        pageSizeOptions: ['10', '30', '50']
    })
    const [triggerstate, setTriggerstate] = useState(false)
    const [collapseAssignee, setCollapseAssignee] = useState(0)
    const [collapseAssignee1, setCollapseAssignee1] = useState(false)

    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != "null") {
            setCurrentEntity(localStorage.getItem('SelectedEntityid').replaceAll('"', ''))
        }
    }, [localStorage.getItem('SelectedEntityid')])
    const handleOk = () => {
        setRemarksModal(false)
    };
    const handleCancel = () => {
        setRemarksModal(false)
    };
    const previous = () => {
        setTab(false)
        Setcheckboxtitle([])
        Settitledata([])
        Setdatacount([])
        setCheckedRow1([])
        setTemparr([])
        setCollapseAssignee('0')
        setCollapseAssignee1(false)
    }
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        setSelectedKeys(e.target.value ? [e.target.value] : [])
        confirm({ closeDropdown: false });
        // setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div>
                <Input
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    value={selectedKeys[0]}
                    // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}

                />

            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });

    const showmore = () => {
        setShowMore1(true)
        const reassignshowlist = [
            authtoken,
            {
                "session_token": authtoken,

                "request":
                    [
                        "GetReAssignComplianceForUnits",
                        {
                            "le_id": entityid,
                            "d_id": Number(dataa.d_id),
                            "usr_id": Number(dataa.usr_id),
                            "user_type_id": reassignunitdata && reassignunitdata.reassign_units && reassignunitdata.reassign_units[0] && reassignunitdata.reassign_units[0].user_type_id,
                            "u_ids": checkrow,
                            "r_count": entires
                        }
                    ]
            }
        ]
        showmoredata({
            payload: reassignshowlist,
            paramid: paramid
        })
    }
    useEffect(() => {
        let tempArr = []
        for (let k in childlist) {
            for (let n in childlist[k]) {
                if (childlist[k][n] && childlist[k][n].length > 0) {
                    tempArr.push(...childlist[k][n])
                    Setdatacount(tempArr)
                }
            }
        }
    }, [childlist])



    useEffect(() => {
        let ss = updateduedate.map((item) => {
            return item.comp_id
        })
        var difference = rowcompid.filter(x => ss.indexOf(x) === -1);
        let finaldata = [...new Set(difference)]
        setDiffId(finaldata)
    }, [updateduedate, rowcompid])

    const ontableChange = (e, i) => {
        let propertyName = e.target.name;
        let inputValue = e.target.value;
        let newArray = trigger;
        for (let j in newArray) {
            if (i == j) {
                newArray[j][propertyName] = inputValue;
                break;
            }
        }
        setTrigger([...newArray]);
    }
    const Onsubmit2 = () => {
        const getuserassigncompliance = [
            authtoken,
            {
                "session_token": authtoken,
                "request":
                    [
                        "GetUserToAssignCompliance",
                        {
                            "d_id": Number(dataa.d_id),
                            "u_ids": [unitid],
                            "le_id": entityid
                        }
                    ]
            }
        ]
        if (temparr.length > 0) {
            getusercomplaince({
                payload: getuserassigncompliance,
                paramid: paramid
            })
            setKey("2")
            setTab(true)
            window.scrollTo(0, 0);
            setCollapseAssignee('1')
            setCollapseAssignee1(true)
        }
        else {
            toast.warning("No Compliance selected for Reassign")
        }
    }
    const Onsubmit1 = () => {
        Setdatacount([])
        Settitledata([])
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setExitCollapse(true);
            setFilterTaskAcc(false);
        
            setFilterModalVisible(false);
            const reassigntableAPI = [
                authtoken,
                {
                    "session_token": authtoken,

                    "request":
                        [
                            "GetReAssignComplianceUnits",
                            {
                                "le_id": entityid,
                                "d_id": Number(dataa.d_id),
                                "usr_id": Number(dataa.usr_id),
                                "user_type_id": Number(dataa.user_type),
                                "unit_id": null,
                            }
                        ]

                }
            ]
            reassignunitlist({
                payload: reassigntableAPI,
                paramid: paramid
            })
            Setnewasigneelist([])
            Setnewasigneelistshow([])
            SetshowTable(true)
        }
    }
    const OnSubmit = () => {
        Setcheckboxtitle([])
        const reassigncomplainceunit = [
            authtoken,
            {
                "session_token": authtoken,
                "request":
                    [
                        "GetReAssignComplianceForUnits",
                        {
                            "le_id": entityid,
                            "d_id": Number(dataa.d_id),
                            "usr_id": Number(dataa.usr_id),
                            "user_type_id": reassignunitdata && reassignunitdata.reassign_units && reassignunitdata.reassign_units[0] && reassignunitdata.reassign_units[0].user_type_id,
                            "u_ids": checkrow,


                            "r_count": 0
                        }
                    ]
            }
        ]
        if (checkedRow1.length > 0) {
            reassigndatatable({
                payload: reassigncomplainceunit,
                paramid: paramid
            })

            setKey("1")
            setTab(true)
            setCountvalue(true)
        }
        else {
            toast.warning("Select Atleast one unit")
        }

    }


    const Onsubmit3 = () => {
        setAddSubmitForm(true)
        if (validator1.current.allValid() && isAuth) {
            const savereassigncomplianceapi = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request":
                        [
                            "ReassignCompliance",
                            {
                                "le_id": entityid,
                                "r_from": 2,
                                "assignee": dataa.employee_name ? Number(dataa.employee_name) : null,
                                "assignee_name": `${dataa.su_num} - ${dataa.su_name}`,
                                "concurrence_person": null,
                                "approval_person": dataa.employee_name ? Number(dataa.employee_name) : null,
                                "reassigned_compliance": Assignpayload,
                                "reason": recalldata.reasons ? recalldata.reasons : '',
                                "d_id": Number(dataa.d_id),
                                "d_name": dataa.d_name

                            }
                        ]
                }
            ]
            savereassigncomplaince({
                payload: savereassigncomplianceapi,
                paramid: paramid
            })
            setTimeout(() => {
                window.location.reload(false);
            }, 4000);
        }
    }

    useEffect(() => {

        if (temparr.length !== 0) {

            let tempArray = []

            temparr && temparr.length > 0 && temparr.map((item, i) => {
                Setunitid(temparr && temparr[i].u_id,)
                for (let k in updateduedate) {
                    if (temparr[i].comp_id == updateduedate[k].comp_id) {
                        tempArray.push({

                            "u_id": temparr[i].u_id,
                            "comp_id": temparr[i].comp_id,
                            "compliance_name": temparr[i].compliance_name,
                            "c_h_id": temparr[i].c_h_id,
                            "d_date": moment(updateduedate[k].date).format("DD-MMM-YYYY"),
                            "o_assignee": temparr[i].assignee,
                            "o_concurrence_person": null,
                            "o_approval_person": temparr[i].approval_person,
                            "tz_date": temparr[i].tz_date,
                            "ac_id": temparr[i].ac_id,
                            "frequency": temparr[i].frequency,
                            "act": temparr[i].act_name,

                        })
                    }
                }
                for (let x in diffid) {

                    if (temparr[i].comp_id == diffid[x]) {
                        tempArray.push({

                            "u_id": temparr[i].u_id,
                            "comp_id": temparr[i].comp_id,
                            "compliance_name": temparr[i].compliance_name,
                            "c_h_id": temparr[i].c_h_id,
                            "d_date": temparr[i].d_date,
                            "o_assignee": temparr[i].assignee,
                            "o_concurrence_person": null,
                            "o_approval_person": temparr[i].approval_person,
                            "tz_date": temparr[i].tz_date,
                            "ac_id": temparr[i].ac_id,
                            "frequency": temparr[i].frequency,
                            "act": temparr[i].act_name,
                        })
                    }
                }
                if (tempArray != "") {
                    setAssignPayload(tempArray)
                }
            })
        }
    }, [temparr, updateduedate, diffid, unitid])


    const showRemarksModal = () => {
        GetRemarksData({
            payload:
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetRemarksData",
                            {}
                        ]
                    }
                ],
            paramid: paramid
        })
        setRemarksModal(true);
    };
    useEffect(() => {
        if (recallremarks) {
            let temp = [];
            for (let i = 0; i < recallremarks.length; i++) {
                if (recallremarks[i].r_description != '') {
                    temp.push(recallremarks[i])
                }
                Setremarksvalue(temp)
            }
        }

    }, [recallremarks])

    useEffect(() => {
        if (isAuth) {
            if (entityid != '') {
                if (legalstate === true) {
                    const payload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                'GetReassignComplianceFilters', {
                                    "le_id": entityid,
                                }
                            ]
                        }
                    ]
                    if (entityid != "null") {
                        reassignlist({
                            payload: payload,
                            paramid: paramid
                        })
                    }
                    Setlegalstate(false)
                }
            }
        }
    }, [isAuth, entityid, legalstate])

    useEffect(() => {
        validator.current.showMessages()
        validator1.current.showMessages()

    }, [])
    useEffect(() => {
        let unit_category = reassigndatalist && reassigndatalist.legal_entity_users.filter((item) => {
            return item.employee_name == dataa.usr_name
        })
        Setunitcategory(unit_category)
    }, [reassigndatalist.legal_entity_users, dataa])

    useEffect(() => {
        if (checkedRow1 && checkedRow1.length > 0) {
            let temp_uid = []
            for (let i in checkedRow1) {
                temp_uid.push(checkedRow1[i].u_id)

            }
            Setcheckrow([...new Set(temp_uid)])
        }
        else {
            Setcheckrow([])
        }

    }, [checkedRow1.length])

    useEffect(() => {
        let temp = [];
        reassigndatalist.legal_entity_users && reassigndatalist.legal_entity_users.map((item) => {
            console.log(item.user_category_id, "itemcategoryyyyyyyy");
            if (item.user_category_id !== 7) {
                console.log("trueeeeeeeeeeeeee");
                if (userType == "1") {
                    if (item.user_category_id == 5 || item.user_category_id == 6) {
                        temp.push({
                            employee_name: item.employee_name,
                            employee_code: item.user_id
                        })
                    }
                }
                else if (userType == "2") {
                    if (item.user_category_id == 3 || item.user_category_id == 4) {
                        temp.push({
                            employee_name: item.employee_name,
                            employee_code: item.user_id
                        })
                    }
                }
                else if (userType == "3") {
                    if (item.user_category_id == 1 || item.user_category_id == 3 || item.user_category_id == 4) {
                        temp.push({
                            employee_name: item.employee_name,
                            employee_code: item.user_id
                        })
                    }
                }
                else if (userType == "0") {
                    temp.push({
                        employee_name: item.employee_name,
                        employee_code: item.user_id
                    })
                }
            }
        })
        Setcategorylist({
            ...categorylist,
            employee_name: temp,
        },
        )
    }, [reassigndatalist.legal_entity_users, userType, dataa])

    const addAllSelectedDataValues = (checked, currentParId, parentData) => {
        let array
        let tempArray = temparr
        var ele = document.getElementsByName('checked' + currentParId);
        if (checked === true) {
            setCheckedRow(true)
            array = _.filter(datacount, { parentId: currentParId });
            for (let i in array) {
                tempArray.push(array[i])
            }
            console.log(tempArray, 'tempArray');
            setTemparr([...tempArray])
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type === 'checkbox')
                    ele[i].checked = true;
            }
        } else {
            setCheckedRow(false)
            for (var x = tempArray.length - 1; x >= 0; x--) {
                if (tempArray[x].parentId === currentParId) {
                    tempArray.splice(x, 1)
                }
            }
            setTemparr([...tempArray])
            for (var j = 0; j < ele.length; j++) {
                if (ele[j].type === 'checkbox')
                    ele[j].checked = false;
            }
        }
    }
    const addSelectedDataValues = (checked, comp_id, act_name) => {
        var ele = document.getElementsByName('allchecked');
        let array
        let tempArray = temparr
        if (checkedRow === true) {
            if (checked === true) {
                array = _.find(datacount, { comp_id: comp_id });
                tempArray.push(array)
            } else {
                for (let i in tempArray) {
                    if (tempArray[i].comp_id === comp_id) {
                        tempArray.splice(i, 1)
                    }
                    if (datacount.length !== temparr.length) {
                        setCheckedRow(false)
                        for (var j = 0; j < ele.length; j++) {
                            if (ele[j].type === 'checkbox')
                                ele[j].checked = false;
                        }
                    }
                }
            }
            setTemparr([...tempArray])
        } else {
            if (checked === true) {
                array = _.find(datacount, { comp_id: comp_id });
                tempArray.push(array)
                if (datacount.length === temparr.length) {
                    setCheckedRow(true)
                    for (var i = 0; i < ele.length; i++) {
                        if (ele[i].type === 'checkbox')
                            ele[i].checked = true;
                    }
                }

            } else {
                for (let i in tempArray) {
                    if (tempArray[i].comp_id === comp_id) {
                        tempArray.splice(i, 1)
                    }
                }
            }
            setTemparr([...tempArray])
        }
    }


    const addSelectedDataValuesunit = (checked, u_id, comp_id) => {
        var ele = document.getElementsByName('allchecked');
        let array
        let tempArray = checkedRow1
        if (checkedRow === true) {
            if (checked === true) {
                array = _.find(reassignunitdata.reassign_units, { u_id: u_id });
                tempArray.push(array)
            } else {
                for (let i in tempArray) {
                    if (tempArray[i].u_id === u_id) {
                        tempArray.splice(i, 1)
                    }
                    if (reassignunitdata.reassign_units !== checkedRow1.length) {
                        setCheckedRow(false)
                        for (var j = 0; j < ele.length; j++) {
                            if (ele[j].type === 'checkbox')
                                ele[j].checked = false;
                        }
                    }
                }
            }
            setCheckedRow1([...tempArray])
        }
        else {
            if (checked === true) {
                array = _.find(reassignunitdata.reassign_units, { u_id: u_id });
                tempArray.push(array)
                if (reassignunitdata.reassign_units.length === checkedRow1.length) {
                    setCheckedRow(true)
                    for (var i = 0; i < ele.length; i++) {
                        if (ele[i].type === 'checkbox')
                            ele[i].checked = true;
                    }
                }
            } else {
                for (let i in tempArray) {
                    if (tempArray[i].u_id === u_id) {
                        tempArray.splice(i, 1)
                    }
                }
            }
            console.log(tempArray, 'kkkkkkkkkkkkkk');
            setCheckedRow1([...tempArray])
        }
    }
    const addAllSelectedDataValuesunit = (checked, u_id) => {
        console.log(checked, "checked");
        let allArray = [];
        var ele = document.getElementsByName('checked');
        if (checked === true) {
            setCheckedRow(true)
            let temp1 = []
            for (let i in reassignunitdata.reassign_units) {
                temp1.push(reassignunitdata.reassign_units[i])
            }
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type === 'checkbox')
                    ele[i].checked = true;
            }
            setSelectedRows(temp1)
            setCheckedRow1(temp1)
        } else {
            setCheckedRow(false)
            setSelectedRows([])
            for (var j = 0; j < ele.length; j++) {
                if (ele[j].type === 'checkbox')
                    ele[j].checked = false;
            }
            setCheckedRow1([])
        }
    };

    const rowSelection = {
        renderCell: (checked, record, index, originNode) => {
            console.log(record, 'recordddddddddddd');
            return (
                <Fragment>
                    <input type="checkbox"
                        name="checked"
                        onClick={(e) => {
                            let checked = e.target.checked;
                            addSelectedDataValuesunit(
                                checked,
                                record.u_id,
                                // record.childId
                            );
                            setTriggerstate(false)
                        }}
                    />
                </Fragment>
            )
        }

    }





    useEffect(() => {
        let temp = checkboxtitle
        if (showmore1 == true) {
            if (showmoredatalist && showmoredatalist.length > 0) {
                for (let i in showmoredatalist) {
                    temp.push(showmoredatalist[i])
                }
                Setcheckboxtitle([...temp]);
            }
        }
        else {
            for (let i in titledata) {
                temp.push(titledata[i])
            }
            Setcheckboxtitle([...temp]);
        }
    }, [titledata, showmoredatalist])

    useEffect(() => {
        if (checkboxtitle) {
            let tempARR = []
            for (let i in checkboxtitle) {
                tempARR.push(checkboxtitle[i].child)
            }
            Setchildlist(tempARR)
        }
    }, [checkboxtitle])
    useEffect(() => {
        let temp = [];
        let temp1 = [];
        let temp2 = [];
        reassignusercompliance.assign_users && reassignusercompliance.assign_users.map((item) => {
            if (item.is_assignee === true) {
                if (Number(item.s_u_id) === Number(dataa.s_u_name)) {
                    temp.push(`${item.emp_code} - ${item.emp_name}`)
                }
            }
            if (item.is_assignee === true) {
                temp1.push(`${item.emp_code} - ${item.emp_name}`)
                temp2.push(item.emp_code)
            }
            if (temp.length === 0) {
                Setallassigneelist([...temp1])
            }
            else {
                Setallassigneelist([])
            }
            Setassigneelist([...temp])
        })
    }, [dataa.s_u_name])

    useEffect(() => {
        assigneelist && assigneelist.length && assigneelist.map((item) => {
            if (item === dataa.usr_name[0]) {
                let array = _.remove(assigneelist, (item) => item === dataa.usr_name[0])
            }
            Setfinalassignee([...assigneelist])
        })
    }, [assigneelist])

    useEffect(() => {
        if (allassigneelist.length !== 0) {
            allassigneelist && allassigneelist.length && allassigneelist.map((item) => {
                if (item === dataa.usr_name[0]) {
                    let array = _.remove(allassigneelist, (item) => item === dataa.usr_name[0])
                }
                Setfinalallassignee([...allassigneelist])
            })
        }
        else {
            Setfinalallassignee([])
        }
    }, [allassigneelist])

    useEffect(() => {
        if (finalallassignee.length !== 0) {
            let temp = []
            finalallassignee && finalallassignee.length > 0 && finalallassignee.map((item) => {
                const myArray = item.split(" ");
                temp.push(myArray[0])
            })
            Setallassigneecode(temp)
        }
    }, [finalallassignee])
    console.log(reassignusercompliance.assign_users, 'reassignusercompliance.assign_users');
    useEffect(() => {
        if (reassignusercompliance.assign_users && reassignusercompliance.assign_users.length > 0) {
            if (unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 5 || unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 6) {


                let array = _.filter(reassignusercompliance.assign_users, { is_assignee: true });
                console.log(array, 'lllllllarray');
                Setnewasigneelist([array])
            }
            else if (unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 1 || unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 3 || unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 4) {
                let array = _.filter(reassignusercompliance.assign_users, { is_approver: true });
                console.log(array, 'lllllllarray');
                Setnewasigneelist([array])
            }
        }
    }, [reassignusercompliance.assign_users])
    console.log(newassigneelist, 'newassigneelist');
    useEffect(() => {
        let array = []
        let k = newassigneelist
        newassigneelist && newassigneelist.length > 0 && newassigneelist[0].map((item, i) => {
            console.log(item, 'item');
            // if (item.is_assignee === true) {
            let a = `${item.emp_code} - ${item.emp_name}`
            console.log(dataa.usr_name[0], item.emp_code, "innnnnnnnnnn");
            let result = dataa.usr_name[0].includes(item.emp_code);
            console.log(result, 'resultresult');
            if ((`${item.emp_code} - ${item.emp_name}`) == dataa.usr_name[0]) {
                console.log("ggggggggffffffff");
                //  array = _.remove(newassigneelist,(item) => a == dataa.usr_name[0])
                // for(let i in k){
                if (dataa.usr_name[0] == a) {
                    k[0].splice(i, 1)
                }
                // }
                console.log(k, "array1111");
            }
            Setnewasigneelistshow([...k])
        })
    }, [newassigneelist])


    useEffect(() => {
        if (countvalue === true) {
            setEntires(datacount && datacount.length > 0 && datacount.length)
        }
    }, [datacount, countvalue])

    useEffect(() => {
        let temp = 0
        let temparray = 0
        for (let i in checkedRow1) {
            temp = checkedRow1[i].no_of_compliances
            temparray = Number(temparray) + Number(temp)
        }
        SetEntriescount(temparray)

    }, [checkedRow1])


    const columns = [
        {
            title: "Unit",
            dataIndex: false,
            key: false,
            align: 'left',
            render: (record) => {
                return (
                    <div className='row'>
                        <span style={{ marginLeft: '10px' }}>
                            <Tooltip title={`${record.address},${record.postal_code}`}>
                                <ExclamationCircleTwoTone />


                                <span style={{ marginLeft: '3px' }} title={record.u_name}>  &nbsp;{record.u_name}</span>
                            </Tooltip>
                        </span>
                    </div>
                )
            },
            width: '65%',
        },
        {
            title: "No of Compliance Tasks",
            align: 'center',
            render: (record) => record.no_of_compliances,
            sortable: false,
            width: '20%',
        },
        {
            title: "Reassign",
            dataIndex: false,
            key: false,
            align: 'center',
            width: '10%',
            render: (record) => {
                console.log(record, 'recordrecord');
                if (record.reassign) {
                    const obj = {
                        children: <div className='row'>
                            <span >
                                <Button type="primary" shape="round" width="100%" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                    size='default' onClick={OnSubmit}>
                                    Reassign
                                </Button>
                            </span>
                        </div>,
                        props: {}
                    };
                    obj.props.rowSpan = reassignunitdata.reassign_units.length
                    setPagesample("2")
                    return (obj)
                } else return null

            },
        }
        // : {title: "Reassign"}
    ]



    const columns1 = [
        {
            title: <Fragment>
                <input type="checkbox" name="allchecked" />
            </Fragment>,
            dataIndex: false,
            key: false,
            ellipsis: true,
            // fixed: 'left',
            align: 'center',
            render: (row, record, rowIndex) => {
                return (
                    <input type="checkbox"
                        name={'checked' + record.parentId}
                        onClick={(e) => {
                            let temp = []
                            temp.push(record.comp_id)
                            setRowCompId([...rowcompid, ...temp])
                            let checked = e.target.checked;
                            addSelectedDataValues(
                                checked,
                                record.comp_id,
                                record.act_name
                            );
                        }}
                    />

                )
            },
            // fixed: 'left',
            width: "35px",
            ellipsis: false
        },
        {
            title: 'Frequency',
            width: "40px",
            ellipsis: false,
            align: 'center',
            render: (record) => {
                return <div style={{ marginTop: '8px' }}>
                    {record.frequency == 'Review' ? <Tooltip title={record.frequency}><p><i style={{ color: "orange" }} className="bi bi-square-fill"></i></p></Tooltip> :
                        record.frequency == 'Periodical' ? <Tooltip title={record.frequency}><p><i style={{ color: "green" }} className="bi bi-square-fill"></i></p></Tooltip> :
                            record.frequency == 'Flexi Review' ? <Tooltip title={record.frequency}><p><i style={{ color: "pink" }} className="bi bi-square-fill"></i></p></Tooltip> :
                                record.frequency == 'On Occurrence' ? <Tooltip title={record.frequency}><p><i style={{ color: "yellow" }} className="bi bi-square-fill"></i></p></Tooltip> :
                                    <Tooltip title={record.frequency}><p><i style={{ color: "red" }} className="bi bi-square-fill"></i></p></Tooltip>
                    }</div>
            }
        },
        {
            title: "Compliance Task",
            ...getColumnSearchProps('compliance_name', 'Compliance Task'),
            dataIndex: false,
            key: false,
            render: (record) => {
                return (
                    <div className='row'>
                        {record.compfielaw_url ?
                            <span >
                                <Tooltip title={record.compliance_description}>
                                    <ExclamationCircleTwoTone style={{ marginRight: '1px' }} />
                                </Tooltip>
                                <Tooltip title={"Compfie Law Knowledge Portal"}>
                                    <a href={`${record.compfielaw_url}`}>
                                        <BookTwoTone />
                                    </a>
                                </Tooltip>&nbsp;
                                <span >{record.compliance_name}</span>
                            </span> : <span style={{}}>
                                <Tooltip title={record.compliance_description}>
                                    <ExclamationCircleTwoTone style={{ marginRight: '1px' }} />
                                </Tooltip>
                                <span>  &nbsp;{record.compliance_name}</span>
                            </span>}
                        {/* <span>
                                <p style={{ whiteSpace: 'unset', marginTop: '-20px', marginLeft: '17px' }} > {record.compfielaw_url ? <a href={`${record.compfielaw_url}`}><BookTwoTone /></a> : ''}&nbsp; {record.compliance_name}</p>
                        </span> */}
                    </div>
                )
            },
            sortable: true,
            reorder: true,
            width: '240px',
            ellipsis: false,
            // fixed: 'left',

        },
        // {
        //     title: "Compliance Frequency",
        //     dataIndex: 'frequency',
        //     key: 'frequency',
        //     ...getColumnSearchProps('frequency', "Compliance Frequency"),
        //     width: '80px',
        //     ellipsis: false
        // },
        {
            title: "Statutory Date",
            dataIndex: 'summary',
            key: 'summary',
            ...getColumnSearchProps('summary', "Statutory Date"),
            width: "160px",
            ellipsis: false


        },
        {
            title: <label><span title='Trigger Before(Days)'>Days</span></label>,
            dataIndex: 'trigger_before_days',
            key: 'trigger_before_days',
            ...getColumnSearchProps('trigger_before_days', "Trigger Before (Days)"),
            width: '20px',
            ellipsis: false,
            align: 'center'

        },
        {
            title: 'Assignee',
            dataIndex: 'assignee_name',
            key: 'assignee_name',
            ...getColumnSearchProps('assignee_name', 'Assignee'),
            width: '40px',
            ellipsis: false,
            align: 'center'

        },
        {
            title: 'Concur',
            dataIndex: 'concurrer_name',
            key: 'concurrer_name',
            ...getColumnSearchProps('concurrer_name', 'Concur'),
            width: '75px',
            ellipsis: false


        },
        {
            title: "Approver",
            dataIndex: 'approver_name',
            key: 'approver_name',
            ...getColumnSearchProps('approver_name', "Approver"),
            width: '60px',
            ellipsis: false


        },
        {
            title: "Due Date",
            dataIndex: false,
            key: false,
            ...getColumnSearchProps('d_date', "Due Date"),
            align: 'center',
            ellipsis: false,

            render: (row, record, rowIndex) => {

                return (
                    <>
                        {record.c_h_id !== null ?

                            <label>{moment(record.d_date).format("DD-MMM-YYYY")}
                                {/* <i style={{ marginLeft: "5px" }} className="ri-edit-line" onClick={() => {
                                            setEditClickDate(true)
                                        }}></i> */}
                            </label>
                            :


                            <>
                                <label>{moment(record.d_date).format("DD-MMM-YYYY")}</label>
                                {(temparr.filter((e) => e.comp_id == record.comp_id).length > 0) ?
                                    <input type='date' style={{ width: "130px", height: "30px" }} className="form-control"
                                        max={moment(record.d_date).format("YYYY-MM-DD")}
                                        onBlur={(e) => {
                                            for (let i in updateduedate) {
                                                if (rowIndex == updateduedate[i].index && row.comp_id == updateduedate[i].comp_id) {
                                                    let temparr = []
                                                    temparr.push({
                                                        date: e.target.value,
                                                        comp_id: record.comp_id,
                                                        index: rowIndex
                                                    })

                                                    setUpdateduedate([...updateduedate, ...temparr])
                                                }
                                            }
                                        }}
                                    />
                                    : ""}
                            </>

                        }

                    </>


                )
            },
            width: '110px',



        },
        {
            title: "Validity Date",
            dataIndex: 'v_date',
            key: 'v_date',
            // ...getColumnSearchProps('v_date', "Validity Date"),
            width: '80px',
            ellipsis: false
        }
    ];

    const columns2 = [
        {
            title: "S.No.",
            // render: (row,record) => record.r_id,
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            },
            sortable: true,
            reorder: true,
            dataIndex: false,
            width: '30px',
            align: 'center',

        },
        {
            title: "Description",
            render: (row, record) => <a onClick={() => {
                Setrecalldata({ ...recalldata, reasons: record.r_description })
                setRemarksModal(false)
            }}>{record.r_description}</a>,
            sortable: true,
            ellipsis: true,
            width: '200px',
            reorder: true,

        }
    ]

    useEffect(() => {
        if (dataa.legalentity == localStorage.getItem('SelectedEntity')) {
            Setlegalstate(true)
        }
    }, [dataa.legalentity])

    useEffect(() => {
        let array = [];
        let finalarray = [];
        let datavalue = [];
        let datas = [];
        let parId = []
        let filteredArr;
        let filteredArr1;

        if (reassigndatatablelist.reassign_compliances) {

            const ab = reassigndatatablelist.reassign_compliances.map(function (item) {
                if ((array.indexOf(item.act_name) < 0))
                    array.push(item.act_name)
            })
            array.map((data, i) => {

                datas = data
                let temp = [];
                let entityArray = []
                let tempArray = []
                let tempArray1 = []
                let checkarray = []
                let checkarray1 = []

                if (datas) {
                    reassigndatatablelist.reassign_compliances.map((child) => {

                        if (datas === child.act_name) {
                            temp.push(child)
                            filteredArr = temp.filter((item) => {
                                return item.task_type === 'Current Task'
                            })
                            filteredArr1 = temp.filter((item) => {
                                return item.task_type === 'Upcoming Task'
                            })
                        }
                    })
                    filteredArr && filteredArr.length > 0 && filteredArr.map((item) => {

                        if (datas === item.act_name) {
                            entityArray = {
                                'ac_id': item.ac_id,
                                'act_name': item.act_name,
                                'approval_person': item.approval_person,
                                'approver_name': item.approver_name,
                                'assignee': item.assignee,
                                'assignee_name': item.assignee_name,
                                'c_h_id': item.c_h_id,
                                'comp_id': item.comp_id,
                                'compfielaw_url': item.compfielaw_url,
                                'compliance_description': item.compliance_description,
                                'compliance_name': item.compliance_name,
                                'concurrence_person': item.concurrence_person,
                                'concurrer_name': item.concurrer_name,
                                'd_date': item.d_date,
                                'extend_due_date': item.extend_due_date,
                                'f_id': item.f_id,
                                'frequency': item.frequency,
                                'summary': item.summary,
                                'task_type': item.task_type,
                                'trigger_before_days': item.trigger_before_days,
                                'tz_date': item.tz_date,
                                'tz_name': item.tz_name,
                                'u_id': item.u_id,
                                'u_name': item.u_name,
                                'v_date': item.v_date,
                                'parentId': i,
                            }
                            tempArray.push(entityArray)
                            console.log(tempArray, "tempArraytempArray11112222");
                        }

                    })
                    let listArray_One_time = _.filter(tempArray, { frequency: "One Time" });
                    if (listArray_One_time && listArray_One_time.length > 0) {
                        for (let i in listArray_One_time) {
                            checkarray.push(listArray_One_time[i])
                        }
                        console.log(checkarray, "checkarray11111111111");
                    }

                    let listArray_periodical = _.filter(tempArray, { frequency: "Periodical" });
                    if (listArray_periodical && listArray_periodical.length > 0) { for (let i in listArray_periodical) { checkarray.push(listArray_periodical[i]) } }
                    let listArray_Review = _.filter(tempArray, { frequency: "Review" });
                    if (listArray_Review && listArray_Review.length > 0) { for (let i in listArray_Review) { checkarray.push(listArray_Review[i]) } }
                    let listArray_Flexi_Review = _.filter(tempArray, { frequency: "Flexi Review" });
                    if (listArray_Flexi_Review && listArray_Flexi_Review.length > 0) { for (let i in listArray_Flexi_Review) { checkarray.push(listArray_Flexi_Review[i]) } }
                    let listArray_On_Occurrence = _.filter(tempArray, { frequency: "On Occurrence" });
                    if (listArray_On_Occurrence && listArray_On_Occurrence.length > 0) { for (let i in listArray_On_Occurrence) { checkarray.push(listArray_On_Occurrence[i]) } }
                    console.log(checkarray, "checkarray111");



                    filteredArr1 && filteredArr1.length > 0 && filteredArr1.map((item) => {

                        if (datas === item.act_name) {

                            entityArray = {
                                'ac_id': item.ac_id,
                                'act_name': item.act_name,
                                'approval_person': item.approval_person,
                                'approver_name': item.approver_name,
                                'assignee': item.assignee,
                                'assignee_name': item.assignee_name,
                                'c_h_id': item.c_h_id,
                                'comp_id': item.comp_id,
                                'compfielaw_url': item.compfielaw_url,
                                'compliance_description': item.compliance_description,
                                'compliance_name': item.compliance_name,
                                'concurrence_person': item.concurrence_person,
                                'concurrer_name': item.concurrer_name,
                                'd_date': item.d_date,
                                'extend_due_date': item.extend_due_date,
                                'f_id': item.f_id,
                                'frequency': item.frequency,
                                'summary': item.summary,
                                'task_type': item.task_type,
                                'trigger_before_days': item.trigger_before_days,
                                'tz_date': item.tz_date,
                                'tz_name': item.tz_name,
                                'u_id': item.u_id,
                                'u_name': item.u_name,
                                'v_date': item.v_date,
                                'parentId': i,
                            }
                            tempArray1.push(entityArray)
                            console.log(tempArray1, "tempArray1tempArray1");

                        }
                    })
                    let listArray_One_time1 = _.filter(tempArray1, { frequency: "One Time" });
                    if (listArray_One_time1 && listArray_One_time1.length > 0) {
                        for (let i in listArray_One_time1) {
                            checkarray1.push(listArray_One_time1[i])
                        }
                        console.log(checkarray, "checkarray11111111111");
                    }

                    let listArray_periodical1 = _.filter(tempArray1, { frequency: "Periodical" });
                    if (listArray_periodical1 && listArray_periodical1.length > 0) { for (let i in listArray_periodical1) { checkarray1.push(listArray_periodical1[i]) } }
                    let listArray_Review1 = _.filter(tempArray1, { frequency: "Review" });
                    if (listArray_Review1 && listArray_Review1.length > 0) { for (let i in listArray_Review1) { checkarray1.push(listArray_Review1[i]) } }
                    let listArray_Flexi_Review1 = _.filter(tempArray1, { frequency: "Flexi Review" });
                    if (listArray_Flexi_Review1 && listArray_Flexi_Review1.length > 0) { for (let i in listArray_Flexi_Review1) { checkarray1.push(listArray_Flexi_Review1[i]) } }
                    let listArray_On_Occurrence1 = _.filter(tempArray1, { frequency: "On Occurrence" });
                    if (listArray_On_Occurrence1 && listArray_On_Occurrence1.length > 0) { for (let i in listArray_On_Occurrence1) { checkarray1.push(listArray_On_Occurrence1[i]) } }
                    console.log(checkarray1, "checkarray111");

                }

                datavalue = {
                    'index': i,
                    'parent': datas,
                    'child': [[...checkarray], [...checkarray1]],
                }
                finalarray.push(datavalue)
                parId.push(i);
            })
            Settitledata(finalarray)

        }
    }, [reassigndatatablelist.reassign_compliances])

    useEffect(() => {
        let array = [];
        let finalarray = [];
        let datavalue = [];
        let datas = [];
        let parId = []
        let filteredArr;
        let filteredArr1;

        if (showmorelist) {

            const ab = showmorelist.map(function (item) {
                if ((array.indexOf(item.act_name) < 0))
                    array.push(item.act_name)
            })
            array.map((data, i) => {
                datas = data
                let temp = [];
                let entityArray = []
                let tempArray = []
                let tempArray1 = []
                let checkarray = []
                let checkarray1 = []

                if (datas) {
                    showmorelist.map((child) => {
                        if (datas === child.act_name) {
                            temp.push(child)
                            filteredArr = temp.filter((item) => {
                                return item.task_type === 'Current Task'
                            })
                            filteredArr1 = temp.filter((item) => {
                                return item.task_type === 'Upcoming Task'
                            })

                        }

                    })
                    filteredArr && filteredArr.length > 0 && filteredArr.map((item) => {

                        if (datas === item.act_name) {
                            entityArray = {
                                'ac_id': item.ac_id,
                                'act_name': item.act_name,
                                'approval_person': item.approval_person,
                                'approver_name': item.approver_name,
                                'assignee': item.assignee,
                                'assignee_name': item.assignee_name,
                                'c_h_id': item.c_h_id,
                                'comp_id': item.comp_id,
                                'compfielaw_url': item.compfielaw_url,
                                'compliance_description': item.compliance_description,
                                'compliance_name': item.compliance_name,
                                'concurrence_person': item.concurrence_person,
                                'concurrer_name': item.concurrer_name,
                                'd_date': item.d_date,
                                'extend_due_date': item.extend_due_date,
                                'f_id': item.f_id,
                                'frequency': item.frequency,
                                'summary': item.summary,
                                'task_type': item.task_type,
                                'trigger_before_days': item.trigger_before_days,
                                'tz_date': item.tz_date,
                                'tz_name': item.tz_name,
                                'u_id': item.u_id,
                                'u_name': item.u_name,
                                'v_date': item.v_date,
                                'parentId': i,
                            }
                            tempArray.push(entityArray)
                        }

                    })
                    let listArray_One_time = _.filter(tempArray, { frequency: "One Time" });
                    if (listArray_One_time && listArray_One_time.length > 0) {
                        for (let i in listArray_One_time) {
                            checkarray.push(listArray_One_time[i])
                        }
                        console.log(checkarray, "checkarray11111111111");
                    }

                    let listArray_periodical = _.filter(tempArray, { frequency: "Periodical" });
                    if (listArray_periodical && listArray_periodical.length > 0) { for (let i in listArray_periodical) { checkarray.push(listArray_periodical[i]) } }
                    let listArray_Review = _.filter(tempArray, { frequency: "Review" });
                    if (listArray_Review && listArray_Review.length > 0) { for (let i in listArray_Review) { checkarray.push(listArray_Review[i]) } }
                    let listArray_Flexi_Review = _.filter(tempArray, { frequency: "Flexi Review" });
                    if (listArray_Flexi_Review && listArray_Flexi_Review.length > 0) { for (let i in listArray_Flexi_Review) { checkarray.push(listArray_Flexi_Review[i]) } }
                    let listArray_On_Occurrence = _.filter(tempArray, { frequency: "On Occurrence" });
                    if (listArray_On_Occurrence && listArray_On_Occurrence.length > 0) { for (let i in listArray_On_Occurrence) { checkarray.push(listArray_On_Occurrence[i]) } }
                    console.log(checkarray, "checkarray111");
                    filteredArr1 && filteredArr1.length > 0 && filteredArr1.map((item) => {
                        if (datas === item.act_name) {
                            entityArray = {
                                'ac_id': item.ac_id,
                                'act_name': item.act_name,
                                'approval_person': item.approval_person,
                                'approver_name': item.approver_name,
                                'assignee': item.assignee,
                                'assignee_name': item.assignee_name,
                                'c_h_id': item.c_h_id,
                                'comp_id': item.comp_id,
                                'compfielaw_url': item.compfielaw_url,
                                'compliance_description': item.compliance_description,
                                'compliance_name': item.compliance_name,
                                'concurrence_person': item.concurrence_person,
                                'concurrer_name': item.concurrer_name,
                                'd_date': item.d_date,
                                'extend_due_date': item.extend_due_date,
                                'f_id': item.f_id,
                                'frequency': item.frequency,
                                'summary': item.summary,
                                'task_type': item.task_type,
                                'trigger_before_days': item.trigger_before_days,
                                'tz_date': item.tz_date,
                                'tz_name': item.tz_name,
                                'u_id': item.u_id,
                                'u_name': item.u_name,
                                'v_date': item.v_date,
                                'parentId': i,
                            }
                            tempArray1.push(entityArray)
                        }
                    })
                    let listArray_One_time1 = _.filter(tempArray1, { frequency: "One Time" });
                    if (listArray_One_time1 && listArray_One_time1.length > 0) {
                        for (let i in listArray_One_time1) {
                            checkarray1.push(listArray_One_time1[i])
                        }
                        console.log(checkarray, "checkarray11111111111");
                    }

                    let listArray_periodical1 = _.filter(tempArray1, { frequency: "Periodical" });
                    if (listArray_periodical1 && listArray_periodical1.length > 0) { for (let i in listArray_periodical1) { checkarray1.push(listArray_periodical1[i]) } }
                    let listArray_Review1 = _.filter(tempArray1, { frequency: "Review" });
                    if (listArray_Review1 && listArray_Review1.length > 0) { for (let i in listArray_Review1) { checkarray1.push(listArray_Review1[i]) } }
                    let listArray_Flexi_Review1 = _.filter(tempArray1, { frequency: "Flexi Review" });
                    if (listArray_Flexi_Review1 && listArray_Flexi_Review1.length > 0) { for (let i in listArray_Flexi_Review1) { checkarray1.push(listArray_Flexi_Review1[i]) } }
                    let listArray_On_Occurrence1 = _.filter(tempArray1, { frequency: "On Occurrence" });
                    if (listArray_On_Occurrence1 && listArray_On_Occurrence1.length > 0) { for (let i in listArray_On_Occurrence1) { checkarray1.push(listArray_On_Occurrence1[i]) } }
                    console.log(checkarray1, "checkarray111");
                }
                datavalue = {
                    'index': i,
                    'parent': datas,
                    'child': [[...checkarray], [...checkarray1]],
                }
                finalarray.push(datavalue)
                parId.push(i);
            })
            setShowMoreDataList(finalarray)

        }
    }, [showmorelist])
    useEffect(() => {
        if (reassignusercompliance.assign_users && reassignusercompliance.assign_users.length > 0) {
            console.log(reassignusercompliance.assign_users, "333333");

            let uniqueObjArray = [...new Map(reassignusercompliance.assign_users && reassignusercompliance.assign_users.length && reassignusercompliance.assign_users.map((item) => [item["s_u_name"], item])).values()];
            setFilteredArray([...uniqueObjArray])
            console.log(uniqueObjArray, "uniqueObjArray");
        }
    }, [reassignusercompliance.assign_users])

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    //  Full screen mode
    const [fullscreen, setfullscreen] = useState(false);
    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', handleKeyDown, false);
            document.addEventListener('webkitfullscreenchange', handleKeyDown, false);
            return () => {
                document.addEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('webkitfullscreenchange', handleKeyDown);
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode();
            setfullscreen(true);
        } else {
            fullscreenexit();
            setfullscreen(false);
        }
    }
    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
      };
      const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
      };


    return (
        <Fragment>
            <div>
                <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} />
                <div id='page-wrapper' className="page-wrapper">
                    <div className="page-titles pb-0 pt-2">
                        <div className="row d-flex">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span style={{ fontSize: '16px' }}>Transaction</span>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                            <span>Reassign Compliance</span>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            
                            {temparr.length > 0 ?
                                <div className="col-lg-2">
                                    {triggerstate == false ? <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: '100%', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'black' }}
                                        size='default' onClick={Onsubmit2}>
                                        Select Assignee <i> </i>
                                    </Button> : <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", float: 'right', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'black' }}

                                        size='default' onClick={() => { toast.error("Trigger before days should not exceed 100") }}>
                                        Select Assignee <i></i>
                                    </Button>}
                                </div> : false}
                            <div className={temparr.length > 0 ? "col-lg-2 text-end" : "col-lg-4 text-end"}>
                           
                            <Button
                className="mx-1"
                type="primary"
                shape="round"
                style={{
                  display: filterTaskAcc ? "none" : "initial",
                }}
                onClick={filterHandle}
              >
                <FilterOutlined /> Filter
              </Button>

                                <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                                    <Tooltip placement="left" title="Full Screen Mode"><FullscreenOutlined /></Tooltip>
                                </button>
                            </div>

                        </div>
                    </div>

                    <div className="container-fluid pt-1">
                        <div className='row assign'>
                            <div className="col-12">

                                <div className="row">
                                    {tab === true ?
                                        <>
                                            {/* <Tabs activeKey={key} type="card">
                                                 <TabPane */}
                                            {/* //     tab="Select Compliance Tasks"
                                                //     disabled
                                                //     key="1"
                                                // > */}
                                            <div className={localStorage.getItem("currentTheme")} style={{ padding: '0px', marginBottom: '3px' }}>
                                                {collapseAssignee1 == true && temparr && temparr.length > 0 ? <>
                                                    <Collapse key={0}
                                                        onChange={(e) => {
                                                            if (collapseAssignee == '0') {
                                                                setCollapseAssignee('1')
                                                            }
                                                            else {
                                                                setCollapseAssignee('0')
                                                            }
                                                        }}
                                                        defaultActiveKey={'0'}
                                                        activeKey={collapseAssignee}
                                                    >
                                                        <Panel header={collapseAssignee1 == false ? 'Assignment Settings' : `Selected Compliances : ${temparr.length}`} key='1' disabled={collapseAssignee1 == false ? true : false} >
                                                            <div className="col-md-12" style={{ marginTop: '0px' }}>
                                                                <div className="row">
                                                                    <span>{unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 5 || unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 6 ? <><label><h5> <b>Current Assignee : {dataa.usr_name}</b></h5></label> <br></br></> : unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 3 || unitcategory && unitcategory[0] && unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 4 ? <><label> <b>Current Concurrer: {dataa.usr_name}</b></label> <br></br></> : unitcategory && unitcategory[0] && unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 1 || unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 3 || unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 4 ? <><label> <b>Current Approver: {dataa.usr_name}</b></label> <br></br></> : ''}</span>
                                                                    <div className='col-md-4' >
                                                                        {unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 5 || unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 6 ? <><label> <b>New Assignee</b><span style={{ color: "red" }}>*</span></label> <br></br></>
                                                                            : unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 3 || unitcategory && unitcategory[0] && unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 4 ? <><label> <b>New Concurrer</b><span style={{ color: "red" }}>*</span></label> <br></br></> : unitcategory && unitcategory[0] && unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 1 || unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 3 || unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 4 ? <><label> <b>New Approver</b><span style={{ color: "red" }}>*</span></label> <br></br></> : ''}
                                                                        {/* <Select
                                                                            allowClear={false}
                                                                            size="default"
                                                                            placeholder="Select Assignee"
                                                                            onChange={(data, value) => {

                                                                                setDataa({
                                                                                    ...dataa,
                                                                                    s_u_name: data
                                                                                })

                                                                            }}
                                                                            showSearch
                                                                            optionFilterProp="children"
                                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                            style={{ width: '100%', marginTop: "5px" }}
                                                                        >
                                                                            <Option key={1}>
                                                                                {"All"}
                                                                            </Option>
                                                                            {filteredArray && filteredArray.length && filteredArray.map((item) => {
                                                                                console.log(item, 'itemmmm');

                                                                                // if (item.s_u_name !== null) {
                                                                                return (
                                                                                    <Option key={item.usr_id}>
                                                                                        {`${item.emp_code} - ${item.emp_name}`}
                                                                                    </Option>
                                                                                );

                                                                                // }
                                                                            })}
                                                                        </Select> */}
                                                                        <Select
                                                                            allowClear={false}
                                                                            size="default"
                                                                            placeholder="Search"
                                                                            onChange={(data, value) => {
                                                                                setDataa({
                                                                                    ...dataa,
                                                                                    employee_name: data,
                                                                                    su_name: value.children[2],
                                                                                    su_num: value.children[0],
                                                                                    su_id: value.children[1]
                                                                                })
                                                                            }}
                                                                            showSearch
                                                                            optionFilterProp="children"
                                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                            style={{ width: '100%', marginTop: "5px" }}
                                                                        >
                                                                            {/* {
                                                                                finalallassignee && finalallassignee.length ? finalallassignee.map((item) => {
                                                                                    console.log(item, "itemmm");

                                                                                    return (
                                                                                        <Option key={item}>
                                                                                            {item}
                                                                                        </Option>
                                                                                    )
                                                                                })
                                                                                    :New Assignee
                                                                                    finalassignee && finalassignee.length && finalassignee.map((item) => {


                                                                                        return (
                                                                                            <Option key={item}>
                                                                                                {item}
                                                                                            </Option>
                                                                                        );
                                                                                    })
                                                                            } */}
                                                                            {newassigneelistshow && newassigneelistshow.length > 0 && newassigneelistshow[0].map((item) => {
                                                                                console.log(item, "09988777777777");
                                                                                if (item.emp_code !== null) {
                                                                                    return (
                                                                                        <Option key={item.usr_id}>
                                                                                            {item.emp_code == null ? '' : item.emp_code} - {item.emp_name}
                                                                                        </Option>
                                                                                    );
                                                                                }
                                                                            })
                                                                            }
                                                                        </Select>
                                                                        {validator1.current.message(
                                                                            'su_name',
                                                                            dataa.su_name,
                                                                            'required',
                                                                            {
                                                                                className: `invalid-feedback ${AddSubmitForm ? 'show' : 'hide'}`,
                                                                                messages: {
                                                                                    required: 'New Assignee Required ',
                                                                                }
                                                                            })}
                                                                        {/* </div> */}
                                                                        {/* <div className='col-md-6' > */}
                                                                        {/* <Select
                                                                            allowClear={false}
                                                                            size="default"
                                                                            placeholder="Search"
                                                                            onChange={(data) => {

                                                                                setDataa({
                                                                                    ...dataa,
                                                                                    employee_name: data
                                                                                })
                                                                            }}

                                                                            showSearch
                                                                            optionFilterProp="children"
                                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                            style={{ width: '100%', marginTop: "5px" }}
                                                                        >
                                                                            {
                                                                                finalallassignee && finalallassignee.length ? finalallassignee.map((item) => {
                                                                                    console.log(item, "itemmm");

                                                                                    return (
                                                                                        <Option key={item}>
                                                                                            {item}
                                                                                        </Option>
                                                                                    )
                                                                                })
                                                                                    :
                                                                                    finalassignee && finalassignee.length && finalassignee.map((item) => {


                                                                                        return (
                                                                                            <Option key={item}>
                                                                                                {item}
                                                                                            </Option>
                                                                                        );
                                                                                    })
                                                                            }
                                                                        </Select> */}
                                                                        {/* </div> */}


                                                                    </div>
                                                                    <div className="col-md-4 form-group">
                                                                        <label htmlFor=""><b>Reason     : </b> <span style={{ color: "red" }}>*</span></label>
                                                                        <div className='d-flex align-items-end w-75'>
                                                                            {/* <div className='col-md-4'></div> */}
                                                                            {/* <div className="col-md-6"> */}
                                                                            <textarea name="remarks" className='form-control' placeholder='Enter Reason here' rows={1}
                                                                                value={recalldata.reasons ? recalldata.reasons : ""} id="remarks"
                                                                                onChange={(e) => {
                                                                                    Setrecalldata({
                                                                                        ...recalldata,
                                                                                        reasons: e.target.value
                                                                                    })
                                                                                }}>
                                                                                {validator1.current.message(
                                                                                    'reasons',
                                                                                    recalldata.reasons,
                                                                                    ['required'],
                                                                                    {
                                                                                        className: `invalid-feedback ${AddSubmitForm ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            required: 'Reason Required',

                                                                                        }
                                                                                    })}
                                                                            </textarea>
                                                                            <span className="text-info ms-1 d-flex 
                                align-items-center justify-content-center" style={{ float: 'right' }}
                                                                                onClick={() => { showRemarksModal() }}>
                                                                                <PlusCircleOutlined />
                                                                            </span>
                                                                            {/* </div> */}
                                                                            {/* <div className='col-md-4'></div> */}


                                                                            {/* <div style={{ marginLeft: '30%' }}>
                                                                        <PlusSquareTwoTone onClick={() => { showRemarksModal() }} />
                                                                    </div> */}

                                                                        </div>
                                                                    </div>
                                                                    <Modal title="Remarks List" visible={remarksModal} footer={null} onOk={handleOk}
                                                                        onCancel={handleCancel} className={"remarksClass add-service-prv modelradius " + localStorage.getItem('currentTheme')}>
                                                                        <Table
                                                                            className='userprivclass'
                                                                            columns={columns2}
                                                                            dataSource={remarksvalue && remarksvalue.length > 0 && remarksvalue}
                                                                            bordered
                                                                            pagination={false}
                                                                        />
                                                                    </Modal>
                                                                    {/* <div className='col-md-4'>
                                                                            <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", float: 'left' }}
                                                                                icon={<ArrowLeftOutlined />} size='default' onClick={() => {
                                                                                    setKey("1")
                                                                                    Setdatacount([])
                                                                                }}>
                                                                                Previous
                                                                            </Button>
                                                                        </div> */}
                                                                    <div className='col-md-4 mt-4'>
                                                                        {/* <button className="btn btn-primary btn-md" style={{ float: "right", width: "100px" }} onClick={Onsubmit3}>Submit</button> */}
                                                                        <Button type="primary" shape="round" className='addbutton'
                                                                            style={{ background: "#198754", borderColor: "#198754" }}
                                                                            icon={<PlayCircleOutlined />} size='default' onClick={Onsubmit3}>
                                                                            Submit
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Panel>
                                                    </Collapse>
                                                </> : false}
                                            </div>
                                            < Collapse defaultActiveKey={['0']} className='cric'>
                                                {checkboxtitle && checkboxtitle.length > 0 &&
                                                    checkboxtitle.map((itemdata, i) => (
                                                        <Panel header={itemdata.parent} extra={<div style={{ width: '120px', textAlign: 'left' }}><span id={'parentid-' + itemdata.index} style={{ display: 'none' }}>{itemdata.index}</span><span>Total Tasks : {itemdata.child[0].length}</span></div>} key={itemdata.index} >
                                                            {itemdata.child && itemdata.child.length > 0 && itemdata.child.map((data, i) => {
                                                                return (
                                                                    <>
                                                                        <div className="container-fluid" style={{ padding: 0 }}>
                                                                            <div className="ressign-table">
                                                                                {data && data.length ?
                                                                                    <Table columns={columns1}
                                                                                        className={localStorage.getItem("currentTheme") + ' assign_table'}
                                                                                        dataSource={data && data.length > 0 ? data : ''}
                                                                                        scroll={{ x: 1000 }}
                                                                                        size="small"
                                                                                        bordered
                                                                                        // pagination={{
                                                                                        //     defaultPageSize: dataTableProperties.pagesize,
                                                                                        //     showSizeChanger: dataTableProperties.sizechanger,
                                                                                        //     pageSizeOptions: dataTableProperties.pageSizeOptions
                                                                                        // }}
                                                                                        pagination={false}
                                                                                        title={() => {
                                                                                            return data ? data && data.length && data[0].task_type : ''
                                                                                        }}
                                                                                        // pagination={false}
                                                                                        onHeaderRow={(columns, index) => {
                                                                                            return {
                                                                                                onClick: (e) => {
                                                                                                    let checked = e.target.checked;
                                                                                                    addAllSelectedDataValues(
                                                                                                        checked,
                                                                                                        itemdata.index,
                                                                                                        itemdata.parent,
                                                                                                    );
                                                                                                }
                                                                                            };
                                                                                        }}
                                                                                    />
                                                                                    : ''}
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            })}
                                                        </Panel>
                                                    ))}
                                            </Collapse>
                                            <div className='col-md-12' style={{ marginTop: "10px" }}>
                                                <div className='row'>
                                                    <div className='col-lg-6' >
                                                        <label style={{ marginLeft: "5px", float: 'left' }}>Showing 1 to <span>{datacount.length}</span> of {Entriescount} entries</label>
                                                    </div>

                                                    <div className='col-lg-6' style={{ float: 'right', textAlign: 'right', marginLeft: '-6px' }}>
                                                        <span style={{ marginLeft: '27%' }}>Selected Compliance :<span style={{ marginLeft: "5px" }}>{temparr.length}</span></span>

                                                    </div>
                                                </div>

                                            </div>

                                            <div className='col-md-12' style={{ padding: "10px", paddingBottom: '0px' }}>
                                                <div className='row'>
                                                    <div className='col-md-4' >
                                                        {/* <button className="btn btn-primary btn-md" style={{ float: "left", width: "100px" }} onClick={previous}>Previous</button> */}
                                                        <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", float: 'left', marginLeft: '-8px' }}
                                                            icon={<ArrowLeftOutlined />} size='default' onClick={previous}>
                                                            Previous
                                                        </Button>
                                                        {/* <button style={{ float: "left" }} className="usrbutton button--anthe prev" onClick={previous}><span class="text-white fs-3 fw-bold">Previous</span></button> */}

                                                    </div>
                                                    <div className='col-md-4' style={{ textAlign: 'center' }}>
                                                        {(Entriescount == entires) ?
                                                            ''
                                                            : <Button type="primary" shape="round" className='showbutton' style={{ background: "#0096FF", borderColor: "#0096FF", textAlign: 'center' }}
                                                                size='default' onClick={showmore}>
                                                                Show More  <i> <DownOutlined /> </i>
                                                            </Button>}


                                                    </div>
                                                    <div className='col-md-4'>
                                                        {/* <button className="btn btn-primary btn-md" style={{ float: "right", width: "100px" }} onClick={Onsubmit2}>Next</button> */}
                                                        {/* <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", float: 'right' }}
                                                                    size='default' onClick={Onsubmit2}>
                                                                    Next <i> <ArrowRightOutlined /></i>
                                                                </Button> */}
                                                        {/* <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", float: 'right' }}
                                                                    size='default' onClick={Onsubmit2}>
                                                                    Next <ArrowRightOutlined />
                                                                </Button> */}


                                                        {triggerstate == false ? <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", float: 'right' }}
                                                            size='default' onClick={Onsubmit2}>
                                                            Select Assignee <i> </i>
                                                        </Button> : <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", float: 'right' }}

                                                            size='default' onClick={() => { toast.error("Trigger before days should not exceed 100") }}>
                                                            Select Assignee <i></i>
                                                        </Button>}

                                                        {/* <button style={{ float: "right" }} className="usrbutton button--anthe" onClick={Onsubmit2}><span class="text-white fs-3 fw-bold">Next</span></button> */}
                                                    </div>
                                                </div>
                                            </div>



                                            <div className='card p-4 py-2 mb-0 rounded user-mapping1 bg-white'>
                                                <div className="d-flex justify-content-between">
                                                    <div>

                                                    </div>
                                                    <div >
                                                        <i style={{ color: "red" }} className="bi bi-square-fill"></i> <label>One time</label>
                                                    </div>
                                                    <div>
                                                        <i style={{ color: 'green' }} className="bi bi-square-fill"></i> <label>Periodical</label>
                                                    </div>
                                                    <div >
                                                        <i style={{ color: "orange" }} className="bi bi-square-fill"></i> <label>Review</label>
                                                    </div>
                                                    <div >
                                                        <i style={{ color: "pink" }} className="bi bi-square-fill"></i> <label>Flexi Review</label>
                                                    </div>

                                                    <div >
                                                        <i style={{ color: "yellow" }} className="bi bi-square-fill"></i> <label>On Occurrence</label>
                                                    </div>

                                                    <div >
                                                    </div>
                                                </div>

                                            </div>
                                            {/* </TabPane> */}

                                            {/* </Tabs>  */}
                                        </> :
                                        < >

                                            <div className={localStorage.getItem("currentTheme")}>
                                                <Collapse className='report-collapse' defaultActiveKey={["1"]} 
                  style={{ display: exitCollapse ? "none" : "block" }}>
                                                    <Panel header="Reassign Compliance" key={key}>
                                                        <div className='col-md-12'>
                                                            <div className='row'>
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor=""><b>Legal Entity :</b></label><span style={{ color: "red" }}> *</span>
                                                                    </div>
                                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                                        <div className="form-group">
                                                                            <Select
                                                                                allowClear={true}
                                                                                size="default"
                                                                                placeholder="Select Legal Entity"
                                                                                onChange={(data, value) => {
                                                                                    if (data !== undefined) {
                                                                                        Setlegalstate(true)
                                                                                        setCurrentEntity(data)
                                                                                        setDataa({
                                                                                            ...dataa,
                                                                                            legalentity: data,
                                                                                            d_name: '',
                                                                                            usr_name: '',
                                                                                            unit_name: '',
                                                                                            user_type: ''
                                                                                        })
                                                                                    }
                                                                                    else {
                                                                                        SetshowTable(false)
                                                                                        Setlegalstate(false)
                                                                                        setCurrentEntity('')
                                                                                        setDataa({
                                                                                            ...dataa,
                                                                                            legalentity: '',
                                                                                            d_name: '',
                                                                                            usr_name: '',
                                                                                            unit_name: '',
                                                                                            user_type: ''
                                                                                        })
                                                                                    }
                                                                                }
                                                                                }
                                                                                showSearch
                                                                                optionFilterProp="children"
                                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                value={dataa.legalentity || undefined}
                                                                                style={{ width: '100%', marginTop: "5px" }}
                                                                            >
                                                                                {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                                    return (
                                                                                        <Option key={item.le_id}>
                                                                                            {item.le_name}
                                                                                        </Option>
                                                                                    );
                                                                                })}
                                                                            </Select>
                                                                            {validator.current.message(
                                                                                'legalentity',
                                                                                dataa.legalentity,
                                                                                'required',
                                                                                {
                                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                    messages: {
                                                                                        required: 'Legal Entity Required',
                                                                                    }
                                                                                })}
                                                                        </div> :
                                                                        <p style={{ width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>


                                                                    }
                                                                </div>

                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor=""><b>Domain : </b><span style={{ color: "red" }}>*</span></label>
                                                                    </div>
                                                                    <Select
                                                                        allowClear={true}
                                                                        disabled={dataa.legalentity == "" ? true : false}
                                                                        size="default"
                                                                        placeholder="Select Domain"
                                                                        // onClick={() => {
                                                                        //     if (dataa.legalentity == "") {

                                                                        //         toast.warning("Legal Entity Required")
                                                                        //     }
                                                                        // }}
                                                                        onChange={(data, value) => {

                                                                            if (data !== undefined) {
                                                                                setDataa({
                                                                                    ...dataa,
                                                                                    d_id: data,
                                                                                    d_name: value.children,
                                                                                    usr_name: '',
                                                                                    unit_name: '',
                                                                                    user_type: ''
                                                                                })
                                                                                Settitledata([])
                                                                                Setdatacount([])
                                                                            }
                                                                            else {
                                                                                setDataa({
                                                                                    ...dataa,
                                                                                    d_id: '',
                                                                                    d_name: '',
                                                                                    usr_name: '',
                                                                                    unit_name: '',
                                                                                    user_type: ''
                                                                                })
                                                                                Settitledata([])
                                                                                Setdatacount([])
                                                                            }
                                                                        }}
                                                                        value={dataa.d_name || undefined}
                                                                        showSearch
                                                                        optionFilterProp="children"
                                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                        style={{ width: '100%', marginTop: "5px" }}
                                                                    >
                                                                        {reassigndatalist.domains && reassigndatalist.domains.length && reassigndatalist.domains.map((item) => {
                                                                            return (
                                                                                <Option key={item.d_id}>
                                                                                    {item.d_name}
                                                                                </Option>
                                                                            );
                                                                        })}
                                                                    </Select>
                                                                    {validator.current.message(
                                                                        'domain',
                                                                        dataa.d_id,
                                                                        'required',
                                                                        {
                                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                            messages: {
                                                                                required: 'Domain Required',

                                                                            }
                                                                        })}
                                                                </div>
                                                                <div className='col-md-4'>
                                                                    <div className="form-group">
                                                                        <label> <b>User : </b><span style={{ color: "red" }}>*</span></label>
                                                                    </div>
                                                                    <Select
                                                                        allowClear={true}
                                                                        disabled={dataa.d_id == "" ? true : false}
                                                                        size="default"
                                                                        placeholder="Select User"
                                                                        // onClick={() => {
                                                                        //     if (dataa.d_id == "") {

                                                                        //         toast.warning("Domain Required")
                                                                        //     }
                                                                        // }}
                                                                        onChange={(data, value) => {
                                                                            if (value !== undefined) {
                                                                                setDataa({
                                                                                    ...dataa,
                                                                                    usr_id: [data],
                                                                                    usr_name: [value.children],
                                                                                    unit_name: '',

                                                                                })
                                                                            }
                                                                            else {
                                                                                setDataa({
                                                                                    ...dataa,
                                                                                    usr_id: '',
                                                                                    usr_name: '',
                                                                                    unit_name: '',

                                                                                })
                                                                            }
                                                                        }}

                                                                        value={dataa.usr_name || undefined}
                                                                        showSearch
                                                                        optionFilterProp="children"
                                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                        style={{ width: '100%', marginTop: "5px" }}
                                                                    >
                                                                        {
                                                                            categorylist.employee_name == '' ? reassigndatalist.legal_entity_users && reassigndatalist.legal_entity_users.map((item) => {
                                                                                console.log(item, "itemitemmmmmmmmmm");

                                                                                return (
                                                                                    <Option key={item.user_id}>
                                                                                        {item.employee_name}
                                                                                    </Option>
                                                                                );

                                                                            })
                                                                                :
                                                                                categorylist.employee_name && categorylist.employee_name.map((item) => {
                                                                                    return (
                                                                                        <Option key={item.employee_code}>
                                                                                            {item.employee_name}
                                                                                        </Option>
                                                                                    );
                                                                                })
                                                                        }
                                                                    </Select>
                                                                    {validator.current.message(
                                                                        'user',
                                                                        dataa.usr_id,
                                                                        'required',
                                                                        {
                                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                            messages: {
                                                                                required: 'User Required'
                                                                            }
                                                                        })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-12' style={{ marginTop: '10px' }}>
                                                            <div className='row'>
                                                                <div className="col-md-4" >
                                                                    <div className="form-group">
                                                                        <label htmlFor=""><b>User Type : </b></label>
                                                                    </div>
                                                                    <Select
                                                                        allowClear={false}
                                                                        size="default"
                                                                        placeholder="Select User Type"
                                                                        defaultValue="All"
                                                                        onChange={(value) => {
                                                                            SetuserType(value)
                                                                            setDataa({
                                                                                ...dataa,
                                                                                user_type: [value],
                                                                            })
                                                                        }}
                                                                        showSearch
                                                                        optionFilterProp="children"
                                                                        value={dataa.user_type || undefined}
                                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                        style={{ width: '100%', marginTop: "5px" }}
                                                                    >
                                                                        <Option defaultValue value="0">All</Option>
                                                                        <Option value="1">Assignee</Option>
                                                                        <Option value="2">Concurrence</Option>
                                                                        <Option value="3">Approver</Option>

                                                                    </Select>

                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor=""><b>Unit : </b></label><br />
                                                                    </div>

                                                                    <Select
                                                                        allowClear={true}
                                                                        size="default"
                                                                        placeholder="Select Unit"
                                                                        disabled={dataa.d_id == "" ? true : false}
                                                                        // onClick={() => {
                                                                        //     if (dataa.d_id == "") {

                                                                        //         toast.warning("Domain Required")
                                                                        //     }
                                                                        // }}
                                                                        onChange={(data, value) => {
                                                                            if (data !== undefined) {
                                                                                setDataa({
                                                                                    ...dataa,
                                                                                    u_ids: data,
                                                                                    unit_name: [value.children]
                                                                                })
                                                                            }
                                                                            else {
                                                                                setDataa({
                                                                                    ...dataa,
                                                                                    u_ids: '',
                                                                                    unit_name: ''
                                                                                })
                                                                            }
                                                                        }}
                                                                        value={dataa.unit_name || undefined}
                                                                        showSearch
                                                                        optionFilterProp="children"
                                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                        style={{ width: '100%', marginTop: "5px" }}
                                                                    >
                                                                        {reassigndatalist.units && reassigndatalist.units.length > 0 && reassigndatalist.units.map((item) => {
                                                                            return (
                                                                                <Option key={item.unit_id}>
                                                                                    {item.unit_name}
                                                                                </Option>
                                                                            );
                                                                        })}
                                                                    </Select>
                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div className='text-center mt-2'>
                                                            {/* <button className="btn btn-primary btn-md" style={{ marginTop: "25px", width: "80px" }} onClick={Onsubmit1}>Show</button> */}
                                                            <Button type="primary" shape="round" icon={<EyeOutlined />} className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                                size='default' onClick={Onsubmit1}>
                                                                Show
                                                            </Button>
                                                        </div>
                                                    </Panel>
                                                </Collapse>
                                                <Modal
                  onCancel={setcancelFilter}
                  footer={false}
                  title="Consolidated Report"
                  className={
                    "add-service-prv cons-report " +
                    localStorage.getItem("currentTheme")
                  }
                  visible={filterModalVisible}
                > 
                <div className='col-md-12'>
                    <div className='row'>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor=""><b>Legal Entity :</b></label><span style={{ color: "red" }}> *</span>
                            </div>
                            {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                <div className="form-group">
                                    <Select
                                        allowClear={true}
                                        size="default"
                                        placeholder="Select Legal Entity"
                                        onChange={(data, value) => {
                                            if (data !== undefined) {
                                                Setlegalstate(true)
                                                setCurrentEntity(data)
                                                setDataa({
                                                    ...dataa,
                                                    legalentity: data,
                                                    d_name: '',
                                                    usr_name: '',
                                                    unit_name: '',
                                                    user_type: ''
                                                })
                                            }
                                            else {
                                                SetshowTable(false)
                                                Setlegalstate(false)
                                                setCurrentEntity('')
                                                setDataa({
                                                    ...dataa,
                                                    legalentity: '',
                                                    d_name: '',
                                                    usr_name: '',
                                                    unit_name: '',
                                                    user_type: ''
                                                })
                                            }
                                        }
                                        }
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        value={dataa.legalentity || undefined}
                                        style={{ width: '100%', marginTop: "5px" }}
                                    >
                                        {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                            return (
                                                <Option key={item.le_id}>
                                                    {item.le_name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                    {validator.current.message(
                                        'legalentity',
                                        dataa.legalentity,
                                        'required',
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Legal Entity Required',
                                            }
                                        })}
                                </div> :
                                <p style={{ width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>


                            }
                        </div>

                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor=""><b>Domain : </b><span style={{ color: "red" }}>*</span></label>
                            </div>
                            <Select
                                allowClear={true}
                                disabled={dataa.legalentity == "" ? true : false}
                                size="default"
                                placeholder="Select Domain"
                                // onClick={() => {
                                //     if (dataa.legalentity == "") {

                                //         toast.warning("Legal Entity Required")
                                //     }
                                // }}
                                onChange={(data, value) => {

                                    if (data !== undefined) {
                                        setDataa({
                                            ...dataa,
                                            d_id: data,
                                            d_name: value.children,
                                            usr_name: '',
                                            unit_name: '',
                                            user_type: ''
                                        })
                                        Settitledata([])
                                        Setdatacount([])
                                    }
                                    else {
                                        setDataa({
                                            ...dataa,
                                            d_id: '',
                                            d_name: '',
                                            usr_name: '',
                                            unit_name: '',
                                            user_type: ''
                                        })
                                        Settitledata([])
                                        Setdatacount([])
                                    }
                                }}
                                value={dataa.d_name || undefined}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                style={{ width: '100%', marginTop: "5px" }}
                            >
                                {reassigndatalist.domains && reassigndatalist.domains.length && reassigndatalist.domains.map((item) => {
                                    return (
                                        <Option key={item.d_id}>
                                            {item.d_name}
                                        </Option>
                                    );
                                })}
                            </Select>
                            {validator.current.message(
                                'domain',
                                dataa.d_id,
                                'required',
                                {
                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Domain Required',

                                    }
                                })}
                        </div>
                        <div className='col-md-4'>
                            <div className="form-group">
                                <label> <b>User : </b><span style={{ color: "red" }}>*</span></label>
                            </div>
                            <Select
                                allowClear={true}
                                disabled={dataa.d_id == "" ? true : false}
                                size="default"
                                placeholder="Select User"
                                // onClick={() => {
                                //     if (dataa.d_id == "") {

                                //         toast.warning("Domain Required")
                                //     }
                                // }}
                                onChange={(data, value) => {
                                    if (value !== undefined) {
                                        setDataa({
                                            ...dataa,
                                            usr_id: [data],
                                            usr_name: [value.children],
                                            unit_name: '',

                                        })
                                    }
                                    else {
                                        setDataa({
                                            ...dataa,
                                            usr_id: '',
                                            usr_name: '',
                                            unit_name: '',

                                        })
                                    }
                                }}

                                value={dataa.usr_name || undefined}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                style={{ width: '100%', marginTop: "5px" }}
                            >
                                {
                                    categorylist.employee_name == '' ? reassigndatalist.legal_entity_users && reassigndatalist.legal_entity_users.map((item) => {
                                        console.log(item, "itemitemmmmmmmmmm");

                                        return (
                                            <Option key={item.user_id}>
                                                {item.employee_name}
                                            </Option>
                                        );

                                    })
                                        :
                                        categorylist.employee_name && categorylist.employee_name.map((item) => {
                                            return (
                                                <Option key={item.employee_code}>
                                                    {item.employee_name}
                                                </Option>
                                            );
                                        })
                                }
                            </Select>
                            {validator.current.message(
                                'user',
                                dataa.usr_id,
                                'required',
                                {
                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'User Required'
                                    }
                                })}
                        </div>
                    </div>
                </div>
                <div className='col-md-12' style={{ marginTop: '10px' }}>
                    <div className='row'>
                        <div className="col-md-4" >
                            <div className="form-group">
                                <label htmlFor=""><b>User Type : </b></label>
                            </div>
                            <Select
                                allowClear={false}
                                size="default"
                                placeholder="Select User Type"
                                defaultValue="All"
                                onChange={(value) => {
                                    SetuserType(value)
                                    setDataa({
                                        ...dataa,
                                        user_type: [value],
                                    })
                                }}
                                showSearch
                                optionFilterProp="children"
                                value={dataa.user_type || undefined}
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                style={{ width: '100%', marginTop: "5px" }}
                            >
                                <Option defaultValue value="0">All</Option>
                                <Option value="1">Assignee</Option>
                                <Option value="2">Concurrence</Option>
                                <Option value="3">Approver</Option>

                            </Select>

                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor=""><b>Unit : </b></label><br />
                            </div>

                            <Select
                                allowClear={true}
                                size="default"
                                placeholder="Select Unit"
                                disabled={dataa.d_id == "" ? true : false}
                                // onClick={() => {
                                //     if (dataa.d_id == "") {

                                //         toast.warning("Domain Required")
                                //     }
                                // }}
                                onChange={(data, value) => {
                                    if (data !== undefined) {
                                        setDataa({
                                            ...dataa,
                                            u_ids: data,
                                            unit_name: [value.children]
                                        })
                                    }
                                    else {
                                        setDataa({
                                            ...dataa,
                                            u_ids: '',
                                            unit_name: ''
                                        })
                                    }
                                }}
                                value={dataa.unit_name || undefined}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                style={{ width: '100%', marginTop: "5px" }}
                            >
                                {reassigndatalist.units && reassigndatalist.units.length > 0 && reassigndatalist.units.map((item) => {
                                    return (
                                        <Option key={item.unit_id}>
                                            {item.unit_name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>


                    </div>
                </div>
                <div className='text-center mt-2 popupbtncolour'>
                    {/* <button className="btn btn-primary btn-md" style={{ marginTop: "25px", width: "80px" }} onClick={Onsubmit1}>Show</button> */}
                    <Button type="primary" shape="round" icon={<EyeOutlined />} className={'addbutton ' + localStorage.getItem('currentTheme') } style={{ background: "#0096FF", borderColor: "#0096FF" }}
                        size='default' onClick={Onsubmit1}>
                        Show
                    </Button>
                </div>
            </Modal>
                                            </div>


                                            {
                                                showTable ?
                                                    <div>
                                                        <div className="row rounded-3 mt-1">
                                                            <div className="col-12 rounded-3 reassign_table1 reassign_card reassign_card1 reassign_card2 reassign_card2 ">

                                                                {reassignunitdata.reassign_units && reassignunitdata.reassign_units.length > 0 ?
                                                                    <Card title={unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 5 || unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 6 ? <b>Assignee</b> : unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 3 || unitcategory && unitcategory[0] && unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 4 ? <b>Concurrence</b> : unitcategory && unitcategory[0] && unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 1 || unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 3 || unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 4 ? <b>Approver</b> : <b>All</b>}
                                                                        extra={
                                                                            // <div style={{width:'160px',textAlign:'left'}}>
                                                                            //     <label> Selected Unit(s) : <span>{checkedRow1.length}</span>  </label>
                                                                            // </div>
                                                                            <div className="card p-1 m-0 overflow-hidden card-hover" style={{ borderColor: 'brown', width: '150px', paddingRight: '10px' }}>
                                                                                <div className="d-flex align-items-center">
                                                                                    <div>
                                                                                        {/* <h4 className="pt-1 mb-0">
                                                                                            <b style={{ color: 'brown' }}>&nbsp;{checkedRow1.length}</b>
                                                                                        </h4> */}
                                                                                        <h6 className="text-muted mb-0 fw-normal"><b style={{ color: 'brown' }}>Selected Units : {checkedRow1.length}</b></h6>
                                                                                    </div>
                                                                                    {/* <div className="ms-auto">
                                                                                        <span
                                                                                            className=" btn btn-m btn-light-danger text-danger btn-circle d-flex align-items-center justify-content-center">
                                                                                            <FeatherIcon icon="check-square" />
                                                                                        </span>
                                                                                    </div> */}
                                                                                </div>

                                                                            </div>
                                                                        }>
                                                                        <Table
                                                                            className={localStorage.getItem("currentTheme") + ' reassignclass1 reassignclass2 reassigntable '}
                                                                            columns={columns}
                                                                            dataSource={reassignunitdata.reassign_units.map((item, i) => {
                                                                                if (i == 0)
                                                                                    item.reassign = true
                                                                                else
                                                                                    item.reassign = false
                                                                                return item

                                                                            })}
                                                                            bordered
                                                                            pagination={false}
                                                                            rowSelection={{
                                                                                columnTitle: <input type="checkbox" name="allchecked" id="all" className='checkclass'
                                                                                    onClick={(e) => {
                                                                                        let checked = e.target.checked;
                                                                                        addAllSelectedDataValuesunit(
                                                                                            checked
                                                                                        );
                                                                                        setTriggerstate(false)

                                                                                    }}
                                                                                />,
                                                                                ...rowSelection
                                                                            }}
                                                                        />

                                                                        {/* <DataTable columns={columns}
                                                                                        data={reassignunitdata.reassign_units}
                                                                                        selectableRows
                                                                                        pagination={false}
                                                                                        onSelectedRowsChange={({
                                                                                            allSelected,
                                                                                            selectedCount,
                                                                                            selectedRows,
                                                                                            checked

                                                                                        }) => {

                                                                                            if (selectedCount > 0) {
                                                                                                setCheckedRow1(selectedRows)
                                                                                            } else {
                                                                                                setCheckedRow1([])
                                                                                            }

                                                                                        }}
                                                                                    /> */}
                                                                    </Card>
                                                                    : <Card style={{ textAlign: 'center' }}> <lable>No Records Found</lable></Card>}

                                                            </div>
                                                            {/* <div>
                                                                      
                                                                        <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "87%" }}
                                                                            size='default' onClick={OnSubmit}>
                                                                            Reassign
                                                                        </Button>
                                                                    </div> */}

                                                        </div>
                                                    </div>
                                                    : ' '
                                            }
                                        </>
                                    }
                                </div>

                            </div>
                        </div>
                    </div >

                </div >

            </div >

        </Fragment >
    )
}
export default connect(mapStateToProps, {
    reassignlist, reassignunitlist, reassigndatatable, getusercomplaince, savereassigncomplaince, GetRemarksData, showmoredata,
})(ReassignCompliance);