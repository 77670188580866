import React, { useEffect, useState, useRef, Fragment } from "react";
import { connect } from "react-redux";
import {
  Route,
  Switch,
  BrowserRouter,
  Link,
  useLocation,
} from "react-router-dom";
import {
  Collapse,
  Input,
  Modal,
  Table,
  List,
  Row,
  Select,
  Tooltip,
  Card,
  Button,
  Popover,
  Drawer,
  Space,
  Popconfirm,
} from "antd";
import {
  PlusOutlined,
  PlusSquareTwoTone,
  FullscreenOutlined,
  InfoCircleOutlined,
  SearchOutlined,
  FilterFilled,
  PlayCircleOutlined,
  PlusCircleOutlined,
  EyeOutlined,
  ExclamationCircleOutlined,
  UpOutlined,
  FilterOutlined,
  ExclamationCircleTwoTone,
  FilterTwoTone
} from "@ant-design/icons";
import SimpleReactValidator from "simple-react-validator";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import {
  ApproveTaskRecallUnitList,
  getApproveTaskRecallList,
  ApproveTaskRecallSubmit,
} from "./../../Store/Action/Transcations/ApproveTaskRecall";
import DataTable from "react-data-table-component";
import { GetRemarksData } from "./../../Store/Action/Transcations/Taskrecall";
import moment from "moment/moment";
import { Toaster } from "../../Libs/Toaster";
import ScrollToTop from "react-scroll-to-top";
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";


const mapStateToProps = (state) => ({
  list: state.ApproveTaskRecallCompliance.AssignTaskRecall,
  filterrecall: state.recallfilters.recallfilters,
});

const ApproveTaskRecall = ({
  ApproveTaskRecallUnitList,
  getApproveTaskRecallList,
  ApproveTaskRecallSubmit,
  GetRemarksData,
  filterrecall: { recallremarks },
  list,
}) => {
  console.log(list, "list");
  const [open, setOpen] = useState(false);
  // const onClose = () => {
  //     setOpen(false);
  // };
  const style = {
    normal: {
      cursor: "pointer",
    },
    hover: {
      cursor: "pointer",
      color: "blue",
    },
  };
  // const [hover, setHover] = useState(false)

  const location = useLocation();
  const paramid = location.pathname;
  const { Option } = Select;
  let temparray = [];
  const { TextArea } = Input;
  let [counts, setcounts] = useState(0);
  const [showdata, Setshowdata] = useState({
    domain: "",
    unit: "",
    frequency: "",
    asignee: "",
    legal_entity: "",
  });
  const [recalldata, Setrecalldata] = useState({
    action: "",
    remarks: "",
  });
  const [finalchild, Setfinalchild] = useState([]);

  const [childlist, Setchildlist] = useState([]);

  const [entires, setEntires] = useState(0);

  const [temparr, setTemparr] = useState([]);
  console.log(temparr, "temparr");
  const [key, setKey] = useState("1");

  const isAuth = localStorage.getItem("isAuthenticated");
  const authtoken = localStorage.getItem("authToken");
  const _ = require("lodash");
  // const entityid = localStorage.getItem('SelectedEntityid');
  const sessionValue = localStorage.getItem("sessionValue");
  const sessionParsedValue = JSON.parse(sessionValue);
  const sessionArr = sessionParsedValue.entity_info;
  const [mapFilter, setMapFilter] = useState([]);
  const [checkedRow, setCheckedRow] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [filterTaskAcc, setFilterTaskAcc] = useState(true);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [exitCollapse, setExitCollapse] = useState(false);


  const [temp, setTemp] = useState([]);
  console.log(temp, 'temp');
  const [titleDynamic, setTitleDynamic] = useState([]);

  const [checkedRowData, setCheckedRowData] = useState([
    {
      compliance_id: "",
      compliance_history_id: "",
      unit_idl: "",
      download_doc: [],
      reviewer_doc: [],
      interm_doc: [],
      interim_docs_size: "",
      reviwer_docs_size: "",
      document_size: "",
      recall_status: "",
      recall_id: "",
    },
  ]);
  const [AddFormSubmit2, setAddFormSubmit2] = useState(false);
  const [mergedArray, setMergedArray] = useState([]);
  const [filteredArray, setFilteredArray] = useState([]);
  const [filteredArrayState, setfilteredArrayState] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showValueModal, setShowValueModal] = useState(false);
  const [acccordionTrue, setAccordionTrue] = useState(false);
  const [passwordmodal, Setpasswordmodal] = useState(false);
  const [FormRecall, setFormRecall] = useState(false);
  const [FormShow, setFormShow] = useState(false);
  const formValidator = useRef(new SimpleReactValidator());
  const recallSubmit = useRef(new SimpleReactValidator());
  const formValidator2 = useRef(new SimpleReactValidator());
  const [isComplainceModalVisible, setIsComplainceModalVisible] =
    useState(false);
  const [remarksModal, setRemarksModal] = useState(false);
  const [assigneename, setAssigneename] = useState([]);

  const [updatedArrayShowmore, setUpdatedArrayShowmore] = useState([]);
  const [updatedArrayshow, setUpdatedArrayShow] = useState([]);
  const [updatedArray, setUpdatedArray] = useState([]);
  const dataSource = [
    {
      key: "1",
      compliance_task:
        "The Contract labour(Regulation Act 1997)The tamilNadu Contact Labour(Regulations)Rule 1975",
      assignee: "AS121-Assignee",
      compliance_status: "Compiled",
      due_date: "30-April-2022",
      completion_date: "28-Apri",
    },
  ];
  const [searchText, setSearchText] = useState("");
  const [all, setAll] = useState("All");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [remarksvalue, Setremarksvalue] = useState();
  const [tempFilterArray, setTempFilterArray] = useState([]);
  const [totalRecord, settotalRecord] = useState([]);

  const [dataTableProperties, setDataTableProperties] = useState({
    fixedHeader: true,
    pagesize: 10,
    sizechanger: true,
    pageSizeOptions: ["10", "30", "50"],
  });
  const [page, setPage] = useState(1);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const setcancelFilter = () => {
    setFilterModalVisible(false);
    setFilterTaskAcc(false);
  };
  useEffect(() => {
    if (recallremarks) {
      let temp = [];
      for (let i = 0; i < recallremarks.length; i++) {
        if (recallremarks[i].r_description != "") {
          temp.push(recallremarks[i]);
        }
        Setremarksvalue(temp);
      }
    }
  }, [recallremarks]);

  //  Full screen mode //
  const [fullscreen, setfullscreen] = useState(false);

  useEffect(() => {
    if (fullscreen == true) {
      document.addEventListener('fullscreenchange', handleKeyDown, false);
      document.addEventListener('webkitfullscreenchange', handleKeyDown, false);
      return () => {
        document.addEventListener('fullscreenchange', handleKeyDown, false);
        document.removeEventListener('fullscreenchange', handleKeyDown, false);
        document.removeEventListener('webkitfullscreenchange', handleKeyDown);
      };
    }
  }, [fullscreen]);

  const fullscreenMode = () => {
    if (fullscreen == false) {
      fullscreenmode();
      setfullscreen(true);
    } else {
      fullscreenexit();
      setfullscreen(false);
    }
  }

  const filterHandle = () => {
    setFilterTaskAcc(true);
    setFilterModalVisible(true);
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder="Search"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterFilled
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex] != null
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });

  useEffect(() => {
    if (list.approverecallcompliance) {
      let arrayTemp = [];
      for (let i in list.approverecallcompliance) {
        arrayTemp.push(list.approverecallcompliance[i]);
      }
      var merged = [].concat.apply([], arrayTemp);
      setUpdatedArray([...merged]);
      setUpdatedArrayShow([...merged]);
    }
  }, [list.approverecallcompliance]);

  // useEffect(() => {
  //     if (recallcomplianceshowmore) {
  //         let arrayTemp = [];
  //         for (let i in recallcomplianceshowmore) {
  //             arrayTemp.push(recallcomplianceshowmore[i])
  //         }
  //         var merged = [].concat.apply([], arrayTemp);
  //         setUpdatedArrayShowmore([...merged])

  //     }
  // }, [recallcomplianceshowmore])

  useEffect(() => {
    if (updatedArrayShowmore.length > 0) {
      setUpdatedArray([]);
      setUpdatedArray([...updatedArrayshow, ...updatedArrayShowmore]);
    }
  }, [updatedArrayShowmore]);

  const [entityid, setCurrentEntity] = useState("");

  useEffect(() => {
    if (localStorage.getItem("SelectedEntityid") != null) {
      setCurrentEntity(localStorage.getItem("SelectedEntityid"));
    }
  }, [localStorage.getItem("SelectedEntityid")]);
  useEffect(() => {
    if (temp) {
      let tempARR = [];

      for (let i in temp) {
        tempARR.push(temp[i].child);
      }
      Setchildlist(tempARR);
    }
  }, [temp]);
  useEffect(() => {
    if (finalchild && finalchild.length > 0) {
      setEntires(finalchild.length);
    }
  }, [finalchild]);
  useEffect(() => {
    let tempArr = [];
    for (let k in childlist) {
      tempArr.push(childlist[k]);
      Setfinalchild(tempArr);
    }
  }, [childlist]);
  const columns = [
    {
      title: "#",
      render: (row, index, record) => {
        return (
          <>
            <p>{counts + 1}</p>
            <span hidden>{(counts = counts + 1)}</span>
            {record && record.compliance_frequency == "Review" ? (
              <Tooltip title={record.compliance_frequency}>
                <p>
                  <i style={{ color: "orange" }} class="bi bi-square-fill"></i>
                </p>
              </Tooltip>
            ) : (
              <Tooltip title={record.compliance_frequency}>
                <p>
                  <i style={{ color: "green" }} class="bi bi-square-fill"></i>
                </p>
              </Tooltip>
            )}
          </>
        );
      },
      width: "10px",
      sortable: true,
      align: "center",
      reorder: true,
      filters: [
        {
          text: (
            <span>
              <i style={{ color: "orange" }} className="bi bi-square-fill"></i>{" "}
              Review
            </span>
          ),
          value: "Review",
        },
        {
          text: (
            <span>
              <i style={{ color: "green" }} className="bi bi-square-fill"></i>{" "}
              Periodical
            </span>
          ),
          value: "Periodical",
        },
        {
          text: (
            <span>
              <i style={{ color: "#F78453" }} className="bi bi-square-fill"></i>{" "}
              Flexi Review
            </span>
          ),
          value: "Review",
        },
        {
          text: (
            <span>
              <i style={{ color: "yellow" }} className="bi bi-square-fill"></i>{" "}
              On Occurance
            </span>
          ),
          value: "On Occurrence",
        },
        {
          text: (
            <span>
              <i style={{ color: "#F21625" }} className="bi bi-square-fill"></i>{" "}
              One Time
            </span>
          ),
          value: "One Time",
        },
      ],
      onFilter: (value, record) => record.compliance_frequency == value,
    },
    {
      title: (
        <Fragment>
          {" "}
          <span>
            <input
              type="checkbox"
              title="Click here to approve all"
              name="allchecked"
            />
          </span>
        </Fragment>
      ),
      dataIndex: false,
      key: false,
      // ellipsis: true,
      width: "10px",
      align: "center",
      render: (text, record) => {
        return (
          <Fragment>
            <input
              type="checkbox"
              title="Click here to Task Recall"
              name={"checked" + record.parentId}
              onClick={(e) => {
                let checked = e.target.checked;
                addSelectedDataValues(checked, record.compliance_history_id);
              }}
            />
          </Fragment>
        );
      },
    },
    {
      title: "Compliance Task",
      ...getColumnSearchProps("compliance_name"),

      // selector: (row) => <><p>{row.statu}</p><br></br><p>{row.compliance_name}</p></>,
      render: (row, record) => {
        const content = (
          <table
            style={{
              width: "600px",
              display: "block",
              height: "250px",
              overflowY: "scroll",
            }}
            class="table table-striped"
          >
            <tbody>
              <tr>
                <th>Unit Name</th>
                <td>{record.unit_name}</td>
              </tr>
              <tr>
                <th>Address</th>
                <td>{record.address}</td>
              </tr>
              <tr>
                <th>Division</th>
                <td>{record.division_name}</td>
              </tr>
              <tr>
                <th>Category</th>
                <td>{record.category_name}</td>
              </tr>
              <tr>
                <th>Act/Rules</th>
                <td>{record.statu}</td>
              </tr>
              <tr>
                <th>Compliance Description</th>
                <td>{record.compliance_description}</td>
              </tr>
              <tr>
                <th>Penal Consequqnces</th>
                <td>{record.penal_consequences}</td>
              </tr>
            </tbody>
          </table>
        );

        return (
          <>
            <span
              className="mt-3"
              onClick={() => {
                showValue();
                // setOpen(true)
                setValueModal({
                  compliance_history_id: record.compliance_history_id,
                  unit: record.unit_name,
                  compliance_task: record.compliance_name,
                  compliance_frequency: record.compliance_frequency,
                  document_issued_date: record.completion_date,
                  document_reference_number: record.document_reference_number,
                  next_due_date: record.next_due_date,
                  address: record.address,
                  document_size: record.document_size,
                  compliance_description: record.compliance_description,
                  uploaded_document: record.upload_docs,
                  interim_details: record.interim_docs,
                  remarks: record.remarks,
                });
              }}
              style={{ cursor: "pointer" }}
            >
              {record.statu}
            </span>
            <div className="approve-task-reacall-tooltip">
              <div>
                <Tooltip
                  placement="top"
                  overlayStyle={{ maxWidth: "420px" }}
                  title={
                    <table
                      style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        border: "1px solid #636262",
                        tableLayout: "auto",
                      }}
                    >
                      <tbody>
                        <tr>
                          <th style={{ border: "1px solid #636262" }}>
                            Unit Name
                          </th>
                          <td style={{ border: "1px solid #636262" }}>
                            {record.unit_name}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ border: "1px solid #636262" }}>
                            Address
                          </th>
                          <td style={{ border: "1px solid #636262" }}>
                            {record.address}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ border: "1px solid #636262" }}>
                            Division
                          </th>
                          <td style={{ border: "1px solid #636262" }}>
                            {record.division_name}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ border: "1px solid #636262" }}>
                            Category
                          </th>
                          <td style={{ border: "1px solid #636262" }}>
                            {record.category_name}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ border: "1px solid #636262" }}>
                            Act/Rules
                          </th>
                          <td style={{ border: "1px solid #636262" }}>
                            {record.statu}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ border: "1px solid #636262" }}>
                            Compliance Description
                          </th>
                          <td style={{ border: "1px solid #636262" }}>
                            {record.compliance_description}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ border: "1px solid #636262" }}>
                            Penal Consequqnces
                          </th>
                          <td style={{ border: "1px solid #636262" }}>
                            {record.penal_consequences}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  }
                >
                  {" "}
                  <ExclamationCircleOutlined />
                </Tooltip>
                &nbsp;{record.compliance_name}
              </div>
            </div>
          </>
        );
      },
      sortable: true,
      width: "55px",
      reorder: true,
    },
    // {
    //     title: "Assignee",
    //     dataIndex: 'assignee_name',
    //     key: 'assignee_name',
    //     sortable: true,
    //     width: '25px',
    //     reorder: true,
    //     ellipsis: true,
    //     ...getColumnSearchProps('assignee_name'),

    // },
    {
      title: "Compliance Status",
      dataIndex: "task_status",
      key: "task_status",
      sortable: true,
      width: "20px",
      reorder: true,
      ...getColumnSearchProps("task_status"),
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      key: "due_date",
      sortable: true,
      align: "center",
      width: "25px",
      reorder: true,
      // ellipsis: true,
      ...getColumnSearchProps("due_date"),
    },
    {
      title: "Completion Date",
      dataIndex: "completion_date",
      key: "completion_date",
      sortable: true,
      width: "25px",
      align: "center",
      reorder: true,
      // ellipsis: true,
      ...getColumnSearchProps("completion_date"),
    },
  ];

  const [valueModal, setValueModal] = useState({
    unit: "",
    address: "",
    compliance_history_id: "",
    compliance_task: "",
    compliance_frequency: "",
    uploaded_document: "",
    document_issued_date: "",
    document_reference_number: "",
    validity_date: "",
    next_due_date: "",
    status: "",
    remarks: "",
    action: "",
    document_size: "",
    compliance_description: "",
    interim_details: "",
  });
  const [password, Setpassword] = useState({
    passwordvalue: "",
  });

  const Remarkscolumn = [
    {
      title: "S.No.",
      dataIndex: false,
      width: "30px",
      align: "center",
      render: (text, record, index) => {
        return (page - 1) * 10 + index + 1;
      },
    },
    {
      title: "Description",
      dataIndex: "r_description",
      key: "r_description",
      width: "200px",
      ellipsis: true,
      render: (text, record) => (
        <a
          onClick={() => {
            Setrecalldata({ ...recalldata, remarks: record.r_description });
            setRemarksModal(false);
          }}
        >
          {record.r_description}
        </a>
      ),
    },
  ];
  const showValue = () => {
    setShowValueModal(true);
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const passwordcancel = () => {
    Setpasswordmodal(false);
    setAddFormSubmit2(false);
  };

  useEffect(() => {
    if (list.approverecallcompliance !== null) {
      settotalRecord(list.approverecallcompliance.length);
      let totalList = [];
      let arr = [];
      let countrylisttempdata = [];
      let tempSetArray = [];
      let parId = [];
      list.approverecallcompliance &&
        list.approverecallcompliance.length > 0 &&
        list.approverecallcompliance.map(function (user) {
          if (arr.indexOf(user.unit_name) < 0) arr.push(user.unit_name);
        });

      arr.map((pmenu, index) => {
        let entityArray = [];
        let tempArray = [];

        if (pmenu) {
          list.approverecallcompliance &&
            list.approverecallcompliance.map((subMenuList, childIndex) => {
              if (pmenu === subMenuList.unit_name) {
                entityArray = {
                  compliance_history_id: subMenuList.compliance_history_id,
                  compliance_name: subMenuList.compliance_name,
                  compliance_frequency: subMenuList.compliance_frequency,
                  domain_name: subMenuList.domain_name,
                  domain_id: subMenuList.domain_id,
                  interim_count: subMenuList.interim_count,
                  unit_id: subMenuList.unit_id,
                  duration_type: subMenuList.duration_type,
                  validity_settings_days: subMenuList.validity_settings_days,
                  assigned_on: subMenuList.assigned_on,
                  start_date: subMenuList.start_date,
                  due_date: moment(subMenuList.due_date).format("DD-MMM-YYYY"),
                  reviwer_docs: subMenuList.reviwer_docs,
                  validity_date: subMenuList.validity_date,
                  next_due_date: subMenuList.next_due_date,
                  assignee_name: subMenuList.assignee_name,
                  document_size: subMenuList.document_size,
                  approved_on: subMenuList.approved_on,
                  format_file_name: subMenuList.format_file_name,
                  unit_name: subMenuList.unit_name,
                  remarks_count: subMenuList.remarks_count,
                  address: subMenuList.address,
                  compliance_description: subMenuList.compliance_description,
                  statu: subMenuList.statu,
                  remarks: subMenuList.remarks,
                  occurrence_remarks: subMenuList.occurrence_remarks,
                  compliance_id: subMenuList.compliance_id,
                  download_url: subMenuList.download_url,
                  file_names: subMenuList.file_names,
                  history_count: subMenuList.history_count,
                  tz_date: subMenuList.tz_date,
                  tz_name: subMenuList.tz_name,
                  comp_history_count: subMenuList.comp_history_count,
                  system_remarks: subMenuList.system_remarks,
                  division_name: subMenuList.division_name,
                  category_name: subMenuList.category_name,
                  document_reference_number:
                    subMenuList.document_reference_number,
                  upload_docs: subMenuList.upload_docs,
                  recall_status: subMenuList.recall_status,
                  penal_consequences: subMenuList.penal_consequences,
                  interim_docs: subMenuList.interim_docs,
                  interm_newdoc: subMenuList.interm_newdoc,
                  reviwer_newdoc: subMenuList.reviwer_newdoc,
                  country_id: subMenuList.country_id,
                  recall_id: subMenuList.recall_id,
                  interim_docs_size: subMenuList.interim_docs_size,
                  reviwer_docs_size: subMenuList.reviwer_docs_size,
                  completion_date: moment(subMenuList.completion_date).format(
                    "DD-MMM-YYYY"
                  ),
                  approve_status: subMenuList.approve_status,
                  assignee_remarks: subMenuList.assignee_remarks,
                  extn_due_date: subMenuList.extn_due_date,
                  task_status: subMenuList.task_status,
                  compfielaw_url: subMenuList.compfielaw_url,
                  parentId: index,
                  childId: childIndex,
                };
                tempArray.push(entityArray);
              }
            });
        }
        let checkarray = []
        let listArray_One_time = _.filter(tempArray, { compliance_frequency: "One Time" });
        if (listArray_One_time && listArray_One_time.length > 0) {
          for (let i in listArray_One_time) {
            checkarray.push(listArray_One_time[i])
          }
        }
        let listArray_periodical = _.filter(tempArray, { compliance_frequency: "Periodical" });
        if (listArray_periodical && listArray_periodical.length > 0) {
          for (let i in listArray_periodical) {
            checkarray.push(listArray_periodical[i])
          }
        }
        let listArray_Review = _.filter(tempArray, { compliance_frequency: "Review" });
        if (listArray_Review && listArray_Review.length > 0) {
          for (let i in listArray_Review) {
            checkarray.push(listArray_Review[i])
          }
        }
        let listArray_Flexi_Review = _.filter(tempArray, { compliance_frequency: "Flexi Review" });
        if (listArray_Flexi_Review && listArray_Flexi_Review.length > 0) {
          for (let i in listArray_Flexi_Review) {
            checkarray.push(listArray_Flexi_Review[i])
          }
        }
        let listArray_On_Occurrence = _.filter(tempArray, { compliance_frequency: "On Occurrence" });
        if (listArray_On_Occurrence && listArray_On_Occurrence.length > 0) {
          for (let i in listArray_On_Occurrence) {
            checkarray.push(listArray_On_Occurrence[i])
          }
        }
        totalList = {
          index: index,
          parentData: pmenu,
          child: checkarray,
        };
        titleDynamic[index] = pmenu;
        countrylisttempdata.push(totalList);
      });
      setTitleDynamic([...titleDynamic]);
      var merged = [].concat.apply([], countrylisttempdata);
      setTemp([...temp, ...merged]);
      //  setData(tempSetArray)
      //getData()
    }
  }, [list.approverecallcompliance]);

  useEffect(() => {
    if (updatedArray && updatedArray.length > 0) {
      let uniqueObjArray = [
        ...new Map(
          updatedArray &&
          updatedArray.length > 0 &&
          updatedArray.map((item) => [item["unit_id"], item])
        ).values(),
      ];
      const uniqueIds =
        uniqueObjArray &&
        uniqueObjArray.map((item) => {
          return item.unit_id;
        });
      let tempArr = [];
      for (let i in uniqueIds) {
        let filteredArr = _.filter(
          updatedArray && updatedArray.length > 0 && updatedArray,
          { unit_id: uniqueIds[i] }
        );
        tempArr.push(filteredArr);
      }
      setFilteredArray([tempArr]);
    } else {
      setFilteredArray([]);
    }
  }, [updatedArray]);

  useEffect(() => {
    let tempArr = [];
    if (updatedArray && updatedArray.length > 0) {
      filteredArrayState &&
        filteredArrayState.length > 0 &&
        filteredArrayState.map((item, i) => {
          let uniqueObjArray = [
            ...new Map(
              item &&
              item.length > 0 &&
              item.map((items) => [items["assignee_name"], items])
            ).values(),
          ];
          const uniqueIds =
            uniqueObjArray.length > 0 &&
            uniqueObjArray.map((itemData) => {
              return itemData.assignee_name;
            });
          for (let i in uniqueIds) {
            let filteredArr = _.find(item && item.length > 0 && item, {
              assignee_name: uniqueIds[i],
            });
            tempArr.push(filteredArr);
          }
        });
      setAssigneename(tempArr);
    }
  }, [filteredArrayState]);

  useEffect(() => {
    if (updatedArray !== null) {
      let totalList = [];
      let arr = [];
      let countrylisttempdata = [];
      let tempSetArray = [];
      let parId = [];
      assigneename &&
        assigneename.length > 0 &&
        assigneename.map(function (user) {
          if (arr.indexOf(user.assignee_name) < 0)
            arr.push({
              assignee_name: user.assignee_name,
              unit_name: user.unit_name,
            });
        });

      arr.map((pmenu, index) => {
        // deepscan-disable-line ARRAY_CALLBACK_RETURN_MISSING
        let entityArray = [];
        let tempArray = [];
        let unit_name = [];
        if (pmenu) {
          updatedArray &&
            updatedArray.map((subMenuList, childIndex) => {
              if (
                pmenu.assignee_name === subMenuList.assignee_name &&
                pmenu.unit_name === subMenuList.unit_name
              ) {
                entityArray = {
                  address: subMenuList.address,
                  approve_status: subMenuList.approve_status,
                  approved_on: subMenuList.approved_on,
                  assigned_on: subMenuList.assigned_on,
                  assignee_name: subMenuList.assignee_name,
                  category_name: subMenuList.category_name,
                  compfielaw_url: subMenuList.compfielaw_url,
                  completion_date: subMenuList.completion_date,
                  compliance_description: subMenuList.compliance_description,
                  compliance_frequency: subMenuList.compliance_frequency,
                  compliance_history_id: subMenuList.compliance_history_id,
                  compliance_id: subMenuList.compliance_id,
                  compliance_name: subMenuList.compliance_name,
                  country_id: subMenuList.country_id,
                  division_name: subMenuList.division_name,
                  document_reference_number:
                    subMenuList.document_reference_number,
                  document_size: subMenuList.document_size,
                  domain_id: subMenuList.domain_id,
                  domain_name: subMenuList.domain_name,
                  download_url: subMenuList.download_url,
                  due_date: subMenuList.due_date,
                  duration_type: subMenuList.duration_type,
                  extn_due_date: subMenuList.extn_due_date,
                  file_names: subMenuList.file_names,
                  format_file_name: subMenuList.format_file_name,
                  history_count: subMenuList.history_count,
                  interim_count: subMenuList.interim_count,
                  interim_docs: subMenuList.interim_docs,
                  interim_docs_size: subMenuList.interim_docs_size,
                  interm_newdoc: subMenuList.interm_newdoc,
                  next_due_date: subMenuList.next_due_date,
                  occurrence_remarks: subMenuList.occurrence_remarks,
                  penal_consequences: subMenuList.penal_consequences,
                  recall_id: subMenuList.recall_id,
                  recall_status: subMenuList.recall_status,
                  remarks: subMenuList.remarks,
                  remarks_count: subMenuList.remarks_count,
                  reviwer_docs: subMenuList.reviwer_docs,
                  reviwer_docs_size: subMenuList.reviwer_docs_size,
                  reviwer_newdoc: subMenuList.reviwer_newdoc,
                  start_date: subMenuList.start_date,
                  statu: subMenuList.statu,
                  system_remarks: subMenuList.system_remarks,
                  task_status: subMenuList.task_status,
                  tz_date: subMenuList.tz_date,
                  tz_name: subMenuList.tz_name,
                  unit_id: subMenuList.unit_id,
                  unit_name: subMenuList.unit_name,
                  upload_docs: subMenuList.upload_docs,
                  validity_date: subMenuList.validity_date,
                  validity_settings_days: subMenuList.validity_settings_days,
                  parentId: index,
                  childId: childIndex,
                  indexNo: childIndex + 1,
                };
                tempArray.push(entityArray);
                unit_name.push(subMenuList.unit_name);
              }
            });
        }
        let checkarray = []
        let listArray_One_time = _.filter(tempArray, { compliance_frequency: "One Time" });
        if (listArray_One_time && listArray_One_time.length > 0) {
          for (let i in listArray_One_time) {
            checkarray.push(listArray_One_time[i])
          }
        }
        let listArray_periodical = _.filter(tempArray, { compliance_frequency: "Periodical" });
        if (listArray_periodical && listArray_periodical.length > 0) {
          for (let i in listArray_periodical) {
            checkarray.push(listArray_periodical[i])
          }
        }
        let listArray_Review = _.filter(tempArray, { compliance_frequency: "Review" });
        if (listArray_Review && listArray_Review.length > 0) {
          for (let i in listArray_Review) {
            checkarray.push(listArray_Review[i])
          }
        }
        let listArray_Flexi_Review = _.filter(tempArray, { compliance_frequency: "Flexi Review" });
        if (listArray_Flexi_Review && listArray_Flexi_Review.length > 0) {
          for (let i in listArray_Flexi_Review) {
            checkarray.push(listArray_Flexi_Review[i])
          }
        }
        let listArray_On_Occurrence = _.filter(tempArray, { compliance_frequency: "On Occurrence" });
        if (listArray_On_Occurrence && listArray_On_Occurrence.length > 0) {
          for (let i in listArray_On_Occurrence) {
            checkarray.push(listArray_On_Occurrence[i])
          }
        }
        totalList = {
          index: index,
          parentData: pmenu,
          child: checkarray,
        };
        titleDynamic[index] = pmenu;
        countrylisttempdata.push(totalList);
        tempSetArray.push(tempArray);
        parId.push(unit_name);
      });
      setTitleDynamic([...titleDynamic]);
      var merged = [].concat.apply([], tempSetArray);
      setTemp(countrylisttempdata);
      setMergedArray(merged);
      setTempFilterArray([...new Set([].concat.apply([], parId))]);
    }
  }, [updatedArray, assigneename]);

  const frequencyfilter = (data) => {
    setAll(data);
    let filterTable;
    if (data == "All") {
      filterTable = updatedArray;
      settotalRecord(filterTable.length);
    } else {
      filterTable = updatedArray.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(data.toLowerCase())
        )
      );
      settotalRecord(filterTable.length);
    }
    let totalList = [];
    let arr = [];
    let countrylisttempdata = [];
    let tempSetArray = [];
    let parId = [];
    if (filterTable && filterTable.length > 0) {
      assigneename &&
        assigneename.length > 0 &&
        assigneename.map(function (user) {
          if (arr.indexOf(user.assignee_name) < 0)
            arr.push({
              assignee_name: user.assignee_name,
              unit_name: user.unit_name,
            });
        });

      arr.map((pmenu, index) => {
        // deepscan-disable-line ARRAY_CALLBACK_RETURN_MISSING
        let entityArray = [];
        let tempArray = [];
        let unit_name = [];
        if (pmenu) {
          filterTable &&
            filterTable.map((subMenuList, childIndex) => {
              if (
                pmenu.assignee_name === subMenuList.assignee_name &&
                pmenu.unit_name === subMenuList.unit_name
              ) {
                entityArray = {
                  address: subMenuList.address,
                  approve_status: subMenuList.approve_status,
                  approved_on: subMenuList.approved_on,
                  assigned_on: subMenuList.assigned_on,
                  assignee_name: subMenuList.assignee_name,
                  category_name: subMenuList.category_name,
                  compfielaw_url: subMenuList.compfielaw_url,
                  completion_date: subMenuList.completion_date,
                  compliance_description: subMenuList.compliance_description,
                  compliance_frequency: subMenuList.compliance_frequency,
                  compliance_history_id: subMenuList.compliance_history_id,
                  compliance_id: subMenuList.compliance_id,
                  compliance_name: subMenuList.compliance_name,
                  country_id: subMenuList.country_id,
                  division_name: subMenuList.division_name,
                  document_reference_number:
                    subMenuList.document_reference_number,
                  document_size: subMenuList.document_size,
                  domain_id: subMenuList.domain_id,
                  domain_name: subMenuList.domain_name,
                  download_url: subMenuList.download_url,
                  due_date: subMenuList.due_date,
                  duration_type: subMenuList.duration_type,
                  extn_due_date: subMenuList.extn_due_date,
                  file_names: subMenuList.file_names,
                  format_file_name: subMenuList.format_file_name,
                  history_count: subMenuList.history_count,
                  interim_count: subMenuList.interim_count,
                  interim_docs: subMenuList.interim_docs,
                  interim_docs_size: subMenuList.interim_docs_size,
                  interm_newdoc: subMenuList.interm_newdoc,
                  next_due_date: subMenuList.next_due_date,
                  occurrence_remarks: subMenuList.occurrence_remarks,
                  penal_consequences: subMenuList.penal_consequences,
                  recall_id: subMenuList.recall_id,
                  recall_status: subMenuList.recall_status,
                  remarks: subMenuList.remarks,
                  remarks_count: subMenuList.remarks_count,
                  reviwer_docs: subMenuList.reviwer_docs,
                  reviwer_docs_size: subMenuList.reviwer_docs_size,
                  reviwer_newdoc: subMenuList.reviwer_newdoc,
                  start_date: subMenuList.start_date,
                  statu: subMenuList.statu,
                  system_remarks: subMenuList.system_remarks,
                  task_status: subMenuList.task_status,
                  tz_date: subMenuList.tz_date,
                  tz_name: subMenuList.tz_name,
                  unit_id: subMenuList.unit_id,
                  unit_name: subMenuList.unit_name,
                  upload_docs: subMenuList.upload_docs,
                  validity_date: subMenuList.validity_date,
                  validity_settings_days: subMenuList.validity_settings_days,
                  parentId: index,
                  childId: childIndex,
                  indexNo: childIndex + 1,
                };
                tempArray.push(entityArray);
                unit_name.push(subMenuList.unit_name);
              }
            });
        }
        totalList = {
          index: index,
          parentData: pmenu,
          child: tempArray,
        };
        titleDynamic[index] = pmenu;
        countrylisttempdata.push(totalList);
        tempSetArray.push(tempArray);
        parId.push(unit_name);
      });
      console.log([...new Set([].concat.apply([], parId))], "parIdId");
      setTitleDynamic([...titleDynamic]);
      var merged = [].concat.apply([], tempSetArray);
      setTemp(countrylisttempdata);
      setMergedArray(merged);
      setTempFilterArray([...new Set([].concat.apply([], parId))]);
    } else {
      setMergedArray([]);
      setTemp([]);
      setTitleDynamic([]);
      setTempFilterArray([]);
    }
  };

  const compliancetaskfilter = (e, type) => {
    let filterTable;
    if (type == "Compliance_Task") {
      filterTable = updatedArray.filter((o) => {
        if (all == "All") {
          if (o["statu"].toLowerCase().includes(e.toLowerCase())) {
            return true;
          }
        } else {
          if (
            o["statu"].toLowerCase().includes(e.toLowerCase()) &&
            o["compliance_frequency"].toLowerCase().includes(all.toLowerCase())
          ) {
            return true;
          }
        }
      });
    } else if (type == "Assignee") {
      filterTable = updatedArray.filter((o) => {
        if (all == "All") {
          if (o["assignee_name"].toLowerCase().includes(e.toLowerCase())) {
            return true;
          }
        } else {
          if (
            o["assignee_name"].toLowerCase().includes(e.toLowerCase()) &&
            o["compliance_frequency"].toLowerCase().includes(all.toLowerCase())
          ) {
            return true;
          }
        }
      });
    } else if (type == "Compliance_Status") {
      filterTable = updatedArray.filter((o) => {
        if (all == "All") {
          if (o["task_status"].toLowerCase().includes(e.toLowerCase())) {
            return true;
          }
        } else {
          if (
            o["task_status"].toLowerCase().includes(e.toLowerCase()) &&
            o["compliance_frequency"].toLowerCase().includes(all.toLowerCase())
          ) {
            return true;
          }
        }
      });
    } else if (type == "Due_Date") {
      filterTable = updatedArray.filter((o) => {
        if (all == "All") {
          if (o["due_date"].toLowerCase().includes(e.toLowerCase())) {
            return true;
          }
        } else {
          if (
            o["due_date"].toLowerCase().includes(e.toLowerCase()) &&
            o["compliance_frequency"].toLowerCase().includes(all.toLowerCase())
          ) {
            return true;
          }
        }
      });
    } else if (type == "Completion_Date") {
      filterTable = updatedArray.filter((o) => {
        if (all == "All") {
          if (o["completion_date"].toLowerCase().includes(e.toLowerCase())) {
            return true;
          }
        } else {
          if (
            o["completion_date"].toLowerCase().includes(e.toLowerCase()) &&
            o["compliance_frequency"].toLowerCase().includes(all.toLowerCase())
          ) {
            return true;
          }
        }
      });
    }

    let totalList = [];
    let arr = [];
    let countrylisttempdata = [];
    let tempSetArray = [];
    let parId = [];
    if (filterTable && filterTable.length > 0) {
      assigneename &&
        assigneename.length > 0 &&
        assigneename.map(function (user) {
          if (arr.indexOf(user.assignee_name) < 0)
            arr.push({
              assignee_name: user.assignee_name,
              unit_name: user.unit_name,
            });
        });

      arr.map((pmenu, index) => {
        // deepscan-disable-line ARRAY_CALLBACK_RETURN_MISSING
        let entityArray = [];
        let tempArray = [];
        let unit_name = [];
        if (pmenu) {
          filterTable &&
            filterTable.map((subMenuList, childIndex) => {
              if (
                pmenu.assignee_name === subMenuList.assignee_name &&
                pmenu.unit_name === subMenuList.unit_name
              ) {
                entityArray = {
                  address: subMenuList.address,
                  approve_status: subMenuList.approve_status,
                  approved_on: subMenuList.approved_on,
                  assigned_on: subMenuList.assigned_on,
                  assignee_name: subMenuList.assignee_name,
                  category_name: subMenuList.category_name,
                  compfielaw_url: subMenuList.compfielaw_url,
                  completion_date: subMenuList.completion_date,
                  compliance_description: subMenuList.compliance_description,
                  compliance_frequency: subMenuList.compliance_frequency,
                  compliance_history_id: subMenuList.compliance_history_id,
                  compliance_id: subMenuList.compliance_id,
                  compliance_name: subMenuList.compliance_name,
                  country_id: subMenuList.country_id,
                  division_name: subMenuList.division_name,
                  document_reference_number:
                    subMenuList.document_reference_number,
                  document_size: subMenuList.document_size,
                  domain_id: subMenuList.domain_id,
                  domain_name: subMenuList.domain_name,
                  download_url: subMenuList.download_url,
                  due_date: subMenuList.due_date,
                  duration_type: subMenuList.duration_type,
                  extn_due_date: subMenuList.extn_due_date,
                  file_names: subMenuList.file_names,
                  format_file_name: subMenuList.format_file_name,
                  history_count: subMenuList.history_count,
                  interim_count: subMenuList.interim_count,
                  interim_docs: subMenuList.interim_docs,
                  interim_docs_size: subMenuList.interim_docs_size,
                  interm_newdoc: subMenuList.interm_newdoc,
                  next_due_date: subMenuList.next_due_date,
                  occurrence_remarks: subMenuList.occurrence_remarks,
                  penal_consequences: subMenuList.penal_consequences,
                  recall_id: subMenuList.recall_id,
                  recall_status: subMenuList.recall_status,
                  remarks: subMenuList.remarks,
                  remarks_count: subMenuList.remarks_count,
                  reviwer_docs: subMenuList.reviwer_docs,
                  reviwer_docs_size: subMenuList.reviwer_docs_size,
                  reviwer_newdoc: subMenuList.reviwer_newdoc,
                  start_date: subMenuList.start_date,
                  statu: subMenuList.statu,
                  system_remarks: subMenuList.system_remarks,
                  task_status: subMenuList.task_status,
                  tz_date: subMenuList.tz_date,
                  tz_name: subMenuList.tz_name,
                  unit_id: subMenuList.unit_id,
                  unit_name: subMenuList.unit_name,
                  upload_docs: subMenuList.upload_docs,
                  validity_date: subMenuList.validity_date,
                  validity_settings_days: subMenuList.validity_settings_days,
                  parentId: index,
                  childId: childIndex,
                  indexNo: childIndex + 1,
                };
                tempArray.push(entityArray);
                unit_name.push(subMenuList.unit_name);
              }
            });
        }
        totalList = {
          index: index,
          parentData: pmenu,
          child: tempArray,
        };
        titleDynamic[index] = pmenu;
        countrylisttempdata.push(totalList);
        tempSetArray.push(tempArray);
        parId.push(unit_name);
      });
      console.log([...new Set([].concat.apply([], parId))], "parIdId");
      setTitleDynamic([...titleDynamic]);
      var merged = [].concat.apply([], tempSetArray);
      setTemp(countrylisttempdata);
      setMergedArray(merged);
      setTempFilterArray([...new Set([].concat.apply([], parId))]);
    } else {
      setMergedArray([]);
      setTemp([]);
      setTitleDynamic([]);
      setTempFilterArray([]);
    }
  };

  useEffect(() => {
    if (filteredArray.length > 0) {
      {
        filteredArray.map((item) => {
          return setMapFilter(item);
        });
      }
    } else {
      setMapFilter([]);
    }
  }, [filteredArray]);

  useEffect(() => {
    if (filteredArray.length > 0) {
      let temp = [];
      for (let i in filteredArray) {
        temp = filteredArray[i];
      }
      setfilteredArrayState([...temp]);
    }
  }, [filteredArray]);

  useEffect(() => {
    if (updatedArray && updatedArray.length > 0) {
      let uniqueObjArray = [
        ...new Map(
          updatedArray &&
          updatedArray.length > 0 &&
          updatedArray.map((item) => [item["unit_id"], item])
        ).values(),
      ];
      const uniqueIds =
        uniqueObjArray &&
        uniqueObjArray.map((item) => {
          return item.unit_id;
        });
      let tempArr = [];
      for (let i in uniqueIds) {
        let filteredArr = _.filter(
          updatedArray && updatedArray.length > 0 && updatedArray,
          { unit_id: uniqueIds[i] }
        );
        tempArr.push(filteredArr);
      }
      setFilteredArray([tempArr]);
    } else {
      setFilteredArray([]);
    }
  }, [updatedArray]);

  const gettooltipfun = (record) => {
    const content = (
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          border: "1px solid #636262",
          tableLayout: "auto",
        }}
      >
        <tbody>
          <tr>
            <th style={{ border: "1px solid #636262" }}>
              <b>Unit Name</b>
            </th>
            <td style={{ border: "1px solid #636262" }}>
              <b>{record.unit_name}</b>
            </td>
          </tr>
          <tr>
            <th style={{ border: "1px solid #636262" }}>
              <b>Address</b>
            </th>
            <td style={{ border: "1px solid #636262" }}>
              <b>{record.address}</b>
            </td>
          </tr>
          <tr>
            <th style={{ border: "1px solid #636262" }}>
              <b>Division</b>
            </th>
            <td style={{ border: "1px solid #636262" }}>
              <b>{record.division_name}</b>
            </td>
          </tr>
          <tr>
            <th style={{ border: "1px solid #636262" }}>
              <b>Category</b>
            </th>
            <td style={{ border: "1px solid #636262" }}>
              <b>{record.category_name}</b>
            </td>
          </tr>
          <tr>
            <th style={{ border: "1px solid #636262" }}>
              <b>Act/Rules</b>
            </th>
            <td style={{ border: "1px solid #636262" }}>
              <b>{record.statu}</b>
            </td>
          </tr>
          <tr>
            <th style={{ border: "1px solid #636262" }}>
              <b>Compliance Description</b>
            </th>
            <td style={{ border: "1px solid #636262" }}>
              <b>{record.compliance_description}</b>
            </td>
          </tr>
          <tr>
            <th style={{ border: "1px solid #636262" }}>
              <b>Penal Consequqnces</b>
            </th>
            <td style={{ border: "1px solid #636262" }}>
              <b>{record.penal_consequences}</b>
            </td>
          </tr>
        </tbody>
      </table>
    );
    return content;
  };

  // const submitpassword = (e) => {
  //     e.preventDefault();
  //     const payload = [
  //         authtoken,
  //         {
  //             "session_token": authtoken,
  //             "request": [
  //                 "",
  //                 {
  //                     "le_id": password.le_id,
  //                     "d_id": password.d_id,
  //                     "u_id": password.u_id,
  //                     "lock": password.lock,
  //                     "password": password.passwordvalue
  //                 }
  //             ]
  //         }
  //     ]
  //     // [
  //     //     authtoken,
  //     //     {
  //     //         "session_token": authtoken,
  //     //         "request": [
  //     //             "UpdateTaskRecallStatus",
  //     //             {
  //     //                 "le_id": entityid,
  //     //                 "check_list": [
  //     //                     {
  //     //                         "compliance_id": 8190,
  //     //                         "compliance_history_id": 91,
  //     //                         "unit_idl": 38884,
  //     //                         "download_doc": [],
  //     //                         "reviewer_doc": [],
  //     //                         "interm_doc": [],
  //     //                         "interim_docs_size": 0,
  //     //                         "reviwer_docs_size": 0,
  //     //                         "document_size": 0,
  //     //                         "recall_status": 0,
  //     //                         "recall_id": null
  //     //                     }
  //     //                 ],
  //     //                 "action": "1",
  //     //                 "remarks": "test",
  //     //                 "password": "Info@123",
  //     //                 "pwd_validate": true
  //     //             }
  //     //         ]
  //     //     }
  //     // ]
  //     Setpasswordmodal(false);
  // }
  const submitpassword = (e) => {
    setAddFormSubmit2(true);

    e.preventDefault();
    if (formValidator2.current.allValid()) {
      let temp = [];
      for (let i in temparr) {
        temp.push({
          compliance_id: temparr[i].compliance_id,
          compliance_history_id: temparr[i].compliance_history_id,
          unit_idl: temparr[i].unit_id,
          download_doc: temparr[i].download_url,
          reviewer_doc: temparr[i].reviwer_newdoc,
          interm_doc: temparr[i].interm_newdoc,
          interim_docs_size: Number(temparr[i].interim_docs_size),
          reviwer_docs_size: Number(temparr[i].reviwer_docs_size),
          document_size: temparr[i].document_size,
          recall_status: temparr[i].recall_status,
          recall_id: temparr[i].recall_id,
        });
      }

      ApproveTaskRecallSubmit({
        payload: [
          authtoken,
          {
            session_token: authtoken,
            request: [
              "UpdateTaskRecallStatus",
              {
                le_id: entityid,
                check_list: temp,
                action: recalldata.action,
                remarks: recalldata.remarks,
                password: password.passwordvalue,
                pwd_validate: true,
              },
            ],
          },
        ],
        paramid: paramid,
      });
      Setpasswordmodal(false);
    }
  };

  const showRemarksModal = () => {
    GetRemarksData({
      payload: [
        authtoken,
        {
          session_token: authtoken,
          request: ["GetRemarksData", {}],
        },
      ],
      paramid: paramid,
    });
    setRemarksModal(true);
  };
  const Active = () => {
    if (temparr.length > 0) {
      setFormRecall(true);
    } else {
      toast.error("Please Select Compliance to recall");
    }
    if (recallSubmit.current.allValid()) {
      if (temparr.length !== 0) {
        Swal.fire({
          title: "Are you sure?",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.isConfirmed) {
            Setpasswordmodal(true);
          }
        });
      }
    }
  };
  // const data = [
  //     'Racing car sprays burning fuel into crowd.',
  //     'Japanese princess to wed commoner.',
  // ];
  const { Panel } = Collapse;
  const { Search } = Input;

  // const showModal = () => {
  //     setIsModalVisible(true);
  // };

  const handleOk = () => {
    setIsModalVisible(false);
    setIsComplainceModalVisible(false);
    setRemarksModal(false);
    setShowValueModal(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsComplainceModalVisible(false);
    setRemarksModal(false);
    setShowValueModal(false);
  };
  useEffect(() => {
    formValidator.current.showMessages();
    recallSubmit.current.showMessages();
    formValidator2.current.showMessages();
  }, []);
  useEffect(() => {
    if (isAuth) {
      if (entityid !== "") {
        const payload = [
          authtoken,
          {
            session_token: authtoken,
            request: [
              "TaskRecallFilters",
              {
                le_id: entityid,
              },
            ],
          },
        ];
        if (entityid != "null") {
          ApproveTaskRecallUnitList({
            payload: payload,
            paramid: paramid,
          });
        }
        Setshowdata({
          ...showdata,
          domain: "",
          unit: "",
          frequency: "",
          asignee: "",
        });
      }
    }
  }, [isAuth, entityid]);

  const showComplianceModal = () => {
    setIsComplainceModalVisible(true);
  };

  const gettaskrecalllist = () => {
    setFormShow(true);
    setTemp([]);
    setKey("0");
    setAssigneename([]);
    setfilteredArrayState([]);
    setUpdatedArray([]);
    setAll("All");
    if (formValidator.current.allValid()) {
      setExitCollapse(true);
      setFilterModalVisible(false);
      setFilterTaskAcc(false);
      setAccordionTrue(true);
      getApproveTaskRecallList({
        payload: [
          authtoken,
          {
            session_token: authtoken,
            request: [
              "GetTaskRecallList",
              {
                le_id: entityid,
                // "unit_id": Number(showdata.unit),
                unit_id: null,
                d_id: Number(showdata.domain),
                frequency_id: Number(showdata.frequency),
                compl_date: "February-2022",
                parent_id: null,
                user_id: null,
                start_count: 0,
                task_status: "Approver Task Recall",
              },
            ],
          },
        ],
        paramid: paramid,
      });
    }
  };
  useEffect(() => {
    let temparr = [];
    for (let i in checkedRow) {
      temparr.push({
        compliance_id: checkedRow[i].compliance_id,
        compliance_history_id: checkedRow[i].compliance_history_id,
        unit_idl: checkedRow[i].unit_id,
        download_doc: [],
        reviewer_doc: checkedRow[i].reviewer_doc,
        interim_docs_size: checkedRow[i].interim_docs_size,
        reviwer_docs_size: checkedRow[i].reviwer_docs_size,
        document_size: checkedRow[i].document_size,
        recall_status: checkedRow[i].recall_status,
        recall_id: checkedRow[i].recall_id,
      });
    }
    setCheckedRowData(temparr);
  }, [checkedRow]);

  useEffect(() => {
    if (
      list &&
      list.approverecallcompliance &&
      list.approverecallcompliance.length > 0
    ) {
      let uniqueObjArray = [
        ...new Map(
          list &&
          list.approverecallcompliance &&
          list.approverecallcompliance.length &&
          list.approverecallcompliance.map((item) => [item["unit_id"], item])
        ).values(),
      ];
      const uniqueIds =
        uniqueObjArray &&
        uniqueObjArray.map((item) => {
          return item.unit_id;
        });
      let tempArr = [];
      for (let i in uniqueIds) {
        let filteredArr = _.filter(list && list.approverecallcompliance, {
          unit_id: uniqueIds[i],
        });
        tempArr.push(filteredArr);
        setFilteredArray([...filteredArray, tempArr]);
      }
    }
  }, [list && list.approverecallcompliance]);
  const addAllSelectedDataValues = (checked) => {
    if (mergedArray.length > 0) {
      let tempArray = temparr;
      var ele = document.getElementsByName("checked");
      if (checked === true) {
        setCheckedRow(true);
        for (let i in mergedArray) {
          tempArray.push(mergedArray[i]);
        }
        /*Remove Duplicate*/
        let compliance_id = tempArray.map((item) => {
          return item.compliance_id;
        });
        var difference = tempArray.filter(
          (x) => compliance_id.indexOf(x) === -1
        );
        let finaldata = [...new Set(difference)];
        /*Remove Duplicate*/

        setTemparr([...finaldata]);
        for (var i = 0; i < ele.length; i++) {
          if (ele[i].type === "checkbox") ele[i].checked = true;
        }
      } else {
        setCheckedRow(false);
        tempArray = [];
        setTemparr([...tempArray]);
        for (var j = 0; j < ele.length; j++) {
          if (ele[j].type === "checkbox") ele[j].checked = false;
        }
      }
    } else {
      var eleclass = document.getElementsByClassName("allChecked");
      eleclass[0].checked = false;
      Toaster.error("No Compliance Available");
    }
  };
  const addSelectedDataValues = (checked, compliance_history_id, parentId) => {
    var ele = document.getElementsByName("allchecked");
    var eleclass = document.getElementsByClassName("allChecked");
    let array;
    let array1;
    let array2;
    let tempArray = temparr;
    if (checkedRow === true) {
      if (checked === true) {
        // array = _.find(recallcompliance, { compliance_history_id: compliance_history_id });
        // tempArray.push(array)
        array1 = _.filter(temp, { index: parentId });
        array = _.find(mergedArray, { index: parentId });
        tempArray.push(array);
        array2 = _.filter(tempArray, { index: parentId });
        if (array2.length === array1[0].child.length) {
          eleclass[0].checked = true;
        }
      } else {
        for (let i in tempArray) {
          if (tempArray[i].compliance_history_id === compliance_history_id) {
            tempArray.splice(i, 1);
          }
          if (updatedArray.length !== temparr.length) {
            setCheckedRow(false);
            eleclass[0].checked = false;
          }
        }
      }
      setTemparr([...tempArray]);
    } else {
      if (checked === true) {
        array = _.find(mergedArray, {
          compliance_history_id: compliance_history_id,
        });
        tempArray.push(array);
        if (tempArray.length === mergedArray.length) {
          eleclass[0].checked = true;
        }
      } else {
        for (let i in tempArray) {
          if (tempArray[i].compliance_history_id === compliance_history_id) {
            tempArray.splice(i, 1);
          }
        }
        eleclass[0].checked = false;
      }
      setTemparr([...tempArray]);
    }
  };
  const homescreen = () => {
    window.location.href = "/dashboard";
  };

  return (
    <>
      <ScrollToTop
        smooth
        color="red"
        component={
          <Tooltip title="Back to top">
            <UpOutlined />
          </Tooltip>
        }
      />
      <div id="page-wrapper" className="page-wrapper">
        <div className="page-titles pb-0 pt-1">
          <div className="row">
            <div className="col-lg-8 col-md-6 col-12 align-self-center">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0 d-flex align-items-center">
                  <li className="breadcrumb-item">
                    <span className="pointer" onClick={homescreen}>
                      <i className="ri-home-3-line fs-5"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                    <span style={{ fontSize: '16px' }}>Transaction</span>
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                    style={{ fontSize: "large", fontWeight: "bold" }}
                  >
                    <span>
                      Approve Task Recall
                    </span>
                  </li>
                </ol>
              </nav>
              {/* <h2 className="mb-0 fw-bold">Approve Task Recall</h2> */}
            </div>
            <div
              className="col-lg-4 col-md-6 d-none d-md-flex align-items-center justify-content-end"
              style={{ paddingRight: "14px" }}
            >
              {/* <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                        icon={<ArrowLeftOutlined />} size='default' onClick={(e) => { previous(e) }}>
                        Back
                      </Button> */}
              <Button
                type="primary"
                shape="round"
                style={{
                  marginRight: "10px",
                  display: filterTaskAcc ? "none" : "block",
                }}
                onClick={filterHandle}
              >
                <FilterOutlined /> Filter
              </Button>

              <button
                onClick={(e) => {
                  fullscreenMode();
                }}
                className="bg-transparent text-black ms-2 full-mode fullscreenradius"
              >
                <Tooltip
                  placement="left"
                  title="Full Screen Mode"
                >
                  <FullscreenOutlined />
                </Tooltip>
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid pt-1">
          <div className="row">
            <div className="col-lg-12">
              <div
                className={
                  "card card-body1-statuatory " +
                  localStorage.getItem("currentTheme")
                }
              >
                <Collapse
                  className="report-collapse"
                  accordion
                  defaultActiveKey={["1"]}
                  style={{ display: exitCollapse ? "none" : "block" }}

                >
                  <Panel header="Approve Task Recall" key={'1'}>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="">
                              <b>Legal Entity : </b>
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <br />
                            {localStorage.getItem("SelectedEntity") ===
                              "All Legal Entity" ? (
                              <div className="form-group">
                                <Select
                                  allowClear={true}
                                  size="default"
                                  placeholder="Select Legal Entity"
                                  onChange={(data, value) => {
                                    if (data !== undefined) {
                                      setCurrentEntity(data);
                                      Setshowdata({
                                        ...showdata,
                                        legal_entity: data,
                                        domain: "",
                                        unit: "",
                                        frequency: "",
                                        asignee: "",
                                      });
                                      setFormShow(false);
                                    } else {
                                      setCurrentEntity("");
                                      Setshowdata({
                                        ...showdata,
                                        legal_entity: "",
                                        domain: "",
                                        unit: "",
                                        frequency: "",
                                        asignee: "",
                                      });
                                    }
                                  }}
                                  showSearch
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  // value={showdata.legal_entity}
                                  style={{ width: "100%", marginTop: "5px" }}
                                >
                                  {sessionArr &&
                                    sessionArr.length > 0 &&
                                    sessionArr.map((item, i) => {
                                      return (
                                        <Option key={item.le_id}>
                                          {item.le_name}
                                        </Option>
                                      );
                                    })}
                                </Select>
                                {formValidator.current.message(
                                  "legal_entity",
                                  showdata.legal_entity,
                                  "required",
                                  {
                                    className: `invalid-feedback ${FormShow ? "show" : "hide"
                                      }`,
                                    messages: {
                                      required: "Legal Entity Required",
                                    },
                                  }
                                )}
                              </div>
                            ) : (
                              <p style={{ width: "100%", marginTop: "5px" }}>
                                {localStorage.getItem("SelectedEntity")}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="">
                              <b>Domain : </b>{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <br />
                            <Select
                              // disabled={entityid != "" ? false : true}
                              disabled={
                                entityid == "" ||
                                  entityid == null ||
                                  entityid == "null"
                                  ? true
                                  : false
                              }
                              allowClear={true}
                              size="default"
                              placeholder="Select Domain"
                              onChange={(data) => {
                                if (data !== undefined) {
                                  Setshowdata({
                                    ...showdata,
                                    domain: data,
                                    unit: "",
                                    frequency: "",
                                    asignee: "",
                                  });
                                } else {
                                  Setshowdata({
                                    ...showdata,
                                    domain: "",
                                    unit: "",
                                    frequency: "",
                                    asignee: "",
                                  });
                                }
                              }}
                              // onSearch={onSearch}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              value={showdata.domain || undefined}
                              style={{ width: "100%", marginTop: "5px" }}
                            >
                              {list &&
                                list.domain.length > 0 &&
                                list.domain.map((item, i) => {
                                  return (
                                    <Option key={item.d_id}>
                                      {item.d_name}
                                    </Option>
                                  );
                                })}
                            </Select>
                            {formValidator.current.message(
                              "domain",
                              showdata.domain,
                              ["required"],
                              {
                                className: `invalid-feedback ${FormShow ? "show" : "hide"
                                  }`,
                                messages: {
                                  required: "Domain Required",
                                },
                              }
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="">
                              <b>Unit : </b>{" "}
                            </label>
                            <br />
                            <Select
                              // disabled={entityid != "" ||  entityid != null ? false : true}
                              disabled={
                                entityid == "" ||
                                  entityid == null ||
                                  entityid == "null"
                                  ? true
                                  : false
                              }
                              allowClear={true}
                              size="default"
                              placeholder="Select Unit"
                              onChange={(data) => {
                                if (data !== undefined) {
                                  Setshowdata({
                                    ...showdata,
                                    unit: data,
                                    frequency: "",
                                    asignee: "",
                                  });
                                } else {
                                  Setshowdata({
                                    ...showdata,
                                    unit: "",
                                    frequency: "",
                                    asignee: "",
                                  });
                                }
                              }}
                              // onSearch={onSearch}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              value={showdata.unit || undefined}
                              style={{ width: "100%", marginTop: "5px" }}
                            >
                              {list &&
                                list.unitdata.length > 0 &&
                                list.unitdata.map((item, i) => {
                                  return (
                                    <Option key={item.unit_id}>
                                      {item.unit_name}
                                    </Option>
                                  );
                                })}
                            </Select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: "10px" }}>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="">
                              <b>Frequency : </b>
                            </label>
                            <br />
                            <Select
                              allowClear={true}
                              size="default"
                              placeholder="Select Frequency"
                              onChange={(data) => {
                                if (data !== undefined) {
                                  Setshowdata({
                                    ...showdata,
                                    frequency: data,
                                  });
                                } else {
                                  Setshowdata({
                                    ...showdata,
                                    frequency: "",
                                  });
                                }
                              }}
                              // onSearch={onSearch}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              value={showdata.frequency || undefined}
                              style={{ width: "100%", marginTop: "5px" }}
                            >
                              <Option key={0}>All</Option>
                              {list &&
                                list.frequency.length > 0 &&
                                list.frequency.map((item, i) => {
                                  return (
                                    <Option key={item.frequency_id}>
                                      {item.frequency_name}
                                    </Option>
                                  );
                                })}
                            </Select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="">
                              <b>Assignee : </b>
                            </label>
                            <br />
                            <Select
                              // disabled={entityid != "" ? false : true}
                              disabled={
                                entityid == "" ||
                                  entityid == null ||
                                  entityid == "null"
                                  ? true
                                  : false
                              }
                              allowClear={true}
                              size="default"
                              placeholder="Select Assignee"
                              onChange={(data) => {
                                if (data !== undefined) {
                                  Setshowdata({
                                    ...showdata,
                                    asignee: data,
                                  });
                                }
                              }}
                              // onSearch={onSearch}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              value={showdata.asignee || undefined}
                              style={{ width: "100%", marginTop: "5px" }}
                            ></Select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-1 popupbtncolour text-center">
                      <Button
                        type="primary"
                        shape="round"
                        className={
                          "addbutton " + localStorage.getItem("currentTheme")
                        }

                        size="default"
                        icon={<EyeOutlined />}
                        onClick={() => {
                          gettaskrecalllist();
                        }}
                      >
                        Show
                      </Button>
                    </div>
                  </Panel>
                </Collapse>
                <Modal title="Approve Task Recall"
                  className={
                    "newStylestatutoryremarks add-service-prv " +
                    localStorage.getItem("currentTheme")
                  }
                  footer={false}
                  visible={filterModalVisible}
                  onCancel={setcancelFilter}>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="">
                            <b>Legal Entity : </b>
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <br />
                          {localStorage.getItem("SelectedEntity") ===
                            "All Legal Entity" ? (
                            <div className="form-group">
                              <Select
                                allowClear={true}
                                size="default"
                                placeholder="Select Legal Entity"
                                onChange={(data, value) => {
                                  if (data !== undefined) {
                                    setCurrentEntity(data);
                                    Setshowdata({
                                      ...showdata,
                                      legal_entity: data,
                                      domain: "",
                                      unit: "",
                                      frequency: "",
                                      asignee: "",
                                    });
                                    setFormShow(false);
                                  } else {
                                    setCurrentEntity("");
                                    Setshowdata({
                                      ...showdata,
                                      legal_entity: "",
                                      domain: "",
                                      unit: "",
                                      frequency: "",
                                      asignee: "",
                                    });
                                  }
                                }}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                value={showdata.legal_entity}
                                style={{ width: "100%", marginTop: "5px" }}
                              >
                                {sessionArr &&
                                  sessionArr.length > 0 &&
                                  sessionArr.map((item, i) => {
                                    return (
                                      <Option key={item.le_id}>
                                        {item.le_name}
                                      </Option>
                                    );
                                  })}
                              </Select>
                              {formValidator.current.message(
                                "legal_entity",
                                showdata.legal_entity,
                                "required",
                                {
                                  className: `invalid-feedback ${FormShow ? "show" : "hide"
                                    }`,
                                  messages: {
                                    required: "Legal Entity Required",
                                  },
                                }
                              )}
                            </div>
                          ) : (
                            <p style={{ width: "100%", marginTop: "5px" }}>
                              {localStorage.getItem("SelectedEntity")}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="">
                            <b>Domain : </b>{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <br />
                          <Select
                            // disabled={entityid != "" ? false : true}
                            disabled={
                              entityid == "" ||
                                entityid == null ||
                                entityid == "null"
                                ? true
                                : false
                            }
                            allowClear={true}
                            size="default"
                            placeholder="Select Domain"
                            onChange={(data) => {
                              if (data !== undefined) {
                                Setshowdata({
                                  ...showdata,
                                  domain: data,
                                  unit: "",
                                  frequency: "",
                                  asignee: "",
                                });
                              } else {
                                Setshowdata({
                                  ...showdata,
                                  domain: "",
                                  unit: "",
                                  frequency: "",
                                  asignee: "",
                                });
                              }
                            }}
                            // onSearch={onSearch}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            value={showdata.domain || undefined}
                            style={{ width: "100%", marginTop: "5px" }}
                          >
                            {list &&
                              list.domain.length > 0 &&
                              list.domain.map((item, i) => {
                                return (
                                  <Option key={item.d_id}>
                                    {item.d_name}
                                  </Option>
                                );
                              })}
                          </Select>
                          {formValidator.current.message(
                            "domain",
                            showdata.domain,
                            ["required"],
                            {
                              className: `invalid-feedback ${FormShow ? "show" : "hide"
                                }`,
                              messages: {
                                required: "Domain Required",
                              },
                            }
                          )}
                        </div>
                      </div>
 
                    </div>
                  </div>
                  <div className="col-md-12" style={{ marginTop: "10px" }}>
                    <div className="row">                     <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">
                            <b>Unit : </b>{" "}
                          </label>
                          <br />
                          <Select
                            // disabled={entityid != "" ||  entityid != null ? false : true}
                            disabled={
                              entityid == "" ||
                                entityid == null ||
                                entityid == "null"
                                ? true
                                : false
                            }
                            allowClear={true}
                            size="default"
                            placeholder="Select Unit"
                            onChange={(data) => {
                              if (data !== undefined) {
                                Setshowdata({
                                  ...showdata,
                                  unit: data,
                                  frequency: "",
                                  asignee: "",
                                });
                              } else {
                                Setshowdata({
                                  ...showdata,
                                  unit: "",
                                  frequency: "",
                                  asignee: "",
                                });
                              }
                            }}
                            // onSearch={onSearch}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            value={showdata.unit || undefined}
                            style={{ width: "100%", marginTop: "5px" }}
                          >
                            {list &&
                              list.unitdata.length > 0 &&
                              list.unitdata.map((item, i) => {
                                return (
                                  <Option key={item.unit_id}>
                                    {item.unit_name}
                                  </Option>
                                );
                              })}
                          </Select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">
                            <b>Frequency : </b>
                          </label>
                          <br />
                          <Select
                            allowClear={true}
                            size="default"
                            placeholder="Select Frequency"
                            onChange={(data) => {
                              if (data !== undefined) {
                                Setshowdata({
                                  ...showdata,
                                  frequency: data,
                                });
                              } else {
                                Setshowdata({
                                  ...showdata,
                                  frequency: "",
                                });
                              }
                            }}
                            // onSearch={onSearch}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            value={showdata.frequency || undefined}
                            style={{ width: "100%", marginTop: "5px" }}
                          >
                            <Option key={0}>All</Option>
                            {list &&
                              list.frequency.length > 0 &&
                              list.frequency.map((item, i) => {
                                return (
                                  <Option key={item.frequency_id}>
                                    {item.frequency_name}
                                  </Option>
                                );
                              })}
                          </Select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">
                            <b>Assignee : </b>
                          </label>
                          <br />
                          <Select
                            // disabled={entityid != "" ? false : true}
                            disabled={
                              entityid == "" ||
                                entityid == null ||
                                entityid == "null"
                                ? true
                                : false
                            }
                            allowClear={true}
                            size="default"
                            placeholder="Select Assignee"
                            onChange={(data) => {
                              if (data !== undefined) {
                                Setshowdata({
                                  ...showdata,
                                  asignee: data,
                                });
                              }
                            }}
                            // onSearch={onSearch}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            value={showdata.asignee || undefined}
                            style={{ width: "100%", marginTop: "5px" }}
                          ></Select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-2 popupbtncolour text-center">

                    <Button
                      type="primary"
                      shape="round"
                      className={
                        "addbutton " + localStorage.getItem("currentTheme")
                      }
                      style={{
                        background: "#0096FF",
                        borderColor: "#0096FF",
                      }}
                      size="default"
                      icon={<EyeOutlined />}
                      onClick={() => {
                        gettaskrecalllist();
                      }}
                    >
                      Show
                    </Button>
                  </div>
                </Modal>
                {acccordionTrue == true ? (
                  <div className="mt-2 mx-2" id="only-restricted">
                    <Collapse
                      className="report-collapse"
                      defaultActiveKey={["1"]}
                      accordion
                    >
                      <Panel
                        header={"Compliance Table"}
                        extra={
                          <>
                            <span style={{ paddingRight: "80px" }}>
                            Selected Frequency : {all.charAt(0).toUpperCase() + all.slice(1)}
                            </span>
                            <span style={{ paddingRight: "80px" }}>
                              Total Compliance : {totalRecord}
                            </span>
                          </>
                        }
                        key="1"
                      >
                        {/* {temp && temp.length > 0 ? temp.map((itemdata, i) => {

                                                return ( */}
                        <Fragment>
                          {/* <Table dataSource={dataSource} columns={columns} pagination={false} /> */}

                          {/* <DataTable

                                                            columns={columns}
                                                            data={list && list.approverecallcompliance.length && list.approverecallcompliance}
                                                            // defaultSortFieldId={1}
                                                            // pagination
                                                            selectableRows={false}
                                                       
                                                        /> */}
                          {/* <Table
                                                            className={localStorage.getItem("currentTheme") + ' userprivclass'}

                                                            columns={columns}
                                                            dataSource={itemdata.child}
                                                            bordered
                                                            title={() => {
                                                                return itemdata.child[0].unit_name
                                                            }}
                                                            scroll={{ y: 1000 }}
                                                            pagination={false}
                                                            onHeaderRow={(columns, index) => {
                                                                return {
                                                                    onClick: (e) => {
                                                                        let checked = e.target.checked;
                                                                        addAllSelectedDataValues(
                                                                            checked,
                                                                            itemdata.index

                                                                        );
                                                                    }

                                                                };
                                                            }}
                                                        /> */}
                          <div className="ant-table ant-table-bordered ant-table-fixed-header">
                            <div className="ant-table-container">
                              <div className="ant-table-content taskrecalltable ">
                              <table className="table-fixed">
                                  <thead className="ant-table-thead">
                                    <tr>
                                      <th className="ant-table-cell" style={{ width: "10px" }}>
                                                      <div className="d-flex justify-conent-center align-items-center">
                                        <span className="me-1"># </span>
                                        <button className="col-form-label" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                          <FilterFilled />
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                          <li onClick={(e) => {frequencyfilter('All')}}><a className="dropdown-item"><b>All</b></a></li>
                                          <li onClick={(e) => {frequencyfilter('periodical')}}><a className="dropdown-item" style={{ background: "green", color: "white" }}>Periodical</a></li>
                                          <li onClick={(e) => {frequencyfilter('flexi')}}><a className="dropdown-item" style={{ background: "pink", color: "black" }}>FlexiReview</a></li>
                                          <li onClick={(e) => {frequencyfilter('Review')}}><a className="dropdown-item" style={{ background: "orange", color: "black" }}>Review</a></li>
                                          <li onClick={(e) => {frequencyfilter('On Occurrence')}}><a className="dropdown-item" style={{ background: "yellow", color: "black" }}>OnOccurrence</a></li>
                                          <li onClick={(e) => {frequencyfilter('One Time')}}><a className="dropdown-item" style={{ background: "red", color: "white" }}>Onetime</a></li>                                          
                                        </ul>
                                        </div>
                                        {/* <div id="filter" style={{position: 'relative'}}>
                                          <div style={{position: 'absolute',marginLeft: '33px', cursor: 'pointer' }} > # 
                                        <FilterFilled style={{ marginLeft: '8px' }} /></div>
                                        <Select  style={{ width: "80px", opacity: 0 }} value={all}
                                        
                                          onChange={(data) => {
                                            frequencyfilter(data);
                                          }}
                                        >
                                          <Option l value="All">All</Option>
                                          <Option value="periodical" style={{ background: "green", color: "white" }}>
                                            Periodical
                                          </Option>
                                          <Option
                                            value="flexi"
                                            style={{
                                              background: "pink",
                                              color: "black",
                                            }}
                                          >
                                            FlexiReview
                                          </Option>
                                          <Option
                                            value="Review"
                                            style={{
                                              background: "orange",
                                              color: "black",
                                            }}
                                          >
                                            Review
                                          </Option>
                                          <Option
                                            value="On Occurrence"
                                            style={{
                                              background: "yellow",
                                              color: "black",
                                            }}
                                          >
                                            OnOccurrence
                                          </Option>
                                          <Option
                                            value="One Time"
                                            style={{
                                              background: "red",
                                              color: "white",
                                            }}
                                          >
                                            Onetime
                                          </Option>
                                        </Select>
                                        </div> */}
                                      </th>
                                      <th
                                        className="ant-table-cell"
                                        style={{ width: "20px" }}
                                      >
                                        {/* Action {' '} */}
                                        <input
                                          type="checkbox"
                                          title="Click here to approve all"
                                          name="allchecked"
                                          className={"allChecked"}
                                          onClick={(e) => {
                                            let checked = e.target.checked;
                                            addAllSelectedDataValues(checked);
                                          }}
                                        />
                                      </th>
                                      <th
                                        className="ant-table-cell"
                                        style={{ width: "350px" }}
                                      >
                                        <div className="d-flex justify-content-between align-items-center">
                                          <p
                                            className="p-0 mb-0"
                                            style={{
                                              marginLeft: "36%",
                                              display: "inline",
                                            }}
                                          >
                                            Compliance Task
                                          </p>{" "}
                                          <Popconfirm
                                            placement="bottom"
                                            title={
                                              <Input
                                                placeholder="Compliance Task"
                                                onKeyUp={(e) => {
                                                  compliancetaskfilter(
                                                    e.target.value,
                                                    "Compliance_Task"
                                                  );
                                                }}
                                              />
                                            }
                                          >
                                            <SearchOutlined
                                              style={{ float: 'right', marginTop: '4px'}}
                                            />
                                          </Popconfirm>
                                        </div>
                                        {/* <Input placeholder='Compliance Task' onKeyUp={(e) => {
                                                                            compliancetaskfilter(e.target.value, 'Compliance_Task')
                                                                        }} /> */}
                                      </th>
                                      {/* <th className='ant-table-cell' style={{ width: '10%' }}>Assignee <Input placeholder='Assignee' onKeyUp={(e) => {
                                                                            compliancetaskfilter(e.target.value, 'Assignee')
                                                                        }} /></th> */}
                                      <th
                                        className="ant-table-cell"
                                        style={{ width: "90px" }}
                                      >
                                        <div className="d-flex justify-content-between align-items-center">
                                          <p
                                          className="mb-0"
                                            style={{
                                              marginLeft: "2px",
                                              display: "inline",
                                            }}
                                          >
                                            Compliance Status
                                          </p>
                                          <Popconfirm
                                            placement="bottom"
                                            title={
                                              <Input
                                                placeholder="Compliance Status"
                                                onKeyUp={(e) => {
                                                  compliancetaskfilter(
                                                    e.target.value,
                                                    "Compliance_Status"
                                                  );
                                                }}
                                              />
                                            }
                                          >
                                            <SearchOutlined
                                              style={{ float: 'right', marginTop: '4px'}}
                                            />
                                          </Popconfirm>
                                        </div>
                                        {/* <Input placeholder='Compliance Status' onKeyUp={(e) => {
                                                                            compliancetaskfilter(e.target.value, 'Compliance_Status')
                                                                        }} /> */}
                                      </th>
                                      <th
                                        className="ant-table-cell"
                                        style={{ width: "80px" }}
                                      >
                                        <div className="d-flex justify-content-between align-items-center">
                                          <p className="mb-0"
                                            style={{
                                              marginLeft: "20%",
                                              display: "inline",
                                            }}
                                          >
                                            Due Date
                                          </p>{" "}
                                          <Popconfirm
                                            placement="bottom"
                                            title={
                                              <Input
                                                placeholder="Due Date"
                                                onKeyUp={(e) => {
                                                  compliancetaskfilter(
                                                    e.target.value,
                                                    "Due_Date"
                                                  );
                                                }}
                                              />
                                            }
                                          >
                                            <SearchOutlined
                                              style={{ float: 'right', marginTop: '4px'}}
                                            />
                                          </Popconfirm>
                                        </div>

                                        {/* <Input placeholder='Due Date' onKeyUp={(e) => {
                                                                            compliancetaskfilter(e.target.value, 'Due_Date')
                                                                        }} /> */}
                                      </th>
                                      <th
                                        className="ant-table-cell"
                                        style={{ width: "90px" }}
                                      >
                                        <div className="d-flex justify-content-between align-items-center">
                                          <p className="mb-0"
                                            style={{
                                              marginLeft: "12%",
                                              display: "inline",
                                            }}
                                          >
                                            Completion Date
                                          </p>
                                          <Popconfirm
                                            placement="bottom"
                                            title={
                                              <Input
                                                placeholder="Completion Date"
                                                onKeyUp={(e) => {
                                                  compliancetaskfilter(
                                                    e.target.value,
                                                    "Completion_Date"
                                                  );
                                                }}
                                              />
                                            }
                                          >
                                            <SearchOutlined
                                              style={{ float: 'right', marginTop: '4px'}}
                                            />
                                          </Popconfirm>
                                        </div>

                                        {/* <Input placeholder='Completion Date' onKeyUp={(e) => {
                                                                            compliancetaskfilter(e.target.value, 'Completion_Date')
                                                                        }} /> */}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="ant-table-tbody">
                                    {filteredArrayState &&
                                      filteredArrayState.length > 0 ? (
                                      filteredArrayState.map((list, ii) => {
                                        return (
                                          <Fragment key={ii}>
                                            {tempFilterArray.includes(
                                              list[0].unit_name
                                            ) == true ? (
                                              <tr className="bg-lightdark">
                                                <td colSpan={7}>
                                                  <div
                                                    className="ant-table-title"
                                                    style={{ padding: "0" }}
                                                  >
                                                    {list[0].unit_name}
                                                  </div>
                                                </td>
                                              </tr>
                                            ) : (
                                              ""
                                            )}
                                            {temp && temp.length > 0 ? (
                                              temp.map((itemdata, i) => {
                                                let data = _.find(
                                                  itemdata.child,
                                                  {
                                                    unit_name:
                                                      list[0].unit_name,
                                                  }
                                                );
                                                if (data != undefined) {
                                                  if (
                                                    data.assignee_name ==
                                                    itemdata.parentData
                                                      .assignee_name
                                                  ) {
                                                    return (
                                                      <Fragment key={i}>
                                                        <tr className="ant-table-row ant-table-row-level-0">
                                                          <td
                                                            colSpan={12}
                                                            className="ant-table-cell"
                                                          >
                                                            <b>
                                                              {
                                                                itemdata
                                                                  .parentData
                                                                  .assignee_name
                                                              }
                                                            </b>
                                                          </td>
                                                        </tr>
                                                        {itemdata.child.map(
                                                          (items, l) => {
                                                            return (
                                                              <tr
                                                                className="ant-table-row ant-table-row-level-0"
                                                                key={l}
                                                              >
                                                                <td className="ant-table-cell">
                                                                  <center>
                                                                    {counts + 1}
                                                                    <span
                                                                      hidden
                                                                    >
                                                                      {
                                                                        (counts =
                                                                          counts +
                                                                          1)
                                                                      }
                                                                    </span>
                                                                    {items.compliance_frequency ==
                                                                      "Periodical" ? (
                                                                      <p>
                                                                        <i
                                                                          style={{
                                                                            color:
                                                                              "#4FC742",
                                                                          }}
                                                                          title={
                                                                            items.compliance_frequency
                                                                          }
                                                                          className="bi bi-square-fill"
                                                                        ></i>
                                                                      </p>
                                                                    ) : items.compliance_frequency ==
                                                                      "FlexiReview" ? (
                                                                      <p>
                                                                        <i
                                                                          style={{
                                                                            color:
                                                                              "#F4BDD5",
                                                                          }}
                                                                          title={
                                                                            items.compliance_frequency
                                                                          }
                                                                          className="bi bi-square-fill"
                                                                        ></i>
                                                                      </p>
                                                                    ) : items.compliance_frequency ==
                                                                      "Review" ? (
                                                                      <p>
                                                                        <i
                                                                          style={{
                                                                            color:
                                                                              "#F78453",
                                                                          }}
                                                                          title={
                                                                            items.compliance_frequency
                                                                          }
                                                                          className="bi bi-square-fill"
                                                                        ></i>
                                                                      </p>
                                                                    ) : items.compliance_frequency ==
                                                                      "On Occurrence" ? (
                                                                      <p>
                                                                        <i
                                                                          style={{
                                                                            color:
                                                                              "yellow",
                                                                          }}
                                                                          title={
                                                                            items.compliance_frequency
                                                                          }
                                                                          className="bi bi-square-fill"
                                                                        ></i>
                                                                      </p>
                                                                    ) : (
                                                                      <p>
                                                                        <i
                                                                          style={{
                                                                            color:
                                                                              "#F21625",
                                                                          }}
                                                                          title={
                                                                            items.compliance_frequency
                                                                          }
                                                                          className="bi bi-square-fill"
                                                                        ></i>
                                                                      </p>
                                                                    )}
                                                                  </center>
                                                                </td>
                                                                <td>
                                                                  <Fragment>
                                                                    <center>
                                                                      <input
                                                                        type="checkbox"
                                                                        title="Click here to Task Recall"
                                                                        name={
                                                                          "checked"
                                                                        }
                                                                        onClick={(
                                                                          e
                                                                        ) => {
                                                                          let checked =
                                                                            e
                                                                              .target
                                                                              .checked;
                                                                          addSelectedDataValues(
                                                                            checked,
                                                                            items.compliance_history_id,
                                                                            items.parentId
                                                                          );
                                                                        }}
                                                                      />
                                                                    </center>
                                                                  </Fragment>
                                                                </td>
                                                                <td>
                                                                  <>
                                                                    <span
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                      onClick={() => {
                                                                        showValue();
                                                                        setValueModal(
                                                                          {
                                                                            compliance_history_id:
                                                                              items.compliance_history_id,
                                                                            unit: items.unit_name,
                                                                            compliance_task:
                                                                              items.compliance_name,
                                                                            compliance_frequency:
                                                                              items.compliance_frequency,
                                                                            document_issued_date:
                                                                              items.completion_date,
                                                                            document_reference_number:
                                                                              items.document_reference_number,
                                                                            next_due_date:
                                                                              items.next_due_date,
                                                                            address:
                                                                              items.address,
                                                                            compliance_description:
                                                                              items.compliance_description,
                                                                            uploaded_document:
                                                                              items.upload_docs,
                                                                            interim_details:
                                                                              items.interim_docs,
                                                                            remarks:
                                                                              items.remarks,
                                                                          }
                                                                        );
                                                                      }}
                                                                    >
                                                                      {/* {items.compliance_frequency == "Periodical" ? (
                                                                        <span style={{ color: "green" }}>{items.statu}</span>
                                                                      ) : items.compliance_frequency ==
                                                                        "FlexiReview" ? (
                                                                        <span style={{ color: "#F4BDD5" }}>{items.statu}</span>
                                                                      ) : items.compliance_frequency ==
                                                                        "Review" ? (
                                                                        <span style={{ color: "#F78453" }}>{items.statu}</span>
                                                                      ) : items.compliance_frequency ==
                                                                        "On Occurrence" ? (
                                                                        <span style={{ color: "#420075" }}>{items.statu}</span>
                                                                      ) : (
                                                                        <span style={{ color: "#F21625" }}>{items.statu}</span>
                                                                      )} */}
                                                                      {items.statu}
                                                                    </span>
                                                                    <div className="approve-task-reacall-tooltip">
                                                                      <div className="mb-0">
                                                                        <Tooltip
                                                                          placement="top"
                                                                          overlayStyle={{
                                                                            maxWidth:
                                                                              "420px",
                                                                          }}
                                                                          title={gettooltipfun(
                                                                            items
                                                                          )}
                                                                        >
                                                                          {" "}
                                                                          <i className="bi-info-circle-fill"></i>
                                                                          {/* gettooltipfun(items)
                                                                                                                            }> <ExclamationCircleTwoTone/> */}
                                                                        </Tooltip>
                                                                        &nbsp;
                                                                        {/* <Popover content={gettooltipfun(items)} title="">
                                                                                                                                <Button style={{ border: 0 }}><i className="bi-info-circle-fill"></i></Button>
                                                                                                                            </Popover> */}
                                                                        {/* {items.compliance_frequency == "Periodical" ? (
                                                                          <span style={{ color: "green" }}>{items.compliance_name}</span>
                                                                        ) : items.compliance_frequency ==
                                                                          "FlexiReview" ? (
                                                                          <span style={{ color: "#F4BDD5" }}>{items.compliance_name}</span>
                                                                        ) : items.compliance_frequency ==
                                                                          "Review" ? (
                                                                          <span style={{ color: "#F78453" }}>{items.compliance_name}</span>
                                                                        ) : items.compliance_frequency ==
                                                                          "On Occurrence" ? (
                                                                          <span style={{ color: "#420075" }}>{items.compliance_name}</span>
                                                                        ) : (
                                                                          <span style={{ color: "#F21625" }}>{items.compliance_name}</span>
                                                                        )} */}
                                                                        {
                                                                          items.compliance_name
                                                                        }<br />Assigned On:{items.assigned_on}
                                                                      </div>
                                                                    </div>
                                                                  </>
                                                                </td>
                                                                {/* <td>
                                                                                                                {items.assignee_name}
                                                                                                            </td> */}
                                                                <td>
                                                                  {
                                                                    items.task_status
                                                                  }
                                                                </td>
                                                                <td>
                                                                  <center>
                                                                    {moment(
                                                                      items.due_date
                                                                    ).format(
                                                                      "DD-MMM-YYYY"
                                                                    )}
                                                                  </center>
                                                                </td>
                                                                <td>
                                                                  <center>
                                                                    {moment(
                                                                      items.completion_date
                                                                    ).format(
                                                                      "DD-MMM-YYYY"
                                                                    )}
                                                                  </center>
                                                                </td>
                                                              </tr>
                                                            );
                                                          }
                                                        )}
                                                      </Fragment>
                                                    );
                                                  }
                                                }
                                              })
                                            ) : ii == 0 ? (
                                              <tr>
                                                <td
                                                  colSpan={7}
                                                  style={{
                                                    textAlign: "center",
                                                    fontSize: "14px",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  No Compliance Available
                                                </td>
                                              </tr>
                                            ) : (
                                              false
                                            )}
                                          </Fragment>
                                        );
                                      })
                                    ) : (
                                      <tr>
                                        <td
                                          colSpan={7}
                                          style={{
                                            textAlign: "center",
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          No Compliance Available
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </Fragment>
                        {/* )
                                            }) : <p style={{ textAlign: 'center' }} >No Compliance Available</p>
                                            } */}
                      </Panel>
                    </Collapse>
                    {temp && temp.length > 0 ? (
                      <>
                        {/* <div>
                                                <div className="col-md-12" style={{ marginTop: '10px' }}>
                                                    <div className="row">
                                                        <label htmlFor="">Total Compliance : {list.approverecallcompliance.length}</label>

                                                    </div>
                                                </div>
                                            </div> */}
                        <div className="col-md-12 m-3">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label htmlFor="">
                                  Action:{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <Select
                                  name="action"
                                  className="select2 form-control custom-select bg-white"
                                  id="action"
                                  onChange={(e) => {
                                    Setrecalldata({
                                      ...recalldata,
                                      action: e,
                                    });
                                  }}
                                  defaultValue={"Please Select"}
                                >
                                  <Option value=""> Please Select </Option>
                                  <Option value="1">
                                    {" "}
                                    Rectify & Retain Document{" "}
                                  </Option>
                                  <Option value="2">
                                    {" "}
                                    Rectify & Remove Document{" "}
                                  </Option>
                                  <Option value="3"> Cancel Recall </Option>
                                </Select>
                                {recallSubmit.current.message(
                                  "action",
                                  recalldata.action,
                                  ["required"],
                                  {
                                    className: `invalid-feedback ${FormRecall ? "show" : "hide"
                                      }`,
                                    messages: {
                                      required:
                                        "Please select Action to Recall",
                                    },
                                  }
                                )}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label htmlFor="">
                                  Remarks:{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <div class="d-flex align-items-end">
                                  <TextArea
                                    name="remarks"
                                    className="form-control"
                                    placeholder="Enter Remarks"
                                    value={
                                      recalldata.remarks
                                        ? recalldata.remarks || undefined
                                        : ""
                                    }
                                    id="remarks"
                                    cols="5"
                                    rows="1"
                                    onChange={(e) => {
                                      Setrecalldata({
                                        ...recalldata,
                                        remarks: e.target.value,
                                      });
                                    }}
                                    maxLength={500}
                                  ></TextArea>
                                  <span
                                    className="ms-1 text-info  d-flex align-items-center justify-content-center"
                                    onClick={() => {
                                      showRemarksModal();
                                    }}
                                  >
                                    <PlusCircleOutlined />
                                  </span>
                                </div>
                                {recallSubmit.current.message(
                                  "remarks",
                                  recalldata.remarks,
                                  ["required"],
                                  {
                                    className: `invalid-feedback ${FormRecall ? "show" : "hide"
                                      }`,
                                    messages: {
                                      required: "Remarks Required",
                                    },
                                  }
                                )}
                              </div>
                            </div>
                            {/* <Modal title="Remarks List" visible={remarksModal} onOk={handleOk} onCancel={handleCancel}>
                                                    <DataTable columns={Remarkscolumn}
                                                        data={recallremarks}
                                                        // defaultSortFieldId={1}
                                                        pagination />
                                                </Modal> */}
                            <Modal
                              title="Remarks List"
                              className={
                                "add-service-prv " +
                                localStorage.getItem("currentTheme")
                              }
                              visible={remarksModal}
                              footer={null}
                              onOk={handleOk}
                              onCancel={handleCancel}
                            >
                              <Table
                                className="userprivclass"
                                columns={Remarkscolumn}
                                dataSource={
                                  remarksvalue &&
                                  remarksvalue.length > 0 &&
                                  remarksvalue
                                }
                                bordered
                                pagination={false}
                              />
                            </Modal>
                            <div className="col-md-3">
                              <div className="form-group">
                                {/* <Link to="" style={{ marginLeft: '40%' }} onClick={()=>{setAccordionTrue(true)}} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Show</Link> */}
                                {/* <a href="javascript:;" onClick={Active} style={{ marginLeft: '10%' }} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Approve</a> */}
                                <Button
                                  type="primary"
                                  shape="round"
                                  className="addbutton"
                                  style={{
                                    background: "#0096FF",
                                    borderColor: "#0096FF",
                                    marginLeft: "10%",
                                    marginTop: "8%",
                                    float: "right",
                                  }}
                                  size="default"
                                  onClick={Active}
                                >
                                  Approve
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
                <Modal
                  title="Domain"
                  visible={isModalVisible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                >
                  <Search
                    placeholder="Search"
                    style={{
                      width: 600,
                    }}
                  />
                </Modal>
                {/* <Modal title="Domain" visible={isComplainceModalVisible} onOk={handleOk} onCancel={handleCancel}>
                                <p>The Contract labour(Regulation Act 1997)The tamilNadu Contact Labour(Regulations)Rule 1975</p>
                            </Modal> */}
                <Modal
                  className={localStorage.getItem("currentTheme") + ' add-service-prv'}
                  title=""
                  visible={open}
                  cancelText={false}
                  onCancel={onClose}
                  footer={null}
                >
                  {/* <Drawer title="Domain" visible={showValueModal} footer={false} onCancel={handleCancel} width={40} > */}

                  {/* <Card style={{padding:0}}> */}
                  <div className="col-md-12 d-flex" >
                    <div>
                      <button
                        type="button"
                        class="btn btn-light-primary text-primary btn-circle btn-xl me-3 mb-1 pe-none"
                      >
                        <i
                          class="fas fa-list-alt"
                        ></i>
                      </button>
                    </div>
                    <div>
                      <p className='mb-1'>Unit Name</p>
                      <h4 className='fw-bolder'> {valueModal.unit}&nbsp;<Tooltip title={valueModal.address} >
                        <ExclamationCircleTwoTone />
                      </Tooltip></h4>
                    </div>
                  </div>
                  <div className="container py-2">
                    <div className="row">
                      <div className="col-4 border rounded-start p-2 bg-light mb-2">
                        <p className="mb-1">Compliance Task:</p>
                        <h5>{valueModal.compliance_task}&nbsp;<Tooltip placement='bottom' title={valueModal.compliance_description} >
                          <ExclamationCircleTwoTone />
                        </Tooltip></h5>
                      </div>

                      <div className="col-4 border p-2 bg-light mb-2">
                        <p className="mb-1">Compliance Frequency:</p>
                        <h5>{valueModal.compliance_frequency}</h5>
                      </div>
                      <div className="col-4 border rounded-end p-2 bg-light mb-2">
                        <p className="mb-1">Uploaded File Size:</p>
                        <h5>   {valueModal.document_size}</h5>
                      </div>
                      <div className="col-12 border rounded-start p-2 bg-light mb-2">
                        <p className="mb-1">Attached Documents:</p>
                        {valueModal.uploaded_document}
                      </div>
                      <div className="col-md-6 border rounded-end p-2 bg-light mb-2">
                        <p className="mb-1">Task Completion Date/Document Issued Date:</p>
                        <h5>{valueModal.document_issued_date}</h5>
                      </div>
                      <div className="col-md-6 d-flex align-items-center border rounded-start p-2 bg-light mb-2" >
                        <p className="mb-1">Document Reference Number:</p>
                        <h5>{valueModal.document_reference_number}</h5>
                      </div>
                      <div className="col-4 border rounded-start p-2 bg-light mb-2">
                        <p className="mb-1">Remarks:</p>
                        {valueModal.remarks}
                      </div>
                      <div className="col-4 border p-2 bg-light mb-2">
                        <p className="mb-1">interim details : </p>
                        <h5>  {valueModal.interim_details}</h5>
                      </div>
                      <div className="col-4 border rounded-end p-2 bg-light mb-2">
                        <p className="mb-1">Recall Remarks :</p>
                        <h5>{valueModal.document_reference_number}</h5>
                      </div>

                    </div>
                  </div>
                  <div className="row">

                  </div>









                </Modal>

                {acccordionTrue === true &&
                  filteredArrayState &&
                  filteredArrayState.length > 0 ? (
                  <div className="card p-4 py-2 mb-0 rounded user-mapping1 bg-white">
                    <div className="d-flex justify-content-between">
                      <div></div>
                      <div>
                        <i style={{ color: "red" }} className="bi bi-square-fill"></i>{" "}
                        <label>One time</label>
                      </div>
                      <div>
                        <i style={{ color: "green" }} className="bi bi-square-fill"></i>{" "}
                        <label>Periodical</label>
                      </div>
                      <div>
                        <i style={{ color: "orange" }} className="bi bi-square-fill"></i>{" "}
                        <label>Review</label>
                      </div>
                      <div>
                        <i style={{ color: "pink" }} className="bi bi-square-fill"></i>{" "}
                        <label>Flexi Review</label>
                      </div>
                      <div>
                        <i style={{ color: "yellow" }} className="bi bi-square-fill"></i>{" "}
                        <label>On Occurrence</label>
                      </div>
                      <div></div>
                    </div>
                  </div>
                ) : (
                  false
                )}
                <Modal
                  visible={passwordmodal}
                  footer={null}
                  onCancel={passwordcancel}
                  className="newStyleModalPassword"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-6">
                        <label className="control-label">Password: </label>
                        <span style={{ color: "red" }}>*</span>
                        <input
                          style={{ marginTop: "10px" }}
                          type="password"
                          id="password"
                          name="passwordvalue"
                          className="form-control"
                          placeholder="Enter password"
                          onChange={(e) => {
                            Setpassword({
                              ...password,
                              passwordvalue: e.target.value,
                            });
                          }}
                        // value={password.passwordvalue}
                        />
                        {formValidator2.current.message(
                          "Password",
                          password.passwordvalue,
                          ["required"],
                          {
                            className: `invalid-feedback ${AddFormSubmit2 ? "show" : "hide"
                              }`,
                            messages: {
                              regex: "Password Required",
                            },
                          }
                        )}
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="form-actions text-center">
                    <Button
                      type="primary"
                      shape="round"
                      className="addbutton"
                      style={{ background: "#198754", borderColor: "#198754" }}
                      icon={<PlayCircleOutlined />}
                      size="default"
                      onClick={submitpassword}
                    >
                      Submit
                    </Button>
                    {/* <div className="button2 mt-0" id={localStorage.getItem('currentTheme')} onClick={submitpassword}>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        Submit
                                    </div> */}
                    {/* <button
                                        className="btn btn-light-danger rounded-pill px-4 ms-2 text-danger waves-effect text-start"
                                        onClick={passwordcancel}>
                                        <div className="d-flex align-items-center"> 
                                            <i data-feather="save" className="feather-sm me-1 fill-icon"></i> 
                                        Cancel
                                        </div>
                                    </button> */}
                  </div>
                </Modal>

                {/* <Modal title="Remarks" visible={remarksModal} onOk={handleOk} onCancel={handleCancel}>
                                <p>Test</p>
                            </Modal> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps, {
  ApproveTaskRecallUnitList,
  getApproveTaskRecallList,
  GetRemarksData,
  ApproveTaskRecallSubmit,
})(ApproveTaskRecall);
