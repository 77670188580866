import React, { useEffect, useState, useRef, Fragment, useCallback, useMemo } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Route, Switch, BrowserRouter, Link, useLocation } from 'react-router-dom';
import { Collapse, Input, Modal, Table, List, Row, Tooltip, DatePicker, Select, Tabs,  Tree, Space, Button, Checkbox, Drawer } from 'antd';
import { PlayCircleOutlined, DownloadOutlined, DoubleRightOutlined, DoubleLeftOutlined, DeleteOutlined, PlusOutlined, CloseOutlined, FullscreenOutlined, FilterOutlined } from '@ant-design/icons';
import SimpleReactValidator from "simple-react-validator";
import DataTable from "react-data-table-component";
import Swal from 'sweetalert2';

import EncryptDecrypt from '../../Libs/Encrypt&Decrypt'
import { GetGroupListData, FetchEmails, emailsave, verifypassword, updateblockoption } from '../../Store/Action/Master/CV_CertificateMailMapping';
import { compliancefilters, GetOnOccurrenceCompliances, onoccurrencetransaction, startonoccurrencecompliance } from '../../Store/Action/Transcations/EXE_OnOccurrenceCompliance';
import { constant, filter, indexOf, merge } from 'lodash';
import { InfoCircleOutlined, PlusCircleOutlined, SearchOutlined, FilterFilled, ExclamationCircleTwoTone, PlusSquareTwoTone } from '@ant-design/icons';
import { getFilterDataValues, UploadFile, removedocument, Downloadfile, updateStatus, saveStatus, getCalenderView, getCurrentTaskDetails, getUpcomigTaskDetails } from '../../Store/Action/Transcations/Exe_ComplianceTaskDetails';
import { GetRemarksData } from './../../Store/Action/Transcations/Taskrecall'
import { Toaster } from '../../Libs/Toaster';
import { toast } from 'react-toastify';
// import Calendar from 'react-awesome-calendar';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment/moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import EXE_OnOccurrenceCompliance from '../../Store/Reducer/Transactions/EXE_OnOccurrenceCompliance';
import { RESET_REMARK_HISTORY } from '../../Store/types';
const _ = require("lodash");

const mapStateToProps = (state) => ({
    // onoccurrencecompliances: state.EXE_OnOccurrenceCompliance.onoccurrencecompliance,
    filterrecall: state.recallfilters.recallfilters,
    complianceTaskDetail: state.EXE_ComplianceTaskDetails.complianceTaskDetails

})


const ComplianceTaskDetails = ({
    compliancefilters,
    FetchEmails,
    emailsave,
    verifypassword,
    onoccurrencetransaction,
    UploadFile,
    Downloadfile,
    removedocument,
    getCalenderView,
    GetOnOccurrenceCompliances,
    startonoccurrencecompliance,
    updateStatus,
    saveStatus,
    getFilterDataValues,
    getCurrentTaskDetails,
    getUpcomigTaskDetails,
    GetRemarksData,
    filterrecall: { recallremarks },
    complianceTaskDetail: { compliancefilter, compliancefiltershow, upcomingTask, currentCompliance },
    // onoccurrencecompliances: { compliancefilter, compliancefiltershow, complianceview }
}) => {
    const { Panel } = Collapse;
    const { Option } = Select;
    const { Search, TextArea } = Input;
    const [tab, setTab] = useState()
    console.log(tab, 'TABTAB')
    const location = useLocation();
    const [show, setShow] = useState(false)
    const [showMore, setShowMore] = useState(false)
    const [counts, setCount] = useState(0)
    const [FormShow, setFormShow] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [passvisible, Setpassvisible] = useState(false);
    const [date, setDate] = useState(new Date())
    const searchInput = useRef(null);
    const [status, setStatus] = useState({
        overDue: false,
        inprogress: false,
        recall: false
    })
    const [type, setType] = useState("")
    const [searchedColumn, setSearchedColumn] = useState('');
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [documentNo, setDocumentNo] = useState("")
    const [tempList, setTempList] = useState([])
    const [showValueModal, setShowValueModal] = useState(false)
    const [particularevent, setParticularevent] = useState({
        data: '',
        value: false
    })
    const [passwordata, Setpassword] = useState({
        passvalue: '',
        sp_id: '',
        active_status: ''
    })

    const [completionDate, setCompletionDate] = useState("")
    const [valueModal, setValueModal] = useState({
        unit: '',
        recall: '',
        address: '',
        compliance_history_id: '',
        compliance_task: '',
        compliance_frequency: '',
        uploaded_document: [],
        document_issued_date: '',
        document_reference_number: '',
        validity_date: '',
        next_due_date: '',
        status: '',
        remarks: '',
        action: '',
        description: "",
        completion_date: "",
        unit_id: null,
        approve_status: "",
        actiondata: "",
        start_date: "",
        domain_id: null,
        history_count: '',
        occurrence_remarks: '',
    })
    const paramid = location.pathname;
    const [checkdata, setCheckdata] = useState([])
    console.log(checkdata,'check data')
    const { TabPane } = Tabs;
    var [count1, setCount1] = useState(1);
    const [titledata, Settitledata] = useState()
    const currentdate = new Date();
    const [today, setToday] = useState(moment(currentdate).format('DD-MMM-YYYY'))
    let [count, setcount] = useState(0)
    const formValidator = useRef(new SimpleReactValidator());
    const formValidator1 = useRef(new SimpleReactValidator());
    // const entityid = localStorage.getItem('SelectedEntityid');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const [remarksModal, setRemarksModal] = useState(false);
    const [eventData, setEventData] = useState([])
    const localizer = momentLocalizer(moment);
    const validator = useRef(new SimpleReactValidator());
    const [showdata, Setshowdata] = useState({
        domain: '',
        unit: '',
        frequency: '',
        category: '',
        act: '',
        division: '',
        // legal_entity: '',
        legal_entity: ''

    })
    const validator1 = useRef(new SimpleReactValidator());
    const validator2 = useRef(new SimpleReactValidator());
    const [fileData, setFileData] = useState([])
    const [valueForFileUpload, setValueForFileUpload] = useState({
        d_id: '',
        u_id: '',
        start_date: '',
        file_info: [],
    })
    const [fileupload, setFileUpload] = useState([])
    const [currentMonth, setCurrentMonth] = useState(moment(new Date()).startOf('month'))
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    const [modalVisible, setModaVisible] = useState(false)
    const [taskvalue, setTaskvalue] = useState()
    const [division, setDivision] = useState([])
    const [frequency, setfrequency] = useState([])
    const [category, setCategoryList] = useState([])
    const [units, setUnits] = useState([])
    const [domain, setDomain] = useState([])
    const [legalentity, setLegalentity] = useState()
    const [modalData, setModalData] = useState({
        password: '',
    })
    // const [compliancedata, setComplianceData] = useState({
    //     startdate: '',
    //     remarks: ''
    // });
    const [tempvalue, setTempvalue] = useState([])
    const [remarks, SetRemarks] = useState('');
    const [remarksvalue, Setremarksvalue] = useState();
    const [page, setPage] = useState(1);
    const [currentDetails, setCurrentDetails] = useState([])
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const dispatch = useDispatch()
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [exitCollapse, setExitCollapse] = useState(false);
    const RemarksColumn = [
        {
            title: 'S.No',
            dataIndex: false,
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            }
        },
        {
            title: 'Description',
            dataIndex: 'r_description',
            key: 'r_description',
            width: '200px',
            ellipsis: true,
            render: (text, record) => <a onClick={() => {
                // setModalData({
                //     ...modalData,
                //     password: text
                // })
                // setComplianceData[{
                //     ...compliancedata
                // }]
                SetRemarks(record.r_description)
                setRemarksModal(false)
            }}>{record.r_description}</a>,
        },
    ]

    useEffect(() => {
        if (recallremarks && recallremarks.length > 0) {
            let temp = [];
            for (let i = 0; i < recallremarks.length; i++) {
                if (recallremarks[i].r_description != '') {
                    temp.push(recallremarks[i])
                }
                Setremarksvalue(temp)
            }
            if (temp && temp.length == 0) {
                Toaster.error("No Remarks Available")
            }
            else {
                setRemarksModal(true);
                dispatch({
                    type: RESET_REMARK_HISTORY
                })
            }
        }

    }, [recallremarks])
    const onsubmit = () => {
        if (completionDate != '') {
            Swal.fire({
                title: 'Are You Sure?',
                text: 'Task will move to Delayed Complied Status,do you still want to proceed',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    Setpassvisible(true);
                    setShowValueModal(false)

                    // Swal.fire('Closed.!', 'Closed...', 'success');
                }
            });
        }

        else {
            Toaster.error("Task Completion Date/Document Issued Date Required")
        }
    }
    const Cancelmodal = () => {
        // setAddFormSubmit1(false)
        setShowValueModal(false);
        // setValueModal({
        //     ...valueModal,
        //     action: '',
        //     remarks: '',
        //     validity_date: '',
        //     next_due_date: ''
        // })
        // setEdit(false)

    }

    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') == null || localStorage.getItem('SelectedEntityid') == 'null') {

        } else {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
            if (localStorage.getItem('SelectedEntityid')) {
                // let mail = _.filter(compliancefilter, { legal_entity_id: localStorage.getItem('SelectedEntityid') })
                // setMail(compliancefilter.user_units)

                setLegalentity(localStorage.getItem('SelectedEntityid'))
            }
        }
    }, [localStorage.getItem('SelectedEntityid'), compliancefilter, entityid])
    useEffect(() => {
        if (currentCompliance && currentCompliance.current_compliances && currentCompliance.current_compliances.length > 0) {
            if (showMore == true) {
                let tempArr = tempList.concat(currentCompliance.current_compliances)
                setTempList(tempArr)
                // setDataList([dataList,statutoryUnitList && statutoryUnitList.statutoryListByUnit])
            }
            else {
                setTempList(currentCompliance.current_compliances)
            }

        }
    }, [currentCompliance])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != "All Legal Entity") {
            setShow(true)
        }
    }, [])


    const filterLegends = (temp) => {
        console.log(temp,'temptemptemp')
        let checkarray = []
        let listArray_One_time = _.filter(temp, { compliance_task_frequency: "One Time" });
        console.log(listArray_One_time,'listArray_One_time')
        if (listArray_One_time && listArray_One_time.length > 0) {
          for (let i in listArray_One_time) {
            checkarray.push(listArray_One_time[i])
          }
        }
        let listArray_periodical = _.filter(temp, { compliance_task_frequency: "Periodical" });
        if (listArray_periodical && listArray_periodical.length > 0) {
          for (let i in listArray_periodical) {
            checkarray.push(listArray_periodical[i])
          }
        }
        let listArray_Review = _.filter(temp, { compliance_task_frequency: "Review" });
        if (listArray_Review && listArray_Review.length > 0) {
          for (let i in listArray_Review) {
            checkarray.push(listArray_Review[i])
          }
        }
        let listArray_Flexi_Review = _.filter(temp, { compliance_task_frequency: "Flexi Review" });
        if (listArray_Flexi_Review && listArray_Flexi_Review.length > 0) {
          for (let i in listArray_Flexi_Review) {
            checkarray.push(listArray_Flexi_Review[i])
          }
        }
        let listArray_On_Occurrence = _.filter(temp, { compliance_task_frequency: "On Occurrence" });
        if (listArray_On_Occurrence && listArray_On_Occurrence.length > 0) {
          for (let i in listArray_On_Occurrence) {
            checkarray.push(listArray_On_Occurrence[i])
          }
        }
    }



    useEffect(() => {
        let temp = []
        let temp2 = []
        let temp3 = []
        let temp4 = []
        let finalTemp = []
        if (tempList && tempList.length > 0) {
            let recallData = _.filter(tempList, { compliance_status: "Recall" })
            temp4 = recallData
            for (let i in tempList) {
                if (tempList[i].compliance_status != 'Recall') {
                    temp.push(tempList[i])
                }
            }
            if (temp && temp.length > 0) {
                for (let j in temp) {
                    // if ((moment(temp[j].due_date).format('DD-MMM-YYYY')) < (moment(new Date()).format('DD-MMM-YYYY'))) {
                    //     console.log(temp[j], 'temp[j]');
                    //     temp2.push(temp[j])
                    // }
                    if (moment(temp[j].due_date).isSameOrAfter(today)) {
                        temp2.push(temp[j])
                    }

                    else {
                        temp3.push(temp[j])
                    }
                }
            }

            let checkarray = []
            let listArray_One_time = _.filter(temp4, { compliance_task_frequency: "One Time" });
            console.log(listArray_One_time,'listArray_One_time')
            if (listArray_One_time && listArray_One_time.length > 0) {
              for (let i in listArray_One_time) {
                checkarray.push(listArray_One_time[i])
              }
            }
            let listArray_periodical = _.filter(temp4, { compliance_task_frequency: "Periodical" });
            if (listArray_periodical && listArray_periodical.length > 0) {
              for (let i in listArray_periodical) {
                checkarray.push(listArray_periodical[i])
              }
            }
            let listArray_Review = _.filter(temp4, { compliance_task_frequency: "Review" });
            if (listArray_Review && listArray_Review.length > 0) {
              for (let i in listArray_Review) {
                checkarray.push(listArray_Review[i])
              }
            }
            let listArray_Flexi_Review = _.filter(temp4, { compliance_task_frequency: "Flexi Review" });
            if (listArray_Flexi_Review && listArray_Flexi_Review.length > 0) {
              for (let i in listArray_Flexi_Review) {
                checkarray.push(listArray_Flexi_Review[i])
              }
            }
            let listArray_On_Occurrence = _.filter(temp4, { compliance_task_frequency: "On Occurrence" });
            if (listArray_On_Occurrence && listArray_On_Occurrence.length > 0) {
              for (let i in listArray_On_Occurrence) {
                checkarray.push(listArray_On_Occurrence[i])
              }
            }

            // temp3 

            let checkarray2 = []
            let listArray_One_time2 = _.filter(temp3, { compliance_task_frequency: "One Time" });
            console.log(listArray_One_time2,'listArray_One_time2')
            if (listArray_One_time2 && listArray_One_time2.length > 0) {
              for (let i in listArray_One_time2) {
                checkarray2.push(listArray_One_time2[i])
              }
            }
            let listArray_periodical2 = _.filter(temp3, { compliance_task_frequency: "Periodical" });
            if (listArray_periodical2 && listArray_periodical2.length > 0) {
              for (let i in listArray_periodical2) {
                checkarray2.push(listArray_periodical2[i])
              }
            }
            let listArray_Review2 = _.filter(temp3, { compliance_task_frequency: "Review" });
            if (listArray_Review2 && listArray_Review2.length > 0) {
              for (let i in listArray_Review2) {
                checkarray2.push(listArray_Review2[i])
              }
            }
            let listArray_Flexi_Review2 = _.filter(temp3, { compliance_task_frequency: "Flexi Review" });
            if (listArray_Flexi_Review2 && listArray_Flexi_Review2.length > 0) {
              for (let i in listArray_Flexi_Review2) {
                checkarray2.push(listArray_Flexi_Review2[i])
              }
            }
            let listArray_On_Occurrence2 = _.filter(temp3, { compliance_task_frequency: "On Occurrence" });
            if (listArray_On_Occurrence2 && listArray_On_Occurrence2.length > 0) {
              for (let i in listArray_On_Occurrence2) {
                checkarray2.push(listArray_On_Occurrence2[i])
              }
            }

            //  temp2 

            let checkarray3 = []
            let listArray_One_time3 = _.filter(temp2, { compliance_task_frequency: "One Time" });
            console.log(listArray_One_time3,'listArray_One_time3')
            if (listArray_One_time3 && listArray_One_time3.length > 0) {
              for (let i in listArray_One_time3) {
                checkarray3.push(listArray_One_time3[i])
              }
            }
            let listArray_periodical3 = _.filter(temp2, { compliance_task_frequency: "Periodical" });
            if (listArray_periodical3 && listArray_periodical3.length > 0) {
              for (let i in listArray_periodical3) {
                checkarray3.push(listArray_periodical3[i])
              }
            }
            let listArray_Review3 = _.filter(temp2, { compliance_task_frequency: "Review" });
            if (listArray_Review3 && listArray_Review3.length > 0) {
              for (let i in listArray_Review3) {
                checkarray3.push(listArray_Review3[i])
              }
            }
            let listArray_Flexi_Review3 = _.filter(temp2, { compliance_task_frequency: "Flexi Review" });
            if (listArray_Flexi_Review3 && listArray_Flexi_Review3.length > 0) {
              for (let i in listArray_Flexi_Review3) {
                checkarray3.push(listArray_Flexi_Review3[i])
              }
            }
            let listArray_On_Occurrence3 = _.filter(temp2, { compliance_task_frequency: "On Occurrence" });
            if (listArray_On_Occurrence3 && listArray_On_Occurrence3.length > 0) {
              for (let i in listArray_On_Occurrence3) {
                checkarray3.push(listArray_On_Occurrence3[i])
              }
            }

            if (temp4 && temp4.length > 0) {
                finalTemp.push({
                    "status": "Recall Compliance",
                    "child":checkarray
                })
            }
            if (temp3 && temp3.length > 0) {
                finalTemp.push({
                    "status": "Over Due Compliance",
                    "child":checkarray2
                })
            }
            if (temp2 && temp2.length > 0) {
                finalTemp.push({
                    "status": "Inprogress Compliance",
                    "child": checkarray3
                })
            }
            setCurrentDetails(finalTemp)
        }
    }, [tempList])
    useEffect(() => {
        formValidator.current.showMessages()
        formValidator1.current.showMessages()

    }, [])
    const [fullscreen, setfullscreen] = useState(false);
  const fullscreenMode = () => {
    let topBar = document.getElementById('top-nav')
    let leftbar = document.getElementsByClassName('left-sidebar');
    let leftnone = leftbar[0];
    let pageWrapper = document.getElementById('page-wrapper')
    if (fullscreen == false) {
      document.documentElement.requestFullscreen();
      topBar.style.display = 'none'
      leftnone.style.display = 'none'
      pageWrapper.style.padding = '0'
      pageWrapper.style.marginLeft = '0'
      setfullscreen(true);
    } else {
      document.exitFullscreen();
      topBar.style.display = 'block'
      leftnone.style.display = 'block'
      pageWrapper.style.marginLeft = '65px'
      pageWrapper.style.paddingTop = '70px'
      setfullscreen(false);
    }
  }
    useEffect(() => {
        if (compliancefiltershow) {
            let temp = []
            if (compliancefiltershow.widget_data && compliancefiltershow.widget_data.length) {
                let arr = compliancefiltershow.widget_data[0].CurrentMonth.split('-')
                arr.pop()
                // arr.toString("-")
                for (let i in compliancefiltershow.widget_data[0].data) {
                    if (compliancefiltershow.widget_data[0].data[i].overdue > 0
                    ) {
                        let date = new Date(`${arr[0]}-${arr[1]}-${compliancefiltershow.widget_data[0].data[i].date}`).toString()
                        temp.push(
                            {
                                id: "over Due",
                                title: compliancefiltershow.widget_data[0].data[i].overdue,
                                allDay: true,
                                start: date,
                                end: date,
                                color: '#f32d2b',
                                entity: showdata.legal_entity,
                                tooltip: `Over Due ${compliancefiltershow.widget_data[0].data[i].overdue}`


                            },
                        )
                    }
                    if (compliancefiltershow.widget_data[0].data[i].inprogress > 0
                    ) {
                        let date = new Date(`${arr[0]}-${arr[1]}-${compliancefiltershow.widget_data[0].data[i].date}`).toString()
                        temp.push(
                            {
                                id: "Inprogress",
                                title: compliancefiltershow.widget_data[0].data[i].inprogress,
                                allDay: true,
                                start: date,
                                end: date,
                                color: 'rgb(255 174 44)',
                                entity: showdata.legal_entity,
                                tooltip: `Inprogress ${compliancefiltershow.widget_data[0].data[i].inprogress}`
                            },
                        )
                    }
                    if (compliancefiltershow.widget_data[0].data[i].upcoming > 0
                    ) {
                        let date = new Date(`${arr[0]}-${arr[1]}-${compliancefiltershow.widget_data[0].data[i].date}`).toString()
                        temp.push(
                            {
                                id: "Upcoming",
                                title: compliancefiltershow.widget_data[0].data[i].upcoming,
                                allDay: true,
                                start: date,
                                end: date,
                                color: 'rgb(42 125 153)',
                                entity: showdata.legal_entity,
                                tooltip: `Upcoming ${compliancefiltershow.widget_data[0].data[i].upcoming}`
                            },
                        )
                    }
                    if (compliancefiltershow.widget_data[0].data[i].duedate > 0
                    ) {
                        let date = new Date(`${arr[0]}-${arr[1]}-${compliancefiltershow.widget_data[0].data[i].date}`).toString()
                        temp.push(
                            {
                                id: "Due Date",
                                title: compliancefiltershow.widget_data[0].data[i].duedate,
                                allDay: true,
                                start: date,
                                end: date,
                                color: 'rgb(149 46 154)',
                                entity: showdata.legal_entity,
                                tooltip: `Due Date ${compliancefiltershow.widget_data[0].data[i].duedate}`
                            },
                        )
                    }
                    if (compliancefiltershow.widget_data[0].data[i].taskrecall > 0
                    ) {
                        let date = new Date(`${arr[0]}-${arr[1]}-${compliancefiltershow.widget_data[0].data[i].date}`).toString()
                        temp.push(
                            {
                                id: "Task Recall",
                                title: compliancefiltershow.widget_data[0].data[i].taskrecall,
                                allDay: true,
                                start: date,
                                end: date,
                                color: '#da6d25',
                                entity: showdata.legal_entity,
                                tooltip: `Task Recall ${compliancefiltershow.widget_data[0].data[i].taskrecall}`

                            },
                        )
                    }


                }
            }
            setEventData(temp)
        }

    }, [compliancefiltershow])
    const onsubmitData = () => {

        setFormShow(true)
        if (formValidator.current.allValid()) {

        setExitCollapse(true);
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
            setShow(true)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetCalendarView",
                        {
                            "le_id": showdata.legal_entity,
                            "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                            "cal_date": null,
                            "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                            "div_id": showdata.division != '' ? Number(showdata.division) : null,
                            "cat_id": showdata.category != '' ? Number(showdata.category) : null,
                            "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                        }
                    ]
                }
            ]
            if (showdata.legal_entity != '') {
                getCalenderView({
                    payload: payload,
                    paramid: paramid
                })
            }
        }
    }
    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "complianceTaskFilters",
                        {
                            "le_id": showdata.legal_entity
                        }
                    ]
                }
            ]
            if (showdata.legal_entity != '') {
                getFilterDataValues({
                    payload: payload,
                    paramid: paramid
                })
            }
        }
    }, [isAuth, showdata.legal_entity])
    useEffect(() => {
        if (compliancefilter) {
            setDomain(compliancefilter && compliancefilter.domains_list
            )
            setfrequency(compliancefilter && compliancefilter.frequency_list
            )
            setCategoryList(compliancefilter && compliancefilter.categories_list)
            setDivision(compliancefilter && compliancefilter.divisions_list)
            setUnits(compliancefilter && compliancefilter.user_units
            )

        }


    }, [compliancefilter])

    useEffect(() => {
        validator.current.showMessages()
        validator1.current.showMessages()
        validator2.current.showMessages()
    }, [])

    const OnCompliance = (data) => {
        const lasttransaction = [
            authtoken,
            {
                "session_token": authtoken,
                "request":
                    [
                        "OnOccurrenceLastTransaction",
                        {
                            "le_id": entityid,
                            "compliance_id": data.compliance_id,
                            "unit_id": data.unit_id
                        }
                    ]
            }
        ]
        onoccurrencetransaction({
            payload: lasttransaction,
            paramid: paramid
        })
    }

    const handleCancel = () => {
        setRemarksModal(false)
    };


    const OnSubmited = () => {
        setAddFormSubmit1(true)
        if (validator1.current.allValid()) {
            startonoccurrencecompliance({
                payload: [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "StartOnOccurrenceCompliance",
                            {
                                "le_id": entityid,
                                "ac_id": tempvalue.ac_id,
                                "compliance_id": tempvalue.compliance_id,
                                "start_date": taskvalue && taskvalue[0].startdate ? taskvalue[0].startdate : taskvalue[1].startdate,
                                "unit_id": tempvalue.unit_id,
                                "duration": tempvalue.complete_within_days,
                                "remarks": taskvalue && taskvalue[0].remarks ? taskvalue[0].remarks : taskvalue[1].remarks,
                                "password": modalData.password
                            }
                        ]
                    }
                ],
                paramid: paramid
            })
            setModaVisible(false)
        }
    }

    const passwordcancel = () => {
        setModaVisible(false)
        Setpassvisible(false);
        setModalData({
            ...modalData,
            password: ""
        })
    }
    const onSelectEvent = useCallback((calEvent) => {
        setTempList([])
        setType(calEvent && calEvent.id)
        setCount1(0)
        setShowMore(false)
        if (calEvent && calEvent.id == 'over Due') {
            setTab('2')
            setParticularevent({
                data: "over Due",
                value: true
            })
            setStatus({
                overDue: true,
                inprogress: false,
                recall: false
            })
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetCurrentComplianceDetail",
                        {
                            "le_id": calEvent && calEvent.entity,
                            "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                            "current_start_count": 0,
                            "cal_view": "OVERDUE",
                            "cal_date": moment(calEvent && calEvent.start).format('DD-MMM-YYYY'),
                            "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                            "division_id": showdata.division != '' ? Number(showdata.division) : null,
                            "category_id": showdata.category != '' ? Number(showdata.category) : null,
                            "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                        }
                    ]
                }
            ]

            getCurrentTaskDetails({

                payload: payload,
                paramid: paramid
            })
        }
        else if (calEvent && calEvent.id == 'Task Recall') {
            setStatus({
                overDue: false,
                inprogress: false,
                recall: true
            })
            setTab('2')
            setParticularevent({
                data: "Task Recall",
                value: true
            })
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetCurrentComplianceDetail",
                        {
                            "le_id": calEvent && calEvent.entity,
                            "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                            "current_start_count": 0,
                            "cal_view": "TASKRECALL",
                            "cal_date": moment(calEvent && calEvent.start).format('DD-MMM-YYYY'),
                            "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                            "division_id": showdata.division != '' ? Number(showdata.division) : null,
                            "category_id": showdata.category != '' ? Number(showdata.category) : null,
                            "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                        }
                    ]
                }
            ]

            getCurrentTaskDetails({

                payload: payload,
                paramid: paramid
            })
        }
        else if (calEvent && calEvent.id == 'Due Date') {
            setStatus({
                overDue: true,
                inprogress: true,
                recall: false
            })
            setTab('2')
            setParticularevent({
                data: "Due Date",
                value: true
            })
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetCurrentComplianceDetail",
                        {
                            "le_id": calEvent && calEvent.entity,
                            "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                            "current_start_count": 0,
                            "cal_view": "DUEDATE",
                            "cal_date": moment(calEvent && calEvent.start).format('DD-MMM-YYYY'),
                            "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                            "division_id": showdata.division != '' ? Number(showdata.division) : null,
                            "category_id": showdata.category != '' ? Number(showdata.category) : null,
                            "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                        }
                    ]
                }
            ]

            getCurrentTaskDetails({

                payload: payload,
                paramid: paramid
            })
        }
        else if (calEvent && calEvent.id == 'Inprogress') {
            setTab('2')
            setParticularevent({
                data: "Inprogress",
                value: true
            })
            // setParticularevent(true)
            setStatus({
                overDue: true,
                inprogress: true,
                recall: false
            })
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetCurrentComplianceDetail",
                        {
                            "le_id": calEvent && calEvent.entity,
                            "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                            "current_start_count": 0,
                            "cal_view": "INPROGRESS",
                            "cal_date": moment(calEvent && calEvent.start).format('DD-MMM-YYYY'),
                            "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                            "division_id": showdata.division != '' ? Number(showdata.division) : null,
                            "category_id": showdata.category != '' ? Number(showdata.category) : null,
                            "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                        }
                    ]
                }
            ]

            getCurrentTaskDetails({

                payload: payload,
                paramid: paramid
            })
        }
        else if (calEvent && calEvent.id == 'Upcoming') {
            setTab('3')
            setCheckdata([])
            Settitledata([])
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetUpcomingComplianceDetail",
                        {
                            "le_id": calEvent && calEvent.entity,
                            "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                            "upcoming_start_count": 0,
                            "cal_view": "UPCOMING",
                            "cal_date": moment(calEvent && calEvent.start).format('DD-MMM-YYYY'),
                            "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                            "division_id": showdata.division != '' ? Number(showdata.division) : null,
                            "category_id": showdata.category != '' ? Number(showdata.category) : null,
                            "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                        }
                    ]
                }
            ]

            getUpcomigTaskDetails({

                payload: payload,
                paramid: paramid
            })

        }
    }, [])

    const showRemarksModal = () => {
        GetRemarksData({
            payload:
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetRemarksData",
                            {}
                        ]
                    }
                ],
            paramid: paramid
        })
    };

    const onInputChange = e => {
        const { value } = e.target;
        const re = /^[A-Z0-9a-z _ .,-]+$/;
        if (value === "" || re.test(value)) {
            SetRemarks(value)
        }
    }

    const onInputChange1 = e => {
        const { value } = e.target;
        const re = /^[A-Z0-9a-z- _.,/)(]+$/;
        if (value === "" || re.test(value)) {
            setDocumentNo(value)
        }
    }

    const { views } = useMemo(() => ({
        views: {
            month: true
        }
    }), [])
    const homescreen = () => {
        window.location.href = '/dashboard'
    }
    const now = new Date();
    const events = [
        {
            id: "over Due",
            title: '5',
            allDay: true,
            start: now,
            end: now,
        },
        {
            id: "Inprogress",
            title: 'All Day Event',
            allDay: true,
            start: 11 / 12 / 2022,
            end: 11 / 12 / 2022,
        },
    ];

    useEffect(() => {
        if (upcomingTask && upcomingTask.upcoming_compliances && upcomingTask.upcoming_compliances.length > 0) {
            let temp = checkdata
            for (let i in upcomingTask.upcoming_compliances) {
                temp.push(upcomingTask.upcoming_compliances[i])
            }
            setCheckdata([...temp])
        }
    }, [upcomingTask])

    useEffect(() => {
        // setTempeditdata(editdata)
        let array = [];
        let finalarray = [];
        let datavalue = [];
        if (checkdata) {
            const ab = checkdata.map(function (item) {
                if ((array.indexOf(item.unit_name) < 0))
                    array.push(item.unit_name)
            })
            array.map((data, i) => {
                // let array2 = [];
                let temp = [];
                let entityArray = [];
                if (data) {
                    checkdata.map((child, childIndex) => {
                        if (data === child.unit_name) {
                            entityArray = {
                                "address": child.address,
                                "assigned_on": child.assigned_on,
                                "category": child.category,
                                "compfielaw_url": child.compfielaw_url,
                                "compliance_description": child.compliance_description,
                                "compliance_name": child.compliance_name,
                                "domain_name": child.domain_name,
                                "due_date": child.due_date,
                                "frequency": child.frequency,
                                "division": child.division,
                                "penal_consequences": child.penal_consequences,
                                "start_date": child.start_date,
                                "statu": child.statu,
                                "unit_name": child.unit_name,
                                "upcoming_format_file_name": child.upcoming_format_file_name,
                                'childId': childIndex,
                                'parentId': i,
                                'indexNo': count1
                            }
                            temp.push(entityArray)
                            console.log(temp,'TEMPPPPPPP')
                            { <span hidden>{count1 = count1 + 1}</span> }
                        }
                    })
                }
                
                let checkarray = []
        let listArray_One_time = _.filter(temp, { frequency: "One Time" });
        console.log(listArray_One_time,'listArray_One_time')
        if (listArray_One_time && listArray_One_time.length > 0) {
          for (let i in listArray_One_time) {
            checkarray.push(listArray_One_time[i])
          }
        }
        let listArray_periodical = _.filter(temp, { frequency: "Periodical" });
        if (listArray_periodical && listArray_periodical.length > 0) {
          for (let i in listArray_periodical) {
            checkarray.push(listArray_periodical[i])
          }
        }
        let listArray_Review = _.filter(temp, { frequency: "Review" });
        if (listArray_Review && listArray_Review.length > 0) {
          for (let i in listArray_Review) {
            checkarray.push(listArray_Review[i])
          }
        }
        let listArray_Flexi_Review = _.filter(temp, { frequency: "Flexi Review" });
        if (listArray_Flexi_Review && listArray_Flexi_Review.length > 0) {
          for (let i in listArray_Flexi_Review) {
            checkarray.push(listArray_Flexi_Review[i])
          }
        }
        let listArray_On_Occurrence = _.filter(temp, { frequency: "On Occurrence" });
        if (listArray_On_Occurrence && listArray_On_Occurrence.length > 0) {
          for (let i in listArray_On_Occurrence) {
            checkarray.push(listArray_On_Occurrence[i])
          }
        }

                datavalue = {
                    index: i,
                    parent: data,
                    child: checkarray
                }
                finalarray.push(datavalue)
            })
            Settitledata(finalarray)
            // getData()
        }

    }, [checkdata])
    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        setSelectedKeys(e.target.value ? [e.target.value] : [])
        confirm({ closeDropdown: false });
        // setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const submitpassword = () => {
        setAddFormSubmit1(true)

        if (formValidator1.current.allValid()) {
            const pay = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "UpdateComplianceDetail",
                        {
                            "le_id": showdata.legal_entity,
                            "compliance_history_id": valueModal.compliance_history_id,
                            "documents": fileupload && fileupload.length > 0 ? fileupload : null,
                            "uploaded_documents": null,
                            "removed_documents": [],
                            "completion_date": moment(completionDate && completionDate._d).format('DD-MMM-YYYY'),
                            "validity_date": null,
                            "next_due_date": null,
                            "remarks": remarks != undefined ? remarks : null,
                            "file_size": 0,
                            "tz_date": moment(new Date()).format('DD-MMM-YYYY hh:mm'),
                            "doc_ref_num": documentNo != '' ? documentNo : null,
                            "password": passwordata.passvalue,
                            "pwd_validate": true
                        }
                    ]
                }
            ]
            saveStatus({
                payload: pay,
                paramid: paramid
            })
        }
        Setpassvisible(false)

    }

    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
            >
                <Input className='form-control'
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    value={selectedKeys[0]}
                    // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}

                />
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    //     // color: filtered ? '#1890ff' : undefined,
                    //     fontWeight: 'bold',
                    //     // backgroundColor:'blue',
                    //     fontSize: '15px',
                    //     color: 'black'
                }}
            />
            // <FileSearchOutlined 
            // style={{
            //     // color: filtered ? '#1890ff' : undefined,
            //     fontWeight:'bold',
            //     // backgroundColor:'blue',
            //     // fontSize:'20px',
            //     color:'black'
            // }}
            // />
            // <i class="bi bi-search"></i>
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });
    // const CustomToolbar=()=>{
    //     return (
    //         <div className="toolbar-container">

    //           <div className="back-next-buttons">
    //             <button className="btn btn-back">
    //               <BackIcon/>
    //             </button>
    //             <label className='label-date'>Aug-Sept 2016</label>
    //           </div>

    //           <div className="filter-container">
    //             <ButtonGroup>
    //               <Button className="bg-filter-off"><span className="label-filter-off">Day</span></Button>
    //               <Button className="bg-filter-off"><span className="label-filter-off">Week</span></Button>
    //               <Button className="bg-filter-off"><span className="label-filter-off">Month</span></Button>
    //               <Button className="bg-filter-off"><span className="label-filter-off">Year</span></Button>
    //             </ButtonGroup>


    //           </div>
    //         </div >
    //       )
    // }
    const CustomToolbar = (e) => {
        let date1 = new Date()
        let month = new Date(
            date1.getFullYear(),
            date1.getMonth() + 6,
            6);

        return (

            <div className="col-md-12">

                <div className="row">

                    <div className="col-md-4">
                        {(moment(e.date).format('MMM') == moment(new Date()).format('MMM')) ? false :
                            <Button icon={<DoubleLeftOutlined />}
                                onClick={() => {
                                    let date1 = e.date
                                    let date = new Date(
                                        date1.getFullYear(),
                                        date1.getMonth() - 1,
                                        1)

                                    let startMonth = moment(date).startOf('month');
                                    const payload = [
                                        authtoken,
                                        {
                                            "session_token": authtoken,
                                            "request": [
                                                "GetCalendarView",
                                                {
                                                    "le_id": showdata.legal_entity,
                                                    "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                                                    "cal_date": ((moment((currentMonth && currentMonth._d)).format('DD-MMM-YYYY')) === (moment(startMonth._d).format('DD-MMM-YYYY'))) ? null : moment(startMonth._d).format('DD-MMM-YYYY'),
                                                    "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                                                    "div_id": showdata.division != '' ? Number(showdata.division) : null,
                                                    "cat_id": showdata.category != '' ? Number(showdata.category) : null,
                                                    "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                                                }
                                            ]
                                        }
                                    ]
                                    if (showdata.legal_entity != '') {
                                        getCalenderView({
                                            payload: payload,
                                            paramid: paramid
                                        })
                                    }
                                    setDate(date)


                                }}>



                            </Button>}

                    </div>

                    <div className="col-md-4 text-center align-self-center">

                        <label ><b>{e.label.replace(" ", " - ")}</b></label>

                    </div>

                    <div className="col-md-4" >
                        {(moment(e.date).format('MMM') == moment(month).format('MMM')) ? false :
                            <Button
                                onClick={() => {
                                    let date1 = e.date
                                    let date = new Date(
                                        date1.getFullYear(),
                                        date1.getMonth() + 1,
                                        1);

                                    let startMonth = moment(date).startOf('month');

                                    const payload = [
                                        authtoken,
                                        {
                                            "session_token": authtoken,
                                            "request": [
                                                "GetCalendarView",
                                                {
                                                    "le_id": showdata.legal_entity,
                                                    "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                                                    "cal_date": ((moment((currentMonth && currentMonth._d)).format('DD-MMM-YYYY')) === (moment(startMonth._d).format('DD-MMM-YYYY'))) ? null : moment(startMonth._d).format('DD-MMM-YYYY'),
                                                    "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                                                    "div_id": showdata.division != '' ? Number(showdata.division) : null,
                                                    "cat_id": showdata.category != '' ? Number(showdata.category) : null,
                                                    "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                                                }
                                            ]
                                        }
                                    ]
                                    if (showdata.legal_entity != '') {
                                        getCalenderView({
                                            payload: payload,
                                            paramid: paramid
                                        })
                                    }
                                    setDate(date)


                                }}
                                icon={<DoubleRightOutlined />} style={{float: 'right'}}>



                            </Button>


                        }
                    </div>

                </div>



            </div>

        )

    }
    const uploadFileFormat = (size, name, content) => {
        var result = {
            'file_size': parseInt(size),
            'file_name': name,
            'file_content': content,
        };

        return result;
    }
    const showmore = () => {
        setShowMore(true)
        setCount(counts + 100)

        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetCurrentComplianceDetail",
                    {
                        "le_id": showdata && showdata.legal_entity,
                        "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                        "current_start_count": counts + 100,
                        "cal_view": type == 'over Due' ? "OVERDUE" : type == 'Task Recall' ? "TASKRECALL" : type == 'Inprogress' ? "INPROGRESS" : null,
                        "cal_date": null,
                        "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                        "division_id": showdata.division != '' ? Number(showdata.division) : null,
                        "category_id": showdata.category != '' ? Number(showdata.category) : null,
                        "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                    }
                ]
            }
        ]

        getCurrentTaskDetails({

            payload: payload,
            paramid: paramid
        })
    }
    const convert_to_base64 = (file, name, size, callback) => {
        var reader = new FileReader();
        if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
            var binary = "";
            reader.onload = function (readerEvt) {
                var bytes = new Uint8Array(readerEvt.target.result);
                var length = bytes.byteLength;
                for (var i = 0; i < length; i++) {
                    binary += String.fromCharCode(bytes[i]);
                }
                callback(EncryptDecrypt.encryptdata(binary), name, size);
            }
            reader.readAsArrayBuffer(file);
        } else {
            reader.onload = function (readerEvt) {
                var binaryString = readerEvt.target.result;
                callback(EncryptDecrypt.encryptdata(binaryString), name, size);
            };
            reader.readAsBinaryString(file);
        }
    }

    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
      };
      const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
      };

    const SavepastRecordOnChange = (e) => {
        if (e.target.name === 'file') {
            var results = [];
            const fileRegex = /^[a-zA-Z0-9-_& ,.+#()[-\]{}$^=%@]*$/
            let files = e.target.files
            let file_max_size = 26214400
            for (var i = 0; i < files.length; i++) {
                var file = files[i];
                var file_name = file.name;
                const f_name = file_name.substring(0, file_name.lastIndexOf('.'));
                if (fileRegex.test(f_name) === false) {
                    return;
                }
                let file_size = file.size;
                if (file_size > file_max_size) {
                    toast.error("Maximum filesize limit exceed")
                    return;
                }
                if (file) {
                    convert_to_base64(file, file_name, file_size, function (file_content, name, size) {
                        var fN = name.substring(0, name.lastIndexOf('.'));
                        var fE = name.substring(name.lastIndexOf('.') + 1);
                        var uniqueId = Math.floor(Math.random() * 90000) + 10000;
                        var f_Name = fN + '-' + uniqueId + '.' + fE;

                        var result = uploadFileFormat(size, f_Name, file_content);
                        setFileData(result)
                        results.push(result);


                        let temp1 = fileupload
                        let temp = []
                        if (results && results.length == 1) {
                            for (let i in results) {
                                temp1.push(results[i])
                            }
                            setFileUpload([...temp1])
                        }
                        else {
                            for (let i in results) {
                                temp.push(results[i])
                            }
                            setFileUpload([...temp])
                        }

                        // const filePayload = [
                        //     authtoken,
                        //     {
                        //         "session_token": authtoken,
                        //         "request": [
                        //             "CertDocumentsTemp",
                        //             {
                        //                 "le_id": data && data.legalEntity,
                        //                 "unit_id": Number(data.vendor),
                        //                 "file_info": results,
                        //             }
                        //         ]
                        //     }
                        // ]
                        // UploadFile({
                        //     payload: filePayload,
                        //     paramid: paramid
                        // })
                    });
                }
            }
        }
        React.forwardRef(({ onClick, ...rest }, ref) => {
            return (
                <>
                    <div className="form-check pb-5" style={{ backgroundColor: '' }}>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            style={{ height: '20px', width: '20px' }}
                            ref={ref}
                            onClick={onClick}
                            {...rest}
                        />
                        <label className="form-check-label" id="booty-check" />
                    </div>
                </>
            )
        })
    }
    const columns = [
        // {
        //     title: <Fragment>
        //         <input type="checkbox" name="allchecked" />
        //     </Fragment>,
        //     dataIndex: false,
        //     key: false,
        //     ellipsis: true,
        //     width: '7px',
        //     align: 'center',
        //     render: (text, record) => {
        //         return (
        //             <Fragment>
        //                 <input type="checkbox"
        //                     name={'checked' + record.parentId}
        //                     onClick={(e) => {
        //                         let checked = e.target.checked;
        //                         addSelectedDataValues(
        //                             checked,
        //                             record.compliance_history_id,
        //                         );
        //                     }}
        //                 />
        //             </Fragment>
        //         )
        //     }
        // },
        {
            title: "S.No",
            render: (row, record, index) => {
                if (record.frequency == 'Periodical') {
                    return <Tooltip title={record && record.frequency}><p><span style={{ padding: '5px', display: 'block' }}>{index + 1}</span>
                        <i style={{ color: "#008000" }} class="bi bi-square-fill"></i>
                    </p></Tooltip>
                } else if (record.frequency == 'FlexiReview') {
                    return <Tooltip title={record && record.frequency}><p><span style={{ padding: '5px', display: 'block' }}>{index + 1}</span><i style={{ color: "#F4BDD5" }} class="bi bi-square-fill"></i></p></Tooltip>
                } else if (record.frequency == 'Review') {
                    return <Tooltip title={record && record.frequency}><p><span style={{ padding: '5px', display: 'block' }}>{index + 1}</span><i style={{ color: "#FFA500" }} class="bi bi-square-fill"></i></p></Tooltip>
                }
                else if (record.frequency == 'On Occurrence') {
                    return <Tooltip title={record && record.frequency}><p><span style={{ padding: '5px', display: 'block' }}>{index + 1}</span><i style={{ color: "#FAF329" }} class="bi bi-square-fill"></i></p></Tooltip>
                } else {
                    return <Tooltip title={record && record.frequency}><p><span style={{ padding: '5px', display: 'block' }}>{index + 1}</span><i style={{ color: "#FF0000" }} class="bi bi-square-fill"></i></p></Tooltip>
                }
            },
            width: '30px',
            align: 'center',
            filters: [
                {
                    text: <span><i style={{ color: "#4FC742" }} class="bi bi-square-fill"></i> Periodical</span>,
                    value: 'Periodical',
                },
                {
                    text: <span><i style={{ color: "#F4BDD5" }} class="bi bi-square-fill"></i> Flexi Review</span>,
                    value: 'FlexiReview',
                },
                {
                    text: <span><i style={{ color: "#F78453" }} class="bi bi-square-fill"></i> Review</span>,
                    value: 'Review',
                },
                {
                    text: <span><i style={{ color: "#FAF329" }} class="bi bi-square-fill"></i> On Occurrance</span>,
                    value: 'On Occurrence',
                },
                {
                    text: <span><i style={{ color: "#F21625" }} class="bi bi-square-fill"></i> One Time</span>,
                    value: 'One Time',
                },
            ],
            onFilter: (value, record) => record.compliance_frequency == value,
            // ...getColumnSearchProps('compliance_frequency'), 
        },
        {
            title: "Compliance Task",
            dataIndex: 'compliance_name',
            key: 'compliance_name',
            ...getColumnSearchProps('compliance_name','Compliance Task'),


            // selector: (row) => <><p>{row.statu}</p><br></br><p>{row.compliance_name}</p></>,
            render: (row, record) => {

                const content = (
                    <table style={{  display: 'block', width: '500px' }}  >
                        <tbody>
                            <tr>
                                <th  style={{ border: "1px solid #636262" }}>Unit Name</th>
                                <td  style={{ border: "1px solid #636262" }}>{record.unit_name}</td>
                            </tr>
                            <tr>
                                <th  style={{ border: "1px solid #636262" }}>Address</th>
                                <td  style={{ border: "1px solid #636262" }}>{record.address}</td>
                            </tr>
                            <tr>
                                <th  style={{ border: "1px solid #636262" }}>Division</th>
                                <td  style={{ border: "1px solid #636262" }}>{record.division}</td>
                            </tr>
                            <tr>
                                <th  style={{ border: "1px solid #636262" }}>Category</th>
                                <td  style={{ border: "1px solid #636262" }}>{record.category}</td>
                            </tr>
                            <tr>
                                <th  style={{ border: "1px solid #636262" }}>Act/Rules</th>
                                <td  style={{ border: "1px solid #636262" }}>{record.statu}</td>
                            </tr>
                            <tr>
                                <th  style={{ border: "1px solid #636262" }}>Compliance Description</th>
                                <td  style={{ border: "1px solid #636262" }}>{record.compliance_description}</td>
                            </tr>
                            <tr>
                                <th  style={{ border: "1px solid #636262" }}>Penal Consequqnces</th>
                                <td  style={{ border: "1px solid #636262" }}>{record.penal_consequences}</td>
                            </tr>
                        </tbody>
                    </table>
                );
                return (
                    <>
                        <span style={{ cursor: "pointer" }}
                        // onClick={() => {
                        //     showValue()
                        //     setValueModal({
                        //         compliance_history_id: record.compliance_history_id,
                        //         unit: record.unit_name,
                        //         compliance_task: record.compliance_name,
                        //         compliance_frequency: record.compliance_frequency,
                        //         document_issued_date: record.completion_date,
                        //         document_reference_number: record.document_reference_number,
                        //         next_due_date: record.next_due_date,
                        //         address: record.address,
                        //         compliance_description: record.compliance_description,
                        //         uploaded_document: record.upload_docs,
                        //         interim_details: record.interim_docs,
                        //         remarks: record.remarks

                        //     })
                        // }}
                        >{record.statu}</span>
                        <div>
                            <div className="mb-0">
                                <Tooltip  title={content}>
                                   {/* <i class="bi-info-circle-fill" style={{ color: '#5DAAE3' }}></i> */}
                                   <InfoCircleOutlined style={{ color: '#5DAAE3' }}/>
                                </Tooltip>{record.compliance_name}
                            </div>
                        </div>

                    </>
                )
            },
            width: '250px',
            // render: (compliance_task) => <a href="javascript:;" onClick={() => { showModal() }}>{compliance_task}</a>,
            // sorter: (a, b) => { return a.statu.localeCompare(b.statu) },
        },
        {
            title: "Domain",
            dataIndex: 'domain_name',
            key: 'domain_name',
            width: '135px',
            ...getColumnSearchProps('domain_name','Domain'),


        },
        {
            title: "Start Date",
            dataIndex: 'start_date',
            key: 'start_date',
            ellipsis: true,
            width: '60px',
            ...getColumnSearchProps('start_date','Start Date'),

        },

        {
            title: "Due Date",
            dataIndex: 'due_date',
            key: 'due_date',
            width: '60px',
            ...getColumnSearchProps('due_date','Due Date'),
            // render: (text, record) => {
            //     return moment(record.due_date).format('DD-MMM-YYYY')
            // }

        },
        {
            title: "Format",
            dataIndex: 'compliance_file_name',
            align:'center',
            key: 'compliance_file_name',
            width: '40px',
            render: (row, record) => {
                let file = record.upcoming_format_file_name && record.upcoming_format_file_name.length > 0 && record.upcoming_format_file_name[0]
                return (
                    <>
                        {
                            record.upcoming_format_file_name && record.upcoming_format_file_name.length > 0 ?
                                <>
                                    <Tooltip title='Download Format'>
                                        <DownloadOutlined
                                            onClick={() => {
                                                window.open('https://13.234.100.64/' + file)
                                            }}
                                        />
                                    </Tooltip>
                                </> : ''
                        }
                    </>
                )
            }



        },


    ];
    const columns2 = [
        // {
        //     title: <Fragment>
        //         <input type="checkbox" name="allchecked" />
        //     </Fragment>,
        //     dataIndex: false,
        //     key: false,
        //     ellipsis: true,
        //     width: '7px',
        //     align: 'center',
        //     render: (text, record) => {
        //         return (
        //             <Fragment>
        //                 <input type="checkbox"
        //                     name={'checked' + record.parentId}
        //                     onClick={(e) => {
        //                         let checked = e.target.checked;
        //                         addSelectedDataValues(
        //                             checked,
        //                             record.compliance_history_id,
        //                         );
        //                     }}
        //                 />
        //             </Fragment>
        //         )
        //     }
        // },
        {
            title: "S.No",
            render: (row, record, index) => {
                if (record.compliance_task_frequency == 'Periodical') {
                    return <Tooltip title={record && record.compliance_task_frequency
                    }><p><span style={{ padding: '5px', display: 'block' }}>{index + 1}</span><i style={{ color: "#008000" }} class="bi bi-square-fill"></i></p></Tooltip>
                } else if (record.compliance_task_frequency == 'FlexiReview') {
                    return <Tooltip title={record && record.compliance_task_frequency
                    }><p><span style={{ padding: '5px', display: 'block' }}>{index + 1}</span><i style={{ color: "#F4BDD5" }} class="bi bi-square-fill"></i></p></Tooltip>
                } else if (record.compliance_task_frequency == 'Review') {
                    return <Tooltip title={record && record.compliance_task_frequency
                    }><p><span style={{ padding: '5px', display: 'block' }}>{index + 1}</span><i style={{ color: "#FFA500" }} class="bi bi-square-fill"></i></p></Tooltip>
                } else if (record.compliance_task_frequency == 'On Occurrence') {
                    return <Tooltip title={record && record.compliance_task_frequency
                    }><p><span style={{ padding: '5px', display: 'block' }}>{index + 1}</span><i style={{ color: "#FAF329" }} class="bi bi-square-fill"></i></p></Tooltip>
                } else {
                    return <Tooltip title={record && record.compliance_task_frequency
                    }><p><span style={{ padding: '5px', display: 'block' }}>{index + 1}</span><i style={{ color: "#FF0000" }} class="bi bi-square-fill"></i></p></Tooltip>
                }
            },
            width: '30px',
            align: 'center',
            filters: [
                {
                    text: <span><i style={{ color: "rgb(0, 128, 0)" }} class="bi bi-square-fill"></i> Periodical</span>,
                    value: 'Periodical',
                },
                {
                    text: <span><i style={{ color: "#F4BDD5" }} class="bi bi-square-fill"></i> Flexi Review</span>,
                    value: 'FlexiReview',
                },
                {
                    text: <span><i style={{ color: "#F78453" }} class="bi bi-square-fill"></i> Review</span>,
                    value: 'Review',
                },
                {
                    text: <span><i style={{ color: "#FAF329" }} class="bi bi-square-fill"></i> On Occurrence</span>,
                    value: 'On Occurrence',
                },
                {
                    text: <span><i style={{ color: "#F21625" }} class="bi bi-square-fill"></i> One Time</span>,
                    value: 'One Time',
                },
            ],
            onFilter: (value, record) => record.compliance_task_frequency.startsWith(value),
            // onFilter: (value, record) => record.compliance_task_frequency == value,
            filterMode: 'tree',
            // ...getColumnSearchProps('compliance_frequency'), record.compliance_task_frequency.startsWith(value)
        },
        {
            title: "Compliance Task",
            dataIndex: 'compliance_name',
            key: 'compliance_name',
            ...getColumnSearchProps('compliance_name','Compliance Task'),


            // selector: (row) => <><p>{row.statu}</p><br></br><p>{row.compliance_name}</p></>,
            render: (row, record) => {
                const content = (
                    <table  style={{width: '500px'}}>
                        <tbody>
                            <tr>
                                <th style={{ border: "1px solid #636262" }}>Unit Name</th>
                                <td style={{ border: "1px solid #636262" }}>{record.unit_name}</td>
                            </tr>
                            <tr>
                                <th style={{ border: "1px solid #636262" }}>Address</th>
                                <td style={{ border: "1px solid #636262" }}>{record.address}</td>
                            </tr>
                            <tr>
                                <th style={{ border: "1px solid #636262" }}>Division</th>
                                <td style={{ border: "1px solid #636262" }}>{record.division}</td>
                            </tr>
                            <tr>
                                <th style={{ border: "1px solid #636262" }}>Category</th>
                                <td style={{ border: "1px solid #636262" }}>{record.category}</td>
                            </tr>
                            <tr>
                                <th style={{ border: "1px solid #636262" }}>Act/Rules</th>
                                <td style={{ border: "1px solid #636262" }}>{record.statu}</td>
                            </tr>
                            <tr>
                                <th style={{ border: "1px solid #636262" }}>Compliance Description</th>
                                <td style={{ border: "1px solid #636262" }}>{record.compliance_description}</td>
                            </tr>
                            <tr>
                                <th style={{ border: "1px solid #636262" }}>Penal Consequqnces</th>
                                <td style={{ border: "1px solid #636262" }}>{record.penal_consequences}</td>
                            </tr>
                        </tbody>
                    </table>
                );
                return (
                    <>
                        <span style={{ cursor: 'pointer', color: record.compliance_status == 'Recall' ? 'brown' : record.compliance_status == 'Rectify' ? 'red' : 'black' }}
                            onClick={() => {
                                setShowValueModal(true)
                                setValueModal({
                                    domain_id: record.domain_id,
                                    start_date: record.start_date,
                                    uploaded_document: record.uploaded_documents,
                                    actiondata: record.action,
                                    approve_status: record.approve_status,
                                    unit_id: record.unit_id,
                                    completion_date: record.completion_date,
                                    remarks: record.remarks,
                                    description: record.compliance_description,
                                    compliance_history_id: record.compliance_history_id,
                                    unit: record.unit_name,
                                    address: record.address,
                                    recall: record.recallremarks,
                                    compliance_task: record.compliance_name,
                                    compliance_frequency: record.compliance_task_frequency,
                                    document_issued_date: record.completion_date,
                                    document_reference_number: record.document_reference_number,
                                    next_due_date: record.next_due_date != null ? record.next_due_date : record.due_date,
                                    history_count: record.history_count,
                                    occurrence_remarks: record.occurrence_remarks,
                                    status: record.compliance_status
                                })
                            }}
                        // onClick={() => {
                        //     showValue()
                        //     setValueModal({
                        //         compliance_history_id: record.compliance_history_id,
                        //         unit: record.unit_name,
                        //         compliance_task: record.compliance_name,
                        //         compliance_frequency: record.compliance_frequency,
                        //         document_issued_date: record.completion_date,
                        //         document_reference_number: record.document_reference_number,
                        //         next_due_date: record.next_due_date,
                        //         address: record.address,
                        //         compliance_description: record.compliance_description,
                        //         uploaded_document: record.upload_docs,
                        //         interim_details: record.interim_docs,
                        //         remarks: record.remarks

                        //     })
                        // }}
                        >{record.statu}</span>
                        <div>
                            <div className="mt-2 mb-2" onClick={() => {
                                setShowValueModal(true)
                                setValueForFileUpload({
                                    d_id: record.domain_id,
                                    u_id: record.unit_id,
                                    start_date: record.start_date,
                                })
                                setValueModal({
                                    domain_id: record.domain_id,
                                    start_date: record.start_date,
                                    uploaded_document: record.uploaded_documents,
                                    actiondata: record.action,
                                    approve_status: record.approve_status,
                                    unit_id: record.unit_id,
                                    completion_date: record.completion_date,
                                    remarks: record.remarks,
                                    description: record.compliance_description,
                                    compliance_history_id: record.compliance_history_id,
                                    unit: record.unit_name,
                                    address: record.address,
                                    recall: record.recallremarks,
                                    compliance_task: record.compliance_name,
                                    compliance_frequency: record.compliance_task_frequency,
                                    document_issued_date: record.completion_date,
                                    document_reference_number: record.document_reference_number,
                                    next_due_date: record.next_due_date != null ? record.next_due_date : record.due_date,
                                    history_count: record.history_count,
                                    occurrence_remarks: record.occurrence_remarks,
                                    status: record.compliance_status
                                })
                            }}>
                                <Tooltip title={content}>
                                    {/* <Button style={{ border: 0 }}> */}
                                    <span style={{ marginRight: '3px' }}>
                                        <i class="bi-info-circle-fill" style={{ color: '#5DAAE3' }}></i>
                                    </span>
                                    {/* </Button> */}
                                    <span style={{ color: record.compliance_status == 'Recall' ? 'brown' : record.compliance_status == 'Rectify' ? 'red' : 'black' }}></span> </Tooltip> <a >{record.compliance_name}</a>
                            </div>
                        </div>

                    </>
                )
            },
            width: '150px',
            // render: (compliance_task) => <a href="javascript:;" onClick={() => { showModal() }}>{compliance_task}</a>,
            // sorter: (a, b) => { return a.statu.localeCompare(b.statu) },
        },
        {
            title: "Domain",
            dataIndex: false,
            key: false,
            width: '140px',
            ...getColumnSearchProps('domain_name','Domain'),
            render: (row, record) => {

                return (<p style={{ cursor: 'pointer', color: record.compliance_status == 'Recall' ? 'brown' : record.compliance_status == 'Rectify' ? 'red' : 'black' }} onClick={() => {
                    setShowValueModal(true)
                    setValueForFileUpload({
                        d_id: record.domain_id,
                        u_id: record.unit_id,
                        start_date: record.start_date,
                    })
                    setValueModal({
                        domain_id: record.domain_id,
                        start_date: record.start_date,
                        uploaded_document: record.uploaded_documents,
                        actiondata: record.action,
                        approve_status: record.approve_status,
                        unit_id: record.unit_id,
                        completion_date: record.completion_date,
                        remarks: record.remarks,
                        description: record.compliance_description,
                        compliance_history_id: record.compliance_history_id,
                        unit: record.unit_name,
                        address: record.address,
                        recall: record.recallremarks,
                        compliance_task: record.compliance_name,
                        compliance_frequency: record.compliance_task_frequency,
                        document_issued_date: record.completion_date,
                        document_reference_number: record.document_reference_number,
                        next_due_date: record.next_due_date != null ? record.next_due_date : record.due_date,
                        history_count: record.history_count,
                        occurrence_remarks: record.occurrence_remarks,
                        status: record.compliance_status
                    })
                }}>{record.domain_name}</p>
                )
            },


        },
        {
            title: "Start Date",
            dataIndex: 'start_date',
            key: 'start_date',
            // ellipsis: true,
            width: '100px',
            align: 'center',
            ...getColumnSearchProps('start_date','Start Date'),
            render: (row, record) => {

                return (<p
                    style={{cursor: 'pointer',  color: record.compliance_status == 'Recall' ? 'brown' : record.compliance_status == 'Rectify' ? 'red' : 'black' }}
                    onClick={() => {
                        setShowValueModal(true)
                        setValueForFileUpload({
                            d_id: record.domain_id,
                            u_id: record.unit_id,
                            start_date: record.start_date,
                        })
                        setValueModal({
                            domain_id: record.domain_id,
                            start_date: record.start_date,
                            uploaded_document: record.uploaded_documents,
                            actiondata: record.action,
                            approve_status: record.approve_status,
                            unit_id: record.unit_id,
                            completion_date: record.completion_date,
                            remarks: record.remarks,
                            description: record.compliance_description,
                            compliance_history_id: record.compliance_history_id,
                            unit: record.unit_name,
                            address: record.address,
                            recall: record.recallremarks,
                            compliance_task: record.compliance_name,
                            compliance_frequency: record.compliance_task_frequency,
                            document_issued_date: record.completion_date,
                            document_reference_number: record.document_reference_number,
                            next_due_date: record.next_due_date != null ? record.next_due_date : record.due_date,
                            history_count: record.history_count,
                            occurrence_remarks: record.occurrence_remarks,
                            status: record.compliance_status
                        })
                    }}>{moment(record.start_date).format('DD-MMM-YYYY')}</p>
                )
            },


        },

        {
            title: "Due Date",
            dataIndex: 'due_date',
            key: 'due_date',
            width: '100px',
            align: 'center',
            ...getColumnSearchProps('due_date','Due Date'),
            // render: (text, record) => {
            //     return moment(record.due_date).format('DD-MMM-YYYY')
            // }
            render: (row, record) => {

                return (<p
                    style={{cursor: 'pointer',  color: record.compliance_status == 'Recall' ? 'brown' : record.compliance_status == 'Rectify' ? 'red' : 'black' }}
                    onClick={() => {
                        setShowValueModal(true)
                        setValueForFileUpload({
                            d_id: record.domain_id,
                            u_id: record.unit_id,
                            start_date: record.start_date,
                        })
                        setValueModal({
                            domain_id: record.domain_id,
                            start_date: record.start_date,
                            uploaded_document: record.uploaded_documents,
                            actiondata: record.action,
                            approve_status: record.approve_status,
                            unit_id: record.unit_id,
                            completion_date: record.completion_date,
                            remarks: record.remarks,
                            description: record.compliance_description,
                            compliance_history_id: record.compliance_history_id,
                            unit: record.unit_name,
                            address: record.address,
                            recall: record.recallremarks,
                            compliance_task: record.compliance_name,
                            compliance_frequency: record.compliance_task_frequency,
                            document_issued_date: record.completion_date,
                            document_reference_number: record.document_reference_number,
                            next_due_date: record.next_due_date != null ? record.next_due_date : record.due_date,
                            history_count: record.history_count,
                            occurrence_remarks: record.occurrence_remarks,
                            status: record.compliance_status
                        })
                    }}
                >{record.due_date}</p>
                )
            },

        },
        {
            title: "Days",
            dataIndex: 'due_date',
            key: 'due_date',
            width: '80px',
            ...getColumnSearchProps('due_date','Days'),
            // render: (text, record) => {
            //     return moment(record.due_date).format('DD-MMM-YYYY')
            // }
            render: (row, record) => {
                let split = record && record.ageing.split(" ")
                return (<p
                    style={{cursor: 'pointer',  color: record.compliance_status == 'Recall' ? 'brown' : 'red' }}
                    onClick={() => {
                        setShowValueModal(true)
                        setValueForFileUpload({
                            d_id: record.domain_id,
                            u_id: record.unit_id,
                            start_date: record.start_date,
                        })
                        setValueModal({
                            domain_id: record.domain_id,
                            start_date: record.start_date,
                            uploaded_document: record.uploaded_documents,
                            actiondata: record.action,
                            approve_status: record.approve_status,
                            unit_id: record.unit_id,
                            completion_date: record.completion_date,
                            remarks: record.remarks,
                            description: record.compliance_description,
                            compliance_history_id: record.compliance_history_id,
                            unit: record.unit_name,
                            address: record.address,
                            recall: record.recallremarks,
                            compliance_task: record.compliance_name,
                            compliance_frequency: record.compliance_task_frequency,
                            document_issued_date: record.completion_date,
                            document_reference_number: record.document_reference_number,
                            next_due_date: record.next_due_date != null ? record.next_due_date : record.due_date,
                            history_count: record.history_count,
                            occurrence_remarks: record.occurrence_remarks,
                            status: record.compliance_status
                        })
                    }}
                >{split && split.length > 0 && split[3]} {split && split.length > 0 && split[4]}</p>
                )
            },

        },
        {
            title: "Time Line",
            dataIndex: 'due_date',
            key: 'due_date',
            width: '100px',
            ...getColumnSearchProps('due_date','Time Line'),
            // render: (text, record) => {
            //     return moment(record.due_date).format('DD-MMM-YYYY')
            // }
            render: (row, record) => {

                return (<p
                    style={{cursor: 'pointer',  color: record.compliance_status == 'Recall' ? 'brown' : record.compliance_status == 'Rectify' ? 'red' : 'black' }}
                    onClick={() => {
                        setShowValueModal(true)
                        setValueForFileUpload({
                            d_id: record.domain_id,
                            u_id: record.unit_id,
                            start_date: record.start_date,
                        })
                        setValueModal({
                            domain_id: record.domain_id,
                            start_date: record.start_date,
                            uploaded_document: record.uploaded_documents,
                            actiondata: record.action,
                            approve_status: record.approve_status,
                            unit_id: record.unit_id,
                            completion_date: record.completion_date,
                            remarks: record.remarks,
                            description: record.compliance_description,
                            compliance_history_id: record.compliance_history_id,
                            unit: record.unit_name,
                            address: record.address,
                            recall: record.recallremarks,
                            compliance_task: record.compliance_name,
                            compliance_frequency: record.compliance_task_frequency,
                            document_issued_date: record.completion_date,
                            document_reference_number: record.document_reference_number,
                            next_due_date: record.next_due_date != null ? record.next_due_date : record.due_date,
                            history_count: record.history_count,
                            occurrence_remarks: record.occurrence_remarks,
                            status: record.compliance_status
                        })
                    }}
                >{(moment(record.due_date).isSameOrAfter(today)) ? "Left" : "Over Due"}</p>
                )
            },

        },
        {
            title: "Status",
            dataIndex: 'due_date',
            key: 'due_date',
            width: '80px',
            ...getColumnSearchProps('due_date','Status'),
            // render: (text, record) => {
            //     return moment(record.due_date).format('DD-MMM-YYYY')
            // }
            render: (row, record) => {

                return (<p
                    style={{ cursor: 'pointer', color: record.compliance_status == 'Recall' ? 'brown' : record.compliance_status == 'Rectify' ? 'red' : 'black' }}
                    onClick={() => {
                        setShowValueModal(true)
                        setValueForFileUpload({
                            d_id: record.domain_id,
                            u_id: record.unit_id,
                            start_date: record.start_date,
                        })
                        setValueModal({
                            domain_id: record.domain_id,
                            start_date: record.start_date,
                            uploaded_document: record.uploaded_documents,
                            actiondata: record.action,
                            approve_status: record.approve_status,
                            unit_id: record.unit_id,
                            completion_date: record.completion_date,
                            remarks: record.remarks,
                            description: record.compliance_description,
                            compliance_history_id: record.compliance_history_id,
                            unit: record.unit_name,
                            address: record.address,
                            recall: record.recallremarks,
                            compliance_task: record.compliance_name,
                            compliance_frequency: record.compliance_task_frequency,
                            document_issued_date: record.completion_date,
                            document_reference_number: record.document_reference_number,
                            next_due_date: record.next_due_date != null ? record.next_due_date : record.due_date,
                            history_count: record.history_count,
                            occurrence_remarks: record.occurrence_remarks,
                            status: record.compliance_status
                        })
                    }}
                >{record.compliance_status}</p>
                )
            },

        },
        {
            title: "Format",
            dataIndex: 'compliance_file_name',
            key: 'compliance_file_name',
            align: 'center',
            width: '60px',
            render: (row, record) => {
                let file = record.compliance_file_name && record.compliance_file_name.length > 0 && record.compliance_file_name[0]
                return (
                    <>
                        {
                            record.compliance_file_name && record.compliance_file_name.length > 0 ?
                                <>
                                    <Tooltip title='Download Format'>
                                        <DownloadOutlined
                                            onClick={() => {
                                                window.open('https://13.234.100.64/' + file)
                                            }}
                                        />
                                    </Tooltip>
                                </> : ''
                        }
                    </>
                )
            }






        },


    ];
    const download = (item) => {
        const downloadpayload =
            [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "DownloadFile_temp",
                        {
                            "le_id": showdata.legal_entity,
                            "c_id": 1,
                            "d_id": valueForFileUpload.d_id,
                            "u_id": valueForFileUpload.u_id,
                            "start_date": valueForFileUpload.start_date,
                            "compliance_history_id": valueModal.compliance_history_id,
                            "upload_type": 2,
                            "file_name": item,
                        }
                    ]
                }
            ]
        Downloadfile({
            payload: downloadpayload,
            paramid: paramid
        });
        // if (item) {
        //     window.open('https:/' + item);
        // }
    }
    const Remove = (index) => {

        let temp = fileupload
        for (let i in fileupload) {
            const removepayload =
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "RemoveFile_temp",
                            {
                                "le_id": showdata.legal_entity,
                                "c_id": 1,
                                "selected_task_info": [
                                    {
                                        "d_id": valueForFileUpload.d_id,
                                        "u_id": valueForFileUpload.u_id,
                                        "start_date": valueForFileUpload.start_date,
                                        "compliance_history_id": valueModal.compliance_history_id,
                                        "file_info": [
                                            {
                                                "file_size": fileupload.file_size,
                                                "file_name": fileupload.file_name,
                                                "file_content": null
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            removedocument({
                payload: removepayload,
                paramid: paramid
            })


            if (fileupload[i]['file_name'] == index) {
                fileupload.splice(i, 1)
            }

        }
        setFileUpload([...fileupload])

    }

    return (
        <div className="page-wrapper" id='page-wrapper'>
            <div className="page-titles pb-2 pt-2">
                <div className="row">
                        <nav className="col-lg-5 col-md-6 col-12 align-self-center" aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <Link className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i>
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <span style={{ color: '#1890ff' }}>Transaction</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: "large", fontWeight: 'bold' }}>
                                    <span style={{ color: '#1890ff' }}>Compliance Task Details</span>
                                </li>
                            </ol>
                        </nav>
                        <div className="col-lg-7 text-end">
                            {/* <Button 
                className="mx-1"
                style={{
                    display: filterTaskAcc ? "none" : "initial",
                  }}
                type="primary"
                shape="round" disabled>

                            {"Total compliance"} : {compliancefiltershow.total_count ? compliancefiltershow.total_count : '0'}
                            </Button>
                            <Button 
                className="mx-1"
                style={{
                    display: filterTaskAcc ? "none" : "initial",
                  }}
                type="primary"
                shape="round" disabled>

                            {/* {"Total Units"} : {tempshowdata ? tempshowdata.length : '0'} 
                            </Button> */}
                            <Button shape={'round'} className='mx-1' style={{display: tab == '2' ? 'initial' : 'none'}} disabled>Over Due : <span> &nbsp; {currentCompliance?.overdue_count}</span></Button>
                            <Button shape={'round'} className='mx-1' style={{display: tab == '2' ? 'initial' : 'none'}} disabled>In-progress : <span> &nbsp; {currentCompliance?.inprogress_count}</span></Button>
                            <Button shape={'round'} className='mx-1' style={{display: tab == '2' ? 'initial' : 'none'}} disabled>Task Recall : <span> &nbsp; {currentCompliance?.recall_count}</span></Button>
                            <Button shape={'round'} className='mx-1' style={{display: tab == '3' ? 'initial' : 'none'}} disabled>Upcoming Compliance : <span> &nbsp; {upcomingTask?.total_count}</span> </Button>

                        <Button
                className="mx-1"
                type="primary"
                shape="round"
                style={{
                  display: filterTaskAcc ? "none" : "initial",
                }}
                onClick={filterHandle}
              >
                <FilterOutlined /> Filter
              </Button>

              <button
                onClick={(e) => {
                  fullscreenMode();
                }}
                className="bg-transparent text-black ms-1 full-mode fullscreenradius"
              >
                <Tooltip
                  placement="left"
                  title="Full Screen Mode"
                >
                  <FullscreenOutlined />
                </Tooltip>
              </button>
                    </div>
                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-lg-12">
                        <div  style={{ borderRadius: '0px' }} className={localStorage.getItem('currentTheme')}>
                            <Collapse accordion defaultActiveKey={['1']} 
                  style={{ display: exitCollapse ? "none" : "block" }}>
                                <Panel header="Compliance Task Details" key="1">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-4" >
                                                {/* <div className="col-md-8" > */}
                                                {/* <div className="mb-3"> */}
                                                {/* <label>Legal Entity:</label> */}
                                                <label htmlFor=""><b>Legal Entity : </b></label><span style={{ color: "red" }}>*</span><br />
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <div className="form-group">
                                                        <Select

                                                            allowClear={false}
                                                            size="default"
                                                            placeholder="Select Legal Entity"
                                                            onChange={(data, value) => {
                                                                if (data !== undefined) {
                                                                    // Setlegalstate(true)
                                                                    // setCurrentEntity(data)
                                                                    Setshowdata({
                                                                        ...showdata,
                                                                        legal_entity: data,

                                                                    })
                                                                }
                                                                else {
                                                                    Setshowdata({
                                                                        ...showdata,
                                                                        legal_entity: '',
                                                                        domain: '',
                                                                        division: '',
                                                                        category: '',
                                                                        unit: '',
                                                                        frequency: ''

                                                                    })
                                                                }
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={showdata.legal_entity || undefined}
                                                            style={{ width: '100%', marginTop: "5px" }}
                                                        >
                                                            {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                return (
                                                                    <Option key={item.le_id}>
                                                                        {item.le_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {formValidator.current.message(
                                                            'legal_entity',
                                                            showdata.legal_entity,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${FormShow ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Legal Entity Required',
                                                                }
                                                            })}
                                                    </div> :
                                                    <p style={{ width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>
                                                }
                                                {/* </div> */}
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>Domain: </b></label><br />
                                                    <Select
                                                        allowClear={false}
                                                        size="default"
                                                        name="domain"
                                                        id="domain"
                                                        placeholder="Enter domain"
                                                        onChange={(data) => {
                                                            if (data !== undefined) {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    domain: data,
                                                                    // unit: '',
                                                                    // frequency: '',
                                                                    // asignee: '',
                                                                    // due_month: '',
                                                                    // act: ''

                                                                })
                                                            }
                                                            else {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    domain: '',
                                                                    // unit: '',
                                                                    // frequency: '',
                                                                    // asignee: '',
                                                                    // due_month: '',
                                                                    // act: ''

                                                                })
                                                            }
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={showdata.domain || undefined}
                                                        style={{ width: '100%', marginTop: "5px" }}
                                                    // disabled={showdata.legal_entity === "" ? true : false}
                                                    >
                                                        {domain && domain.length > 0 && domain.map((item, i) => {
                                                            return (
                                                                <Option key={item.d_id}>
                                                                    {item.d_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                    {/* {formValidator.current.message(
                                                        'domain',
                                                        showdata.domain,
                                                        ['required'],
                                                        {
                                                            className: `invalid-feedback ${FormShow ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Domain Required',
                                                            }
                                                        })} */}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>Division: </b></label><br />
                                                    <Select
                                                        allowClear={false}
                                                        size="default"
                                                        placeholder="Enter Division"
                                                        onChange={(data) => {
                                                            if (data !== undefined) {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    division: data
                                                                })
                                                            }
                                                            else {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    division: ''
                                                                })
                                                            }
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={showdata.division || undefined}
                                                        style={{ width: '100%', marginTop: "5px" }}
                                                    // disabled={showdata.legal_entity === "" ? true : false}
                                                    >
                                                        {division && division.length > 0 && division.map((item, i) => {
                                                            return (
                                                                <Option key={item.div_id}>
                                                                    {item.div_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>Category: </b></label>
                                                    <Select
                                                        allowClear={false}
                                                        size="default"
                                                        placeholder="Enter Category"
                                                        onChange={(data) => {
                                                            if (data !== undefined) {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    category: data
                                                                })
                                                            }
                                                            else {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    category: ''
                                                                })
                                                            }
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={showdata.category || undefined}

                                                        style={{ width: '100%', marginTop: "5px" }}
                                                    >
                                                        {category && category.length > 0 && category.map((item, i) => {
                                                            return (
                                                                <Option key={item.cat_id}>
                                                                    {item.cat_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">

                                                <div className="form-group">

                                                    <label htmlFor=""><b>Unit: </b></label>
                                                    <Select
                                                        allowClear={false}
                                                        size="default"
                                                        placeholder="Enter Unit"
                                                        onChange={(data) => {
                                                            if (data !== undefined) {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    unit: data
                                                                })
                                                            }
                                                            else {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    unit: ''
                                                                })
                                                            }
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={showdata.unit || undefined}

                                                        style={{ width: '100%', marginTop: "5px" }}
                                                    >
                                                        {units && units.length > 0 && units.map((item, i) => {
                                                            return (
                                                                <Option key={item.unit_id}>
                                                                    {item.unit_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                    {/* {formValidator.current.message(
                                                        'date',
                                                        showdata.due_month,
                                                        ['required'],
                                                        {
                                                            className: `invalid-feedback ${FormShow ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Due Month Required',
                                                            }
                                                        })} */}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>Frequency: </b></label>
                                                    <Select
                                                        allowClear={false}
                                                        size="default"
                                                        defaultValue={"All"}
                                                        placeholder="Enter Frequency"
                                                        onChange={(data) => {
                                                            if (data !== undefined) {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    frequency: data
                                                                })
                                                            }
                                                            else {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    frequency: ''
                                                                })
                                                            }
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={showdata.frequency || undefined}
                                                        style={{ width: '100%', marginTop: "5px" }}
                                                    // disabled={showdata.legal_entity === "" ? true : false}
                                                    >
                                                        <Option key=''>All</Option>
                                                        {frequency && frequency.length > 0 && frequency.map((item, i) => {
                                                            return (
                                                                <Option key={item.frequency_id}>
                                                                    {item.frequency_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3 popupbtncolour">
                                        {/* <Link to="" style={{ marginLeft: '40%' }} onClick={()=>{setAccordionTrue(true)}} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Show</Link> */}
                                        {/* <a href={() => false} style={{ marginLeft: '40%' }} onClick={gettaskrecalllist} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Show</a> */}
                                        <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: '46%', marginTop: "5px" }}
                                            size='default'
                                            onClick={onsubmitData}
                                        >Show
                                        </Button>
                                    </div>
                                </Panel>
                            </Collapse>
                            <Modal
                  onCancel={setcancelFilter}
                  footer={false}
                  title="Compliance Task Details"
                  className={
                    "add-service-prv remark-header cons-report " +
                    localStorage.getItem("currentTheme")
                  }
                  visible={filterModalVisible}
                >  
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-4" >
                            {/* <div className="col-md-8" > */}
                            {/* <div className="mb-3"> */}
                            {/* <label>Legal Entity:</label> */}
                            <label htmlFor=""><b>Legal Entity : </b></label><span style={{ color: "red" }}>*</span><br />
                            {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                <div className="form-group">
                                    <Select

                                        allowClear={false}
                                        size="default"
                                        placeholder="Select Legal Entity"
                                        onChange={(data, value) => {
                                            if (data !== undefined) {
                                                // Setlegalstate(true)
                                                // setCurrentEntity(data)
                                                Setshowdata({
                                                    ...showdata,
                                                    legal_entity: data,

                                                })
                                            }
                                            else {
                                                Setshowdata({
                                                    ...showdata,
                                                    legal_entity: '',
                                                    domain: '',
                                                    division: '',
                                                    category: '',
                                                    unit: '',
                                                    frequency: ''

                                                })
                                            }
                                        }}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        value={showdata.legal_entity || undefined}
                                        style={{ width: '100%', marginTop: "5px" }}
                                    >
                                        {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                            return (
                                                <Option key={item.le_id}>
                                                    {item.le_name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                    {formValidator.current.message(
                                        'legal_entity',
                                        showdata.legal_entity,
                                        'required',
                                        {
                                            className: `invalid-feedback ${FormShow ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Legal Entity Required',
                                            }
                                        })}
                                </div> :
                                <p style={{ width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>
                            }
                            {/* </div> */}
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor=""><b>Domain: </b></label><br />
                                <Select
                                    allowClear={false}
                                    size="default"
                                    name="domain"
                                    id="domain"
                                    placeholder="Enter domain"
                                    onChange={(data) => {
                                        if (data !== undefined) {
                                            Setshowdata({
                                                ...showdata,
                                                domain: data,
                                                // unit: '',
                                                // frequency: '',
                                                // asignee: '',
                                                // due_month: '',
                                                // act: ''

                                            })
                                        }
                                        else {
                                            Setshowdata({
                                                ...showdata,
                                                domain: '',
                                                // unit: '',
                                                // frequency: '',
                                                // asignee: '',
                                                // due_month: '',
                                                // act: ''

                                            })
                                        }
                                    }}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    value={showdata.domain || undefined}
                                    style={{ width: '100%', marginTop: "5px" }}
                                // disabled={showdata.legal_entity === "" ? true : false}
                                >
                                    {domain && domain.length > 0 && domain.map((item, i) => {
                                        return (
                                            <Option key={item.d_id}>
                                                {item.d_name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                                {/* {formValidator.current.message(
                                    'domain',
                                    showdata.domain,
                                    ['required'],
                                    {
                                        className: `invalid-feedback ${FormShow ? 'show' : 'hide'}`,
                                        messages: {
                                            required: 'Domain Required',
                                        }
                                    })} */}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor=""><b>Division: </b></label><br />
                                <Select
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Division"
                                    onChange={(data) => {
                                        if (data !== undefined) {
                                            Setshowdata({
                                                ...showdata,
                                                division: data
                                            })
                                        }
                                        else {
                                            Setshowdata({
                                                ...showdata,
                                                division: ''
                                            })
                                        }
                                    }}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    value={showdata.division || undefined}
                                    style={{ width: '100%', marginTop: "5px" }}
                                // disabled={showdata.legal_entity === "" ? true : false}
                                >
                                    {division && division.length > 0 && division.map((item, i) => {
                                        return (
                                            <Option key={item.div_id}>
                                                {item.div_name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mt-3">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor=""><b>Category: </b></label>
                                <Select
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Category"
                                    onChange={(data) => {
                                        if (data !== undefined) {
                                            Setshowdata({
                                                ...showdata,
                                                category: data
                                            })
                                        }
                                        else {
                                            Setshowdata({
                                                ...showdata,
                                                category: ''
                                            })
                                        }
                                    }}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    value={showdata.category || undefined}

                                    style={{ width: '100%', marginTop: "5px" }}
                                >
                                    {category && category.length > 0 && category.map((item, i) => {
                                        return (
                                            <Option key={item.cat_id}>
                                                {item.cat_name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </div>
                        </div>
                        <div className="col-md-4">

                            <div className="form-group">

                                <label htmlFor=""><b>Unit: </b></label>
                                <Select
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Unit"
                                    onChange={(data) => {
                                        if (data !== undefined) {
                                            Setshowdata({
                                                ...showdata,
                                                unit: data
                                            })
                                        }
                                        else {
                                            Setshowdata({
                                                ...showdata,
                                                unit: ''
                                            })
                                        }
                                    }}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    value={showdata.unit || undefined}

                                    style={{ width: '100%', marginTop: "5px" }}
                                >
                                    {units && units.length > 0 && units.map((item, i) => {
                                        return (
                                            <Option key={item.unit_id}>
                                                {item.unit_name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                                {/* {formValidator.current.message(
                                    'date',
                                    showdata.due_month,
                                    ['required'],
                                    {
                                        className: `invalid-feedback ${FormShow ? 'show' : 'hide'}`,
                                        messages: {
                                            required: 'Due Month Required',
                                        }
                                    })} */}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor=""><b>Frequency: </b></label>
                                <Select
                                    allowClear={false}
                                    size="default"
                                    defaultValue={"All"}
                                    placeholder="Enter Frequency"
                                    onChange={(data) => {
                                        if (data !== undefined) {
                                            Setshowdata({
                                                ...showdata,
                                                frequency: data
                                            })
                                        }
                                        else {
                                            Setshowdata({
                                                ...showdata,
                                                frequency: ''
                                            })
                                        }
                                    }}
                                    showSearch
                                    optionFilterProp="children"
                                    // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    value={showdata.frequency || undefined}
                                    style={{ width: '100%', marginTop: "5px" }}
                                // disabled={showdata.legal_entity === "" ? true : false}
                                >
                                    <Option key=''>All</Option>
                                    {frequency && frequency.length > 0 && frequency.map((item, i) => {
                                        return (
                                            <Option key={item.frequency_id}>
                                                {item.frequency_name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </div>
                        </div>
                        <div className="col-md-4">
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mt-3 popupbtncolour">
                    {/* <Link to="" style={{ marginLeft: '40%' }} onClick={()=>{setAccordionTrue(true)}} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Show</Link> */}
                    {/* <a href={() => false} style={{ marginLeft: '40%' }} onClick={gettaskrecalllist} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Show</a> */}
                    <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: '46%', marginTop: "5px" }}
                        size='default'
                        onClick={onsubmitData}
                    >Show
                    </Button>
                </div>
            </Modal>

                            {show == true ?

                                <div className="card p-3 rounded-3 mt-0">

                                    <Tabs type="card"

                                        onChange={(key) => {
                                            setTab(key)
                                            if (key == 1) {

                                                setDate(new Date())
                                                const payload = [
                                                    authtoken,
                                                    {
                                                        "session_token": authtoken,
                                                        "request": [
                                                            "GetCalendarView",
                                                            {
                                                                "le_id": showdata.legal_entity,
                                                                "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                                                                "cal_date": null,
                                                                "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                                                                "div_id": showdata.division != '' ? Number(showdata.division) : null,
                                                                "cat_id": showdata.category != '' ? Number(showdata.category) : null,
                                                                "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                                                            }
                                                        ]
                                                    }
                                                ]
                                                if (showdata.legal_entity != '') {
                                                    getCalenderView({
                                                        payload: payload,
                                                        paramid: paramid
                                                    })
                                                }

                                            }
                                            if (key == 2) {
                                                setStatus({
                                                    overDue: true,
                                                    inprogress: true,
                                                    recall: true
                                                })
                                                const payload = [
                                                    authtoken,
                                                    {
                                                        "session_token": authtoken,
                                                        "request": [
                                                            "GetCurrentComplianceDetail",
                                                            {
                                                                "le_id": showdata.legal_entity,
                                                                "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                                                                "current_start_count": 0,
                                                                "cal_view": null,
                                                                "cal_date": null,
                                                                "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                                                                "division_id": showdata.division != '' ? Number(showdata.division) : null,
                                                                "category_id": showdata.category != '' ? Number(showdata.category) : null,
                                                                "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                                                            }
                                                        ]
                                                    }
                                                ]

                                                getCurrentTaskDetails({

                                                    payload: payload,
                                                    paramid: paramid
                                                })

                                            }
                                            if (key == 3) {
                                                const payload = [
                                                    authtoken,
                                                    {
                                                        "session_token": authtoken,
                                                        "request": [
                                                            "GetUpcomingComplianceDetail",
                                                            {
                                                                "le_id": showdata.legal_entity,
                                                                "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                                                                "upcoming_start_count": 0,
                                                                "cal_view": null,
                                                                "cal_date": null,
                                                                "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                                                                "division_id": showdata.division != '' ? Number(showdata.division) : null,
                                                                "category_id": showdata.category != '' ? Number(showdata.category) : null,
                                                                "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                                                            }
                                                        ]
                                                    }
                                                ]

                                                getUpcomigTaskDetails({

                                                    payload: payload,
                                                    paramid: paramid
                                                })

                                            }


                                        }}
                                        //  onChange={() => {
                                        //     setCategory({
                                        //         compfie: '',
                                        //         compie_litigation: '',
                                        //         compfie_vendor: ''
                                        //     })
                                        // }}
                                        activeKey={tab}

                                    >

                                        <TabPane



                                            tab={
                                                <span
                                                    style={{ "margin-top": "-16px", padding: "0px 50px", fontSize: "16px" }}
                                                >
                                                    <b>Calendar View</b>
                                                </span>
                                            }
                                            key="1">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="card">
                                                            <Calendar
                                                                style={{ height: '300pt', }}
                                                                tooltipAccessor={(e) => {
                                                                    return e.tooltip
                                                                }}
                                                                views={views}
                                                                // components={(e) => {
                                                                //     Event(e)
                                                                // }}
                                                                components={{

                                                                    toolbar: CustomToolbar
                                                                }}
                                                                eventPropGetter={(event, start, end, isSelected) => {
                                                                    var backgroundColor = event.color;
                                                                    var style = {
                                                                        backgroundColor: backgroundColor,
                                                                        borderRadius: '25px',
                                                                        float: 'left',
                                                                        fontSize: '10px !important',
                                                                        height: '20px',
                                                                        textAlign: 'center',
                                                                        lineHeight: '20px',
                                                                        // verticalAlign: 'middle',
                                                                        horizontalAlign: 'middle',
                                                                        marginLeft: '5px',
                                                                        width: '50px',
                                                                        // opacity: 0.8,
                                                                        // color: 'black',
                                                                        // border: '0px',
                                                                        // display: 'block'
                                                                    };
                                                                    return {
                                                                        style: style
                                                                    };
                                                                }}
                                                                date={date}
                                                                events={eventData}
                                                                startAccessor="start"
                                                                endAccessor="end"
                                                                defaultDate={moment().toDate()}
                                                                localizer={localizer}
                                                                eventOrder="horizontal"
                                                                defaultView='month'
                                                                onSelectEvent={onSelectEvent}
                                                                onNavigate={(date) => {
                                                                    let startMonth = moment(date).startOf('month');
                                                                    const payload = [
                                                                        authtoken,
                                                                        {
                                                                            "session_token": authtoken,
                                                                            "request": [
                                                                                "GetCalendarView",
                                                                                {
                                                                                    "le_id": showdata.legal_entity,
                                                                                    "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                                                                                    "cal_date": ((moment((currentMonth && currentMonth._d)).format('DD-MMM-YYYY')) === (moment(startMonth._d).format('DD-MMM-YYYY'))) ? null : moment(startMonth._d).format('DD-MMM-YYYY'),
                                                                                    "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                                                                                    "div_id": showdata.division != '' ? Number(showdata.division) : null,
                                                                                    "cat_id": showdata.category != '' ? Number(showdata.category) : null,
                                                                                    "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                    if (showdata.legal_entity != '') {
                                                                        getCalenderView({
                                                                            payload: payload,
                                                                            paramid: paramid
                                                                        })
                                                                    }
                                                                    setDate(date)
                                                                }}


                                                            />
                                                            <div className="col-md-12 mt-4 text-center">
                                                                <div className="row">

                                                                    <div style={{ fontSize: '13px', fontWeight: 'bold' }}>
                                                                        <div style={{ display: 'inline-block', marginRight: '15px' }}>
                                                                            <div style={{ background: ' #da6d25', height: '20px', width: '20px', borderRadius: '25px', float: 'left', marginRight: '5px' }}></div>
                                                                            <div style={{ float: 'left' }}>Task Recall</div>
                                                                        </div>
                                                                        <div style={{ display: 'inline-block', marginRight: '15px' }}>
                                                                            <div style={{ background: 'rgb(149 46 154)', height: '20px', width: '20px', borderRadius: '25px', float: 'left', marginRight: '5px' }}></div>
                                                                            <div style={{ float: 'left' }}>Due Date</div>
                                                                        </div>
                                                                        <div style={{ display: 'inline-block', marginRight: '15px' }}>
                                                                            <div style={{ background: 'rgb(255 174 44)', height: '20px', width: '20px', borderRadius: '25px', float: 'left', marginRight: '5px' }}></div>
                                                                            <div style={{ float: 'left' }}>Inprogress</div>
                                                                        </div>
                                                                        <div style={{ display: 'inline-block', marginRight: '15px' }}>
                                                                            <div style={{ background: '#f32d2b', height: '20px', width: '20px', borderRadius: '25px', float: 'left', marginRight: '5px' }}></div>
                                                                            <div style={{ float: 'left' }}>Over Due</div>
                                                                        </div>
                                                                        <div style={{ display: 'inline-block', marginRight: '15px' }}>
                                                                            <div style={{ background: '  rgb(42 125 153)', height: '20px', width: '20px', borderRadius: '25px', float: 'left', marginRight: '5px' }}></div>
                                                                            <div style={{ float: 'left' }}>Upcoming</div>
                                                                        </div>
                                                                        {/* <div style="display: inline-block; margin-right: 15px; ">
                            <div style="background: rgb(149 46 154);height: 15px; width: 15px; border-radius:25px;float: left;margin-right: 5px;"></div>
                            <div style="float: left; ">Due Date</div>
                        </div>
                        <div style="display: inline-block;margin-right: 15px; ">
                            <div style="background:  rgb(255 174 44);height: 15px; width: 15px; border-radius:25px;float: left;margin-right: 5px;"></div>
                            <div style="float: left; ">Inprogress</div>
                        </div>
                        <div style="display: inline-block; margin-right: 15px;">
                            <div style="background:  #f32d2b;height: 15px; width: 15px;border-radius:25px;float: left;margin-right: 5px;"></div>
                            <div style="float: left; ">Over Due</div>
                        </div>
                        <div style="display: inline-block;margin-right: 15px; ">
                            <div style="background:  rgb(42 125 153);height: 15px; width: 15px; border-radius:25px;float: left;margin-right: 5px;"></div>
                            <div style="float: left; ">Upcoming</div>
                        </div> */}
                                                                        &nbsp; </div>


                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            {/* <Divider /> */}


                                            <div>


                                            </div>

                                        </TabPane>


                                        <TabPane
                                            // disabled={tabValue.compfie_litigation == false ? true : false}

                                            tab={

                                                <span style={{ padding: "0px 50px", fontSize: "16px" }}
                                                >


                                                    <b>Current Tasks : {tab == '2' ? `${moment(new Date()).format('DD-MMM-YYYY')}UTC` : false}</b>
                                                </span>
                                            }
                                            key="2">
                                            <div className="col-md-12">
                                            <div className="card p-4 py-2 mb-0 rounded user-mapping1 bg-white">
          <div className="d-flex justify-content-between">
            <div></div>
            <div>
              <i style={{ color: "red" }} className="bi bi-square-fill"></i>{" "}
              <label>One time</label>
            </div>
            <div>
              <i style={{ color: "green" }} className="bi bi-square-fill"></i>{" "}
              <label>Periodical</label>
            </div>
            <div>
              <i style={{ color: "orange" }} className="bi bi-square-fill"></i>{" "}
              <label>Review</label>
            </div>
            <div>
              <i style={{ color: "pink" }} className="bi bi-square-fill"></i>{" "}
              <label>Flexi Review</label>
            </div>
            <div>
              <i style={{ color: "yellow" }} className="bi bi-square-fill"></i>{" "}
              <label>On Occurrence</label>
            </div>
            <div></div>
          </div>
        </div>
                                                <div className="row">

                                                    {
                                                        currentDetails && currentDetails.length > 0 && currentDetails.map((list) => {
                                                            return <>
                                                                <Table
                                                                    size={'small'}
                                                                    columns={columns2}
                                                                    dataSource={list.child}
                                                                    bordered
                                                                    title={() => {
                                                                        return list.status
                                                                    }}
                                                                    // {...tableProps}
                                                                    // scroll={{ x: 1500, y: 1000 }}
                                                                    pagination={false}
                                                                // rowSelection={{
                                                                //     columnTitle: <input type="checkbox" name="allchecked" className={'allChecked' + itemdata.index}
                                                                //         onClick={(e) => {
                                                                //             let checked = e.target.checked;
                                                                //             addAllSelectedDataValues(
                                                                //                 checked, itemdata.index
                                                                //             );
                                                                //         }}
                                                                //     />,
                                                                //     columnWidth: '10px',
                                                                //     ...rowSelection
                                                                // }}
                                                                // onHeaderRow={(columns, index) => {
                                                                //     return {
                                                                //         onClick: (e) => {
                                                                //             let checked = e.target.checked;
                                                                //             addAllSelectedDataValues(
                                                                //                 checked,
                                                                //                 itemdata.index
                                                                //             );
                                                                //         }

                                                                //     };
                                                                // }}
                                                                />





                                                            </>

                                                        })
                                                    }
                                                    {

                                                        ((currentCompliance && currentCompliance.overdue_count) + (currentCompliance && currentCompliance.inprogress_count) + (currentCompliance && currentCompliance.recall_count)) == (tempList.length) ?
                                                            false : <div  style={{ marginTop: '2%' }}>
                                                                <div className='text-center'>
                                                                    <Button className="btn btn-success" shape='round'  onClick={showmore} >Show More</Button>
                                                                </div>
                                                            </div>}

                                                    {/* {((currentCompliance && currentCompliance.overdue_count > 0) && (status.overDue == true)) ? <p style={{ marginTop: '2%', marginLeft: '10px' }}>Total Over Due Compliance : {currentCompliance && currentCompliance.overdue_count}</p> : false}
                                                    {((currentCompliance && currentCompliance.inprogress_count > 0) && (status.inprogress == true)) ? <p style={{ marginLeft: '10px' }}>Total In-progress Compliance : {currentCompliance && currentCompliance.inprogress_count
                                                    }</p> : false}
                                                    {((currentCompliance && currentCompliance.recall_count > 0) && (status.recall == true)) ?
                                                        <p style={{ marginLeft: '10px' }}>Total Task Recall Compliance : {currentCompliance && currentCompliance.recall_count
                                                        }</p> : false} */}



                                                </div>
                                            </div>



                                            <div>


                                            </div>

                                        </TabPane>


                                        <TabPane
                                            // disabled={tabValue.compfie_vendor == false ? true : false}
                                            tab={
                                                <span style={{ padding: "0px 50px", fontSize: "16px" }}>


                                                    <b>Upcoming Compliance Tasks</b>
                                                </span>
                                            }
                                            key="3">

                                            <div className="col-md-12">
                                            <div className="card p-4 py-2 mb-0 rounded user-mapping1 bg-white">
          <div className="d-flex justify-content-between">
            <div></div>
            <div>
              <i style={{ color: "red" }} className="bi bi-square-fill"></i>{" "}
              <label>One time</label>
            </div>
            <div>
              <i style={{ color: "green" }} className="bi bi-square-fill"></i>{" "}
              <label>Periodical</label>
            </div>
            <div>
              <i style={{ color: "orange" }} className="bi bi-square-fill"></i>{" "}
              <label>Review</label>
            </div>
            <div>
              <i style={{ color: "pink" }} className="bi bi-square-fill"></i>{" "}
              <label>Flexi Review</label>
            </div>
            <div>
              <i style={{ color: "yellow" }} className="bi bi-square-fill"></i>{" "}
              <label>On Occurrence</label>
            </div>
            <div></div>
          </div>
        </div>
                                                <div className="row">
                                                    {
                                                        titledata.map((list) => {
                                                            return <>
                                                                <Table
                                                                    size={'small'}
                                                                    columns={columns}
                                                                    dataSource={list.child}
                                                                    bordered
                                                                    title={() => {
                                                                        return list.parent
                                                                    }}
                                                                    // {...tableProps}
                                                                    // scroll={{ x: 1500, y: 1000 }}
                                                                    pagination={false}
                                                                // rowSelection={{
                                                                //     columnTitle: <input type="checkbox" name="allchecked" className={'allChecked' + itemdata.index}
                                                                //         onClick={(e) => {
                                                                //             let checked = e.target.checked;
                                                                //             addAllSelectedDataValues(
                                                                //                 checked, itemdata.index
                                                                //             );
                                                                //         }}
                                                                //     />,
                                                                //     columnWidth: '10px',
                                                                //     ...rowSelection
                                                                // }}
                                                                // onHeaderRow={(columns, index) => {
                                                                //     return {
                                                                //         onClick: (e) => {
                                                                //             let checked = e.target.checked;
                                                                //             addAllSelectedDataValues(
                                                                //                 checked,
                                                                //                 itemdata.index
                                                                //             );
                                                                //         }

                                                                //     };
                                                                // }}
                                                                />





                                                            </>

                                                        })
                                                    }

                                                    {/* <p style={{ marginTop: '2%' }}>Total Upcoming Compliance : {upcomingTask && upcomingTask.total_count}</p> */}

                                                </div>
                                            </div>
                                            <div>
                                            </div>

                                        </TabPane>

                                    </Tabs>
                                </div>


                                : ''
                            }

                        </div>
                    </div>


                </div >
            </div >
            {/* <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <Calendar
                                    events={events}
                                   
                                     

                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <Drawer title="" visible={showValueModal} footer={null} cancelText={false}
                onClose={Cancelmodal}
                width={750}
                >
                {/* <div className="row"> */}
                
                    <div className="row" >
                        <div className="col-md-6" style={{ display: 'flex', justifyContent: 'space-between' }} >
                            <label htmlFor=""><h5>Unit</h5></label>
                            <span>:</span>
                        </div>
                        <div className="col-md-6">
                            <p style={{ float: 'left' }}><Tooltip
                                title={valueModal.address}
                            >
                                <ExclamationCircleTwoTone />
                            </Tooltip> &nbsp;{valueModal.unit}</p>
                        </div>
                    </div>
               
                <div className="col-md-12" style={{ marginTop: "5px" }}>
                    <div className="row">
                        <div className="col-md-6" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <label htmlFor=""><h5>Compliance Task</h5></label>
                            <span>:</span>
                        </div>
                        <div className="col-md-6">
                            <p style={{ float: 'left' }}><Tooltip placement='bottom' title={valueModal.description} >
                                <ExclamationCircleTwoTone />
                            </Tooltip> </p>&nbsp;{valueModal.compliance_task}
                        </div>
                    </div>
                </div>
                <div className="col-md-12" style={{ marginTop: "5px" }}>
                    <div className="row">
                        <div className="col-md-6" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <label htmlFor=""><h5>Compliance Frequency</h5></label>
                            <span>:</span>
                        </div>
                        <div className="col-md-6">
                            <p style={{ float: 'left' }}>{valueModal.compliance_frequency}</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-12" style={{ marginTop: "5px" }}>
                    <div className="row">
                        <div className="col-md-6" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <label htmlFor=""><h5>Uploaded Documents</h5></label>
                            <span>:</span>
                        </div>
                        <div className="col-md-3" >
                            <input type="file" multiple name="file"
                                disabled={valueModal.status == 'Recall' ? true : false}
                                onChange={(e) => {
                                    SavepastRecordOnChange(e)
                                }}
                                id="" />
                            <p style={{ fontSize: "10px" }}>(Maxmium 25 MB per file/task)</p>

                        </div>
                        <div className="col-md-3" style={{ textAlign: 'center' }}>
                            <span>{moment(new Date()).format('DD-MMM-YYYY')}</span>
                            <p style={{ fontSize: "12px" }}>{`(Upload Date)`}</p>
                        </div>
                        {/* <div className="col-md-6">
                            {valueModal.uploaded_document != null ?
                                valueModal.uploaded_document.length > 0 && valueModal.uploaded_document.map((item, i) => {
                                    return (
                                        <> < p style={{ float: 'left' }}> {item} <span> <DownloadOutlined
                                            onClick={(e) => {
                                                const remarkapi = [
                                                    authtoken,
                                                    {
                                                        "session_token": authtoken,
                                                        "request": [
                                                            "DownloadFile",
                                                            {
                                                                "le_id": entityid,
                                                                "c_id": countryidd,
                                                                "d_id": valueModal.domain_id,
                                                                "u_id": Number(valueModal.unit_id),
                                                                "start_date": valueModal.start_date,
                                                                "file_name": valueModal.uploaded_document[i]
                                                            }
                                                        ]
                                                    }
                                                ]
                                                if (entityid != "null") {
                                                    getdownloadpath({
                                                        payload: remarkapi,
                                                        paramid: paramid,
                                                    })
                                                }
                                            }}
                                            style={{ color: "blue" }} /></span> </p>
                                          
                                        </>)
                                }) : ""}
                        </div> */}
                    </div>
                </div>
                <div className="col-md-12" style={{ marginTop: "5px" }}>
                    <div className="row">
                        <div className="col-md-6" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <label htmlFor=""><h5>Uploaded File Size</h5></label>
                            <span>:</span>
                        </div>

                        {/* <div className="col-md-6">
                            {valueModal.uploaded_document != null ?
                                valueModal.uploaded_document.length > 0 && valueModal.uploaded_document.map((item, i) => {
                                    return (
                                        <> < p style={{ float: 'left' }}> {item} <span> <DownloadOutlined
                                            onClick={(e) => {
                                                const remarkapi = [
                                                    authtoken,
                                                    {
                                                        "session_token": authtoken,
                                                        "request": [
                                                            "DownloadFile",
                                                            {
                                                                "le_id": entityid,
                                                                "c_id": countryidd,
                                                                "d_id": valueModal.domain_id,
                                                                "u_id": Number(valueModal.unit_id),
                                                                "start_date": valueModal.start_date,
                                                                "file_name": valueModal.uploaded_document[i]
                                                            }
                                                        ]
                                                    }
                                                ]
                                                if (entityid != "null") {
                                                    getdownloadpath({
                                                        payload: remarkapi,
                                                        paramid: paramid,
                                                    })
                                                }
                                            }}
                                            style={{ color: "blue" }} /></span> </p>
                                          
                                        </>)
                                }) : ""}
                        </div> */}
                    </div>
                </div>
                <div className="col-md-12" style={{ marginTop: '5px' }}>
                    <div className="row">
                        <div className="col-md-6" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <label htmlFor=""><b>Attached Documents</b></label>
                            <span>:</span>
                        </div>
                        <div className="col-md-6">
                            {fileupload && fileupload.length > 0 && fileupload.map((item, i) => {

                                return <p>{item.file_name}    <Button type="primary" class='fa fa-download text-primary c-pointer'
                                    icon={<DownloadOutlined />} size='small'
                                    onClick={(e) => {
                                        download(item.file_name)
                                    }}
                                    style={{ marginTop: '2%' }}  >
                                </Button> &nbsp;
                                    <Button type="primary" class='fa fa-times text-primary removeicon'
                                        icon={< CloseOutlined />} size='small'
                                        onClick={(e) => { Remove(item.file_name) }}
                                    >
                                    </Button></p>
                            })}
                        </div>
                    </div>
                </div>
                <div className="col-md-12" style={{ marginTop: "10px" }}>
                    <div className="row">
                        <div className="col-md-6" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <label htmlFor=""><h5>Task Completion Date/Document Issued Date</h5><span style={{ "color": "red" }}>*</span></label>
                            <span>:</span>
                        </div>

                        <div className="col-md-6">
                            <DatePicker allowClear={false} format={'DD-MMM-YYYY'} placeholder={'DD-MMM-YYYY'} className='form-control'
                                // value={valueModal.validity_date ? moment(valueModal.validity_date) : ''} 
                                style={{ width: '80%' }}
                                name="validity_date" id=""
                                disabledDate={(current) => {
                                    return current > Date.now()
                                }}
                                onChange={(dateString) => {
                                    setCompletionDate(
                                        dateString
                                    )
                                }}
                                disabled={valueModal.status == 'Recall' ? true : false}
                            />
                        </div>
                        {/* :
                            <div className="col-md-6">
                                <p style={{ float: 'left' }}>{valueModal.document_issued_date}</p>
                            </div>

                        } */}

                    </div>
                </div>
                <div className="col-md-12" style={{ marginTop: "10px" }}>
                    <div className="row">
                        <div className="col-md-6" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <label htmlFor=""><h5>Document Reference Number</h5></label>
                            <span>:</span>
                        </div>
                        <div className="col-md-6">
                            <Input class="form-control"
                                disabled={valueModal.status == 'Recall' ? true : false}
                                style={{ "width": "80%" }}
                                value={documentNo}
                                onChange={onInputChange1}
                            // onChange={(e) => {
                            //     const value = e.target.value;
                            //     const re = /^[A-Za-z0-9()-_.,/]{0,500}$/;
                            //     if (value === "" || re.test(value)) {
                            //         // SetRemarks(value)
                            //         setDocumentNo(value)
                            //     }
                            // }}
                            />
                            {/* <p style={{ float: 'left' }}>{valueModal.document_reference_number}</p> */}
                        </div>
                    </div>
                </div>

                <div className="col-md-12" style={{ marginTop: "10px" }}>
                    <div className="row">
                        <div className="col-md-6" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <label htmlFor=""><h5>Validity Date</h5></label>
                            <span>:</span>
                        </div>
                        {/* <div className="col-md-6">
                                    {edit === false ?
                                        <a href={() => false}>
                                            <span
                                                className="btn btn-sm btn-light-info text-info btn-circle d-flex align-items-center justify-content-center"
                                            >
                                                <i className="ri-edit-line" onClick={() => {
                                                    setEdit(true)
                                                }}></i>
                                            </span>
                                        </a> :
                                        <DatePicker allowClear={false} format={'DD-MMM-YYYY'} placeholder={'DD-MMM-YYYY'} className='form-control' 
                                        value={valueModal.validity_date ? moment(valueModal.validity_date) : ''} name="validity_date" id="" 
                                        onChange={(dateString) => {
                                            setValueModal({
                                                ...valueModal,
                                                validity_date: dateString
                                            })
                                        }} />
                                    }
                                </div> */}
                    </div>
                </div>
                <div className="col-md-12" style={{ marginTop: "10px" }}>
                    <div className="row">
                        <div className="col-md-6" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <label htmlFor=""><h5>Next Due Date</h5><span style={{ "color": "red" }}>*</span></label>
                            <span>:</span>
                        </div>
                        {/* <div className="col-md-6">
                                    {edit === true ?
                                        <DatePicker allowClear={false} format={'DD-MMM-YYYY'} className='form-control' disabled={moment(valueModal.next_due_date) < moment(currentDate)} value={valueModal.next_due_date ? moment(valueModal.next_due_date) : ''} name="next_due_date" id="" onChange={(dateString) => {
                                            setValueModal({
                                                ...valueModal,
                                                next_due_date: dateString
                                            })
                                        }} />
                                        :
                                        <> <p style={{ float: 'left' }}>{valueModal.next_due_date}</p> </>
                                    }

                                </div> */}
                    </div>
                </div>

                {/* {valueModal.compliance_frequency == 'On Occurrence' ?
                    <>
                        <div className="col-md-12" style={{ marginTop: "5px" }} >
                            <div className="row">
                                <div className="col-md-6">
                                    <label htmlFor=""><h5>Onoccurance Remarks</h5></label>
                                    <span>:</span>
                                </div>
                                <div className="col-md-6">
                                    <p style={{ float: 'left' }}>{valueModal.occurrence_remarks}</p>
                                </div>
                            </div>
                        </div>
                    </>
                    : ''} */}
                <div className="col-md-12" style={{ marginTop: "5px" }} >
                    <div className="row">
                        <div className="col-md-6" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <label htmlFor=""><h5>Status</h5></label>
                            <span>:</span>
                        </div>
                        <div className="col-md-6">
                            <p style={{ float: 'left' }}>{valueModal && valueModal.status}</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-12" style={{ marginTop: "5px" }} >
                    <div className="row">
                        <div className="col-md-6" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <label htmlFor=""><h5>Remarks</h5></label>
                            <span>:</span>
                        </div>
                        <div className="col-md-6">
                            <p style={{ float: 'left' }}>{valueModal.remarks}</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-12" style={{ marginTop: "5px" }} >
                    <div className="row">
                        <div className="col-md-6" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <label htmlFor=""><h5>Recall Remarks</h5></label>
                            <span>:</span>
                        </div>
                        <div className="col-md-6">
                            <p style={{ float: 'left' }}>{valueModal && valueModal.recall}</p>
                        </div>
                    </div>
                </div>
                {
                    valueModal.status !== "Recall" ?
                        <div className="col-md-12" style={{ marginTop: "5px" }}>
                            <div className="row">
                                <div className="col-md-6" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <label htmlFor=""><h5>Remarks</h5></label>
                                    <span>:</span>

                                </div>
                                <div className="col-md-6" style={{ display: 'flex' }}>
                                    <textarea class="form-control" placeholder='Enter remarks' maxLength={500} onChange={onInputChange} rows={1} style={{ width: '80%' }}
                                        value={remarks}
                                    // onChange={(e) => {
                                    //     const value = e.target.value;
                                    //     console.log(value,'valuevalue');
                                    //     const re = /^[A-Za-z0-9_ .,-]{0,500}$/;
                                    //     if (value === "" || re.test(value)) {
                                    //         console.log(value,'valuevaluevaluevalue');
                                    //         SetRemarks(value)
                                    //     }
                                    // }}
                                    />
                                    <span className="ms-0 btn btn-sm  text-info  d-flex 
                                align-items-end justify-content-center"
                                        onClick={() => { showRemarksModal() }}>
                                        <PlusCircleOutlined />
                                    </span>
                                    <div style={{ marginLeft: '2px', marginTop: '8px' }}>
                                        {/* <PlusSquareTwoTone
                                            onClick={() => {
                                                showRemarksModal()
                                            }}

                                        /> */}
                                        {/* <p style={{ float: 'left' }} placeholder="Enter Remarks"
                                             onChange={(e) => {
                                                setValueModal({
                                                    ...valueModal,
                                                    remarks: e.target.value
                                                })
                                            }}></p> */}

                                        {/* <span>{valueModal.remarks}</span> */}
                                        {/* {valueModal.history_count != 0 ?
                                <>
                                    <Tooltip title="click to view remark history" ><ExclamationCircleTwoTone style={{ marginLeft: '5px' }} onClick={(e) => {
                                        showModalremark()
                                        setToolTip([valueModal])
                                        const remarkapi = [
                                            authtoken,
                                            {
                                                "session_token": authtoken,
                                                "request": [
                                                    "GetComplianceRemarksHistoryData",
                                                    {
                                                        "legal_entity_id": entityid,
                                                        "unit_id": Number(valueModal.unit_id),
                                                        "compliance_history_id": Number(valueModal.compliance_history_id)
                                                    }
                                                ]
                                            }
                                        ]
                                        getremarkhistory({
                                            payload: remarkapi,
                                            paramid: paramid
                                        })
                                    }
                                    } /></Tooltip></> : ''
                            } */}
                                    </div>
                                </div>
                            </div>
                        </div> : false
                }


                <br />
                {
                    valueModal.status !== 'Recall' ? <div className='popupbtncolour'>
                        <Button type="primary" shape="round" className={'addbutton '+ localStorage.getItem('currentTheme')}
                            style={{ background: "#198754", borderColor: "#198754", marginLeft: '25%' }}
                            size='default'
                            onClick={() => {
                                if (fileupload && fileupload.length > 0 || remarks != undefined) {
                                    const payload = [
                                        authtoken,
                                        {
                                            "session_token": authtoken,
                                            "request": [
                                                "SaveInterimComplianceHistory",
                                                {
                                                    "legal_entity_id": showdata && showdata.legal_entity,
                                                    "compliance_history_id": valueModal.compliance_history_id,
                                                    "documents": fileupload.length > 0 ? fileupload : null,
                                                    "uploaded_documents": null,
                                                    "remarks": remarks,
                                                    "file_size": 0
                                                }
                                            ]
                                        }
                                    ]

                                    updateStatus({
                                        payload: payload,
                                        paramid: paramid,
                                        setShowValueModal: setShowValueModal
                                    })
                                }
                                else {
                                    Toaster.error("Please give either a valid remarks Or upload document to save")
                                }


                            }}
                        >
                            Update Interim Status
                        </Button>
                        <Button type="primary" shape="round" className={'addbutton '+ localStorage.getItem('currentTheme')}
                            style={{ background: "#198754", borderColor: "#198754", marginLeft: '5%' }}
                            icon={<PlayCircleOutlined />} size='default'
                            onClick={onsubmit

                            }
                        >
                            Submit
                        </Button>
                    </div> : false
                }


            </Drawer>
            <Modal title="Remarks List" visible={remarksModal}
                onCancel={handleCancel}
                className="remarksClass" footer={false}>
                <Table
                    // className='userprivclass'
                    size={'small'}
                    columns={RemarksColumn}
                    dataSource={remarksvalue && remarksvalue.length > 0 ? remarksvalue : ''}
                    bordered
                    pagination={false}
                />
            </Modal>
            <Modal visible={passvisible} className="newStyleModalPassword" footer={null} onCancel={passwordcancel}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="mb-6">
                            <label className="control-label"> <b>Password </b><span style={{ "color": "red" }}>*</span></label>
                            <input
                                type="password"
                                id="password"
                                name='passwordvalue'
                                placeholder="Enter password"
                                onChange={(e) => {
                                    Setpassword({
                                        ...passwordata,
                                        passvalue: e.target.value

                                    })
                                }}
                                value={passwordata.passvalue}
                                style={{ "margin-top": "5px", width: '100%' }}
                                className="form-control"
                            />
                            {formValidator1.current.message(
                                'Password',
                                passwordata.passvalue,
                                ['required'],
                                {
                                    className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Password Required'
                                    }
                                })}
                        </div>
                    </div>

                </div>
                <br />
                <div className="form-actions">
                    <Button type="primary" shape="round" className='addbutton'
                        style={{ background: "#198754", borderColor: "#198754", marginLeft: '33%' }}
                        icon={<PlayCircleOutlined />} size='default'
                        onClick={submitpassword}
                    >Submit
                    </Button>

                    {/* <button
                        className="btn btn-light-danger rounded-pill px-4 ms-2 text-danger waves-effect text-start"
                        onClick={passwordcancel}
                    >Cancel
                    </button> */}
                </div>
            </Modal>

        </div >

    )
}

export default connect(mapStateToProps, {
    compliancefilters,
    emailsave,
    GetGroupListData,
    FetchEmails,
    verifypassword,
    updateblockoption,
    GetOnOccurrenceCompliances,
    onoccurrencetransaction,
    startonoccurrencecompliance,
    GetRemarksData,
    getCalenderView,
    getCurrentTaskDetails,
    getUpcomigTaskDetails,
    getFilterDataValues,
    UploadFile,
    Downloadfile,
    removedocument,
    updateStatus,
    saveStatus
})(ComplianceTaskDetails)
