import React, { Fragment } from 'react'
import { Collapse, Select, Input, DatePicker, Card, Modal, Table, Button, Tooltip, Pagination } from 'antd';
import { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { VCSFilters, VCSexport } from '../../../Store/Action/Report/CV_VendorComplianceScore';
import { connect, useDispatch } from 'react-redux';
import { VENDOR_COMPLIANCE_SCORE_RESET } from './../../../Store/types/index'
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../../Libs/fullscreenmode";

import moment from 'moment';
import { FullscreenOutlined } from '@ant-design/icons';

const mapStateToProps = (state) => ({
    // domain_data: state.Assigneescorecard.assigneescoredata.domain_data,
    // tableData: state.Assigneescorecard.assigneescoredata.getTableData
    Vendorcompliancescore: state.Vendorcompliancescore.vendorcompliancescore,
})

const Vendorcompliancescore = ({
    VCSFilters,
    Vendorcompliancescore: { vendorcompliancescorefilter, vendorcompliancescoreexport },
    VCSexport,
    tableData
}) => {
    console.log(vendorcompliancescoreexport, 'vendorcompliancescoreexport');
    const _ = require("lodash");
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const dispatch = useDispatch();
    const country_info = sessionParsedValue.country_info
    const sessionArr = sessionParsedValue.entity_info
    const [getreportlist, setGetReportList] = useState([])
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const location = useLocation();
    const paramid = location.pathname;
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [tableshow, setTableShow] = useState(false)
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [key, setKey] = useState("1")
    const [datasource, setDatasource] = useState([])
    const [type, setType] = useState('')
    const [division, setDivision] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const [domain, setDomain] = useState([])
    const [vendorfilter, setVendorfilter] = useState([])
    const [totalvendor, setTotalvendor] = useState([])
    const [filteredArray, setFilteredArray] = useState([])
    const [finaltable, setFinaltable] = useState([])

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [legalstate, Setlegalstate] = useState(false)

    const [vendorComplianceScore, setVendorComplianceScore] = useState({
        country: '',
        countryName: '',
        division: 0,
        divisionName: '',
        typeid: '',
        typeName: '',
        category: 0,
        categoryName: '',
        from_month: '',
        to_month: '',
        legel_entity: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
        le_id: '',
        domain: '',
        domainName: '',
        units: [],
        vendorunits: [],
        bg_id: '',
        bg_name: ''
    })
    // const [all, setAll] = useState([]) ;
    // const [alll, setAlll] = (localStorage.getItem('SelectedEntity') === "All Legal Entity" ? ([]) : [localStorage.getItem('SelectedEntityid').toString()]);
    const [all, setAll] = useState((localStorage.getItem('SelectedEntity') === "All Legal Entity" ? ([]) : [localStorage.getItem('SelectedEntityid').toString()]))
    const [allvendor, setAllvendor] = useState([]);
    const [selectcheckbox, Setselectcheckbox] = useState('block');
    const [unselectcheckbox, Setunselectcheckbox] = useState('none');
    const [selectcheckboxvendor, Setselectcheckboxvendor] = useState('block');
    const [unselectcheckboxvendor, Setunselectcheckboxvendor] = useState('none');
    const validator = useRef(new SimpleReactValidator());
    const { Panel } = Collapse;
    const { Option, OptGroup } = Select;

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    useEffect(() => {
        if (vendorComplianceScore.legel_entity == localStorage.getItem('SelectedEntity')) {
            Setlegalstate(true)
        }
    }, [vendorComplianceScore.legel_entity])

    useEffect(() => {
        if (isAuth) {
            // if (legalstate === true) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetVCSFilters",
                        {}
                    ]
                }
            ]
            // if (entityid != 'null') {

            VCSFilters({
                payload: payload,
                paramid: paramid
            })
            // }
            Setlegalstate(false)
            // }
        }

    }, [isAuth, entityid, legalstate])

    useEffect(() => {
        if (vendorcompliancescorefilter) {
            // setaskCategory(filterValue && filterValue.Data && filterValue.Data.task_category_list)
            // setAct(userwisereports && userwisereports.user_act_task_list)
            setCategoryList(vendorcompliancescorefilter && vendorcompliancescorefilter.categories_list)
            // setFrequency(userwisereports && userwisereports.compliance_frequency_list)
            // setCompliancetask(userwisereports && userwisereports.compliance_task_status)
            // setUsertype(userwisereports && userwisereports.compliance_user_type)
            // setUsers(userwisereports && userwisereports.le_users_list)
            setDivision(vendorcompliancescorefilter && vendorcompliancescorefilter.divisions_list)
            // setDomain(vendorcompliancescorefilter && vendorcompliancescorefilter.domains)
            // setUnitcode(userwisereports && userwisereports.unit_code_label_list)
            // setUnit(userwisereports && userwisereports.users_units_list)
        }
    }, [vendorcompliancescorefilter])

    useEffect(() => {
        if (entityid === null) {
            setCurrentEntity(vendorComplianceScore.le_id)
        }
    }, [entityid])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != 'All Legal Entity') {
            setVendorComplianceScore({
                ...vendorComplianceScore,
                le_id: localStorage.getItem('SelectedEntityid')
            })
        }
    }, [localStorage.getItem('SelectedEntity') != 'All Legal Entity'])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != 'All Legal Entity') {
            if (all && all.length > 0) {
                let domain = _.filter(vendorcompliancescorefilter.domains, { le_id: all[0] })
                setDomain(domain)
            }
        }
    }, [localStorage.getItem('SelectedEntity') != 'All Legal Entity', vendorcompliancescorefilter])

    useEffect(() => {
        if (vendorcompliancescoreexport && vendorcompliancescoreexport.link) {
            window.open('https://13.234.100.64/' + vendorcompliancescoreexport.link);
            dispatch({
                type: VENDOR_COMPLIANCE_SCORE_RESET
            });
        }

    }, [vendorcompliancescoreexport])

    const showModal = () => {

        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onexport = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            // setTableShow(true)
            setKey(0)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetVCS",
                        {
                            "country_id": Number(vendorComplianceScore.country),
                            "legal_entity_list": all,
                            "domain_id": Number(vendorComplianceScore.domain),
                            "division_id": Number(vendorComplianceScore.division),
                            "category_id": Number(vendorComplianceScore.category),
                            "vendor_ids": allvendor,
                            "export_type": Number(vendorComplianceScore.typeid),
                            "from_date": moment(vendorComplianceScore.from_month).format("MMM YYYY"),
                            "to_date": moment(vendorComplianceScore.to_month).format("MMM YYYY")
                        }
                    ]
                }
            ]
            VCSexport({
                payload: payload,
                paramid: paramid
            })
        }
    }

    const onChange = (key) => {
        console.log(key);
    };

    const HandelChange = (obj) => {
        let allArray = [];
        if (obj && obj.length > 0) {
            if (obj.includes('all')) {
                if (sessionArr && sessionArr.length > 0) {
                    let temp = []
                    for (let i in sessionArr) {
                        let domain = _.find(vendorcompliancescorefilter.domains, { le_id: sessionArr[i].le_id })
                        temp.push(domain)
                    }
                    let uniqueObjArray = [...new Map(temp && temp.length > 0 && temp.map((items) => [items["d_id"], items])).values()];
                    setDomain(uniqueObjArray)
                }
            } else {
                if (obj.includes('unselect')) {

                } else {
                    let temp = []
                    for (let i in obj) {
                        let domain = _.find(vendorcompliancescorefilter.domains, { le_id: obj[i] })
                        temp.push(domain)
                    }
                    let uniqueObjArray = [...new Map(temp && temp.length > 0 && temp.map((items) => [items["d_id"], items])).values()];
                    setDomain(uniqueObjArray)
                }
            }
        }
        if (obj.includes('all')) {
            if (sessionArr && sessionArr.length > 0) {
                let temp = []
                for (let i in sessionArr) {
                    temp.push(sessionArr[i].le_id)
                    allArray.push(sessionArr[i].le_id)
                }
                setAll(temp)
                setVendorComplianceScore({
                    ...vendorComplianceScore,
                    units: allArray,
                    domain: '',
                    domainName: '',
                    division: 0,
                    divisionName: '',
                    category: 0,
                    categoryName: '',
                    typeid: '',
                    typeName: '',
                    from_month: '',
                    to_date: ''
                })
                setAllvendor([])
            }
            Setselectcheckbox('none');
            Setunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setVendorComplianceScore({
                ...vendorComplianceScore,
                units: [],
                domain: '',
                domainName: '',
                division: 0,
                divisionName: '',
                category: 0,
                categoryName: '',
                typeid: '',
                typeName: '',
                from_month: '',
                to_date: ''
            })
            setAllvendor([])
            setAll(temp);
            Setselectcheckbox('block');
            Setunselectcheckbox('none');
        } else {
            if (obj.length == sessionArr.length) {
                Setselectcheckbox('none');
                Setunselectcheckbox('block');
            } else {
                Setselectcheckbox('block');
                Setunselectcheckbox('none');
            }
            setAll(obj)
            setVendorComplianceScore({
                ...vendorComplianceScore,
                units: obj,
                domain: '',
                domainName: '',
                division: 0,
                divisionName: '',
                category: 0,
                categoryName: '',
                typeid: '',
                typeName: '',
                from_month: '',
                to_date: ''
            })
            setAllvendor([])
        }
    };

    const HandelChangeVendor = (obj) => {
        let allArray = [];
        if (obj.includes('all')) {
            if (vendorfilter && vendorfilter.length > 0) {
                let temp = []
                for (let i in vendorfilter) {
                    for (let j in vendorfilter[i]) {
                        temp.push((vendorfilter[i][j].unit_id).toString())
                        allArray.push(vendorfilter[i][j].unit_name)
                    }
                    // temp.push((vendorfilter[i].unit_id).toString())
                    // allArray.push(vendorfilter[i].unit_name)
                }
                setAllvendor(temp)
                setVendorComplianceScore({
                    ...vendorComplianceScore,
                    vendorunits: allArray,
                })
            }
            Setselectcheckboxvendor('none');
            Setunselectcheckboxvendor('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setVendorComplianceScore({
                ...vendorComplianceScore,
                vendorunits: [],
            })
            setAllvendor(temp);
            Setselectcheckboxvendor('block');
            Setunselectcheckboxvendor('none');
        } else {
            if (obj.length == totalvendor.length) {
                Setselectcheckboxvendor('none');
                Setunselectcheckboxvendor('block');
            } else {
                Setselectcheckboxvendor('block');
                Setunselectcheckboxvendor('none');
            }
            setAllvendor(obj)
            setVendorComplianceScore({
                ...vendorComplianceScore,
                vendorunits: obj,
            })
        }

    };

    // useEffect(() => {
    //     if (tableData && tableData.work_flow_score_card_list && tableData.work_flow_score_card_list.length > 0) {
    //         setFilteredArray(tableData.work_flow_score_card_list)
    //     }
    // }, [tableData.work_flow_score_card_list])

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    const [fullscreen, setfullscreen] = useState(false);
    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', handleKeyDown, false);
            document.addEventListener('webkitfullscreenchange', handleKeyDown, false);
            return () => {
                document.addEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('webkitfullscreenchange', handleKeyDown);
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode();
            setfullscreen(true);
        } else {
            fullscreenexit();
            setfullscreen(false);
        }
    }

    return (
        <Fragment>
            <div className="page-wrapper" id="page-wrapper">
                <div className="page-titles pb-2 pt-2">
                    
                    <div className="row">
                        <nav className="col-lg-6 col-md-6 col-12 align-self-center" aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center p-0">
                                <li className="breadcrumb-item">
                                    <span className="pointer" onClick={homescreen}>
                                        <i className="ri-home-3-line fs-5"></i>
                                    </span>
                                </li>
                                <li className="breadcrumb-item active " aria-current="page">
                                    <span style={{ fontSize: '14px', fontWeight: "bold" }}>Report</span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page" style={{ fontSize: "15px", fontWeight: "bold" }}>
                                    <span>Customized Reports</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: "large", fontWeight: "bold" }}>
                                    <span>Vendor Compliance Score</span>
                                </li>
                            </ol>
                        </nav>
                        <div className="col-lg-6 text-end">
                            <button onClick={(e) => { fullscreenMode(); }} className="bg-transparent text-black ms-1 full-mode fullscreenradius">
                                <Tooltip placement="left" title="Full Screen Mode">
                                    <FullscreenOutlined />
                                </Tooltip>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="container-fluid pt-1">
                    <div className="row">
                        <div className="col-12">
                            <div className={"card-body " + localStorage.getItem('currentTheme')} style={{ padding: "0px 5px" }}>
                                <Collapse defaultActiveKey={["0"]} className="report-collapse" onChange={onChange} >
                                    <Panel header="Vendor Compliance Score" >
                                        {/* key={key} */}
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className='col-md-4'>
                                                    <label><b>Country: </b> <span style={{ color: "red" }}>*</span></label>
                                                    <div className="form-group">
                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                            <div className="form-group">
                                                                <Select
                                                                    allowClear={true}
                                                                    size="default"
                                                                    placeholder="Enter Country"
                                                                    onChange={(value, data) => {
                                                                        setVendorComplianceScore({
                                                                            ...vendorComplianceScore,
                                                                            country: value,
                                                                            countryName: data.children
                                                                        })
                                                                    }}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    //value={Settings.le_id}
                                                                    style={{ width: '100%', marginTop: '5px' }}
                                                                    value={vendorComplianceScore.countryName || undefined}
                                                                >
                                                                    {country_info && country_info.length && country_info.map((item, i) => {
                                                                        return (
                                                                            <Option key={item.c_id}>
                                                                                {item.c_name}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                                {validator.current.message(
                                                                    'countryName',
                                                                    vendorComplianceScore.countryName,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Country Required',
                                                                        }
                                                                    })}
                                                            </div> :
                                                            <p>{sessionArr[0].c_name}</p>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Legal Entity: </b>  <span style={{ color: "red" }}>*</span></label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Enter Legal Entity"
                                                                onChange={(option) => HandelChange(option)}
                                                                // onChange={(data, value) => {
                                                                //     Setlegalstate(true)
                                                                //     setCurrentEntity(data)
                                                                //     setVendorComplianceScore({
                                                                //         ...vendorComplianceScore,
                                                                //         le_id: data,
                                                                //         legel_entity: value.children
                                                                //     })
                                                                // }}
                                                                mode="multiple"
                                                                maxTagCount='responsive'
                                                                style={{ width: '100%' }}
                                                                // value={vendorComplianceScore.legel_entity || undefined}
                                                                value={all}
                                                                disabled={vendorComplianceScore.country == '' ? true : false}
                                                            >
                                                                <Option key="all" value="all" style={{ display: selectcheckbox }}>---SELECT ALL---</Option>
                                                                <Option key="unselect" value="unselect" style={{ display: unselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                {sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.le_id}>
                                                                            {item.le_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'legalentity',
                                                                all,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Legal Entity Required',
                                                                    }
                                                                })}
                                                        </div> :
                                                        <p>{localStorage.getItem('SelectedEntity')}</p>
                                                    }
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Domain: </b>  <span style={{ color: "red" }}>*</span></label>
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            onChange={(value, data) => {
                                                                setVendorComplianceScore({
                                                                    ...vendorComplianceScore,
                                                                    domain: value,
                                                                    domainName: data.children
                                                                })
                                                                // setCategoryList(category)
                                                                let temp = []
                                                                for (let i in all) {
                                                                    let category
                                                                    if (localStorage.getItem('SelectedEntity') != 'All Legal Entity') {
                                                                        category = _.filter(vendorcompliancescorefilter && vendorcompliancescorefilter.unit_legal_entity.length > 0 && vendorcompliancescorefilter.unit_legal_entity, { legal_entity_id: all[0] })
                                                                    } else {
                                                                        category = _.filter(vendorcompliancescorefilter && vendorcompliancescorefilter.unit_legal_entity.length > 0 && vendorcompliancescorefilter.unit_legal_entity, { legal_entity_id: all[i] })
                                                                    }
                                                                    temp.push(category)
                                                                }
                                                                let temparray = []
                                                                for (let j in temp) {
                                                                    temp[j].map((items, i) => {
                                                                        temparray.push(items)
                                                                    })
                                                                }
                                                                setTotalvendor(temparray)
                                                                setVendorfilter(temp)
                                                            }}
                                                            placeholder="Enter Domain Name"
                                                            style={{ width: '100%', marginTop: '5px' }}
                                                            value={vendorComplianceScore.domainName || undefined}
                                                            // disabled={vendorComplianceScore.legel_entity == '' ? true : false}
                                                            disabled={all && all.length > 0 ? false : true}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                        >
                                                            {domain && domain.length > 0 && domain.map((item, i) => {
                                                                if (item != undefined) {
                                                                    return (
                                                                        <Option key={item.d_id}>
                                                                            {item.d_name}
                                                                        </Option>
                                                                    );
                                                                }
                                                            })}
                                                        </Select>
                                                    </div>
                                                    {validator.current.message(
                                                        'domain',
                                                        vendorComplianceScore.domain,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Domain Required',
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12" style={{ marginTop: "15px" }}>
                                            <div className="row">
                                                <div className='col-md-4'>
                                                    <label><b>Type: </b>  <span style={{ color: "red" }}>*</span></label>
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            onChange={(value, data) => {
                                                                setVendorComplianceScore({
                                                                    ...vendorComplianceScore,
                                                                    typeid: value,
                                                                    typeName: data.children
                                                                })
                                                            }}
                                                            showSearch
                                                            placeholder="Select type"
                                                            style={{ width: '100%', marginTop: '5px' }}
                                                            value={vendorComplianceScore.typeName || undefined}
                                                            disabled={vendorComplianceScore.domainName == '' ? true : false}
                                                        // disabled={vendorComplianceScore.legel_entity == '' ? true : false}
                                                        >
                                                            <Option key={"1"}>Consolidated</Option>
                                                            <Option key={"2"}>Month wise</Option>
                                                            {/* {domain_data && domain_data.length && domain_data.map((item, i) => {
                                                                return (
                                                                    <Option key={item.d_id}>
                                                                        {item.d_name}
                                                                    </Option>
                                                                );
                                                            })} */}
                                                        </Select>
                                                    </div>
                                                    {validator.current.message(
                                                        'type',
                                                        vendorComplianceScore.typeid,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Type Required',
                                                            }
                                                        })}
                                                </div>
                                                <div className='col-md-4'>
                                                    <label style={{ "fontWeight": "bold" }}>From Month: <span style={{ color: "red" }} >*</span></label>
                                                    <DatePicker
                                                        style={{ width: '100%', marginTop: '5px' }} allowClear={true} picker="month"
                                                        value={vendorComplianceScore.from_month ? moment(vendorComplianceScore.from_month) : ''}
                                                        onChange={(date, dateString) => {
                                                            setVendorComplianceScore({
                                                                ...vendorComplianceScore,
                                                                from_month: dateString,
                                                                to_month: moment(dateString).add(2, 'months')
                                                            })
                                                        }}
                                                    />
                                                    {validator.current.message(
                                                        'from_month',
                                                        vendorComplianceScore.from_month,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'From Month Required',
                                                            }
                                                        })}
                                                </div>
                                                <div className='col-md-4'>
                                                    <label style={{ "fontWeight": "bold" }}>To Month: <span style={{ color: "red" }} >*</span></label>
                                                    <DatePicker style={{ width: '100%', marginTop: '5px' }}
                                                        allowClear={true} picker="month"
                                                        value={vendorComplianceScore.to_month ? moment(vendorComplianceScore.to_month) : ''}
                                                    // onChange={(date, dateString) => {
                                                    //     setVendorComplianceScore({
                                                    //         ...vendorComplianceScore,
                                                    //         from_month: dateString,
                                                    //         to_month: moment(dateString).add(2, 'months')
                                                    //     })
                                                    // }}
                                                    />
                                                    {validator.current.message(
                                                        'to_month',
                                                        vendorComplianceScore.to_month,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'To Month Required',
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12" style={{ marginTop: "15px" }}>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label style={{ marginBottom: "5px" }} htmlFor=""><b>Business Group: </b></label>
                                                    </div>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Select Business Group"
                                                                onChange={(data, value) => {

                                                                    setVendorComplianceScore({
                                                                        ...vendorComplianceScore,
                                                                        bg_id: data,
                                                                        bg_name: value.children
                                                                    })
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                //  value={userDetails.legal_entity_id}
                                                                style={{ width: '100%' }}
                                                            >
                                                                {sessionArr && sessionArr.length && sessionArr.map((item, i) => {

                                                                    if (item.bg_name !== null) {
                                                                        return (
                                                                            <Option key={item.bg_id}>
                                                                                {item.bg_name}
                                                                            </Option>
                                                                        );
                                                                    }
                                                                })}
                                                            </Select>

                                                        </div> :
                                                        <p> - </p>
                                                    }
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Division: </b></label>
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            onChange={(value, data) => {
                                                                let category = _.filter(vendorcompliancescorefilter.categories_list, { div_id: Number(value) })
                                                                setCategoryList(category)
                                                                setVendorComplianceScore({
                                                                    ...vendorComplianceScore,
                                                                    division: value,
                                                                    divisionName: data.children
                                                                })
                                                            }}
                                                            showSearch
                                                            placeholder="Select Division"
                                                            style={{ width: '100%', marginTop: '5px' }}
                                                            value={vendorComplianceScore.divisionName || undefined}
                                                            disabled={vendorComplianceScore.domainName == '' ? true : false}
                                                        // disabled={vendorComplianceScore.legel_entity == '' ? true : false}
                                                        >
                                                            {division && division.length > 0 && division.map((item, i) => {
                                                                return (
                                                                    <Option key={item.div_id}>
                                                                        {item.div_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Category: </b></label>
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            onChange={(value, data) => {
                                                                setVendorComplianceScore({
                                                                    ...vendorComplianceScore,
                                                                    category: value,
                                                                    categoryName: data.children
                                                                })
                                                            }}
                                                            showSearch
                                                            placeholder="Select Category"
                                                            style={{ width: '100%', marginTop: '5px' }}
                                                            value={vendorComplianceScore.categoryName || undefined}
                                                            disabled={vendorComplianceScore.domainName == '' ? true : false}
                                                        // disabled={vendorComplianceScore.legel_entity == '' ? true : false}
                                                        >
                                                            {categoryList && categoryList.length > 0 && categoryList.map((item, i) => {
                                                                return (
                                                                    <Option key={item.cat_id}>
                                                                        {item.cat_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12" style={{ marginTop: "15px" }}>
                                            <div className="row">
                                                <div className='col-md-4'>
                                                    <label><b>Vendor: </b></label>
                                                    <div className="form-group">
                                                        <Select
                                                            // onChange={(value, data) => {
                                                            //     setVendorComplianceScore({
                                                            //         ...vendorComplianceScore,
                                                            //         vendor: value,
                                                            //         domainName: data.children
                                                            //     })
                                                            // }}
                                                            allowClear={true}
                                                            size="default"
                                                            onChange={(option) => HandelChangeVendor(option)}
                                                            showSearch
                                                            // onChange={(value) => (console.log(value,'option'))}
                                                            placeholder="Select Vendor"
                                                            mode="multiple"
                                                            style={{ width: '100%', marginTop: '5px' }}
                                                            value={vendorComplianceScore.vendorunits || undefined}
                                                            maxTagCount='responsive'
                                                            // value={vendorComplianceScore.domainName || undefined}
                                                            disabled={vendorComplianceScore.domainName == '' ? true : false}
                                                        >
                                                            <Option value="all" style={{ display: selectcheckboxvendor }}>---SELECT ALL---</Option>
                                                            <Option value="unselect" style={{ display: unselectcheckboxvendor }}>--UNSELECT ALL--</Option>
                                                            {vendorfilter && vendorfilter.length && vendorfilter.map((item, i) => {
                                                                // if (sessionArr && sessionArr.length > 0) {
                                                                // let data = _.find(sessionArr, { le_id: item.legal_entity_id })
                                                                return (
                                                                    <OptGroup label={item && item[0].le_name}>
                                                                        {item.map((items, i) => {
                                                                            return (<Option key={items.unit_id}>
                                                                                {items.unit_name}
                                                                            </Option>)
                                                                        })}
                                                                    </OptGroup>
                                                                );
                                                                // }
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "15px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'></div>
                                                <div className='col-md-4' style={{ textAlign: 'center' }}>
                                                    <Button type="primary" shape="round" className='addbutton' style={{ marginLeft: "39px", marginRight: "10px" }}
                                                        onClick={onexport}
                                                    >
                                                        Export
                                                    </Button>
                                                </div>
                                                <div className='col-md-4'></div>
                                            </div>
                                        </div>
                                    </Panel>
                                </Collapse>

                                {tableshow == true ?
                                    <>
                                        <div className='col-md-12 mt-1' >
                                            <div className='row'>
                                                <div className='col-md-5'>
                                                    <label><b>Legal Entity :</b>{localStorage.getItem('SelectedEntity')}</label>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label><b>Country :</b>{sessionArr && sessionArr[0].c_name}</label>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Domain :</b>{vendorComplianceScore.domainName}</label>
                                                </div>


                                            </div>
                                        </div>
                                        <div className='ant-table-wrapper'>
                                            <div className='ant-table ant-table-bordered'>
                                                <div className='ant-table-container'>
                                                    <div className='ant-table-content fh userprivclass1'>
                                                        <table>
                                                            <colgroup>
                                                                <col style={{ width: '10px' }}></col>
                                                            </colgroup>
                                                            <thead className='ant-table-thead'>
                                                                <tr>
                                                                    <th className='ant-table-cell w10px'>#</th>
                                                                    <th title='Compliance Task' className='ant-table-cell custom-width1'>Task Status</th>
                                                                    <th className='ant-table-cell custom-width1'>Assignee</th>
                                                                    <th className='ant-table-cell custom-width1'><center>Concur</center></th>
                                                                    <th className='ant-table-cell custom-width1'>Approver</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className='ant-table-tbody'>
                                                                <tr>
                                                                    <td className='ant-table-cell'><center>1</center></td>
                                                                    <td className='ant-table-cell'><center><Link type="primary"
                                                                        onClick={() => {
                                                                            setType('completed')
                                                                            showModal()
                                                                        }}
                                                                    >Completed</Link></center></td>
                                                                    <td className='ant-table-cell'><center>{filteredArray && filteredArray.length > 0 && filteredArray[0].c_assignee}</center></td>
                                                                    <td className='ant-table-cell'><center>{filteredArray && filteredArray.length > 0 && filteredArray[0].c_concur}</center></td>
                                                                    <td className='ant-table-cell'><center>{filteredArray && filteredArray.length > 0 && filteredArray[0].c_approver}</center></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='ant-table-cell'><center>2</center></td>
                                                                    <td className='ant-table-cell'><center><Link type="primary"
                                                                        onClick={() => {
                                                                            setType('inprogress')
                                                                            showModal()

                                                                        }}
                                                                    >In progress within due date</Link></center></td>
                                                                    <td className='ant-table-cell'><center>{filteredArray && filteredArray.length > 0 && filteredArray[0].inp_assignee}</center></td>
                                                                    <td className='ant-table-cell'><center>{filteredArray && filteredArray.length > 0 && filteredArray[0].inp_concur}</center></td>
                                                                    <td className='ant-table-cell'><center>{filteredArray && filteredArray.length > 0 && filteredArray[0].inp_approver}</center></td>

                                                                </tr>
                                                                <tr>
                                                                    <td className='ant-table-cell'><center>3</center></td>
                                                                    <td className='ant-table-cell'><center><Link type="primary"
                                                                        onClick={() => {
                                                                            setType('overdue')
                                                                            showModal()
                                                                        }}
                                                                    >In progress over due</Link></center></td>
                                                                    <td className='ant-table-cell'><center>{filteredArray && filteredArray.length > 0 && filteredArray[0].ov_assignee}</center></td>
                                                                    <td className='ant-table-cell'><center>{filteredArray && filteredArray.length > 0 && filteredArray[0].ov_concur}</center></td>
                                                                    <td className='ant-table-cell'><center>{filteredArray && filteredArray.length > 0 && filteredArray[0].ov_approver}</center></td>

                                                                </tr>
                                                                <tr>
                                                                    <td className='ant-table-cell'><center></center></td>
                                                                    <td className='ant-table-cell'><center><b>Total</b></center></td>
                                                                    <td className='ant-table-cell'><center>{filteredArray && filteredArray.length > 0 && filteredArray[0].c_assignee + filteredArray[0].inp_assignee + filteredArray[0].ov_assignee}</center></td>
                                                                    <td className='ant-table-cell'><center>{filteredArray && filteredArray.length > 0 && filteredArray[0].c_concur + filteredArray[0].inp_concur + filteredArray[0].ov_concur}</center></td>
                                                                    <td className='ant-table-cell'><center>{filteredArray && filteredArray.length > 0 && filteredArray[0].c_approver + filteredArray[0].inp_approver + filteredArray[0].ov_approver}</center></td>
                                                                </tr>
                                                            </tbody>

                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    : ''
                                    //   <h4 style={{ textAlign: 'center', marginTop: '5%' }}>No Records Found</h4>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}


export default connect(mapStateToProps, {
    VCSFilters,
    VCSexport
})(Vendorcompliancescore);