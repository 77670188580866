import { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom'
import { Collapse, Modal, Input, Table, Button, Space, Tooltip, Select, DatePicker, Card, Switch } from 'antd';
import SimpleReactValidator from "simple-react-validator";
import Swal from 'sweetalert2';
import ScrollToTop from "react-scroll-to-top";
import { CertificateLevelTable, CertificateLevelAdd, savelevelmapping, updatelevelmapping } from './../../Store/Action/Master/CV_CertificateLevelMapping';
import { InfoCircleOutlined, PlayCircleOutlined, PlusOutlined, UpOutlined, SearchOutlined, AudioOutlined, CheckOutlined, ArrowLeftOutlined, FilterFilled, CloseOutlined, PlusCircleOutlined, EyeOutlined, FullscreenOutlined } from '@ant-design/icons';
import { GetRemarksData } from './../../Store/Action/Transcations/Taskrecall'
import { toast } from 'react-toastify';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
const mapStateToProps = (state) => ({
    Certilabeltablelist: state.CertificateLabelMap.Certificate.CertificateTableList,
    Certilabelunitlist: state.CertificateLabelMap.Certificate.CertificateTableAdd,
    filterrecall: state.recallfilters.recallfilters

});
const CertiticateLevelMapping = ({
    savelevelmapping,
    updatelevelmapping,
    CertificateLevelAdd,
    Certilabelunitlist,
    CertificateLevelTable,
    Certilabeltablelist,
    filterrecall: { recallremarks },
    GetRemarksData
}) => {
    console.log(Certilabeltablelist, "Certilabeltablelist");
    const { Option } = Select;
    const { Panel } = Collapse;
    const location = useLocation();
    const paramid = location.pathname;
    const formValidator = useRef(new SimpleReactValidator());
    const [legalentity, setLegalentity] = useState()
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [remarks, setRemarks] = useState('')
    //const entityid = localStorage.getItem('SelectedEntityid');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const validator = useRef(new SimpleReactValidator());
    const validator1 = useRef(new SimpleReactValidator());
    const [show, setShow] = useState(false)
    const [editshow, Seteditshow] = useState(false)
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const [AddFormSubmit2, setAddFormSubmit2] = useState(false);
    const [AddFormSubmit3, setAddFormSubmit3] = useState(false);
    const [FormSubmit, setFormSubmit] = useState(false);
    const [remarksModal, setRemarksModal] = useState(false);
    const [toggleButton, setToggleButton] = useState(false)
    console.log(toggleButton, "toggleButton");
    const [toggleButton1, setToggleButton1] = useState(false)
    console.log(toggleButton1, "toggleButton1");
    const [table, SetTable] = useState(false)
    console.log(table, 'table');
    const [all, setAll] = useState([]);
    console.log(all, "all123");
    const [key, setKey] = useState(false)
    const [selectcheckbox, Setselectcheckbox] = useState('block');
    const [unselectcheckbox, Setunselectcheckbox] = useState('none');
    const [modalVisible, SetmodalVisible] = useState(false);
    const [complianceTask, setComplianceTask] = useState([])
    const [Levelmappingdata, setlevelmappingdata] = useState({
        assignee_id: '',
        assignee_name: '',
        concurrer_id: '',
        concurrer_name: '',
        approver_id: '',
        approver_name: '',
        user_id: '',
        user_name: '',
        is_approver: null,
        is_concurrence: null,
    })
    console.log(Levelmappingdata, "Levelmappingdata");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(
        false
    );
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    const [modalData, setModalData] = useState({
        password: '',
        reason: '',
        unit_id: '',
        legal_entity: '',
        selectedtype: '',
    })
    const [modalData1, setModalData1] = useState({
        password: '',
        reason: '',
        unit_id: '',
        legal_entity: '',
        selectedtype: '',
        date: ''
    })

    console.log(Certilabelunitlist, 'Certilabelunitlist');
    const [remarksvalue, Setremarksvalue] = useState();
    const [page, setPage] = useState(1);
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    console.log(entityid, 'entity');
    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
        }
    }, [localStorage.getItem('SelectedEntityid')])
    useEffect(() => {
        validator1.current.showMessages()
        validator.current.showMessages()
    }, [])
    const { Search } = Input;
    const suffix = (
        <AudioOutlined
            style={{
                fontSize: 16,
                color: '#1890ff',
            }}
        />
    );
    const [searchText, setSearchText] = useState({
        data: '',
        index: 0
    });
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        setSelectedKeys(e.target.value ? [e.target.value] : [])
        confirm({ closeDropdown: false });
        // setSearchText({
        //     ...searchText,
        //     data: selectedKeys[0]
        // });
        // setSearchedColumn(dataIndex);
    };
    const [iconEnable, setIconEnable] = useState(false);
    const [clearText, setClearText] = useState('');

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText({
            data: '',
            index: searchText.index + 1
        });
    };

    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div>
                <Input
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    value={selectedKeys[0]}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}
                />

            </div>
        ),
        filterIcon: (filtered) => (
            <FilterFilled
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) => record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });
    const HandelChange = (obj) => {
        let allArray = [];
        if (obj.includes('all')) {
            if (Certilabelunitlist && Certilabelunitlist.units.length > 0) {
                let temp = []
                for (let i in Certilabelunitlist.units) {
                    temp.push((Certilabelunitlist.units[i].unit_id).toString())
                    allArray.push((Certilabelunitlist.units[i].unit_id).toString())
                }
                console.log(temp, "temp1111");
                setAll(temp)
                setComplianceTask(obj)
            }
            Setselectcheckbox('none');
            Setunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setComplianceTask(obj)
            setAll(temp);
            Setselectcheckbox('block');
            Setunselectcheckbox('none');
        } else {
            console.log(obj, "yhfffffffff")
            if (obj.length == Certilabelunitlist.units.length) {
                Setselectcheckbox('none');
                Setunselectcheckbox('block');
            } else {
                Setselectcheckbox('block');
                Setunselectcheckbox('none');
            }
            setAll(obj)
            setComplianceTask(obj)
        }
        // setfiltervalue({
        //     ...filtervalue,
        //     domain: ''
        // })
        // setUserDetails({
        //     ...userDetails,
        //     units: (allArray && allArray.length > 0 ? allArray : obj)
        // })
        return obj;

    };
    const [mappingid, Setmappingid] = useState({
        mapping_id: ''
    })
    const editservice = (data) => {
        console.log(data, "data123");
        setEditbasedata([data])
        setlevelmappingdata({
            ...Levelmappingdata,
            assignee_id: data.assignee,
            concurrer_id: data.concurrer_id,
            approver_id: data.approver_id,
            is_approver: data.is_approver,
            is_concurrence: data.is_concurrence,
            assignee_name: data.assignee_name,
            concurrer_name: data.concurrer_name,
            approver_name: data.approver_name,
            user_id: data.user_id,
            user_name: data.user_name,
        })
        Setmappingid({
            ...mappingid,
            mapping_id: data.mapping_id
        })
        setShow(true)
        SetTable(false)
        Seteditshow(true)
    }
    const onsubmit = () => {
        setAddFormSubmit1(true)
        setAddFormSubmit2(true)
        setAddFormSubmit3(true)
        if (validator1.current.allValid()) {
            console.log(mappingid.mapping_id, "mappingid.mapping_id");
            if (mappingid.mapping_id === '') {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "SaveCLMDetails",
                            {
                                "legal_entity_id": entityid,
                                "u_ids": [parseInt(all)],
                                "is_approver": true,
                                "is_concurrence": true,
                                "assignee": Number(Levelmappingdata.assignee_id),
                                "concurrence_person": Number(Levelmappingdata.concurrer_id),
                                "approval_person": Number(Levelmappingdata.approver_id),
                            }
                        ]
                    }
                ]
                savelevelmapping({
                    payload: payload,
                    paramid: paramid
                })

            } else {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "UpdateCLMDetails",
                            {
                                "legal_entity_id": entityid,
                                "mapping_id": [mappingid.mapping_id],
                                "is_approver": true,
                                "is_concurrence": true,
                                "assignee": Number(Levelmappingdata.assignee_id),
                                "concurrence_person": Number(Levelmappingdata.concurrer_id),
                                "approval_person": Number(Levelmappingdata.approver_id),
                            }
                        ]
                        // "request": [
                        //     "UpdateCLMDetails",
                        //     {
                        //      "legal_entity_id": "zphf",
                        //      "mapping_id": [
                        //       "11"
                        //      ],
                        //      "is_approver": true,
                        //      "is_concurrence": true,
                        //      "assignee": 23,
                        //      "concurrence_person": 15,
                        //      "approval_person": 1
                        //     }
                        //    ]
                    }
                ]
                updatelevelmapping({
                    payload: payload,
                    paramid: paramid
                })
            }
        }
    }

    console.log(Certilabelunitlist, 'Certilabelunitlist');

    const columns = [
        {
            title: '#',
            dataIndex: false,
            align: 'center',
            width: '20px',
            // ellipsis: true,
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            }
        },
        // {
        //     title: 'Vendor Code',
        //     dataIndex: 'unit_code',
        //     key: 'unit_code',
        //     width: '170px',
        //     // ellipsis: true,
        //     align: 'center',
        //     ...getColumnSearchProps('unit_code', 'Vendor Code'),
        //     // sorter: (a, b) => a.unit_code && b.unit_code != null ? a.unit_code.localeCompare(b.unit_code) : "",
        // },
        {
            title: 'Vendor Name',
            dataIndex: 'unit_name',
            key: 'unit_name',
            width: '150px',
            // ellipsis: true,
            ...getColumnSearchProps('unit_name', 'Vendor Name'),
            // sorter: (a, b) => a.unit_name.localeCompare(b.unit_name),
            render: (text, record, index) => {
                return <span>{`${record.unit_code}-${record.unit_name}`}</span>
            }
        },
        {
            title: 'Assignee',
            dataIndex: 'assignee_name',
            key: 'assignee_name',
            // align: 'center',
            // ellipsis: true,
            width: '120px',
            ...getColumnSearchProps('assignee_name', 'Assignee'),
            render: (text, record, index) => {
                return <span>{record.assignee_name}</span>
            }
            // sorter: (a, b) => a.assignee_name.localeCompare(b.assignee_name),
        },
        {
            title: 'Concurrer',
            dataIndex: 'concurrer_name',
            key: 'concurrer_name',
            width: '120px',
            // align: 'center',
            // ellipsis: true,
            render: (text, record, index) => {
                return <span>{record.concurrer_name}</span>
            },
            ...getColumnSearchProps('concurrer_name', 'Concurrer'),
            // sorter: (a, b) => a.concurrer_name.localeCompare(b.concurrer_name),
        },
        {
            title: 'Approver',
            dataIndex: 'approver_name',
            key: 'approver_name',
            // align: 'center',
            // ellipsis: true,
            // align: 'left',
            ...getColumnSearchProps('approver_name', 'Approver'),
            // sorter: (a, b) => a.approver_name.localeCompare(b.approver_name),
            width: '120px',
            render: (text, record, index) => {
                return <span>{record.approver_name}</span>
            }
        },
        {
            title: 'Edit',
            dataIndex: 'false',
            key: 'false',
            align: "center",
            width: '40px',
            render: (text, record) => {
                console.log(record, "record111");
                return (
                    <Fragment>
                        <div style={{ marginLeft: '33%' }}>
                            <span className="btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center"
                                onClick={(e) => { editservice(record) }}
                            >
                                <Tooltip title="Click here to Edit" color='#39cb7f'>
                                    <i className="ri-edit-line"></i></Tooltip>
                            </span>
                        </div>
                    </Fragment>
                )
            }
        },
    ];
    const columns1 = [
        {
            title: '#',
            dataIndex: false,
            align: 'center',
            width: '50px',
            // ellipsis: true,
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            }
        },
        {
            title: 'Vendor Code',
            dataIndex: 'unit_code',
            key: 'unit_code',
            width: '170px',
            // ellipsis: true,
            align: 'center',
            ...getColumnSearchProps('unit_code', 'Vendor Code'),
            // sorter: (a, b) => a.unit_code && b.unit_code != null ? a.unit_code.localeCompare(b.unit_code) : "",
        },
        {
            title: 'Vendor Name',
            dataIndex: 'unit_name',
            key: 'unit_name',
            width: '150px',
            // ellipsis: true,
            ...getColumnSearchProps('unit_name', 'Vendor Name'),
            // sorter: (a, b) => a.unit_name.localeCompare(b.unit_name),
        },
        {
            title: 'Assignee',
            dataIndex: 'assignee_name',
            key: 'assignee_name',
            align: 'center',
            // ellipsis: true,
            width: '120px',
            ...getColumnSearchProps('assignee_name', 'Assignee'),
            // sorter: (a, b) => a.assignee_name.localeCompare(b.assignee_name),
        },
        {
            title: 'Concurrer',
            dataIndex: 'concurrer_name',
            key: 'concurrer_name',
            width: '120px',
            align: 'center',
            // ellipsis: true,
            ...getColumnSearchProps('concurrer_name', 'Concurrer'),
            // sorter: (a, b) => a.concurrer_name.localeCompare(b.concurrer_name),
        },
        {
            title: 'Approver',
            dataIndex: 'approver_name',
            key: 'approver_name',
            align: 'center',
            // ellipsis: true,
            // align: 'left',
            ...getColumnSearchProps('approver_name', 'Approver'),
            // sorter: (a, b) => a.approver_name.localeCompare(b.approver_name),
            width: '120px',
        },
    ];

    const RemarksColumn = [
        {
            title: 'S.No',
            dataIndex: false,
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            }
        },
        {
            title: 'Description',
            dataIndex: 'r_description',
            key: 'r_description',
            width: '200px',
            ellipsis: true,
            render: (text, record) => <a onClick={() => {
                setRemarks(text)
                setRemarksModal(false)
            }}>{record.r_description}</a>,
        },
    ]

    useEffect(() => {
        if (recallremarks) {
            let temp = [];
            for (let i = 0; i < recallremarks.length; i++) {
                if (recallremarks[i].r_description != '') {
                    temp.push(recallremarks[i])
                }
                Setremarksvalue(temp)
            }
        }

    }, [recallremarks])

    useEffect(() => {
        formValidator.current.showMessages()
    }, [])


    const handleOk = () => {
        // setIsModalVisible(false);
        setRemarksModal(false)
    };

    const handleCancel = () => {
        // setIsModalVisible(false);
        setRemarksModal(false)
    };
    useEffect(() => {
        if (modalData.legal_entity == '' || null || undefined) {
            setModalData({
                ...modalData,
                legal_entity: entityid
            })

        }
    }, [modalData.legal_entity])
    useEffect(() => {
        if (modalData1.legal_entity == '' || null || undefined) {
            setModalData({
                ...modalData1,
                legal_entity: entityid
            })

        }
    }, [modalData1.legal_entity])

    useEffect(() => {
        if (Certilabeltablelist && Certilabeltablelist.certificatemappinglist && Certilabeltablelist.certificatemappinglist.length > 0) {
            setBaseData(Certilabeltablelist.certificatemappinglist);
        }
    }, [Certilabeltablelist.certificatemappinglist])

    const [baseData, setBaseData] = useState([]);
    console.log(Certilabelunitlist.units, 'Certilabelunitlist.units');
    console.log(baseData, "baseData");
    const [EditbaseData, setEditbasedata] = useState([])
    console.log(EditbaseData, "EditbaseData");
    const [fullscreen, setfullscreen] = useState(false);
    const dispatch = useDispatch()
    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    useEffect(() => {
        if (entityid !== '' && entityid !== null) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetCLList",
                        {
                            "legal_entity_id": entityid
                        }
                    ]
                }
            ]
            // setAddFormSubmit(true);
            // if (validator.current.allValid()) {

            if (entityid != "null") {
                CertificateLevelTable({
                    payload: payload,
                    paramid: paramid
                })
                SetTable(true)
            }
            // }
        }
    }, [entityid])
    useEffect(() => {
        if (Certilabelunitlist) {
            if (Certilabelunitlist && Certilabelunitlist.units.length > 0) {
                setShow(true)
                Seteditshow(false)

            }
            else {
                toast.error('Selected Legal Entity has no units')
            }
        }
    }, [Certilabelunitlist])


    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', handleKeyDown, false);
            document.addEventListener('webkitfullscreenchange', handleKeyDown, false);
            return () => {
                document.addEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('webkitfullscreenchange', handleKeyDown);
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode();
            setfullscreen(true);
        } else {
            fullscreenexit();
            setfullscreen(false);
        }
    }
    return (
        <Fragment>
            <div>
                <div id='page-wrapper' className="page-wrapper">
                    <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} />
                    <div className="page-titles pb-1 pt-1">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span style={{ fontSize: '16px' }}>Master</span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span >Certificate Level Mapping</span>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="col-lg-4 col-md-6 text-end">
                                {(entityid !== null && entityid !== '') && (show == false) ? <Button
                                    onClick={() => {

                                        // if (Certilabelunitlist && Certilabelunitlist.units.length > 0) {
                                        const payload = [
                                            authtoken,
                                            {
                                                "session_token": authtoken,
                                                "request": [
                                                    "AddCLList",
                                                    {
                                                        "legal_entity_id": entityid
                                                    }
                                                ]
                                            }
                                        ]
                                        setAddFormSubmit(true);
                                        if (validator.current.allValid()) {
                                            SetTable(false)
                                            if (entityid != "null") {
                                                CertificateLevelAdd({
                                                    payload: payload,
                                                    paramid: paramid
                                                })
                                            }
                                        }

                                    }}
                                    type="primary"
                                    shape="round"
                                    icon={<PlusCircleOutlined />}
                                    className='addbutton'
                                    style={{ marginRight: '2%' }}
                                >
                                    Add
                                </Button> : <Button className='addbutton' shape="round" type="primary"
                                    icon={<ArrowLeftOutlined />} size='default' style={{ marginRight: '2%' }} onClick={() => {
                                        setKey(true)
                                        SetTable(true)
                                        setShow(false)
                                        setToggleButton(false)
                                        setToggleButton1(false)
                                    }}>Back

                                </Button>}
                                <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black full-mode fullscreenradius" style={{ marginRight: '10px' }} >
                                    <Tooltip placement="left" title="Full Screen Mode"><FullscreenOutlined /></Tooltip>
                                </button>
                            </div>
                            <br></br>
                            <div className='col-md-12' style={{ marginTop: '10px' }}>
                                <div className='row'>
                                    <div className='col-md-6' >
                                        <label style={{ "font-weight": 'bold' }}>Legal Entity<span style={{ "color": "red" }}> *</span></label>
                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                            <div className="form-group" style={{ "margin-top": '-24px', "margin-left": '105px' }}>
                                                <Select style={{ width: "80%" }}
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Select Legal Entity"
                                                    onChange={(data, value) => {
                                                        if (data !== undefined) {
                                                            setCurrentEntity(data)
                                                            setLegalentity(data)
                                                            setShow(false)
                                                            setBaseData([])
                                                        }
                                                        else {
                                                            setCurrentEntity('')
                                                            setLegalentity('')
                                                            setShow(false)
                                                            setBaseData([])
                                                        }

                                                    }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    display="none"
                                                >
                                                    {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                        return (
                                                            <Option title="" key={item.le_id}>
                                                                {item.le_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                                {validator.current.message(
                                                    'legalentity',
                                                    legalentity,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Legal Entity Required',
                                                        }
                                                    })}
                                            </div>

                                            :
                                            <p style={{ width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>

                                        }
                                    </div>
                                    {/* <div className='col-md-1'></div> */}
                                    {show == true ?

                                        <div className='col-md-6' style={{ marginTop: '-24px' }}>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <label style={{ "font-weight": 'bold' }}>Approver:</label>

                                                    <div className="serv-switch">
                                                        <Switch
                                                            checkedChildren={<CheckOutlined />}
                                                            unCheckedChildren={<CloseOutlined />}
                                                            // defaultChecked
                                                            onChange={() => { setToggleButton(!toggleButton) }}
                                                        />
                                                    </div>
                                                </div>



                                                {/* <Switch checkedChildren="YES" unCheckedChildren="NO"
                                                onChange={() => { setToggleButton(!toggleButton) }} /> */}

                                                <div className="col-md-3">

                                                    {toggleButton == true ? <>
                                                        <label style={{ "font-weight": 'bold' }}>Concurrer:</label>
                                                        {/* <Switch checkedChildren="YES" unCheckedChildren="NO"
                                                   /> */}
                                                        <div className="serv-switch">
                                                            <Switch
                                                                checkedChildren={<CheckOutlined />}
                                                                unCheckedChildren={<CloseOutlined />}
                                                                // defaultChecked
                                                                onChange={() => {
                                                                    setToggleButton1(!toggleButton1)

                                                                }}
                                                            />
                                                        </div>
                                                    </> : ''}
                                                </div>
                                            </div>
                                            {/* <div className="bt-switch">
                                                <input
                                                    type="checkbox"
                                                    checked
                                                    data-on-color="primary"
                                                    data-off-color="info"
                                                    data-on-text="esttttttttttttttttttt"
                                                    data-off-text="94845>"
                                                />
                                                <input
                                                    type="checkbox"
                                                    checked
                                                    data-on-color="info"
                                                    data-off-color="success"
                                                    data-on-text="<i className='ri-mail-line'/>"
                                                    data-off-text="<i data-feather='mail' className='feather-sm fill-white' />"
                                                />
                                            </div> */}
                                            {/* <div>
                                                <Button className='addbutton' style={{ float: 'right' }}
                                                    icon={<CloseOutlined />} size='default' onClick={() => {
                                                        setKey(true)
                                                        SetTable(true)
                                                        setShow(false)
                                                    }}>

                                                </Button>
                                            </div> */}

                                        </div>


                                        :
                                        <div className='col-md-4'>
                                            {/* <Button
                                                onClick={() => {

                                                    const payload = [
                                                        authtoken,
                                                        {
                                                            "session_token": authtoken,
                                                            "request": [
                                                                "GetCLList",
                                                                {
                                                                    "legal_entity_id": entityid
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                    setAddFormSubmit(true);
                                                    if (validator.current.allValid()) {
                                                        SetTable(true)
                                                        if (entityid != "null") {
                                                            CertificateLevelTable({
                                                                payload: payload,
                                                                paramid: paramid
                                                            })
                                                        }
                                                    }
                                                }}
                                                type="primary"
                                                shape="round"
                                                className='addbutton'
                                                icon={<EyeOutlined />}
                                                style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                            >
                                                Show
                                            </Button> */}


                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {show == true ?
                        <>
                            <div className="col-md-12" style={{ marginTop: "5px" }}>
                                <div className="row">
                                    <div className="form-group">
                                        <div className="card-body" style={{ paddingTop: 0 }}>
                                            {editshow == false ?

                                                <Card className='userCard vendorCard' id="only-restricted" style={{ borderRadius: "15px" }} headStyle={{ borderRadius: "15px", height: '10px', background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }} title={<p>Vendor & User Selection</p>} key="1" >
                                                    {toggleButton == true ? <>
                                                        <div className="col-md-12">
                                                            <div className="row">
                                                                <div className='col-md-4'>
                                                                    <label><b> Vendor</b>  <span style={{ color: "red" }}>*</span></label>
                                                                    <Select
                                                                        name="units"
                                                                        id="units"
                                                                        className='form-control'
                                                                        placeholder="Enter Vendor"
                                                                        onChange={(option) => HandelChange(option)}
                                                                        mode="multiple"
                                                                        value={all}
                                                                        maxTagCount='responsive'
                                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    >
                                                                        { }
                                                                        <Option key="all" value="all" style={{ display: selectcheckbox }}>---SELECT ALL---</Option>
                                                                        <Option key="unselect" value="unselect" style={{ display: unselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                        {Certilabelunitlist && Certilabelunitlist.units.length && Certilabelunitlist.units.map((item, i) => {
                                                                            console.log(item, "item11222");
                                                                            return (
                                                                                <Option key={item.unit_id}>
                                                                                    {item.unit_code} - {item.unitname} - {item.address}
                                                                                </Option>
                                                                            );
                                                                        })}

                                                                    </Select>
                                                                    {/* {validator1.current.message(
                                                    'countryName',
                                                    Levelmappingdata.assignee_name,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Vendor Required',
                                                        }
                                                    })} */}
                                                                </div>
                                                                <div className='col-md-4'>
                                                                    <label><b>Assignee</b>   <span style={{ color: "red" }}>*</span></label>
                                                                    <div className="form-group">
                                                                        <Select name="action" className='select2 form-control custom-select bg-white' id="action" placeholder="Please Select"
                                                                            defaultValue={"Select"}
                                                                            value={Levelmappingdata.assignee_name || undefined}
                                                                            onChange={(value, data) => {
                                                                                setlevelmappingdata({
                                                                                    ...Levelmappingdata,
                                                                                    assignee_id: value,
                                                                                    assignee_name: data.children
                                                                                })
                                                                            }}>
                                                                            {Certilabelunitlist.assignee_list && Certilabelunitlist.assignee_list.length && Certilabelunitlist.assignee_list.map((item, i) => {
                                                                                return (
                                                                                    <Option key={item.user_id}>
                                                                                        {item.employee_name}
                                                                                    </Option>
                                                                                )
                                                                            })}

                                                                        </Select>
                                                                        {validator1.current.message(
                                                                            'AssigneeName',
                                                                            Levelmappingdata.assignee_name,
                                                                            'required',
                                                                            {
                                                                                className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                                                                                messages: {
                                                                                    required: 'Assignee Required',
                                                                                }
                                                                            })}
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-4'>
                                                                    <label><b> Approver</b>  <span style={{ color: "red" }}>*</span></label>
                                                                    <div className="form-group">
                                                                        <Select name="action" className='select2 form-control custom-select bg-white' id="action" placeholder="Please Select"
                                                                            defaultValue={"Select"}
                                                                            disabled={Levelmappingdata.assignee_id === '' ? true : false}
                                                                            value={Levelmappingdata.approver_name || undefined}
                                                                            onChange={(value, data) => {
                                                                                setlevelmappingdata({
                                                                                    ...Levelmappingdata,
                                                                                    approver_id: value,
                                                                                    approver_name: data.children
                                                                                })
                                                                            }} >
                                                                            {Certilabelunitlist.approver_list && Certilabelunitlist.approver_list.length && Certilabelunitlist.approver_list.map((item, i) => {
                                                                                return (
                                                                                    <Option key={item.user_id}>
                                                                                        {item.employee_name}
                                                                                    </Option>
                                                                                )
                                                                            })}

                                                                        </Select>
                                                                        {validator1.current.message(
                                                                            'ApproverName',
                                                                            Levelmappingdata.approver_name,
                                                                            'required',
                                                                            {
                                                                                className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                                                                                messages: {
                                                                                    required: 'Approver Required',
                                                                                }
                                                                            })}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {toggleButton1 == true ?
                                                            <div className="col-md-12">
                                                                <div className="row">
                                                                    <div className='col-md-4'>
                                                                        <label><b> Concurrer</b>   <span style={{ color: "red" }}>*</span></label>
                                                                        <div className="form-group">
                                                                            <Select name="action" className='select2 form-control custom-select bg-white' id="action" placeholder="Please Select"
                                                                                defaultValue={"Select"}
                                                                                value={Levelmappingdata.concurrer_name || undefined}
                                                                                disabled={Levelmappingdata.assignee_id === '' ? true : false}
                                                                                onChange={(value, data) => {
                                                                                    setlevelmappingdata({
                                                                                        ...Levelmappingdata,
                                                                                        concurrer_id: value,
                                                                                        concurrer_name: data.children,
                                                                                        approver_id: '',
                                                                                        approver_name: '',
                                                                                        is_approver: null,
                                                                                        is_concurrence: null,
                                                                                    })
                                                                                }}
                                                                            >
                                                                                {Certilabelunitlist.concur_list && Certilabelunitlist.concur_list.length && Certilabelunitlist.concur_list.map((item, i) => {
                                                                                    return (
                                                                                        <Option key={item.user_id}>
                                                                                            {item.employee_name}
                                                                                        </Option>
                                                                                    );
                                                                                })}

                                                                            </Select>
                                                                            {validator1.current.message(
                                                                                'concurrer_name',
                                                                                Levelmappingdata.concurrer_name,
                                                                                'required',
                                                                                {
                                                                                    className: `invalid-feedback ${AddFormSubmit3 ? 'show' : 'hide'}`,
                                                                                    messages: {
                                                                                        required: 'Concurrer Required',
                                                                                    }
                                                                                })}
                                                                        </div>
                                                                        <div className="col-md-4">

                                                                        </div>
                                                                        <div className="col-md-4">

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> : ''}
                                                    </> :
                                                        <div className="col-md-12">
                                                            <div className="row">


                                                                <div className='col-md-4'>
                                                                    <label><b> Vendor</b>  <span style={{ color: "red" }}>*</span></label>
                                                                    <Select
                                                                        name="units"
                                                                        id="units"
                                                                        className='form-control'
                                                                        placeholder="Enter Vendor"
                                                                        onChange={(option) => HandelChange(option)}
                                                                        mode="multiple"
                                                                        value={all}
                                                                        maxTagCount='responsive'
                                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    >
                                                                        { }
                                                                        <Option key="all" value="all" style={{ display: selectcheckbox }}>---SELECT ALL---</Option>
                                                                        <Option key="unselect" value="unselect" style={{ display: unselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                        {Certilabelunitlist && Certilabelunitlist.units.length && Certilabelunitlist.units.map((item, i) => {
                                                                            console.log(item, "item11222");
                                                                            return (
                                                                                <Option key={item.unit_id}>
                                                                                    {item.unit_code} - {item.unitname} - {item.address}
                                                                                </Option>
                                                                            );
                                                                        })}

                                                                    </Select>
                                                                    {/* {validator1.current.message(
                                                    'countryName',
                                                    Levelmappingdata.assignee_name,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Vendor Required',
                                                        }
                                                    })} */}
                                                                </div>
                                                                <div className='col-md-4'>
                                                                    <label><b> User</b>  <span style={{ color: "red" }}>*</span></label>

                                                                    <Select name="action" className='select2 form-control custom-select bg-white' id="action" placeholder="Please Select"
                                                                        defaultValue={"Select"}
                                                                        value={Levelmappingdata.user_name || undefined}
                                                                        onChange={(value, data) => {
                                                                            setlevelmappingdata({
                                                                                ...Levelmappingdata,
                                                                                user_id: value,
                                                                                user_name: data.children
                                                                            })
                                                                        }} >
                                                                        {Certilabelunitlist.user_list && Certilabelunitlist.user_list.length && Certilabelunitlist.user_list.map((item, i) => {
                                                                            return (
                                                                                <Option key={item.user_id}>
                                                                                    {item.employee_name}
                                                                                </Option>
                                                                            );
                                                                        })}

                                                                    </Select>
                                                                    {validator1.current.message(
                                                                        'Username',
                                                                        Levelmappingdata.user_name,
                                                                        'required',
                                                                        {
                                                                            className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                            messages: {
                                                                                required: 'User Required',
                                                                            }
                                                                        })}
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <Button type="primary" shape="round" className='addbutton'
                                                                        style={{ background: "#198754", borderColor: "#198754", marginTop: '5%', marginLeft: '30%' }}
                                                                        icon={<PlayCircleOutlined />} size='default'
                                                                        onClick={onsubmit}

                                                                    >
                                                                        Submit
                                                                    </Button>
                                                                </div>
                                                                {/* <div className="col-md-4"></div> */}
                                                            </div>
                                                        </div>}
                                                    <div className='col-md-12'>
                                                        <div className='row'>
                                                            <div className='col-md-4'></div>
                                                            <div className='col-md-4'>
                                                                {toggleButton == true ?
                                                                    <div className="form-actions" style={{ textAlign: 'center' }}>
                                                                        <Button type="primary" shape="round" className='addbutton'
                                                                            style={{ background: "#198754", borderColor: "#198754", marginTop: '5%' }}
                                                                            icon={<PlayCircleOutlined />} size='default'
                                                                            onClick={onsubmit}

                                                                        >
                                                                            Submit
                                                                        </Button>
                                                                    </div> : false}
                                                            </div>
                                                            <div className='col-md-4'></div>
                                                        </div>
                                                    </div>
                                                </Card>
                                                : ''}
                                            {editshow == true ?
                                                <>
                                                    <div className="card-body" style={{ padding: '0px' }}>
                                                        <Table
                                                            columns={columns1}
                                                            className={localStorage.getItem("currentTheme")}
                                                            dataSource={EditbaseData}
                                                            bordered

                                                        />
                                                    </div>
                                                    <Card className='userCard vendorCard' style={{ borderRadius: "15px" }} headStyle={{ borderRadius: "15px", height: '10px', background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }} title={<p>User Selection <span style={{ color: 'red' }}>*</span></p>} key="1" >
                                                        {toggleButton == true ? <>
                                                            <div className="col-md-12">
                                                                <div className="row">

                                                                    <div className='col-md-4'>
                                                                        <label><b>Assignee</b>   <span style={{ color: "red" }}>*</span></label>
                                                                        <div className="form-group">
                                                                            <Select name="action" className='select2 form-control custom-select bg-white' id="action" placeholder="Please Select"
                                                                                defaultValue={"Select"}
                                                                                value={Levelmappingdata.assignee_name || undefined}
                                                                                onChange={(value, data) => {
                                                                                    setlevelmappingdata({
                                                                                        ...Levelmappingdata,
                                                                                        assignee_id: value,
                                                                                        assignee_name: data.children
                                                                                    })
                                                                                }}>
                                                                                {Certilabelunitlist.assignee_list && Certilabelunitlist.assignee_list.length && Certilabelunitlist.assignee_list.map((item, i) => {
                                                                                    return (
                                                                                        <Option key={item.user_id}>
                                                                                            {item.employee_name}
                                                                                        </Option>
                                                                                    )
                                                                                })}

                                                                            </Select>
                                                                            {validator1.current.message(
                                                                                'AssigneeName',
                                                                                Levelmappingdata.assignee_name,
                                                                                'required',
                                                                                {
                                                                                    className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                                                                                    messages: {
                                                                                        required: 'Assignee Required',
                                                                                    }
                                                                                })}
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-4'>
                                                                        <label><b> Approver</b>  <span style={{ color: "red" }}>*</span></label>
                                                                        <div className="form-group">
                                                                            <Select name="action" className='select2 form-control custom-select bg-white' id="action" placeholder="Please Select"
                                                                                defaultValue={"Select"}
                                                                                disabled={Levelmappingdata.assignee_id === '' ? true : false}
                                                                                value={Levelmappingdata.approver_name || undefined}
                                                                                onChange={(value, data) => {
                                                                                    setlevelmappingdata({
                                                                                        ...Levelmappingdata,
                                                                                        approver_id: value,
                                                                                        approver_name: data.children
                                                                                    })
                                                                                }} >
                                                                                {Certilabelunitlist.approver_list && Certilabelunitlist.approver_list.length && Certilabelunitlist.approver_list.map((item, i) => {
                                                                                    return (
                                                                                        <Option key={item.user_id}>
                                                                                            {item.employee_name}
                                                                                        </Option>
                                                                                    )
                                                                                })}

                                                                            </Select>
                                                                            {validator1.current.message(
                                                                                'ApproverName',
                                                                                Levelmappingdata.approver_name,
                                                                                'required',
                                                                                {
                                                                                    className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                                                                                    messages: {
                                                                                        required: 'Approver Required',
                                                                                    }
                                                                                })}
                                                                        </div>
                                                                    </div>
                                                                    {toggleButton1 == true ?

                                                                        <div className='col-md-4'>
                                                                            <label><b> Concurrer</b>   <span style={{ color: "red" }}>*</span></label>
                                                                            <div className="form-group">
                                                                                <Select name="action" className='select2 form-control custom-select bg-white' id="action" placeholder="Please Select"
                                                                                    defaultValue={"Select"}
                                                                                    value={Levelmappingdata.concurrer_name || undefined}
                                                                                    disabled={Levelmappingdata.assignee_id === '' ? true : false}
                                                                                    onChange={(value, data) => {
                                                                                        setlevelmappingdata({
                                                                                            ...Levelmappingdata,
                                                                                            concurrer_id: value,
                                                                                            concurrer_name: data.children,
                                                                                            approver_id: '',
                                                                                            approver_name: '',
                                                                                            is_approver: null,
                                                                                            is_concurrence: null,
                                                                                        })
                                                                                    }}
                                                                                >
                                                                                    {Certilabelunitlist.concur_list && Certilabelunitlist.concur_list.length && Certilabelunitlist.concur_list.map((item, i) => {
                                                                                        return (
                                                                                            <Option key={item.user_id}>
                                                                                                {item.employee_name}
                                                                                            </Option>
                                                                                        );
                                                                                    })}

                                                                                </Select>
                                                                                {validator1.current.message(
                                                                                    'concurrer_name',
                                                                                    Levelmappingdata.concurrer_name,
                                                                                    'required',
                                                                                    {
                                                                                        className: `invalid-feedback ${AddFormSubmit3 ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            required: 'Concurrer Required',
                                                                                        }
                                                                                    })}
                                                                            </div>
                                                                            <div className="col-md-4">

                                                                            </div>
                                                                            <div className="col-md-4">


                                                                            </div>
                                                                        </div> : ''}
                                                                </div>
                                                            </div>

                                                        </> :
                                                            <div className="col-md-12">
                                                                <div className="row">


                                                                    <div className='col-md-4'>

                                                                    </div>
                                                                    <div className='col-md-4'>
                                                                        <label><b> User</b>  <span style={{ color: "red" }}>*</span></label>

                                                                        <Select name="action" className='select2 form-control custom-select bg-white' id="action" placeholder="Please Select"
                                                                            defaultValue={"Select"}
                                                                            value={Levelmappingdata.user_name || undefined}
                                                                            onChange={(value, data) => {
                                                                                setlevelmappingdata({
                                                                                    ...Levelmappingdata,
                                                                                    user_id: value,
                                                                                    user_name: data.children
                                                                                })
                                                                            }} >
                                                                            {Certilabelunitlist.user_list && Certilabelunitlist.user_list.length && Certilabelunitlist.user_list.map((item, i) => {
                                                                                return (
                                                                                    <Option key={item.user_id}>
                                                                                        {item.employee_name}
                                                                                    </Option>
                                                                                );
                                                                            })}

                                                                        </Select>
                                                                        {validator1.current.message(
                                                                            'Username',
                                                                            Levelmappingdata.user_name,
                                                                            'required',
                                                                            {
                                                                                className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                                messages: {
                                                                                    required: 'User Required',
                                                                                }
                                                                            })}
                                                                    </div>

                                                                    <div className="col-md-4"></div>
                                                                </div>
                                                            </div>}
                                                        <div className='col-md-12'>
                                                            <div className='row'>
                                                                <div className='col-md-4'></div>
                                                                <div className='col-md-4'>
                                                                    <div className="form-actions" style={{ textAlign: 'center' }}>
                                                                        <Button type="primary" shape="round" className='addbutton'
                                                                            style={{ background: "#198754", borderColor: "#198754", marginTop: '5%' }}
                                                                            icon={<PlayCircleOutlined />} size='default'
                                                                            onClick={onsubmit}

                                                                        >
                                                                            Submit
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-4'></div>
                                                            </div>
                                                        </div>
                                                    </Card>

                                                </>
                                                : ''}


                                            {/* <Card title="User Selection" className='userCard vendorCard' style={{ borderRadius: "15px" }} headStyle={{ borderRadius: "15px", height: '10px', background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }} key="1">
                                                {toggleButton == true ?
                                                    <div className='col-md-12'>
                                                        <div className='row'>
                                                            {toggleButton1 == true ? <>
                                                                <div className='col-md-4'>
                                                                    <label><b>Assignee</b>   <span style={{ color: "red" }}>*</span></label>
                                                                    <div className="form-group">
                                                                        <Select name="action" className='select2 form-control custom-select bg-white' id="action" placeholder="Please Select"
                                                                            defaultValue={"Select"}
                                                                            value={Levelmappingdata.assignee_name || undefined}
                                                                            onChange={(value, data) => {
                                                                                setlevelmappingdata({
                                                                                    ...Levelmappingdata,
                                                                                    assignee_id: value,
                                                                                    assignee_name: data.children,
                                                                                    concurrer_id: '',
                                                                                    concurrer_name: '',
                                                                                    approver_id: '',
                                                                                    approver_name: '',
                                                                                    is_approver: null,
                                                                                    is_concurrence: null,

                                                                                })
                                                                            }}>
                                                                            {Certilabelunitlist.assignee_list && Certilabelunitlist.assignee_list.length && Certilabelunitlist.assignee_list.map((item, i) => {
                                                                                return (
                                                                                    <Option key={item.user_id}>
                                                                                        {item.employee_name}
                                                                                    </Option>
                                                                                );
                                                                            })}

                                                                        </Select>
                                                                        {validator1.current.message(
                                                                            'assignee_name',
                                                                            Levelmappingdata.assignee_name,
                                                                            'required',
                                                                            {
                                                                                className: `invalid-feedback ${AddFormSubmit3 ? 'show' : 'hide'}`,
                                                                                messages: {
                                                                                    required: 'Assignee Required',
                                                                                }
                                                                            })}
                                                                    </div>
                                                                </div>

                                                                <div className='col-md-4'>
                                                                    <label><b> Concurrer</b>   <span style={{ color: "red" }}>*</span></label>
                                                                    <div className="form-group">
                                                                        <Select name="action" className='select2 form-control custom-select bg-white' id="action" placeholder="Please Select"
                                                                            defaultValue={"Select"}
                                                                            value={Levelmappingdata.concurrer_name || undefined}
                                                                            disabled={Levelmappingdata.assignee_id === '' ? true : false}
                                                                            onChange={(value, data) => {
                                                                                setlevelmappingdata({
                                                                                    ...Levelmappingdata,
                                                                                    concurrer_id: value,
                                                                                    concurrer_name: data.children,
                                                                                    approver_id: '',
                                                                                    approver_name: '',
                                                                                    is_approver: null,
                                                                                    is_concurrence: null,
                                                                                })
                                                                            }}
                                                                        >
                                                                            {Certilabelunitlist.concur_list && Certilabelunitlist.concur_list.length && Certilabelunitlist.concur_list.map((item, i) => {
                                                                                return (
                                                                                    <Option key={item.user_id}>
                                                                                        {item.employee_name}
                                                                                    </Option>
                                                                                );
                                                                            })}

                                                                        </Select>
                                                                        {validator1.current.message(
                                                                            'concurrer_name',
                                                                            Levelmappingdata.concurrer_name,
                                                                            'required',
                                                                            {
                                                                                className: `invalid-feedback ${AddFormSubmit3 ? 'show' : 'hide'}`,
                                                                                messages: {
                                                                                    required: 'Concurrer Required',
                                                                                }
                                                                            })}
                                                                    </div>
                                                                </div>

                                                                <div className='col-md-4'>
                                                                    <label><b> Approver</b>  <span style={{ color: "red" }}>*</span></label>
                                                                    <div className="form-group">
                                                                        <Select name="action" className='select2 form-control custom-select bg-white' id="action" placeholder="Please Select"
                                                                            value={Levelmappingdata.approver_name || undefined}
                                                                            defaultValue={"Select"}
                                                                            disabled={Levelmappingdata.concurrer_id === '' ? true : false}
                                                                            onChange={(value, data) => {
                                                                                setlevelmappingdata({
                                                                                    ...Levelmappingdata,
                                                                                    approver_id: value,
                                                                                    approver_name: data.children
                                                                                })
                                                                            }} >
                                                                            {Certilabelunitlist.approver_list && Certilabelunitlist.approver_list.length && Certilabelunitlist.approver_list.map((item, i) => {
                                                                                return (
                                                                                    <Option key={item.user_id}>
                                                                                        {item.employee_name}
                                                                                    </Option>
                                                                                );
                                                                            })}

                                                                        </Select>
                                                                        {validator1.current.message(
                                                                            'approver_name',
                                                                            Levelmappingdata.approver_name,
                                                                            'required',
                                                                            {
                                                                                className: `invalid-feedback ${AddFormSubmit3 ? 'show' : 'hide'}`,
                                                                                messages: {
                                                                                    required: 'Approver Required',
                                                                                }
                                                                            })}
                                                                    </div>
                                                                </div>
                                                            </> : <>
                                                                <div className='col-md-6'>
                                                                    <label><b>Assignee</b>   <span style={{ color: "red" }}>*</span></label>
                                                                    <div className="form-group">
                                                                        <Select name="action" className='select2 form-control custom-select bg-white' id="action" placeholder="Please Select"
                                                                            defaultValue={"Select"}
                                                                            value={Levelmappingdata.assignee_name || undefined}
                                                                            onChange={(value, data) => {
                                                                                setlevelmappingdata({
                                                                                    ...Levelmappingdata,
                                                                                    assignee_id: value,
                                                                                    assignee_name: data.children
                                                                                })
                                                                            }}>
                                                                            {Certilabelunitlist.assignee_list && Certilabelunitlist.assignee_list.length && Certilabelunitlist.assignee_list.map((item, i) => {
                                                                                return (
                                                                                    <Option key={item.user_id}>
                                                                                        {item.employee_name}
                                                                                    </Option>
                                                                                )
                                                                            })}

                                                                        </Select>
                                                                        {validator1.current.message(
                                                                            'AssigneeName',
                                                                            Levelmappingdata.assignee_name,
                                                                            'required',
                                                                            {
                                                                                className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                                                                                messages: {
                                                                                    required: 'Assignee Required',
                                                                                }
                                                                            })}
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <label><b> Approver</b>  <span style={{ color: "red" }}>*</span></label>
                                                                    <div className="form-group">
                                                                        <Select name="action" className='select2 form-control custom-select bg-white' id="action" placeholder="Please Select"
                                                                            defaultValue={"Select"}
                                                                            disabled={Levelmappingdata.assignee_id === '' ? true : false}
                                                                            value={Levelmappingdata.approver_name || undefined}
                                                                            onChange={(value, data) => {
                                                                                setlevelmappingdata({
                                                                                    ...Levelmappingdata,
                                                                                    approver_id: value,
                                                                                    approver_name: data.children
                                                                                })
                                                                            }} >
                                                                            {Certilabelunitlist.approver_list && Certilabelunitlist.approver_list.length && Certilabelunitlist.approver_list.map((item, i) => {
                                                                                return (
                                                                                    <Option key={item.user_id}>
                                                                                        {item.employee_name}
                                                                                    </Option>
                                                                                )
                                                                            })}

                                                                        </Select>
                                                                        {validator1.current.message(
                                                                            'ApproverName',
                                                                            Levelmappingdata.approver_name,
                                                                            'required',
                                                                            {
                                                                                className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                                                                                messages: {
                                                                                    required: 'Approver Required',
                                                                                }
                                                                            })}
                                                                    </div>
                                                                </div></>}

                                                        </div>
                                                    </div> :
                                                    <div className='col-md-12'>
                                                        <div className='row'>
                                                            <div className='col-md-4'> </div>
                                                            <div className='col-md-4'>
                                                                <label><b> User</b>  <span style={{ color: "red" }}>*</span></label>
                                                                <div className="form-group">
                                                                    <Select name="action" className='select2 form-control custom-select bg-white' id="action" placeholder="Please Select"
                                                                        defaultValue={"Select"}
                                                                        value={Levelmappingdata.user_name || undefined}
                                                                        onChange={(value, data) => {
                                                                            setlevelmappingdata({
                                                                                ...Levelmappingdata,
                                                                                user_id: value,
                                                                                user_name: data.children
                                                                            })
                                                                        }} >
                                                                        {Certilabelunitlist.user_list && Certilabelunitlist.user_list.length && Certilabelunitlist.user_list.map((item, i) => {
                                                                            return (
                                                                                <Option key={item.user_id}>
                                                                                    {item.employee_name}
                                                                                </Option>
                                                                            );
                                                                        })}

                                                                    </Select>
                                                                    {validator1.current.message(
                                                                        'Username',
                                                                        Levelmappingdata.user_name,
                                                                        'required',
                                                                        {
                                                                            className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                            messages: {
                                                                                required: 'User Required',
                                                                            }
                                                                        })}
                                                                </div>
                                                            </div>
                                                            <div className='col-md-4'> </div>

                                                        </div>
                                                    </div>}
                                                <div className='col-md-12'>
                                                    <div className='row'>
                                                        <div className='col-md-4'></div>
                                                        <div className='col-md-4'>
                                                            <div className="form-actions" style={{ textAlign: 'center', marginTop: '50px' }}>
                                                                <Button type="primary" shape="round" className='addbutton'
                                                                    style={{ background: "#198754", borderColor: "#198754" }}
                                                                    icon={<PlayCircleOutlined />} size='default'
                                                                    onClick={onsubmit}

                                                                >
                                                                    Submit
                                                                </Button>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-4'></div>
                                                    </div>
                                                </div>

                                            </Card> */}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        : ""}



                    {show == false ?
                        <div className="container-fluid pt-0">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body" style={{ padding: '0px' }}>
                                            <Table
                                                columns={columns}
                                                className={localStorage.getItem("currentTheme")}
                                                dataSource={baseData}
                                                bordered

                                                pagination={{
                                                    defaultPageSize: dataTableProperties.pagesize,
                                                    showSizeChanger: dataTableProperties.sizechanger
                                                }}

                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : ''}

                </div >

            </div>

        </Fragment >
    )
}
export default connect(mapStateToProps, {
    CertificateLevelTable,
    CertificateLevelAdd,
    GetRemarksData,
    savelevelmapping,
    updatelevelmapping,
})(CertiticateLevelMapping);