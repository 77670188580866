import React from 'react'
import { Collapse, Select, Input, Card, Drawer, Button, Table, Modal, Pagination, Tooltip, Progress } from 'antd';
import { useState, useEffect, useRef, Fragment } from 'react';
import { useLocation, Link } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { connect } from 'react-redux';
import { UnitListFilters, UnitListshow, UnitListexport, } from '../../Store/Action/Report/UnitlistReport';
import { toast } from 'react-toastify';
import { PlayCircleOutlined, FullscreenOutlined, UpOutlined, ExportOutlined, EyeOutlined, FilterOutlined } from '@ant-design/icons';
import { fullscreenmode, fullscreenexit, handleKeyDown } from './../../Libs/fullscreenmode';
import ScrollToTop from "react-scroll-to-top";

const mapStateToProps = (state) => ({
    filerunitdatas: state.UnitlistReport.unitlistreports.unitlist,
    tabledatas: state.UnitlistReport.unitlistreports.tabledata
})

const UnitlistReport = ({
    UnitListFilters,
    UnitListshow,
    UnitListexport,
    filerunitdatas,
    tabledatas
}) => {
    const { Panel } = Collapse;
    const { Option } = Select;
    const location = useLocation();
    const paramid = location.pathname;
    const _ = require("lodash");
    const sessionValue = localStorage.getItem('sessionValue')
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const country_info = sessionParsedValue.country_info
    const validator = useRef(new SimpleReactValidator());
    const [open, setOpen] = useState(false);
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [tableshow, setTableShow] = useState(false)
    const [statkey, setStatKey] = useState("0")
    const [key, setKey] = useState("1")
    const [countrydata, setCountryData] = useState(null)
    const [legalstate, Setlegalstate] = useState(false)
    const [filerdatas, setfilerdatas] = useState([])
    const [checkboxValue, setCheckBoxValue] = useState([1, 2, 3, 4, 5, 6, 11])
    const [tabledata, setTableData] = useState([])
    const [legalEntity, setLegalEntity] = useState([])
    const [finaldata, Setfinaldata] = useState([])
    const [titledata, Settitledata] = useState()
    const [pagecount, setPagecount] = useState(false)
    const [show, Setshow] = useState(0)
    let [count, setCount] = useState(0)
    const [fcount, setfcount] = useState(0)
    const [tablecount, setTablecount] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArrayState, setfilteredArrayState] = useState([])
    const [current, setCurrent] = useState(1);
    const [pageState, setpageState] = useState(false);
    const [pageSize, setPageSize] = useState(25)
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [exitCollapse, setExitCollapse] = useState(false);
    const [unitlist, setUnitlist] = useState({
        country: "",
        country_id: '',
        legal_entity_id: "",
        legal_entity_name: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
        domain: "",
        division: "",
        category: "",
        unit: "",
        business_group_id: '',
        domain_id: null,
        division_id: null,
        category_id: null,
        unit_id: null,
        domain_org_id: '',
        domain_org_name: '',
        product_status: '',
        product_status_id: '',
        unit_status_id: '',
        unit_status: '',
        bg_id: '',
        bg_name: ''
    })

    const onChange = (key) => {
        console.log(key);
    };

    const onStatChange = (key) => {
        console.log(key);
    }
    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };
    const onClose = () => {
        setOpen(false);
    };
    console.log(filerunitdatas.unit_status_list, 'filerunitdatas.unit_status_list');

    useEffect(() => {
        if (finaldata && finaldata && finaldata.length > 0) {
            let uniqueObjArray = [...new Map(finaldata && finaldata.length && finaldata.map((item) => [item["category_name"], item])).values()];
            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.category_name
            })
            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(finaldata && finaldata && finaldata.length && finaldata, { category_name: uniqueIds[i] })
                tempArr.push(filteredArr)
            }
            setFilteredArray([...filteredArray, tempArr])
        }
    }, [finaldata])

    useEffect(() => {
        if (unitlist.legal_entity_name == localStorage.getItem('SelectedEntity')) {
            Setlegalstate(true)
        }
    }, [unitlist.legal_entity_name])

    useEffect(() => {
        if (legalEntity && legalEntity.length === 0) {
            setLegalEntity(sessionArr)
        }
    }, [legalEntity && legalEntity.length === 0])

    useEffect(() => {
        if (filteredArray && filteredArray.length > 0) {
            filteredArray && filteredArray.map((item, i) => {
                return (
                    setfilteredArrayState(item)
                )
            })
        }
    }, [filteredArray])

    useEffect(() => {
        let array = [];
        let finalarray = [];
        let datavalue = [];
        if (finaldata && finaldata.length > 0) {
            const ab = finaldata && finaldata.length && finaldata.map(function (item) {
                if ((array.indexOf(item.category_name) < 0))
                    if (item.category_name != '') {
                        array.push(item.category_name)
                    } else {
                        if (array.indexOf('-') < 0)
                            array.push('-')
                    }
            })
            array.map((data, i) => {
                let temp = [];
                let entityArray = [];
                let datavalue = [];
                if (data) {
                    finaldata && finaldata.length && finaldata.map((child, childIndex) => {
                        if (data == child.category_name) {
                            entityArray = {
                                "address": child.address,
                                "category_name": child.category_name,
                                "closed_date": child.closed_date,
                                "d_i_names": child.d_i_names,
                                "division_name": child.division_name,
                                "geography_name": child.geography_name,
                                "logo_url": child.logo_url,
                                "postal_code": child.postal_code,
                                "product_status": child.product_status,
                                "tz_name": child.tz_name,
                                "unit_code": child.unit_code,
                                "unit_id": child.unit_id,
                                "unit_name": child.unit_name,
                                "unit_status": child.unit_status,
                                'childId': childIndex,
                                'parentId': i
                            }
                            temp.push(entityArray)
                        } else if (data == '-') {
                            if (child.category_name == '') {
                                entityArray = {
                                    "address": child.address,
                                    "category_name": child.category_name,
                                    "closed_date": child.closed_date,
                                    "d_i_names": child.d_i_names,
                                    "division_name": child.division_name,
                                    "geography_name": child.geography_name,
                                    "logo_url": child.logo_url,
                                    "postal_code": child.postal_code,
                                    "product_status": child.product_status,
                                    "tz_name": child.tz_name,
                                    "unit_code": child.unit_code,
                                    "unit_id": child.unit_id,
                                    "unit_name": child.unit_name,
                                    "unit_status": child.unit_status,
                                    'childId': childIndex,
                                    'parentId': i
                                }
                                temp.push(entityArray)
                            }
                        }
                    })
                }
                datavalue = {
                    index: i,
                    parent: data,
                    child: temp
                }
                finalarray.push(datavalue)
            })
            Settitledata(finalarray)
        }
    }, [finaldata])

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    useEffect(() => {
        if (tabledatas) {
            setTableData(tabledatas && tabledatas.unit_list_report)
        }
    }, [tabledatas && tabledatas.unit_list_report])

    useEffect(() => {
        if (entityid != null) {
            setUnitlist({
                ...unitlist,
                country_id: sessionArr[0].c_id
            })
        }
    }, [entityid != null])

    useEffect(() => {
        if (tabledata && tabledata.length > 0) {
            let data = tabledata[0]
            if (data) {
                let data1 = Object.values(data)
                let data2 = Object.keys(data)
                let temp = []
                data1 && data1.length > 0 && data1.map((item) => {
                    temp.push(...item)
                })
                Setfinaldata(temp)
            }
        }
    }, [tabledata])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
            const country = sessionParsedValue.country_info
            setCountryData(country)
        }
    }, [localStorage.getItem('SelectedEntityid')])

    useEffect(() => {
        if (isAuth) {
            if (legalstate === true) {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            'GetUnitListReportFilters', {
                                'country_id': Number(unitlist.country_id),
                                'business_group_id': null,
                                'legal_entity_id': entityid
                            }
                        ]
                    }
                ]
                if (entityid != "null") {
                    UnitListFilters({
                        payload: payload,
                        paramid: paramid
                    })
                }
                Setlegalstate(false)
            }
        }
    }, [isAuth, entityid, legalstate])

    const onshow = () => {
        setfilteredArrayState([])
        setAddFormSubmit(true)
        setPagecount(false)
        setExitCollapse(true);
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
        if (validator.current.allValid()) {
            setTableShow(true)
            setKey(0)
            setStatKey(1)
            const payload1 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetUnitListReport",
                        {
                            "country_id": Number(unitlist.country_id),
                            "business_group_id": null,
                            "legal_entity_id": entityid,
                            "division_id": unitlist.division_id ? Number(unitlist.division_id) : 0,
                            "category_id": unitlist.category_id ? Number(unitlist.category_id) : 0,
                            "unit_id": unitlist.unit_id ? Number(unitlist.unit_id) : 0,
                            "domain_id": unitlist.domain_id ? Number(unitlist.domain_id) : 0,
                            "organisation_id": unitlist.domain_org_id ? Number(unitlist.domain_org_id) : 0,
                            "product_status": unitlist.product_status ? unitlist.product_status : "All",
                            "unit_status": unitlist.unit_status ? unitlist.unit_status : "All",
                            "csv": false,
                            "from_count": 0,
                            "page_count": 25,
                            "list_check": null
                        }
                    ]
                }
            ]
            UnitListshow({
                payload: payload1,
                paramid: paramid
            })
            Setshow(fcount + 1)
        }
    }

    useEffect(() => {
        if (pageState == true) {
            const payload1 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetUnitListReport",
                        {
                            "country_id": Number(unitlist.country_id),
                            "business_group_id": null,
                            "legal_entity_id": entityid,
                            "division_id": unitlist.division_id ? Number(unitlist.division_id) : 0,
                            "category_id": unitlist.category_id ? Number(unitlist.category_id) : 0,
                            "unit_id": unitlist.unit_id ? Number(unitlist.unit_id) : 0,
                            "domain_id": unitlist.domain_id ? Number(unitlist.domain_id) : 0,
                            "organisation_id": unitlist.domain_org_id ? Number(unitlist.domain_org_id) : 0,
                            "product_status": unitlist.product_status ? unitlist.product_status : "All",
                            "unit_status": unitlist.unit_status ? unitlist.unit_status : "All",
                            "csv": false,
                            "from_count": Math.max(((current - 1) * pageSize), 0) + 1,
                            "page_count": current != 0 ? current * pageSize : pageSize,
                            "list_check": null
                        }
                    ]
                }
            ]
            UnitListshow({
                payload: payload1,
                paramid: paramid
            })
        }
    }, [current, pageSize, pageState])

    useEffect(() => {
        if (pageState === true) {
            setCount(pageSize * (current - 1))
        }
    }, [pageState, pageSize, current])


    const showModal = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setIsModalOpen(true);
        }
    };

    const handleOk = () => {
        setIsModalOpen(true);
        if (validator.current.allValid()) {
            const payload2 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetUnitListReport",
                        {
                            "country_id": Number(unitlist.country_id),
                            "business_group_id": null,
                            "legal_entity_id": entityid,
                            "division_id": unitlist.division_id ? Number(unitlist.division_id) : 0,
                            "category_id": unitlist.category_id ? Number(unitlist.category_id) : 0,
                            "unit_id": unitlist.unit_id ? Number(unitlist.unit_id) : 0,
                            "domain_id": unitlist.domain_id ? Number(unitlist.domain_id) : 0,
                            "organisation_id": unitlist.domain_org_id ? Number(unitlist.domain_org_id) : 0,
                            "product_status": unitlist.product_status ? unitlist.product_status : "All",
                            "unit_status": unitlist.unit_status ? unitlist.unit_status : "All",
                            "csv": false,
                            "from_count": 0,
                            "page_count": 25,
                            "list_check": checkboxValue
                        }
                    ]
                }
            ]
            UnitListexport({
                payload: payload2,
                paramid: paramid
            })
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const checkBoxOnChange = (e) => {
        let tempIds = []
        let temp = checkboxValue
        if (temp.includes(Number(e.target.value)) == true) {
            for (let i in temp) {
                if (temp[i] == Number(e.target.value)) {
                    temp.splice(i, 1)
                }
            }
            setCheckBoxValue([...temp])
        } else {
            tempIds.push(Number(e.target.value))
            setCheckBoxValue([...checkboxValue, ...tempIds])
        }
    }

    const selectAllCheckBox = (checked) => {
        var ele = document.getElementsByName('chk');
        let tempAllIds = []
        if (checked == true) {
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox') {
                    ele[i].checked = true;
                    tempAllIds.push(Number(ele[i].value))
                }
            }
            setCheckBoxValue(tempAllIds)
        }
        else {
            var ele = document.getElementsByName('chk');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = false;
            }
            setCheckBoxValue([1, 2, 3, 4, 5, 6, 11])
        }
    }

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };

    const [fullscreen, setfullscreen] = useState(false);

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', handleKeyDown, false);
            document.addEventListener('webkitfullscreenchange', handleKeyDown, false);
            return () => {
                document.addEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('webkitfullscreenchange', handleKeyDown);
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        console.log(fullscreen, 'ddddddd');
        if (fullscreen == false) {
            setfullscreen(true);
            fullscreenmode();
        } else {
            setfullscreen(false);
            fullscreenexit();


        }
    }

    return (
        <>
            <div id='page-wrapper' className="page-wrapper">
                <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} />
                <div className="page-titles pb-0 pt-2">
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                    </li>
                                    <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                        <span style={{ fontSize: '16px' }}>Report</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        <span>Unit List</span>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-4 text-end">
                            <Button
                                className="mx-1"
                                type="primary"
                                shape="round"
                                style={{
                                    display: filterTaskAcc ? "none" : "initial",
                                }}
                                onClick={filterHandle}
                            >
                                <FilterOutlined /> Filter
                            </Button>
                            <button style={{ float: 'right', marginTop: '5px' }} onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                                <Tooltip placement="left" title="Full Screen Mode"><FullscreenOutlined /></Tooltip>
                            </button>
                        </div>
                    </div>
                </div>

                <Drawer title="Select Fields" className={'add-service-prv resize-drawer ' + localStorage.getItem('currentTheme')} visible={isModalOpen} footer={null} onCancel={handleCancel} onClose={handleCancel} width={600}>
                    <div className='d-flex flex-wrap'>
                        <input id="select_all" width="100%" type="checkbox" autocomplete="off"
                            checked={checkboxValue.length === 17 ? true : false}
                            onClick={(e) => {
                                let checked = e.target.checked
                                selectAllCheckBox(checked)
                            }} /><label for='select_all'>  Select All</label>
                        <input id="column_1" name='chk' type="checkbox" checked disabled="disabled" value='1' onChange={(e) => (e) => checkBoxOnChange(e)} /><label for='column_1'>  Business Group</label>
                        <input id="column_2" name='chk' type="checkbox" checked disabled="disabled" value='2' onChange={(e) => checkBoxOnChange(e)} /><label for='column_2'> Legal Entity</label>
                        <input id="column_6" name='chk' type="checkbox" value='3' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_6'>  Division</label>
                        <input id="column_7" name='chk' type="checkbox" value='4' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_7'>  Category</label>
                        <input id="column_8" name='chk' type="checkbox" value='5' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_8'>  Unit Code</label>
                        <input id="column_9" name='chk' type="checkbox" value='6' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_9'>  Unit Name</label>
                        <input id="column_10" name='chk' type="checkbox" value='7' onChange={(e) => checkBoxOnChange(e)} /><label for='column_10'> Unit Code 1</label>
                        <input id="column_29" name='chk' type="checkbox" value='8' onChange={(e) => checkBoxOnChange(e)} /><label for='column_29'>  Unit Code 2</label>

                        <input id="column_32" name='chk' type="checkbox" value='9' onChange={(e) => checkBoxOnChange(e)} /><label for='column_32'>  Unit Code 3</label>
                        <input id="column_3" name='chk' type="checkbox" value='10' onChange={(e) => checkBoxOnChange(e)} /><label for='column_3'>  Unit Code 4</label>
                        <input id="column_4" name='chk' type="checkbox" value='11' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_4'>  Location</label>
                        <input id="column_5" name='chk' type="checkbox" value='12' onChange={(e) => checkBoxOnChange(e)} /><label for='column_5'> Domain</label>

                        <input id="column_14" name='chk' type="checkbox" value='13' onChange={(e) => checkBoxOnChange(e)} /><label for='column_14'>  Organization Type</label>
                        <input id="column_15" name='chk' type="checkbox" value='14' onChange={(e) => checkBoxOnChange(e)} /><label for='column_15'>  Postal Code</label>
                        <input id="column_16" name='chk' type="checkbox" value='15' onChange={(e) => checkBoxOnChange(e)} /><label for='column_16'>  Product</label>
                        <input id="column_17" name='chk' type="checkbox" value='16' onChange={(e) => checkBoxOnChange(e)} /><label for='column_17'>  Status</label>
                        <input id="column_18" name='chk' type="checkbox" value='17' onChange={(e) => checkBoxOnChange(e)} /><label for='column_18'>  Date </label>

                    </div>

                    <div className="form-actions popupbtncolour text-center">
                        <Button type="primary" shape="round" className={'addbutton mt-3 ' + localStorage.getItem('currentTheme')}
                            icon={<PlayCircleOutlined />} size='default' onClick={handleOk}> Submit
                        </Button>
                    </div>


                </Drawer>
                <div className="container-fluid pt-1">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-body" id='only-restricted' style={{ position: 'relative', padding: "10px 3px" }}>
                                <div className={localStorage.getItem('currentTheme')}>
                                    <Collapse style={{ display: exitCollapse ? "none" : "block" }} defaultActiveKey={["1"]} className='report-collapse' onChange={onChange}>
                                        <Panel header="Unit List" key={key} extra={tableshow == true ? '' : false}>
                                            <div className='col-md-12' >
                                                <div className='row'  >
                                                    <div className='col-md-4'>
                                                        <label><b>Country:</b> <span style={{ color: "red" }}>*</span></label>
                                                        <div className="form-group">
                                                            {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                                <div className="form-group">
                                                                    <Select
                                                                        placeholder='Enter Country'
                                                                        allowClear={true}
                                                                        size="default"
                                                                        onChange={(value, data) => {
                                                                            setUnitlist({
                                                                                ...unitlist,
                                                                                country_id: value,
                                                                                country: data.children,
                                                                                legal_entity_id: "",
                                                                                legal_entity_name: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
                                                                                domain: "",
                                                                                division: "",
                                                                                category: "",
                                                                                unit: "",
                                                                                business_group_id: '',
                                                                                domain_id: null,
                                                                                division_id: null,
                                                                                category_id: null,
                                                                                unit_id: null,
                                                                                domain_org_id: '',
                                                                                domain_org_name: '',
                                                                                product_status: '',
                                                                                product_status_id: '',
                                                                                unit_status_id: '',
                                                                                unit_status: '',
                                                                                bg_id: '',
                                                                                bg_name: ''
                                                                            })
                                                                        }}
                                                                        showSearch
                                                                        optionFilterProp="children"
                                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                        style={{ width: '100%', marginTop: '3px' }}
                                                                        value={unitlist.country || undefined}
                                                                    >
                                                                        {country_info && country_info.length && country_info.map((item, i) => {
                                                                            return (
                                                                                <Option key={item.c_id}>
                                                                                    {item.c_name}
                                                                                </Option>
                                                                            );
                                                                        })}
                                                                    </Select>
                                                                    {validator.current.message(
                                                                        'country',
                                                                        unitlist.country,
                                                                        'required',
                                                                        {
                                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                            messages: {
                                                                                required: 'Country Required',
                                                                            }
                                                                        })}
                                                                </div> :
                                                                <p>{sessionArr[0].c_name}</p>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Legal Entity: </b><span style={{ color: "red" }}>*</span></label>
                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                            <div className="form-group">
                                                                <Select
                                                                    allowClear={true}
                                                                    size="default"
                                                                    placeholder="Enter Legal Entity"
                                                                    onChange={(data, value) => {
                                                                        Setlegalstate(true)
                                                                        setCurrentEntity(data)
                                                                        setUnitlist({
                                                                            ...unitlist,
                                                                            legal_entity_id: data,
                                                                            legal_entity_name: value.children,
                                                                            domain: "",
                                                                            division: "",
                                                                            category: "",
                                                                            unit: "",
                                                                            business_group_id: '',
                                                                            domain_id: null,
                                                                            division_id: null,
                                                                            category_id: null,
                                                                            unit_id: null,
                                                                            domain_org_id: '',
                                                                            domain_org_name: '',
                                                                            product_status: '',
                                                                            product_status_id: '',
                                                                            unit_status_id: '',
                                                                            unit_status: '',
                                                                            bg_id: '',
                                                                            bg_name: ''
                                                                        })
                                                                    }}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    style={{ width: '100%', marginTop: '3px' }}

                                                                    value={unitlist.legal_entity_name || undefined}
                                                                    disabled={unitlist.country == '' ? true : false}
                                                                >
                                                                    {legalEntity && legalEntity.length && legalEntity.map((item, i) => {
                                                                        return (
                                                                            <Option key={item.le_id}>
                                                                                {item.le_name}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                                {validator.current.message(
                                                                    'legal_entity_id',
                                                                    unitlist.legal_entity_id,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Legal Entity Required',
                                                                        }
                                                                    })}
                                                            </div> :
                                                            <p>{localStorage.getItem('SelectedEntity')}</p>
                                                        }
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Division:</b></label>
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Division"
                                                            disabled={unitlist.legal_entity_name == '' ? true : false}
                                                            onChange={(data, value) => {

                                                                setUnitlist({
                                                                    ...unitlist,
                                                                    division_id: data,
                                                                    division: value.children

                                                                })
                                                            }}

                                                            style={{ width: '100%', marginTop: '3px' }}
                                                        >

                                                            {filerunitdatas.divisions && filerunitdatas.divisions.length > 0 &&
                                                                filerunitdatas.divisions.map((item) => (
                                                                    <Option key={item.division_id}>{item.division_name}</Option>
                                                                ))}

                                                        </Select>


                                                    </div>

                                                </div>
                                            </div>
                                            <div className='col-md-12' style={{ marginTop: "5px" }}>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <label><b>Business Group: </b>  </label>
                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                            <div className="form-group">
                                                                <Select
                                                                    allowClear={true}
                                                                    size="default"
                                                                    placeholder="Entry Business Group"
                                                                    onChange={(data, value) => {

                                                                        setUnitlist({
                                                                            ...unitlist,
                                                                            bg_id: data,
                                                                            bg_name: value.children
                                                                        })
                                                                        let filteredLegalEntity = _.filter(legalEntity, { bg_id: Number(data) })
                                                                        setLegalEntity(filteredLegalEntity)
                                                                    }}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    //  value={userDetails.legal_entity_id}
                                                                    style={{ width: '100%', marginTop: '3px' }}
                                                                >
                                                                    {sessionArr && sessionArr.length && sessionArr.map((item, i) => {

                                                                        if (item.bg_name !== null) {
                                                                            return (
                                                                                <Option key={item.bg_id}>
                                                                                    {item.bg_name}
                                                                                </Option>
                                                                            );
                                                                        }
                                                                    })}
                                                                </Select>

                                                            </div> :
                                                            <p> - </p>
                                                        }

                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Domain:</b> </label>
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Domain"
                                                            value={unitlist.domain || undefined}
                                                            disabled={unitlist.legal_entity_name == '' ? true : false}
                                                            style={{ width: '100%', marginTop: '3px' }}

                                                            onChange={(data, value) => {
                                                                setUnitlist({
                                                                    ...unitlist,
                                                                    domain_id: data,
                                                                    domain: value.children,
                                                                    division: "",
                                                                    category: "",
                                                                    unit: "",
                                                                    business_group_id: '',
                                                                    division_id: null,
                                                                    category_id: null,
                                                                    unit_id: null,
                                                                    domain_org_id: '',
                                                                    domain_org_name: '',
                                                                    product_status: '',
                                                                    product_status_id: '',
                                                                    unit_status_id: '',
                                                                    unit_status: '',
                                                                    bg_id: '',
                                                                    bg_name: ''
                                                                })
                                                            }}>

                                                            {filerunitdatas.domains_organisations_list && filerunitdatas.domains_organisations_list.length > 0 &&
                                                                filerunitdatas.domains_organisations_list.map((item) => (

                                                                    <Option key={item.domain_id}>{item.domain_name}</Option>
                                                                ))}
                                                        </Select>

                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Category:</b></label>
                                                        <Select
                                                            onChange={(data, value) => {

                                                                setUnitlist({
                                                                    ...unitlist,
                                                                    category_id: data,
                                                                    category: value.children
                                                                })
                                                            }}
                                                            placeholder="Enter Category"
                                                            disabled={unitlist.legal_entity_name == '' ? true : false}
                                                            style={{ width: '100%', marginTop: '3px' }}


                                                        >

                                                            {filerunitdatas.categories && filerunitdatas.categories.length > 0 &&
                                                                filerunitdatas.categories.map((item) => (

                                                                    <Option key={item.category_id}>{item.category_name}</Option>
                                                                ))}                                                       </Select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-12' style={{ marginTop: "5px" }}>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <label><b>Organization Type:</b></label>
                                                        <Select
                                                            onChange={(data, value) => {

                                                                setUnitlist({
                                                                    ...unitlist,
                                                                    domain_org_id: data,
                                                                    domain_org_name: value.children
                                                                })
                                                            }}
                                                            placeholder="Enter Organization"
                                                            style={{ width: '100%', marginTop: '3px' }}

                                                            value={unitlist.domain_org_name || undefined}

                                                        >
                                                            {filerunitdatas && filerunitdatas.domains_organisations_list && filerunitdatas.domains_organisations_list.length > 0 &&
                                                                filerunitdatas.domains_organisations_list.map((item) => {

                                                                    return <Option key={item.organisation_id}>{item.organisation_name}</Option>
                                                                })}
                                                        </Select>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Unit: </b></label>
                                                        <Select
                                                            placeholder='Enter Unit'
                                                            disabled={unitlist.legal_entity_name == '' ? true : false}
                                                            onChange={(data, value) => {
                                                                setUnitlist({
                                                                    ...unitlist,
                                                                    unit_id: data,
                                                                    unit: value.children
                                                                })
                                                            }}
                                                            style={{ width: '100%', marginTop: '3px' }}

                                                            value={unitlist.unit || undefined}
                                                        >

                                                            {filerunitdatas && filerunitdatas.units_list && filerunitdatas.units_list.length > 0 &&
                                                                filerunitdatas.units_list.map((item) => {
                                                                    return <Option key={item.unit_id}>{`${item.unit_code}-${item.unit_name}`}</Option>
                                                                })}
                                                        </Select>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Product:</b></label>
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Product"
                                                            defaultValue="All"

                                                            onChange={(data, value) => {
                                                                setUnitlist({
                                                                    ...unitlist,
                                                                    product_status_id: data,
                                                                    product_status: value.children
                                                                })
                                                            }}

                                                            style={{ width: '100%', marginTop: '3px' }}



                                                        >
                                                            <Option key="All">All</Option>
                                                            {filerunitdatas && filerunitdatas.product_status_list && filerunitdatas.product_status_list.length > 0 &&
                                                                filerunitdatas.product_status_list.map((item) => {

                                                                    return <Option key={item.product_status_id}>{item.product_status}</Option>
                                                                })}

                                                        </Select>

                                                    </div>

                                                </div>
                                            </div>
                                            <div className='col-md-12' style={{ marginTop: "5px" }}>
                                                <div className='row'>
                                                    <div className='col-md-4'>

                                                        <label><b>Status:</b> </label>
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Status"
                                                            defaultValue="All"
                                                            onChange={(data, value) => {
                                                                setUnitlist({
                                                                    ...unitlist,
                                                                    unit_status_id: data,
                                                                    unit_status: value.children
                                                                })
                                                            }}

                                                            style={{ width: '100%', marginTop: '3px' }}


                                                        >
                                                            <Option key="All">All</Option>
                                                            {filerunitdatas && filerunitdatas.unit_status_list && filerunitdatas.unit_status_list.length > 0 &&
                                                                filerunitdatas.unit_status_list.map((item) => {

                                                                    return <Option key={item.unit_status_id}>{item.unit_status}</Option>
                                                                })}

                                                        </Select>


                                                        <div className='col-md-4'></div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='col-md-12' style={{ marginTop: "20px" }}>
                                                <div className='row'>
                                                    <div className='col-md-4'></div>
                                                    <div className='col-md-4 popupbtncolour'>
                                                        <Button type="primary" icon={<EyeOutlined />} shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ marginLeft: "23%", marginRight: "10px" }} onClick={onshow}
                                                        >
                                                            Show
                                                        </Button>
                                                        <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} icon={<ExportOutlined />} style={{ background: "#32a852", borderColor: "#32a852" }} onClick={showModal}
                                                        >
                                                            Export
                                                        </Button>

                                                    </div>
                                                    <div className='col-md-4'></div>
                                                </div>
                                            </div>

                                        </Panel>
                                    </Collapse>
                                    <Modal
                                        onCancel={setcancelFilter}
                                        footer={false}
                                        title="Unit List"
                                        className={
                                            "add-service-prv cons-report " +
                                            localStorage.getItem("currentTheme")
                                        }
                                        visible={filterModalVisible}
                                    >
                                        <div className='col-md-12' >
                                            <div className='row'  >
                                                <div className='col-md-4'>
                                                    <label><b>Country:</b> <span style={{ color: "red" }}>*</span></label>
                                                    <div className="form-group">
                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                            <div className="form-group">
                                                                <Select
                                                                    placeholder='Enter Country'
                                                                    allowClear={true}
                                                                    size="default"
                                                                    onChange={(value, data) => {
                                                                        setUnitlist({
                                                                            ...unitlist,
                                                                            country_id: value,
                                                                            country: data.children,
                                                                            legal_entity_id: "",
                                                                            legal_entity_name: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
                                                                            domain: "",
                                                                            division: "",
                                                                            category: "",
                                                                            unit: "",
                                                                            business_group_id: '',
                                                                            domain_id: null,
                                                                            division_id: null,
                                                                            category_id: null,
                                                                            unit_id: null,
                                                                            domain_org_id: '',
                                                                            domain_org_name: '',
                                                                            product_status: '',
                                                                            product_status_id: '',
                                                                            unit_status_id: '',
                                                                            unit_status: '',
                                                                            bg_id: '',
                                                                            bg_name: ''
                                                                        })
                                                                    }}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    style={{ width: '100%', marginTop: '3px' }}
                                                                    value={unitlist.country || undefined}
                                                                >
                                                                    {country_info && country_info.length && country_info.map((item, i) => {
                                                                        return (
                                                                            <Option key={item.c_id}>
                                                                                {item.c_name}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                                {validator.current.message(
                                                                    'country',
                                                                    unitlist.country,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Country Required',
                                                                        }
                                                                    })}
                                                            </div> :
                                                            <p>{sessionArr[0].c_name}</p>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Legal Entity: </b><span style={{ color: "red" }}>*</span></label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Enter Legal Entity"
                                                                onChange={(data, value) => {
                                                                    Setlegalstate(true)
                                                                    setCurrentEntity(data)
                                                                    setUnitlist({
                                                                        ...unitlist,
                                                                        legal_entity_id: data,
                                                                        legal_entity_name: value.children,
                                                                        domain: "",
                                                                        division: "",
                                                                        category: "",
                                                                        unit: "",
                                                                        business_group_id: '',
                                                                        domain_id: null,
                                                                        division_id: null,
                                                                        category_id: null,
                                                                        unit_id: null,
                                                                        domain_org_id: '',
                                                                        domain_org_name: '',
                                                                        product_status: '',
                                                                        product_status_id: '',
                                                                        unit_status_id: '',
                                                                        unit_status: '',
                                                                        bg_id: '',
                                                                        bg_name: ''
                                                                    })
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                style={{ width: '100%', marginTop: '3px' }}

                                                                value={unitlist.legal_entity_name || undefined}
                                                                disabled={unitlist.country == '' ? true : false}
                                                            >
                                                                {legalEntity && legalEntity.length && legalEntity.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.le_id}>
                                                                            {item.le_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'legal_entity_id',
                                                                unitlist.legal_entity_id,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Legal Entity Required',
                                                                    }
                                                                })}
                                                        </div> :
                                                        <p>{localStorage.getItem('SelectedEntity')}</p>
                                                    }
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Division:</b></label>
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Division"
                                                        disabled={unitlist.legal_entity_name == '' ? true : false}
                                                        onChange={(data, value) => {

                                                            setUnitlist({
                                                                ...unitlist,
                                                                division_id: data,
                                                                division: value.children

                                                            })
                                                        }}

                                                        style={{ width: '100%', marginTop: '3px' }}
                                                    >

                                                        {filerunitdatas.divisions && filerunitdatas.divisions.length > 0 &&
                                                            filerunitdatas.divisions.map((item) => (
                                                                <Option key={item.division_id}>{item.division_name}</Option>
                                                            ))}

                                                    </Select>


                                                </div>

                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "5px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Business Group: </b>  </label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Entry Business Group"
                                                                onChange={(data, value) => {

                                                                    setUnitlist({
                                                                        ...unitlist,
                                                                        bg_id: data,
                                                                        bg_name: value.children
                                                                    })
                                                                    let filteredLegalEntity = _.filter(legalEntity, { bg_id: Number(data) })
                                                                    setLegalEntity(filteredLegalEntity)
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                //  value={userDetails.legal_entity_id}
                                                                style={{ width: '100%', marginTop: '3px' }}
                                                            >
                                                                {sessionArr && sessionArr.length && sessionArr.map((item, i) => {

                                                                    if (item.bg_name !== null) {
                                                                        return (
                                                                            <Option key={item.bg_id}>
                                                                                {item.bg_name}
                                                                            </Option>
                                                                        );
                                                                    }
                                                                })}
                                                            </Select>

                                                        </div> :
                                                        <p> - </p>
                                                    }

                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Domain:</b> </label>
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Domain"
                                                        value={unitlist.domain || undefined}
                                                        disabled={unitlist.legal_entity_name == '' ? true : false}
                                                        style={{ width: '100%', marginTop: '3px' }}

                                                        onChange={(data, value) => {
                                                            setUnitlist({
                                                                ...unitlist,
                                                                domain_id: data,
                                                                domain: value.children,
                                                                division: "",
                                                                category: "",
                                                                unit: "",
                                                                business_group_id: '',
                                                                division_id: null,
                                                                category_id: null,
                                                                unit_id: null,
                                                                domain_org_id: '',
                                                                domain_org_name: '',
                                                                product_status: '',
                                                                product_status_id: '',
                                                                unit_status_id: '',
                                                                unit_status: '',
                                                                bg_id: '',
                                                                bg_name: ''
                                                            })
                                                        }}>

                                                        {filerunitdatas.domains_organisations_list && filerunitdatas.domains_organisations_list.length > 0 &&
                                                            filerunitdatas.domains_organisations_list.map((item) => (

                                                                <Option key={item.domain_id}>{item.domain_name}</Option>
                                                            ))}
                                                    </Select>

                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Category:</b></label>
                                                    <Select
                                                        onChange={(data, value) => {

                                                            setUnitlist({
                                                                ...unitlist,
                                                                category_id: data,
                                                                category: value.children
                                                            })
                                                        }}
                                                        placeholder="Enter Category"
                                                        disabled={unitlist.legal_entity_name == '' ? true : false}
                                                        style={{ width: '100%', marginTop: '3px' }}


                                                    >

                                                        {filerunitdatas.categories && filerunitdatas.categories.length > 0 &&
                                                            filerunitdatas.categories.map((item) => (

                                                                <Option key={item.category_id}>{item.category_name}</Option>
                                                            ))}                                                       </Select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "5px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Organization Type:</b></label>
                                                    <Select
                                                        onChange={(data, value) => {

                                                            setUnitlist({
                                                                ...unitlist,
                                                                domain_org_id: data,
                                                                domain_org_name: value.children
                                                            })
                                                        }}
                                                        placeholder="Enter Organization"
                                                        style={{ width: '100%', marginTop: '3px' }}

                                                        value={unitlist.domain_org_name || undefined}

                                                    >
                                                        {filerunitdatas && filerunitdatas.domains_organisations_list && filerunitdatas.domains_organisations_list.length > 0 &&
                                                            filerunitdatas.domains_organisations_list.map((item) => {

                                                                return <Option key={item.organisation_id}>{item.organisation_name}</Option>
                                                            })}
                                                    </Select>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Unit: </b></label>
                                                    <Select
                                                        placeholder='Enter Unit'
                                                        disabled={unitlist.legal_entity_name == '' ? true : false}
                                                        onChange={(data, value) => {
                                                            setUnitlist({
                                                                ...unitlist,
                                                                unit_id: data,
                                                                unit: value.children
                                                            })
                                                        }}
                                                        style={{ width: '100%', marginTop: '3px' }}

                                                        value={unitlist.unit || undefined}
                                                    >

                                                        {filerunitdatas && filerunitdatas.units_list && filerunitdatas.units_list.length > 0 &&
                                                            filerunitdatas.units_list.map((item) => {
                                                                return <Option key={item.unit_id}>{`${item.unit_code}-${item.unit_name}`}</Option>
                                                            })}
                                                    </Select>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Product:</b></label>
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Product"
                                                        defaultValue="All"

                                                        onChange={(data, value) => {
                                                            setUnitlist({
                                                                ...unitlist,
                                                                product_status_id: data,
                                                                product_status: value.children
                                                            })
                                                        }}

                                                        style={{ width: '100%', marginTop: '3px' }}



                                                    >
                                                        <Option key="All">All</Option>
                                                        {filerunitdatas && filerunitdatas.product_status_list && filerunitdatas.product_status_list.length > 0 &&
                                                            filerunitdatas.product_status_list.map((item) => {

                                                                return <Option key={item.product_status_id}>{item.product_status}</Option>
                                                            })}

                                                    </Select>

                                                </div>

                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "5px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'>

                                                    <label><b>Status:</b> </label>
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Status"
                                                        defaultValue="All"
                                                        onChange={(data, value) => {
                                                            setUnitlist({
                                                                ...unitlist,
                                                                unit_status_id: data,
                                                                unit_status: value.children
                                                            })
                                                        }}

                                                        style={{ width: '100%', marginTop: '3px' }}


                                                    >
                                                        <Option key="All">All</Option>
                                                        {filerunitdatas && filerunitdatas.unit_status_list && filerunitdatas.unit_status_list.length > 0 &&
                                                            filerunitdatas.unit_status_list.map((item) => {

                                                                return <Option key={item.unit_status_id}>{item.unit_status}</Option>
                                                            })}

                                                    </Select>


                                                    <div className='col-md-4'></div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "20px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'></div>
                                                <div className='col-md-4 popupbtncolour'>
                                                    <Button type="primary" icon={<EyeOutlined />} shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ marginLeft: "23%", marginRight: "10px" }} onClick={onshow}
                                                    >
                                                        Show
                                                    </Button>
                                                    <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} icon={<ExportOutlined />} style={{ background: "#32a852", borderColor: "#32a852" }} onClick={showModal}
                                                    >
                                                        Export
                                                    </Button>

                                                </div>
                                                <div className='col-md-4'></div>
                                            </div>
                                        </div>

                                    </Modal>

                                </div>
                                {/* <div className={localStorage.getItem('currentTheme')}>
                                    {tableshow == true ?
                                        <Collapse defaultActiveKey={["1"]} className='report-collapse' onChange={onStatChange} style={{ marginTop: '1%', backgroundColor: '#5daae3' }}>
                                            <Panel header="Overall Statistics" key={statkey}>
                                                <div className="col-md-12" style={{ marginLeft: '5%', marginRight: '5%' }}>
                                                    <div className="row m-2">
                                                        <div className="col-md-4">
                                                            <Progress type="circle" percent={100} width={90} format={(percent) => `${210}`} />
                                                            <h4 style={{ marginLeft: '5%' }}>Active</h4>

                                                        </div>
                                                        <div className="col-md-4">
                                                            <Progress type="circle" percent={10} width={90} status="exception" format={(percent) => `${90}`} />
                                                            <h4 style={{ marginLeft: '5%' }}>Inactive</h4>

                                                        </div>
                                                        <div className="col-md-4">
                                                            <Progress type="circle" percent={70} width={90} strokeColor={{ '0%': '#f5b342', '100%': '#f5b342' }} format={(percent) => `${175}`} />
                                                            <h4 style={{ marginLeft: '5%' }}>Closed</h4>

                                                        </div>
                                                    </div>
                                                </div>
                                            </Panel>
                                        </Collapse> : ''}
                                </div> */}
                                {tableshow == true ?
                                    <Card className='rounded-3'>
                                        <div className='col-md-12 mt-1 mb-1' >
                                            <div className='row'>
                                                <div className='col-md-5'>
                                                    <label><b>Legal Entity :</b> {unitlist.legal_entity_name}</label>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label><b>Country :</b> {unitlist.country ? unitlist.country : sessionArr[0].c_name}</label>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Domain :</b> {unitlist.domain}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='ant-table-wrapper'>
                                            <div className={localStorage.getItem("currentTheme") + ' ant-table-wrapper'}>
                                                <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                                    <div className='ant-table-container'>
                                                        <div className='ant-table-content userprivclass1'>
                                                            <table>
                                                                <colgroup>
                                                                    <col style={{ width: '10px' }}></col>
                                                                </colgroup>
                                                                <thead className="ant-table-thead antable-head">
                                                                    <tr>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '10px' }}> # </th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '80px' }}>Unit Code</th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '120px' }}>Unit Name</th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '100px' }}>Time Zone</th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '220px' }}><center>Domain-Organization Type</center></th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '120px' }}>Location</th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '120px' }}><center>Address</center></th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '120px' }}><center>Product</center></th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '120px' }}><center>Status</center></th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '120px' }}><center>Date</center></th>

                                                                    </tr>
                                                                </thead>
                                                                {filteredArrayState && filteredArrayState.length ? filteredArrayState.map((item, i) => {

                                                                    return (
                                                                        <tbody className='ant-table-tbody' key={i}>

                                                                            {titledata && titledata.map((itemdata, i) => {

                                                                                let data = _.find(itemdata.child, { category_name: item[0].category_name })

                                                                                if (data != undefined) {

                                                                                    if (data.category_name == itemdata.parent) {
                                                                                        return <Fragment key={i}>
                                                                                            <tr className='ant-table-row ant-table-row-level-0'>
                                                                                                <td colSpan={6} className='ant-table-cell' ><b style={{ backgroundColor: "lightyellow" }}>{"Division:" + itemdata.child[0].division_name}</b></td>
                                                                                                <td colSpan={6} className='ant-table-cell' ><b style={{ backgroundColor: "lightyellow" }}>{"Category:" + itemdata.parent}</b></td>
                                                                                            </tr>
                                                                                            {itemdata.child.map((items, itemskj) => {

                                                                                                if (item[0].category_name == items.category_name) {

                                                                                                    return <tr key={itemskj}>
                                                                                                        <td className='ant-table-cell'><center>{count + 1}</center></td>
                                                                                                        <td className='ant-table-cell'>{items.unit_code}</td>
                                                                                                        <td className='ant-table-cell'>{items.unit_name}</td>
                                                                                                        <td className='ant-table-cell'>{items.tz_name}</td>
                                                                                                        <td className='ant-table-cell'>{items.d_i_names}</td>
                                                                                                        <td className='ant-table-cell'>{items.geography_name}</td>
                                                                                                        <td className='ant-table-cell'>{items.address}{items.postal_code ? "," + items.postal_code : ''}</td>
                                                                                                        <td className='ant-table-cell'>{items.product_status}</td>
                                                                                                        <td className='ant-table-cell'>{items.unit_status}</td>
                                                                                                        <td className='ant-table-cell'><center>{items.closed_date}</center></td>
                                                                                                        <span hidden>{count = count + 1}</span>
                                                                                                    </tr>
                                                                                                }
                                                                                            })
                                                                                            }
                                                                                        </Fragment>
                                                                                    }
                                                                                    else if (itemdata.parent == '-') {

                                                                                        if (data.category_name == '') {
                                                                                            return <Fragment key={i}>
                                                                                                <tr className='ant-table-row ant-table-row-level-0'>
                                                                                                    <td colSpan={6} className='ant-table-cell'><b style={{ backgroundColor: "lightyellow" }}>{"Division:" + itemdata.child[0].division_name}</b></td>
                                                                                                    <td colSpan={6} className='ant-table-cell'><b style={{ backgroundColor: "lightyellow" }}>{"Category:" + itemdata.parent}</b></td>
                                                                                                </tr>
                                                                                                {itemdata.child.map((items, ite) => {

                                                                                                    console.log(items, 'items');

                                                                                                    if (item[0].category_name == items.category_name) {
                                                                                                        return <tr key={ite}>
                                                                                                            <td className='ant-table-cell' style={{ width: '10px' }}><center>{count + 1}</center></td>
                                                                                                            <td className='ant-table-cell' style={{ width: '120px' }}><center>{items.unit_code}</center></td>
                                                                                                            <td className='ant-table-cell' style={{ width: '120px' }}><center>{items.unit_name}</center></td>
                                                                                                            <td className='ant-table-cell' style={{ width: '120px' }}><center>{items.tz_name}</center></td>
                                                                                                            <td className='ant-table-cell' style={{ width: '200px' }}>{items.d_i_names}</td>
                                                                                                            <td className='ant-table-cell' style={{ width: '120px' }}><center>{items.geography_name}</center></td>
                                                                                                            <td className='ant-table-cell' style={{ width: '120px' }}>{items.address}{items.postal_code ? "," + items.postal_code : ''}</td>
                                                                                                            <td className='ant-table-cell' style={{ width: '120px' }}>{items.product_status}</td>
                                                                                                            <td className='ant-table-cell' style={{ width: '120px' }}><center>{items.unit_status}</center></td>
                                                                                                            <td className='ant-table-cell'><center>{items.closed_date}</center></td>

                                                                                                            <span hidden>{count = count + 1}</span>
                                                                                                        </tr>
                                                                                                    }
                                                                                                })
                                                                                                }
                                                                                            </Fragment>
                                                                                        }
                                                                                    }
                                                                                }
                                                                            })
                                                                            }
                                                                        </tbody>
                                                                    )
                                                                }) :
                                                                    <tr>
                                                                        <td colSpan="100%" style={{ paddingTop: '15px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>
                                                                    </tr>
                                                                }
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            {count != 0 ?
                                                <div className='col-md-6'>
                                                    <label>Showing {pageSize * (current - 1) + 1} to {count} of {tabledatas && tabledatas.total_count} entries</label>
                                                </div> : ''}
                                            <div className='col-md-6 text-right'>

                                                <Pagination
                                                    current={current}
                                                    showSizeChanger={true}
                                                    onShowSizeChange={() => {
                                                        setCurrent(1)

                                                    }}
                                                    pageSizeOptions={[25, 50, 100]} hideOnSinglePage={tabledatas && tabledatas.total_count > 25 ? false : true} defaultPageSize={25}
                                                    onChange={(page, pageSizes) => {
                                                        setpageState(true)
                                                        setCurrent(pageSize !== pageSizes ? 1 : page);
                                                        setPageSize(pageSizes)
                                                    }} total={tabledatas.total_count}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                    : ''
                                    //  <h4 style={{ textAlign: 'center', marginTop: '5%' }}>No Records Found</h4>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default connect(mapStateToProps, {
    UnitListFilters,
    UnitListshow,
    UnitListexport

})(UnitlistReport);