import React, { Fragment } from 'react'
import { Collapse, Select, Input, DatePicker, Card, Modal, Table, Button, Tooltip, Pagination } from 'antd';
import { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { connect } from 'react-redux';
import moment from 'moment';
import { getApproveComplianceCertificateFilter, RemarksData, approveCertificate, certificateMailData, viewApproveComplianceDetails, AddAdditionalInformation, getApproveComplianceCertificateTable, viewApproveComplianceStatus, ApprovemappingDetails } from '../../Store/Action/Transcations/CV_ApproveCompliance';
import { getComplianceCertificateFilter, getComplianceCertificateTable, searchCompliance, submitComplianceCertificate, UploadFile, Downloadfile, mappingDetails, getLabelList, viewComplianceStatus, viewComplianceDetails } from '../../Store/Action/Transcations/CV-ComplianceCertificate';
// import { MonthlyComplianceFilterReport, MonthlyComplianceTable, MonthlyComplianceExport } from '../../../src/Store/Action/Report/CV_MonthlyComplianceReport';
import { toast } from 'react-toastify';
import ScrollToTop from "react-scroll-to-top";
import Swal from 'sweetalert2';
import { ExclamationCircleTwoTone, ExclamationCircleOutlined, FullscreenOutlined, PlayCircleOutlined, ArrowLeftOutlined, UpOutlined, PlusCircleOutlined, PlusOutlined, DownloadOutlined, CloseOutlined, SearchOutlined, EyeOutlined } from '@ant-design/icons';
import { Toaster } from '../../Libs/Toaster';
import EncryptDecrypt from '../../Libs/Encrypt&Decrypt'
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
const mapStateToProps = (state) => ({
    filtervalues: state.CV_ApproveCompliance.approvecomplianceCertificate.FilterData,
    tabledatas: state.CV_ApproveCompliance.approvecomplianceCertificate.tabledata,
    complianceStatusModals: state.CV_ApproveCompliance.approvecomplianceCertificate.complianceStatusModal,
    detailsModals: state.CV_ApproveCompliance.approvecomplianceCertificate.detailsModal,
    MappingDetail: state.CV_ComplianceCertificate.complianceCertificate.MappingDetails,
    labelLists: state.CV_ComplianceCertificate.complianceCertificate.labelList,
    searchDatas: state.CV_ComplianceCertificate.complianceCertificate.searchData,
    downloadFile: state.CV_ComplianceCertificate.complianceCertificate.downloadFile,
    mailData: state.CV_ApproveCompliance.approvecomplianceCertificate.mailDatas,
    Remarks: state.CV_ApproveCompliance.approvecomplianceCertificate.RemarksDatas,

})

const CV_ApproveCompliances = ({
    getApproveComplianceCertificateFilter,
    MonthlyComplianceFilterReport,
    AddAdditionalInformation,
    approveCertificate,
    UploadFile,
    Remarks,
    mailData,
    viewApproveComplianceDetails,
    filtervalues,
    certificateMailData,
    Downloadfile,
    MonthlyComplianceTable,
    RemarksData,
    MonthlyComplianceExport,
    complianceStatusModals,
    getApproveComplianceCertificateTable,
    viewApproveComplianceStatus,
    getComplianceCertificateTable,
    submitComplianceCertificate,
    getComplianceCertificateFilter,
    viewComplianceStatus,
    searchDatas,
    detailsModals,
    downloadFile,
    searchCompliance,
    labelLists,
    filerunitdatas,
    MappingDetail,
    viewComplianceDetails,
    ApprovemappingDetails,
    mappingDetails,
    getLabelList,
    tabledatas
    // tabledatas


}) => {
    console.log(mailData, 'mailData');
    console.log(Remarks, 'Remarks');
    console.log(filtervalues, 'filtervalues');
    console.log(downloadFile, 'downloadFile');
    console.log(labelLists, 'labelLists');
    console.log(MappingDetail, 'MappingDetail');
    console.log(detailsModals, 'detailsModals');
    console.log(complianceStatusModals, 'complianceStatusModal');
    console.log(tabledatas, "tabledatastabledatas");
    const [labelPayload, setLabelPayload] = useState({})
    const [fullscreen, setfullscreen] = useState(false);
    const _ = require("lodash");
    const [domainValue, setDomainValue] = useState(false)
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const formValidator = useRef(new SimpleReactValidator());

    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const country_info = sessionParsedValue.country_info
    const sessionArr = sessionParsedValue.entity_info
    const [remarksModal, setRemarksModal] = useState(false)
    const [complianceSave, setComplianceSave] = useState(false)
    const [statusId, setStatusId] = useState("")
    const [dateChange, setDateChange] = useState(false)
    const [payloadData, setPayloadData] = useState([])
    const [finalpayloadData, setFinalPayloadData] = useState([])
    const [getreportlist, setGetReportList] = useState([])
    const isAuth = localStorage.getItem('isAuthenticated');
    const [selectValue, setSelectValues] = useState("")
    const [RemoveDocument, setRemoveDocument] = useState([])
    const [valueOnChange, setValueOnChange] = useState(false)
    const [complaincePayload, setCompliancePayload] = useState([])
    console.log(RemoveDocument, 'RemoveDocument');
    const [AssigneeDetails, setAssigneeDetails] = useState([])
    const [assigneeDocument, setAssigneeDocument] = useState([])
    const [concurrerDocument, setconcurrerDocument] = useState([])
    const [ApproverDocument, setApproverDocument] = useState([])
    const [updatevalue, setupdatevalue] = useState([])
    console.log(assigneeDocument, 'assigneeDocument');
    const [remarksId, setRemarksId] = useState([])
    const [remarksvalue, setRemarksValue] = useState([])
    const [concurrerDetails, setconcurrerDetails] = useState([])
    const [approverDetails, setApproverDetails] = useState([])
    const [approveLabel, setApproveLabel] = useState([])
    console.log(approveLabel, 'approveLabel');
    const [approveLabelData, setApproveLabelData] = useState([])
    console.log(approveLabelData, 'approveLabelData');
    const [assigneeLabel, setassigneeLabel] = useState([])
    const [concurrerLabel, setAconcurrerLabel] = useState([])
    const [select, setSelect] = useState([])
    const [monthData, setMonthData] = useState("")
    const [RemarksDetail, setRemarksDetail] = useState([])
    const [assignee, setAssignee] = useState({
        assignee: '',
        concurrer: ''

    })
    const [validationState, setValidationState] = useState(false)
    console.log(validationState, 'validationState');
    const authtoken = localStorage.getItem('authToken');
    const [labelCheckBox, setlabelCheckBox] = useState([])
    const location = useLocation();
    const paramid = location.pathname;
    const [invoiceData, setInvoiceData] = useState({
        invoice_no: '',
        invoice_date: ''
    })
    const [entityid, setCurrentEntity] = useState("");
    const [tableshow, setTableShow] = useState(false)
    console.log(tableshow, 'tableshow');
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [complianceStatusModal, setComplianceStatusModal] = useState(false)
    const [detailsModal, setDetailsModal] = useState(false)
    const [searchModal, setsearchModal] = useState(false)
    const [labelSubmit, setLabelSubmit] = useState([])
    const [fileuploadData, setFileUploadData] = useState([])
    console.log(labelSubmit, 'labelSubmit');
    const [key, setKey] = useState("1")
    const [type, setType] = useState('')
    const [month, setMonth] = useState('')
    const [invoice, setInvoice] = useState(false)

    const [titledata, Settitledata] = useState()

    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArrayState, setFilteredArrayState] = useState([])
    const [division, setDivision] = useState([])
    const [category, setCategory] = useState([])
    const [domain, setDomain] = useState([])
    console.log(domain, 'domain5555555');
    const [domainName, setDomainName] = useState("")
    const [vendor, setVendor] = useState([])
    const [paginationdata, setPaginationdata] = useState([])
    const [count, setCount] = useState([])
    let [index, setindex] = useState(0)
    const [labelModal, setLabelModal] = useState(false)
    const [current, setCurrent] = useState(1);
    const [pageState, setpageState] = useState(false);
    const [pageSize, setPageSize] = useState(25)
    const [checkboxValue, setCheckBoxValue] = useState([0, 1, 6, 7, 8, 9, 28, 30, 33])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [submit, setSubmit] = useState(false)
    const [legalstate, Setlegalstate] = useState(false)
    const [tablecount, setTablecount] = useState([])
    const [data, setData] = useState({
        country: '',
        legalEntity: '',
        domain: '',
        vendor: '',
        category: '',
        division: '',
        vendorName: '',
        vendorCode: ''
    })
    console.log(data, 'ssssss');
    const [vendorComplianceScore, setVendorComplianceScore] = useState({
        country: '',
        countryName: '',
        division: '',
        divisionName: '',
        typeid: '',
        typeName: '',
        category: '',
        categoryName: '',
        from_month: '',
        to_month: '',
        legal_entity_name: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
        legal_entity_id: '',
        domain: '',
        domainName: '',
        vendor_id: '',
        vendor_name: ''
    })
    const [fileData, setFileData] = useState([])
    const [fileupload, setFileUpload] = useState([])
    const [vendorDetails, setVendorDetails] = useState({
        code: '',
        name: '',
        invoice_no: '',
        invoice_date: '',
        month: '',
        cert_id: '',
        vendor_id: ''

    })
    const sessionArray = sessionParsedValue.login_response
    console.log(sessionArray, 'sessionArray');
    const [certificateFormSubmit, setcertificateFormSubmit] = useState(false);
    const validator = useRef(new SimpleReactValidator());
    const Formvalidator = useRef(new SimpleReactValidator());
    const Formvalidator1 = useRef(new SimpleReactValidator());
    const { Panel } = Collapse;
    const { Option } = Select;

    const showModal = (row) => {
        console.log(row, 'rowrow');
        const payload2 = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetComplianceStatusCertifications",
                    {
                        "legal_entity_id": data.legalEntity,
                        "cert_id": row.certid

                    }
                ]
            }
        ]

        viewApproveComplianceStatus({
            payload: payload2,
            paramid: paramid
        })
        setComplianceStatusModal(true)
    };
    const showModalData = (row) => {
        console.log(row, 'rowrow');
        console.log(row && row.certid, 'row && row.certid');
        const payload2 = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetComplianceDetailsEnclosed",
                    {
                        "legal_entity_id": data.legalEntity,
                        "cert_id": row && row.certid
                    }
                ]

            }
        ]

        viewApproveComplianceDetails({
            payload: payload2,
            paramid: paramid
        })
        setDetailsModal(true)
    };
    useEffect(() => {
        if (detailsModals) {
            if (detailsModals && detailsModals.details_enclosed && detailsModals.details_enclosed.length > 0) {
                let assignee = _.filter(detailsModals.details_enclosed, { details: "Assignee" })
                setAssigneeDetails(assignee)
                let approver = _.filter(detailsModals.details_enclosed, { details: "Approver" })
                setApproverDetails(approver)
                let concurrer = _.filter(detailsModals.details_enclosed, { details: "Concurrer" })
                setconcurrerDetails(concurrer)

            }
        }
    }, [detailsModals])
    console.log(updatevalue, 'updatevalue');
    useEffect(() => {
        if (approveLabel && approveLabel.length > 0) {
            let temp = []
            approveLabel && approveLabel.length > 0 && approveLabel.map((item, i) => {
                for (let j in approveLabel[i]) {
                    temp.push(approveLabel[i][j])
                }

                console.log(temp, 'temptemptemptemptemp');
            })
            setupdatevalue(temp)
        }
    }, [approveLabel])

    useEffect(() => {
        let obj = []
        if (payloadData && payloadData.length > 0) {
            console.log(payloadData, 'payloadData');
            for (let i in payloadData) {
                let entity = {
                    [payloadData[i].cert_id]: {
                        "is_concur": payloadData[i].is_concur,
                        "status": payloadData[i].status,
                        "remarks": payloadData[i].remarks
                    }
                }
                obj.push(entity)

            }
            if (obj && obj.length > 0) {
                let finalObj = {};
                for (let i in obj) {
                    Object.assign(finalObj, obj[i]);
                }
                console.log(finalObj, 'finalObjfinalObjfinalObj');
                setFinalPayloadData(finalObj)
            }
            else {
                setFinalPayloadData([])
            }

        }
    }, [payloadData])

    useEffect(() => {
        if (approverDetails && approverDetails.length > 0) {
            let temp = []

            for (let i in approverDetails[0].lbl_info_details
            ) {
                let data = approverDetails[0].lbl_info_details[i]
                let splitdata = data.split('->')
                console.log(splitdata, 'splitdata');
                console.log(data, 'avvvvvv');
                if (splitdata && splitdata.length > 0) {
                    let obj = {
                        [splitdata[0]]: splitdata[1]
                    }
                    temp.push(obj)
                }

            }
            setApproveLabelData(temp)

        }
    }, [approverDetails])

    const RemarksColumn = [
        {
            title: 'S.No',
            dataIndex: false,
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return <span>{record.r_id}</span>
            }
        },
        {
            title: 'Description',
            dataIndex: 'r_description',
            key: 'r_description',
            width: '200px',
            ellipsis: true,
            render: (text, record) => <a style={{ color: record.r_description == remarksvalue ? 'blue' : 'black' }} onClick={(e) => {
                console.log(record.r_description, 'e.target.value');
                let temp = RemarksDetail
                setValueOnChange(!valueOnChange)
                RemarksDetail[remarksId] = record.r_description

                setRemarksDetail(RemarksDetail)
                setRemarksValue(record.r_description)
                setRemarksDetail(temp)
                setRemarksModal(false)

            }}>{record.r_description}</a>,
        },
    ]

    useEffect(() => {
        if (AssigneeDetails && AssigneeDetails.length > 0) {
            let temp = []

            for (let i in AssigneeDetails[0].lbl_info_details
            ) {
                let data = AssigneeDetails[0].lbl_info_details[i]
                let splitdata = data.split('->')
                console.log(splitdata, 'splitdata');
                console.log(data, 'avvvvvv');
                if (splitdata && splitdata.length > 0) {
                    let obj = {
                        label: splitdata[0],
                        value: splitdata[1]
                    }
                    temp.push(obj)
                }

            }
            setassigneeLabel(temp)

        }
    }, [AssigneeDetails])
    useEffect(() => {
        if (concurrerDetails && concurrerDetails.length > 0) {
            let temp = []

            for (let i in concurrerDetails[0].lbl_info_details
            ) {
                let data = concurrerDetails[0].lbl_info_details[i]
                let splitdata = data.split('->')
                console.log(splitdata, 'splitdata');
                console.log(data, 'avvvvvv');
                if (splitdata && splitdata.length > 0) {
                    let obj = {
                        label: splitdata[0],
                        value: splitdata[1]
                    }
                    temp.push(obj)
                }

            }
            setAconcurrerLabel(temp)

        }
    }, [concurrerDetails])
    useEffect(() => {
        if (AssigneeDetails.length > 0) {
            let keys = []
            AssigneeDetails && AssigneeDetails.length > 0 && AssigneeDetails.map((item, i) => {
                let data = item.document_name
                keys = Object.keys(data)
            })
            console.log(keys, 'keyskeyskeys');
            setAssigneeDocument(keys)
        }
    }, [AssigneeDetails])
    useEffect(() => {
        if (concurrerDetails.length > 0) {
            let keys = []
            concurrerDetails && concurrerDetails.length > 0 && concurrerDetails.map((item, i) => {
                let data = item.document_name
                keys = Object.keys(data)
            })
            console.log(keys, 'keyskeyskeys');
            setconcurrerDocument(keys)
        }
    }, [concurrerDetails])
    useEffect(() => {
        if (approverDetails.length > 0) {
            let keys = []
            approverDetails && approverDetails.length > 0 && approverDetails.map((item, i) => {
                let data = item.document_name
                keys = Object.keys(data)
            })
            console.log(keys, 'keyskeyskeys');
            setApproverDocument(keys)
        }
    }, [approverDetails])
    useEffect(() => {
        if (MappingDetail) {
            if (MappingDetail && MappingDetail.Additional_Details && MappingDetail.Additional_Details.length > 0) {
                let assignee = _.filter(MappingDetail.Additional_Details, { details: "Assignee" })
                setAssigneeDetails(assignee)
                let approver = _.filter(MappingDetail.Additional_Details, { details: "Approver" })
                setApproverDetails(approver)
                let concurrer = _.filter(MappingDetail.Additional_Details, { details: "Concurrer" })
                setconcurrerDetails(concurrer)

            }
        }
    }, [MappingDetail])
    console.log(AssigneeDetails, 'AssigneeDetails');
    console.log(approverDetails, 'approverDetails');
    console.log(concurrerDetails, 'concurrerDetails');
    useEffect(() => {
        if (month != '') {
            if (searchDatas && searchDatas.cmplist && searchDatas.cmplist.length > 0) {
                if (submit == true) {
                    submitData()
                }
                else {
                    setsearchModal(true)
                }

            }
            else {
                Toaster.error("No compliance for the selected month")
            }
        }
    }, [searchDatas && searchDatas.cmplist])
    useEffect(() => {
        if (approveLabel && approveLabel.length > 0) {
            let finalObj = {};
            for (let i in approveLabel) {
                Object.assign(finalObj, approveLabel[i]);
            }
            console.log(finalObj, 'finalObjfinalObjfinalObj');
            setLabelPayload(finalObj)
        }
        else {
            setLabelPayload([])
        }
    }, [approveLabel])
    console.log(labelPayload, 'labelPayload');
    useEffect(() => {
        if (fileupload && fileupload.length > 0) {
            let temp = []
            for (let i in fileupload) {
                let obj = {
                    "file_size": fileupload[i].file_size,
                    "file_name": fileupload[i].file_name,
                    "file_content": null
                }
                temp.push(obj)
            }
            setFileUploadData(temp)
        }
    }, [fileupload])
    const submitData = () => {

        Swal.fire({
            title: 'Are You Sure,You want to submit the certificate?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {

                const payload1 = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "ComplianceCertificate",
                            {
                                "le_id": data.legalEntity,
                                "v_id": Number(data.vendor),
                                "inv_req": invoice == true ? 1 : 0,
                                "cer_mon": month,
                                "inv_no": invoice == true ? invoiceData.invoice_no : null,
                                "inv_date": invoice == true ? moment(new Date(invoiceData.invoice_date)).format('DD/MM/YYYY') : null,
                                "label_value": "{}",
                                "documents": fileuploadData.length > 0 ? fileuploadData : null,
                                "assignee": assignee.assignee,
                                "concurrer": assignee.concurrer,
                                "mail_data": [

                                ],
                                "c_id": Number(data.country),
                                "d_id": Number(data.domain),
                                "unit_name": data.vendorName
                            }
                        ]

                    }
                ]
                submitComplianceCertificate({
                    payload: payload1,
                    paramid: paramid
                })
                // Swal.fire('Closed.!', 'Closed...', 'success');
            }
        });
    };
    // useEffect(() => {
    //     if (MappingDetail) {
    //         setAssignee({
    //             ...assignee,
    //             assignee: MappingDetail.assignee,
    //             concurrer: MappingDetail.concurrer
    //         })
    //     }
    // }, [MappingDetail])

    const onSearch = () => {
        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetVndrWiseCmp",
                    {
                        "cer_mon": month,
                        "v_id": Number(data.vendor),
                        "legal_entity_id": data.legalEntity,
                        "d_id": Number(data.domain)
                    }
                ]

            }
        ]

        searchCompliance({
            payload: payload,
            paramid: paramid
        })
    }

    console.log(data, 'datadatadatadata');
    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != "All Legal Entity") {
            let domains = _.filter(filtervalues && filtervalues.domains, { le_id: localStorage.getItem('SelectedEntityid') })
            console.log(domains, 'domains999999');
            setData({
                ...data,
                country: sessionArr[0].c_name,
                legalEntity: localStorage.getItem('SelectedEntityid'),
                // domain: domains && domains.length > 0 && domains[0].d_id
            })
        }
    }, [localStorage.getItem('SelectedEntity')])

    useEffect(() => {

        if (domain && domain.length > 0) {
            setDomainValue(true)
            setData({
                ...data,
                domain: domain && domain.length > 0 && domain[0].d_id
            })
        }
    }, [domain])
    console.log(data && data.legalEntity, 'data && data.legalEntity ');
    const Remove = (index) => {
        console.log(index, 'fileindex');
        let temp2 = []
        let temp = fileupload
        for (let i in fileupload) {

            if (fileupload[i]['file_name'] == index) {
                fileupload.splice(i, 1)
            }

        }
        temp2.push(index)
        setRemoveDocument([...RemoveDocument, ...temp2])
        setFileUpload([...fileupload])

    }
    const download = (item) => {
        console.log(item, "itemitem");

        // <a href={`${'https://13.234.100.64/compliance-approval/' + fileData.file_name}`} target="_blank" >
        // </a>


        const downloadpayload =
            [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "DownloadFileCert",
                        {
                            "le_id": data.legalEntity,
                            "c_id": 1,
                            "u_id": Number(data.vendor),
                            "upload_type": 2,
                            "file_value": item,
                        }
                    ]
                }
            ]
        Downloadfile({
            payload: downloadpayload,
            paramid: paramid
        });
        if (item) {
            // window.open('https://13.234.100.64/' + item);

            // <a href="path_to_file" download="item"></a>
            window.open('https:/' + item);
        }
        // <a href="https://13.234.100.64/" download="item"></a>
    }
    const columns = [


        {
            title: 'Month',
            dataIndex: false,
            key: false,
            render: (text, row) => {
                return (
                    <p style={{ textAlign: 'center' }}>{row.month}</p>
                )
            }
            // width: "50px",
            // ellipsis: true,
            // record && record.comp_name
        },
        {
            title: 'Year',
            dataIndex: false,
            key: false,
            render: (text, row) => {
                return (
                    <p style={{ textAlign: 'center' }}>{row.year}</p>
                )
            },
            width: "55px",
            // ellipsis: true,
            // record && record.comp_name
        },
        {
            title: 'Invoice Number',
            dataIndex: false,
            ellipsis: true,

            render: (text, row) => {
                return (
                    <p style={{ textAlign: 'center' }}>{row.invoice_no
                    }</p>
                )
            }
        },

        {
            title: 'Compliance Status',
            dataIndex: false,
            ellipsis: true,
            width: '150px',
            // width: '12%',
            render: (text, row) => {
                return (
                    <div className="text-center">
                        <Button type="primary" style={{ background: "#0096FF" }}
                            className='addbutton'
                            shape='round'
                            // style={{ background: "#008000"}}
                            onClick={() => {
                                showModal(row)
                                setMonthData(`${row.month}-${row.year}`)
                                // setType('unit_overdue')
                                // showModal()
                            }}
                        >View</Button></div>
                )
            }
        },
        {
            title: 'Details Enclosed',
            dataIndex: false,
            ellipsis: true,
            // width: '12%',
            render: (text, row) => {
                return (
                    <div className="text-center">
                        <Button type="primary"
                            className='addbutton'
                            shape='round'
                            style={{ background: "#0096FF" }}
                            onClick={() => {
                                setAssigneeDocument([])
                                setAssigneeDetails([])
                                setconcurrerDocument([])
                                setconcurrerDetails([])
                                setApproverDocument([])
                                setApproverDetails([])
                                //   setType('unit_overdue')
                                showModalData(row)
                                setVendorDetails({
                                    code: row.vendorcode,
                                    name: row.vendorname,
                                    invoice_date: row.invoice_date,
                                    invoice_no: row.invoice_no,
                                    month: `${row.month}-${row.year}`

                                })

                            }}
                        >View</Button></div>
                )
            }
        },
        {
            title: 'Additional Input',
            dataIndex: false,
            ellipsis: true,
            // width: '12%',
            render: (text, row) => {
                return (
                    <div className="text-center">
                        <PlusCircleOutlined onClick={() => {
                            setAssigneeDocument([])
                            setAssigneeDetails([])
                            setconcurrerDocument([])
                            setconcurrerDetails([])
                            setApproverDocument([])
                            setApproverDetails([])
                            onCreate(row)
                            setVendorDetails({
                                code: row.vendorcode,
                                name: row.vendorname,
                                invoice_date: row.invoice_date,
                                invoice_no: row.invoice_no,
                                month: `${row.month}-${row.year}`,
                                cert_id: row.certid,
                                vendor_id: row.vendorid

                            })

                        }} /></div>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: false,
            ellipsis: true,
            render: (text, row) => {
                return (
                    <p style={{ textAlign: 'center' }}>{row.inv_date
                    }</p>
                )
            }
        },
        {
            title: 'Action',
            dataIndex: false,
            ellipsis: true,
            width: '200px',
            render: (text, row, k) => {
                if (sessionArray && sessionArray.cmpf_user_category == 1) {
                    return (
                        <div className="text-center">
                            <Select style={{ width: '85%' }}
                                defaultValue="All"
                                onChange={(data) => {
                                    console.log(data, '(e.target.value');
                                    setValueOnChange(!valueOnChange)
                                    let temp = []

                                    setSelectValues(data)
                                    select && select.length > 0 && select.map((item, i) => {
                                        if (item.id === row.certid) {
                                            select.splice(i, 1)
                                            setSelect([...select, ...temp])
                                        }
                                    })
                                    temp.push({
                                        id: row.certid,
                                        value: `${data}`,
                                        status: `${row.is_concurr}`,
                                        index: k
                                    })

                                    setSelect([...select, ...temp])
                                }}>
                                <Option value="All">Select</Option>
                                <Option value="3">Approve</Option>
                                <Option value='1'>Rectify Assignee</Option>
                                {row.cert_concur_status == 1 ? <Option value='2'>Rectify Concurrer</Option> : false}
                            </Select></div>

                    )
                }
                else {
                    return (
                        <div className="text-center">
                            <Select defaultValue="All" style={{ width: '85%', marginLeft: '15%', textAlign: 'center' }}
                                onChange={(data) => {
                                    console.log(data, '(e.target.value');
                                    setValueOnChange(!valueOnChange)
                                    let temp = []


                                    select && select.length > 0 && select.map((item, i) => {
                                        if (item.id === row.certid) {
                                            select.splice(i, 1)
                                            setSelect([...select, ...temp])
                                        }
                                    })
                                    temp.push({
                                        id: row.certid,
                                        value: `${data}`,
                                        status: `${row.is_concurr}`,
                                        index: k
                                    })

                                    setSelect([...select, ...temp])
                                }}
                            >
                                <Option value="All">Select</Option>
                                <Option value="1">Concur</Option>
                                <Option value='2'>Rectify Assignee</Option>

                            </Select></div>
                    )
                }

            }
        },
        {
            title: "Remarks",
            dataIndex: false,
            width: '150px',
            render: (record, rowIndex, k) => {
                console.log(k, 'select11111');
                if (select && select.length > 0) {
                    for (let i in select) {
                        console.log(((select[i].id == record.certid) && ((select[i].value != '1') || (select[i].value != 'All'))), 'testtttt');
                        if (select[i].id == record.certid) {
                            return (

                                <div style={{ display: ' flex', justifyContent: 'center' }}>

                                    <div> <Input name='remarks' className="form-control" placeholder='Enter Remarks' autoComplete={'off'}
                                        defaultValue={RemarksDetail[k]}
                                        id='remarks'
                                        autoFocus={true}
                                        key={RemarksDetail[k]}
                                        hidden={(sessionArray && sessionArray.cmpf_user_category == 1 && select[i].value == '3') || (sessionArray && sessionArray.cmpf_user_category == 3 && select[i].value == '1') || select[i].value == 'All'} style={{ height: "35px", width: '100%' }}
                                        onChange={(e) => {
                                            setValueOnChange(!valueOnChange)
                                            let temp = RemarksDetail
                                            // RemarksDetail && RemarksDetail.length > 0 && RemarksDetail.map((item, i) => {
                                            //     if (i == k) {
                                            //         RemarksDetail.splice(i, 1)
                                            //         setRemarksDetail([...RemarksDetail, ...temp])
                                            //     }
                                            // })
                                            // temp.push({
                                            //     id: record.certid,
                                            //     value: e.target.value,
                                            //     status: record.current_status
                                            // })
                                            RemarksDetail[k] = e.target.value

                                            setRemarksDetail(RemarksDetail)
                                        }}

                                    >
                                    </Input>
                                        {formValidator.current.message(
                                            'remarks',
                                            RemarksDetail[k],
                                            'required',
                                            {
                                                className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Remark Required',
                                                }
                                            })}
                                    </div>
                                    <PlusCircleOutlined
                                        hidden={(sessionArray && sessionArray.cmpf_user_category == 1 && select[i].value == '3') || (sessionArray && sessionArray.cmpf_user_category == 3 && select[i].value == '1') || select[i].value == 'All'} style={{ alignSelf: 'center', marginLeft: '5px' }}
                                        // style={{ marginLeft: '10px' }}
                                        onClick={() => {
                                            let temp = []
                                            const payload = [
                                                authtoken,
                                                {
                                                    "session_token": authtoken,
                                                    "request": [
                                                        "GetRemarksData",
                                                        {}
                                                    ]

                                                }
                                            ]
                                            setRemarksId(k)
                                            setStatusId(record.current_status)
                                            RemarksData({
                                                payload: payload,
                                                paramid: paramid
                                            })
                                            setRemarksModal(true)
                                        }}
                                    />



                                </div >
                            )

                        }
                    }
                }




            },
            dataIndex: false,
            key: false
        },



    ]
    console.log(select, 'select');
    console.log(RemarksDetail, 'RemarksDetail');
    const searchcolumns = [


        {
            title: 'Act',
            dataIndex: false,
            key: false,
            render: (text, row) => {
                return (
                    <p>{row.act

                    }</p>
                )
            },
            // width: "50px",
            ellipsis: true,
            // record && record.comp_name
        },

        {
            title: 'Compliance Task',
            dataIndex: false,
            ellipsis: true,

            render: (text, row) => {
                return (
                    <p >{row.compliance_task
                    }</p>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: false,
            ellipsis: true,
            render: (text, row) => {
                return (
                    <p>{row.task_status

                    }</p>
                )
            }
        },
        {
            title: 'Due Date',
            dataIndex: false,
            ellipsis: true,
            render: (text, row) => {
                return (
                    <p>{row.due_date

                    }</p>
                )
            }
        },




    ]
    const columns1 = [


        {
            title: 'Act',
            dataIndex: false,
            key: false,
            render: (text, row) => {
                return (
                    <p>{row.stat_mapping

                    }</p>
                )
            }
            // width: "50px",
            // ellipsis: true,
            // record && record.comp_name
        },

        {
            title: 'Compliance Task',
            dataIndex: false,
            // ellipsis: true,

            render: (text, row) => {
                return (
                    <p>{row.c_task
                    }</p>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: false,
            // ellipsis: true,
            render: (text, row) => {
                return (
                    <p>{row.a_status

                    }</p>
                )
            }
        },




    ]
    const labelcolumns = [


        {
            title: <Fragment>
                <input type="checkbox" name="allchecked" />
            </Fragment>,
            dataIndex: false,
            key: false,
            ellipsis: true,
            width: '15px',
            align: 'center',
            render: (text, record) => {
                return (
                    <Fragment>
                        <input type="checkbox"
                            name={'checked'}

                            onClick={(e) => {
                                let checked = e.target.checked;

                                addSelectedDataValues(
                                    checked,
                                    record
                                );
                            }}
                        />
                    </Fragment>
                )
            }
        },

        {
            title: 'Select All',
            dataIndex: false,
            ellipsis: true,
            width: '80px',
            render: (text, row) => {
                return (
                    <p>{row.label_description

                    }</p>
                )
            }
        },





    ]

    const uploadFileFormat = (size, name, content) => {
        var result = {
            'file_size': parseInt(size),
            'file_name': name,
            'file_content': content,
        };

        return result;
    }

    const convert_to_base64 = (file, name, size, callback) => {
        var reader = new FileReader();
        if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
            var binary = "";
            reader.onload = function (readerEvt) {
                var bytes = new Uint8Array(readerEvt.target.result);
                var length = bytes.byteLength;
                for (var i = 0; i < length; i++) {
                    binary += String.fromCharCode(bytes[i]);
                }
                callback(EncryptDecrypt.encryptdata(binary), name, size);
            }
            reader.readAsArrayBuffer(file);
        } else {
            reader.onload = function (readerEvt) {
                var binaryString = readerEvt.target.result;
                callback(EncryptDecrypt.encryptdata(binaryString), name, size);
            };
            reader.readAsBinaryString(file);
        }
    }

    useEffect(() => {
        if (select && select.length > 0) {
            let temp = []
            if (RemarksDetail && RemarksDetail.length > 0) {
                for (let i in select) {
                    for (let j in RemarksDetail) {
                        if (select[i].index == j) {
                            temp.push({
                                cert_id: select[i].id,
                                is_concur: select[i].status,
                                status: select[i].value,
                                remarks: RemarksDetail[j]


                            })
                        }
                    }

                }
                setPayloadData(temp)
                console.log(temp, 'temp5555');
            }
        }
    }, [select, RemarksDetail, valueOnChange])


    const SavepastRecordOnChange = (e) => {
        if (e.target.name === 'file') {
            var results = [];
            const fileRegex = /^[a-zA-Z0-9-_& ,.+#()[-\]{}$^=%@]*$/
            let files = e.target.files
            let file_max_size = 26214400
            for (var i = 0; i < files.length; i++) {
                var file = files[i];
                var file_name = file.name;
                const f_name = file_name.substring(0, file_name.lastIndexOf('.'));
                if (fileRegex.test(f_name) === false) {
                    return;
                }
                let file_size = file.size;
                if (files[i].size > file_max_size) {
                    toast.error("File max limit exceeded")
                    return;
                }
                if (file) {
                    convert_to_base64(file, file_name, file_size, function (file_content, name, size) {
                        var fN = name.substring(0, name.lastIndexOf('.'));
                        var fE = name.substring(name.lastIndexOf('.') + 1);
                        var uniqueId = Math.floor(Math.random() * 90000) + 10000;
                        var f_Name = fN + '-' + uniqueId + '.' + fE;

                        var result = uploadFileFormat(size, f_Name, file_content);
                        setFileData(result)
                        results.push(result);


                        let temp1 = fileupload
                        let temp = []
                        if (results && results.length == 1) {
                            for (let i in results) {
                                temp1.push(results[i])
                            }
                            setFileUpload([...temp1])
                        }
                        else {
                            for (let i in results) {
                                temp.push(results[i])
                            }
                            setFileUpload([...temp])
                        }

                        const filePayload = [
                            authtoken,
                            {
                                "session_token": authtoken,
                                "request": [
                                    "CertDocumentsTemp",
                                    {
                                        "le_id": data && data.legalEntity,
                                        "unit_id": Number(data.vendor),
                                        "file_info": results,
                                    }
                                ]
                            }
                        ]
                        console.log(filePayload, 'filePayload');
                        UploadFile({
                            payload: filePayload,
                            paramid: paramid
                        })
                    });
                }
            }
        }
        React.forwardRef(({ onClick, ...rest }, ref) => {
            return (
                <>
                    <div className="form-check pb-5" style={{ backgroundColor: '' }}>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            style={{ height: '20px', width: '20px' }}
                            ref={ref}
                            onClick={onClick}
                            {...rest}
                        />
                        <label className="form-check-label" id="booty-check" />
                    </div>
                </>
            )
        })
    }
    const addSelectedDataValues = (checked, record) => {
        console.log(record, 'record');
        var ele = document.getElementsByName('allchecked');

        let array
        let tempArray = labelCheckBox


        if (checked === true) {

            let temp = []
            tempArray.push(record.label_description)
        } else {
            for (let i in tempArray) {
                if (tempArray[i] === record.label_description) {
                    tempArray.splice(i, 1)
                }

                for (var j = 0; j < ele.length; j++) {
                    if (ele[j].type === 'checkbox')
                        ele[j].checked = false;
                }

            }

        }
        setlabelCheckBox([...tempArray])


    }

    const addAllSelectedDataValues = (checked) => {

        // console.log(data,'data');

        var ele = document.getElementsByName('checked');

        if (checked === true) {

            let data = labelLists && labelLists.label_name && labelLists.label_name.length > 0 && labelLists.label_name.map((item, i) => {
                return item.label_description
            })
            console.log(data, 'data');
            setlabelCheckBox(data)
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type === 'checkbox')
                    ele[i].checked = true;
            }
        } else {

            setlabelCheckBox([])
            for (var j = 0; j < ele.length; j++) {
                if (ele[j].type === 'checkbox')
                    ele[j].checked = false;
            }
        }
    }
    console.log(labelCheckBox, 'labelCheckBox');



    useEffect(() => {
        if (data && data.legalEntity != '') {
            const payload2 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetApproveComplianceCertificationsFilters",
                        {
                            "country_id": 1,
                            "legal_entity_id": data.legalEntity
                        }
                    ]
                }
            ]

            getApproveComplianceCertificateFilter({
                payload: payload2,
                paramid: paramid
            })


        }

    }, [data.legalEntity])
    const handleOk = () => {
        setIsModalOpen(true);
        if (validator.current.allValid()) {
            const payload2 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetMonthlyComplianceReport",
                        {
                            "country_id": vendorComplianceScore.country ? Number(vendorComplianceScore.country) : 1,
                            "legal_entity_id": entityid,
                            "domain_id": vendorComplianceScore.domain ? Number(vendorComplianceScore.domain) : 0,
                            "division_id": vendorComplianceScore.division ? Number(vendorComplianceScore.division) : 0,
                            "category_id": vendorComplianceScore.category ? Number(vendorComplianceScore.category) : 0,
                            "unit_id": vendorComplianceScore.vendor_id ? Number(vendorComplianceScore.vendor_id) : 0,
                            "from_date": moment(vendorComplianceScore.from_date).format("MMMM-YYYY"),
                            "to_date": moment(vendorComplianceScore.from_date).format("MMMM-YYYY"),
                            "csv": true,
                            "from_count": 0,
                            "page_count": 0,
                            "list_check": checkboxValue
                        }
                    ]
                }
            ]
            MonthlyComplianceExport({
                payload: payload2,
                paramid: paramid
            })
        }
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setComplianceStatusModal(false)
        setDetailsModal(false)
        setLabelModal(false)
        setsearchModal(false)
        setRemarksModal(false)
    };
    useEffect(() => {
        if (tabledatas.certificate_compliances) {
            if (tabledatas.certificate_compliances.length > 0) {
                setTableShow(true)
            }
            else {
                Toaster.error("There is no Compliance Certification Available")
            }
        }
    }, [tabledatas.certificate_compliances])

    const onshow = () => {
        setAddFormSubmit(true)
        console.log(validator.current.allValid(), 'validator.current.allValid()');
        if (validator.current.allValid()) {
            // setTableShow(true)
            console.log('aishuuuuuuu');
            // setKey(0)
            const payload1 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetComplianceCertifications",
                        {
                            "unit_id": data.vendor != '' ? Number(data.vendor) : null,
                            "legal_entity_id": data.legalEntity,
                            "d_id": Number(data.domain),
                            "division_id": data.division != '' ? Number(data.division) : null,
                            "category_id": data.category != '' ? Number(data.category) : null,
                        }
                    ]
                }
            ]
            getApproveComplianceCertificateTable({
                payload: payload1,
                paramid: paramid
            })

        }
        else {
            console.log('testtttt');
        }
    }


    const onSubmitData = () => {

        setAddFormSubmit1(true)

        let datas = JSON.stringify(finalpayloadData)

        if (formValidator.current.allValid()) {
            console.log(datas, 'dats8888');
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetCertificateMailData",
                        {
                            "v_ids": [],
                            "legal_entity_id": data.legalEntity
                        }
                    ]


                }
            ]

            certificateMailData({
                payload: payload,
                paramid: paramid
            })
            const payload2 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetComplianceCertificateApproval",
                        {
                            "certificate_status": datas,
                            "legal_entity_id": data && data.legalEntity,
                            "country_id": country_info && country_info.length > 0 && country_info[0].c_id,
                            "domain_id": Number(data.domain),
                            "mail_data": []
                        }
                    ]


                }
            ]
            console.log(payload2, 'AVVVV');
            approveCertificate({
                payload: payload2,
                paramid: paramid
            })

            console.log('aishuuuuuuu');
            // setKey(0)

            setAddFormSubmit1(false)
        }


    }

    const onSubmit = () => {

        let datas = JSON.stringify(labelPayload)
        let data1 = JSON.stringify(datas)
        console.log(data1, 'datas12333');
        if (validationState === true) {
            if (Formvalidator1.current.allValid()) {
                console.log('Snehaaaa');
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "UpdateCertificateApproverDetails",
                            {
                                "cert_id": vendorDetails.cert_id,
                                "certificate_status": approveLabel && approveLabel.length > 0 ? datas : '{}',
                                "legal_entity_id": data.legalEntity,
                                "vendorid": vendorDetails.vendor_id,
                                "documents": fileuploadData.length > 0 ? fileuploadData : []

                                ,
                                "removed_documents": RemoveDocument
                            }
                        ]


                    }
                ]
                console.log(payload, 'payload9999');
                AddAdditionalInformation({
                    payload: payload,
                    paramid: paramid
                })
            }
        }
        else {
            toast.error('Please provide valid additional info')
        }

    }
    const onCreate = (row) => {



        setComplianceSave(true)
        console.log('aishuuuuuuu');
        // setKey(0)
        const payload1 = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetComplianceAdditionalInput",
                    {
                        "cert_id": row.certid,
                        "legal_entity_id": data.legalEntity
                    }
                ]
            }
        ]
        ApprovemappingDetails({
            payload: payload1,
            paramid: paramid
        })


    }
    const onChange = (key) => {
        console.log(key);
    };

    useEffect(() => {
        validator.current.showMessages()
        Formvalidator.current.showMessages()
        Formvalidator1.current.showMessages()
        formValidator.current.showMessages()
    }, [])

    useEffect(() => {
        if (approveLabel && approveLabel.length > 0) {
            for (let i in approveLabel) {
                for (let j in approveLabel && approveLabel[i]) {
                    if (approveLabel[i][j] != '') {
                        setValidationState(true)
                        setcertificateFormSubmit(false)
                    }
                    else {
                        setValidationState(false)
                        setcertificateFormSubmit(true)
                    }
                }
            }
        }

    }, [approveLabel])

    useEffect(() => {
        if (vendorComplianceScore.legel_entity == localStorage.getItem('SelectedEntity')) {
            Setlegalstate(true)
        }
    }, [vendorComplianceScore.legel_entity])

    useEffect(() => {
        if (isAuth) {
            if (entityid != '') {
                if (legalstate === true) {
                    const payload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "GetMonthlyComplianceReportFilters",
                                {
                                    "country_id": Number(vendorComplianceScore.country),
                                    "legal_entity_id": entityid
                                }
                            ]
                        }
                    ]
                    if (entityid != 'null') {

                        MonthlyComplianceFilterReport({
                            payload: payload,
                            paramid: paramid
                        })
                    }
                    Setlegalstate(false)
                }
            }
        }

    }, [isAuth, entityid, legalstate])

    useEffect(() => {
        if (entityid === null) {
            setCurrentEntity(vendorComplianceScore.le_id)
        }
    }, [entityid])
    useEffect(() => {
        if (data && data.legalEntity != "") {
            if (filtervalues && filtervalues.domains && filtervalues.domains.length > 0) {
                let domainData = _.filter(filtervalues && filtervalues.domains, { le_id: data.legalEntity })
                setDomain(domainData)
            }

        }
    }, [filtervalues && filtervalues.domains, data && data.legalEntity])

    useEffect(() => {
        if (filteredArray && filteredArray.length > 0) {
            filteredArray && filteredArray.map((item, i) => {
                return (
                    setFilteredArrayState(item)
                )
            })
        }
    }, [filteredArray])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != 'All Legal Entity') {
            setVendorComplianceScore({
                ...vendorComplianceScore,
                le_id: localStorage.getItem('SelectedEntityid')
            })
        }
    }, [localStorage.getItem('SelectedEntity') != 'All Legal Entity'])

    useEffect(() => {
        if (filtervalues) {
            setCategory(filtervalues && filtervalues.category_list)
            setDivision(filtervalues && filtervalues.division_list)
            setDomain(filtervalues && filtervalues.domains
            )
            setVendor(filtervalues && filtervalues.unit_list
            )
        }
    }, [filtervalues])
    console.log(domain, 'domaindomaindomain');
    const homescreen = () => {
        window.location.href = '/dashboard'
    }
    const checkBoxOnChange = (e) => {
        let tempIds = []
        let temp = checkboxValue
        if (temp.includes(Number(e.target.value)) == true) {
            for (let i in temp) {
                if (temp[i] == Number(e.target.value)) {
                    temp.splice(i, 1)
                }
            }
            setCheckBoxValue([...temp])
        } else {
            tempIds.push(Number(e.target.value))
            setCheckBoxValue([...checkboxValue, ...tempIds])
        }
    }

    const selectAllCheckBox = (checked) => {
        var ele = document.getElementsByName('chk');
        let tempAllIds = []
        if (checked == true) {
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox') {
                    ele[i].checked = true;
                    tempAllIds.push(Number(ele[i].value))
                }
            }
            setCheckBoxValue(tempAllIds)
        }
        else {
            var ele = document.getElementsByName('chk');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = false;

            }
            setCheckBoxValue([1, 2, 3, 4, 5, 6, 11])
        }
    }
    // useEffect(() => {
    //     if (tabledatas) {
    //         setTableData(tabledatas && tabledatas.monthly_compliances)
    //     }
    //     if (tabledatas.total_count) {
    //         setCount(tabledata && tabledatas.total_count)
    //     }
    // }, [tabledatas, tabledatas.monthly_compliances, tabledatas.total_count, count])


    // useEffect(() => {
    //     if (pageState == true) {
    //         const payload1 = [
    //             authtoken,
    //             {
    //                 "session_token": authtoken,
    //                 "request": [
    //                     'GetCriticalityData', {
    //                         "country_id": vendorComplianceScore.country ? Number(vendorComplianceScore.country) : 1,
    //                         "legal_entity_id": entityid,
    //                         "domain_id": vendorComplianceScore.domain ? Number(vendorComplianceScore.domain) : 0,
    //                         "division_id": vendorComplianceScore.division ? Number(vendorComplianceScore.division) : 0,
    //                         "category_id": vendorComplianceScore.category ? Number(vendorComplianceScore.category) : 0,
    //                         "unit_id": vendorComplianceScore.vendor_id ? Number(vendorComplianceScore.vendor_id) : 0,
    //                         "from_date": moment(vendorComplianceScore.from_date).format("MMMM-YYYY"),
    //                         "to_date": moment(vendorComplianceScore.from_date).format("MMMM-YYYY"),
    //                         "csv": true,
    //                         "from_count": 0,
    //                         "page_count": 0,
    //                         "list_check": null,
    //                         'csv': false,
    //                         'f_count': Math.max(((current - 1) * pageSize), 0) + 1,
    //                         't_count': current != 0 ? current * pageSize : pageSize,
    //                         'count_qry': true,
    //                         'list_check': null
    //                     }
    //                 ]
    //             }
    //         ]
    //         MonthlyComplianceTable({
    //             payload: payload1,
    //             paramid: paramid
    //         })
    //     }
    // }, [current, pageSize, pageState])
    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', handleKeyDown, false);
            document.addEventListener('webkitfullscreenchange', handleKeyDown, false);
            return () => {
                document.addEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('webkitfullscreenchange', handleKeyDown);
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode();
            setfullscreen(true);
        } else {
            fullscreenexit();
            setfullscreen(false);
        }
    }
    return (

        <Fragment>


            <div id="page-wrapper" className="page-wrapper">
                <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} />
                <div className="page-titles pb-1 pt-1">
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <span style={{ fontSize: '16px' }}>Transaction</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        <span>Approve Compliance Certificate</span>
                                    </li>

                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-4 col-md-6 text-end">
                            {complianceSave == true ?
                                <Button type="primary" shape="round" className='addbutton' style={{ marginRight: '2%' }}
                                    icon={<ArrowLeftOutlined />} size='default' onClick={() => { setComplianceSave(false) }}>
                                    Back
                                </Button> : false}
                            <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black full-mode fullscreenradius" style={{ marginRight: '10px' }} >
                                <Tooltip placement="left" title="Full Screen Mode"><FullscreenOutlined /></Tooltip>
                            </button>
                        </div>
                    </div>
                </div>
                {complianceSave == false ?
                    <div className="container-fluid pt-1">
                        <div className="row">
                            <div className="col-12">

                                <div className="card">
                                    {/* <div className="card-body approveCard" > */}
                                    <div className={localStorage.getItem('currentTheme')} >
                                        <Collapse className='report-collapse' accordion defaultActiveKey={["1"]}>
                                            <Panel header="Approve Compliance Certificate" key="1"
                                            // extra={tableshow == true ? genextra() : false}
                                            > <div className='col-md-12'>
                                                    <div className='row'>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor=""><b>Country:</b><span style={{ color: "red" }}>*</span></label><br />
                                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                                    <div className="form-group">
                                                                        <Select
                                                                            placeholder='Enter Country'
                                                                            allowClear={true}
                                                                            size="default"
                                                                            onChange={(value, datas) => {
                                                                                if (value !== undefined) {
                                                                                    setData({
                                                                                        ...data,
                                                                                        country: value
                                                                                    })
                                                                                }
                                                                                else {
                                                                                    setData({
                                                                                        ...data,
                                                                                        country: "",
                                                                                        legalEntity: '',
                                                                                        category: '',
                                                                                        division: '',
                                                                                        domain: '',
                                                                                        vendor: '',
                                                                                        vendorName: '',
                                                                                        vendorCode: ''
                                                                                    })
                                                                                }
                                                                            }}

                                                                            showSearch
                                                                            optionFilterProp="children"
                                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                            value={data.country || undefined}
                                                                            style={{ width: '100%', marginTop: '5px' }}

                                                                        // value={unitdata.countryName || undefined}
                                                                        >
                                                                            {country_info && country_info.length && country_info.map((item, i) => {
                                                                                return (
                                                                                    <Option key={item.c_id}>
                                                                                        {item.c_name}
                                                                                    </Option>
                                                                                );
                                                                            })}


                                                                            {/* <Option key={sessionArr && sessionArr.length > 0 && sessionArr[0].c_id}>
                                                                        {sessionArr && sessionArr.length > 0 && sessionArr[0].c_name}
                                                                    </Option> */}



                                                                        </Select>
                                                                        {validator.current.message(
                                                                            'country',
                                                                            data.country,
                                                                            'required',
                                                                            {
                                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                messages: {
                                                                                    required: 'Country Required',

                                                                                }
                                                                            })}
                                                                    </div> :
                                                                    <p>{sessionArr && sessionArr.length > 0 && sessionArr[0].c_name}</p>
                                                                }
                                                                {/* <Select
                                                        allowClear={false}
                                                        // disabled={dataa.legalentity == "" ? true : false}
                                                        size="default"
                                                        placeholder="Enter Country"
                                                        // onClick={() => {
                                                        //     if (dataa.legalentity == "") {

                                                        //         toast.warning("Legal Entity Required")
                                                        //     }
                                                        // }}
                                                        // onChange={(data, value) => {


                                                        //     setDataa({
                                                        //         ...dataa,
                                                        //         d_id: data,
                                                        //         d_name: value.children,
                                                        //         usr_name: '',
                                                        //         unit_name: '',
                                                        //         user_type: ''
                                                        //     })
                                                        // }}
                                                        // value={dataa.d_name || undefined}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%', marginTop: "5px" }}
                                                    >
                                                        {/* {reassigndatalist.domains && reassigndatalist.domains.length && reassigndatalist.domains.map((item) => {
                                                                            return (
                                                                                <Option key={item.d_id}>
                                                                                    {item.d_name}
                                                                                </Option>
                                                                            );
                                                                        })} */}
                                                                {/* </Select> */}

                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor=""><b>Legal Entity:</b><span style={{ color: "red" }}>*</span></label><br />

                                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?

                                                                    <div className="form-group">

                                                                        <Select
                                                                            disabled={data.country == '' ? true : false}
                                                                            // disabled={consolidatedData.country == '' ? true : false}
                                                                            allowClear={true}
                                                                            size="default"
                                                                            placeholder="Enter Legal Entity"
                                                                            onChange={(value, datas) => {
                                                                                if (value !== undefined) {
                                                                                    setData({
                                                                                        ...data,
                                                                                        legalEntity: value
                                                                                    })
                                                                                }
                                                                                else {
                                                                                    setData({
                                                                                        ...data,
                                                                                        legalEntity: '',
                                                                                        category: '',
                                                                                        division: '',
                                                                                        domain: '',
                                                                                        vendor: '',
                                                                                        vendorName: '',
                                                                                        vendorCode: ''
                                                                                    })
                                                                                }
                                                                                // setCurrentEntity(data)
                                                                                // setConsolidatedData({

                                                                                //     ...consolidatedData,
                                                                                //     legel_entity: data
                                                                                // })
                                                                                // setData({
                                                                                //     ...data,
                                                                                //     le_name: value.children
                                                                                // })
                                                                                // setCurrentEntity(data)
                                                                            }}
                                                                            showSearch
                                                                            optionFilterProp="children"
                                                                            value={data.legalEntity || undefined}
                                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                            //value={Settings.le_id}
                                                                            style={{ width: '100%' }}

                                                                        >

                                                                            {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                                return (
                                                                                    <Option key={item.le_id}>
                                                                                        {item.le_name}
                                                                                    </Option>
                                                                                );
                                                                            })}


                                                                        </Select>

                                                                        {validator.current.message(
                                                                            'legal Entity',
                                                                            data.legalEntity,
                                                                            'required',
                                                                            {
                                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                messages: {
                                                                                    required: 'Legal Entity Required',

                                                                                }
                                                                            })}
                                                                    </div> :
                                                                    <p>{localStorage.getItem('SelectedEntity')}</p>
                                                                }


                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor=""><b>Domain:</b><span style={{ color: "red" }}>*</span></label><br />
                                                                {data.legalEntity != '' ? <p>{domain && domain.length && domain[0].d_name}</p> :
                                                                    <>
                                                                        <Select
                                                                            disabled={data.legalEntity == '' ? true : false}
                                                                            allowClear={true}
                                                                            // disabled={dataa.legalentity == "" ? true : false}
                                                                            size="default"
                                                                            placeholder="Enter Domain Name"
                                                                            // onClick={() => {
                                                                            //     if (dataa.legalentity == "") {

                                                                            //         toast.warning("Legal Entity Required")
                                                                            //     }
                                                                            // }}
                                                                            onChange={(value, datas) => {

                                                                                if (value !== undefined) {
                                                                                    setData({
                                                                                        ...data,
                                                                                        domain: value
                                                                                    })
                                                                                }
                                                                                else {
                                                                                    setData({
                                                                                        ...data,
                                                                                        domain: ''
                                                                                    })
                                                                                }
                                                                            }}
                                                                            value={data.domain || undefined}
                                                                            showSearch
                                                                            optionFilterProp="children"
                                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                            style={{ width: '100%', marginTop: "5px" }}
                                                                        >
                                                                            {domain && domain.length && domain.map((item, i) => {
                                                                                return (
                                                                                    <Option key={item.domain_id}>
                                                                                        {item.domain_name}
                                                                                    </Option>
                                                                                );
                                                                            })}
                                                                        </Select>
                                                                        {/* {domainValue == false ? validator.current.message(
                                                                    'domain',
                                                                    data.domain,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Domain Required',

                                                                        }
                                                                    }) : false} */}
                                                                    </>
                                                                }

                                                            </div>
                                                        </div>


                                                        {/* <div style={{ marginLeft: '30%' }}>
                                                                        <PlusSquareTwoTone onClick={() => { showRemarksModal() }} />
                                                                    </div> */}


                                                    </div>
                                                </div>
                                                <div className='col-md-12' style={{ marginTop: "5px" }}>
                                                    <div className='row'>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor=""><b>Division:</b></label><br />

                                                                <Select
                                                                    allowClear={true}
                                                                    disabled={data.legalEntity == '' ? true : false}
                                                                    // disabled={dataa.legalentity == "" ? true : false}
                                                                    size="default"
                                                                    placeholder="Enter Division Name"
                                                                    // onClick={() => {
                                                                    //     if (dataa.legalentity == "") {

                                                                    //         toast.warning("Legal Entity Required")
                                                                    //     }
                                                                    // }}
                                                                    // onChange={(data, value) => {


                                                                    //     setDataa({
                                                                    //         ...dataa,
                                                                    //         d_id: data,
                                                                    //         d_name: value.children,
                                                                    //         usr_name: '',
                                                                    //         unit_name: '',
                                                                    //         user_type: ''
                                                                    //     })
                                                                    // }}
                                                                    alue={data.division || undefined}
                                                                    onChange={(value, datas) => {
                                                                        if (value !== undefined) {
                                                                            setData({
                                                                                ...data,
                                                                                division: value
                                                                            })
                                                                        }
                                                                        else {
                                                                            setData({
                                                                                ...data,
                                                                                division: ''
                                                                            })
                                                                        }
                                                                    }}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    style={{ width: '100%', marginTop: "5px" }}
                                                                >
                                                                    {division && division.length && division.map((item, i) => {
                                                                        return (
                                                                            <Option key={item.div_id
                                                                            }>
                                                                                {item.div_name
                                                                                }
                                                                            </Option>
                                                                        );
                                                                    })}

                                                                </Select>
                                                                {/* {validator.current.message(
                                                                        'domain',
                                                                        dataa.d_id,
                                                                        'required',
                                                                        {
                                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                            messages: {
                                                                                required: 'Domain Required',

                                                                            }
                                                                        })} */}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor=""><b>Category:</b></label><br />

                                                                <Select
                                                                    disabled={data.legalEntity == '' ? true : false}
                                                                    allowClear={true}
                                                                    // disabled={dataa.legalentity == "" ? true : false}
                                                                    size="default"
                                                                    placeholder="Enter Category Name"
                                                                    onChange={(value, datas) => {
                                                                        if (value !== undefined) {
                                                                            setData({
                                                                                ...data,
                                                                                category: value
                                                                            })
                                                                        }
                                                                        else {
                                                                            setData({
                                                                                ...data,
                                                                                category: ''
                                                                            })
                                                                        }

                                                                    }}
                                                                    // onClick={() => {
                                                                    //     if (dataa.legalentity == "") {

                                                                    //         toast.warning("Legal Entity Required")
                                                                    //     }
                                                                    // }}
                                                                    // onChange={(data, value) => {


                                                                    //     setDataa({
                                                                    //         ...dataa,
                                                                    //         d_id: data,
                                                                    //         d_name: value.children,
                                                                    //         usr_name: '',
                                                                    //         unit_name: '',
                                                                    //         user_type: ''
                                                                    //     })
                                                                    // }}
                                                                    value={data.category || undefined}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    style={{ width: '100%', marginTop: "5px" }}
                                                                >
                                                                    {category && category.length && category.map((item, i) => {
                                                                        return (
                                                                            <Option key={item.cat_id}>
                                                                                {item.cat_name}
                                                                            </Option>
                                                                        );
                                                                    })}

                                                                </Select>
                                                                {/* {validator.current.message(
                                                                        'domain',
                                                                        dataa.d_id,
                                                                        'required',
                                                                        {
                                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                            messages: {
                                                                                required: 'Domain Required',

                                                                            }
                                                                        })} */}
                                                            </div>
                                                        </div>
                                                        {/* <div style={{ marginLeft: '30%' }}>
                                                                        <PlusSquareTwoTone onClick={() => { showRemarksModal() }} />
                                                                    </div> */}
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor=""><b>Vendor Name:</b></label><br />

                                                                <Select
                                                                    allowClear={true}
                                                                    disabled={data.legalEntity == '' ? true : false}
                                                                    // disabled={dataa.legalentity == "" ? true : false}
                                                                    size="default"
                                                                    placeholder="Enter Vendor"
                                                                    // onClick={() => {
                                                                    //     if (dataa.legalentity == "") {

                                                                    //         toast.warning("Legal Entity Required")
                                                                    //     }
                                                                    // }}
                                                                    onChange={(value, datas) => {
                                                                        if (value !== undefined) {
                                                                            setTableShow(false)
                                                                            console.log(datas, 'datas');
                                                                            let splitName = datas.children.split('-')
                                                                            console.log(splitName, 'splitName');
                                                                            setData({
                                                                                ...data,
                                                                                vendor: value,
                                                                                vendorName: datas.children,
                                                                                vendorCode: splitName && splitName.length > 0 && splitName[0]
                                                                            })
                                                                        }
                                                                        else {
                                                                            setTableShow(false)

                                                                            setData({
                                                                                ...data,
                                                                                vendor: '',
                                                                                vendorName: '',
                                                                                vendorCode: ''
                                                                            })
                                                                        }
                                                                    }}
                                                                    value={data.vendor || undefined}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    style={{ width: '100%', marginTop: "5px" }}
                                                                >
                                                                    {vendor && vendor.length && vendor.map((item, i) => {
                                                                        return (
                                                                            <Option key={item.unit_id
                                                                            }>
                                                                                {`${item.unit_code}-${item.unit_name}`
                                                                                }
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className='col-md-12'>
                                                    <div className='row'>
                                                        <div className='col-md-4'></div>
                                                        <div className='col-md-4 text-center'>
                                                            {/* <Button type="primary" shape="round" className='addbutton' style={{ marginRight: "10px" }}
                                                    onClick={onCreate}
                                                >

                                                    Create Certificate
                                                </Button> */}
                                                            <Button type="primary" shape="round" className='addbutton' style={{ marginTop: "5px", marginRight: '30%' }}
                                                                icon={<EyeOutlined />} onClick={onshow}
                                                            >
                                                                Show
                                                            </Button>

                                                        </div>
                                                        <div className='col-md-4'></div>
                                                    </div>
                                                </div>
                                            </Panel>
                                        </Collapse>


                                    </div>
                                    {/* </div> */}

                                    {tableshow == true ? <div className="col-md-12" style={{ marginTop: "1px", padding: '10px' }}>
                                        <Table
                                            size={'small'}
                                            // className='userprivclass'
                                            // id='service-user-priv'
                                            className={localStorage.getItem("currentTheme")}
                                            columns={columns}
                                            dataSource={tabledatas.certificate_compliances
                                            }
                                            bordered
                                            // fixedHeader={dataTableProperties.fixedHeader}
                                            // scroll={{ x: 1500, y: 1000 }}
                                            pagination={false} />
                                        <Button type="primary" shape="round" className='addbutton' style={{ background: "#198754", borderColor: "#00FF7F", marginTop: '1%', marginLeft: '90%' }}
                                            icon={<PlayCircleOutlined />} size='default'
                                            onClick={onSubmitData}

                                        >
                                            Submit
                                        </Button>
                                    </div> : false}

                                </div>
                            </div>
                        </div>
                    </div> :
                    <div className="container-fluid pt-1">
                        {/* <div className="row">
                            <div className="col-12"> */}




                        <div className="card">
                            <div className="card-body approveCard"  >
                                <div className={"col-12 " + localStorage.getItem('currentTheme')}>
                                    <Collapse accordion defaultActiveKey={["1"]}>
                                        <Panel header="Assignee Details" key="1"
                                        // extra={tableshow == true ? genextra() : false}
                                        >
                                            <div className="row" style={{ marginLeft: '1%' }}>
                                                <div className="column" style={{ flex: '30%' }}>
                                                    <div className="row">
                                                        <div className="row-md-6">
                                                            <div className="row-md-2">   <label htmlFor=""><b>Vendor Code:</b></label></div>
                                                            <div className="row-md-4"><label>{vendorDetails.code}</label></div>
                                                        </div>
                                                        <div className="row-md-6 mt-2">
                                                            <div className="row-md-2">   <label htmlFor=""><b>Vendor Name:</b></label></div>
                                                            <div className="row-md-4"><label>{vendorDetails.name}</label></div>
                                                        </div>
                                                        <div className="row-md-6 mt-2">
                                                            <div className="row-md-2">   <label htmlFor=""><b>Invoice Number:</b></label></div>
                                                            <div className="row-md-4"><label>{vendorDetails.invoice_no}</label></div>
                                                        </div>
                                                        <div className="row-md-6 mt-2">
                                                            <div className="row-md-2">   <label htmlFor=""><b>Invoice Date:</b></label></div>
                                                            <div className="row-md-4"> <label>{vendorDetails.invoice_date}</label></div>
                                                        </div>
                                                        <div className="row-md-6 mt-2">
                                                            <div className="row-md-2">   <label htmlFor=""><b>Certificate process for the month of:</b></label></div>
                                                            <div className="row-md-4"> <label>{vendorDetails.month}</label></div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="column" style={{
                                                    flex: "30%",
                                                }}>

                                                    <div className="row">
                                                        <div style={{ height: '100px', overflowY: assigneeDocument && assigneeDocument.length > 2 ? "scroll" : "hidden" }}>
                                                            <table>
                                                                <thead>
                                                                    <tr>

                                                                        <th >Assignee_Documents</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody style={{ marginTop: '10%' }}>
                                                                    {

                                                                        assigneeDocument && assigneeDocument.length > 0 && assigneeDocument.map((item, i) => {
                                                                            return <tr> <p>{item}    <Button type="primary" class='fa fa-download text-primary c-pointer'
                                                                                icon={<DownloadOutlined />} size='small'
                                                                            // onClick={(e) => { download(item.file_name) }}
                                                                            >

                                                                            </Button> &nbsp;  <Button type="primary" class='fa fa-times text-primary removeicon'
                                                                                icon={< CloseOutlined />} size='small'
                                                                                onClick={(e) => { Remove(item) }}
                                                                            >
                                                                                </Button>
                                                                            </p>
                                                                            </tr>





                                                                        })
                                                                    }

                                                                </tbody>
                                                            </table>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="column" style={{
                                                    flex: "30%",
                                                }}>

                                                    <div className="row"  >
                                                        <div className="div" style={{ height: '100px', width: '250px', overflowY: assigneeLabel && assigneeLabel.length > 3 ? "scroll" : "hidden" }}>

                                                            <table>
                                                                <thead>
                                                                    <tr>

                                                                        <th>Label_Info</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody >
                                                                    {assigneeLabel && assigneeLabel.length > 0 && assigneeLabel.map((item) => {
                                                                        return (
                                                                            <>
                                                                                <tr>
                                                                                    <td>{item.label}</td>
                                                                                    <td>{item.value}</td>
                                                                                </tr>

                                                                            </>

                                                                        )
                                                                    })
                                                                    }

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Panel>
                                    </Collapse>
                                    <Collapse accordion defaultActiveKey={["1"]}>
                                        <Panel header="Concurrer Details" key="1"
                                        // extra={tableshow == true ? genextra() : false}
                                        >
                                            {sessionArray && sessionArray.cmpf_user_category == 1 ?

                                                <div className="row" style={{ marginLeft: '1%' }}>

                                                    <div className="column" style={{
                                                        flex: "30%",
                                                    }}>

                                                        <div className="row">
                                                            <div style={{ height: '100px', overflowY: concurrerDocument && concurrerDocument.length > 2 ? "scroll" : "hidden" }}>
                                                                <table>
                                                                    <thead>
                                                                        <tr>

                                                                            <th >Concurrer_Documents</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody style={{ marginTop: '10%' }}>
                                                                        {concurrerDocument && concurrerDocument.length > 0 && concurrerDocument.map((item) => {
                                                                            return (
                                                                                <>
                                                                                    <tr> <p>{item}    <Button type="primary" class='fa fa-download text-primary c-pointer'
                                                                                        icon={<DownloadOutlined />} size='small'
                                                                                    // onClick={(e) => { download(item.file_name) }}
                                                                                    >

                                                                                    </Button> &nbsp;
                                                                                        <Button type="primary" class='fa fa-times text-primary removeicon'
                                                                                            icon={< CloseOutlined />} size='small'
                                                                                            onClick={(e) => { Remove(item) }}
                                                                                        >
                                                                                        </Button>
                                                                                    </p>
                                                                                    </tr>

                                                                                </>

                                                                            )
                                                                        })
                                                                        }

                                                                    </tbody>
                                                                </table>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="column" style={{
                                                        flex: "30%",
                                                    }}>

                                                        <div className="row"  >
                                                            <div className="div" style={{ height: '100px', width: '250px', overflowY: concurrerLabel && concurrerLabel.length > 3 ? "scroll" : "hidden" }}>
                                                                <table>
                                                                    <thead>
                                                                        <tr>

                                                                            <th>Label_Info</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody >
                                                                        {concurrerLabel && concurrerLabel.length > 0 && concurrerLabel.map((item) => {
                                                                            return (
                                                                                <>
                                                                                    <tr>
                                                                                        <td>{item.label}</td>
                                                                                        <td>{item.value}</td>
                                                                                    </tr>

                                                                                </>

                                                                            )
                                                                        })
                                                                        }

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :

                                                <div className="row" style={{ marginLeft: '1%' }}>

                                                    <div className="column" style={{
                                                        flex: "30%",
                                                    }}>

                                                        <div className="row">

                                                            <label htmlFor=""><b>Upload Documents:</b></label>

                                                            <input type="file" multiple name="file"
                                                                onChange={(e) => {
                                                                    SavepastRecordOnChange(e)
                                                                }}
                                                                id="" />
                                                            <p>(Maximum 25MB per file/task)</p>


                                                            <label htmlFor=""><b>Attached Documents:</b></label><br />
                                                            {fileupload && fileupload.length > 0 && fileupload.map((item, i) => {

                                                                return <p>{item.file_name}    <Button type="primary" class='fa fa-download text-primary c-pointer'
                                                                    icon={<DownloadOutlined />} size='small'
                                                                    onClick={(e) => { download(item.file_name) }}
                                                                    style={{ marginTop: '2%' }}  >
                                                                </Button> &nbsp;

                                                                    <Button type="primary" class='fa fa-times text-primary removeicon'
                                                                        icon={< CloseOutlined />} size='small'
                                                                        onClick={(e) => { Remove(item) }}
                                                                    >
                                                                    </Button></p>
                                                            })}
                                                            <Button type="primary" shape="round" className='addbutton' style={{ background: "#198754", width: '50%', borderColor: "#00FF7F", marginTop: '10%' }}
                                                                icon={<PlayCircleOutlined />} size='default' onClick={onSubmit}

                                                            >
                                                                Submit
                                                            </Button>

                                                        </div>
                                                    </div>
                                                    <div className="column" style={{ flex: '30%' }}>
                                                        <div className="row" style={{ height: '100px', overflowY: ApproverDocument && ApproverDocument.length > 2 ? "scroll" : "hidden" }}>


                                                            <div >
                                                                <table>
                                                                    <thead>
                                                                        <tr>

                                                                            <th >Document</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody style={{ marginTop: '10%' }}>
                                                                        {ApproverDocument && ApproverDocument.length > 0 && ApproverDocument.map((item) => {
                                                                            return (
                                                                                <>
                                                                                    <tr> <p>{item}    <Button type="primary" class='fa fa-download text-primary c-pointer'
                                                                                        icon={<DownloadOutlined />} size='small'
                                                                                    // onClick={(e) => { download(item.file_name) }}
                                                                                    >

                                                                                    </Button> &nbsp;
                                                                                        <Button type="primary" class='fa fa-times text-primary removeicon'
                                                                                            icon={< CloseOutlined />} size='small'
                                                                                            onClick={(e) => { Remove(item) }}
                                                                                        >
                                                                                        </Button>
                                                                                    </p>
                                                                                    </tr>

                                                                                </>

                                                                            )
                                                                        })
                                                                        }

                                                                    </tbody>
                                                                </table>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="column" style={{
                                                        flex: "30%",
                                                    }}>

                                                        <div className="row">
                                                            <Button type="primary" shape='round' className='addbutton' style={{ background: "green", width: '50%', marginLeft: '15%' }}
                                                                onClick={(e) => {
                                                                    if (MappingDetail && MappingDetail.lbl_master && MappingDetail.lbl_master.length > 0) {
                                                                        setLabelModal(true)
                                                                        const payload2 = [
                                                                            authtoken,
                                                                            {
                                                                                "session_token": authtoken,
                                                                                "request": [
                                                                                    "GetLabelList",
                                                                                    {}
                                                                                ]
                                                                            }
                                                                        ]
                                                                        getLabelList({
                                                                            payload: payload2,
                                                                            paramid: paramid
                                                                        })
                                                                    }
                                                                    else {
                                                                        Toaster.warning("No Label")
                                                                    }
                                                                }}
                                                                icon={<PlusOutlined />} size='default'>
                                                                Insert Label
                                                            </Button>
                                                            {approveLabel && approveLabel.length > 0 ?
                                                                <div className="row-md-6 mt-3" style={{ height: '120px', width: '300px', overflowY: labelSubmit.length > 2 ? "scroll" : "hidden" }}>
                                                                    <table >
                                                                        <tbody>
                                                                            {approveLabel && approveLabel.length > 0 && approveLabel.map((item, i) => {
                                                                                let k = approveLabel[i]
                                                                                let temp = []
                                                                                for (let j in approveLabel[i]) {
                                                                                    return (
                                                                                        <tr>
                                                                                            <td>


                                                                                                <label htmlFor="">{j}</label>
                                                                                            </td>
                                                                                            <td>


                                                                                                <Input type="text" autocomplete="off" autoFocus={true} id={j} className='form-control' name="emp_name" key={updatevalue[i]}
                                                                                                    // onChange={(e) => {
                                                                                                    //     let temp = []
                                                                                                    //     console.log(e.target.value, 'e.target.value');
                                                                                                    //     //  approveLabel[i][j] = e.target.value
                                                                                                    //     updatevalue[i] = e.target.value
                                                                                                    //     setupdatevalue(updatevalue)
                                                                                                    //     console.log(temp, 'temp');
                                                                                                    // }} 

                                                                                                    onChange={(e) => {
                                                                                                        console.log(approveLabel[i], 'approveLabel[i]');
                                                                                                        let array = approveLabel
                                                                                                        // let temp = []
                                                                                                        for (let k in array) {
                                                                                                            for (let l in array[k]) {
                                                                                                                console.log(l, 'llll')
                                                                                                                console.log(e.target.id, 'iddddddddd');;

                                                                                                                if (l == e.target.id) {

                                                                                                                    temp.push({

                                                                                                                        [l]: e.target.value,



                                                                                                                    })

                                                                                                                }
                                                                                                                else {

                                                                                                                    temp.push(array[k])

                                                                                                                }

                                                                                                            }
                                                                                                        }
                                                                                                        console.log(temp, 'temp7777');
                                                                                                        setApproveLabel(temp)
                                                                                                        // setEmailvalue(temp)

                                                                                                    }}


                                                                                                    style={{ width: "80%", marginLeft: '12%' }} />

                                                                                                {Formvalidator1.current.message(
                                                                                                    'approveLabel',
                                                                                                    approveLabel[i][j],
                                                                                                    'required',
                                                                                                    {
                                                                                                        className: `invalid-feedback ${certificateFormSubmit ? 'show' : 'hide'}`,
                                                                                                        messages: {
                                                                                                            required: 'Please provide valid additional info',

                                                                                                        }
                                                                                                    })}
                                                                                            </td>

                                                                                            <td>

                                                                                                <Button style={{ marginLeft: '2%' }} id={j} icon={<CloseOutlined />} onClick={(e) => {
                                                                                                    let array = approveLabel
                                                                                                    for (let j in array) {
                                                                                                        if (i == j) {
                                                                                                            array.splice(i, 1);
                                                                                                        }
                                                                                                    }
                                                                                                    setApproveLabel([...array])
                                                                                                    // let temp = labelSubmit
                                                                                                    // console.log('sneha');
                                                                                                    // // if (labelSubmit[i] === item) {
                                                                                                    // temp.splice(i, 1)
                                                                                                    // // }
                                                                                                    // // console.log(temp, 'temptemptemp');
                                                                                                    // setLabelSubmit([...temp])




                                                                                                    // let array = approveLabel

                                                                                                    // let temp = []

                                                                                                    // for (let k in array) {
                                                                                                    //     for (let l in array[k]) {
                                                                                                    //         console.log(l, 'llll')
                                                                                                    //         console.log(e.target.id, 'iddddddddd');;

                                                                                                    //         if (l == e.target.id) {

                                                                                                    //             array.splice(array[k], i)


                                                                                                    //         }
                                                                                                    //         setApproveLabel(approveLabel)
                                                                                                    //     }
                                                                                                    // }
                                                                                                    console.log(labelSubmit, 'labelSubmit7777');
                                                                                                }}></Button>




                                                                                            </td>

                                                                                        </tr>
                                                                                    )
                                                                                }

                                                                            })}

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                : false}
                                                        </div>
                                                    </div>

                                                </div>
                                            }
                                        </Panel>
                                    </Collapse>
                                    {/* <Card className='vendorCard' title="Assignee Details" style={{ borderRadius: "15px" }} headStyle={{ borderRadius: "15px", height: '10px', background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>
                                    <div className="row">
                                        <div className="column" style={{ flex: '30%' }}>
                                            <div className="row">
                                                <div className="row-md-6">
                                                    <div className="row-md-2">   <label htmlFor=""><b>Vendor Code:</b></label></div>
                                                    <div className="row-md-4"><label>{vendorDetails.code}</label></div>
                                                </div>
                                                <div className="row-md-6 mt-2">
                                                    <div className="row-md-2">   <label htmlFor=""><b>Vendor Name:</b></label></div>
                                                    <div className="row-md-4"><label>{vendorDetails.name}</label></div>
                                                </div>
                                                <div className="row-md-6 mt-2">
                                                    <div className="row-md-2">   <label htmlFor=""><b>Invoice Number:</b></label></div>
                                                    <div className="row-md-4"><label>{vendorDetails.invoice_no}</label></div>
                                                </div>
                                                <div className="row-md-6 mt-2">
                                                    <div className="row-md-2">   <label htmlFor=""><b>Invoice Date:</b></label></div>
                                                    <div className="row-md-4"> <label>{vendorDetails.invoice_date}</label></div>
                                                </div>
                                                <div className="row-md-6 mt-2">
                                                    <div className="row-md-2">   <label htmlFor=""><b>Certificate process for the month of:</b></label></div>
                                                    <div className="row-md-4"> <label>{vendorDetails.month}</label></div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="column" style={{
                                            flex: "30%",
                                        }}>

                                            <div className="row">
                                                <div style={{ height: '100px', overflowY: assigneeDocument && assigneeDocument.length > 2 ? "scroll" : "hidden" }}>
                                                    <table>
                                                        <thead>
                                                            <tr>

                                                                <th >Assignee_Documents</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style={{ marginTop: '10%' }}>
                                                            {

                                                                assigneeDocument && assigneeDocument.length > 0 && assigneeDocument.map((item, i) => {
                                                                    return <tr> <p>{item}    <Button type="primary" class='fa fa-download text-primary c-pointer'
                                                                        icon={<DownloadOutlined />} size='small'
                                                                    // onClick={(e) => { download(item.file_name) }}
                                                                    >

                                                                    </Button> &nbsp;  <Button type="primary" class='fa fa-times text-primary removeicon'
                                                                        icon={< CloseOutlined />} size='small'
                                                                        onClick={(e) => { Remove(item) }}
                                                                    >
                                                                        </Button>
                                                                    </p>
                                                                    </tr>





                                                                })
                                                            }

                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="column" style={{
                                            flex: "30%",
                                        }}>

                                            <div className="row"  >
                                                <div className="div" style={{ height: '100px', width: '250px', overflowY: assigneeLabel && assigneeLabel.length > 3 ? "scroll" : "hidden" }}>

                                                    <table>
                                                        <thead>
                                                            <tr>

                                                                <th>Label_Info</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody >
                                                            {assigneeLabel && assigneeLabel.length > 0 && assigneeLabel.map((item) => {
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td>{item.label}</td>
                                                                            <td>{item.value}</td>
                                                                        </tr>

                                                                    </>

                                                                )
                                                            })
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card> */}
                                    {/* <Card className='vendorCard' title="Concurrer Details" style={{ borderRadius: "15px" }} headStyle={{ borderRadius: "15px", height: '10px', background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>

                                    </Card> */}
                                    {sessionArray && sessionArray.cmpf_user_category == 1 ?
                                        <Card className='vendorCard' title="Approver Details" style={{ borderRadius: "15px" }} headStyle={{ borderRadius: "15px", height: '10px', background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>

                                            <div className="row">

                                                <div className="column" style={{
                                                    flex: "30%",
                                                }}>

                                                    <div className="row">

                                                        <label htmlFor=""><b>Upload Documents:</b></label>

                                                        <input type="file" multiple name="file"
                                                            onChange={(e) => {
                                                                SavepastRecordOnChange(e)
                                                            }}
                                                            id="" />
                                                        <p>(Maximum 25MB per file/task)</p>


                                                        <label htmlFor=""><b>Attached Documents:</b></label><br />
                                                        {fileupload && fileupload.length > 0 && fileupload.map((item, i) => {

                                                            return <p>{item.file_name}    <Button type="primary" class='fa fa-download text-primary c-pointer'
                                                                icon={<DownloadOutlined />} size='small'
                                                                onClick={(e) => { download(item.file_name) }}
                                                                style={{ marginTop: '2%' }}  >
                                                            </Button> &nbsp;

                                                                <Button type="primary" class='fa fa-times text-primary removeicon'
                                                                    icon={< CloseOutlined />} size='small'
                                                                    onClick={(e) => { Remove(item) }}
                                                                >
                                                                </Button></p>
                                                        })}
                                                        <Button type="primary" shape="round" className='addbutton' style={{ background: "#198754", width: '30%', marginLeft: '130%', borderColor: "#00FF7F", marginTop: '10%' }}
                                                            icon={<PlayCircleOutlined />} size='default' onClick={onSubmit}

                                                        >
                                                            Submit
                                                        </Button>

                                                    </div>
                                                </div>
                                                <div className="column" style={{ flex: '30%' }}>
                                                    <div className="row" style={{ height: '100px', overflowY: ApproverDocument && ApproverDocument.length > 2 ? "scroll" : "hidden" }}>


                                                        <div >
                                                            <table>
                                                                <thead>
                                                                    <tr>

                                                                        <th >Document</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody style={{ marginTop: '10%' }}>
                                                                    {ApproverDocument && ApproverDocument.length > 0 && ApproverDocument.map((item) => {
                                                                        return (
                                                                            <>
                                                                                <tr> <p>{item}    <Button type="primary" class='fa fa-download text-primary c-pointer'
                                                                                    icon={<DownloadOutlined />} size='small'
                                                                                // onClick={(e) => { download(item.file_name) }}
                                                                                >

                                                                                </Button> &nbsp;
                                                                                    <Button type="primary" class='fa fa-times text-primary removeicon'
                                                                                        icon={< CloseOutlined />} size='small'
                                                                                        onClick={(e) => { Remove(item) }}
                                                                                    >
                                                                                    </Button>
                                                                                </p>
                                                                                </tr>

                                                                            </>

                                                                        )
                                                                    })
                                                                    }

                                                                </tbody>
                                                            </table>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="column" style={{
                                                    flex: "30%",
                                                }}>

                                                    <div className="row">
                                                        <Button type="primary" shape='round' className='addbutton' style={{ background: "green", width: '50%', marginLeft: '15%' }}
                                                            onClick={(e) => {
                                                                if (MappingDetail && MappingDetail.lbl_master && MappingDetail.lbl_master.length > 0) {
                                                                    setLabelModal(true)
                                                                    const payload2 = [
                                                                        authtoken,
                                                                        {
                                                                            "session_token": authtoken,
                                                                            "request": [
                                                                                "GetLabelList",
                                                                                {}
                                                                            ]
                                                                        }
                                                                    ]
                                                                    getLabelList({
                                                                        payload: payload2,
                                                                        paramid: paramid
                                                                    })
                                                                }
                                                                else {
                                                                    Toaster.warning("No Label")
                                                                }
                                                            }}
                                                            icon={<PlusOutlined />} size='default'>
                                                            Insert Label
                                                        </Button>
                                                        {approveLabel && approveLabel.length > 0 ?
                                                            <div className="row-md-6 mt-3" style={{ height: '120px', width: '300px', overflowY: labelSubmit.length > 2 ? "scroll" : "hidden" }}>
                                                                <table >
                                                                    <tbody>
                                                                        {approveLabel && approveLabel.length > 0 && approveLabel.map((item, i) => {
                                                                            let k = approveLabel[i]
                                                                            for (let j in approveLabel[i]) {
                                                                                return (
                                                                                    <tr>
                                                                                        <td>


                                                                                            <label htmlFor="">{j}</label>
                                                                                        </td>
                                                                                        <td>


                                                                                            <Input type="text" autocomplete="off" autoFocus={true} id={j} className='form-control' name="emp_name" key={updatevalue[i]}
                                                                                                // onChange={(e) => {
                                                                                                //     let temp = []
                                                                                                //     console.log(e.target.value, 'e.target.value');
                                                                                                //     //  approveLabel[i][j] = e.target.value
                                                                                                //     updatevalue[i] = e.target.value
                                                                                                //     setupdatevalue(updatevalue)
                                                                                                //     console.log(temp, 'temp');
                                                                                                // }} 

                                                                                                onChange={(e) => {
                                                                                                    console.log(approveLabel[i], 'approveLabel[i]');
                                                                                                    let array = approveLabel
                                                                                                    let temp = []
                                                                                                    for (let k in array) {
                                                                                                        for (let l in array[k]) {
                                                                                                            console.log(l, 'llll')
                                                                                                            console.log(e.target.id, 'iddddddddd');;

                                                                                                            if (l == e.target.id) {

                                                                                                                temp.push({

                                                                                                                    [l]: e.target.value,



                                                                                                                })

                                                                                                            }
                                                                                                            else {

                                                                                                                temp.push(array[k])

                                                                                                            }

                                                                                                        }
                                                                                                    }
                                                                                                    console.log(temp, 'temp7777');
                                                                                                    setApproveLabel(temp)
                                                                                                    // setEmailvalue(temp)

                                                                                                }}


                                                                                                style={{ width: "80%", marginLeft: '12%' }} value={approveLabel[i][j]} />
                                                                                        </td>

                                                                                        <td>

                                                                                            <Button style={{ marginLeft: '2%' }} id={j} icon={<CloseOutlined />} onClick={(e) => {
                                                                                                let array = approveLabel
                                                                                                for (let j in array) {
                                                                                                    if (i == j) {
                                                                                                        array.splice(i, 1);
                                                                                                    }
                                                                                                }
                                                                                                setApproveLabel([...array])
                                                                                                // let temp = labelSubmit
                                                                                                // console.log('sneha');
                                                                                                // // if (labelSubmit[i] === item) {
                                                                                                // temp.splice(i, 1)
                                                                                                // // }
                                                                                                // // console.log(temp, 'temptemptemp');
                                                                                                // setLabelSubmit([...temp])




                                                                                                // let array = approveLabel

                                                                                                // let temp = []

                                                                                                // for (let k in array) {
                                                                                                //     for (let l in array[k]) {
                                                                                                //         console.log(l, 'llll')
                                                                                                //         console.log(e.target.id, 'iddddddddd');;

                                                                                                //         if (l == e.target.id) {

                                                                                                //             array.splice(array[k], i)


                                                                                                //         }
                                                                                                //         setApproveLabel(approveLabel)
                                                                                                //     }
                                                                                                // }
                                                                                                console.log(labelSubmit, 'labelSubmit7777');
                                                                                            }}></Button>




                                                                                        </td>

                                                                                    </tr>
                                                                                )
                                                                            }

                                                                        })}

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            : false}
                                                    </div>
                                                </div>

                                            </div>
                                        </Card> : false}
                                </div>

                            </div>

                            {/* </div>
                            </div> */}
                        </div>

                    </div>}
            </div>

            <Modal title='Compliance List' className={"remark-header modelradius add-service-prv c  " + localStorage.getItem('currentTheme')} onCancel={handleCancel} visible={complianceStatusModal} footer={null} width={350}>
                <p><b>{monthData}</b></p>
                <Table
                    size={'small'}
                    // id='service-user-priv'
                    className={localStorage.getItem("currentTheme")}
                    // className='userprivclass'
                    columns={columns1}
                    dataSource={complianceStatusModals.compliancestatus_certifications}
                    bordered
                    // fixedHeader={dataTableProperties.fixedHeader}
                    // scroll={{ x: 1500, y: 1000 }}
                    pagination={false} />


            </Modal>
            <Modal title='Details Enclosed' className={"remark-header modelradius add-service-prv complianceStatus  " + localStorage.getItem('currentTheme')} onCancel={handleCancel} visible={detailsModal} footer={null} width={500}>

                {AssigneeDetails && AssigneeDetails.length > 0 ? <>  <p style={{ fontWeight: 'bold' }}>{`Details Submitted by:${AssigneeDetails && AssigneeDetails.length > 0 && AssigneeDetails[0].cert_by}`}</p>
                    <div className="row">
                        <div className="column" style={{ flex: '50%' }}>
                            <div className="row">
                                <div className="row-md-6">
                                    <div className="row-md-2">   <label htmlFor=""><b>Vendor Code:</b></label></div>
                                    <div className="row-md-4"> <Input type="text" autocomplete="off" className='form-control' name="emp_name"
                                        value={vendorDetails.code}

                                        style={{ marginTop: "5px", width: "60%" }} /></div>
                                </div>
                                <div className="row-md-6">
                                    <div className="row-md-2">   <label htmlFor=""><b>Vendor Name:</b></label></div>
                                    <div className="row-md-4"> <Input type="text" autocomplete="off" className='form-control' name="emp_name"
                                        value={vendorDetails.name}

                                        style={{ marginTop: "5px", width: "60%" }} /></div>
                                </div>
                                <div className="row-md-6">
                                    <div className="row-md-2">   <label htmlFor=""><b>Invoice Number:</b></label></div>
                                    <div className="row-md-4"> <Input type="text" autocomplete="off" className='form-control' name="emp_name"
                                        value={vendorDetails.invoice_no}

                                        style={{ marginTop: "5px", width: "60%" }} /></div>
                                </div>
                                <div className="row-md-6">
                                    <div className="row-md-2">   <label htmlFor=""><b>Invoice Date:</b></label></div>
                                    <div className="row-md-4"> <Input type="text" autocomplete="off" className='form-control' name="emp_name"
                                        value={vendorDetails.invoice_date}

                                        style={{ marginTop: "5px", width: "60%" }} /></div>
                                </div>
                                <div className="row-md-6">
                                    <div className="row-md-2">   <label htmlFor=""><b>Certificate process for the month of:</b></label></div>
                                    <div className="row-md-4"> <Input type="text" autocomplete="off" className='form-control' name="emp_name"
                                        value={vendorDetails.month}

                                        style={{ marginTop: "5px", width: "60%" }} /></div>
                                </div>
                            </div>

                        </div>
                        <div className="column" style={{ flex: '50%' }}>

                            {AssigneeDetails && AssigneeDetails.length && AssigneeDetails[0].document_name && AssigneeDetails[0].document_name.length > 0 ? <div className="row">
                                <Card size='small' title='Assignee_Documents' headStyle={{ fontWeight: 'bold',height: '10px', background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }} style={{ height: '150px' }}>
                                    <div className="row" style={{ height: '100px', width: '250px', overflowY: AssigneeDetails && AssigneeDetails.length > 0 && AssigneeDetails[0].document_name && AssigneeDetails[0].document_name.length > 2 ? "scroll" : "hidden" }}>


                                        {/* <thead>
                                            <tr>

                                                <th >Concurrer_Documents</th>
                                            </tr>
                                        </thead> */}

                                        {AssigneeDetails && AssigneeDetails.length > 0 && AssigneeDetails[0].document_name && AssigneeDetails[0].document_name.length > 0 && AssigneeDetails[0].document_name.map((item) => {
                                            return (
                                                <>
                                                    <p>{item}    <Button type="primary" class='fa fa-download text-primary c-pointer'
                                                        icon={<DownloadOutlined />} size='small'
                                                    // onClick={(e) => { download(item.file_name) }}
                                                    >

                                                    </Button> &nbsp;
                                                    </p>


                                                </>

                                            )
                                        })
                                        }


                                    </div>
                                </Card>
                            </div> : false}
                            {assigneeLabel && assigneeLabel.length > 0 ?

                                <Card size='small' title='Label_Info' headStyle={{ height: '10px', fontWeight: 'bold', background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }} style={{ marginLeft: '10%', height: '150px' }}>
                                    <div className="row" style={{fontWeight: 'bold', height: '100px', width: '250px', overflowY: assigneeLabel && assigneeLabel.length > 0 && assigneeLabel[0].document_name && assigneeLabel[0].document_name.length > 2 ? "scroll" : "hidden" }}>


                                        {/* <thead>
        <tr>

            <th >Concurrer_Documents</th>
        </tr>
    </thead> */}

                                        {assigneeLabel && assigneeLabel.length > 0 && assigneeLabel.map((item) => {
                                            for (let k in item) {
                                                console.log(k, 'aishuuuu');
                                                return (
                                                    <>
                                                        <div className="col-md-12">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <p>{k}</p>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <p>{item[k]}</p>
                                                                </div>
                                                            </div>
                                                        </div>




                                                    </>

                                                )
                                            }
                                        })
                                        }


                                    </div>
                                </Card>
                                // <div className="row" >
                                //     <div className="div" style={{ height: '100px', width: '250px', overflowY: assigneeLabel && assigneeLabel.length > 3 ? "scroll" : "hidden" }} >
                                //         <table>
                                //             <thead>
                                //                 <tr>

                                //                     <th>Label_Info</th>
                                //                 </tr>
                                //             </thead>
                                //             <tbody >
                                //                 {assigneeLabel && assigneeLabel.length > 0 && assigneeLabel.map((item) => {
                                //                     for (let k in item) {
                                //                         console.log(k, 'aishuuuu');
                                //                         return (
                                //                             <>
                                //                                 <tr>
                                //                                     <td>{k}</td>
                                //                                     <td>{item[k]}</td>
                                //                                 </tr>

                                //                             </>

                                //                         )
                                //                     }
                                //                 })
                                //                 }

                                //             </tbody>
                                //         </table>
                                //     </div>
                                // </div>
                                : false}
                        </div>
                    </div></> : false}
                {concurrerDetails && concurrerDetails.length > 0 ? <div style={{ marginTop: '5%' }}>    <p style={{ fontWeight: 'bold' }}>{`Details Submitted by:${concurrerDetails && concurrerDetails.length > 0 && concurrerDetails[0].cert_by}`}</p>
                    <div className="row">


                        {concurrerDetails && concurrerDetails.length && concurrerDetails[0].document_name && concurrerDetails[0].document_name.length > 0 ?
                            <div className="column" style={{ flex: '50%' }}>


                                <Card size='small' title='Concurrer_Documents' headStyle={{ fontWeight: 'bold',height: '10px', background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }} style={{ height: '150px' }}>
                                    <div className="row" style={{ height: '100px', width: '250px', overflowY: concurrerDetails && concurrerDetails.length > 0 && concurrerDetails[0].document_name && concurrerDetails[0].document_name.length > 2 ? "scroll" : "hidden" }}>


                                        {/* <thead>
                                            <tr>

                                                <th >Concurrer_Documents</th>
                                            </tr>
                                        </thead> */}

                                        {concurrerDetails && concurrerDetails.length > 0 && concurrerDetails[0].document_name && concurrerDetails[0].document_name.length > 0 && concurrerDetails[0].document_name.map((item) => {
                                            return (
                                                <>
                                                    <p>{item}    <Button type="primary" class='fa fa-download text-primary c-pointer'
                                                        icon={<DownloadOutlined />} size='small'
                                                    // onClick={(e) => { download(item.file_name) }}
                                                    >

                                                    </Button> &nbsp;
                                                    </p>


                                                </>

                                            )
                                        })
                                        }


                                    </div>
                                </Card>




                            </div> : false}
                        {concurrerLabel && concurrerLabel.length > 0 ?
                            <div className="column" style={{ flex: '50%' }}>
                                <Card size='small' title='Label_Info' headStyle={{fontWeight: 'bold', height: '10px', background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }} style={{ marginLeft: '10%', height: '150px' }}>
                                    <div className="row" style={{ height: '100px', width: '250px', overflowY: concurrerDetails && concurrerDetails.length > 0 && concurrerDetails[0].document_name && concurrerDetails[0].document_name.length > 2?"scroll" : "hidden" }}>


                                        {/* <thead>
                                            <tr>

                                                <th >Concurrer_Documents</th>
                                            </tr>
                                        </thead> */}

                                        {concurrerLabel && concurrerLabel.length > 0 && concurrerLabel.map((item) => {
                                            for (let k in item) {
                                                console.log(k, 'aishuuuu');
                                                return (
                                                    <>
                                                        <div className="col-md-12">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <p>{k}</p>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <p>{item[k]}</p>
                                                                </div>
                                                            </div>
                                                        </div>




                                                    </>

                                                )
                                            }
                                        })
                                        }


                                    </div>
                                </Card>
                                {/* <div className="row" >
                                    <div className="div" style={{ height: '100px', width: '250px', overflowY: concurrerLabel && concurrerLabel.length > 3 ? "scroll" : "hidden" }} >

                                        <table>
                                            <thead>
                                                <tr>

                                                    <th>Label_Info</th>
                                                </tr>
                                            </thead>
                                            <tbody >
                                                {concurrerLabel && concurrerLabel.length > 0 && concurrerLabel.map((item) => {
                                                    for (let k in item) {
                                                        console.log(k, 'aishuuuu');
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td>{k}</td>
                                                                    <td>{item[k]}</td>
                                                                </tr>

                                                            </>

                                                        )
                                                    }
                                                })
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div> */}
                            </div>
                            : false}
                    </div></div> : false}
                {approverDetails && approverDetails.length > 0 ? <div style={{ marginTop: '5%' }}> <p style={{ fontWeight: 'bold' }}>{`Details Submitted by:${approverDetails && approverDetails.length > 0 && approverDetails[0].cert_by}`}</p>
                    <div className="row">
                        {approverDetails && approverDetails.length > 0 && approverDetails[0].document_name && approverDetails[0].document_name.length > 0 ?
                            <div className="column" style={{ flex: '50%' }}>
                                <Card size='small' title='DocumentName' headStyle={{ fontWeight: 'bold',height: '10px', background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }} style={{ height: '150px' }}>
                                    <div className="row" style={{ height: '100px', width: '250px', overflowY: approverDetails && approverDetails.length > 0 && approverDetails[0].document_name && approverDetails[0].document_name.length > 2 ? "scroll" : "hidden" }}>


                                        {/* <thead>
                                            <tr>

                                                <th >Concurrer_Documents</th>
                                            </tr>
                                        </thead> */}

                                        {approverDetails && approverDetails.length > 0 && approverDetails[0].document_name && approverDetails[0].document_name.length > 0 && approverDetails[0].document_name.map((item) => {
                                            return (
                                                <>
                                                    <p>{item}    <Button type="primary" class='fa fa-download text-primary c-pointer'
                                                        icon={<DownloadOutlined />} size='small'
                                                    // onClick={(e) => { download(item.file_name) }}
                                                    >

                                                    </Button> &nbsp;
                                                    </p>


                                                </>

                                            )
                                        })
                                        }


                                    </div>
                                </Card>



                                {/* <div className="row" style={{ height: '100px', width: '250px', overflowY: approverDetails && approverDetails.length > 0 && approverDetails[0].document_name && approverDetails[0].document_name.length > 2 ? "scroll" : "hidden" }}>


                                    <div >
                                        <table>
                                            <thead>
                                                <tr>

                                                    <th >DocumentName</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ marginTop: '10%' }}>
                                                {approverDetails && approverDetails.length > 0 && approverDetails[0].document_name && approverDetails[0].document_name.length > 0 && approverDetails[0].document_name.map((item) => {
                                                    return (
                                                        <>
                                                            <tr> <p>{item}    <Button type="primary" class='fa fa-download text-primary c-pointer'
                                                                icon={<DownloadOutlined />} size='small'
                                                            // onClick={(e) => { download(item.file_name) }}
                                                            >

                                                            </Button> &nbsp;
                                                            </p>
                                                            </tr>

                                                        </>

                                                    )
                                                })
                                                }

                                            </tbody>
                                        </table>

                                    </div>
                                </div> */}
                            </div> : false}
                        {approveLabelData && approveLabelData.length > 0 ?
                            <div className="column" style={{ flex: '50%' }}>
                                <Card size='small' title='Label_Info' headStyle={{fontWeight: 'bold', height: '10px', background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }} style={{ marginLeft: '10%', height: '150px' }}>
                                    <div className="row" style={{ height: '100px', width: '250px', overflowY: approveLabelData && approveLabelData.length > 0 && approveLabelData[0].document_name && approveLabelData[0].document_name.length > 2 ? "scroll" : "hidden" }}>


                                        {/* <thead>
                                            <tr>

                                                <th >Concurrer_Documents</th>
                                            </tr>
                                        </thead> */}

                                        {approveLabelData && approveLabelData.length > 0 && approveLabelData.map((item) => {
                                            for (let k in item) {
                                                console.log(k, 'aishuuuu');
                                                return (
                                                    <>
                                                        <div className="col-md-12">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <p>{k}</p>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <p>{item[k]}</p>
                                                                </div>
                                                            </div>
                                                        </div>




                                                    </>

                                                )
                                            }
                                        })
                                        }


                                    </div>
                                </Card>
                                {/* <div className="row" >
                                    <div className="div" style={{ height: '100px', width: '250px', overflowY: approveLabelData && approveLabelData.length > 3 ? "scroll" : "hidden" }}>
                                        <table>
                                            <thead>
                                                <tr>

                                                    <th>Label_Info</th>
                                                </tr>
                                            </thead>
                                            <tbody >
                                                {approveLabelData && approveLabelData.length > 0 && approveLabelData.map((item, j) => {
                                                    for (let k in item) {
                                                        console.log(k, 'aishuuuu');
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td>{k}</td>
                                                                    <td>{item[k]}</td>
                                                                </tr>

                                                            </>

                                                        )
                                                    }
                                                })
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div> */}

                            </div>
                            : false}
                    </div></div> : false}



                {/* <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-2">
                            <label>Vendor Code:</label>
                        </div>
                        <div className="col-md-4">  <input type='text' className='form-control' value={vendorDetails.code} ></input></div>
                        <div className="col-md-2">
                            <label>Certification process for the month of:</label>
                        </div>
                        <div className="col-md-4">  <input type='text' value={`${vendorDetails.month}-${vendorDetails.year}`} className='form-control' ></input></div>

                    </div>
                </div>
                <div className="col-md-12 mt-3">
                    <div className="row">
                        <div className="col-md-2">
                            <label>Vendor Name:</label>
                        </div>
                        <div className="col-md-4">  <input type='text' value={vendorDetails.name} className='form-control' ></input></div>
                        <div className="col-md-2">
                            <label>Attached Documents:</label>
                        </div>
                        <div className="col-md-4">  <input type='text' className='form-control' ></input></div>

                    </div>
                </div> */}
            </Modal>
            <Modal title='Select Additional Title' className={"remark-header modelradius add-service-prv labelModal  " + localStorage.getItem('currentTheme')} onCancel={handleCancel} visible={labelModal} footer={null} width={350}>
                <Table
                    size={'small'}
                    // id='service-user-priv'
                    className={localStorage.getItem("currentTheme")}
                    // className='userprivclass'
                    columns={labelcolumns}
                    dataSource={labelLists && labelLists.label_name
                    }
                    bordered
                    // fixedHeader={dataTableProperties.fixedHeader}
                    // scroll={{ x: 1500, y: 1000 }}
                    pagination={false}
                    onHeaderRow={(columns, index) => {

                        return {
                            onClick: (e) => {

                                let checked = e.target.checked;
                                addAllSelectedDataValues(
                                    checked,
                                );
                            }

                        };
                    }}

                />
                <div className="form-actions text-center popupbtncolour">


                    <Button type="primary" shape="round" className={'mt-2 addbutton ' + localStorage.getItem('currentTheme')}
                        icon={<PlayCircleOutlined />} size='default'
                        onClick={() => {
                            setLabelSubmit(labelCheckBox)
                            setLabelModal(false)
                            if (labelCheckBox.length > 0) {
                                let temp = []
                                temp = approveLabel
                                // for (let i in labelCheckBox) {
                                //     temp.push({
                                //         labelCheckBox[i]: ''
                                //     })
                                // }
                                // for (let k in approveLabel) {
                                //     for (let s in approveLabel[k]) {
                                labelCheckBox && labelCheckBox.map((item, i) => {
                                    // console.log(item !== k, 'item !== k');
                                    // console.log(item, 'ishu');
                                    // console.log(approveLabel[k][s], 'kkkkkkkk');
                                    // if (approveLabel[k][s] !== k) {
                                    temp.push({
                                        [item]: ''
                                    })
                                    // }
                                })
                                //     }
                                // }

                                setApproveLabel([...temp])
                            }
                        }
                        }
                    // onClick={userDetailsOnSubmit
                    // }
                    >
                        Submit
                    </Button>
                </div>

            </Modal>
            <Modal title='Compliance Status' className={"remark-header modelradius add-service-prv complianceStatus  " + localStorage.getItem('currentTheme')} onCancel={handleCancel} visible={searchModal} footer={null} width={350}>
                <Table
                    // className='userprivclass'

                    // id='service-user-priv'
                    className={localStorage.getItem("currentTheme")}
                    size={'small'}
                    columns={searchcolumns}
                    dataSource={searchDatas && searchDatas.cmplist}
                    bordered
                    // fixedHeader={dataTableProperties.fixedHeader}
                    // scroll={{ x: 1500, y: 1000 }}
                    pagination={false} />


            </Modal>

            <Modal title="Remarks List" footer={null} visible={remarksModal} className={"remark-header add-service-prv " + localStorage.getItem('currentTheme')}
                onOk={handleOk}
                onCancel={handleCancel}>
                <div style={{ height: '300px', overflowY: "scroll" }}>
                    <Table
                        size={'small'}
                        columns={RemarksColumn}
                        dataSource={Remarks}
                        bordered
                        pagination={false}
                    />
                </div>

            </Modal>
        </Fragment >

    )


}
export default connect(mapStateToProps, {
    getComplianceCertificateFilter,
    getApproveComplianceCertificateTable,
    getComplianceCertificateTable,
    viewComplianceStatus,
    viewComplianceDetails,
    viewApproveComplianceStatus,
    ApprovemappingDetails,
    mappingDetails,
    getLabelList,
    UploadFile,
    Downloadfile,
    approveCertificate,
    submitComplianceCertificate,
    searchCompliance,
    viewApproveComplianceDetails,
    getApproveComplianceCertificateFilter,
    AddAdditionalInformation,
    certificateMailData,
    RemarksData
})(CV_ApproveCompliances);