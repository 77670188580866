import { Fragment, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import Swal from 'sweetalert2';
import { Modal, Input, Table, Button, Space, Tooltip, DatePicker, Alert, Popover, Card, Switch, Progress, Avatar, Segmented, Collapse, Drawer, List } from 'antd';
import { providerlist, addprovider, updatestatus, updateblock, updateprovider } from '../../Store/Action/Master/Serviceprovider';
import moment from 'moment';
import { GetRemarksData } from '../../Store/Action/Transcations/Taskrecall'
import {
    LikeOutlined, DislikeOutlined, PlayCircleOutlined,
    PlusOutlined, InfoCircleOutlined, StopOutlined, ClockCircleOutlined, SearchOutlined, PlusCircleOutlined, ExclamationCircleTwoTone, CloseOutlined, FileSearchOutlined, CloseCircleOutlined, UpOutlined, FullscreenOutlined, CheckCircleOutlined, CheckOutlined, EditOutlined
} from '@ant-design/icons';
import ScrollToTop from 'react-scroll-to-top';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";



const mapStateToProps = (state) => ({
    lists: state.Serviceprovider.list.service_providers,
    filterrecall: state.recallfilters.recallfilters
})
const Serviceproviderlist = ({
    providerlist,
    lists,
    addprovider,
    updatestatus,
    updateblock,
    updateprovider,
    GetRemarksData,
    filterrecall: { recallremarks },
}) => {
    const _ = require("lodash");
    const { TextArea } = Input;
    const formValidator = useRef(new SimpleReactValidator());
    const formValidator1 = useRef(new SimpleReactValidator());
    const formValidator2 = useRef(new SimpleReactValidator());
    const location = useLocation();
    const paramid = location.pathname;
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const [AddFormSubmit2, setAddFormSubmit2] = useState(false);
    const isAuth = localStorage.getItem('isAuthenticated');
    const [fullscreen, setfullscreen] = useState(false);
    const authtoken = localStorage.getItem('authToken');
    const [modalVisible, SetmodalVisible] = useState(false);
    const [passvisible, Setpassvisible] = useState(false);
    const [blockvisible, Setblockvisible] = useState(false);
    const [filterText, setFilterText] = useState("");
    const [remarksvalue, Setremarksvalue] = useState();
    const [resetPaginationToggle, setResetPaginationToggle] = useState(
        false
    );
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true,
        pageSizeOptions: ['10', '30', '50']
    })
    const [remarksModal, setRemarksModal] = useState(false);
    const [remarks, setRemarks] = useState('')
    const [page, setPage] = useState(1);
    const [iconEnable, setIconEnable] = useState(false);
    const [clearText, setClearText] = useState('');
    const [type, setType] = useState('remove')
    const [activeArray, setActiveArray] = useState([])
    const [inActiveArray, setInActiveArray] = useState([])
    const [blockedArray, setBlockedArray] = useState([])
    const [unBlockedArray, setUnBlockedArray] = useState([])
    const [errorField, setErrorField] = useState('')
    const [emailField, setEmailField] = useState('')
    const [shortName, setShortName] = useState('')
    const [contactFrom, setContactFrom] = useState('')
    const [contactTo, setContactTo] = useState('')
    const [contactPerson, setContactPerson] = useState('')
    const [contactNumber, setContactNumber] = useState('')
    const [passwordata, Setpassword] = useState({
        passvalue: '',
        sp_id: '',
        active_status: ''
    })

    const [blockdata, Setblockdata] = useState({
        "sp_id": '',
        "is_blocked": '',
        "remarks": '',
        "password": ''
    })
    const [toggleButton, setToggleButton] = useState(true)
    const { Panel } = Collapse;

    const data = [
        {
            title: 'Ant Design Title 1',
        },
        {
            title: 'Ant Design Title 2',
        },
        {
            title: 'Ant Design Title 3',
        },
        {
            title: 'Ant Design Title 4',
        },
    ];

    const modalcancel = () => {
        setServiceProviderDetails({
            ...serviceProviderDetails,
            provider_name: '',
            contact_no1: '',
            contact_no2: '',
            contact_no: '',
            short_name: '',
            mobile_no: '',
            mobile_no1: '',
            Contract_from: '',
            Contract_to: '',
            contact_person: '',
            email: '',
            address: '',
        })
        setAddFormSubmit(false);
        // SetmodalVisible(false);
        setOpen(false);
    }
    const [open, setOpen] = useState(false);
    const addopen = () => {
        // SetmodalVisible(true);
        setOpen(true);
    }
    const columns1 = [
        {
            title: 'Service Provider',
            dataIndex: 's_p_name',
            key: 's_p_name',
            // ...getColumnSearchProps('s_p_name', 'Service Provider'),
            ellipsis: true,
            width: '100px',
            // filteredValue: filteredInfo.s_p_name || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        },
        {
            title: 'Status',
            key: 'status',
            align: "center",
            width: "50px",
            dataIndex: 'status',
            render: (text, record, index) => {
                return <p className='mb-0'>Blocked</p>
            },
        },
        {
            title: 'TimeLine',
            key: 'status',
            align: "center",
            width: "90px",
            dataIndex: 'status',
            render: (text, record, index) => {
                // return <Alert message={`${record.unblock_days} - Days left`} type="error" showIcon={<ClockCircleOutlined />} />
                // if (record.unblock_days <= 1) {
                //     return <p className='mb-0' style={{ color: '#fc4b6c' }}><ClockCircleOutlined className='I' id='I' />  {`${record.unblock_days} - Days left`}</p>
                // }
                // else {
                //     return <p className='mb-0' style={{ color: '#fc4b6c' }}><ClockCircleOutlined />  {`${record.unblock_days} - Days left`}</p>
                // }
                if (record.unblock_days >= 20) {
                    return <p className='mb-0 text-success'><ClockCircleOutlined />  {`${record.unblock_days} - Days left`}</p>
                }
                else if (record.unblock_days >= 6) {
                    return <p className='mb-0 text-primary'><ClockCircleOutlined />  {`${record.unblock_days} - Days left`}</p>
                }
                else if (record.unblock_days <= 5) {
                    return <p className='mb-0 text-danger'><ClockCircleOutlined className='I' id='I' /> &nbsp; {`${record.unblock_days} - Days left`}</p>
                }
            },
        },
        {
            title: <><label>Actions</label></>,
            dataIndex: 'is_active',
            width: '40px',
            // filteredValue: filteredInfo.is_active,
            // onFilter: (value, record) => record.is_active == value,
            // // onFilter: (value, record) => record.is_active === value,
            // filterSearch: false,
            render: (text, record) => {

                return (
                    <Fragment>
                        {/* <div style={{display:'flex',justifyContent:'space-around'}}> */}
                        <div className='row hover'>
                            <div className='col-4 px-0 me-0' style={{ marginLeft: '35%' }}>
                                {record.is_blocked === true ?
                                    <div className='col-md-2'>
                                        <span
                                            className="social-link btn btn-sm btn-light-danger
                              text-danger btn-circle d-flex align-items-center justify-content-center"
                                            onClick={(e) => { blockprovider(record) }}>
                                            <Tooltip title={record.unblock_days + '-' + 'Days Left'} color='#fc4b6c'><StopOutlined /></Tooltip>
                                        </span>
                                    </div> :
                                    <div className='col-md-2'>
                                        <span
                                            className="social-link btn btn-sm btn-light-success
                              text-success btn-circle d-flex align-items-center justify-content-center"
                                            onClick={(e) => { blockprovider(record) }}>
                                            <Tooltip title="Click here to block" color='#39cb7f'><CheckOutlined /></Tooltip>
                                        </span>
                                    </div>
                                }
                            </div>
                        </div>
                    </Fragment>
                )

            }
        },
    ]

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', handleKeyDown, false);
            document.addEventListener('webkitfullscreenchange', handleKeyDown, false);
            return () => {
                document.addEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('webkitfullscreenchange', handleKeyDown);
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode();
            setfullscreen(true);
        } else {
            fullscreenexit();
            setfullscreen(false);
        }
    }


    const content = (
        <div>
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-3">

                        <span className="btn btn-sm btn-light-success text-success btn-circle d-flex align-items-center justify-content-center"><LikeOutlined /></span>&nbsp;10

                    </div>
                    <div className="col-md-3">
                        <span className="btn btn-sm btn-light-danger
                                            text-danger btn-circle d-flex align-items-center justify-content-center"><DislikeOutlined /></span>&nbsp;2
                    </div>
                    <div className="col-md-3">
                        <span
                            className="btn btn-sm btn-light-success
                              text-success btn-circle d-flex align-items-center justify-content-center"
                        >
                            <i className="ri-check-line"></i>
                        </span>&nbsp;5
                    </div>
                    <div className="col-md-3">
                        <span
                            className="btn btn-sm btn-light-danger
                              text-danger btn-circle d-flex align-items-center justify-content-center"
                        >
                            <StopOutlined />
                        </span>&nbsp;7
                    </div>


                </div>
            </div>


        </div>

    );
    const passwordcancel = () => {
        setAddFormSubmit1(false)
        Setpassvisible(false);
        Setpassword({
            ...passwordata,
            passvalue: ""

        })
    }

    const blockcancel = () => {
        Setblockvisible(false);
        Setblockdata({
            ...blockdata,
            password: '',
            remarks: ''

        })
        setAddFormSubmit2(false);

    }

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        setSelectedKeys(e.target.value ? [e.target.value] : [])
        confirm({ closeDropdown: false });
        // setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const Activate = () => {

        Swal.fire({
            title: 'Are You Sure?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',

        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                Setpassvisible(true);


                // Swal.fire('Closed.!', 'Closed...', 'success');
            }
        });
    };
    const Block = () => {

        Swal.fire({
            title: 'Are You Sure?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',

        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                Setblockvisible(true);


                // Swal.fire('Closed.!', 'Closed...', 'success');
            }
        });
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
            >
                <Input className='form-control'
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    value={selectedKeys[0]}
                    // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}

                />
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    //     // color: filtered ? '#1890ff' : undefined,
                    //     fontWeight: 'bold',
                    //     // backgroundColor:'blue',
                    //     fontSize: '15px',
                    //     color: 'black'
                }}
            />
            // <FileSearchOutlined 
            // style={{
            //     // color: filtered ? '#1890ff' : undefined,
            //     fontWeight:'bold',
            //     // backgroundColor:'blue',
            //     // fontSize:'20px',
            //     color:'black'
            // }}
            // />
            // <i class="bi bi-search"></i>
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });

    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});

    const handleChange = (pagination, filters, sorter) => {

        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    const [paginationSize, setPaginationSize] = useState(25);
    const columns = [
        {
            title: '#',
            key: 'index',
            align: "center",
            width: "30px",
            render: (text, record, index) => {
                return (
                    (page - 1) * paginationSize + index + 1
                )
            },
        },
        {
            title: 'Service Provider',
            dataIndex: 's_p_name',
            key: 's_p_name',
            ...getColumnSearchProps('s_p_name', 'Service Provider'),
            ellipsis: true,
            width: '165px',
            filteredValue: filteredInfo.s_p_name || null,
            sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        },
        {
            title: 'Contact Person',
            dataIndex: 'cont_person',
            // key: 'cont_person',
            ...getColumnSearchProps('cont_person', 'Contact Person'),
            ellipsis: true,
            width: '150px',
            filteredValue: filteredInfo.cont_person || null,
            sorter: (a, b) => a.cont_person.localeCompare(b.cont_person),
            sortOrder: sortedInfo.columnKey === 'cont_person' ? sortedInfo.order : null,
            render: (text, record) => {
                let text1 = record.cont_person
                let letter = text1.charAt(0);
                let upper = letter.toUpperCase();
                return <p style={{ marginBottom: '0px' }}><Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>{upper}</Avatar>&nbsp;{record.cont_person}</p>
            }

        },
        {
            title: 'Contact No',
            dataIndex: 'cont_no',
            key: 'cont_no',
            ...getColumnSearchProps('cont_no', 'Contact No'),
            ellipsis: true,
            width: '140px',
            filteredValue: filteredInfo.cont_no || null,
            sorter: (a, b) => a.cont_no.length - b.cont_no.length,
            sortOrder: sortedInfo.columnKey === 'cont_no' ? sortedInfo.order : null,

        },
        {
            title: 'Email ID',
            dataIndex: 'e_id',
            key: 'e_id',
            ...getColumnSearchProps('e_id', 'Email ID'),
            ellipsis: true,
            width: '170px',
            filteredValue: filteredInfo.e_id || null,
            sorter: (a, b) => a.e_id.localeCompare(b.e_id),
            sortOrder: sortedInfo.columnKey === 'e_id' ? sortedInfo.order : null,


        },
        {
            title: 'Remarks',
            dataIndex: 'remarks',
            key: 'remarks',
            ...getColumnSearchProps('remarks', 'Remarks'),
            ellipsis: true,
            width: '100px',
            filteredValue: filteredInfo.remarks || null,
            // sorter: (a, b) => a.remarks.length - b.remarks.length,

        },
        {
            title: <><label>Actions</label>{type === 'block' ? '' : <Tooltip className='serviveproidertooltipclass' style={{ marginBotton: '5px', marginleft: '5px' }} placement="topRight" title={"This Action Includes Edit, Active/Inactive Status Change, Block/Unblock Status Change"}><InfoCircleOutlined /></Tooltip>}</>,
            dataIndex: 'is_active',
            width: type === 'block' ? '50px' : '100px',
            filteredValue: filteredInfo.is_active,
            onFilter: (value, record) => record.is_active == value,
            // onFilter: (value, record) => record.is_active === value,
            filterSearch: false,
            render: (text, record) => {

                return (
                    <Fragment>
                        <div className='row hover'>
                            {type === 'block' ?
                                <span><div className='col-4 px-0 me-0' style={{ marginLeft: '25%' }}>
                                    {record.is_blocked === true ?
                                        <div className='col-md-2'>
                                            <span
                                                className="social-link btn btn-sm btn-light-danger
                              text-danger btn-circle d-flex align-items-center justify-content-center"
                                                onClick={(e) => { blockprovider(record) }}>
                                                <StopOutlined />
                                            </span>
                                        </div> :
                                        <div className='col-md-2'>
                                            <span
                                                className="social-link btn btn-sm btn-light-success
                              text-success btn-circle d-flex align-items-center justify-content-center"
                                                onClick={(e) => { blockprovider(record) }}>
                                                <Tooltip title="Click here to block" color='#39cb7f'><CheckOutlined /></Tooltip>
                                            </span>
                                        </div>
                                    }
                                </div></span>
                                :
                                <>
                                    <div className='col-4 d-flex justify-content-center' >
                                        {record.is_blocked == false ?
                                            <div className='col-md-2'>
                                                <span className="social-link btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center"
                                                    onClick={(e) => { editservice(record) }}>
                                                    <Tooltip title="Click here to Edit" color='blue'>
                                                        <EditOutlined /></Tooltip>
                                                </span>
                                            </div>
                                            :
                                            <div className='col-md-2'>
                                                <span className="social-link btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center"

                                                ><Tooltip color='#39cb7f'>
                                                        <EditOutlined style={{ cursor: 'not-allowed' }} /></Tooltip>
                                                </span>
                                            </div>
                                        }
                                    </div>

                                    <div className='col-4 px-0 me-0 d-flex justify-content-center'>
                                        {record.is_blocked == false ?
                                            <div>
                                                {record.is_active === true ?
                                                    <span className="social-link btn btn-sm btn-light-success
                                            text-success btn-circle d-flex align-items-center justify-content-center"  onClick={(e) => { statuschange(record) }}><Tooltip title="Click here to Deactivate" color='#39cb7f'><LikeOutlined /></Tooltip></span>
                                                    :
                                                    <span className="social-link btn btn-sm btn-light-danger
                                            text-danger btn-circle d-flex align-items-center justify-content-center" onClick={(e) => { statuschange(record) }}><Tooltip title="Click here to Activate" color='#fc4b6c'><DislikeOutlined /></Tooltip></span>}
                                            </div>
                                            :
                                            <div>
                                                {
                                                    record.is_active === true ?
                                                        <span className="social-link btn btn-sm btn-light-success
                                                text-success btn-circle d-flex align-items-center justify-content-center" style={{ cursor: 'not-allowed' }}
                                                        // onClick={(e) => { statuschange(row) }}
                                                        ><Tooltip color='#39cb7f'><LikeOutlined /></Tooltip>
                                                        </span>
                                                        :
                                                        <span className="social-link btn btn-sm btn-light-danger
                                                text-danger btn-circle d-flex align-items-center justify-content-center" style={{ cursor: 'not-allowed' }}
                                                        //  onClick={(e) => { statuschange(row) }}
                                                        ><Tooltip color='#fc4b6c'><DislikeOutlined /></Tooltip></span>
                                                }
                                            </div>
                                        }
                                    </div>

                                    <div className='col-4 px-0 me-0' >
                                        {record.is_blocked === true ?
                                            <div className='col-md-2'>
                                                <span
                                                    className="social-link btn btn-sm btn-light-danger
                              text-danger btn-circle d-flex align-items-center justify-content-center"
                                                    onClick={(e) => { blockprovider(record) }}>
                                                    <Tooltip title={record.unblock_days + '-' + 'Days Left'} color='#fc4b6c'><StopOutlined /></Tooltip>
                                                </span>
                                            </div> :
                                            <div className='col-md-2'>
                                                <span
                                                    className="social-link btn btn-sm btn-light-success
                              text-success btn-circle d-flex align-items-center justify-content-center"
                                                    onClick={(e) => { blockprovider(record) }}>
                                                    <Tooltip title="Click here to block" color='#39cb7f'><CheckOutlined /></Tooltip>
                                                </span>
                                            </div>
                                        }
                                    </div>
                                </>}

                        </div>
                    </Fragment>
                )

            }
        },
    ];

    const [serviceProviderDetails, setServiceProviderDetails] = useState({
        provider_name: '',
        contact_no1: '',
        contact_no2: '',
        contact_no: '',
        short_name: '',
        mobile_no: '',
        mobile_no1: '',
        Contract_from: '',
        Contract_to: '',
        contact_person: '',
        email: '',
        address: '',
    });
    console.log(serviceProviderDetails, 'serviceProviderDetails');
    const [sid, Setsid] = useState({
        s_id: ''
    })


    const serviceProviderOnChange = (e) => {
        setServiceProviderDetails({
            ...serviceProviderDetails,
            [e.target.name]: e.target.value
        })
    }



    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetServiceProviders",
                        {}
                    ]
                }
            ]
            providerlist({
                payload: payload,
                paramid: paramid
            })
        }
    }, [isAuth])

    useEffect(() => {
        formValidator.current.showMessages()
        formValidator1.current.showMessages()
        formValidator2.current.showMessages()


    }, []);

    useEffect(() => {
        if (recallremarks) {
            let temp = [];
            for (let i = 0; i < recallremarks.length; i++) {
                if (recallremarks[i].r_description != '') {
                    temp.push(recallremarks[i])
                }
                Setremarksvalue(temp)
            }
        }

    }, [recallremarks])

    const addservice = (e) => {
        e.preventDefault();
        setAddFormSubmit(true);
        if (formValidator.current.allValid()) {
            if (sid.s_id === '') {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "SaveServiceProvider",
                            {
                                "s_p_name": serviceProviderDetails.provider_name,
                                "s_p_short": serviceProviderDetails.short_name,
                                "cont_from": moment(serviceProviderDetails.Contract_from).format('D-MMM-YYYY'),
                                "cont_to": moment(serviceProviderDetails.Contract_to).format('D-MMM-YYYY'),
                                "cont_person": serviceProviderDetails.contact_person,
                                "cont_no": serviceProviderDetails.contact_no1 + '-' + serviceProviderDetails.contact_no2 + '-' + serviceProviderDetails.contact_no,
                                "mob_no": serviceProviderDetails.mobile_no1 + '-' + serviceProviderDetails.mobile_no,
                                "e_id": serviceProviderDetails.email,
                                "address": serviceProviderDetails.address
                            }
                        ]
                    }
                ];
                addprovider({
                    payload: payload,
                    paramid: paramid
                })
            }
            else {

                const pay = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "UpdateServiceProvider",
                            {
                                "s_p_id": sid.s_id,
                                "s_p_name": serviceProviderDetails.provider_name,
                                "s_p_short": serviceProviderDetails.short_name,
                                "address": serviceProviderDetails.address,
                                "cont_from": moment(serviceProviderDetails.Contract_from).format('D-MMM-YYYY'),
                                "cont_to": moment(serviceProviderDetails.Contract_to).format('D-MMM-YYYY'),
                                "cont_person": serviceProviderDetails.contact_person,
                                "cont_no": serviceProviderDetails.contact_no1 + '-' + serviceProviderDetails.contact_no2 + '-' + serviceProviderDetails.contact_no,
                                "mob_no": serviceProviderDetails.mobile_no1 + '-' + serviceProviderDetails.mobile_no,
                                "e_id": serviceProviderDetails.email
                            }
                        ]
                    }
                ]
                updateprovider({
                    payload: pay,
                    paramid: paramid
                })
            }
        }
    }

    const statuschange = (e) => {
        Activate()
        Setpassword({
            ...passwordata,
            sp_id: e.s_p_id,
            active_status: !e.is_active
        })
    }

    const blockprovider = (e) => {
        Block()
        Setblockdata({
            ...blockdata,
            sp_id: e.s_p_id,
            is_blocked: !e.is_blocked
        })
    }

    const submitpassword = () => {
        setAddFormSubmit1(true)
        if (formValidator1.current.allValid()) {
            const pay = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "ChangeServiceProviderStatus",
                        {
                            "sp_id": passwordata.sp_id,
                            "active_status": passwordata.active_status,
                            "password": passwordata.passvalue
                        }
                    ]
                }
            ]
            updatestatus({
                payload: pay,
                paramid: paramid
            })
        }
    }

    const blockservice = () => {
        setAddFormSubmit2(true)
        if (formValidator2.current.allValid()) {
            const pay = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "BlockServiceProvider",
                        {
                            "sp_id": blockdata.sp_id,
                            "is_blocked": blockdata.is_blocked,
                            "remarks": blockdata.remarks,
                            "password": blockdata.password
                        }
                    ]
                }
            ]
            updateblock({
                payload: pay,
                paramid: paramid
            })
            // Setblockdata({
            //     ...blockdata,
            //     password: '',
            //     remarks: ''
            // })
        }
    }

    const editservice = (data) => {
        const phn = data.cont_no.split('-');
        const mbl = data.mob_no.split('-');
        setServiceProviderDetails({
            ...serviceProviderDetails,
            provider_name: data.s_p_name,
            contact_no1: phn[0],
            contact_no2: phn[1],
            contact_no: phn[2],
            short_name: data.s_p_short,
            mobile_no: mbl[1],
            mobile_no1: mbl[0],
            Contract_from: data.cont_from,
            Contract_to: data.cont_to,
            contact_person: data.cont_person,
            email: data.e_id,
            address: data.address
        })
        Setsid({
            ...sid,
            s_id: data.s_p_id
        })
        // SetmodalVisible(true);
        setOpen(true);


    }

    const handleOk = () => {
        setRemarksModal(false)
    };

    const handleCancel = () => {
        setRemarksModal(false)
    };

    const RemarksColumn = [
        {
            title: 'S.No',
            dataIndex: false,
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            }
        },
        {
            title: 'Description',
            dataIndex: 'r_description',
            key: 'r_description',
            width: '200px',
            ellipsis: true,
            render: (text, record) => <a onClick={() => {
                Setblockdata({
                    ...blockdata,
                    remarks: record.r_description
                })
                setRemarksModal(false)
            }}>{record.r_description}</a>,
        },
    ]

    const showRemarksModal = () => {
        GetRemarksData({
            payload:
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetRemarksData",
                            {}
                        ]
                    }
                ],
            paramid: paramid
        })
        setRemarksModal(true);
    };

    const [baseData, setBaseData] = useState([]);
    // const [commonSearch, setCommonSearch] = useState();
    useEffect(() => {
        if (lists && lists.length > 0) {
            setBaseData(lists);
        }
    }, [lists])

    useEffect(() => {
        // if (type === 'active' && baseData.length > 0) {
        let activeData = _.filter(baseData, { is_active: true })
        setActiveArray(activeData)
        // }
        // else if (type === 'inactive' && baseData.length > 0) {
        let inactiveData = _.filter(baseData, { is_active: false })
        setInActiveArray(inactiveData)
        // }
        // else if (type === 'unblock' && baseData.length > 0) {
        let unblockData = _.filter(baseData, { is_blocked: false })
        setUnBlockedArray(unblockData)
        // }
        // else {
        let block = _.filter(baseData, { is_blocked: true })
        setBlockedArray(block)
        // }
    }, [baseData])

    console.log(baseData, 'baseData');


    const searchRecords = (e) => {
        if (e == 'clear') {
            setClearText('')
            setBaseData(lists)
            setIconEnable(false)
        } else {
            setClearText(e.target.value)
            if (e.target.value.length > 0) {
                setIconEnable(true)
            } else {
                setIconEnable(false)
            }
            const filterTable = lists.filter(o =>
                Object.keys(o).some(k =>
                    String(o[k])
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            );
            setBaseData(filterTable)
        }


    };

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    const onInputChange = e => {
        const { value } = e.target;


        const re = /^[0-9a-zA-Z]+$/;
        if (value === "" || re.test(value)) {
            setServiceProviderDetails({
                ...serviceProviderDetails,
                short_name: e.target.value
            })
        }
    }

    const serviceNameOnChange = e => {
        const { value } = e.target;
        const re = /^[a-zA-Z ]*$/;
        console.log(re.test(value), 're.test(value)');
        if (re.test(value) === true) {
            setErrorField(true)
            setServiceProviderDetails({
                ...serviceProviderDetails,
                provider_name: e.target.value
            })
        }
        else if (value === '') {
            setErrorField(false)
        }
        else {
            setErrorField(false)
            setServiceProviderDetails({
                ...serviceProviderDetails,
                provider_name: e.target.value
            })
        }
    }



    const shortNameOnChange = e => {
        const { value } = e.target;
        const re = /^[a-zA-Z0-9]*$/;
        console.log(re.test(value), 're.test(value)');
        if (re.test(value) === true) {
            setShortName(true)
            setServiceProviderDetails({
                ...serviceProviderDetails,
                short_name: e.target.value
            })
        }
        else {
            setShortName(false)
            setServiceProviderDetails({
                ...serviceProviderDetails,
                short_name: e.target.value
            })
        }
    }

    const contactNumberOnChange = e => {
        const { value } = e.target;
        const re = /^\+(?=.{15})\d{10,15}_{0,5}$/;
        console.log(re.test(value), 're.test(value)');
        if (re.test(value) === true) {
            setContactNumber(true)
            setServiceProviderDetails({
                ...serviceProviderDetails,
                contact_no: e.target.value
            })
        }
        else {
            setContactNumber(false)
            setServiceProviderDetails({
                ...serviceProviderDetails,
                contact_no: e.target.value
            })
        }
    }


    const contactPersonOnChange = e => {
        const { value } = e.target;
        const re = /^[a-zA-Z ]*$/;
        console.log(re.test(value), 're.test(value)');
        if (re.test(value) === true) {
            setContactPerson(true)
            setServiceProviderDetails({
                ...serviceProviderDetails,
                contact_person: e.target.value
            })
        }
        else {
            setContactPerson(false)
            setServiceProviderDetails({
                ...serviceProviderDetails,
                contact_person: e.target.value
            })
        }
    }

    const contactFromOnChange = e => {
        const { value } = e._d;
        const re = !'';
        console.log(re.test(value), 're.test(value)');
        if (re.test(value) === true) {
            setContactFrom(true)
            setServiceProviderDetails({
                ...serviceProviderDetails,
                Contract_from: e.target.value
            })
        }
        else {
            setContactFrom(false)
            setServiceProviderDetails({
                ...serviceProviderDetails,
                Contract_from: e.target.value
            })
        }
    }


    const emailOnChange = e => {
        const { value } = e.target;
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        console.log(re.test(value), 're.test(value)');
        if (re.test(value) === true) {
            setEmailField(true)
            setServiceProviderDetails({
                ...serviceProviderDetails,
                email: e.target.value
            })
        }
        else {
            setEmailField(false)
            setServiceProviderDetails({
                ...serviceProviderDetails,
                email: e.target.value
            })
        }
    }


    return (
        <Fragment>
            <div>
                <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} />
                <div id='page-wrapper' className="page-wrapper">
                    <div className="page-titles pb-1 pt-1">
                        <div className="row">
                            <div className="col-lg-4 col-md-2 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span style={{ fontSize: '16px' }}>Master</span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span >Service Providers </span>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="col-lg-8 col-md-6 d-none d-md-flex align-items-center p-0 justify-content-end">
                                <div className="search-box" style={{ position: 'relative', marginRight: '26px', width: '240px', boxShadow: '0 5px 5px rgb(0 0 0 / 10%)' }}>
                                    <input className="search-txt" type="text" value={clearText} onChange={searchRecords} placeholder="Type to Search" />
                                    <a className="search-btn" style={{ position: 'relative', right: 1 }}>
                                        {iconEnable == true ?
                                            <i className="fas fa-close" onClick={(e) => { searchRecords('clear') }}></i>
                                            : <i className="fas fa-search"></i>}
                                    </a>
                                </div>


                                <div>
                                    <Button type="primary" shape="round" className='addbutton' style={{ marginRight: '12px' }}
                                        icon={<PlusCircleOutlined />} size='default' onClick={addopen} >
                                        Add
                                    </Button>
                                </div>
                                <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black full-mode fullscreenradius" style={{ marginRight: '10px' }} >
                                    <Tooltip placement="left" title="Full Screen Mode"><FullscreenOutlined /></Tooltip>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid pt-0">
                        <div className="row" >
                            <div className="col-12">
                                <div className={"col-12 " + localStorage.getItem('currentTheme')}>
                                    <Collapse className='report-collapse' accordion defaultActiveKey={["1"]}>
                                        <Panel header="Service Provider Statistics" key="1"
                                        // extra={tableshow == true ? genextra() : false}
                                        >
                                            <div className="col-md-12">
                                                <div className="row">

                                                    <div className="col-md-6 userCard userCard1">
                                                        <Card>
                                                            {/* <div className="row">
                                                        <h5>Overall Statistics<span style={{ marginLeft: '45%' }}><Tooltip title="Toggle here to view blocked/unblocked statistics"><Switch defaultChecked onChange={(e) => {
                                                            console.log(e, 'eeeee');
                                                            setToggleButton(e)
                                                        }} /></Tooltip></span></h5>
                                                        {toggleButton === true ?
                                                            <>
                                                                <div className="col-md-2" style={{ marginTop: '7%', marginLeft: '15%' }}>
                                                                    <Progress type="circle" percent={30} width={90} format={(percent) => `${120}`} />
                                                                    <h4 style={{ marginLeft: '50%' }}>Active</h4>
                                                                </div>
                                                                <div className="col-md-2" style={{ marginLeft: '12%', marginTop: '7%' }}>
                                                                    <Progress type="circle" percent={20} width={90} status="exception" format={(percent) => `${50}`} />
                                                                    <h4 style={{ marginLeft: '50%' }}>InActive</h4>
                                                                </div>
                                                            </> :
                                                            <>
                                                                <div className="col-md-2" style={{ marginTop: '7%', marginLeft: '15%' }}>
                                                                    <Progress type="circle" percent={30} width={90} format={(percent) => `${120}`} />
                                                                    <h4 style={{ marginLeft: '50%' }}>Block</h4>
                                                                </div>
                                                                <div className="col-md-2" style={{ marginLeft: '12%', marginTop: '7%' }}>
                                                                    <Progress type="circle" percent={20} width={90} status="exception" format={(percent) => `${50}`} />
                                                                    <h4 style={{ marginLeft: '50%' }}>UnBlock</h4>
                                                                </div>
                                                            </>}
                                                    </div> */}
                                                            <h6>Overall Statistics</h6>
                                                            <div className="service-provider-segment1 service-provider-segment">
                                                                <Space direction="vertical">
                                                                    {/* <Segmented onChange={(value) => {
                                                                setType(value)
                                                            }}
                                                                options={[
                                                                    {
                                                                        label: (
                                                                            <div onChange={() => {
                                                                                console.log('hello');
                                                                            }}
                                                                                style={{
                                                                                    padding: 4,
                                                                                }}
                                                                            >
                                                                                <Avatar
                                                                                    style={{
                                                                                        backgroundColor: '#87d068',
                                                                                    }}
                                                                                    icon={<LikeOutlined />}
                                                                                />
                                                                                <div>Active</div>
                                                                                <b>{activeArray.length}</b>
                                                                            </div>
                                                                        ),
                                                                        value: 'activeData',
                                                                    },
                                                                    {
                                                                        label: (
                                                                            <div onChange={() => {
                                                                                console.log('inactive');
                                                                            }}
                                                                                style={{
                                                                                    padding: 4,
                                                                                }}
                                                                            >
                                                                                <Avatar
                                                                                    style={{
                                                                                        backgroundColor: '#F32013',
                                                                                    }}
                                                                                    icon={<DislikeOutlined />}
                                                                                />
                                                                                <div>Inactive</div>
                                                                                <b>{inActiveArray.length}</b>

                                                                            </div>
                                                                        ),
                                                                        value: 'inactive',
                                                                    },
                                                                    {
                                                                        label: (
                                                                            <div onChange={() => {
                                                                                console.log('unblk');
                                                                            }}
                                                                                style={{
                                                                                    padding: 4,
                                                                                }}
                                                                            >
                                                                                <Avatar
                                                                                    style={{
                                                                                        backgroundColor: '#87d068',
                                                                                    }}
                                                                                    icon={<i className="ri-check-line"></i>}
                                                                                />
                                                                                <div>Unblocked</div>
                                                                                <b>{unBlockedArray.length}</b>

                                                                            </div>
                                                                        ),
                                                                        value: 'unblock',
                                                                    },
                                                                    {
                                                                        label: (
                                                                            <div onChange={() => {
                                                                                console.log('blocked');
                                                                            }}
                                                                                style={{
                                                                                    padding: 4,
                                                                                }}
                                                                            >
                                                                                <Avatar
                                                                                    style={{
                                                                                        backgroundColor: '#F32013',
                                                                                    }}
                                                                                    icon={<StopOutlined />}
                                                                                />
                                                                                <div>Blocked</div>
                                                                                <b>{blockedArray.length}</b>

                                                                            </div>
                                                                        ),
                                                                        value: 'block',
                                                                    },
                                                                    {
                                                                        label: (
                                                                            <div onChange={() => {
                                                                                console.log('hello');
                                                                            }}
                                                                                style={{
                                                                                    padding: 4,
                                                                                }}
                                                                            >
                                                                                <Avatar
                                                                                    style={{
                                                                                        backgroundColor: '#87d068',
                                                                                    }}
                                                                                    icon={<CloseOutlined />}
                                                                                />
                                                                                <div>All Data</div>
                                                                                <b>{baseData.length}</b>
                                                                            </div>
                                                                        ),
                                                                        value: 'active',
                                                                    }
                                                                ]}
                                                            /> */}
                                                                    <Segmented size='small' onChange={(value) => {
                                                                        setType(value)
                                                                        // handleClickScroll()

                                                                    }}
                                                                        options={[
                                                                            {
                                                                                label: (
                                                                                    <div onChange={() => {
                                                                                        console.log('hello');
                                                                                    }}

                                                                                    >
                                                                                        <Avatar
                                                                                            style={{
                                                                                                backgroundColor: '#87d068',
                                                                                            }}
                                                                                            icon={<i className="fas fa-database"></i>}
                                                                                        />
                                                                                        <div>All Data</div>
                                                                                        <b>{baseData.length}</b>
                                                                                    </div>
                                                                                ),
                                                                                value: 'active',
                                                                            },                                                                            
                                                                            {
                                                                                label: (
                                                                                    <div onChange={() => {
                                                                                        console.log('unblk');
                                                                                    }}

                                                                                    >
                                                                                        <Avatar
                                                                                            style={{
                                                                                                backgroundColor: '#87d068',
                                                                                            }}
                                                                                            icon={<i className="fas fa-user-plus"></i>}
                                                                                        />
                                                                                        <div>Active</div>
                                                                                        <b>{activeArray.length}</b>

                                                                                    </div>
                                                                                ),
                                                                                value: 'activeData',
                                                                            },
                                                                            {
                                                                                label: (
                                                                                    <div onChange={() => {
                                                                                        console.log('inactive');
                                                                                    }}

                                                                                    >
                                                                                        <Avatar
                                                                                            style={{
                                                                                                backgroundColor: '#F32013',
                                                                                            }}
                                                                                            icon={<i className="fas fa-user-times"></i>}
                                                                                        />
                                                                                        <div>Inactive</div>
                                                                                        <b>{inActiveArray.length}</b>

                                                                                    </div>
                                                                                ),
                                                                                value: 'inactive',
                                                                            },
                                                                            {
                                                                                label: (
                                                                                    <div onChange={() => {
                                                                                        console.log('blocked');
                                                                                    }}

                                                                                    >
                                                                                        <Avatar
                                                                                            style={{
                                                                                                backgroundColor: '#F32013',
                                                                                            }}
                                                                                            icon={<i className="fas fa-hand-paper"></i>}
                                                                                        />
                                                                                        <div>Blocked</div>
                                                                                        <b>{blockedArray.length}</b>

                                                                                    </div>
                                                                                ),
                                                                                value: 'block',
                                                                            },
                                                                            // {
                                                                            //     label: (
                                                                            //         <div onChange={() => {
                                                                            //             console.log('unblk');
                                                                            //         }}

                                                                            //         >
                                                                            //             <Avatar
                                                                            //                 style={{
                                                                            //                     backgroundColor: '#87d068',
                                                                            //                 }}
                                                                            //                 icon={<i className="ri-check-line"></i>}
                                                                            //             />
                                                                            //             <div>Unblocked</div>
                                                                            //             <b>{unBlockedArray.length}</b>

                                                                            //         </div>
                                                                            //     ),
                                                                            //     value: 'unblock',
                                                                            // },
                                                                        ]}
                                                                    />



                                                                </Space>
                                                            </div>
                                                        </Card>
                                                    </div>

                                                    <div className="col-md-6 userCard userCard1" style={{ height: '219px', width: '50%' }}>
                                                        <Card>
                                                            <h6>Action Needed<span style={{ marginLeft: '77%' }}></span></h6>
                                                            <div id='service-table-action' className='service-table'>
                                                                <Table className={`stats ${localStorage.getItem("currentTheme")}`}
                                                                    columns={columns1}
                                                                    dataSource={blockedArray}
                                                                    pagination={false}
                                                                // style={{ overflowY: 'auto', overflowX: 'hidden', height: '165px' }}
                                                                />
                                                            </div>
                                                        </Card>
                                                    </div>
                                                </div>
                                            </div>
                                        </Panel>
                                    </Collapse>
                                    <br />
                                    <div>
                                        <Table
                                            sortDirections={["ascend", "descend", "ascend"]}
                                            className={localStorage.getItem("currentTheme")}
                                            // id='service-user-priv'
                                            size={'small'}
                                            columns={columns}
                                            dataSource={type === 'activeData' ? activeArray : (type === 'inactive' ? inActiveArray : (type === 'unblock' ? unBlockedArray : (type === 'block' ? blockedArray : baseData)))}
                                            bordered
                                            pagination={false}
                                            showSorterTooltip={false}
                                            onChange={handleChange} />
                                    </div>
                                    {/* </div> */}
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>

                    </div >
                </div>

                <div>
                    <Drawer
                        title="Service Provider"
                        placement='right'
                        closable={false}
                        className={localStorage.getItem("currentTheme") + ' resize-drawer'}
                        // height={100}
                        width={600}
                        onClose={modalcancel}
                        open={open}
                        // getContainer={false}
                        visible={open}
                        onCancel={modalcancel} >


                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label className={`control-label ${errorField === true ? 'success-field' : 'error-field'}`}><b>Service Provider</b></label>
                                    <span style={{ "color": "red" }}> *</span>
                                    <div className="position-relative">
                                        <Input
                                            type="text"
                                            id="provider_name"
                                            name='provider_name'
                                            className={`form-control`}
                                            placeholder="Enter Service Provider Name"
                                            autoComplete={'off'}
                                            onChange={serviceNameOnChange}
                                            style={{ marginTop: '5px', borderColor: errorField === true && serviceProviderDetails.provider_name != '' ? '#87d068' : errorField === false && serviceProviderDetails.provider_name != '' ? '#fc4b6c' : '' }}
                                            value={serviceProviderDetails.provider_name}
                                            maxlength='50'
                                        // onKeyPress={(event) => {
                                        //     if (!/[a-zA-Z ]/.test(event.key)) {
                                        //         event.preventDefault();
                                        //     }
                                        // }}
                                        />
                                        {errorField === true && serviceProviderDetails.provider_name != '' ?
                                            <i style={{ top: '7px', right: '7px' }} className="ri-check-line position-absolute text-success fs-5 fw-bold"></i>
                                            :
                                            errorField === false && serviceProviderDetails.provider_name != '' ?
                                                <i style={{ top: '7px', right: '7px' }} className="ri-close-line position-absolute text-danger fs-5 fw-bold"></i>
                                                : ''
                                        }
                                    </div>

                                    {formValidator.current.message(
                                        'provider_name',
                                        serviceProviderDetails.provider_name,
                                        [
                                            'required',
                                            {
                                                regex: /^[a-zA-Z ]*$/,
                                            },

                                            `max:50`
                                        ],
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Service Provider Name Required',
                                                regex: 'Enter Valid Name',
                                                max: 'Maximum character is 50 only'
                                            }
                                        })}


                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label className="control-label"><b>Short Name</b></label>
                                    <span style={{ "color": "red" }}> *</span>
                                    <div className="position-relative">
                                        <Input
                                            type="text"
                                            name='short_name'
                                            id="short_name"
                                            style={{ marginTop: '5px', borderColor: shortName === true && serviceProviderDetails.short_name != '' ? '#87d068' : shortName === false && serviceProviderDetails.short_name != '' ? '#fc4b6c' : '' }}
                                            className="form-control"
                                            placeholder="Enter Short Name"
                                            autoComplete={'off'}
                                            onChange={shortNameOnChange}
                                            value={serviceProviderDetails.short_name}
                                            maxlength='25'
                                        />
                                        {shortName === true && serviceProviderDetails.short_name != '' ?
                                            <i style={{ top: '7px', right: '7px' }} className="ri-check-line position-absolute text-success fs-5 fw-bold"></i>
                                            :
                                            shortName === false && serviceProviderDetails.short_name != '' ?
                                                <i style={{ top: '7px', right: '7px' }} className="ri-close-line position-absolute text-danger fs-5 fw-bold"></i>
                                                : ''
                                        }
                                    </div>

                                    {formValidator.current.message(
                                        'short_name',
                                        serviceProviderDetails.short_name,
                                        [
                                            'required',
                                            {
                                                regex: /^[a-zA-Z0-9]*$/,
                                            },

                                            `max:25`
                                        ],
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Short Name Required',
                                                regex: 'Enter Valid Name',
                                                max: 'Maximum character is 25 only'
                                            }
                                        })}
                                </div>
                            </div>
                        </div>
                        {sid.s_id === '' ? false :
                            <>
                                <div className='row'>
                                    <div className="col-md-6">
                                        <div className="mb-2">
                                            <label className="control-label"><b>Contact Number</b></label>
                                            <span style={{ "color": "red" }}> *</span>

                                            <div className="position-relative">
                                                <Input
                                                    type="text"
                                                    id="contact_no"
                                                    style={{ marginTop: '5px', borderColor: contactNumber === true && serviceProviderDetails.contact_no != '' ? '#87d068' : contactNumber === false && serviceProviderDetails.contact_no != '' ? '#fc4b6c' : '' }}
                                                    name='contact_no'
                                                    placeholder="+91-452-1234567890"
                                                    autoComplete={'off'}
                                                    className='form-control'
                                                    // placeholder="1234567890"
                                                    onChange={contactNumberOnChange}
                                                    value={serviceProviderDetails.contact_no}
                                                // onKeyPress={(event) => {
                                                //     if (!/[0-9]/.test(event.key)) {
                                                //         event.preventDefault();
                                                //     }
                                                // }}
                                                // maxlength='10'
                                                />
                                                {contactNumber === true && serviceProviderDetails.contact_no != '' ?
                                                    <i style={{ top: '7px', right: '7px' }} className="ri-check-line position-absolute text-success fs-5 fw-bold"></i>
                                                    :
                                                    contactNumber === false && serviceProviderDetails.contact_no != '' ?
                                                        <i style={{ top: '7px', right: '7px' }} className="ri-close-line position-absolute text-danger fs-5 fw-bold"></i>
                                                        : ''
                                                }
                                                {formValidator.current.message(
                                                    'contact_no',
                                                    serviceProviderDetails.contact_no,
                                                    ['required',
                                                        { regex: /^([0-9]{6}|[0-9]{7}|[0-9]{8}|[0-9]{9}|[0-9]{10})$/ }],
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Contact Number Required',
                                                            regex: 'Invalid Contact Number',

                                                        }
                                                    })}

                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="mb-2">
                                            <label className="control-label"><b>Mobile Number</b></label>

                                            <div className="row">
                                                <div className="col-3 pe-0">
                                                    <Input
                                                        type="text"
                                                        name='mobile_no1'
                                                        autoComplete={'off'}
                                                        placeholder="+00"
                                                        className='form-control'
                                                        style={{ marginTop: '5px' }}
                                                        onChange={serviceProviderOnChange}
                                                        value={serviceProviderDetails.mobile_no1}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9 +]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        maxlength='3'
                                                    />

                                                    {formValidator.current.message(
                                                        'mobile_no1',
                                                        serviceProviderDetails.mobile_no1,
                                                        [{ regex: /^\+?[0-9]{2}$/ }],

                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Mobile Number Required',
                                                                regex: 'Enter Valid Mobile Number'
                                                            }
                                                        })}

                                                </div>
                                                <div className="col-9">
                                                    <Input
                                                        type="text"
                                                        id="contact_no"
                                                        name='mobile_no'
                                                        style={{ marginTop: '5px' }}
                                                        autoComplete={'off'}
                                                        className='form-control'
                                                        placeholder="1234567890"
                                                        onChange={serviceProviderOnChange}
                                                        value={serviceProviderDetails.mobile_no}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        maxlength='10'
                                                    />

                                                    {formValidator.current.message(
                                                        'mobile_no1',
                                                        serviceProviderDetails.mobile_no1,
                                                        [{ regex: /^\+?[0-9]{2}$/ }],

                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                regex: 'Valid Country Code Required'
                                                            }
                                                        })}

                                                    {formValidator.current.message(
                                                        'mobile_no1',
                                                        serviceProviderDetails.mobile_no,
                                                        [

                                                            {
                                                                regex: /^\+?[1-9]{1}[0-9]{9}$/,
                                                            },
                                                            `min:10`,
                                                            `max:10`
                                                        ],
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {

                                                                regex: 'Enter Valid Mobile Number  Only',
                                                                min: 'mobile number should be 10 digits',
                                                                max: 'mobile number should be 10 digits'
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label className="control-label"><b>Contract From</b></label>
                                    <span style={{ "color": "red" }}> *</span>

                                    <DatePicker placeholder="DD-MMM-YYYY" className='form-control' type="date"
                                        format={'DD-MMM-YYYY'}
                                        onKeyDown={(event) => {
                                            event.preventDefault()
                                        }}
                                        allowClear={true}
                                        value={serviceProviderDetails.Contract_from != '' ? moment(serviceProviderDetails.Contract_from) : undefined}
                                        style={{ marginTop: '5px' }}
                                        onChange={(e) => {
                                            setServiceProviderDetails({
                                                ...serviceProviderDetails,
                                                Contract_from: e._d
                                            })
                                        }}
                                    ></DatePicker >
                                    {formValidator.current.message(
                                        'Contract_from',
                                        serviceProviderDetails.Contract_from,
                                        ['required'],
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Contract From Required'
                                            }
                                        })}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label className="control-label"><b>Contract To</b></label>
                                    <span style={{ "color": "red" }}> *</span>

                                    <DatePicker placeholder="DD-MMM-YYYY" className='form-control' type="date"
                                        format={'DD-MMM-YYYY'}
                                        onKeyDown={(event) => {
                                            event.preventDefault()
                                        }}
                                        allowClear={true}
                                        style={{ marginTop: '5px' }}
                                        value={serviceProviderDetails.Contract_to != '' ? moment(serviceProviderDetails.Contract_to) : undefined}
                                        disabledDate={(current) => {
                                            return moment(serviceProviderDetails.Contract_from) >= current || moment() >= current
                                        }}
                                        onChange={(e) => {
                                            setServiceProviderDetails({
                                                ...serviceProviderDetails,
                                                Contract_to: e._d
                                            })
                                        }}
                                    ></DatePicker >
                                    {formValidator.current.message(
                                        'Contract_to',
                                        serviceProviderDetails.Contract_to,
                                        ['required'],
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Contract To Required',

                                            }
                                        })}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label className="control-label"><b>Email ID</b></label>
                                    <span style={{ "color": "red" }}> *</span>
                                    <div className="position-relative">
                                        <Input
                                            type="text"
                                            id="email"
                                            name='email'
                                            style={{ marginTop: '5px', borderColor: emailField === true && serviceProviderDetails.email != '' ? '#87d068' : emailField === false && serviceProviderDetails.email != '' ? '#fc4b6c' : '' }}
                                            autoComplete={'off'}
                                            placeholder="Enter Email ID"
                                            onChange={emailOnChange}
                                            className='form-control'
                                            value={serviceProviderDetails.email}
                                        />
                                        {emailField === true && serviceProviderDetails.email != '' ?
                                            <i style={{ top: '7px', right: '7px' }} className="ri-check-line position-absolute text-success fs-5 fw-bold"></i>
                                            :
                                            emailField === false && serviceProviderDetails.email != '' ?
                                                <i style={{ top: '7px', right: '7px' }} className="ri-close-line position-absolute text-danger fs-5 fw-bold"></i>
                                                : ''
                                        }

                                    </div>
                                    {formValidator.current.message(
                                        'email',
                                        serviceProviderDetails.email,
                                        ['required', { regex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ }],
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Email ID Required',
                                                regex: 'Enter valid email'
                                            }
                                        })}
                                </div>
                            </div>
                            {/* {emailField === true || emailField === false ?
                                <div className="col-md-1" style={{ marginTop: '25px' }}>
                                    {emailField === true ?
                                        <Avatar
                                            style={{
                                                backgroundColor: '#87d068',
                                            }}
                                            icon={<i className="ri-check-line"></i>}
                                        /> :
                                        <Avatar
                                            style={{
                                                backgroundColor: '#F32013',
                                            }}
                                            icon={<CloseCircleOutlined />}
                                        />}
                                </div> : ''} */}

                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label className="control-label"><b>Contact Person</b></label>
                                    <span style={{ "color": "red" }}> *</span>
                                    <div className="position-relative">
                                        <Input
                                            type="text"
                                            id="contact_person"
                                            name='contact_person'
                                            autoComplete={'off'}
                                            className='form-control'
                                            style={{ marginTop: '5px', borderColor: contactPerson === true && serviceProviderDetails.contact_person != '' ? '#87d068' : contactPerson === false && serviceProviderDetails.contact_person != '' ? '#fc4b6c' : '' }}
                                            placeholder="Enter Contact Person"
                                            onChange={contactPersonOnChange}
                                            value={serviceProviderDetails.contact_person}
                                        // onKeyPress={(event) => {
                                        //     if (!/[a-zA-Z ]/.test(event.key)) {
                                        //         event.preventDefault();
                                        //     }
                                        // }}
                                        />
                                        {contactPerson === true && serviceProviderDetails.contact_person != '' ?
                                            <i style={{ top: '7px', right: '7px' }} className="ri-check-line position-absolute text-success fs-5 fw-bold"></i>
                                            :
                                            contactPerson === false && serviceProviderDetails.contact_person != '' ?
                                                <i style={{ top: '7px', right: '7px' }} className="ri-close-line position-absolute text-danger fs-5 fw-bold"></i>
                                                : ''
                                        }
                                    </div>
                                    {formValidator.current.message(
                                        'contact_person',
                                        serviceProviderDetails.contact_person,
                                        ['required', { regex: /^[a-zA-Z ]*$/ }],
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Contact Person Name Required',
                                                regex: 'Enter valid name'
                                            }
                                        })}

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label className="control-label"><b>Contact Number</b></label>
                                    <span style={{ "color": "red" }}> *</span>
                                    <div className="position-relative">
                                        <Input
                                            type="text"
                                            id="contact_no"
                                            style={{ marginTop: '5px', borderColor: contactNumber === true && serviceProviderDetails.contact_no != '' ? '#87d068' : contactNumber === false && serviceProviderDetails.contact_no != '' ? '#fc4b6c' : '' }}
                                            name='contact_no'
                                            placeholder="+91-452-1234567890"
                                            autoComplete={'off'}
                                            className='form-control'
                                            // placeholder="1234567890"
                                            onChange={contactNumberOnChange}
                                            value={serviceProviderDetails.contact_no}
                                        // onKeyPress={(event) => {
                                        //     if (!/[0-9]/.test(event.key)) {
                                        //         event.preventDefault();
                                        //     }
                                        // }}
                                        // maxlength='10'
                                        />
                                        {contactNumber === true && serviceProviderDetails.contact_no != '' ?
                                            <i style={{ top: '7px', right: '7px' }} className="ri-check-line position-absolute text-success fs-5 fw-bold"></i>
                                            :
                                            contactNumber === false && serviceProviderDetails.contact_no != '' ?
                                                <i style={{ top: '7px', right: '7px' }} className="ri-close-line position-absolute text-danger fs-5 fw-bold"></i>
                                                : ''
                                        }
                                        {formValidator.current.message(
                                            'contact_no',
                                            serviceProviderDetails.contact_no,
                                            ['required',
                                                { regex: /^([0-9]{6}|[0-9]{7}|[0-9]{8}|[0-9]{9}|[0-9]{10})$/ }],
                                            {
                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Contact Number Required',
                                                    regex: 'Invalid Contact Number',

                                                }
                                            })}
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label className="control-label"><b>Mobile Number</b></label>
                                    <div className="position-relative">
                                        <Input
                                            type="text"
                                            id="contact_no"
                                            style={{ marginTop: '5px', borderColor: contactNumber === true && serviceProviderDetails.mobile_no != '' ? '#87d068' : contactNumber === false && serviceProviderDetails.mobile_no != '' ? '#fc4b6c' : '' }}
                                            name='contact_no'
                                            placeholder="+91-9876543210"
                                            autoComplete={'off'}
                                            className='form-control'
                                            // placeholder="1234567890"
                                            onChange={(e) => {
                                                setServiceProviderDetails({
                                                    ...serviceProviderDetails,
                                                    mobile_no: e.target.value
                                                })
                                            }}
                                            value={serviceProviderDetails.mobile_no}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <label><b>Address</b></label>
                                <TextArea className="form-control" rows={1} id="address" placeholder="Enter Address"
                                    name='address' onChange={serviceProviderOnChange}
                                    style={{ marginTop: '5px', width: '100%' }}
                                    value={serviceProviderDetails.address} maxlength='500'></TextArea>
                                {formValidator.current.message(
                                    'address',
                                    serviceProviderDetails.address,
                                    [
                                        `max:500`
                                    ],
                                    {
                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                        messages: {
                                            max: 'Maximum character is 500 only'
                                        }
                                    })}
                            </div>



                        </div>
                        <div className='row mt-5'>
                            <div className='col-md-3'></div>
                            <div className="col-md-3 form-actions popupbtncolour" style={{ textAlign: 'end' }}>
                                <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                                    icon={<PlayCircleOutlined />} size='default'
                                    onClick={addservice}>
                                    Submit
                                </Button>
                            </div>
                            <div className='col-md-3 form-actions popupbtncolour'>
                                <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                                    icon={<CloseCircleOutlined />} size='default'
                                    onClick={modalcancel}
                                >Cancel</Button>
                            </div>
                            <div className='col-md-3'></div>


                        </div>

                    </Drawer>
                </div>

                <Modal visible={passvisible} className="newStyleModalPassword modal-service-priv" footer={null} onCancel={passwordcancel}>
                    <div style={{ marginTop: '5px' }}>
                        <label className="control-label"> <b>Password </b><span style={{ "color": "red" }}>*</span></label>
                        <input
                            type="password"
                            id="password"
                            name='passwordvalue'
                            placeholder="Enter password"
                            onChange={(e) => {
                                Setpassword({
                                    ...passwordata,
                                    passvalue: e.target.value

                                })
                            }}
                            value={passwordata.passvalue}
                            style={{ marginTop: "5px", width: '100%' }}
                            className="form-control"
                        />
                        {formValidator1.current.message(
                            'Password',
                            passwordata.passvalue,
                            ['required'],
                            {
                                className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                messages: {
                                    required: 'Password Required'
                                }
                            })}

                    </div>
                    <br />
                    <div className="form-actions text-center popupbtncolour">
                        <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                            style={{ marginBottom: '3%' }}
                            icon={<PlayCircleOutlined />} size='default'
                            onClick={submitpassword}
                        >Submit
                        </Button>
                    </div>
                </Modal>
                <Modal visible={blockvisible} className='usr_modal_class user-priv-modal' footer={null}
                    onCancel={blockcancel}>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor=""><b>Password</b> <span style={{ "color": "red" }}>*</span></label>
                            <input
                                type="password"
                                id="password"
                                name='passwordvalue'
                                className="form-control"
                                placeholder="Enter password"
                                onChange={(e) => {
                                    Setblockdata({
                                        ...blockdata,
                                        password: e.target.value
                                    })
                                }}
                                style={{ width: '95%' }}
                                value={blockdata.password}
                            />
                            {formValidator2.current.message(
                                'Password',
                                blockdata.password,
                                ['required'],
                                {
                                    className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Password Required'
                                    }
                                })}

                        </div>
                    </div>
                    <br />
                    <div className="col-md-12">
                        <div className="row">
                            <label htmlFor=""><b>Remarks </b> <span style={{ "color": "red" }}>*</span></label></div>
                        <div className="row">
                            <div className="d-flex align-items-end">
                                <TextArea name="remarks" className='form-control' placeholder='Enter Reason'
                                    value={blockdata.remarks} id="remarks" cols="3" rows="1"
                                    onChange={(e) => {
                                        Setblockdata({
                                            ...blockdata,
                                            remarks: e.target.value
                                        })
                                    }}></TextArea>
                                <span className="ms-1 text-info d-flex 
                                align-items-center justify-content-center" style={{ float: 'right' }}
                                    onClick={() => { showRemarksModal() }}>
                                    <PlusCircleOutlined />
                                </span>
                            </div>
                            {formValidator2.current.message(
                                'remark',
                                blockdata.remarks,
                                ['required'],
                                {
                                    className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Remarks Required'
                                    }
                                })}</div>
                    </div>
                    <div className="form-actions text-center pt-3 popupbtncolour">
                        <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                            style={{ marginLeft: '-4%' }}
                            icon={<PlayCircleOutlined />} size='default'
                            onClick={blockservice}
                        >
                            Submit
                        </Button>
                    </div>
                </Modal>
                <Modal title="Remarks List" footer={null} visible={remarksModal} className={"remark-header add-service-prv " + localStorage.getItem('currentTheme')}
                    onOk={handleOk}
                    onCancel={handleCancel}>

                    <Table
                        size={'small'}
                        columns={RemarksColumn}
                        dataSource={remarksvalue && remarksvalue.length > 0 && remarksvalue}
                        bordered
                        pagination={false}
                    />

                </Modal>
            </div>
        </Fragment>
    )
}

export default connect(mapStateToProps, {
    providerlist,
    addprovider,
    updatestatus,
    updateblock,
    updateprovider,
    GetRemarksData
})(Serviceproviderlist);