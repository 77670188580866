import React, { useEffect, useState, useRef, Fragment } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Route, Switch, BrowserRouter, Link, useLocation } from 'react-router-dom';
import { Collapse, Input, Modal, Table, List, Row, Tooltip, DatePicker, Select, Tree, Button, Checkbox } from 'antd';
import { PlayCircleOutlined, DeleteOutlined, PlusOutlined, FilterOutlined, FullscreenOutlined, PlusCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import SimpleReactValidator from "simple-react-validator";
import DataTable from "react-data-table-component";
import Swal from 'sweetalert2';
import { GetGroupListData, FetchEmails, emailsave, verifypassword, updateblockoption } from '../../Store/Action/Master/CV_CertificateMailMapping';
import { compliancefilters, GetOnOccurrenceCompliances, onoccurrencetransaction, startonoccurrencecompliance } from '../../Store/Action/Transcations/EXE_OnOccurrenceCompliance';
import { constant, filter, indexOf, merge } from 'lodash';
import { GetRemarksData } from '../../Store/Action/Transcations/Taskrecall'
import { Toaster } from '../../Libs/Toaster';
import { toast } from 'react-toastify';
import EXE_OnOccurrenceCompliance from '../../Store/Reducer/Transactions/EXE_OnOccurrenceCompliance';
import { PASSWRD_MODAL_HANDLE, RESET_REMARK_HISTORY, RESET_VIEW_HISTORY } from '../../Store/types';

import moment from 'moment';
const _ = require("lodash");

const mapStateToProps = (state) => ({
    onoccurrencecompliances: state.EXE_OnOccurrenceCompliance.onoccurrencecompliance,
    filterrecall: state.recallfilters.recallfilters    
})

const OnOccurrenceCompliance = ({
    compliancefilters,
    onoccurrencetransaction,
    GetOnOccurrenceCompliances,
    startonoccurrencecompliance,
    GetRemarksData,
    filterrecall: { recallremarks },
    onoccurrencecompliances: { compliancefilter, compliancefiltershow, complianceview , modalVisible}
}) => {
    console.log(recallremarks,'recallremarks');
    console.log(compliancefiltershow,'compliancefiltershow');
    const { Panel } = Collapse;
    const { Option } = Select;
    const { Search, TextArea } = Input;
    const location = useLocation();
    const [show, setShow] = useState(false)
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const paramid = location.pathname;
    let [count, setcount] = useState(0)
    // const entityid = localStorage.getItem('SelectedEntityid');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const [remarksModal, setRemarksModal] = useState(false);
    console.log(remarksModal,'remarksModal');
    const validator = useRef(new SimpleReactValidator());
    const validator1 = useRef(new SimpleReactValidator());
    const validator2 = useRef(new SimpleReactValidator());
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    // const [modalVisible, setModaVisible] = useState(false)
    const [taskvalue, setTaskvalue] = useState()
    const [userId, setUserId] = useState('')
    const [legalentity, setLegalentity] = useState()
    const [modalData, setModalData] = useState({
        password: '',
    })
    const [compliancedata, setComplianceData] = useState([]);
    const [RemarksDetail, setRemarksDetail] = useState([])
    const [mail, setMail] = useState([]);
    const [tempshowdata, setTempshowdata] = useState([]);
    console.log('tempPPPPPP',tempshowdata)
    const [viewhistory, SetViewhistory] = useState(false);
    const [tempvalue, setTempvalue] = useState([])
    const [remarksvalue, Setremarksvalue] = useState();
    const [tempcomp, Settempcomp] = useState();
    const [remarksId, setRemarksId] = useState([])
    const [page, setPage] = useState(1);
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [exitCollapse, setExitCollapse] = useState(false);
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    console.log('COMPLAINCE DATE IN EVERY UPDATE', compliancedata)
    const dispatch = useDispatch()

  const [fullscreen, setfullscreen] = useState(false);
  const fullscreenMode = () => {
    let topBar = document.getElementById('top-nav')
    let leftbar = document.getElementsByClassName('left-sidebar');
    let leftnone = leftbar[0];
    let pageWrapper = document.getElementById('page-wrapper')
    if (fullscreen == false) {
      document.documentElement.requestFullscreen();
      topBar.style.display = 'none'
      leftnone.style.display = 'none'
      pageWrapper.style.padding = '0'
      pageWrapper.style.marginLeft = '0'
      setfullscreen(true);
    } else {
      document.exitFullscreen();
      topBar.style.display = 'block'
      leftnone.style.display = 'block'
      pageWrapper.style.marginLeft = '65px'
      pageWrapper.style.paddingTop = '70px'
      setfullscreen(false);
    }
  }
    console.log(remarksModal, 'MODAL VISIBLITY CHECK')

    
    const RemarksColumn = [
        {
            title: 'S.No',
            dataIndex: false,
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            }
        },
        {
            title: 'Description',
            dataIndex: 'r_description',
            key: 'r_description',
            width: '200px',
            ellipsis: true,
            render: (text, record) => <a onClick={() => {
                let temp = compliancedata
                let tempdate = ''
                console.log('BEFORE UPDATE IN REMARK',temp)
                
                if (temp && temp.length > 0) {
                    let tempval = _.filter(temp, { compid: tempcomp })
                    if (tempval.length > 0) {
                        for (let i in temp) {
                            if (temp[i].compid == tempcomp) {
                                temp.splice(i, 1);
                                console.log('REMARKS ADDED UPDATE',tempval)
                            }
                        }
                        temp.push({
                            compid: tempcomp,
                            startdate: tempval ? tempval[0].startdate : '',
                            remarks: text
                        })
                        setComplianceData([...temp])
                    } else {
                        temp.push({
                            compid: tempcomp,
                            startdate: '',
                            remarks: text
                        })
                        setComplianceData([...temp])
                    }
                } else {
                    temp.push({
                        compid: tempcomp,
                        startdate: '',
                        remarks: text
                    })
                    setComplianceData([...temp])
                }
                RemarksDetail[remarksId] = text
                setRemarksDetail(RemarksDetail)
                setRemarksDetail(RemarksDetail)
                setRemarksModal(false)
            }}>{record.r_description}</a>,
        },
    ]

    // useEffect(() => {
        
    // })

    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') == null || localStorage.getItem('SelectedEntityid') == 'null') {

        } else {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
            if (localStorage.getItem('SelectedEntityid')) {
                // let mail = _.filter(compliancefilter, { legal_entity_id: localStorage.getItem('SelectedEntityid') })
                // setMail(compliancefilter.user_units)

                setLegalentity(localStorage.getItem('SelectedEntityid'))
            }
        }
    }, [localStorage.getItem('SelectedEntityid'), compliancefilter, entityid])
    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
      };
      const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
      };
    
    useEffect(() => {
        if (recallremarks && recallremarks.length > 0) {
            console.log( recallremarks.length,' recallremarks.length');
            let temp = [];
            for (let i = 0; i < recallremarks.length; i++) {
                if (recallremarks[i].r_description != '') {
                    temp.push(recallremarks[i])
                }
                Setremarksvalue(temp)
            }
            console.log(temp,temp.length,'temptemp');
            console.log('REMARK VALUES IN MODAL', temp)
            if (temp && temp.length == 0) {
                Toaster.error("No Remarks Availablee")
            } 
            else {
                setRemarksModal(true);
                dispatch({
                    type: RESET_REMARK_HISTORY
                })
            }
        }

    }, [recallremarks,recallremarks.length > 0])

    useEffect(() => {
        if(localStorage.getItem('SelectedEntity') != "All Legal Entity"){
            setShow(true)
        }
    },[])

    useEffect(() => {
        let tempcheck = []
        let tempcheck1 = []
        for (var key in compliancefiltershow.onoccur_compliances) {
            let tempArr = []
            if (compliancefiltershow.onoccur_compliances.hasOwnProperty(key)) {
                for (let i in compliancefiltershow.onoccur_compliances[key]) {
                    tempArr.push(compliancefiltershow.onoccur_compliances[key][i])
                }
                tempcheck = {
                    parent: key,
                    child: tempArr
                }
                tempcheck1.push(tempcheck)
            }
        }
        setTempshowdata(tempcheck1)
    }, [compliancefiltershow])

    useEffect(() => {
        if (complianceview && complianceview.onoccurrence_transactions && complianceview.onoccurrence_transactions.length > 0) {
            SetViewhistory(true)
        } else {
            if (complianceview && complianceview.onoccurrence_transactions && complianceview.onoccurrence_transactions.length == 0)
                Toaster.error("No history available")
        }
    }, [complianceview])

    useEffect(() => {
        if (compliancefilter) {
            setMail(compliancefilter && compliancefilter.user_units)
        }

    }, [compliancefilter])

    useEffect(() => {
        if (isAuth) {
            if (entityid !== '') {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "ComplianceFilters",
                            {
                                "le_id": entityid
                            }
                        ]
                    }
                ]
                if (entityid != 'null') {
                    compliancefilters({
                        payload: payload,
                        paramid: paramid
                    })
                }
            }
        }
    }, [isAuth, entityid])

    useEffect(() => {
        validator.current.showMessages()
        validator1.current.showMessages()
        validator2.current.showMessages()
    }, [])

    const OnCompliance = (data) => {
        const lasttransaction = [
            authtoken,
            {
                "session_token": authtoken,
                "request":
                    [
                        "OnOccurrenceLastTransaction",
                        {
                            "le_id": entityid,
                            "compliance_id": data.compliance_id,
                            "unit_id": data.unit_id
                        }
                    ]
            }
        ]
        onoccurrencetransaction({
            payload: lasttransaction,
            paramid: paramid
        })
    }

    const handleCancel = () => {
        setRemarksModal(false)
    };

    const startcompliance = (items) => {
        setModalData({
            ...modalData,
            password: ''
        })
        setAddFormSubmit1(false)
        setTempvalue(items)
        let task = _.filter(compliancedata, { compid: items.compliance_id })
        setTaskvalue(task)
        if (task.length == 0) {
            Toaster.error("Start Date Required")
        } else {
            if (task[0].startdate == '') {
                Toaster.error("Start Date Required")
            } else if (task[0].remarks == '') {
                Toaster.error("Remarks Required")
            } else {
                Swal.fire({
                    title: 'Are you sure?',
                    text: 'You want to start the Compliance "Application for duplicate Registration Certificate or License"',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }).then((result) => {
                    if (result.isConfirmed) {
                        // setModaVisible(true)
                        dispatch({
                            type: PASSWRD_MODAL_HANDLE,
                            payload: true
                        })
                    }
                })
            }
        }
        // if (task.length == 1) {
        //     Toaster.error("Remarks Required")
        // }
        // if (task.length == 2) {
        //     if (task && task[0].remarks == '') {
        //         Toaster.error("Remarks Required")
        //     } else if (task && task[1].remarks == '') {
        //         Toaster.error("Remarks Required")
        //     } else {
        //         Swal.fire({
        //             title: 'Are you sure?',
        //             text: 'You want to start the Compliance "Application for duplicate Registration Certificate or License"',
        //             showCancelButton: true,
        //             confirmButtonText: 'Yes',
        //             cancelButtonText: 'No',
        //         }).then((result) => {
        //             if (result.isConfirmed) {
        //                 setModaVisible(true)
        //             }
        //         })
        //     }
        // }
    }

    const OnSubmited = () => {
        setAddFormSubmit1(true)
        if (validator1.current.allValid()) {
            startonoccurrencecompliance({
                payload: [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "StartOnOccurrenceCompliance",
                            {
                                "le_id": entityid,
                                "ac_id": tempvalue.ac_id,
                                "compliance_id": tempvalue.compliance_id,
                                "start_date": moment(taskvalue[0].startdate ).format('DD-MMM-YYYY hh:mm'),
                                "unit_id": tempvalue.unit_id,
                                "duration": tempvalue.complete_within_days,
                                "remarks": taskvalue[0].remarks,
                                "password": modalData.password
                            }
                        ]
                    }
                ],
                paramid: paramid
            })
           // setModaVisible(false)
        }
    }

    const passwordcancel = () => {
        // setModaVisible(false)
        dispatch({
            type: PASSWRD_MODAL_HANDLE,
            payload: false
        })
        setModalData({
            ...modalData,
            password: ""
        })
    }

    const showRemarksModal = () => {
        GetRemarksData({
            payload:
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetRemarksData",
                            {}
                        ]
                    }
                ],
            paramid: paramid
        })
    };

    const homescreen = () => {
        window.location.href = '/dashboard'
    }
    return (
        <div className="page-wrapper" id='page-wrapper'>
            <div className="page-titles pb-2 pt-2">
                <div className="row">
                        <nav
              className="col-lg-6 col-md-6 col-12 align-self-center" aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <Link className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i>
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <span style={{ color: '#1890ff' }}>Transaction</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: "large", fontWeight: 'bold' }}>
                                    <span style={{ color: '#1890ff' }}>On Occurrence Compliance</span>
                                </li>
                            </ol>
                        </nav>
                        <div className="col-lg-6 text-end">
                            <Button 
                className="mx-1"
                style={{
                    display: filterTaskAcc ? "none" : "initial",
                  }}
                type="primary"
                shape="round" disabled>

                            {"Total compliance"} : {compliancefiltershow.total_count ? compliancefiltershow.total_count : '0'}
                            </Button>
                            <Button 
                className="mx-1"
                style={{
                    display: filterTaskAcc ? "none" : "initial",
                  }}
                type="primary"
                shape="round" disabled>

                            {"Total Units"} : {tempshowdata ? tempshowdata.length : '0'}
                            </Button>

                        <Button
                className="mx-1"
                type="primary"
                shape="round"
                style={{
                  display: filterTaskAcc ? "none" : "initial",
                }}
                onClick={filterHandle}
              >
                <FilterOutlined /> Filter
              </Button>

              <button
                onClick={(e) => {
                  fullscreenMode();
                }}
                className="bg-transparent text-black ms-1 full-mode fullscreenradius"
              >
                <Tooltip
                  placement="left"
                  title="Full Screen Mode"
                >
                  <FullscreenOutlined />
                </Tooltip>
              </button>
                        </div>
                    </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-lg-12">
                        <div style={{ borderRadius: '0px' }} className={localStorage.getItem('currentTheme')}>
                            <Collapse accordion defaultActiveKey={['2']} 
                  style={{ display: exitCollapse ? "none" : "block" }}>
                                <Panel header="On Occurrence Compliance" key="2">
                                <div className="row">
                                    <div className='col-lg-5'>
                                        <label htmlFor=""><b>Legal Entity : </b> <span style={{ "color": "red" }}>*</span></label>
                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                            <div className="form-group">
                                                <Select style={{ marginTop: "5px", width: "100%" }}
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Select Legal Entity"
                                                    onChange={(value, data) => {
                                                        // let mail = _.filter(Mailmapping, { legal_entity_id: value })
                                                        // setMail(mail)
                                                        if (value !== undefined) {
                                                            setCurrentEntity(value)
                                                            setLegalentity(value)
                                                            setUserId('')
                                                            setShow(false)
                                                        }
                                                        else {
                                                            setCurrentEntity('')
                                                            setLegalentity('')
                                                            setUserId('')
                                                            setShow(false)

                                                        }
                                                    }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    //  value={modalData.legal_entity}
                                                    display="none"
                                                >
                                                    {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                        return (
                                                            <Option title="" key={item.le_id}>
                                                                {item.le_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                                {validator.current.message(
                                                    'legalentity',
                                                    legalentity,
                                                    ['required'],
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Legal Entity Required',
                                                        }
                                                    })}
                                            </div> :
                                            <p>{localStorage.getItem('SelectedEntity')}</p>
                                        }
                                    </div>
                                    <div className='col-lg-5'>
                                        <label htmlFor=""><b>Unit :</b></label>
                                        <Select style={{ marginTop: "5px" }}
                                            labelInValue={false}
                                            allowClear={true}
                                            onFocus={false}
                                            onSelect={false}
                                            disabled={entityid == null || entityid == 'null' ? true : false}
                                            className='form-control'
                                            placeholder="Select Unit"
                                            onChange={(data, value) => {
                                                if (data !== undefined) {
                                                    setUserId(data)
                                                    setShow(false)
                                                }
                                                else {
                                                    setUserId('')
                                                    setShow(false)
                                                }
                                            }}
                                            showSearch
                                            value={userId || undefined}
                                            optionFilterProp="children"
                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        >
                                            {mail && mail.length && mail.map((item) => {
                                                return (
                                                    <Option title="" key={item.unit_id}>
                                                        {item.unit_name}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </div>
                                    <div className="col-md-2 popupbtncolour">
                                            <Button
                                                onClick={() => {
                                                    const payload = [
                                                        authtoken,
                                                        {
                                                            "session_token": authtoken,
                                                            "request": [
                                                                "GetOnOccurrenceCompliances",
                                                                {
                                                                    "le_id": entityid,
                                                                    "unit_id": userId ? Number(userId) : null,
                                                                    "start_count": 0
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                    setAddFormSubmit(true);
                                                   
                                                    if (validator.current.allValid()) {
                                                        setExitCollapse(true);
                                                        setFilterModalVisible(false);
                                                        setFilterTaskAcc(false);
                                                        setShow(true)
                                                        GetOnOccurrenceCompliances({
                                                            payload: payload,
                                                            paramid: paramid
                                                        })
                                                    }
                                                }}
                                                type="primary"
                                                shape="round"
                                                className={'addbutton ' + localStorage.getItem('currentTheme')}
                                                style={{
                                                    marginTop: "20px"
                                                }}
                                            >
                                                Show
                                            </Button>
                                        </div>
                                </div>
                                       
                                {/* {show == true ?
                                    <>
                                        <div className="col-lg-12" style={{ marginTop: '15px' , marginBottom : ' 15px' }}>
                                            <div className="row">
                                                <div className={'ant-table-wrapper ' + localStorage.getItem('currentTheme')}>
                                                    <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                                        <div className='ant-table-container'>
                                                            <div className='ant-table-content'>
                                                                <table className='table-fixed'>
                                                                    <colgroup>
                                                                        <col style={{ width: '10px' }}></col>
                                                                    </colgroup>
                                                                    <thead className='ant-table-thead'>
                                                                        <tr>
                                                                            <th className='ant-table-cell' style={{ width: '10px' }}>#</th>
                                                                            <th className='ant-table-cell' style={{ width: '200px' }}>Statutory</th>
                                                                            <th className='ant-table-cell' style={{ width: '200px' }}><center>Compliance Task</center></th>
                                                                            <th className='ant-table-cell' style={{ width: '200px' }}><center>Description</center></th>
                                                                            <th className='ant-table-cell' style={{ width: '120px' }}><center>To complete within</center></th>
                                                                            <th className='ant-table-cell' style={{ width: '200px' }}><center>Start Date <span style={{ "color": "red" }}>*</span></center></th>
                                                                            <th className='ant-table-cell' style={{ width: '230px' }}><center>Remarks <span style={{ "color": "red" }}>*</span></center></th>
                                                                            <th className='ant-table-cell' style={{ width: '150px' }}><center>Start</center></th>
                                                                        </tr>
                                                                    </thead>
                                                                    {tempshowdata?.length > 0 ?
                                                                        <tbody className='ant-table-tbody'>
                                                                            {tempshowdata && tempshowdata.length > 0 && tempshowdata.map((items, j) => {
                                                                                return <>
                                                                                    <tr className='ant-table-row ant-table-row-level-0'>
                                                                                        <td colSpan={8} className='ant-table-cell'><b>{items.parent}</b></td>
                                                                                    </tr>
                                                                                    {items.child.map((item, i) => {
                                                                                        return <>
                                                                                            <tr>
                                                                                                <td className='ant-table-cell'>{count + 1}</td>
                                                                                                <td title={item.statutory_provision} className='ant-table-cell ellipsis'>{item.statutory_provision}</td>
                                                                                                <td title={item.compliance_name} className='ant-table-cell ellipsis' onClick={() => { OnCompliance(item) }}><a href='javascript:;'>{item.compliance_name}</a></td>
                                                                                                <td title={item.description} className='ant-table-cell ellipsis'>{item.description}</td>
                                                                                                <td className='ant-table-cell'><center>{item.complete_within_days}</center></td>
                                                                                                <td className='ant-table-cell'>
                                                                                                    <DatePicker
                                                                                                        disabledDate={(current) => {
                                                                                                            return current > Date.now()
                                                                                                        }}
                                                                                                        format={'DD-MMM-YYYY'}
                                                                                                        style={{ width: '100%', marginTop: '5px' }}
                                                                                                        placeholder='DD-MM-YYYY'
                                                                                                        allowClear={false}
                                                                                                     
                                                                                                        onChange={(date, dateString) => {
                                                                                                            let temp = compliancedata
                                                                                                            let tempremarks
                                                                                                            let tempdate
                                                                                                            if (temp.length > 0) {
                                                                                                                for (let i in temp) {
                                                                                                                    if (temp[i].compid == item.compliance_id) {
                                                                                                                        tempremarks = temp[i].remarks
                                                                                                                        temp.splice(i, 1);
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                            temp.push({
                                                                                                                compid: item.compliance_id,
                                                                                                                startdate: dateString,
                                                                                                                remarks: tempremarks ? tempremarks : ''
                                                                                                            })
                                                                                                            console.log(dateString, 'dateString value')
                                                                                                            console.log(temp, 'AFTER DATESTRING ADDED')
                                                                                                            setComplianceData([...temp])
                                                                                                            console.log(compliancedata,'COMPLIANCE DATA AFTER SETTING DATA')
                                                                                                        }}
                                                                                                    />
                                                                                                </td>
                                                                                                <td className='ant-table-cell d-flex align-items-end mt-1'>
                                                                                                    <TextArea name="remarks" className='form-control mt-1' placeholder='Remark'
                                                                                                        defaultValue={RemarksDetail[i]}
                                                                                                        key={RemarksDetail[i]}
                                                                                                        maxLength={500} id="remarks" cols="5" rows="1"
                                                                                                        
                                                                                                        onBlur={(e) => {
                                                                                                            let temp1 = compliancedata
                                                                                                            let tempdate = ''
                                                                                                            for (let i in temp1) {
                                                                                                                if (temp1[i].compid == item.compliance_id) {
                                                                                                                    if (temp1[i].remarks != undefined) {
                                                                                                                        tempdate = temp1[i].startdate
                                                                                                                        temp1.splice(i, 1);
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                            temp1.push({
                                                                                                                compid: item.compliance_id,
                                                                                                                startdate: tempdate ? tempdate : '',
                                                                                                                remarks: e.target.value
                                                                                                            })
                                                                                                            setComplianceData([...temp1])
                                                                                                            RemarksDetail[i] = e.target.value
                                                                                                            setRemarksDetail(RemarksDetail)
                                                                                                        }}
                                                                                                    >
                                                                                                    </TextArea>
                                                                                                    <span className="ms-2 btn btn-sm btn-light-info text-info btn-circle d-flex align-items-center justify-content-center" style={{ float: 'right', marginBottom: '1    em' }}
                                                                                                        onClick={() => {
                                                                                                            showRemarksModal()
                                                                                                            setRemarksId(i)
                                                                                                            Settempcomp(item.compliance_id)
                                                                                                        }}
                                                                                                    >
                                                                                                        <PlusOutlined />
                                                                                                    </span>
                                                                                                </td>
                                                                                                <td className='ant-table-cell'><center>
                                                                                                    <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                                                                        size='default' onClick={() => startcompliance(item)}>
                                                                                                        Start
                                                                                                    </Button></center></td>
                                                                                                <span hidden>{count = count + 1}</span>
                                                                                            </tr>

                                                                                        </>
                                                                                    })}
                                                                                </>
                                                                            })}
                                                                        </tbody>
                                                                        :
                                                                        <tr>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td>NO RECORDS FOUND</td>
                                                                        </tr>
                                                                    }
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <span>{"Total compliance"} : {compliancefiltershow.total_count}</span>
                                    </>
                                    : ""} */}

                        </Panel>
                            </Collapse>
                            <Modal
                  onCancel={setcancelFilter}
                  maskClosable={false}
                  footer={false}
                  title="On Occurrence Compliance"
                  className={
                    "add-service-prv remark-header cons-report " +
                    localStorage.getItem("currentTheme")
                  }
                  visible={filterModalVisible}
                > 
                    <div className="row">
                        <div className='col-lg-5'>
                            <label htmlFor=""><b>Legal Entity : </b> <span style={{ "color": "red" }}>*</span></label>
                            {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                <div className="form-group">
                                    <Select style={{ marginTop: "5px", width: "100%" }}
                                        allowClear={true}
                                        size="default"
                                        placeholder="Select Legal Entity"
                                        // onChange={(value, data) => {
                                        //     // let mail = _.filter(Mailmapping, { legal_entity_id: value })
                                        //     // setMail(mail)
                                        //     if (value !== undefined) {
                                        //         setCurrentEntity(value)
                                        //         setLegalentity(value)
                                        //         setUserId('')
                                        //         setShow(false)      
                                        //     }
                                        //     else {
                                        //         setCurrentEntity('')
                                        //         setLegalentity('')
                                        //         setUserId('')
                                        //         setShow(false)

                                        //     }
                                        // }} 
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        //  value={modalData.legal_entity}
                                        display="none"
                                    >
                                        {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                            return (
                                                <Option title="" key={item.le_id}>
                                                    {item.le_name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                    {validator.current.message(
                                        'legalentity',
                                        legalentity,
                                        ['required'],
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Legal Entity Required',
                                            }
                                        })}
                                </div> :
                                <p>{localStorage.getItem('SelectedEntity')}</p>
                            }
                        </div>
                        <div className='col-lg-5'>
                            <label htmlFor=""><b>Unit :</b></label>
                            <Select style={{ marginTop: "5px" }}
                                labelInValue={false}
                                allowClear={true}
                                onFocus={false}
                                onSelect={false}
                                disabled={entityid == null || entityid == 'null' ? true : false}
                                className='form-control'
                                placeholder="Select Unit"
                                onChange={(data, value) => {
                                    if (data !== undefined) {
                                        setUserId(data)
                                        setShow(false)
                                    }
                                    else {
                                        setUserId('')
                                        setShow(false)
                                    }
                                }}
                                showSearch
                                value={userId || undefined}
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                            >
                                {mail && mail.length && mail.map((item) => {
                                    return (
                                        <Option title="" key={item.unit_id}>
                                            {item.unit_name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                        <div className="col-md-2 popupbtncolour">
                                <Button
                                    onClick={() => {
                                        const payload = [
                                            authtoken,
                                            {
                                                "session_token": authtoken,
                                                "request": [
                                                    "GetOnOccurrenceCompliances",
                                                    {
                                                        "le_id": entityid,
                                                        "unit_id": userId ? Number(userId) : null,
                                                        "start_count": 0
                                                    }
                                                ]
                                            }
                                        ]
                                        setAddFormSubmit(true);
                                        setExitCollapse(true);
                                        setFilterModalVisible(false);
                                        setFilterTaskAcc(false);
                                        if (validator.current.allValid()) {
                                            setShow(true)
                                            GetOnOccurrenceCompliances({
                                                payload: payload,
                                                paramid: paramid
                                            })
                                        }
                                    }}
                                    type="primary"
                                    shape="round"
                                    className={'addbutton ' + localStorage.getItem('currentTheme')}
                                    style={{
                                        marginTop: "20px"
                                    }}
                                >
                                    Show
                                </Button>
                            </div>
                    </div>
                           
                  

            </Modal>  {show == true ?
                        <>
                            <div className="col-lg-12" style={{ marginTop: '15px' , marginBottom : ' 15px' }}>
                                <div className="row">
                                    <div className={'ant-table-wrapper ' + localStorage.getItem('currentTheme')}>
                                        <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                            <div className='ant-table-container'>
                                                <div className='ant-table-content'>
                                                    <table className='table-fixed'>
                                                        <colgroup>
                                                            <col style={{ width: '10px' }}></col>
                                                        </colgroup>
                                                        <thead className='ant-table-thead'>
                                                            <tr>
                                                                <th className='ant-table-cell' style={{ width: '10px' }}>#</th>
                                                                <th className='ant-table-cell' style={{ width: '230px' }}>Statutory</th>
                                                                <th className='ant-table-cell' style={{ width: '230px' }}><center>Compliance Task</center></th>
                                                                {/* <th className='ant-table-cell' style={{ width: '200px' }}><center>Description</center></th> */}
                                                                <th className='ant-table-cell' style={{ width: '110px' }}><center>Complete within</center></th>
                                                                <th className='ant-table-cell' style={{ width: '130px' }}><center>Start Date <span style={{ "color": "red" }}>*</span></center></th>
                                                                <th className='ant-table-cell' style={{ width: '150px' }}><center>Remarks <span style={{ "color": "red" }}>*</span></center></th>
                                                                <th className='ant-table-cell' style={{ width: '100px' }}><center>Start</center></th>
                                                            </tr>
                                                        </thead>
                                                        {tempshowdata?.length > 0 ?
                                                            <tbody className='ant-table-tbody'>
                                                                {tempshowdata && tempshowdata.length > 0 && tempshowdata.map((items, j) => {
                                                                    return <>
                                                                        <tr className='ant-table-row ant-table-row-level-0'>
                                                                            <td colSpan={8} className='ant-table-cell'><b style={{backgroundColor: 'lightyellow'}}>{items.parent}</b></td>
                                                                        </tr>
                                                                        {items.child.map((item, i) => {
                                                                            return <>
                                                                                <tr>
                                                                                    <td className='ant-table-cell'>{count + 1}</td>
                                                                                    <td  className='ant-table-cell '>{item.statutory_provision}</td>
                                                                                    <td  className='ant-table-cell ' onClick={() => { OnCompliance(item) }}><Tooltip title={item.description}><InfoCircleOutlined className='text-info px-1'/></Tooltip><a href='javascript:;'>{item.compliance_name}</a></td>
                                                                                    {/* <td title={item.description} className='ant-table-cell ellipsis'>{item.description}</td> */}
                                                                                    <td className='ant-table-cell'><center>{item.complete_within_days}</center></td>
                                                                                    <td className='ant-table-cell'>
                                                                                        <DatePicker
                                                                                            // value={'22-Jul-2022'}
                                                                                            disabledDate={(current) => {
                                                                                                return current > Date.now()
                                                                                            }}
                                                                                            format={'DD-MMM-YYYY'}
                                                                                            style={{ width: '100%', marginTop: '5px' }}
                                                                                            placeholder='DD-MM-YYYY'
                                                                                            allowClear={false}
                                                                                            // onKeyPress={(event) => {
                                                                                            //     console.log(event,'eventevent');
                                                                                            //     if (!/[a-zA-Z ]/.test(event.key)) {
                                                                                            //         event.preventDefault();
                                                                                            //     }
                                                                                            // }}
                                                                                            onChange={(date, dateString) => {
                                                                                                let temp = compliancedata
                                                                                                let tempremarks
                                                                                                let tempdate
                                                                                                if (temp.length > 0) {
                                                                                                    for (let i in temp) {
                                                                                                        if (temp[i].compid == item.compliance_id) {
                                                                                                            tempremarks = temp[i].remarks
                                                                                                            temp.splice(i, 1);
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                                temp.push({
                                                                                                    compid: item.compliance_id,
                                                                                                    startdate: dateString,
                                                                                                    remarks: tempremarks ? tempremarks : ''
                                                                                                })
                                                                                                console.log(dateString, 'dateString value')
                                                                                                console.log(temp, 'AFTER DATESTRING ADDED')
                                                                                                setComplianceData([...temp])
                                                                                                console.log(compliancedata,'COMPLIANCE DATA AFTER SETTING DATA')
                                                                                            }}
                                                                                        />
                                                                                    </td>
                                                                                    <td className='ant-table-cell'>
                                                                                        <div className="d-flex align-items-end">
                                                                                        <TextArea name="remarks" className='form-control ' placeholder='Remark'
                                                                                            // value={modalData.password || undefined}
                                                                                            defaultValue={RemarksDetail[i]}
                                                                                            key={RemarksDetail[i]}
                                                                                            maxLength={500} id="remarks" cols="5" rows="1"
                                                                                            
                                                                                            onBlur={(e) => {
                                                                                                let temp1 = compliancedata
                                                                                                let tempdate = ''
                                                                                                for (let i in temp1) {
                                                                                                    if (temp1[i].compid == item.compliance_id) {
                                                                                                        if (temp1[i].remarks != undefined) {
                                                                                                            tempdate = temp1[i].startdate
                                                                                                            temp1.splice(i, 1);
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                                temp1.push({
                                                                                                    compid: item.compliance_id,
                                                                                                    startdate: tempdate ? tempdate : '',
                                                                                                    remarks: e.target.value
                                                                                                })
                                                                                                setComplianceData([...temp1])
                                                                                                RemarksDetail[i] = e.target.value
                                                                                                setRemarksDetail(RemarksDetail)
                                                                                            }}
                                                                                        >
                                                                                        </TextArea>
                                                                                        <span className="ms-0 btn btn-sm  text-info d-flex align-items-end justify-content-center" style={{ float: 'right', marginBottom: '1    em' }}
                                                                                            onClick={() => {
                                                                                                showRemarksModal()
                                                                                                setRemarksId(i)
                                                                                                Settempcomp(item.compliance_id)
                                                                                            }}
                                                                                        >
                                                                                            <PlusCircleOutlined />
                                                                                        </span></div>
                                                                                    </td>
                                                                                    {/* {recallSubmit.current.message(
                                                                                    'remarks',
                                                                                    recalldata.remarks,
                                                                                    ['required'],
                                                                                    {
                                                                                        className: `invalid-feedback ${FormRecall ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            required: 'Remarks Required',
                                                                                        }
                                                                                    })} */}
                                                                                    <td className='ant-table-cell'><center className='popupbtncolour'>
                                                                                        {/* <Button type="primary" shape="round" className='addbutton' 
                                                                                            size='default' onClick={() => startcompliance(item)}>
                                                                                            Start
                                                                                        </Button> */}
                                                                                       <Tooltip title={'Start'}> <PlayCircleOutlined className={localStorage.getItem('currentTheme')}  style={{fontSize: '20px', padding: '5px', borderRadius: '50%'}}  onClick={() => startcompliance(item)} />
                                                                                      </Tooltip>  </center></td>
                                                                                    <span hidden>{count = count + 1}</span>
                                                                                </tr>

                                                                            </>
                                                                        })}
                                                                    </>
                                                                })}
                                                            </tbody>
                                                            :
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td>NO RECORDS FOUND</td>
                                                            </tr>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        : ""}
                        </div>
                    </div>
                </div>
            </div>
            <Modal title={"Enter Password"} visible={modalVisible ? modalVisible : false} footer={null}
                className='usr_modal_class' onCancel={passwordcancel}>
                <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor=""><b>Password</b> <span style={{ "color": "red" }}>*</span></label>
                        <input
                            type="password"
                            id="password"
                            name='password'
                            className="form-control"
                            placeholder="password"
                            value={modalData.password}
                            onChange={(e) => {
                                setModalData({
                                    ...modalData,
                                    password: e.target.value
                                })
                            }} />
                        {validator1.current.message(
                            'password',
                            modalData.password,
                            'required',
                            {
                                className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                messages: {
                                    required: 'Password Required',
                                }
                            })}
                    </div>
                </div>
                <br />
                <div className="form-actions">
                    <Button type="primary" shape="round" className='addbutton'
                        style={{ background: "#198754", borderColor: "#198754", marginLeft: '33%' }}
                        icon={<PlayCircleOutlined />} size='default'
                        onClick={() => { OnSubmited() }}
                    >
                        Submit
                    </Button>
                </div>

            </Modal>
            <Modal title="Remarks List" visible={remarksModal}
                onCancel={handleCancel}
                className={"remarksClass add-service-prv " + localStorage.getItem('currentTheme')} footer={false}>
                <Table
                    // className='userprivclass'
                    size={'small'}
                    columns={RemarksColumn}
                    dataSource={remarksvalue && remarksvalue.length > 0 ? remarksvalue : ''}
                    bordered
                    pagination={false}
                />
            </Modal>
            <Modal className={'add-service-prv  w-75 ' + localStorage.getItem('currentTheme')} title={"Compliance History"} visible={viewhistory} footer={null} maskClosable={false} onCancel={() => {
            SetViewhistory(false)
            dispatch({
                type: RESET_VIEW_HISTORY
            })}} >
                <div className="row exe-desc">
                        <p style={{ float: "right" }}> <b> Statutory :  </b> {complianceview && complianceview.onoccurrence_transactions && complianceview.onoccurrence_transactions.length > 0 && complianceview.onoccurrence_transactions[0].on_statutory}
                   </p>
                    
                      
                </div>
                <div className="row exe-desc" style={{ marginTop: '5px' }}>
                    
                        <label style={{ float: "right" }}><b>Unit :</b> {complianceview && complianceview.onoccurrence_transactions && complianceview.onoccurrence_transactions.length > 0 && complianceview.onoccurrence_transactions[0].on_unit}
                  </label>
                   
                
                       
                </div>
                <div className="row exe-desc" style={{ marginTop: '5px' }}>
                    
                        <label style={{ float: "right" }}><b>Compliance Task :</b> {complianceview && complianceview.onoccurrence_transactions && complianceview.onoccurrence_transactions.length > 0 && complianceview.onoccurrence_transactions[0].compliance_task}
                </label>
            
                       
                </div>
                <div className="row exe-desc" style={{ marginTop: '5px' }}>
                    
                        <label style={{ float: "right" }}><b>Compliance Description :</b> {complianceview && complianceview.onoccurrence_transactions && complianceview.onoccurrence_transactions.length > 0 && complianceview.onoccurrence_transactions[0].compliance_description}
                  </label>
               
               
                       
                </div>
                <div className="col-lg-12" style={{ marginTop: '15px' }}>
                    <div className="row">
                        <div className='ant-table-wrapper'>
                            <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                <div className='ant-table-container'>
                                    <div className='ant-table-content  userprivclass1'>
                                        <table className=' table-fixed'>
                                            <colgroup>
                                                <col style={{ width: '10px' }}></col>
                                            </colgroup>
                                            <thead className='ant-table-thead'>
                                                <tr>
                                                    <th className='ant-table-cell' style={{ width: '150px' }}><center>Start Date</center></th>
                                                    <th className='ant-table-cell' style={{ width: '150px' }}><center>Assignee</center></th>
                                                    <th className='ant-table-cell' style={{ width: '160px' }}><center>Completed On</center></th>
                                                    <th className='ant-table-cell' style={{ width: '110px' }}><center>Concurr</center></th>
                                                    <th className='ant-table-cell' style={{ width: '110px' }}><center>Concurred On</center></th>
                                                    <th className='ant-table-cell' style={{ width: '110px' }}><center>Approver</center></th>
                                                    <th className='ant-table-cell' style={{ width: '160px' }}><center>Approved On</center></th>
                                                    <th className='ant-table-cell' style={{ width: '150px' }}><center>Status</center></th>
                                                </tr>
                                            </thead>
                                            {complianceview && complianceview.onoccurrence_transactions && complianceview.onoccurrence_transactions.length > 0 && complianceview.onoccurrence_transactions.map((items, i) => {
                                                return <Fragment>
                                                    <tbody className='ant-table-tbody'>
                                                        <tr>
                                                            <td className='ant-table-cell'>{items.start_date}</td>
                                                            <td className='ant-table-cell'>{items.assignee_name}</td>
                                                            <td className='ant-table-cell'>{items.completion_date}</td>
                                                            <td className='ant-table-cell'>{items.concurrer_name}</td>
                                                            <td className='ant-table-cell'>{items.concurred_on}</td>
                                                            <td className='ant-table-cell'>{items.approver_name}</td>
                                                            <td className='ant-table-cell'>{items.approved_on}</td>
                                                            <td className='ant-table-cell'>{items.on_compliance_status}</td>
                                                        </tr>
                                                    </tbody>
                                                </Fragment>
                                            })}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div >
    )
}

export default connect(mapStateToProps, {
    compliancefilters,
    emailsave,
    GetGroupListData,
    FetchEmails,
    verifypassword,
    updateblockoption,
    GetOnOccurrenceCompliances,
    onoccurrencetransaction,
    startonoccurrencecompliance,
    GetRemarksData
})(OnOccurrenceCompliance)
