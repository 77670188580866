import { Fragment, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation, } from 'react-router-dom';
import { AudioOutlined, PlusOutlined, CheckOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import { Modal, Input, Table, Button, Space, Tooltip, Select, Card, Tag } from 'antd';
import { getUsermanagementList, updatestatus, activeStatus, disableStatusChange, LegalAdmindisableStatusChange, haveCompliance, getById, resendEmail, getUsermanagementDetails } from './../../Store/Action/Master/UserManagement'
import {
    InfoCircleOutlined, LikeOutlined, DislikeOutlined, UpCircleOutlined, UpOutlined,
    StopOutlined, SearchOutlined, PlayCircleOutlined, CloseCircleOutlined, PlusCircleOutlined, CloseOutlined, FullscreenOutlined, MailOutlined, EyeOutlined, FilterOutlined, EditOutlined
} from '@ant-design/icons';
import moment from 'moment';
import SimpleReactValidator from 'simple-react-validator';
import { GetRemarksData } from './../../Store/Action/Transcations/Taskrecall'
import AddUserManagement from './AddUserManagement';
import TextArea from 'antd/lib/input/TextArea';
import ScrollToTop from "react-scroll-to-top";
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";


const _ = require("lodash");
const { Option } = Select;
const mapStateToProps = (state) => ({
    usermanagementList: state.UserManagement.userManagement,
    filterrecall: state.recallfilters.recallfilters
})
const Usermanagement = ({
    getUsermanagementList,
    resendEmail,
    getUsermanagementDetails,
    GetRemarksData,
    updatestatus,
    getById,
    activeStatus,
    addChange,
    setAddChange,
    userfullscreen1,
    disableStatusChange,
    LegalAdmindisableStatusChange,
    haveCompliance,
    filterrecall: { recallremarks },
    usermanagementList: { userManagementList, UserLegalDetails, userManagementDetails, GetUserDetails }
}) => {
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const formValidator = useRef(new SimpleReactValidator());
    const formValidator1 = useRef(new SimpleReactValidator());
    const entityFormvalidator = useRef(new SimpleReactValidator());
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const [userCategoryId, setUserCategoryId] = useState()
    const [details, setDetails] = useState({
        le_id: '',
        user_id: ''
    })
    console.log(UserLegalDetails, 'UserLegalDetails');
    const [scroll, setScroll] = useState(false)
    const [fullscreen, setfullscreen] = useState(false);
    const [usermanagementDetails, setuserMAnagementDeails] = useState([])
    const [EntitySubmit, setEntityFormSubmit] = useState(false);
    const [remarks, setRemarks] = useState('')
    const [filterarray, setFilterArray] = useState([])
    const selectedEntity = localStorage.getItem('SelectedEntity')
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    // const entityid = localStorage.getItem('SelectedEntityid');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [disableType, setDisableType] = useState("")
    const [remarksModal, setRemarksModal] = useState(false);
    const currentdate = new Date();
    const [date, setDate] = useState(moment(currentdate).format('DD-MMM-YYYY'))
    const [show, setShow] = useState(false)
    const [filterModal, setFilterModal] = useState(false)
    const [showList, setShowList] = useState(false)
    const [filterText, setFilterText] = useState("");
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true,
        pageSizeOptions: ['10', '30', '50']
    })
    const [resetPaginationToggle, setResetPaginationToggle] = useState(
        false
    );

    const [passvisible, Setpassvisible] = useState(false);
    const [usermangementData, setUserMagementData] = useState([])
    const [EntityValue, setEntityValue] = useState()
    const [main, setmain] = useState({
        country: '',
        entity: '',
        business: ''
    })
    const [categoryValue, setCategoryValue] = useState({
        compfie: '',
        vendor: ''
    })
    console.log(categoryValue, 'categoryValue');
    const [type, setType] = useState("");
    const [AddType, setAddType] = useState("list")
    const [finalResult, SetfinalResult] = useState({
        Resultdata: []
    })
    const [legalEntity, setLegalEntity] = useState()

    const [finallegalEntity, setFinallegalentity] = useState()

    const sessionValue = JSON.parse(localStorage.getItem('sessionValue'))
    const entity_info = sessionValue.entity_info
    const country_info = sessionValue.country_info
    const [passwordata, Setpassword] = useState({
        passvalue: '',
        sp_id: '',
        user_id: '',
        product_id: '',
        le_id: ''
    })
    const [modalData, setModalData] = useState({
        password: '',
        reason: '',
        user_id: ''

    })
    const [active, setActive] = useState({
        u_id: "",
        emp_name: "",
    })
    const [modalVisible, setModaVisible] = useState(false)
    const [business, setBusiness] = useState(false)
    const [legal, setLegal] = useState(false)
    const [Entity, setEntity] = useState({})
    const [filteredArray, setFilteredArray] = useState([])
    console.log(filteredArray, 'filteredArray');
    const [filteredArrayState, setFilteredArrayState] = useState([])
    const [page, setPage] = useState(1);
    const [remarksvalue, Setremarksvalue] = useState();
    const RemarksColumn = [
        {
            title: '#',
            dataIndex: false,
            width: '18px',
            align: 'center',
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            },

        },
        {
            title: 'Description',
            dataIndex: 'r_description',
            key: 'r_description',
            width: '100px',
            ellipsis: true,
            render: (text, record) => <a onClick={() => {
                setRemarks(text)
                setRemarksModal(false)
            }}>{record.r_description}</a>,

        },
    ];

    const [baseData, setBaseData] = useState([]);
    useEffect(() => {
        if (recallremarks) {
            let temp = [];
            for (let i = 0; i < recallremarks.length; i++) {
                if (recallremarks[i].r_description != '') {
                    temp.push(recallremarks[i])
                }
                Setremarksvalue(temp)
            }
        }

    }, [recallremarks])
    const modalCancel = () => {
        setFilterModal(false)
    }
    const passwordcancel = () => {
        setAddFormSubmit(false)
        Setpassvisible(false);
        setModaVisible(false)
        setAddFormSubmit1(false)
        Setpassword({
            ...passwordata,
            passvalue: ""

        })
        setModalData({
            ...modalData,
            password: ""
        })

        setRemarks("")

    }

    // const scrollup = () => {
    //     window.scrollTo(0, 0)
    // }

    // useEffect(() => {
    //     window.addEventListener('scroll', handleScroll);
    // }, []);

    // const handleScroll = () => {
    //     if (window.scrollY >= 213) {
    //         setScroll(true)
    //     }
    //     else {
    //         setScroll(false)
    //     }
    // }


    const showModal = () => {
        setIsModalVisible(true);
    };
    const businessmodel = () => {
        setBusiness(true)
    }
    const legalmodel = () => {
        setLegal(true)
    }
    useEffect(() => {
        formValidator.current.showMessages()
        formValidator1.current.showMessages()
        entityFormvalidator.current.showMessages()
    }, [])
    const OnSubmited = () => {
        let datas = []
        let activeData = []
        setAddFormSubmit(true)
        if (userCategoryId && userCategoryId.u_cat_id == 3) {
            let data = _.filter(usermangementData, { u_cat_id: Number(userCategoryId.u_cat_id) })

            if (data && data.length > 0) {
                datas = _.filter(data, { le_id: userCategoryId.le_id })
                if (datas && datas.length > 0) {
                    activeData = _.filter(datas, { is_disable: 0 })
                }
                console.log(activeData, 'activeData');
            }
        }
        if (userCategoryId.u_cat_id != 3) {
            if (formValidator.current.allValid()) {
                setModaVisible(false)
                if (disableType == 'disable') {
                    haveCompliance({
                        payload:
                            [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request": [
                                        "HaveCompliances",
                                        {
                                            "le_id": modalData.le_id,
                                            "user_id": modalData.user_id,
                                            "password": modalData.password,
                                            "product_id": "",

                                        }

                                    ]
                                }
                            ],
                        paramid: paramid,
                        entityid: entityid,
                        type: disableType,
                        user_id: modalData.user_id,
                        remarks: remarks,
                        authtoken: authtoken,
                        password: modalData.password,
                        setModaVisible: setModaVisible
                    })
                }
                else {
                    const payload1 = [

                        authtoken,

                        {

                            "session_token": authtoken,

                            "request": [

                                "BlockUser",

                                {

                                    "user_id": modalData.user_id,

                                    "is_blocked": false,

                                    "active_status": true,

                                    "remarks": remarks,

                                    "password": modalData.password



                                }


                            ]


                        }

                    ]
                    disableStatusChange({
                        payload: payload1,
                        paramid: paramid,
                        setModaVisible: setModaVisible
                    })
                }
                setRemarks("")
                Setpassword({
                    ...passwordata,
                    passvalue: ""

                })
                setModalData({
                    ...modalData,
                    password: ""
                })
                setAddFormSubmit(false)

            }
        }
        else {
            if (formValidator.current.allValid()) {
                setModaVisible(false)
                if (datas && datas.length == 1) {
                    if (disableType == 'disable') {
                        haveCompliance({
                            payload:
                                [
                                    authtoken,
                                    {
                                        "session_token": authtoken,
                                        "request": [
                                            "HaveCompliances",
                                            // "SaveUnitClosureData",
                                            // {
                                            //     "password": modalData.password,
                                            //     "closed_remarks": remarks,
                                            //     "unit_id": Number(modalData.unit_id),
                                            //     "grp_mode": modalData.reason=='closed'?"close":"reactive",
                                            //     "legal_entity_id": entityid
                                            // }
                                            {
                                                "le_id": modalData.le_id,
                                                "user_id": modalData.user_id,
                                                "password": modalData.password,
                                                "product_id": ""
                                            }

                                        ]
                                    }
                                ],
                            paramid: paramid,
                            entityid: entityid,
                            type: disableType,
                            user_id: modalData.user_id,
                            remarks: remarks,
                            authtoken: authtoken,
                            password: modalData.password,
                            setModaVisible: setModaVisible
                        })
                    }
                    else {
                        const payload1 = [

                            authtoken,

                            {

                                "session_token": authtoken,

                                "request": [

                                    "BlockUser",

                                    {

                                        "user_id": modalData.user_id,

                                        "is_blocked": false,

                                        "active_status": true,

                                        "remarks": remarks,

                                        "password": modalData.password



                                    }


                                ]


                            }

                        ]
                        disableStatusChange({
                            payload: payload1,
                            paramid: paramid,
                            setModaVisible: setModaVisible
                        })
                    }
                }
                else if (activeData && activeData.length == 1) {
                    if (disableType == 'disable') {
                        haveCompliance({
                            payload:
                                [
                                    authtoken,
                                    {
                                        "session_token": authtoken,
                                        "request": [
                                            "HaveCompliances",
                                            // "SaveUnitClosureData",
                                            // {
                                            //     "password": modalData.password,
                                            //     "closed_remarks": remarks,
                                            //     "unit_id": Number(modalData.unit_id),
                                            //     "grp_mode": modalData.reason=='closed'?"close":"reactive",
                                            //     "legal_entity_id": entityid
                                            // }
                                            {
                                                "le_id": modalData.le_id,
                                                "user_id": modalData.user_id,
                                                "password": modalData.password,
                                                "product_id": ""
                                            }

                                        ]
                                    }
                                ],
                            paramid: paramid,
                            entityid: entityid,
                            type: disableType,
                            user_id: modalData.user_id,
                            remarks: remarks,
                            authtoken: authtoken,
                            password: modalData.password,
                            setModaVisible: setModaVisible
                        })
                    }
                    else {
                        const payload1 = [

                            authtoken,

                            {

                                "session_token": authtoken,

                                "request": [

                                    "BlockUser",

                                    {

                                        "user_id": modalData.user_id,

                                        "is_blocked": disableType == 'disable' ? true : false,

                                        "active_status": true,

                                        "remarks": remarks,

                                        "password": modalData.password



                                    }


                                ]


                            }

                        ]
                        LegalAdmindisableStatusChange({
                            payload: payload1,
                            paramid: paramid,
                            setModaVisible: setModaVisible,
                            type: disableType
                        })
                    }
                }
                else {
                    const payload1 = [

                        authtoken,

                        {

                            "session_token": authtoken,

                            "request": [

                                "BlockUser",

                                {

                                    "user_id": modalData.user_id,

                                    "is_blocked": disableType == 'disable' ? true : false,

                                    "active_status": true,

                                    "remarks": remarks,

                                    "password": modalData.password



                                }


                            ]


                        }

                    ]
                    LegalAdmindisableStatusChange({
                        payload: payload1,
                        paramid: paramid,
                        setModaVisible: setModaVisible,
                        type: disableType
                    })
                }

                setRemarks("")
                Setpassword({
                    ...passwordata,
                    passvalue: ""

                })
                setModalData({
                    ...modalData,
                    password: ""
                })
                setAddFormSubmit(false)

            }
        }

    }
    const showRemarksModal = () => {
        GetRemarksData({
            payload:
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetRemarksData",
                            {}
                        ]
                    }
                ],
            paramid: paramid
        })
        setRemarksModal(true);
    };
    const handleOk = () => {

        setBusiness(false)
        setLegal(false)
        setRemarksModal(false)
        Setpassvisible(false)
    };

    const handleRemarksOk = () => {
        setRemarksModal(false)
    }


    const submitpassword = () => {
        setAddFormSubmit1(true)
        if (formValidator1.current.allValid()) {

            if (type === "active") {
                console.log('test in')
                const pay = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "HaveCompliances",
                            {
                                "le_id": passwordata.le_id,
                                "user_id": passwordata.user_id,
                                "password": passwordata.passvalue,
                                "product_id": ""
                            }
                        ]
                    }
                ]

                const payloads = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "ChangeClientUserStatus",
                            {
                                "u_id": active.u_id,
                                "active_status": false,
                                "emp_name": active.emp_name,
                                "password": passwordata.passvalue
                            }
                        ]


                    }
                ]

                updatestatus({
                    payload: pay,
                    paramid: paramid,
                    types: type,
                    payloads: payloads,
                    Setpassvisible: Setpassvisible
                })

                Setpassvisible(false);
                Setpassword({
                    ...passwordata,
                    passvalue: ""
                })
            }
            else {

                const payloads = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "ChangeClientUserStatus",
                            {
                                "u_id": active.u_id,
                                "active_status": type === "active" ? false : true,
                                "emp_name": active.emp_name,
                                "password": passwordata.passvalue
                            }
                        ]


                    }
                ]

                activeStatus({
                    types: type,
                    payload: payloads,
                    paramid: paramid,
                    Setpassvisible: Setpassvisible
                })
                Setpassvisible(false);
                Setpassword({
                    ...passwordata,
                    passvalue: ""
                })

            }
            setAddFormSubmit1(false)
        }
    }

    const handleCancel = () => {
        setAddFormSubmit(false)
        setIsModalVisible(false);
        Setpassvisible(false)
        setBusiness(false)
        setLegal(false)
        setModaVisible(false)
        setRemarksModal(false)
    };
    useEffect(() => {
        let finalArray = [];
        let countrylistdata = [];
        let countrylisttempdata = [];
        // for (let countrylist in country_info) {

        let entityArray = []
        let tempArray = []
        let sumValue = 0;
        let country_id
        let country_name
        for (let entitylist in entity_info) {
            country_id = entity_info[entitylist].c_id
            country_name = entity_info[entitylist].c_name
            let entitylist_cid = entity_info[entitylist].c_id
            if (entitylist_cid === country_id) {
                sumValue = entity_info[entitylist].u_count + sumValue
                entityArray = {
                    'bg_id': entity_info[entitylist].bg_id,
                    'bg_name': entity_info[entitylist].bg_name,
                    'c_id': entity_info[entitylist].c_id,
                    'c_name': entity_info[entitylist].c_name,
                    'le_id': entity_info[entitylist].le_id,
                    'le_name': entity_info[entitylist].le_name,
                    'u_count': entity_info[entitylist].u_count,
                }
                tempArray.push(entityArray)
            }
        }
        finalArray[country_name] = tempArray
        countrylistdata = {
            'c_id': country_id,
            'c_name': country_name,
            'allEntitySumNew': sumValue,
            'child': tempArray
        }
        countrylisttempdata.push(countrylistdata)
        SetfinalResult({
            ...finalResult,
            Resultdata: countrylisttempdata
        })


        // }

    }, [])

    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "UserManagementList",
                        {}
                    ]
                }
            ]
            getUsermanagementList({
                payload: payload,
                paramid: paramid
            })
        }
    }, [isAuth])
    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "UserManagementPrerequisite",
                        {}
                    ]
                }
            ]
            getUsermanagementDetails({
                payload: payload,
                paramid: paramid
            })
        }
    }, [isAuth])

    const { Search } = Input;

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        setSelectedKeys(e.target.value ? [e.target.value] : [])
        confirm({ closeDropdown: false });
        console.log(dataIndex, 'SEARCH VALUE')
        console.log(e.target.value, 'e.target.value');
        setSearchText(e.target.value);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };


    const getColumnSearchProps = (dataIndex, placeholder) => ({

        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            console.log(dataIndex, 'dataIndex'),
            <div>
                <Input
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        console.log(e, 'e')
                        console.log(dataIndex, 'dataIndex');
                        console.log(setSelectedKeys, 'setSelectedKeys');
                        console.log(confirm, 'confirm');
                        handleSearch(e, confirm, dataIndex, setSelectedKeys)
                    }


                    }
                />
                {/* <Space>
                    <Button
                        type="primary"
                        // onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                </Space> */}
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', handleKeyDown, false);
            document.addEventListener('webkitfullscreenchange', handleKeyDown, false);
            return () => {
                document.addEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('webkitfullscreenchange', handleKeyDown);
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode();
            setfullscreen(true);
        } else {
            fullscreenexit();
            setfullscreen(false);
        }
    }

    useEffect(() => {
        console.log(userfullscreen1, 'userfullscreen1');
        if (userfullscreen1 != undefined && (userfullscreen1 != '' || userfullscreen1 == true)) {
            fullscreenMode();
        }
    }, [userfullscreen1])

    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const tablehandleChange = (pagination, filters, sorter) => {
        setSortedInfo(sorter);
        setFilteredInfo(filters);
    };


    useEffect(() => {
        if ((addChange == true) && (fullscreen == false)) {
            fullscreenMode()
        }
    }, [addChange])




    const columns = [
        {
            title: '#',
            dataIndex: false,
            align: 'center',
            width: '18px',
            render: (text, record, index) => {
                return index + 1
            }
        },

        // {
        //     title: 'Compfie Category',
        //     dataIndex: 'u_cat_id',
        //     align: 'center',

        //     width: '37px',
        //     filters:
        //         [
        //             {
        //                 text: <span><i style={{ color: "#9a9da0" }} className="bi bi-square-fill"></i> View Only</span>,
        //                 value: 2,
        //             },
        //             {
        //                 text: <span><i style={{ color: "#fbca35" }} className="bi bi-square-fill"></i> Legal Entity Admin</span>,
        //                 value: 3,
        //             },
        //             {
        //                 text: <span><i style={{ color: "#45b0e2" }} className="bi bi-square-fill"></i> Domain Admin</span>,
        //                 value: 4,
        //             },
        //             {
        //                 text: <span><i style={{ color: "#FF6666" }} className="bi bi-square-fill"></i> Inhouse User</span>,
        //                 value: 5,
        //             },
        //             {
        //                 text: <span><i style={{ color: "#60667a" }} className="bi bi-square-fill"></i> Service Provider User</span>,
        //                 value: 6,
        //             },
        //             {
        //                 text: <span><i style={{ color: "#b29999" }} className="bi bi-square-fill"></i> View Only Restricted</span>,
        //                 value: 7,
        //             },
        //             {
        //                 text: <span><i style={{ color: "#00FFFF" }} className="bi bi-square-fill"></i> Litigation User
        //                 </span>,
        //                 value: 1,
        //             },
        //             {
        //                 text: <span><i style={{ color: "#ff8acc" }} className="bi bi-square-fill"></i> Not Access
        //                 </span>,
        //                 value: null,
        //             },
        //         ],

        //     filteredValue: filteredInfo.u_cat_id,
        //     onFilter: (value, record) => record.u_cat_id == value,
        //     // onFilter: (value, record) => record.is_active === value,
        //     filterSearch: false,
        //     render: (row, record) => {
        //         console.log(record.u_cat_id, "recorddd");
        //         return (
        //             <Fragment>
        //                 {
        //                     (row == 4 ? <i style={{ color: "#45b0e2" }} className="bi bi-square-fill"></i>
        //                         : (row == 3 ? <i style={{ color: "#fbca35" }} className="bi bi-square-fill"></i> :
        //                             (row == 5 ? <i style={{ color: "#e96154" }} className="bi bi-square-fill"></i> :
        //                                 (row == 2 ? <i style={{ color: "#9a9da0" }} className="bi bi-square-fill"></i> :
        //                                     (row == 6 ? <i style={{ color: "#60667a" }} className="bi bi-square-fill"></i> :
        //                                         row == 7 ? <i style={{ color: "#b29999" }} className="bi bi-square-fill"></i> :
        //                                             row == 1 ? <i style={{ color: "#FF6666" }} className="bi bi-square-file"></i> :
        //                                                 row == null ? <i style={{ color: "#ff8acc" }} className="bi bi-square-fill"></i> : false)))))
        //                 }
        //             </Fragment>
        //         )
        //     },
        //     // sorter: (a, b) => a.emp_name.localeCompare(b.emp_name)
        // },

        // {
        //     title: 'Vendor Category',
        //     dataIndex: 'vndr_user_category',
        //     width: '37px',
        //     align: 'center',
        //     filters: [
        //         {
        //             text: <span><i style={{ color: "#9a9da0" }} className="bi bi-square-fill"></i> View Only</span>,
        //             value: 2,
        //         },
        //         {
        //             text: <span><i style={{ color: "#fbca35" }} className="bi bi-square-fill"></i> Legal Entity Admin</span>,
        //             value: 3,
        //         },
        //         {
        //             text: <span><i style={{ color: "#45b0e2" }} className="bi bi-square-fill"></i> Domain Admin</span>,
        //             value: 4,
        //         },
        //         {
        //             text: <span><i style={{ color: "#FF6666" }} className="bi bi-square-fill"></i> Inhouse User</span>,
        //             value: 5,
        //         },
        //         {
        //             text: <span><i style={{ color: "#60667a" }} className="bi bi-square-fill"></i> Service Provider User</span>,
        //             value: 6,
        //         },
        //         {
        //             text: <span><i style={{ color: "#b29999" }} className="bi bi-square-fill"></i> View Only Restricted</span>,
        //             value: 7,
        //         },
        //         {
        //             text: <span><i style={{ color: "#00FFFF" }} className="bi bi-square-fill"></i> Litigation User
        //             </span>,
        //             value: 1,
        //         },
        //         {
        //             text: <span><i style={{ color: "#ff8acc" }} className="bi bi-square-fill"></i> Not Access
        //             </span>,
        //             value: null,
        //         },
        //     ],

        //     filteredValue: filteredInfo.vndr_user_category,
        //     onFilter: (value, record) => record.vndr_user_category == value,
        //     // onFilter: (value, record) => record.is_active === value,
        //     filterSearch: false,
        //     render: (row, record) => {
        //         console.log(record, 'rowworrowwor')
        //         return (
        //             <Fragment>
        //                 {
        //                     (row == 4 ? <i style={{ color: "#45b0e2" }} className="bi bi-square-fill"></i>
        //                         : (row == 3 ? <i style={{ color: "#fbca35" }} className="bi bi-square-fill"></i> :
        //                             (row == 5 ? <i style={{ color: "#e96154" }} className="bi bi-square-fill"></i> :
        //                                 (row == 2 ? <i style={{ color: "#9a9da0" }} className="bi bi-square-fill"></i> :
        //                                     (row == 6 ? <i style={{ color: "#60667a" }} className="bi bi-square-fill"></i> :
        //                                         row == 7 ? <i style={{ color: "#b29999" }} className="bi bi-square-fill"></i> :
        //                                             row == 1 ? <i style={{ color: "#FF6666" }} className="bi bi-square-file"></i> :
        //                                                 row == null ? <i style={{ color: "#ff8acc" }} className="bi bi-square-fill"></i> : false)))))
        //                 }
        //             </Fragment>)
        //     },

        // },
        {
            title: 'Users',
            dataIndex: 'emp_name',
            key: 'emp_name',
            width: '75px',
            ellipsis: true,
            ...getColumnSearchProps('emp_name', 'Users'),
            filteredValue: filteredInfo.emp_name || null,
            // sorter: (a, b) => a.emp_name.localeCompare(b.emp_name),
            sorter: (a, b) => {
                if (a && a.emp_name && a.emp_name.length && b && b.emp_name && b.emp_name.length) {
                    return a.emp_name.localeCompare(b.emp_name);
                } else if (a && a.emp_name && a.emp_name.length) {
                    // That means be has null division_name, so a will come first.
                    return -1;
                } else if (b && b.emp_name && b.emp_name.length) {
                    // That means a has null division_name so b will come first.
                    return 1;
                }

                // Both division_name has null value so there will be no order change.
                return 0;
            },
            sortOrder: sortedInfo.columnKey === 'emp_name' ? sortedInfo.order : null,
            render: (row, record) => {
                console.log(row, 'row12344948');
                return (
                    <Fragment>
                        {
                            ((record.user_name == null) && (record.is_disable == 0)) ?
                                // <p>{record.emp_name}&nbsp;<MailOutlined style={{ color: '#800000' }} /></p>


                                <Tooltip title="Click here to resend email" > <span>{record.emp_name}&nbsp;<MailOutlined style={{ color: '#800000' }} onClick={() => { resentEmailRegistration(record) }} /></span></Tooltip>

                                : <span>{record.emp_name}</span>
                        }

                    </Fragment>



                )
            }
        },
        {
            title: 'User ID',
            dataIndex: 'user_name_new',
            key: 'user_name_new',
            width: '60px',
            // ellipsis: true,
            ...getColumnSearchProps('user_name_new', 'User ID'),
            filteredValue: filteredInfo.user_name_new || null,

            sorter: (a, b) => {

                if (a && a.user_name_new && a.user_name_new.length && b && b.user_name_new && b.user_name_new.length) {
                    return a.user_name_new.localeCompare(b.user_name_new);
                } else if (a && a.user_name_new && a.user_name_new.length) {
                    // That means be has null division_name, so a will come first.
                    return -1;
                } else if (b && b.user_name_new && b.user_name_new.length) {
                    // That means a has null division_name so b will come first.
                    return 1;
                }

                // Both division_name has null value so there will be no order change.
                return 0;
            },
            // sorter: (a, b) => a.user_name && b.user_name != null ? a.user_name.localeCompare(b.user_name) : "",
            sortOrder: sortedInfo.columnKey === 'user_name_new' ? sortedInfo.order : null,

        },
        {
            title: 'Email ID',
            dataIndex: 'email_id',
            width: '85px',
            key: 'email_id',
            ...getColumnSearchProps('email_id', 'Email ID'),
            ellipsis: true,
            filteredValue: filteredInfo.email_id || null,
            sorter: (a, b) => {

                if (a && a.email_id && a.email_id.length && b && b.email_id && b.email_id.length) {
                    return a.email_id.localeCompare(b.email_id);
                } else if (a && a.email_id && a.email_id.length) {
                    // That means be has null division_name, so a will come first.
                    return -1;
                } else if (b && b.email_id && b.email_id.length) {
                    // That means a has null division_name so b will come first.
                    return 1;
                }

                // Both division_name has null value so there will be no order change.
                return 0;
            },
            // sorter: (a, b) => a.email_id && b.email_id != null ? a.email_id.localeCompare(b.email_id) : "",
            sortOrder: sortedInfo.columnKey === 'email_id' ? sortedInfo.order : null,
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mob_no',
            key: 'mob_no',
            ...getColumnSearchProps('mob_no', 'Mobile Number'),
            width: '50px',
            // ellipsis: true
            filteredValue: filteredInfo.mob_no || null,
            sorter: (a, b) => a.mob_no.localeCompare(b.mob_no),
            // sorter: (a, b) => {

            //     if(a && a.mob_no && a.mob_no.length && b && b.mob_no && b.mob_no.length) {
            //         return a.user_name.localeCompare(b.mob_no);
            //     }else if(a && a.mob_no && a.mob_no.length) {
            //         // That means be has null division_name, so a will come first.
            //         return -1;
            //     } else if(b && b.mob_no && b.mob_no.length) {
            //         // That means a has null division_name so b will come first.
            //         return 1;
            //     }

            //     // Both division_name has null value so there will be no order change.
            //     return 0;
            // },
            sortOrder: sortedInfo.columnKey === 'mob_no' ? sortedInfo.order : null,
        },
        // {
        //     title: 'Registration Email',
        //     dataIndex: 'user_name',
        //     key: 'user_name',
        //     width: '55px',
        //     align: 'center',
        //     // ellipsis: true,
        //     filteredValue: filteredInfo.user_name || null,
        //     sorter: (a, b) => {

        //         if (a && a.user_name && a.user_name.length && b && b.user_name && b.user_name.length) {
        //             return a.user_name.localeCompare(b.user_name);
        //         } else if (a && a.user_name && a.user_name.length) {
        //             // That means be has null division_name, so a will come first.
        //             return -1;
        //         } else if (b && b.user_name && b.user_name.length) {
        //             // That means a has null division_name so b will come first.
        //             return 1;
        //         }

        //         // Both division_name has null value so there will be no order change.
        //         return 0;
        //     },
        //     // sorter: (a, b) => a.user_name && b.user_name != null ? a.user_name.localeCompare(b.user_name) : "",
        //     sortOrder: sortedInfo.columnKey === 'user_name' ? sortedInfo.order : null,
        //     render: (row, record) => {
        //         return (
        //             <Fragment>
        //                 {
        //                     ((row == null) && (record.is_disable == 0)) ?
        //                         <Tooltip title="Click here to resend email" ><Link onClick={() => { resentEmailRegistration(record) }}>Resend</Link></Tooltip>

        //                         : ''
        //                 }
        //             </Fragment>



        //         )
        //     }
        // },
        {
            title: <><label>Actions</label><Tooltip className='toolTipClassusermanagement' placement="topRight" title={"Edit,Status,Disable"}><InfoCircleOutlined /></Tooltip></>,
            dataIndex: 'is_active',
            width: '40px',
            filters: [
                {
                    text: 'Active',
                    value: true,
                },
                {
                    text: 'Inactive',
                    value: false,
                },
            ],

            filteredValue: filteredInfo.is_active,
            onFilter: (value, record) => record.is_active == value,
            filterSearch: false,
            render: (text, record) => {
                console.log(record, 'recordlist');
                return (
                    <Fragment>
                        <div style={{ width: '95%', margin: '0 auto' }} className='row hover'>
                            <div className='col-4 d-flex justify-content-center'>
                                {record.is_disable === 0 ?
                                    <div>
                                        <span className="social-link btn btn-sm btn-light-info text-info btn-circle d-flex align-items-center justify-content-center"
                                            onClick={(e) => { editUser(record.user_id, record) }}>
                                            <Tooltip title="Click here to Edit" color='blue'>
                                                <EditOutlined /></Tooltip>
                                            {/* <i className="ri-edit-line"></i> */}
                                        </span>
                                    </div> :
                                    <div >
                                        <span className="social-link btn btn-sm btn-light-info text-info btn-circle d-flex align-items-center justify-content-center">
                                            <EditOutlined style={{ cursor: 'not-allowed' }}></EditOutlined>
                                        </span>
                                    </div>
                                }
                            </div>
                            <div className='col-4 d-flex justify-content-center px-2'>
                                {
                                    record.is_disable === 0 ?
                                        <div >
                                            {record.is_active == true ?
                                                <span className="social-link btn btn-sm btn-light-success
                                                text-success btn-circle d-flex align-items-center justify-content-center" onClick={(e) => { ActivateById(record, "active") }}>
                                                    {/* text-success btn-circle d-flex align-items-center justify-content-center" onClick={(e) => { statuschange(record, "active") }} */}
                                                    <Tooltip title="Click here to Deactivate" color='#39cb7f'><LikeOutlined /></Tooltip>
                                                </span> :
                                                <span className="social-link btn btn-sm btn-light-danger
                                                text-danger btn-circle d-flex align-items-center justify-content-center"  onClick={(e) => { ActivateById(record, "deactive") }}>
                                                    <Tooltip title="Click here to Activate" color='#fc4b6c'><DislikeOutlined /></Tooltip>
                                                </span>
                                            }
                                        </div> :
                                        <div>
                                            {record.is_active == true ?
                                                <span className="social-link btn btn-sm btn-light-success
                                                text-success btn-circle d-flex align-items-center justify-content-center" >
                                                    <Tooltip title="" color='#39cb7f'><LikeOutlined /></Tooltip>
                                                </span>
                                                :
                                                <span className="social-link btn btn-sm btn-light-danger
                                                text-danger btn-circle d-flex align-items-center justify-content-center" >
                                                    <Tooltip title="De-Active" color='#fc4b6c'><DislikeOutlined /></Tooltip>
                                                </span>


                                            }
                                        </div>

                                }
                            </div>
                            <div className='col-4 d-flex justify-content-center'>
                                {record.is_disable === 0 ?
                                    <span className="social-link btn btn-sm btn-light-success
                                    text-success btn-circle d-flex align-items-center justify-content-center" onClick={() => {
                                            setShow(true)
                                            DisableById(record.user_id, "disable", record.le_id, record);
                                        }}>
                                        <Tooltip title="Click here to Disable" color='#39cb7f'><CheckOutlined /></Tooltip>
                                    </span>
                                    :
                                    <div>
                                        {record.unblock_days === 0 ? '' :
                                            <span className="social-link btn btn-sm btn-light-danger
                                            text-danger btn-circle d-flex align-items-center justify-content-center" onClick={() => {
                                                    setShow(true)
                                                    DisableById(record.user_id, "unDisable", record.le_id, record);
                                                }}>
                                                <Tooltip title={record.unblock_days + '-' + 'Days Left'} color='#fc4b6c'><StopOutlined /></Tooltip>
                                            </span>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </Fragment>
                )
            }
        }
    ];
    const columns2 = [
        {
            title: '#',
            dataIndex: false,
            align: 'center',
            width: '18px',
            render: (text, record, index) => {
                return index + 1
            }
        },

        // {
        //     title: 'Compfie Category',
        //     dataIndex: 'u_cat_id',
        //     align: 'center',
        //     // ellipsis: true,
        //     width: '37px',
        //     filters: [
        //         {
        //             text: <span><i style={{ color: "#9a9da0" }} className="bi bi-square-fill"></i> View Only</span>,
        //             value: 2,
        //         },
        //         {
        //             text: <span><i style={{ color: "#fbca35" }} className="bi bi-square-fill"></i> Legal Entity Admin</span>,
        //             value: 3,
        //         },
        //         {
        //             text: <span><i style={{ color: "#45b0e2" }} className="bi bi-square-fill"></i> Domain Admin</span>,
        //             value: 4,
        //         },
        //         {
        //             text: <span><i style={{ color: "#FF6666" }} className="bi bi-square-fill"></i> Inhouse User</span>,
        //             value: 5,
        //         },
        //         {
        //             text: <span><i style={{ color: "#60667a" }} className="bi bi-square-fill"></i> Service Provider User</span>,
        //             value: 6,
        //         },
        //         {
        //             text: <span><i style={{ color: "#b29999" }} className="bi bi-square-fill"></i> View Only Restricted</span>,
        //             value: 7,
        //         },
        //         {
        //             text: <span><i style={{ color: "#00FFFF" }} className="bi bi-square-fill"></i> Litigation User
        //             </span>,
        //             value: 1,
        //         },
        //         {
        //             text: <span><i style={{ color: "#ff8acc" }} className="bi bi-square-fill"></i> Not Access
        //             </span>,
        //             value: null,
        //         },
        //     ],

        //     filteredValue: filteredInfo.u_cat_id,
        //     onFilter: (value, record) => record.u_cat_id == value,
        //     // onFilter: (value, record) => record.is_active === value,
        //     filterSearch: false,
        //     render: (row, record) => {
        //         console.log(record.u_cat_id, "recorddd");
        //         return (
        //             <Fragment>
        //                 {
        //                     (row == 4 ? <i style={{ color: "#45b0e2" }} className="bi bi-square-fill"></i>
        //                         : (row == 3 ? <i style={{ color: "#fbca35" }} className="bi bi-square-fill"></i> :
        //                             (row == 5 ? <i style={{ color: "#e96154" }} className="bi bi-square-fill"></i> :
        //                                 (row == 2 ? <i style={{ color: "#9a9da0" }} className="bi bi-square-fill"></i> :
        //                                     (row == 6 ? <i style={{ color: "#60667a" }} className="bi bi-square-fill"></i> :
        //                                         row == 7 ? <i style={{ color: "#b29999" }} className="bi bi-square-fill"></i> :
        //                                             row == 1 ? <i style={{ color: "#FF6666" }} className="bi bi-square-file"></i> :
        //                                                 row == null ? <i style={{ color: "#ff8acc" }} className="bi bi-square-fill"></i> : false)))))
        //                 }
        //             </Fragment>
        //         )
        //     },
        //     // sorter: (a, b) => a.emp_name.localeCompare(b.emp_name)
        // },


        {
            title: 'Users',
            dataIndex: 'emp_name',
            key: 'emp_name',
            width: '75px',
            ellipsis: true,
            ...getColumnSearchProps('emp_name', 'Users'),
            filteredValue: filteredInfo.emp_name || null,
            // sorter: (a, b) => a.emp_name.localeCompare(b.emp_name),
            sorter: (a, b) => {

                if (a && a.emp_name && a.emp_name.length && b && b.emp_name && b.emp_name.length) {
                    return a.emp_name.localeCompare(b.emp_name);
                } else if (a && a.emp_name && a.emp_name.length) {
                    // That means be has null division_name, so a will come first.
                    return -1;
                } else if (b && b.emp_name && b.emp_name.length) {
                    // That means a has null division_name so b will come first.
                    return 1;
                }

                // Both division_name has null value so there will be no order change.
                return 0;
            },
            sortOrder: sortedInfo.columnKey === 'emp_name' ? sortedInfo.order : null,
            render: (row, record) => {
                console.log(row, 'row12344948');
                return (
                    <Fragment>
                        {
                            ((record.user_name == null) && (record.is_disable == 0)) ?
                                // <p>{record.emp_name}&nbsp;<MailOutlined style={{ color: '#800000' }} /></p>


                                <Tooltip title="Click here to resend email" > <span>{record.emp_name}&nbsp;<MailOutlined style={{ color: '#800000' }} onClick={() => { resentEmailRegistration(record) }} /></span></Tooltip>

                                : <span>{record.emp_name}</span>
                        }
                    </Fragment>



                )
            }

        },
        {
            title: 'User ID',
            dataIndex: 'user_name_new',
            key: 'user_name_new',
            width: '60px',
            // ellipsis: true,
            ...getColumnSearchProps('user_name_new', 'User ID'),
            filteredValue: filteredInfo.user_name_new || null,
            sorter: (a, b) => {

                if (a && a.user_name_new && a.user_name_new.length && b && b.user_name_new && b.user_name_new.length) {
                    return a.user_name_new.localeCompare(b.user_name_new);
                } else if (a && a.user_name_new && a.user_name_new.length) {
                    // That means be has null division_name, so a will come first.
                    return -1;
                } else if (b && b.user_name_new && b.user_name_new.length) {
                    // That means a has null division_name so b will come first.
                    return 1;
                }

                // Both division_name has null value so there will be no order change.
                return 0;
            },
            // sorter: (a, b) => a.user_name && b.user_name != null ? a.user_name.localeCompare(b.user_name) : "",
            sortOrder: sortedInfo.columnKey === 'user_name_new' ? sortedInfo.order : null,

        },
        {
            title: 'Email ID',
            dataIndex: 'email_id',
            width: '85px',
            key: 'email_id',
            ...getColumnSearchProps('email_id', 'Email ID'),
            ellipsis: true,
            filteredValue: filteredInfo.email_id || null,
            sorter: (a, b) => {

                if (a && a.email_id && a.email_id.length && b && b.email_id && b.email_id.length) {
                    return a.email_id.localeCompare(b.email_id);
                } else if (a && a.email_id && a.email_id.length) {
                    // That means be has null division_name, so a will come first.
                    return -1;
                } else if (b && b.email_id && b.email_id.length) {
                    // That means a has null division_name so b will come first.
                    return 1;
                }

                // Both division_name has null value so there will be no order change.
                return 0;
            },
            // sorter: (a, b) => a.email_id && b.email_id != null ? a.email_id.localeCompare(b.email_id) : "",
            sortOrder: sortedInfo.columnKey === 'email_id' ? sortedInfo.order : null,
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mob_no',
            key: 'mob_no',
            ...getColumnSearchProps('mob_no', 'Mobile Number'),
            width: '50px',
            // ellipsis: true
            filteredValue: filteredInfo.mob_no || null,
            sorter: (a, b) => a.mob_no.localeCompare(b.mob_no),
            // sorter: (a, b) => {

            //     if(a && a.mob_no && a.mob_no.length && b && b.mob_no && b.mob_no.length) {
            //         return a.user_name.localeCompare(b.mob_no);
            //     }else if(a && a.mob_no && a.mob_no.length) {
            //         // That means be has null division_name, so a will come first.
            //         return -1;
            //     } else if(b && b.mob_no && b.mob_no.length) {
            //         // That means a has null division_name so b will come first.
            //         return 1;
            //     }

            //     // Both division_name has null value so there will be no order change.
            //     return 0;
            // },
            sortOrder: sortedInfo.columnKey === 'mob_no' ? sortedInfo.order : null,
        },
        // {
        //     title: 'Registration Email',
        //     dataIndex: 'user_name',
        //     key: 'user_name',
        //     width: '55px',
        //     align: 'center',
        //     // ellipsis: true,
        //     filteredValue: filteredInfo.user_name || null,
        //     sorter: (a, b) => {

        //         if (a && a.user_name && a.user_name.length && b && b.user_name && b.user_name.length) {
        //             return a.user_name.localeCompare(b.user_name);
        //         } else if (a && a.user_name && a.user_name.length) {
        //             // That means be has null division_name, so a will come first.
        //             return -1;
        //         } else if (b && b.user_name && b.user_name.length) {
        //             // That means a has null division_name so b will come first.
        //             return 1;
        //         }

        //         // Both division_name has null value so there will be no order change.
        //         return 0;
        //     },
        //     // sorter: (a, b) => a.user_name && b.user_name != null ? a.user_name.localeCompare(b.user_name) : "",
        //     sortOrder: sortedInfo.columnKey === 'user_name' ? sortedInfo.order : null,
        //     render: (row, record) => {
        //         return (
        //             <Fragment>
        //                 {
        //                     ((row == null) && (record.is_disable == 0)) ?
        //                         <Tooltip title="Click here to resend email" ><Link onClick={() => { resentEmailRegistration(record) }}>Resend</Link></Tooltip>

        //                         : ''
        //                 }
        //             </Fragment>



        //         )
        //     }
        // },
        {
            title: <><label>Actions</label><Tooltip className='toolTipClassusermanagement' placement="topRight" title={"Edit,Status,Disable"}><InfoCircleOutlined /></Tooltip></>,
            dataIndex: 'is_active',
            width: '40px',
            filters: [
                {
                    text: 'Active',
                    value: true,
                },
                {
                    text: 'Inactive',
                    value: false,
                },
            ],

            filteredValue: filteredInfo.is_active,
            onFilter: (value, record) => record.is_active == value,
            filterSearch: false,
            render: (text, record) => {
                console.log(record, 'recordlist');
                return (
                    <Fragment>
                        <div style={{ width: '95%', margin: '0 auto' }} className='row hover'>
                            <div className='col-4 d-flex justify-content-center'>
                                {record.is_disable === 0 ?
                                    <div>
                                        <span className="social-link btn btn-sm btn-light-info text-info btn-circle d-flex align-items-center justify-content-center"
                                            onClick={(e) => { editUser(record.user_id, record) }}>
                                            <Tooltip title="Click here to Edit" color='blue'>
                                                <EditOutlined /></Tooltip>
                                            {/* <i className="ri-edit-line"></i> */}
                                        </span>
                                    </div> :
                                    <div >
                                        <span className="social-link btn btn-sm btn-light-info text-info btn-circle d-flex align-items-center justify-content-center">
                                            <EditOutlined style={{ cursor: 'not-allowed' }}></EditOutlined>
                                        </span>
                                    </div>
                                }
                            </div>
                            <div className='col-4 d-flex justify-content-center px-2'>
                                {
                                    record.is_disable === 0 ?
                                        <div >
                                            {record.is_active == true ?
                                                <span className="social-link btn btn-sm btn-light-success
                                                text-success btn-circle d-flex align-items-center justify-content-center" onClick={(e) => { ActivateById(record, "active") }}>
                                                    {/* text-success btn-circle d-flex align-items-center justify-content-center" onClick={(e) => { statuschange(record, "active") }} */}
                                                    <Tooltip title="Click here to Deactivate" color='#39cb7f'><LikeOutlined /></Tooltip>
                                                </span> :
                                                <span className="social-link btn btn-sm btn-light-danger
                                                text-danger btn-circle d-flex align-items-center justify-content-center"  onClick={(e) => { ActivateById(record, "deactive") }}>
                                                    <Tooltip title="Click here to Activate" color='#fc4b6c'><DislikeOutlined /></Tooltip>
                                                </span>
                                            }
                                        </div> :
                                        <div>
                                            {record.is_active == true ?
                                                <span className="social-link btn btn-sm btn-light-success
                                                text-success btn-circle d-flex align-items-center justify-content-center" >
                                                    <Tooltip title="" color='#39cb7f'><LikeOutlined /></Tooltip>
                                                </span>
                                                :
                                                <span className="social-link btn btn-sm btn-light-danger
                                                text-danger btn-circle d-flex align-items-center justify-content-center" >
                                                    <Tooltip title="De-Active" color='#fc4b6c'><DislikeOutlined /></Tooltip>
                                                </span>


                                            }
                                        </div>

                                }
                            </div>
                            <div className='col-4 d-flex justify-content-center'>
                                {record.is_disable === 0 ?
                                    <span className="social-link btn btn-sm btn-light-success
                                    text-success btn-circle d-flex align-items-center justify-content-center" onClick={() => {
                                            setShow(true)
                                            DisableById(record.user_id, "disable", record.le_id, record);
                                        }}>
                                        <Tooltip title="Click here to Disable" color='#39cb7f'><CheckOutlined /></Tooltip>
                                    </span>
                                    :
                                    <div>
                                        {record.unblock_days === 0 ? '' :
                                            <span className="social-link btn btn-sm btn-light-danger
                                            text-danger btn-circle d-flex align-items-center justify-content-center" onClick={() => {
                                                    setShow(true)
                                                    DisableById(record.user_id, "unDisable", record.le_id, record);
                                                }}>
                                                <Tooltip title={record.unblock_days + '-' + 'Days Left'} color='#fc4b6c'><StopOutlined /></Tooltip>
                                            </span>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </Fragment>
                )
            }
        }
    ];
    const columns1 = [
        {
            title: '#',
            dataIndex: false,
            align: 'center',
            width: '18px',
            render: (text, record, index) => {
                return index + 1
            }
        },


        {
            title: 'Users',
            dataIndex: 'emp_name',
            key: 'emp_name',
            width: '75px',
            ellipsis: true,
            ...getColumnSearchProps('emp_name', 'Users'),
            filteredValue: filteredInfo.emp_name || null,
            // sorter: (a, b) => a.emp_name.localeCompare(b.emp_name),
            sorter: (a, b) => {

                if (a && a.emp_name && a.emp_name.length && b && b.emp_name && b.emp_name.length) {
                    return a.emp_name.localeCompare(b.emp_name);
                } else if (a && a.emp_name && a.emp_name.length) {
                    // That means be has null division_name, so a will come first.
                    return -1;
                } else if (b && b.emp_name && b.emp_name.length) {
                    // That means a has null division_name so b will come first.
                    return 1;
                }

                // Both division_name has null value so there will be no order change.
                return 0;
            },
            sortOrder: sortedInfo.columnKey === 'emp_name' ? sortedInfo.order : null,
            render: (row, record) => {
                console.log(row, 'row12344948');
                return (
                    <Fragment>
                        {
                            ((record.user_name == null) && (record.is_disable == 0)) ?
                                // <p>{record.emp_name}&nbsp;<MailOutlined style={{ color: '#800000' }} /></p>


                                <Tooltip title="Click here to resend email" > <span>{record.emp_name}&nbsp;<MailOutlined style={{ color: '#800000' }} onClick={() => { resentEmailRegistration(record) }} /></span></Tooltip>

                                : <span>{record.emp_name}</span>
                        }
                    </Fragment>



                )
            }
        },
        {
            title: 'User ID',
            dataIndex: 'user_name_new',
            key: 'user_name_new',
            width: '60px',
            // ellipsis: true,
            ...getColumnSearchProps('user_name_new', 'User ID'),
            filteredValue: filteredInfo.user_name_new || null,
            sorter: (a, b) => {

                if (a && a.user_name_new && a.user_name_new.length && b && b.user_name_new && b.user_name_new.length) {
                    return a.user_name_new.localeCompare(b.user_name_new);
                } else if (a && a.user_name_new && a.user_name_new.length) {
                    // That means be has null division_name, so a will come first.
                    return -1;
                } else if (b && b.user_name_new && b.user_name_new.length) {
                    // That means a has null division_name so b will come first.
                    return 1;
                }

                // Both division_name has null value so there will be no order change.
                return 0;
            },
            // sorter: (a, b) => a.user_name && b.user_name != null ? a.user_name.localeCompare(b.user_name) : "",
            sortOrder: sortedInfo.columnKey === 'user_name_new' ? sortedInfo.order : null,

        },
        {
            title: 'Email ID',
            dataIndex: 'email_id',
            width: '85px',
            key: 'email_id',
            ...getColumnSearchProps('email_id', 'Email ID'),
            ellipsis: true,
            filteredValue: filteredInfo.email_id || null,
            sorter: (a, b) => {

                if (a && a.email_id && a.email_id.length && b && b.email_id && b.email_id.length) {
                    return a.email_id.localeCompare(b.email_id);
                } else if (a && a.email_id && a.email_id.length) {
                    // That means be has null division_name, so a will come first.
                    return -1;
                } else if (b && b.email_id && b.email_id.length) {
                    // That means a has null division_name so b will come first.
                    return 1;
                }

                // Both division_name has null value so there will be no order change.
                return 0;
            },
            // sorter: (a, b) => a.email_id && b.email_id != null ? a.email_id.localeCompare(b.email_id) : "",
            sortOrder: sortedInfo.columnKey === 'email_id' ? sortedInfo.order : null,
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mob_no',
            key: 'mob_no',
            ...getColumnSearchProps('mob_no', 'Mobile Number'),
            width: '50px',
            // ellipsis: true
            filteredValue: filteredInfo.mob_no || null,
            sorter: (a, b) => a.mob_no.localeCompare(b.mob_no),
            // sorter: (a, b) => {

            //     if(a && a.mob_no && a.mob_no.length && b && b.mob_no && b.mob_no.length) {
            //         return a.user_name.localeCompare(b.mob_no);
            //     }else if(a && a.mob_no && a.mob_no.length) {
            //         // That means be has null division_name, so a will come first.
            //         return -1;
            //     } else if(b && b.mob_no && b.mob_no.length) {
            //         // That means a has null division_name so b will come first.
            //         return 1;
            //     }

            //     // Both division_name has null value so there will be no order change.
            //     return 0;
            // },
            sortOrder: sortedInfo.columnKey === 'mob_no' ? sortedInfo.order : null,
        },
        // {
        //     title: 'Registration Email',
        //     dataIndex: 'user_name',
        //     key: 'user_name',
        //     width: '55px',
        //     align: 'center',
        //     // ellipsis: true,
        //     filteredValue: filteredInfo.user_name || null,
        //     sorter: (a, b) => {

        //         if (a && a.user_name && a.user_name.length && b && b.user_name && b.user_name.length) {
        //             return a.user_name.localeCompare(b.user_name);
        //         } else if (a && a.user_name && a.user_name.length) {
        //             // That means be has null division_name, so a will come first.
        //             return -1;
        //         } else if (b && b.user_name && b.user_name.length) {
        //             // That means a has null division_name so b will come first.
        //             return 1;
        //         }

        //         // Both division_name has null value so there will be no order change.
        //         return 0;
        //     },
        //     // sorter: (a, b) => a.user_name && b.user_name != null ? a.user_name.localeCompare(b.user_name) : "",
        //     sortOrder: sortedInfo.columnKey === 'user_name' ? sortedInfo.order : null,
        //     render: (row, record) => {
        //         return (
        //             <Fragment>
        //                 {
        //                     ((row == null) && (record.is_disable == 0)) ?
        //                         <Tooltip title="Click here to resend email" ><Link onClick={() => { resentEmailRegistration(record) }}>Resend</Link></Tooltip>

        //                         : ''
        //                 }
        //             </Fragment>



        //         )
        //     }
        // },
        {
            title: <><label>Actions</label><Tooltip className='toolTipClassusermanagement' placement="topRight" title={"Edit,Status,Disable"}><InfoCircleOutlined /></Tooltip></>,
            dataIndex: 'is_active',
            width: '40px',
            filters: [
                {
                    text: 'Active',
                    value: true,
                },
                {
                    text: 'Inactive',
                    value: false,
                },
            ],

            filteredValue: filteredInfo.is_active,
            onFilter: (value, record) => record.is_active == value,
            filterSearch: false,
            render: (text, record) => {
                console.log(record, 'recordlist');
                return (
                    <Fragment>
                        <div style={{ width: '95%', margin: '0 auto' }} className='row hover'>
                            <div className='col-4 d-flex justify-content-center'>
                                {record.is_disable === 0 ?
                                    <div>
                                        <span className="social-link btn btn-sm btn-light-info text-info btn-circle d-flex align-items-center justify-content-center"
                                            onClick={(e) => { editUser(record.user_id, record) }}>
                                            <Tooltip title="Click here to Edit" color='blue'>
                                                <EditOutlined /></Tooltip>
                                            {/* <i className="ri-edit-line"></i> */}
                                        </span>
                                    </div> :
                                    <div >
                                        <span className="social-link btn btn-sm btn-light-info text-info btn-circle d-flex align-items-center justify-content-center">
                                            <EditOutlined style={{ cursor: 'not-allowed' }}></EditOutlined>
                                        </span>
                                    </div>
                                }
                            </div>
                            <div className='col-4 d-flex justify-content-center px-2'>
                                {
                                    record.is_disable === 0 ?
                                        <div >
                                            {record.is_active == true ?
                                                <span className="social-link btn btn-sm btn-light-success
                                                text-success btn-circle d-flex align-items-center justify-content-center" onClick={(e) => { ActivateById(record, "active") }}>
                                                    {/* text-success btn-circle d-flex align-items-center justify-content-center" onClick={(e) => { statuschange(record, "active") }} */}
                                                    <Tooltip title="Click here to Deactivate" color='#39cb7f'><LikeOutlined /></Tooltip>
                                                </span> :
                                                <span className="social-link btn btn-sm btn-light-danger
                                                text-danger btn-circle d-flex align-items-center justify-content-center"  onClick={(e) => { ActivateById(record, "deactive") }}>
                                                    <Tooltip title="Click here to Activate" color='#fc4b6c'><DislikeOutlined /></Tooltip>
                                                </span>
                                            }
                                        </div> :
                                        <div>
                                            {record.is_active == true ?
                                                <span className="social-link btn btn-sm btn-light-success
                                                text-success btn-circle d-flex align-items-center justify-content-center" >
                                                    <Tooltip title="" color='#39cb7f'><LikeOutlined /></Tooltip>
                                                </span>
                                                :
                                                <span className="social-link btn btn-sm btn-light-danger
                                                text-danger btn-circle d-flex align-items-center justify-content-center" >
                                                    <Tooltip title="De-Active" color='#fc4b6c'><DislikeOutlined /></Tooltip>
                                                </span>


                                            }
                                        </div>

                                }
                            </div>
                            <div className='col-4 d-flex justify-content-center'>
                                {record.is_disable === 0 ?
                                    <span className="social-link btn btn-sm btn-light-success
                                    text-success btn-circle d-flex align-items-center justify-content-center" onClick={() => {
                                            setShow(true)
                                            DisableById(record.user_id, "disable", record.le_id, record);
                                        }}>
                                        <Tooltip title="Click here to Disable" color='#39cb7f'><CheckOutlined /></Tooltip>
                                    </span>
                                    :
                                    <div>
                                        {record.unblock_days === 0 ? '' :
                                            <span className="social-link btn btn-sm btn-light-danger
                                            text-danger btn-circle d-flex align-items-center justify-content-center" onClick={() => {
                                                    setShow(true)
                                                    DisableById(record.user_id, "unDisable", record.le_id, record);
                                                }}>
                                                <Tooltip title={record.unblock_days + '-' + 'Days Left'} color='#fc4b6c'><StopOutlined /></Tooltip>
                                            </span>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </Fragment>
                )
            }
        }
    ];
    const columns3 = [
        {
            title: '#',
            dataIndex: false,
            align: 'center',
            width: '18px',
            render: (text, record, index) => {
                return index + 1
            }
        },
        // {
        //     title: 'Vendor Category',
        //     dataIndex: 'vndr_user_category',
        //     width: '37px',
        //     align: 'center',
        //     filters: [
        //         {
        //             text: <span><i style={{ color: "#9a9da0" }} className="bi bi-square-fill"></i> View Only</span>,
        //             value: 2,
        //         },
        //         {
        //             text: <span><i style={{ color: "#fbca35" }} className="bi bi-square-fill"></i> Legal Entity Admin</span>,
        //             value: 3,
        //         },
        //         {
        //             text: <span><i style={{ color: "#45b0e2" }} className="bi bi-square-fill"></i> Domain Admin</span>,
        //             value: 4,
        //         },
        //         {
        //             text: <span><i style={{ color: "#FF6666" }} className="bi bi-square-fill"></i> Inhouse User</span>,
        //             value: 5,
        //         },
        //         {
        //             text: <span><i style={{ color: "#60667a" }} className="bi bi-square-fill"></i> Service Provider User</span>,
        //             value: 6,
        //         },
        //         {
        //             text: <span><i style={{ color: "#b29999" }} className="bi bi-square-fill"></i> View Only Restricted</span>,
        //             value: 7,
        //         },
        //         {
        //             text: <span><i style={{ color: "#00FFFF" }} className="bi bi-square-fill"></i> Litigation User
        //             </span>,
        //             value: 1,
        //         },
        //         {
        //             text: <span><i style={{ color: "#ff8acc" }} className="bi bi-square-fill"></i> Not Access
        //             </span>,
        //             value: null,
        //         },
        //     ],

        //     filteredValue: filteredInfo.vndr_user_category,
        //     onFilter: (value, record) => record.vndr_user_category == value,
        //     // onFilter: (value, record) => record.is_active === value,
        //     filterSearch: false,
        //     render: (row, record) => {
        //         console.log(record, 'rowworrowwor')
        //         return (
        //             <Fragment>
        //                 {
        //                     (row == 4 ? <i style={{ color: "#45b0e2" }} className="bi bi-square-fill"></i>
        //                         : (row == 3 ? <i style={{ color: "#fbca35" }} className="bi bi-square-fill"></i> :
        //                             (row == 5 ? <i style={{ color: "#e96154" }} className="bi bi-square-fill"></i> :
        //                                 (row == 2 ? <i style={{ color: "#9a9da0" }} className="bi bi-square-fill"></i> :
        //                                     (row == 6 ? <i style={{ color: "#60667a" }} className="bi bi-square-fill"></i> :
        //                                         row == 7 ? <i style={{ color: "#b29999" }} className="bi bi-square-fill"></i> :
        //                                             row == 1 ? <i style={{ color: "#FF6666" }} className="bi bi-square-file"></i> :
        //                                                 row == null ? <i style={{ color: "#ff8acc" }} className="bi bi-square-fill"></i> : false)))))
        //                 }
        //             </Fragment>)
        //     },

        // },
        {
            title: 'Users',
            dataIndex: 'emp_name',
            key: 'emp_name',
            width: '75px',
            ellipsis: true,
            ...getColumnSearchProps('emp_name', 'Users'),
            filteredValue: filteredInfo.emp_name || null,
            // sorter: (a, b) => a.emp_name.localeCompare(b.emp_name),
            sorter: (a, b) => {

                if (a && a.emp_name && a.emp_name.length && b && b.emp_name && b.emp_name.length) {
                    return a.emp_name.localeCompare(b.emp_name);
                } else if (a && a.emp_name && a.emp_name.length) {
                    // That means be has null division_name, so a will come first.
                    return -1;
                } else if (b && b.emp_name && b.emp_name.length) {
                    // That means a has null division_name so b will come first.
                    return 1;
                }

                // Both division_name has null value so there will be no order change.
                return 0;
            },
            sortOrder: sortedInfo.columnKey === 'emp_name' ? sortedInfo.order : null,
            render: (row, record) => {
                console.log(row, 'row12344948');
                return (
                    <Fragment>
                        {

                            ((record.user_name == null) && (record.is_disable == 0)) ?
                                // <p>{record.emp_name}&nbsp;<MailOutlined style={{ color: '#800000' }} /></p>


                                <Tooltip title="Click here to resend email" > <span>{record.emp_name}&nbsp;<MailOutlined onClick={() => { resentEmailRegistration(record) }} style={{ color: '#800000' }} /></span></Tooltip>

                                : <span>{record.emp_name}</span>
                        }


                    </Fragment>



                )
            }
        },
        {
            title: 'User ID',
            dataIndex: 'user_name_new',
            key: 'user_name_new',
            width: '60px',
            // ellipsis: true,
            ...getColumnSearchProps('user_name_new', 'User ID'),
            filteredValue: filteredInfo.user_name_new || null,
            sorter: (a, b) => {

                if (a && a.user_name_new && a.user_name_new.length && b && b.user_name_new && b.user_name_new.length) {
                    return a.user_name_new.localeCompare(b.user_name_new);
                } else if (a && a.user_name_new && a.user_name_new.length) {
                    // That means be has null division_name, so a will come first.
                    return -1;
                } else if (b && b.user_name_new && b.user_name_new.length) {
                    // That means a has null division_name so b will come first.
                    return 1;
                }

                // Both division_name has null value so there will be no order change.
                return 0;
            },
            // sorter: (a, b) => a.user_name && b.user_name != null ? a.user_name.localeCompare(b.user_name) : "",
            sortOrder: sortedInfo.columnKey === 'user_name_new' ? sortedInfo.order : null,

        },
        {
            title: 'Email ID',
            dataIndex: 'email_id',
            width: '85px',
            key: 'email_id',
            ...getColumnSearchProps('email_id', 'Email ID'),
            ellipsis: true,
            filteredValue: filteredInfo.email_id || null,
            sorter: (a, b) => {

                if (a && a.email_id && a.email_id.length && b && b.email_id && b.email_id.length) {
                    return a.email_id.localeCompare(b.email_id);
                } else if (a && a.email_id && a.email_id.length) {
                    // That means be has null division_name, so a will come first.
                    return -1;
                } else if (b && b.email_id && b.email_id.length) {
                    // That means a has null division_name so b will come first.
                    return 1;
                }

                // Both division_name has null value so there will be no order change.
                return 0;
            },
            // sorter: (a, b) => a.email_id && b.email_id != null ? a.email_id.localeCompare(b.email_id) : "",
            sortOrder: sortedInfo.columnKey === 'email_id' ? sortedInfo.order : null,
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mob_no',
            key: 'mob_no',
            ...getColumnSearchProps('mob_no', 'Mobile Number'),
            width: '50px',
            // ellipsis: true
            filteredValue: filteredInfo.mob_no || null,
            sorter: (a, b) => a.mob_no.localeCompare(b.mob_no),
            // sorter: (a, b) => {

            //     if(a && a.mob_no && a.mob_no.length && b && b.mob_no && b.mob_no.length) {
            //         return a.user_name.localeCompare(b.mob_no);
            //     }else if(a && a.mob_no && a.mob_no.length) {
            //         // That means be has null division_name, so a will come first.
            //         return -1;
            //     } else if(b && b.mob_no && b.mob_no.length) {
            //         // That means a has null division_name so b will come first.
            //         return 1;
            //     }

            //     // Both division_name has null value so there will be no order change.
            //     return 0;
            // },
            sortOrder: sortedInfo.columnKey === 'mob_no' ? sortedInfo.order : null,
        },
        // {
        //     title: 'Registration Email',
        //     dataIndex: 'user_name',
        //     key: 'user_name',
        //     width: '55px',
        //     align: 'center',
        //     // ellipsis: true,
        //     filteredValue: filteredInfo.user_name || null,
        //     sorter: (a, b) => {

        //         if (a && a.user_name && a.user_name.length && b && b.user_name && b.user_name.length) {
        //             return a.user_name.localeCompare(b.user_name);
        //         } else if (a && a.user_name && a.user_name.length) {
        //             // That means be has null division_name, so a will come first.
        //             return -1;
        //         } else if (b && b.user_name && b.user_name.length) {
        //             // That means a has null division_name so b will come first.
        //             return 1;
        //         }

        //         // Both division_name has null value so there will be no order change.
        //         return 0;
        //     },
        //     // sorter: (a, b) => a.user_name && b.user_name != null ? a.user_name.localeCompare(b.user_name) : "",
        //     sortOrder: sortedInfo.columnKey === 'user_name' ? sortedInfo.order : null,
        //     render: (row, record) => {
        //         return (
        //             <Fragment>
        //                 {
        //                     ((row == null) && (record.is_disable == 0)) ?
        //                         <Tooltip title="Click here to resend email" ><Link onClick={() => { resentEmailRegistration(record) }}>Resend</Link></Tooltip>

        //                         : ''
        //                 }
        //             </Fragment>



        //         )
        //     }
        // },
        {
            title: <><label>Actions</label><Tooltip className='toolTipClassusermanagement' placement="topRight" title={"Edit,Status,Disable"}><InfoCircleOutlined /></Tooltip></>,
            dataIndex: 'is_active',
            width: '40px',
            filters: [
                {
                    text: 'Active',
                    value: true,
                },
                {
                    text: 'Inactive',
                    value: false,
                },
            ],

            filteredValue: filteredInfo.is_active,
            onFilter: (value, record) => record.is_active == value,
            filterSearch: false,
            render: (text, record) => {
                console.log(record, 'recordlist');
                return (
                    <Fragment>
                        <div style={{ width: '95%', margin: '0 auto' }} className='row hover'>
                            <div className='col-4 d-flex justify-content-center'>
                                {record.is_disable === 0 ?
                                    <div>
                                        <span className="social-link btn btn-sm btn-light-info text-info btn-circle d-flex align-items-center justify-content-center"
                                            onClick={(e) => { editUser(record.user_id, record) }}>
                                            <Tooltip title="Click here to Edit" color='blue'>
                                                <EditOutlined /></Tooltip>
                                            {/* <i className="ri-edit-line"></i> */}
                                        </span>
                                    </div> :
                                    <div >
                                        <span className="social-link btn btn-sm btn-light-info text-info btn-circle d-flex align-items-center justify-content-center">
                                            <EditOutlined style={{ cursor: 'not-allowed' }}></EditOutlined>
                                        </span>
                                    </div>
                                }
                            </div>
                            <div className='col-4 d-flex justify-content-center px-2'>
                                {
                                    record.is_disable === 0 ?
                                        <div >
                                            {record.is_active == true ?
                                                <span className="social-link btn btn-sm btn-light-success
                                                text-success btn-circle d-flex align-items-center justify-content-center" onClick={(e) => { ActivateById(record, "active") }}>
                                                    {/* text-success btn-circle d-flex align-items-center justify-content-center" onClick={(e) => { statuschange(record, "active") }} */}
                                                    <Tooltip title="Click here to Deactivate" color='#39cb7f'><LikeOutlined /></Tooltip>
                                                </span> :
                                                <span className="social-link btn btn-sm btn-light-danger
                                                text-danger btn-circle d-flex align-items-center justify-content-center"  onClick={(e) => { ActivateById(record, "deactive") }}>
                                                    <Tooltip title="Click here to Activate" color='#fc4b6c'><DislikeOutlined /></Tooltip>
                                                </span>
                                            }
                                        </div> :
                                        <div>
                                            {record.is_active == true ?
                                                <span className="social-link btn btn-sm btn-light-success
                                                text-success btn-circle d-flex align-items-center justify-content-center" >
                                                    <Tooltip title="" color='#39cb7f'><LikeOutlined /></Tooltip>
                                                </span>
                                                :
                                                <span className="social-link btn btn-sm btn-light-danger
                                                text-danger btn-circle d-flex align-items-center justify-content-center" >
                                                    <Tooltip title="De-Active" color='#fc4b6c'><DislikeOutlined /></Tooltip>
                                                </span>


                                            }
                                        </div>

                                }
                            </div>
                            <div className='col-4 d-flex justify-content-center'>
                                {record.is_disable === 0 ?
                                    <span className="social-link btn btn-sm btn-light-success
                                    text-success btn-circle d-flex align-items-center justify-content-center" onClick={() => {
                                            setShow(true)
                                            DisableById(record.user_id, "disable", record.le_id, record);
                                        }}>
                                        <Tooltip title="Click here to Disable" color='#39cb7f'><CheckOutlined /></Tooltip>
                                    </span>
                                    :
                                    <div>
                                        {record.unblock_days === 0 ? '' :
                                            <span className="social-link btn btn-sm btn-light-danger
                                            text-danger btn-circle d-flex align-items-center justify-content-center" onClick={() => {
                                                    setShow(true)
                                                    DisableById(record.user_id, "unDisable", record.le_id, record);
                                                }}>
                                                <Tooltip title={record.unblock_days + '-' + 'Days Left'} color='#fc4b6c'><StopOutlined /></Tooltip>
                                            </span>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </Fragment>
                )
            }
        }
    ];

    const resentEmailRegistration = (record) => {
        const payload = [
            authtoken,
            {
                "session_token": authtoken,

                "request": [
                    "ResendRegistrationEmail",
                    {
                        "user_id": record.user_id
                    }
                ]
            }
        ]
        resendEmail({
            payload: payload,
            paramid: paramid
        })

    }


    const editUser = (user_id, record) => {
        setDetails({
            ...details,
            le_id: record.le_id,
            user_id: record.user_id
        })
        setAddType("edit")
        setShowList(true)
        const payload = [
            authtoken,
            {
                "session_token": authtoken,

                "request": [
                    "UserManagementEditView",
                    {
                        "user_id": user_id
                    }
                ]
            }
        ]
        getById({
            payload: payload,
            paramid: paramid
        })


    }
    console.log(disableType, 'disableTypedisableType');
    const DisableById = (user_id, type, le_id, record) => {
        setDisableType(type)
        setUserCategoryId({
            u_cat_id: record.u_cat_id,
            le_id: record.le_id
        })
        type == "disable" ?
            Swal.fire({
                title: 'Are you sure? ',
                text: ' You Want to Disable the User',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',

            }).then((result) => {
                if (result.isConfirmed) {
                    setModaVisible(true)
                    setAddFormSubmit1(false)
                    setModalData({
                        ...modalData,
                        user_id: user_id,
                        le_id: le_id
                    })
                }
            }) : Swal.fire({
                title: 'Are you sure? ',
                text: ' You Want to Enable the User',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',

            }).then((result) => {
                if (result.isConfirmed) {
                    setModaVisible(true)
                    setAddFormSubmit1(false)
                    setModalData({
                        ...modalData,
                        user_id: user_id,
                        le_id: le_id
                    })
                }
            });
    };

    const ActivateById = (user_id, type) => {
        setDisableType(type)
        type == "active" ?
            Swal.fire({
                title: 'Are you sure? ',
                text: ' You Want to Deactivate',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',

            }).then((result) => {
                if (result.isConfirmed) {
                    // setModaVisible(true)
                    statuschange(user_id, type)
                    Setpassvisible(true);
                    setAddFormSubmit1(false)
                    setModalData({
                        ...modalData,
                        user_id: user_id,
                    })
                }
            }) : Swal.fire({
                title: 'Are you sure? ',
                text: ' You Want to Activate',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',

            }).then((result) => {
                if (result.isConfirmed) {
                    Setpassvisible(true);
                    statuschange(user_id, type)
                    setAddFormSubmit1(false)
                    // setModaVisible(true)
                    setModalData({
                        ...modalData,
                        user_id: user_id,
                    })
                }
            });
    };


    const statuschange = (e, type) => {
        Setpassvisible(true);
        if (type == "active") {
            Setpassword({
                ...passwordata,
                user_id: e.user_id,
                le_id: e.le_id
            })
            setActive({
                ...active,
                u_id: e.user_id,
                emp_name: e.emp_name
            })
        }
        else {
            setActive({
                ...active,
                u_id: e.user_id,
                emp_name: e.emp_name
            })
        }
        setType(type)

    }


    useEffect(() => {
        if (entity_info) {
            setLegalEntity(entity_info)
        }
    }, [])

    useEffect(() => {

        let LegalEntityData = _.find(UserLegalDetails && UserLegalDetails, { le_id: entityid })
        setEntity(LegalEntityData)
    }, [entityid, UserLegalDetails])
    console.log(userManagementList, 'userManagementList5555');
    useEffect(() => {
        if (userManagementList && userManagementList.length > 0 && selectedEntity == 'All Legal Entity') {
            let temp = []
            for (let i in userManagementList) {
                for (let j in UserLegalDetails) {
                    if (userManagementList[i].le_id == UserLegalDetails[j].le_id) {
                        let obj = {
                            "email_id": userManagementList[i].email_id,
                            "emp_code": userManagementList[i].emp_code,
                            "emp_name": userManagementList[i].emp_name,
                            "is_active": userManagementList[i].is_active,
                            "is_disable": userManagementList[i].is_disable,
                            "le_id": userManagementList[i].le_id,
                            "mob_no": userManagementList[i].mob_no,
                            "u_cat_id": userManagementList[i].u_cat_id,
                            "u_type_id": userManagementList[i].u_type_id,
                            "unblock_days": userManagementList[i].unblock_days,
                            "user_id": userManagementList[i].user_id,
                            "user_name": userManagementList[i].user_name,
                            "cont_from": UserLegalDetails[j].cont_from,
                            "cont_to": UserLegalDetails[j].cont_to,
                            "l_cont_from": UserLegalDetails[j].l_cont_from,
                            "l_cont_to": UserLegalDetails[j].l_cont_to,
                            "v_cont_from": UserLegalDetails[j].v_cont_from,
                            "v_cont_to": UserLegalDetails[j].v_cont_to,
                            "vndr_user_category": userManagementList[i].vndr_user_category

                        }
                        temp.push(obj)

                    }
                }
            }
            setUserMagementData(temp)
        }
        else if (usermanagementDetails && usermanagementDetails.length > 0 && selectedEntity !== 'All Legal Entity') {
            let temp = []
            let LegalEntityData = _.find(UserLegalDetails && UserLegalDetails, { le_id: entityid })
            for (let i in usermanagementDetails) {
                let obj = {
                    "email_id": usermanagementDetails[i].email_id,
                    "emp_code": usermanagementDetails[i].emp_code,
                    "emp_name": usermanagementDetails[i].emp_name,
                    "is_active": usermanagementDetails[i].is_active,
                    "is_disable": usermanagementDetails[i].is_disable,
                    "le_id": usermanagementDetails[i].le_id,
                    "mob_no": usermanagementDetails[i].mob_no,
                    "u_cat_id": usermanagementDetails[i].u_cat_id,
                    "u_type_id": usermanagementDetails[i].u_type_id,
                    "unblock_days": usermanagementDetails[i].unblock_days,
                    "user_id": usermanagementDetails[i].user_id,
                    "user_name": usermanagementDetails[i].user_name,
                    "cont_from": usermanagementDetails.cont_from,
                    "cont_to": usermanagementDetails.cont_to,
                    "l_cont_from": usermanagementDetails.l_cont_from,
                    "l_cont_to": usermanagementDetails.l_cont_to,
                    "v_cont_from": usermanagementDetails.v_cont_from,
                    "v_cont_to": usermanagementDetails.v_cont_to,
                    "vndr_user_category": usermanagementDetails[i].vndr_user_category

                }
                temp.push(obj)
            }
            setUserMagementData(temp)

        }
    }, [UserLegalDetails, userManagementList, usermanagementDetails])
    console.log(usermangementData, 'usermangementData123');
    console.log(userManagementList, 'userManagementList');
    useEffect(() => {
        if (userManagementList && userManagementList.length > 0 && selectedEntity == 'All Legal Entity') {
            let uniqueObjArray = [...new Map(userManagementList && userManagementList.length > 0 && userManagementList.map((item) => [item["le_id"], item])).values()];

            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.le_id
            })

            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(userManagementList, { le_id: uniqueIds[i] })

                tempArr.push(filteredArr)
            }
            setFilteredArray(tempArr)

        }

    }, [userManagementList])
    useEffect(() => {
        if (userManagementList && userManagementList.length > 0 && selectedEntity !== 'All Legal Entity') {
            // let data = _.uniqBy(userManagementList, function (e) {
            //     return e.user_id;
            // });
            // console.log(data, 'datadatadatadatadata');

            let temp = _.filter(userManagementList, { le_id: entityid })
            console.log(temp, 'temptemptemptemp');
            setuserMAnagementDeails(temp)




        }

    }, [userManagementList])
    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
        }
    }, [localStorage.getItem('SelectedEntityid')])

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    return (
        <>
            <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} />
            {showList == false ? <div id='page-wrapper' className="page-wrapper">
                <div className="page-titles pb-2 pt-2">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 align-self-center">
                            <nav aria-label="breadcrumb">
                                {AddType == 'add' ?
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span style={{ fontSize: '16px' }}>Master</span>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large' }}>
                                            <span>Add user Management</span>
                                        </li>
                                    </ol> : <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span style={{ fontSize: '16px' }}>Master</span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span >User Management</span>
                                            {/* <Link>User Management</Link> */}
                                        </li>
                                    </ol>}
                            </nav>
                        </div>
                        <div
                            className="
                            col-lg-6 col-md-6 col-sm-6 col-xs-6
                d-none d-md-flex
                align-items-center
                justify-content-end">
                            {/* <div> <Tooltip title="" color='#1890ff'>
                                <Button type="primary" shape="round" className='addbutton'

                                    icon={<PlusCircleOutlined />} size='default' onClick={() => {
                                        setShowList(true)
                                        setAddType('add')
                                    }}>
                                    Add
                                </Button>

                            </Tooltip></div> */}
                            <div className="wrapper" style={{ marginRight: '-191px' }}>

                                <Button type="primary" shape="round" style={{ marginRight: '10px' }}
                                    icon={<FilterOutlined />} size='default' onClick={() => {
                                        setFilterModal(true)
                                    }} >
                                    Filter
                                </Button>

                                <Button type="primary" shape="round" style={{ marginRight: '10px' }}
                                    icon={<PlusCircleOutlined />} size='default' onClick={() => {
                                        setShowList(true)
                                        setAddType('add')
                                    }} >
                                    Add
                                </Button>


                                <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" style={{ marginRight: '195px' }} >
                                    <Tooltip placement="top" title="Full Screen Mode"><FullscreenOutlined /></Tooltip>
                                </button>

                                {/* <button onClick={() => {
                                    setShowList(true)
                                    setAddType('add')
                                }} className="icons twitter" style={{ border: 'none', margin: '0px' }}>
                                    <span className="tooltips" style={{ right: '105%' }}>Add</span>
                                    <span><i id={localStorage.getItem('currentTheme')}><PlusCircleOutlined /></i></span>
                                </button> */}
                            </div>

                        </div>
                    </div>



                    {(selectedEntity === 'All Legal Entity' && filterarray && filterarray.length == 0) ?
                        filteredArray && filteredArray.length > 0 && filteredArray.map((item, i) => {
                            let data = _.find(UserLegalDetails && UserLegalDetails.length && UserLegalDetails, { le_id: item && item.length && item[0] && item[0].le_id })
                            console.log(data, 'data55555');
                            if ((moment(data && data.cont_to).isSameOrAfter(date)) || (moment(date).isBefore(data && data.v_cont_to))) {
                                return (

                                    <Card style={{ marginTop: "6px", borderRadius: "15px" }} key={i} className='userCard'>
                                        {/* <div className='col-md-12'> */}

                                        {/* <div className="card w-100 overflow-hidden">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <h4 className="mb-0">Outbound calls</h4>
                    <div className="ms-auto">
                      <span
                        className="
                          text-danger
                          fs-3
                          font-weight-medium
                          d-flex
                          align-items-center
                        "
                        >( <i className="ri-arrow-down-s-fill"></i> 18% High )</span
                      >
                    </div>
                  </div>
                  <div className="d-flex align-items-center my-3">
                    <div className="border-end pe-3">
                      <h6 className="text-muted fw-normal">Yearly</h6>
                      <b>80.40%</b>
                    </div>
                    <div className="ms-3 border-end pe-3">
                      <h6 className="text-muted fw-normal">Montly</h6>
                      <b>15.40%</b>
                    </div>
                    <div className="ms-3">
                      <h6 className="text-muted fw-normal">Day</h6>
                      <b>5.50%</b>
                    </div>
                  </div>
                  <a
                    href="javascript:void(0)"
                    className="btn btn-light-primary text-primary"
                    >Learn More</a
                  >
                </div>
                <div className="progress">
                  <div
                    className="
                      progress-bar progress-bar-striped
                      bg-primary
                      progress-bar-animated
                    "
                    style="width: 60%; height: 6px"
                    role="progressbar"
                  >
                    <span className="sr-only">60% Complete</span>
                  </div>
                </div>
              </div> 

                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label ><b>Country: </b>{data && data.c_name}</label>
                                            </div>
                                            <div className='col-md-4' style={{ textAlign: "center" }}>
                                                <label ><b>Business Group: </b>{data && data.b_g_name}</label>
                                            </div>
                                            <div className='col-md-4' tyle={{ textAlign: "center" }}>
                                                <label><b >Legal Entity: </b>{data && data.le_name}</label>
                                            </div>
                                            {/* <hr></hr> 
                                        </div>
                                    </div>

                                    {/* {
                                        ((data && data.total_licences) && (moment(data && data.cont_to).isSameOrAfter(date)))
                                            ?
                                            <div className='col-md-12' style={{ marginTop: "10px" }}>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <label><span><b>Compfie Total Licence(s):</b></span> {data && data.total_licences} <span><b>Used:</b></span> {data && data.used_licences} | <span><b>Remaining:</b></span> {Number(data && data.total_licences) - Number(data && data.used_licences)}</label>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <label style={{ textAlign: "center" }}><span><b>Contract From:</b></span> {data && data.cont_from}</label>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <label style={{ textAlign: "center" }}><span><b>Contract To:</b></span> {data && data.cont_to}</label>
                                                    </div>
                                                </div>
                                            </div> : ''}
                                    {((data && data.l_total_licences) && (moment(data && data.l_cont_to).isSameOrAfter(date)))

                                        ?
                                        <div className='col-md-12' style={{ marginTop: "10px" }}>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <label><span><b>Compfie Litigation Total Licence(s):</b></span> {data && data.l_total_licences} <span><b>Used:</b></span> {data && data.l_used_licences} | <span><b>Remaining:</b></span> {Number(data && data.l_total_licences) - Number(data && data.l_used_licences)}</label>
                                                </div>
                                                <div className='col-md-3'>


                                                    <label ><span><b>Contract From:</b></span> {data && data.l_cont_from}</label>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label ><span><b>Contract To:</b></span> {data && data.l_cont_to}</label>
                                                </div>
                                            </div>
                                        </div>
                                        : ''}
                                    {((data && data.v_total_licences) && (moment(data && data.v_cont_to).isSameOrAfter(date))) ?
                                        <div className='col-md-12'>*/}

                                        <div className='row px-4'>


                                            <div className='col-md-4' >
                                                <label ><b>Country: </b>{data && data.c_name}</label>
                                            </div>
                                            <div className='col-md-4'  >
                                                <label ><b>Business Group:</b>  {data && data.b_g_name}</label>
                                            </div>
                                            <div className='col-md-4' >
                                                <label><b >Legal Entity:</b><span style={{ marginLeft: "10px" }}></span>{data && data.le_name}</label>
                                            </div>

                                            {/* <hr></hr> */}
                                        </div>

                                        <div className="row mt-1">
                                            <div className="accordion" id="accordionExample ">
                                                <div className="accordion-item" style={{ borderRadius: '15px' }}>
                                                    <h2 className="accordion-header" id="headingOne">
                                                        <button
                                                            className="accordion-button accor-head p-2"
                                                            type="button"
                                                            id={i}
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#collapseOne"
                                                            aria-expanded="true"
                                                            aria-controls="collapseOne"
                                                        >
                                                            <span><b>{data && data.le_name} - {item.length} Users</b></span>
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id="collapseOne"
                                                        className="accordion-collapse collapse show"
                                                        aria-labelledby="headingOne"
                                                        data-bs-parent="#accordionExample"
                                                    >
                                                        <div className="accordion-body pb-1">
                                                            <div className="row">
                                                                <div className="col-12">


                                                                    <div
                                                                        id="carouselExampleControls"
                                                                        className="carousel slide carousel-dark user-carousal"
                                                                        /*data-bs-ride="carousel"*/data-bs-touch="false" data-bs-interval="false"
                                                                    >
                                                                        <div className="carousel-inner">
                                                                            <div className="carousel-item active">
                                                                                <div className="row">
                                                                                    <div className="col-md-4 px-2">
                                                                                        <div className="card mb-0 w-100 overflow-hidden card-hover mb-3">
                                                                                            <div className="card-body py-2 px-2">
                                                                                                <div className="d-flex align-items-center ">
                                                                                                    <h4 className="mb-0 fs-3">Compfie Total Licence(s)</h4>

                                                                                                </div>
                                                                                                <div className="d-flex align-items-center my-3 mb-1">
                                                                                                    <div style={{ backgroundColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ' }} className={"border-end pe-3 text-white text-center rounded p-2"}>
                                                                                                        {/* :`border-end pe-3 ${localStorage.getItem("currentTheme")} text-white rounded p-2` */}
                                                                                                        <h6 className="fw-bold" style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>Total</h6>
                                                                                                        <b className='fw-bold' style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>{data && data.total_licences} </b>
                                                                                                    </div>
                                                                                                    <div className="ms-3 border-end pe-3 text-center">
                                                                                                        <h6 className="text-muted fw-normal">Used</h6>
                                                                                                        <b>{data && data.used_licences}</b>
                                                                                                    </div>
                                                                                                    <div className="ms-3 pe-3 text-center">
                                                                                                        <h6 className="text-muted fw-normal">Remaining</h6>
                                                                                                        <b>{Number(data && data.total_licences) - Number(data && data.used_licences)}</b>
                                                                                                    </div>

                                                                                                </div>
                                                                                                <div className="d-flex">
                                                                                                    <span className="fs-2  font-weight-medium">Contract From : {data && data.cont_from}</span>
                                                                                                    <span className="fs-2 ms-3 font-weight-medium">Contract To : {data && data.cont_to}</span>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-4 px-2">
                                                                                        <div className="card mb-0 w-100 overflow-hidden  card-hover mb-3">
                                                                                            <div className="card-body py-2 px-2">
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <h4 className="mb-0 fs-3">Compfie Litigation Total Licence(s)</h4>

                                                                                                </div>
                                                                                                <div className="d-flex align-items-center my-3 mb-1">
                                                                                                    <div style={{ backgroundColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ' }} className={"border-end pe-3 text-center text-white rounded p-2"}>
                                                                                                        <h6 className="fw-bold" style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>Total</h6>
                                                                                                        <b className='fw-bold' style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>{data && data.l_total_licences} </b>
                                                                                                    </div>
                                                                                                    <div className="ms-3 border-end pe-3 text-center">
                                                                                                        <h6 className="text-muted fw-normal">Used</h6>
                                                                                                        <b>{data && data.l_used_licences}</b>
                                                                                                    </div>
                                                                                                    <div className="ms-3 pe-3 text-center">
                                                                                                        <h6 className="text-muted fw-normal">Remaining</h6>
                                                                                                        <b>{Number(data && data.l_total_licences) - Number(data && data.l_used_licences)}</b>
                                                                                                    </div>

                                                                                                </div>
                                                                                                <div className="d-flex">
                                                                                                    <span className="fs-2  font-weight-medium">Contract From : {data && data.cont_from}</span>
                                                                                                    <span className="fs-2 ms-3 font-weight-medium">Contract To : {data && data.cont_to}</span>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-4 px-2">
                                                                                        <div className="card mb-0 w-100 overflow-hidden card-hover mb-3">
                                                                                            <div className="card-body py-2 px-2">
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <h4 className="mb-0 fs-3">Compfie Vendor Total Licence(s)</h4>

                                                                                                </div>
                                                                                                <div className="d-flex align-items-center my-3 mb-1">
                                                                                                    <div style={{ backgroundColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ' }} className={"border-end pe-3 text-center text-white rounded p-2"}>
                                                                                                        <h6 className="fw-bold" style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>Total</h6>
                                                                                                        <b className='fw-bold' style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>{data && data.v_total_licences} </b>
                                                                                                    </div>
                                                                                                    <div className="ms-3 border-end pe-3 text-center">
                                                                                                        <h6 className="text-muted fw-normal">Used</h6>
                                                                                                        <b>{data && data.v_used_licences}</b>
                                                                                                    </div>
                                                                                                    <div className="ms-3 pe-3 text-center">
                                                                                                        <h6 className="text-muted fw-normal">Remaining</h6>
                                                                                                        <b>{Number(data && data.v_total_licences) - Number(data && data.v_used_licences)}</b>
                                                                                                    </div>

                                                                                                </div>

                                                                                                <div className="d-flex">
                                                                                                    <span className="fs-2  font-weight-medium">Contract From : {data && data.v_cont_from}</span>

                                                                                                    <span className="fs-2 ms-3 font-weight-medium">Contract To : {data && data.v_cont_to}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="carousel-item ">
                                                                                <div className="row">
                                                                                    <div className="col-md-4 px-2">
                                                                                        <div className="card mb-0 w-100 overflow-hidden card-hover mb-3">
                                                                                            <div className="card-body py-2 px-2">
                                                                                                <div className="d-flex align-items-center ">
                                                                                                    <h4 className="mb-0 fs-3">Compfie Total Licence(s)</h4>

                                                                                                </div>
                                                                                                <div className="d-flex align-items-center my-3 mb-1">
                                                                                                    <div style={{ backgroundColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ' }} className={"border-end pe-3 text-white text-center rounded p-2"}>
                                                                                                        {/* :`border-end pe-3 ${localStorage.getItem("currentTheme")} text-white rounded p-2` */}
                                                                                                        <h6 className="fw-bold" style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>Total</h6>
                                                                                                        <b className='fw-bold' style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>{data && data.total_licences} </b>
                                                                                                    </div>
                                                                                                    <div className="ms-3 border-end pe-3 text-center">
                                                                                                        <h6 className="text-muted fw-normal">Used</h6>
                                                                                                        <b>{data && data.used_licences}</b>
                                                                                                    </div>
                                                                                                    <div className="ms-3 pe-3 text-center">
                                                                                                        <h6 className="text-muted fw-normal">Remaining</h6>
                                                                                                        <b>{Number(data && data.total_licences) - Number(data && data.used_licences)}</b>
                                                                                                    </div>

                                                                                                </div>
                                                                                                <div className="d-flex">
                                                                                                    <span className="fs-2  font-weight-medium">Contract From : {data && data.cont_from}</span>

                                                                                                    <span className="fs-2 ms-3 font-weight-medium">Contract To : {data && data.cont_to}</span>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-4 px-2">
                                                                                        <div className="card mb-0 w-100 overflow-hidden  card-hover mb-3">
                                                                                            <div className="card-body py-2 px-2">
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <h4 className="mb-0 fs-3">Compfie Litigation Total Licence(s)</h4>

                                                                                                </div>
                                                                                                <div className="d-flex align-items-center my-3 mb-1">
                                                                                                    <div style={{ backgroundColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ' }} className={"border-end pe-3 text-center text-white rounded p-2"}>
                                                                                                        <h6 className="fw-bold" style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>Total</h6>
                                                                                                        <b className='fw-bold' style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>{data && data.l_total_licences} </b>
                                                                                                    </div>
                                                                                                    <div className="ms-3 border-end pe-3 text-center">
                                                                                                        <h6 className="text-muted fw-normal">Used</h6>
                                                                                                        <b>{data && data.l_used_licences}</b>
                                                                                                    </div>
                                                                                                    <div className="ms-3 pe-3 text-center">
                                                                                                        <h6 className="text-muted fw-normal">Remaining</h6>
                                                                                                        <b>{Number(data && data.l_total_licences) - Number(data && data.l_used_licences)}</b>
                                                                                                    </div>

                                                                                                </div>
                                                                                                <div className="d-flex">
                                                                                                    <span className="fs-2  font-weight-medium">Contract From : {data && data.cont_from}</span>

                                                                                                    <span className="fs-2 ms-3 font-weight-medium">Contract To : {data && data.cont_to}</span>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-4 px-2">
                                                                                        <div className="card mb-0 w-100 overflow-hidden card-hover mb-3">
                                                                                            <div className="card-body py-2 px-2">
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <h4 className="mb-0 fs-3">Compfie Vendor Total Licence(s)</h4>

                                                                                                </div>
                                                                                                <div className="d-flex align-items-center my-3 mb-1">
                                                                                                    <div style={{ backgroundColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ' }} className={"border-end pe-3 text-center text-white rounded p-2"}>
                                                                                                        <h6 className="fw-bold" style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>Total</h6>
                                                                                                        <b className='fw-bold' style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>{data && data.v_total_licences} </b>
                                                                                                    </div>
                                                                                                    <div className="ms-3 border-end pe-3 text-center">
                                                                                                        <h6 className="text-muted fw-normal">Used</h6>
                                                                                                        <b>{data && data.v_used_licences}</b>
                                                                                                    </div>
                                                                                                    <div className="ms-3 pe-3 text-center">
                                                                                                        <h6 className="text-muted fw-normal">Remaining</h6>
                                                                                                        <b>{Number(data && data.v_total_licences) - Number(data && data.v_used_licences)}</b>
                                                                                                    </div>

                                                                                                </div>
                                                                                                <div className="d-flex">
                                                                                                    <span className="fs-2  font-weight-medium">Contract From : {data && data.v_cont_from}</span>

                                                                                                    <span className="fs-2 ms-3 font-weight-medium">Contract To : {data && data.v_cont_to}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        {/* {UserLegalDetails && UserLegalDetails.length > 5 ? */}
                                                                        <><a
                                                                            className="carousel-control-prev"
                                                                            href="#carouselExampleControls"
                                                                            role="button"
                                                                            data-bs-slide="prev"
                                                                        >
                                                                            <span
                                                                                className="carousel-control-prev-icon"
                                                                                aria-hidden="true"
                                                                            ></span>
                                                                            <span className="visually-hidden">Previous</span>
                                                                        </a>
                                                                            <a
                                                                                className="carousel-control-next"
                                                                                href="#carouselExampleControls"
                                                                                role="button"
                                                                                data-bs-slide="next"
                                                                            >
                                                                                <span
                                                                                    className="carousel-control-next-icon"
                                                                                    aria-hidden="true"
                                                                                ></span>
                                                                                <span className="visually-hidden">Next</span>
                                                                            </a>
                                                                        </>
                                                                        {/* : false} */}
                                                                    </div>


                                                                </div>




                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        {/* <div className='col-md-12' style={{ marginTop: "10px" }}>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <label><span><b>Compfie Vendor Total Licence(s):</b></span> {data && data.v_total_licences} <span><b>Used:</b></span> {data && data.v_used_licences} | <span><b>Remaining:</b></span> {Number(data && data.v_total_licences) - Number(data && data.v_used_licences)}</label>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label ><span><b>Contract From:</b></span> {data && data.v_cont_from}</label>
                                                </div>

                                                <div className='col-md-3'>
                                                    <label><span><b>Contract To:</b></span> {data && data.v_cont_to}</label>
                                                </div>

                                            </div>
                                        </div>  */}
                                        {/* </div>


                                        : ''} */}


                                        <div className='col-md-12 usersplittable'>
                                            <Table
                                                id='service-user-priv'
                                                className={localStorage.getItem("currentTheme")}
                                                size={'small'}
                                                columns={((moment(data && data.v_cont_to).isSameOrAfter(date)) && (moment(data && data.cont_to).isSameOrAfter(date))) ? columns : (moment(data && data.cont_to).isSameOrAfter(date)) ? columns2 : moment(date).isBefore(data && data.v_cont_to) ? columns3 : false}
                                                dataSource={item && item.length > 0 && item.map((itemslist) => {
                                                    if (itemslist.user_name == null) {
                                                        itemslist.user_name_new = ''
                                                    } else {
                                                        itemslist.user_name_new = itemslist.user_name
                                                    }
                                                    return itemslist
                                                })}
                                                onChange={tablehandleChange}
                                                bordered
                                                pagination={false}
                                                showSorterTooltip={false}

                                            />

                                        </div>
                                        <div className='card p-4 py-2 mb-0 rounded user-mapping bg-white'>
                                            <div className="row">
                                                <div className='col-md-3'>
                                                    <i style={{ color: "#45b0e2" }} className="bi bi-square-fill"></i> <label>Domain Admin</label>
                                                </div>
                                                <div className='col-md-3'>
                                                    <i style={{ color: "#e96154" }} className="bi bi-square-fill"></i> <label>Inhouse User</label>
                                                </div>
                                                <div className='col-md-3'>
                                                    <i style={{ color: "#fbca35" }} className="bi bi-square-fill"></i> <label>Legal Entity Admin</label>
                                                </div>
                                                <div className='col-md-3'>
                                                    <i style={{ color: "#60667a" }} className="bi bi-square-fill"></i> <label>Service Provider User</label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className='col-md-3'>
                                                    <i style={{ color: "#9a9da0" }} className="bi bi-square-fill"></i> <label>View Only</label>
                                                </div>
                                                <div className='col-md-3'>
                                                    <i style={{ color: "#b29999" }} className="bi bi-square-fill"></i> <label>View Only Restricted</label>
                                                </div>
                                                <div className='col-md-3'>
                                                    <i style={{ color: "#00FFFF" }} className="bi bi-square-fill"></i> <label>Litigation User</label>
                                                </div>
                                                <div className='col-md-3'>
                                                    {/* <i style={{ color: "skyblue" }} class="bi bi-square-fill"></i> <label>Litigation User</label> */}
                                                    <i style={{ color: "#ff8acc" }} className="bi bi-square-fill"></i> <label>Not Access</label>


                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                )
                            }
                            else {
                                return false
                            }
                        })
                        :
                        <Card style={{ marginTop: "6px", borderRadius: "15px" }} className='userCard'>
                            <div className='col-md-12'>

                                <div className='row px-4'>


                                    <div className='col-md-4' >
                                        <label ><b>Country: </b>{Entity && Entity.c_name}</label>
                                    </div>
                                    <div className='col-md-4'  >
                                        <label ><b>Business Group:</b>  {Entity && Entity.b_g_name}</label>
                                    </div>
                                    <div className='col-md-4' >
                                        <label><b >Legal Entity:</b><span style={{ marginLeft: "10px" }}></span>{Entity && Entity.le_name}</label>
                                    </div>

                                    {/* <hr></hr> */}
                                </div>
                                {/* <div className="row mt-1">
                                    <div className="accordion" id="accordionExample2">
                                        <div className="accordion-item" style={{ borderRadius: '15px' }}>
                                            <h2 className="accordion-header" id="headingTwo">
                                                <button
                                                    style={{ borderRadius: '15px' }}
                                                    className="accordion-button p-2 "
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseTwo"
                                                    aria-expanded="true"
                                                    aria-controls="collapseTwo"
                                                >
                                                    <span>{Entity && Entity.le_name} - {usermangementData.length} Users</span>
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseTwo"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="headingTwo"
                                                data-bs-parent="#accordionExample2"
                                            >
                                                <div className="accordion-body pb-1">
                                                    <div className="row">
                                                        <div className="col-md-3 px-2">
                                                            <div className="card w-100 overflow-hidden card-hover mb-3">
                                                                <div className="card-body py-2">
                                                                    <div className="d-flex align-items-center" >
                                                                        <h4 className="mb-0 fs-4">Compfie Total Licence(s)</h4>

                                                                    </div>
                                                                    <div className="d-flex align-items-center my-3 mb-1">
                                                                        <div style={{ backgroundColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ' }} className={"border-end text-center pe-3 text-white rounded p-2"}>
                                                                            <h6 className="text-white fw-bold">Total</h6>
                                                                            <b className='text-white fw-bold'>{Entity && Entity.total_licences} </b>
                                                                        </div>
                                                                        <div className="ms-3 border-end pe-3 text-center">
                                                                            <h6 className="text-muted fw-normal">Used</h6>
                                                                            <b> {Entity && Entity.used_licences}</b>
                                                                        </div>
                                                                        <div className="ms-3  pe-3 text-center">
                                                                            <h6 className="text-muted fw-normal">Remaining</h6>
                                                                            <b>{Number(Entity && Entity.total_licences) - Number(Entity && Entity.used_licences)}</b>
                                                                        </div>

                                                                    </div>
                                                                    <span class="fs-2  font-weight-medium">Contract From : {Entity && Entity.cont_from}</span> <br />
                                                                    <span class="fs-2  font-weight-medium">Contract To : {Entity && Entity.cont_to}</span>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 px-2">
                                                            <div className="card w-100 overflow-hidden card-hover mb-3">
                                                                <div className="card-body py-2">
                                                                    <div className="d-flex align-items-center">
                                                                        <h4 className="mb-0 fs-3">Compfie Litigation Total Licence(s)</h4>

                                                                    </div>
                                                                    <div className="d-flex align-items-center my-3 mb-1">
                                                                        <div style={{ backgroundColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ' }} className={"border-end pe-3 text-center text-white rounded p-2"}>
                                                                            <h6 className="text-white fw-bold">Total</h6>
                                                                            <b className='text-white fw-bold'>{Entity && Entity.l_total_licences}</b>
                                                                        </div>
                                                                        <div className="ms-3 border-end pe-3 text-center">
                                                                            <h6 className="text-muted fw-normal">Used</h6>
                                                                            <b>{Entity && Entity.l_used_licences}</b>
                                                                        </div>
                                                                        <div className="ms-3 border-end pe-3 text-center">
                                                                            <h6 className="text-muted fw-normal">Remaining</h6>
                                                                            <b>{Number(Entity && Entity.l_total_licences) - Number(Entity && Entity.l_used_licences)}</b>
                                                                        </div>

                                                                    </div>
                                                                    <span class="fs-2  font-weight-medium">Contract From : {Entity && Entity.l_cont_from} </span> <br />
                                                                    <span class="fs-2  font-weight-medium">Contract To : {Entity && Entity.l_cont_to}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 px-2">
                                                            <div className="card w-100 overflow-hidden card-hover mb-3">
                                                                <div className="card-body py-2">
                                                                    <div className="d-flex align-items-center">
                                                                        <h4 className="mb-0 fs-3">Compfie Vendor Total Licence(s)</h4>

                                                                    </div>
                                                                    <div className="d-flex align-items-center my-3 mb-1">
                                                                        <div style={{ backgroundColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ' }} className={"border-end pe-3 text-white text-center rounded p-2"}>
                                                                            <h6 className="text-white fw-bold">Total</h6>
                                                                            <b className='text-white fw-bold'>{Entity && Entity.v_total_licences} </b>
                                                                        </div>
                                                                        <div className="ms-3 border-end pe-3 text-center">
                                                                            <h6 className="text-muted fw-normal">Used</h6>
                                                                            <b>{Entity && Entity.v_used_licences}</b>
                                                                        </div>
                                                                        <div className="ms-3 border-end pe-3 text-center">
                                                                            <h6 className="text-muted fw-normal">Remaining</h6>
                                                                            <b>{Number(Entity && Entity.v_total_licences) - Number(Entity && Entity.v_used_licences)}</b>
                                                                        </div>

                                                                    </div>
                                                                    <span class="fs-2  font-weight-medium">Contract From : {Entity && Entity.v_cont_from}</span> <br />
                                                                    <span class="fs-2  font-weight-medium">Contract To : {Entity && Entity.v_cont_to} </span>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 px-2">
                                                            <div className="card mb-0 px-2 py-3">
                                                                <div className="row">
                                                                    <div className='col-md-3 px-3 py-1'>
                                                                        <i style={{ color: "#45b0e2" }} className="bi bi-square-fill"></i> <label className='fs-2 lh-1'>Domain Admin</label>
                                                                    </div>
                                                                    <div className='col-md-3 px-3 py-1'>
                                                                        <i style={{ color: "#e96154" }} className="bi bi-square-fill"></i> <label className='fs-2 lh-1'>Inhouse User</label>
                                                                    </div>
                                                                    <div className='col-md-3 px-3 py-1'>
                                                                        <i style={{ color: "#fbca35" }} className="bi bi-square-fill"></i> <label className='fs-2 lh-1'>Legal Entity Admin</label>
                                                                    </div>
                                                                    <div className='col-md-3 px-3 py-1'>
                                                                        <i style={{ color: "#60667a" }} className="bi bi-square-fill"></i> <label className='fs-2 lh-1'>Service Provider User</label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-0 py-0">
                                                                    <div className='col-md-3 px-3 py-1'>
                                                                        <i style={{ color: "#9a9da0" }} className="bi bi-square-fill"></i> <label className='fs-2 lh-1'>View Only</label>
                                                                    </div>
                                                                    <div className='col-md-3 px-3 py-1'>
                                                                        <i style={{ color: "#b29999" }} className="bi bi-square-fill"></i> <label className='fs-2 lh-1'>View Only Restricted</label>
                                                                    </div>
                                                                    <div className='col-md-3 px-3 py-1'>
                                                                        <i style={{ color: "#00FFFF" }} className="bi bi-square-fill"></i> <label className='fs-2 lh-1'>Litigation User</label>
                                                                    </div>
                                                                    <div className='col-md-3 px-3 py-1'>
                                                                        {/* <i style={{ color: "skyblue" }} class="bi bi-square-fill"></i> <label>Litigation User</label> */}
                                {/* <i style={{ color: "#ff8acc" }} className="bi bi-square-fill"></i> <label className='fs-2 lh-1'>Not Access</label>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div> */}



                                <div className="row mt-1">
                                    <div className="accordion" id="accordionExample ">
                                        <div className="accordion-item" style={{ borderRadius: '15px' }}>
                                            <h2 className="accordion-header" id="headingOne">
                                                <button
                                                    className="accordion-button accor-head p-2"
                                                    type="button"
                                                    // id={i}
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseOne"
                                                    aria-expanded="true"
                                                    aria-controls="collapseOne"
                                                >
                                                    <span><b>{Entity && Entity.le_name} - {usermangementData.length} Users</b></span>
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseOne"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="headingOne"
                                                data-bs-parent="#accordionExample"
                                            >
                                                <div className="accordion-body pb-1">
                                                    <div className="row">
                                                        <div className="col-12">


                                                            <div
                                                                id="carouselExampleControls"
                                                                className="carousel slide carousel-dark user-carousal"
                                                                /*data-bs-ride="carousel"*/ data-bs-touch="false" data-bs-interval="false"
                                                            >
                                                                <div className="carousel-inner">
                                                                    <div className="carousel-item active">
                                                                        <div className="row">
                                                                            <div className="col-md-4 px-2">
                                                                                <div className="card mb-0 w-100 overflow-hidden card-hover mb-3">
                                                                                    <div className="card-body py-2 px-2">
                                                                                        <div className="d-flex align-items-center ">
                                                                                            <h4 className="mb-0 fs-3">Compfie Total Licence(s)</h4>

                                                                                        </div>
                                                                                        <div className="d-flex align-items-center my-3 mb-1">
                                                                                            <div style={{ backgroundColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ' }} className={"border-end pe-3 text-white text-center rounded p-2"}>
                                                                                                {/* :`border-end pe-3 ${localStorage.getItem("currentTheme")} text-white rounded p-2` */}
                                                                                                <h6 className="fw-bold" style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>Total</h6>
                                                                                                <b className='fw-bold' style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>{Entity && Entity.total_licences} </b>
                                                                                            </div>
                                                                                            <div className="ms-3 border-end pe-3 text-center">
                                                                                                <h6 className="text-muted fw-normal">Used</h6>
                                                                                                <b>{Entity && Entity.used_licences}</b>
                                                                                            </div>
                                                                                            <div className="ms-3 pe-3 text-center">
                                                                                                <h6 className="text-muted fw-normal">Remaining</h6>
                                                                                                <b>{Number(Entity && Entity.total_licences) - Number(Entity && Entity.used_licences)}</b>
                                                                                            </div>

                                                                                        </div>
                                                                                        <div className="d-flex">
                                                                                            <span className="fs-2  font-weight-medium">Contract From : {Entity && Entity.cont_from}</span>

                                                                                            <span className="fs-2 ms-3 font-weight-medium">Contract To : {Entity && Entity.cont_to}</span>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4 px-2">
                                                                                <div className="card mb-0 w-100 overflow-hidden  card-hover mb-3">
                                                                                    <div className="card-body py-2 px-2">
                                                                                        <div className="d-flex align-items-center">
                                                                                            <h4 className="mb-0 fs-3">Compfie Litigation Total Licence(s)</h4>

                                                                                        </div>
                                                                                        <div className="d-flex align-items-center my-3 mb-1">
                                                                                            <div style={{ backgroundColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ' }} className={"border-end pe-3 text-center text-white rounded p-2"}>
                                                                                                <h6 className="fw-bold" style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>Total</h6>
                                                                                                <b className='fw-bold' style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>{Entity && Entity.l_total_licences} </b>
                                                                                            </div>
                                                                                            <div className="ms-3 border-end pe-3 text-center">
                                                                                                <h6 className="text-muted fw-normal">Used</h6>
                                                                                                <b>{Entity && Entity.l_used_licences}</b>
                                                                                            </div>
                                                                                            <div className="ms-3 pe-3 text-center">
                                                                                                <h6 className="text-muted fw-normal">Remaining</h6>
                                                                                                <b>{Number(Entity && Entity.l_total_licences) - Number(Entity && Entity.l_used_licences)}</b>
                                                                                            </div>

                                                                                        </div>
                                                                                        <div className="d-flex">
                                                                                            <span className="fs-2  font-weight-medium">Contract From : {Entity && Entity.cont_from}</span>

                                                                                            <span className="fs-2 ms-3 font-weight-medium">Contract To : {Entity && Entity.cont_to}</span>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4 px-2">
                                                                                <div className="card mb-0 w-100 overflow-hidden card-hover mb-3">
                                                                                    <div className="card-body py-2 px-2">
                                                                                        <div className="d-flex align-items-center">
                                                                                            <h4 className="mb-0 fs-3">Compfie Vendor Total Licence(s)</h4>

                                                                                        </div>
                                                                                        <div className="d-flex align-items-center my-3 mb-1">
                                                                                            <div style={{ backgroundColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ' }} className={"border-end pe-3 text-center text-white rounded p-2"}>
                                                                                                <h6 className="fw-bold" style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>Total</h6>
                                                                                                <b className='fw-bold' style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>{Entity && Entity.v_total_licences} </b>
                                                                                            </div>
                                                                                            <div className="ms-3 border-end pe-3 text-center">
                                                                                                <h6 className="text-muted fw-normal">Used</h6>
                                                                                                <b>{Entity && Entity.v_used_licences}</b>
                                                                                            </div>
                                                                                            <div className="ms-3 pe-3 text-center">
                                                                                                <h6 className="text-muted fw-normal">Remaining</h6>
                                                                                                <b>{Number(Entity && Entity.v_total_licences) - Number(Entity && Entity.v_used_licences)}</b>
                                                                                            </div>

                                                                                        </div>
                                                                                        <div className="d-flex">
                                                                                            <span className="fs-2  font-weight-medium">Contract From : {Entity && Entity.v_cont_from}</span>

                                                                                            <span className="fs-2 ms-3 font-weight-medium">Contract To : {Entity && Entity.v_cont_to}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="carousel-item ">
                                                                        <div className="row">
                                                                            <div className="col-md-4 px-2">
                                                                                <div className="card mb-0 w-100 overflow-hidden card-hover mb-3">
                                                                                    <div className="card-body py-2 px-2">
                                                                                        <div className="d-flex align-items-center ">
                                                                                            <h4 className="mb-0 fs-3">Compfie Total Licence(s)</h4>

                                                                                        </div>
                                                                                        <div className="d-flex align-items-center my-3 mb-1">
                                                                                            <div style={{ backgroundColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ' }} className={"border-end pe-3 text-white text-center rounded p-2"}>
                                                                                                {/* :`border-end pe-3 ${localStorage.getItem("currentTheme")} text-white rounded p-2` */}
                                                                                                <h6 className="fw-bold" style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>Total</h6>
                                                                                                <b className='fw-bold' style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>{Entity && Entity.total_licences} </b>
                                                                                            </div>
                                                                                            <div className="ms-3 border-end pe-3 text-center">
                                                                                                <h6 className="text-muted fw-normal">Used</h6>
                                                                                                <b>{Entity && Entity.used_licences}</b>
                                                                                            </div>
                                                                                            <div className="ms-3 pe-3 text-center">
                                                                                                <h6 className="text-muted fw-normal">Remaining</h6>
                                                                                                <b>{Number(Entity && Entity.total_licences) - Number(Entity && Entity.used_licences)}</b>
                                                                                            </div>

                                                                                        </div>
                                                                                        <div className="d-fex">
                                                                                            <span className="fs-2  font-weight-medium">Contract From : {Entity && Entity.cont_from}</span>
                                                                                            <span className="fs-2 ms-3 font-weight-medium">Contract To : {Entity && Entity.cont_to}</span>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4 px-2">
                                                                                <div className="card mb-0 w-100 overflow-hidden  card-hover mb-3">
                                                                                    <div className="card-body py-2 px-2">
                                                                                        <div className="d-flex align-items-center">
                                                                                            <h4 className="mb-0 fs-3">Compfie Litigation Total Licence(s)</h4>

                                                                                        </div>
                                                                                        <div className="d-flex align-items-center my-3 mb-1">
                                                                                            <div style={{ backgroundColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ' }} className={"border-end pe-3 text-center text-white rounded p-2"}>
                                                                                                <h6 className="fw-bold" style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>Total</h6>
                                                                                                <b className='fw-bold' style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>{Entity && Entity.l_total_licences} </b>
                                                                                            </div>
                                                                                            <div className="ms-3 border-end pe-3 text-center">
                                                                                                <h6 className="text-muted fw-normal">Used</h6>
                                                                                                <b>{Entity && Entity.l_used_licences}</b>
                                                                                            </div>
                                                                                            <div className="ms-3 pe-3 text-center">
                                                                                                <h6 className="text-muted fw-normal">Remaining</h6>
                                                                                                <b>{Number(Entity && Entity.l_total_licences) - Number(Entity && Entity.l_used_licences)}</b>
                                                                                            </div>

                                                                                        </div>
                                                                                        <div className="d-flex">
                                                                                            <span className="fs-2  font-weight-medium">Contract From : {Entity && Entity.cont_from}</span>
                                                                                            <span className="fs-2 ms-3 font-weight-medium">Contract To : {Entity && Entity.cont_to}</span>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4 px-2">
                                                                                <div className="card mb-0 w-100 overflow-hidden card-hover mb-3">
                                                                                    <div className="card-body py-2 px-2">
                                                                                        <div className="d-flex align-items-center">
                                                                                            <h4 className="mb-0 fs-3">Compfie Vendor Total Licence(s)</h4>

                                                                                        </div>
                                                                                        <div className="d-flex align-items-center my-3 mb-1">
                                                                                            <div style={{ backgroundColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ' }} className={"border-end pe-3 text-center text-white rounded p-2"}>
                                                                                                <h6 className="fw-bold" style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>Total</h6>
                                                                                                <b style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>{Entity && Entity.v_total_licences} </b>
                                                                                            </div>
                                                                                            <div className="ms-3 border-end pe-3 text-center">
                                                                                                <h6 className="text-muted fw-normal">Used</h6>
                                                                                                <b>{Entity && Entity.v_used_licences}</b>
                                                                                            </div>
                                                                                            <div className="ms-3 pe-3 text-center">
                                                                                                <h6 className="text-muted fw-normal">Remaining</h6>
                                                                                                <b>{Number(Entity && Entity.v_total_licences) - Number(Entity && Entity.v_used_licences)}</b>
                                                                                            </div>

                                                                                        </div>
                                                                                        <div className="d-flex">
                                                                                            <span className="fs-2  font-weight-medium">Contract From : {Entity && Entity.v_cont_from}</span> <br />
                                                                                            <span className="fs-2 ms-3 font-weight-medium">Contract To : {Entity && Entity.v_cont_to}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <a
                                                                    className="carousel-control-prev"
                                                                    href="#carouselExampleControls"
                                                                    role="button"
                                                                    data-bs-slide="prev"
                                                                >
                                                                    <span
                                                                        className="carousel-control-prev-icon"
                                                                        aria-hidden="true"
                                                                    ></span>
                                                                    <span className="visually-hidden">Previous</span>
                                                                </a>
                                                                <a
                                                                    className="carousel-control-next"
                                                                    href="#carouselExampleControls"
                                                                    role="button"
                                                                    data-bs-slide="next"
                                                                >
                                                                    <span
                                                                        className="carousel-control-next-icon"
                                                                        aria-hidden="true"
                                                                    ></span>
                                                                    <span className="visually-hidden">Next</span>
                                                                </a>
                                                            </div>


                                                        </div>




                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            {/* <div className='col-md-12' style={{ marginTop: "20px" }}>
                                {moment(Entity && Entity.cont_to).isSameOrAfter(date) ?
                                    <>
                                        <div className='row'>

                                            <div className='col-md-6'>
                                                <label><span><b>Compfie Total Licence(s):</b></span> {Entity && Entity.total_licences} <span><b>Used:</b></span> {Entity && Entity.used_licences} | <span><b>Remaining:</b></span> {Number(Entity && Entity.total_licences) - Number(Entity && Entity.used_licences)}</label>
                                            </div>
                                            <div className='col-md-3'>
                                                <label style={{ textAlign: "center" }}><span><b>Contract From:</b></span> {Entity && Entity.cont_from}</label>
                                            </div>
                                            <div className='col-md-3'>
                                                <label style={{ textAlign: "center" }}><span><b>Contract To:</b></span> {Entity && Entity.cont_to}</label>
                                            </div>
                                        </div>
                                    </> : false}
                            </div>
                            <div className='col-md-12' style={{ marginTop: "10px" }}>
                                {moment(Entity && Entity.l_cont_to).isSameOrAfter(date) ?
                                    <>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label><span><b>Compfie Litigation Total Licence(s):</b></span> {Entity && Entity.l_total_licences} <span><b>Used:</b></span> {Entity && Entity.l_used_licences} | <span><b>Remaining:</b></span> {Number(Entity && Entity.l_total_licences) - Number(Entity && Entity.l_used_licences)}</label>
                                            </div>
                                            <div className='col-md-3'>
                                                <label ><span><b>Contract From:</b></span> {Entity && Entity.l_cont_from}</label>
                                            </div>
                                            <div className='col-md-3'>
                                                <label ><span><b>Contract To:</b></span> {Entity && Entity.l_cont_to}</label>
                                            </div>
                                        </div>
                                    </> : false}
                            </div>
                            <div className='col-md-12' style={{ marginTop: "10px" }}>
                                {moment(Entity && Entity.v_cont_to).isSameOrAfter(date) ?
                                    <>
                                        <div className='row'>
                                            <div className='col-md-6'>

                                                <label><span><b>Compfie Vendor Total Licence(s):</b></span> {Entity && Entity.v_total_licences} <span><b>Used:</b></span> {Entity && Entity.v_used_licences} | <span><b>Remaining:</b></span> {Number(Entity && Entity.v_total_licences) - Number(Entity && Entity.v_used_licences)}</label>
                                            </div>
                                            <div className='col-md-3'>
                                                <label ><span><b>Contract From:</b></span> {Entity && Entity.v_cont_from}</label>
                                            </div>
                                            <div className='col-md-3'>
                                                <label ><span><b>Contract To:</b></span> {Entity && Entity.v_cont_to}</label>
                                            </div>
                                        </div>
                                    </> : false}
                            </div> */}


                            <div className='col-md-12  usersplittable'>
                                <Table
                                    id='service-user-priv'
                                    className={localStorage.getItem("currentTheme")}
                                    size={'small'}
                                    columns={((moment(Entity && Entity.v_cont_to).isSameOrAfter(date)) && (moment(Entity && Entity.cont_to).isSameOrAfter(date))) ? columns : (moment(Entity && Entity.cont_to).isSameOrAfter(date)) ? columns2 : moment(date).isBefore(Entity && Entity.v_cont_to) ? columns3 : false}
                                    dataSource={usermangementData && usermangementData.length > 0 && usermangementData.map((usermanlist) => {
                                        if (usermanlist.user_name == null) {
                                            usermanlist.user_name_new = ''
                                        } else {
                                            usermanlist.user_name_new = usermanlist.user_name
                                        }
                                        return usermanlist
                                    })}
                                    onChange={tablehandleChange}
                                    bordered
                                    showSorterTooltip={false}
                                    pagination={false}
                                />
                            </div>
                            <div className='card p-4 py-2 mb-0 rounded user-mapping bg-white'>
                                <div className="row">
                                    <div className='col-md-3'>
                                        <i style={{ color: "#45b0e2" }} className="bi bi-square-fill"></i> <label>Domain Admin</label>
                                    </div>
                                    <div className='col-md-3'>
                                        <i style={{ color: "#e96154" }} className="bi bi-square-fill"></i> <label>Inhouse User</label>
                                    </div>
                                    <div className='col-md-3'>
                                        <i style={{ color: "#fbca35" }} className="bi bi-square-fill"></i> <label>Legal Entity Admin</label>
                                    </div>
                                    <div className='col-md-3'>
                                        <i style={{ color: "#60667a" }} className="bi bi-square-fill"></i> <label>Service Provider User</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className='col-md-3'>
                                        <i style={{ color: "#9a9da0" }} className="bi bi-square-fill"></i> <label>View Only</label>
                                    </div>
                                    <div className='col-md-3'>
                                        <i style={{ color: "#b29999" }} className="bi bi-square-fill"></i> <label>View Only Restricted</label>
                                    </div>
                                    <div className='col-md-3'>
                                        <i style={{ color: "#00FFFF" }} className="bi bi-square-fill"></i> <label>Litigation User</label>
                                    </div>
                                    <div className='col-md-3'>
                                        {/* <i style={{ color: "skyblue" }} class="bi bi-square-fill"></i> <label>Litigation User</label> */}
                                        <i style={{ color: "#ff8acc" }} className="bi bi-square-fill"></i> <label>Not Access</label>


                                    </div>
                                </div>
                            </div>
                        </Card>
                    }

                    <Modal title="Country" className='modelradius' visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                        <Search
                            placeholder="Search"
                        // onSearch={onSearch}
                        // style={{
                        //     width: 200,
                        // }}
                        />
                    </Modal>
                    {/* <div className={scroll == true ? "d-block" : "d-none"}>
                        <Button title="Scroll Back to Top" style={{ position: 'fixed', right: '30px', bottom: '50px', zIndex: 1, width: '34px', display: 'flex', justifyContent: 'center' ,borderRadius:'15px' }} onClick={() => { scrollup() }}><UpCircleOutlined style={{ fontSize: '1.5rem' }} /></Button>
                    </div> */}
                    <Modal title="Business Group" className='modelradius' visible={business} onOk={handleOk} onCancel={handleCancel}>
                        <Search
                            placeholder="Search"
                        // onSearch={onSearch}
                        // style={{
                        //     width: 200,
                        // }}
                        />
                    </Modal>
                    <Modal title="Legal Entity" className='modelradius' visible={legal} onOk={handleOk} onCancel={handleCancel}>
                        <Search
                            placeholder="Search"
                        // onSearch={onSearch}
                        // style={{
                        //     width: 200,
                        // }}
                        />
                    </Modal>

                    {/* <Modal visible={modalVisible} footer={null}
                        onCancel={passwordcancel}
                    >

                        <div className="row">
                            <div className="row pt-3">

                                <div className="col-md-6">
                                    <div className='mb-2'>
                                        <label className="control-label">Password:</label>
                                        <span style={{ "color": "red" }}>*</span>
                                        <input
                                            type="password"
                                            id="password"
                                            name='password'
                                            className="form-control"
                                            placeholder="Enter password"
                                            value={modalData.password}
                                            onChange={(e) => {
                                                setModalData({
                                                    ...modalData,
                                                    password: e.target.value
                                                })
                                            }}
                                        //   value={password.passwordvalue}
                                        />
                                        {formValidator.current.message(
                                            'password',
                                            modalData.password,
                                            'required',
                                            {
                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Please Enter Password',
                                                }
                                            })}

                                    </div><br />
                                </div>


                                <div className="col-md-5">
                                    <div className="mb-2">
                                        <label className="control-label">Reason:</label>

                                        <span style={{ "color": "red" }}>*</span>
                                        <div className="col-md-2">
                                            <PlusSquareTwoTone onClick={() => { showRemarksModal() }} style={{ marginLeft: '650%' }} />
                                        </div>
                                        <textarea className='form-control'
                                            onChange={(e) => {
                                                setRemarks(e.target.value)
                                            }}
                                            value={remarks}

                                        ></textarea>

                                        {formValidator.current.message(
                                            'reason',
                                            remarks,
                                            'required',
                                            {
                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Please Enter Reason',
                                                }
                                            })}
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="form-actions">
                            <div className="card-body border-top">
                                <button
                                    className="btn btn-light-success rounded-pill px-4 text-success"
                                // onClick={submitpassword}
                                >
                                    <div className="d-flex align-items-center" onClick={OnSubmited}>
                                        <i
                                            data-feather="save"
                                            className="feather-sm me-1 fill-icon"

                                        ></i>
                                        Ok
                                    </div>
                                </button>
                                <button
                                    className="btn btn-light-danger text-danger font-weight-medium
               waves-effect text-start"
                                    onClick={passwordcancel}
                                >
                                    <div className="d-flex align-items-center">
                                        <i data-feather="save" className="feather-sm me-1 fill-icon"></i>
                                        Cancel
                                    </div>
                                </button>
                            </div>
                        </div>

                    </Modal> */}

                    {/* <Modal visible={modalVisible} footer={null}
                onCancel={passwordcancel} className="newStyleModalPassword">
                <div className="modal-body" >
                            <div className="row">
                                <div className="col-md-6">
                                    <div class="mb-3">
                                    </div>
                                    <div className="mb-3">

                                        <input
                                            type="password"
                                            id="password"
                                            name='passwordvalue'
                                            className="form-control"
                                            placeholder="Enter password"
                                            onChange={(e) => {
                                                Setpassword({
                                                    ...passwordata,
                                                    passvalue: e.target.value

                                                })
                                            }}
                                            value={passwordata.passvalue}
                                        />
                                        {/* {formValidator.current.message(
                  'Password',
                  password.passwordvalue,
                  ['required'],
                  {
                    className: `invalid-feedback ${formValidator ? 'show' : 'hide'}`,
                    messages: {
                      regex: 'Enter Password'
                    }
                  })} */}
                    {/* </div>
                                </div><br />

                            </div>
                            <div className="form-actions">
                                <div className="card-body border-top">
                                    <button
                                        className="btn btn-light-success rounded-pill px-4 text-success"
                                        onClick={submitpassword}
                                    >
                                        <div className="d-flex align-items-center">
                                            <i
                                                data-feather="save"
                                                className="feather-sm me-1 fill-icon"
                                            ></i>
                                            Ok
                                        </div>
                                    </button>
                                    <button
                                        className="btn btn-light-danger text-danger font-weight-medium
               waves-effect text-start"
                                        onClick={passwordcancel}
                                    >
                                        <div className="d-flex align-items-center">
                                            <i data-feather="save" className="feather-sm me-1 fill-icon"></i>
                                            Cancel
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
            </Modal> */}
                    <Modal title="" visible={filterModal} footer={null} maskClosable={false} className={"serviceProvider user-priv-modal add-service-prv modelradius " + localStorage.getItem('currentTheme')}
                        closable={false}
                    >
                        <div className='col-md-12'>
                            <div className="row">
                                <div className="col-md-6">
                                    <label ><b>Country</b><span style={{ color: "red" }}>*</span></label>
                                    {/* </div> */}
                                    {/* <div className='col-md-2'> */}
                                    <Select
                                        title={''}
                                        allowClear={true}

                                        size="default"
                                        placeholder="Enter Country"
                                        onChange={(value, data) => {

                                            if (value == undefined) {
                                                setmain({
                                                    ...main,
                                                    country: '',
                                                    entity: '',
                                                    business: ''
                                                })
                                                setCurrentEntity('')

                                                setEntityValue(
                                                    ''
                                                )

                                            }
                                            else {
                                                let filteredEntity = _.filter(legalEntity, { c_id: Number(value) })

                                                setFinallegalentity(filteredEntity)
                                                setmain({
                                                    ...main,
                                                    country: data && data.children
                                                })
                                            }
                                        }}
                                        // onSearch={onSearch}
                                        optionFilterProp="children"

                                        showSearch


                                        filterOption={(input, option) =>

                                            // option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                                            option.children != undefined ? option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false


                                        }
                                        style={{ width: '100%' }}
                                    >
                                        <Option key={finalResult && finalResult.Resultdata.length && finalResult.Resultdata[0].c_id}>{finalResult && finalResult.Resultdata.length > 0 && finalResult.Resultdata[0].c_name}</Option>
                                    </Select>
                                    {entityFormvalidator.current.message(
                                        '',
                                        main.country,
                                        'required',
                                        {
                                            className: `invalid-feedback ${EntitySubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Country Required',
                                            }
                                        })}
                                </div>
                                <div className="col-md-6">
                                    <label><b>Business Group</b></label>

                                    {/* </div> */}
                                    {/* <div className='col-md-2'> */}
                                    <Select

                                        allowClear={true}
                                        size="default"
                                        placeholder="Enter Business Group"
                                        disabled={main.country ? false : true}
                                        // placeholder="Enter Entity"
                                        onChange={(data) => {
                                            if (data == undefined) {
                                                setmain({
                                                    ...main,
                                                    business: '',
                                                    entity: ''
                                                })
                                                setFinallegalentity(entity_info)
                                            }
                                            else {
                                                let filteredEntity = _.filter(entity_info, { bg_id: Number(data) })
                                                setFinallegalentity(filteredEntity)
                                                setmain({
                                                    ...main,
                                                    business: data
                                                })
                                            }
                                        }}
                                        // onSearch={onSearch}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        value={main.business ? main.business : undefined}
                                        style={{ width: '100%' }}
                                    >
                                        {main.country ?
                                            finalResult && finalResult.Resultdata.length > 0 && finalResult.Resultdata[0].child.map((item, i) => {
                                                if (item.bg_name !== null) {
                                                    return (
                                                        <Option key={item.bg_id}>
                                                            {item.bg_name}
                                                        </Option>
                                                    );
                                                }
                                            }) : ''}
                                    </Select>
                                </div>
                            </div>

                            {/* <div className='row user-filter-warpper'> */}




                            {/* <div className='col-md-1'></div> */}

                            {/* <div className='col-md-1'></div> */}





                        </div>
                        <div className="col-md-12" style={{ marginTop: '10px' }}>
                            <div className="row">
                                <div className="col-md-6">
                                    <label><b>Legal Entity</b></label>
                                    {/* </div> */}
                                    {/* <div className='col-md-2'> */}
                                    {/* {localStorage.getItem('SelectedEntity') === "All Legal Entity" ? */}
                                    <Select
                                        disabled={main.country ? false : true}
                                        allowClear={true}
                                        size="default"
                                        placeholder="Enter Legal Entity"
                                        onChange={(data, value) => {
                                            if (data == undefined) {
                                                setmain({
                                                    ...main,
                                                    entity: ''
                                                })
                                            }
                                            else {
                                                setCurrentEntity(data)

                                                setEntityValue(
                                                    data
                                                )
                                                setmain({
                                                    ...main,
                                                    entity: data
                                                })
                                            }
                                            // setUnit({
                                            //     ...unit,
                                            //     legal_entity: data
                                            // })
                                        }}
                                        // onSearch={onSearch}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        value={main.entity ? main.entity : undefined}
                                        style={{ width: '100%' }}
                                    >


                                        {finallegalEntity && finallegalEntity.length > 0 && finallegalEntity.map((item, i) => {

                                            return (
                                                main.country ?
                                                    <Option key={item.le_id}>
                                                        {item.le_name}
                                                    </Option> : ""
                                            );
                                        })}
                                    </Select>
                                    {/* :
                                        <p className='form-control'>{localStorage.getItem('SelectedEntity')}</p>

                                    } */}

                                    {/* {entityFormvalidator.current.message(
                                        'Entity',
                                        main.entity,
                                        'required',
                                        {
                                            className: `invalid-feedback ${EntitySubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Required Field Missing',
                                            }
                                        })} */}
                                </div>
                                <div className="col-md-6">
                                    <label><b>Compfie Category</b></label>
                                    {/* </div> */}
                                    {/* <div className='col-md-2'> */}
                                    {/* {localStorage.getItem('SelectedEntity') === "All Legal Entity" ? */}
                                    <Select
                                        // disabled={main.country ? false : true}
                                        allowClear={true}
                                        size="default"
                                        placeholder="Enter Compfie Category"
                                        onChange={(data, value) => {
                                            if (data == undefined) {
                                                setCategoryValue({
                                                    ...categoryValue,
                                                    compfie: data
                                                })
                                            }
                                            else {



                                                setCategoryValue({
                                                    ...categoryValue,
                                                    compfie: ''
                                                })
                                            }
                                            // setUnit({
                                            //     ...unit,
                                            //     legal_entity: data
                                            // })
                                        }}
                                        // onSearch={onSearch}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        value={categoryValue.compfie ? categoryValue.compfie : undefined}
                                        style={{ width: '100%' }}
                                    >


                                        {/* [
                {
                    text: <span><i style={{ color: "#9a9da0" }} className="bi bi-square-fill"></i> View Only</span>,
                    value: 2,
                },
                {
                    text: <span><i style={{ color: "#fbca35" }} className="bi bi-square-fill"></i> Legal Entity Admin</span>,
                    value: 3,
                },
                {
                    text: <span><i style={{ color: "#45b0e2" }} className="bi bi-square-fill"></i> Domain Admin</span>,
                    value: 4,
                },
                {
                    text: <span><i style={{ color: "#FF6666" }} className="bi bi-square-fill"></i> Inhouse User</span>,
                    value: 5,
                },
                {
                    text: <span><i style={{ color: "#60667a" }} className="bi bi-square-fill"></i> Service Provider User</span>,
                    value: 6,
                },
                {
                    text: <span><i style={{ color: "#b29999" }} className="bi bi-square-fill"></i> View Only Restricted</span>,
                    value: 7,
                },
                {
                    text: <span><i style={{ color: "#00FFFF" }} className="bi bi-square-fill"></i> Litigation User
                    </span>,
                    value: 1,
                },
                {
                    text: <span><i style={{ color: "#ff8acc" }} className="bi bi-square-fill"></i> Not Access
                    </span>,
                    value: null,
                },
            ], */}
                                        <Option key={2}>View Only</Option>
                                        <Option key={3}>Legal Entity Admin</Option>
                                        <Option key={4}>Domain Admin</Option>
                                        <Option key={5}>Inhouse User</Option>
                                        <Option key={6}>Service Provider User</Option>
                                        <Option key={7}> View Only Restricted</Option>
                                        <Option key={1}>Litigation User</Option>
                                        <Option key={null}> Not Access</Option>

                                    </Select>
                                    {/* :
                                        <p className='form-control'>{localStorage.getItem('SelectedEntity')}</p>

                                    } */}

                                    {/* {entityFormvalidator.current.message(
                                        'Entity',
                                        main.entity,
                                        'required',
                                        {
                                            className: `invalid-feedback ${EntitySubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Required Field Missing',
                                            }
                                        })} */}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '10px' }}>
                            <div className="row">
                                <div className="col-md-6">
                                    <label><b>Vendor Category</b></label>
                                    {/* </div> */}
                                    {/* <div className='col-md-2'> */}
                                    {/* {localStorage.getItem('SelectedEntity') === "All Legal Entity" ? */}
                                    <Select
                                        // disabled={main.country ? false : true}
                                        allowClear={true}
                                        size="default"
                                        placeholder="Enter Vendor Category"
                                        onChange={(data, value) => {
                                            if (data == undefined) {
                                                setCategoryValue({
                                                    ...categoryValue,
                                                    vendor: data
                                                })
                                            }
                                            else {
                                                setCategoryValue({
                                                    ...categoryValue,
                                                    vendor: ''
                                                })
                                            }
                                            // setUnit({
                                            //     ...unit,
                                            //     legal_entity: data
                                            // })
                                        }}
                                        // onSearch={onSearch}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        value={categoryValue.vendor ? categoryValue.vendor : undefined}
                                        style={{ width: '100%' }}
                                    >


                                        <Option key={2}>View Only</Option>
                                        <Option key={3}>Legal Entity Admin</Option>
                                        <Option key={4}>Domain Admin</Option>
                                        <Option key={5}>Inhouse User</Option>
                                        <Option key={6}>Service Provider User</Option>
                                        <Option key={7}> View Only Restricted</Option>
                                        <Option key={1}>Litigation User</Option>
                                        <Option key={null}> Not Access</Option>
                                    </Select>
                                    {/* :
                                        <p className='form-control'>{localStorage.getItem('SelectedEntity')}</p>

                                    } */}

                                    {/* {entityFormvalidator.current.message(
                                        'Entity',
                                        main.entity,
                                        'required',
                                        {
                                            className: `invalid-feedback ${EntitySubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Required Field Missing',
                                            }
                                        })} */}
                                </div>
                                <div className="col-md-6">
                                    <Button icon={<EyeOutlined />} shape="round" className='popupbtncolour' style={{ background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', marginLeft: '27px', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white', marginTop: '8%' }} onClick={(e) => {
                                        e.preventDefault()
                                        setEntityFormSubmit(true)
                                        if (entityFormvalidator.current.allValid()) {
                                            if (EntityValue) {
                                                if (userManagementList && userManagementList.length) {
                                                    let data = _.filter(userManagementList, { le_id: EntityValue })
                                                    setFilterArray(data)
                                                }
                                                let LegalEntityData = _.find(UserLegalDetails && UserLegalDetails, { le_id: EntityValue })
                                                setEntity(LegalEntityData)
                                            }
                                            setFilterModal(false)
                                        }
                                    }}>Show
                                    </Button>
                                    <Button icon={<CloseCircleOutlined />} shape="round" className='popupbtncolour' style={{ background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', marginLeft: '10px', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}
                                        onClick={() => {
                                            setFilterModal(false)
                                        }}
                                    >Close
                                    </Button>
                                </div>
                            </div>
                        </div>


                        {/* <div className="col-md-12 mt-3">
                            <div className="row">
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-3">

                                    <Button icon={<EyeOutlined />} shape="round" className='popupbtncolour' style={{ background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', marginLeft: '10px', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }} onClick={(e) => {
                                        e.preventDefault()
                                        setEntityFormSubmit(true)
                                        if (entityFormvalidator.current.allValid()) {
                                            if (EntityValue) {
                                                if (userManagementList && userManagementList.length) {
                                                    let data = _.filter(userManagementList, { le_id: EntityValue })
                                                    setFilterArray(data)
                                                }
                                                let LegalEntityData = _.find(UserLegalDetails && UserLegalDetails, { le_id: EntityValue })
                                                setEntity(LegalEntityData)
                                            }
                                            setFilterModal(false)
                                        }
                                    }}>Show
                                    </Button>

                                </div>
                                <div className="col-md-3" style={{ marginLeft: '0%' }}>
                                    <Button icon={<CloseCircleOutlined />} shape="round" className='popupbtncolour' style={{ background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', marginLeft: '-18px', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}
                                        onClick={() => {
                                            setFilterModal(false)
                                        }}
                                    >Close
                                    </Button>
                                </div>
                                <div className="col-md-3">
                                </div>
                            </div>

                        </div> */}
                    </Modal>

                    <Modal visible={modalVisible} className='usr_modal_class modelradius' footer={null}
                        onCancel={passwordcancel}
                    >
                        {/* <div className="col-md-12"> */}
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor=""><b>Password</b> <span style={{ "color": "red" }}>*</span></label>
                                <input
                                    type="password"
                                    id="password"
                                    name='passwordvalue'
                                    className="form-control"
                                    placeholder="Enter password"
                                    value={modalData.password}
                                    onChange={(e) => {
                                        setModalData({
                                            ...modalData,
                                            password: e.target.value
                                        })
                                    }}
                                    style={{ width: '95%' }}

                                />
                                {formValidator.current.message(
                                    'password',
                                    modalData.password,
                                    'required',
                                    {
                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                        messages: {
                                            required: 'Enter Password',
                                        }
                                    })}
                            </div>
                        </div>
                        <br />
                        <div className="col-md-12">
                            <div>
                                <label htmlFor=""><b>Remarks </b> <span style={{ "color": "red" }}>*</span></label></div>
                            <div className="d-flex align-items-end">
                                <TextArea className='form-control' row='1' cols='4' name='remark' placeholder="Enter Reason"
                                    onChange={(e) => {
                                        setRemarks(e.target.value)
                                    }}
                                    value={remarks}
                                />
                                <span className="text-info ms-1 d-flex 
                                align-items-center justify-content-center" style={{ float: 'right' }}
                                    onClick={() => { showRemarksModal() }}>
                                    <PlusCircleOutlined />
                                </span>
                                {formValidator.current.message(
                                    'reason',
                                    remarks,
                                    'required',
                                    {
                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                        messages: {
                                            required: 'Remarks Required',
                                        }
                                    })}</div>
                        </div>
                        {/* <div className="form-actions text-center"> */}
                        {/* <Button type="primary" shape="round" className='addbutton'
                                style={{ background: "#198754", borderColor: "#198754", marginLeft: '33%' }}
                                icon={<PlayCircleOutlined />} size='default'
                                onClick={OnSubmited}
                            >
                                Submit
                            </Button> */}
                        <div className="form-actions text-center pt-3 popupbtncolour">
                            <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                                style={{ marginLeft: '-4%' }}
                                icon={<PlayCircleOutlined />} size='default'
                                onClick={OnSubmited}
                            >
                                Submit
                            </Button>
                            {/* <div className="button2" id={localStorage.getItem('currentTheme')} onClick={blockservice}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        Submit
                    </div> */}
                        </div>
                        {/* <button
                                className="btn btn-light-danger rounded-pill px-4 ms-2 text-danger
               waves-effect text-start"
                                onClick={passwordcancel}
                            > Cancel

                            </button> */}
                        {/* </div> */}
                    </Modal>

                    <Modal visible={passvisible} footer={null} className="newStyleModalPassword modelradius" onCancel={passwordcancel}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-6">
                                    <label className="control-label"> <b>Password </b><span style={{ "color": "red" }}>*</span></label>
                                    <input
                                        style={{ marginTop: "5px", width: '100%' }}
                                        type="password"
                                        id="password"
                                        name='passwordvalue'
                                        className="form-control"
                                        placeholder="Enter password"
                                        onChange={(e) => {
                                            Setpassword({
                                                ...passwordata,
                                                passvalue: e.target.value

                                            })
                                        }}
                                        value={passwordata.passvalue}

                                    />
                                    {formValidator1.current.message(
                                        'password',
                                        passwordata.passvalue,
                                        'required',
                                        {
                                            className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Enter Password',
                                            }
                                        })}
                                </div>
                            </div>

                        </div>
                        <br />
                        <div className="form-actions popupbtncolour text-center">
                            <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                                style={{ background: "#198754", borderColor: "#198754", marginLeft: '-4%' }}
                                icon={<PlayCircleOutlined />} size='default'
                                onClick={submitpassword}>
                                Submit
                            </Button>
                            {/* <div class="button2" id={localStorage.getItem('currentTheme')} onClick={submitpassword}>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                Submit
                            </div> */}
                            {/* <button className="btn btn-light-danger text-danger font-weight-medium waves-effect text-start" style={{ marginLeft: "20px" }} onClick={passwordcancel} >
                                Cancel
                            </button> */}
                        </div>
                    </Modal>
                    <Modal title="Remarks List" visible={remarksModal} className={"remark-header modelradius add-service-prv " + localStorage.getItem('currentTheme')}
                        footer={false}
                        onCancel={handleCancel}
                    >
                        <Table
                            // className='userprivclass'
                            size={'small'}
                            columns={RemarksColumn}
                            dataSource={remarksvalue && remarksvalue.length > 0 && remarksvalue}
                            bordered
                            // pagination={{
                            //     defaultPageSize: dataTableProperties.pagesize,
                            //     showSizeChanger: dataTableProperties.sizechanger,
                            //     pageSizeOptions: dataTableProperties.pageSizeOptions
                            // }}
                            pagination={false}
                        // style={{width:'300px'}}

                        // onRowClick={{}}

                        />
                    </Modal>
                </div>
            </div > : <AddUserManagement details={details} setAddType={setAddType} showList={showList} setShowList={setShowList} AddType={AddType} fullscreen={fullscreen} setfullscreen={setfullscreen} />}

        </>

    )
}
export default connect(mapStateToProps, {
    getUsermanagementList,
    updatestatus,
    activeStatus,
    GetRemarksData,
    disableStatusChange,
    haveCompliance,
    getUsermanagementDetails,
    LegalAdmindisableStatusChange,
    getById,
    resendEmail
})(Usermanagement)